import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";

const Container = tw.div`
  fixed bottom-7 left-0 right-0 mx-auto w-fit max-w-[90%]
  flex flex-row rounded-md bg-blue-350 shadow-[0_4px_16px_0_rgba(0,0,0,0.1)] 
  items-center justify-center py-3 px-10 gap-4
  text-sm animate-[enter-bottom_0.4s_ease-out_forwards]
`;

type Props = {
  allItemsSelected: boolean;
  selectedCount: number;
  totalCount: number;
  itemNameSingular: string;
  itemNamePlural: string;
  selectAll?: () => void;
  clearAll?: () => void;
  hideSelectAll?: boolean;
};

export const ListSelectAll: FC<Props> = ({
  allItemsSelected,
  selectedCount,
  totalCount,
  itemNameSingular,
  itemNamePlural,
  selectAll,
  clearAll,
  hideSelectAll = false,
}) => {
  if (allItemsSelected) {
    return (
      <Container>
        <FormattedMessage id="ALL_ITEMS_SELECTED" values={{ totalCount }} />
        <LinkLike onClick={clearAll}>
          <FormattedMessage id="CLEAR" />
        </LinkLike>
      </Container>
    );
  }
  if (!selectedCount) {
    return null;
  }
  return (
    <Container>
      <FormattedMessage
        id="ITEMS_SELECTED"
        values={{ selectedCount, totalCount, itemNameSingular, itemNamePlural }}
      />
      <If isTrue={!hideSelectAll}>
        <LinkLike onClick={selectAll}>
          <FormattedMessage
            id="SELECT_ALL"
            values={{ totalCount, itemNamePlural }}
          />
        </LinkLike>
      </If>
      <LinkLike onClick={clearAll}>
        <FormattedMessage id="CLEAR_SELECTION" />
      </LinkLike>
    </Container>
  );
};

import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  BuyoutsDocument,
  QuoteStatus,
  useAcceptQuoteMutation as useAcceptQuoteGraphQLMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useAcceptQuote = () => {
  const { paginationArgs } = usePagination();
  const { setError } = useGlobalError();
  const [acceptQuoteMutation, { loading: accepting }] =
    useAcceptQuoteGraphQLMutation();

  const acceptQuote = useCallback(
    async ({
      rfqId,
      quoteId,
      selectedQuotes = [],
      selectedAuxiliaryQuoteItems = [],
      createBuyout = false,
      notifyOtherVendors = false,
    }: {
      rfqId: string;
      quoteId?: string;
      selectedQuotes?: { quoteItemId: string }[];
      selectedAuxiliaryQuoteItems?: { itemId: string }[];
      createBuyout?: boolean;
      notifyOtherVendors?: boolean;
    }) => {
      try {
        if (!rfqId) {
          return undefined;
        }

        const { errors, data: acceptQuoteResult } = await acceptQuoteMutation({
          variables: {
            input: {
              rfqId,
              ...(selectedQuotes.length > 0 && {
                quoteItemIDs: selectedQuotes.map((quote) => quote.quoteItemId),
                auxiliaryQuoteItemIDs: selectedAuxiliaryQuoteItems.map(
                  (quote) => quote.itemId,
                ),
              }),
              ...(quoteId && { quoteId }),
              createBuyout,
              notifyOtherVendors,
            },
            quoteStatus: [
              QuoteStatus.Submitted,
              QuoteStatus.Rejected,
              QuoteStatus.Accepted,
            ],
          },
          ...(createBuyout && {
            refetchQueries: [
              {
                query: BuyoutsDocument,
                variables: {
                  ...paginationArgs,
                },
              },
            ],
          }),
        });
        setError(errors);

        if (createBuyout) {
          return acceptQuoteResult?.acceptQuote?.quotes
            .filter((quote) => quote.buyout)
            .sort((a, b) =>
              a.buyout && b.buyout
                ? a.buyout.clientIdentifier.localeCompare(
                    b.buyout.clientIdentifier,
                  )
                : 0,
            )[0]?.buyout?.id;
        }
        return acceptQuoteResult?.acceptQuote?.quotes[0]?.id;
      } catch (errors) {
        setError(errors);
        return undefined;
      }
    },
    [acceptQuoteMutation, setError, paginationArgs],
  );

  return { acceptQuote, accepting };
};

import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { ConnectReleasePOsPanel } from "@/contractor/pages/home/release/components/connections/components/panels/ConnectReleasePOsPanel";
import { ReleaseFieldsFragment, SourceSystem } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSubmitChanges } from "../../../../pages/specify-details/hooks/useSubmitReleaseChanges";
import { ReleaseExportStatus } from "../common/release-export-status/ReleaseExportStatus";
import { useReleaseExportStatus } from "./hooks/useReleaseExportStatus";

type Props = {
  release: Pick<
    ReleaseFieldsFragment,
    | "status"
    | "id"
    | "poLink"
    | "version"
    | "poNumber"
    | "project"
    | "syncVersion"
    | "poExists"
    | "buyout"
    | "useSourceSystemPO"
  >;
  sourceSystem?: SourceSystem;
};

export const EditReleasePOButton: FC<Props> = ({ release, sourceSystem }) => {
  const { status } = useReleaseExportStatus({ release });
  const { submitChanges, submitting } = useSubmitChanges();

  const connectionMode = useMemo(() => {
    if (release.poLink?.autoSync) {
      return ConnectionMode.Edit;
    }
    return ConnectionMode.Sync;
  }, [release.poLink?.autoSync]);

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <>
          <If isTrue={release.poLink || release.poExists}>
            <ReleaseExportStatus
              status={status}
              sourceSystem={sourceSystem}
              date={release.poLink?.syncedAt}
              poNumber={release.poNumber}
              projectId={release.project?.id}
              poExists={release.poExists}
              poLink={release.poLink}
              useSourceSystemPO={release.useSourceSystemPO}
            />
          </If>
          <If isTrue={release.poLink}>
            <OutlinedButton
              $small
              onClick={async () => {
                await submitChanges({
                  callback: () => {
                    togglePanel(true);
                  },
                  validationKey: "NEED_TO_SAVE_CHANGES_EXPORT_SETTINGS",
                });
              }}
            >
              <Loader loading={submitting} small />
              <FormattedMessage id="EXPORT_SETTINGS" tagName="span" />
            </OutlinedButton>
          </If>
        </>
      )}
      content={(togglePanel) => (
        <ConnectReleasePOsPanel
          releaseIds={[release.id]}
          onClose={() => togglePanel(false)}
          mode={connectionMode}
        />
      )}
    />
  );
};

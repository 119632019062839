import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { FormattedMessage } from "react-intl";
import { useInventoryItemSequence } from "../current-stock-list/components/hooks/useInventoryItemSequence";
import { SelectProjectDialog } from "../select-project-dialog/SelectProjectDialog";
import { useAddJobOrder } from "./useAddJobOrder";

export const AddJobOrderButton = () => {
  const { selectedEntities } = useInventoryItemSequence();
  const { handleAdjustment, open, setOpen, handleConfirm } = useAddJobOrder();

  return (
    <>
      <Tooltip
        element={
          <OutlinedButton
            onClick={handleAdjustment}
            disabled={selectedEntities.length === 0}
          >
            <FormattedMessage id="JOB_ORDER" />
          </OutlinedButton>
        }
      >
        {selectedEntities.length === 0 ? (
          <FormattedMessage id="JOB_ORDER_DISABLED_TOOLTIP" />
        ) : (
          ""
        )}
      </Tooltip>
      <SelectProjectDialog
        open={open}
        onClose={() => setOpen(false)}
        labelKey="SELECT_PROJECT"
        handleConfirm={handleConfirm}
      />
    </>
  );
};

import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { Dialog } from "@/common/components/dialog/Dialog";
import { InfoText } from "@/common/components/dialog/Dialog.styles";
import { If } from "@/common/components/if/If";
import { SupplierInsuranceModal } from "@/common/components/supplier-insurance-modal/SupplierInsuranceModal";
import { Switch } from "@/common/components/switch/Switch";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { useSupplierInsurance } from "@/common/hooks/useSupplierInsurance";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { routes } from "@/config/routes";
import { useReleaseSequence } from "@/contractor/pages/home/releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { useAcceptQuote } from "@/contractor/pages/home/rfq-quotes/hooks/useAcceptQuote";
import {
  ReleaseFieldsFragment,
  ReleaseReassignmentFieldsFragment,
  RfqStatus,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";
import { isWarehouseRelease } from "../../../utils/isWarehouseRelease";
import { ReleaseDateTimePicker } from "../components/release-date-time-picker/ReleaseDateTimePicker";
import {
  ReleaseAlertType,
  useReleaseAlert,
} from "../hooks/release-alerts/useReleaseAlert";
import { useSyncReleaseItems } from "../hooks/useSyncReleaseItems";
import { SubmitReleaseCallbackProps } from "./SubmitReleaseCallbackProps";
import { useSubmitReleaseDialog } from "./useSubmitReleaseDialog";

const Container = tw.div`
  grid gap-1 mt-5 px-30 items-start justify-center
`;

const InfoTextNoBg = tw(InfoText)`bg-transparent text-gray-600 max-w-xs p-0`;

const InfoIconStyled = tw(InfoOutlined)`
  text-blue-800
`;

const FooterText = tw.div`
  text-xs w-fit grid grid-flow-col gap-x-1 items-center font-normal place-self-center
  py-1.5 px-4 col-span-3 rounded-3xl 
`;

const AdditionalInfo = tw.div`text-xs col-span-3 text-center leading-tight`;

const DateTimeContainer = tw.div`flex items-center justify-center pt-5`;

const InsuranceInfoContainer = tw.div`flex items-center text-sm font-medium text-red-500 justify-center gap-4 col-span-3 mb-6`;

type CustomCallbackParams = {
  requestedDate: Date | null;
};

type SubmitDialogParams = {
  includeVendorConfirmation?: boolean;
  title?: string;
  confirmButtonText?: string;
  dateLabelText?: string;
  release?: Pick<ReleaseFieldsFragment, "id" | "version">;
  customCallback?: (
    params?: CustomCallbackParams,
  ) => Promise<boolean> | boolean;
  disabledConfirmButton?: boolean;
};

type SubmitDialogProps = {
  visible: boolean;
  callback?: ({
    skip,
    receive,
    addDeliveryRecord,
    callback,
    skipVendorNotification,
  }: SubmitReleaseCallbackProps) =>
    | Promise<boolean | ReleaseReassignmentFieldsFragment[]>
    | void
    | boolean;
  setVisible: (visible: boolean) => void;
  params?: SubmitDialogParams;
};

export const SubmitReleaseDialog: FC<SubmitDialogProps> = ({
  visible,
  callback,
  setVisible,
  params = {
    includeVendorConfirmation: true,
  },
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { setWarningAlert } = useSnackbar();
  const { triggerAlert } = useReleaseAlert();
  const { release } = useRelease();
  const { requestedDate, setRequestedDate, setInputError } =
    useReleaseActions();
  const { updateRelease, updating } = useReleaseUpdate();
  const { saving: syncingReleaseItems, getSyncedRelease } =
    useSyncReleaseItems();
  const { orderTypeConfig } = useOrderTypesConfig({ release });
  const [isDateSpecified, setIsDateSpecified] =
    useState<boolean>(!!requestedDate);
  const { setStep } = useReleaseSequence();
  const { vendor, supplierInsuranceInfoIsVisible, insuranceExpired } =
    useSupplierInsurance(release?.sellerOrgLocation?.id);
  const [modalOpened, setModalOpened] = useState(false);
  const { dialogTitle, retroactiveRelease, confirmButtonText, schedule } =
    useSubmitReleaseDialog();
  const closeModal = useCallback(() => {
    setModalOpened(false);
  }, []);
  const { acceptQuote } = useAcceptQuote();

  const { releaseUpdateOptions, updateReleaseUpdateOptions, setHasChanges } =
    useReleaseStore(
      useShallow((state) => ({
        releaseUpdateOptions: state.releaseUpdateOptions,
        updateReleaseUpdateOptions: state.updateReleaseUpdateOptions,
        setHasChanges: state.setHasChanges,
      })),
    );

  const [skipConfirmation, setSkipConfirmation] = useState(false);
  const [skipVendorNotification, setSkipVendorNotification] = useState(false);
  const [notifyOtherVendors, setNotifyOtherVendors] = useState(false);
  const [addDeliveryRecord, setAddDeliveryRecord] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  useEffect(() => {
    if (visible) {
      setIsDateSpecified(!!requestedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const navigateAfterSubmit = useCallback(
    (
      result: boolean | ReleaseReassignmentFieldsFragment[],
      reassignedReleases: ReleaseReassignmentFieldsFragment[],
    ) => {
      if (result) {
        if (typeof result === "boolean" || reassignedReleases.length) {
          const currentIndex = reassignedReleases.findIndex(
            (r) => r.id === release?.id,
          );
          if (
            currentIndex !== -1 &&
            currentIndex < reassignedReleases.length - 1
          ) {
            setStep(currentIndex + 1);
            navigate(
              generatePath(routes.specifyDeliveryDetails, {
                deliveryId: reassignedReleases[currentIndex + 1].id,
              }),
            );
          } else {
            navigate(
              generatePath(routes.delivery, { deliveryId: release?.id }),
            );
          }
        } else {
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: result[0].id,
            }),
          );
        }
      }
    },
    [navigate, release?.id, setStep],
  );

  const displayDialog = useCallback(
    (
      result: boolean | ReleaseReassignmentFieldsFragment[],
      release?: ReleaseFieldsFragment | undefined | null,
      reassignedReleases: ReleaseReassignmentFieldsFragment[] = [],
    ) => {
      setSaving(false);
      setVisible(false);
      if (result && release) {
        updateReleaseUpdateOptions({ requestedTime: null });
        setHasChanges(false);

        triggerAlert(ReleaseAlertType.Submit, {
          isSplit: !!reassignedReleases.length,
          release: release,
        });
        setTimeout(() => {
          navigateAfterSubmit(result, reassignedReleases);
        }, DIALOG_AUTO_CLOSE_TIMER);
      }
    },
    [
      navigateAfterSubmit,
      setHasChanges,
      setVisible,
      triggerAlert,
      updateReleaseUpdateOptions,
    ],
  );

  const isFromActiveRfq = useMemo(() => {
    return release?.quote && release?.quote?.rfq?.status === RfqStatus.Active;
  }, [release]);

  const handleConfirm = async () => {
    const date = releaseUpdateOptions.requestedTime
      ? new Date(releaseUpdateOptions.requestedTime)
      : requestedDate;
    if (!callback) {
      if (params.customCallback) {
        const result = await params.customCallback({ requestedDate: date });
        setVisible(!result);
      } else {
        setVisible(false);
      }
      return;
    }

    if (!date && !release?.timeTBD) {
      setInputError(ReleaseErrorType.REQUESTED_FULFILLMENT_DATE);
      setWarningAlert(
        intl.$t({ id: "DELIVERY_SUBMIT_WARNING_NO_RELEASE_DATE" }),
      );
      return false;
    }

    const input = await getSyncedRelease();
    const updateReleaseTime =
      !!releaseUpdateOptions.requestedTime && !!release?.id;
    await updateRelease({
      ...input,
      ...(updateReleaseTime
        ? { requestedTime: date?.getTime(), timeTBD: false }
        : {}),
    });
    setSaving(true);

    if (isFromActiveRfq) {
      acceptQuote({
        rfqId: release?.quote?.rfq?.id ?? "",
        quoteId: release?.quote?.id ?? "",
        createBuyout: false,
        notifyOtherVendors,
      });
    }

    const result = await callback({
      skip:
        (release?.vendorContacts || []).length || isWarehouseRelease(release)
          ? skipConfirmation
          : true,
      receive: retroactiveRelease || undefined,
      addDeliveryRecord,
      callback: (result, release, reassignedReleases = []) =>
        displayDialog(result, release, reassignedReleases),
      skipVendorNotification: (release?.vendorContacts || []).length
        ? skipVendorNotification
        : true,
      schedule,
    });

    if (result) {
      displayDialog(result);
    }
  };

  const splitItems = useMemo(() => {
    return release?.items.reduce((acc: number, item) => {
      if (
        item.sellerOrgLocation?.id &&
        item.sellerOrgLocation?.id !== release.sellerOrgLocation?.id
      ) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [release]);

  const newOrders = useMemo(() => {
    const locations = release?.items.reduce((acc, item) => {
      if (
        item.sellerOrgLocation?.id &&
        item.sellerOrgLocation?.id !== release.sellerOrgLocation?.id &&
        !acc.includes(item.sellerOrgLocation?.id)
      ) {
        acc.push(item.sellerOrgLocation?.id);
      }
      return acc;
    }, [] as string[]);
    return locations?.length;
  }, [release]);

  const onDateConfirm = async (date: Date | null | undefined) => {
    const isTBD = date === undefined;
    setRequestedDate(date ?? null);
    const currentRelease = params.release ?? release;

    if (currentRelease) {
      const input: UpdateContractorReleaseInput = {
        releaseId: currentRelease?.id,
        version: currentRelease?.version,
      };

      input.requestedTime = !isTBD && date ? date.getTime() : undefined;
      input.timeTBD = isTBD;

      await updateRelease(input);
    }
  };

  useEffect(() => {
    if (visible) {
      setSkipVendorNotification(false);
      setSaving(false);
    }
  }, [visible, retroactiveRelease]);

  useEffect(() => {
    setSkipConfirmation(skipVendorNotification);
  }, [skipVendorNotification]);

  return (
    <Dialog
      title={params.title ?? dialogTitle}
      includeWarningIcon
      saving={saving || updating || syncingReleaseItems}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      disabledConfirmButton={params.disabledConfirmButton}
      confirmButtonText={params.confirmButtonText ?? confirmButtonText}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      buttonsConfiguration={{
        confirm: {
          wide: !splitItems,
        },
        cancel: {
          wide: !splitItems,
        },
      }}
      loading={false}
      show={visible}
      content={
        <>
          <Container>
            <If isTrue={supplierInsuranceInfoIsVisible && insuranceExpired}>
              <InsuranceInfoContainer>
                <FormattedMessage id="VENDORS_INSURANCE_EXPIRED" />
                <PrimaryButton
                  $small
                  onClick={() => setModalOpened(true)}
                  className="!h-8"
                >
                  <FormattedMessage id="INSPECT" />
                </PrimaryButton>
              </InsuranceInfoContainer>
              {vendor ? (
                <SupplierInsuranceModal
                  modalOpened={modalOpened}
                  closeModal={closeModal}
                  vendorId={vendor.sellerOrgLocation.id}
                />
              ) : null}
            </If>
            <If
              isTrue={params.includeVendorConfirmation && !retroactiveRelease}
            >
              <InfoText>
                <FormattedMessage id="NOTIFY_VENDOR_ABOUT_ORDER" />
                <Switch
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  value={!skipVendorNotification}
                  onChange={() =>
                    setSkipVendorNotification((prevValue) => !prevValue)
                  }
                />
                <If isTrue={!skipVendorNotification}>
                  <FormattedMessage id="REQUIRE_VENDOR_CONFIRM_ORDER" />
                  <Switch
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    value={!skipConfirmation}
                    onChange={() =>
                      setSkipConfirmation((prevValue) => !prevValue)
                    }
                  />
                </If>
                <If isTrue={isFromActiveRfq}>
                  <FormattedMessage id="NOTIFY_VENDORS_THEIR_QUOTE_NOT_SELECTED" />
                  <Switch
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    value={notifyOtherVendors}
                    onChange={() =>
                      setNotifyOtherVendors((prevValue) => !prevValue)
                    }
                  />
                </If>
              </InfoText>
              <If isTrue={skipConfirmation}>
                <AdditionalInfo className="mt-2">
                  <FormattedMessage id="ORDER_WILL_TRANSITION_AUTOMATICALLY_TO_SCHEDULED" />
                </AdditionalInfo>
              </If>
            </If>
            <If
              isTrue={
                !params.includeVendorConfirmation &&
                !retroactiveRelease &&
                !release?.sourceWarehouse
              }
            >
              <InfoTextNoBg>
                <InfoOutlined className="h-5 w-5" />
                <FormattedMessage id="SCHEDULE_ORDER_DESCRIPTION" />
              </InfoTextNoBg>
            </If>
            <If
              isTrue={retroactiveRelease && release?.type?.requireDeliverySlip}
            >
              <InfoText>
                <FormattedMessage id="INCLUDE_DELIVERY_RECORD" />
                <Switch
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  value={addDeliveryRecord}
                  onChange={() => setAddDeliveryRecord(!addDeliveryRecord)}
                />
              </InfoText>
            </If>
            <If isTrue={splitItems}>
              <FooterText>
                <InfoIconStyled />
                <FormattedMessage
                  id="VENDOR_REASSIGN_ITEMS_DETAILS"
                  values={{ itemsCount: splitItems }}
                />{" "}
                <FormattedMessage
                  id="ORDER_WITH_COUNT"
                  values={{ ordersCount: newOrders }}
                />
              </FooterText>
            </If>
          </Container>
          <If isTrue={!isDateSpecified}>
            <DateTimeContainer className="mt-3">
              <ReleaseDateTimePicker
                value={requestedDate}
                onChange={onDateConfirm}
                label={
                  params.dateLabelText ?? orderTypeConfig.labels.datePicker
                }
                prefillIfTbd
              />
            </DateTimeContainer>
          </If>
        </>
      }
    />
  );
};

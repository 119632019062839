import { DEFAULT_SOURCES } from "@/common/components/spreadsheet-table/constants/tableConstants";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import {
  SpreadSheetConfig,
  useColumnMapper,
} from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getCellValueWithPrefill } from "@/common/components/spreadsheet-table/utils/getCellValueWithPrefill";
import { setPrefillCell } from "@/common/components/spreadsheet-table/utils/setPrefillCell";
import { getInventoryItemUnitPrice } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemUnitPrice";
import Handsontable from "handsontable";
import { CellChange, ChangeSource } from "handsontable/common";
import { useCallback } from "react";

const getPriceColumnType = (config: SpreadSheetConfig[]) => {
  return config.some((c) => c.columnType === COLUMN_TYPE.PrefilledPrice)
    ? COLUMN_TYPE.PrefilledPrice
    : COLUMN_TYPE.UnitPrice;
};

const getQuantityColumnType = (config: SpreadSheetConfig[]) => {
  return config.some((c) => c.columnType === COLUMN_TYPE.PositiveQuantity)
    ? COLUMN_TYPE.PositiveQuantity
    : COLUMN_TYPE.Quantity;
};

export const useOrderTableHelpers = () => {
  const { getInventoryItem } = useTableHelpers();
  const { config } = useColumnMapper();

  const prefillQuantityChange = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hotInstance || !DEFAULT_SOURCES.includes(source)) {
        return;
      }
      const priceColumnType = getPriceColumnType(config);
      const quantityColumnType = getQuantityColumnType(config);
      changes?.forEach((change) => {
        const [row, column, , value] = change;
        if (column === quantityColumnType) {
          const inventoryItem = getInventoryItem({
            material: getCellValueWithPrefill(
              hotInstance,
              row,
              COLUMN_TYPE.Material,
            ),
            uom: getCellValueWithPrefill(hotInstance, row, COLUMN_TYPE.UOM),
            supplier: getCellValueWithPrefill(
              hotInstance,
              row,
              COLUMN_TYPE.Supplier,
            ),
          });
          if (!inventoryItem) {
            return;
          }

          const unitPrice = getInventoryItemUnitPrice(inventoryItem, -value);
          setPrefillCell(hotInstance, row, priceColumnType, unitPrice);
        }
      });
    },
    [getInventoryItem, config],
  );

  return {
    prefillQuantityChange,
  };
};

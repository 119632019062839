import { ReactNode } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../../../if/If";

const Container = tw.div`py-2 bg-gray-200 sticky z-10 -top-2 pl-6`;
const ListItem = tw.li``;
const List = tw.ul``;

export const GroupRenderer = (params: {
  key: number;
  children?: ReactNode;
  group: string;
}) => {
  const intl = useIntl();

  return (
    <ListItem key={params.key}>
      <If isTrue={params.group}>
        <Container>
          {params.group ? intl.$t({ id: params.group }) : ""}
        </Container>
      </If>
      <List>{params.children}</List>
    </ListItem>
  );
};

import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import {
  Base,
  LgFixedContainer,
  SmdFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { ExternalVendorFieldsFragment } from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  ${({ $disabled }: { $disabled?: boolean }) => ($disabled ? "opacity-50" : "")}
`;
const PhoneContainer = tw(SmdFixedContainer)``;
const EmailContainer = tw(Base)`
  col-span-full flex-1
`;

export const useExternalVendorsTableConfiguration = (
  selectedItems?: string[],
): Array<GridCol<ExternalVendorFieldsFragment & Identity>> => {
  return [
    {
      header: <FormattedMessage id="NAME" />,
      wrapper: LgFixedContainer,
      item: ({ item, index, count }) => (
        <SelectableItem
          item={item}
          index={index}
          selectedItems={selectedItems ?? []}
          itemNameSelectorFn={(item) => item.name}
          count={count}
        />
      ),
    },
    {
      header: <FormattedMessage id="EMAIL" />,
      wrapper: EmailContainer,
      item: ({ item }) => <Container>{item.email}</Container>,
      position: "start",
    },
    {
      header: <FormattedMessage id="PHONE" />,
      wrapper: PhoneContainer,
      item: ({ item }) => <Container>{item.phone}</Container>,
    },
    {
      header: <FormattedMessage id="ADDRESS" />,
      wrapper: LgFixedContainer,
      item: ({ item }) => (
        <Container>
          {item.address?.addressLine1
            ? `${item.address?.addressLine1.trim()} `
            : ""}
          {item.address?.addressLine2
            ? `${item.address?.addressLine2.trim()}, `
            : ""}
          {item.address?.city ? `${item.address.city.trim()}, ` : ""}
          {item.address?.state ? `${item.address.state} ` : ""}
          {item.address?.postalCode ? `${item.address.postalCode.trim()}` : ""}
        </Container>
      ),
    },
  ];
};

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { isNumber } from "lodash";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../../value-currency/hooks/useFormatNumberToCurrency";
import { useTableHelpers } from "../../hooks/useTableHelpers";
import { getPhysicalColumnIndex } from "../../utils/getPhysicalColumnIndex";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { useRenderHelpers } from "../helpers/useRenderHelpers";
import { applyClasses } from "../utils/applyClasses";
import { applyTooltip } from "../utils/applyTooltip";
import { isValueEmpty } from "../utils/isValueEmpty";

const priceTooltipInfo = (
  buyoutItem: boolean,
  price: { isVendorPrice: boolean; isEstimatedPrice: boolean },
) => {
  if (buyoutItem) {
    return "BUYOUT_ITEM_PRICE_NOTICE";
  } else if (price.isVendorPrice) {
    return "VENDOR_PRICE_NOTICE";
  } else if (price.isEstimatedPrice) {
    return "ESTIMATED_PRICE_NOTICE";
  }
  return null;
};

export const usePrefillPriceRenderer = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { getPrefilledValue, getBuyoutItem, getInventoryItem } =
    useTableHelpers();
  const { formatPrice, addIconWithTooltip } = useRenderHelpers();
  const baseRenderer = useBaseRenderer();
  const { calcExtPrice } = usePriceCalculation();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      if (isValueEmpty(value)) {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
        return;
      }

      const rowData = instance?.getDataAtRow(row);
      const formattedValue = formatPrice(rowData, instance, value);
      baseRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      applyClasses(td, "pr-4");

      const uomIndex = getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM);
      const materialIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Material,
      );
      const vendorIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Supplier,
      );
      const supplierIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Supplier,
      );
      const manufacturerIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Manufacturer,
      );
      const buyoutItemIdIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.BuyoutItemId,
      );
      const quantityIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Quantity,
      );
      const prefilledPriceIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.PrefilledPrice,
      );
      const extPriceColumnIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.ExtPrice,
      );

      const uom = rowData[uomIndex];
      if (isLumpSumUomText(uom)) {
        return;
      }

      const price = getPrefilledValue({
        material: rowData[materialIndex],
        vendor: rowData[vendorIndex] || rowData[supplierIndex],
        manufacturer: rowData[manufacturerIndex],
        uom: rowData[uomIndex],
      });

      const buyoutItem = getBuyoutItem({
        id: rowData[buyoutItemIdIndex],
        material: rowData[materialIndex],
        uom: rowData[uomIndex],
      });

      const inventoryItem = getInventoryItem({
        material: rowData[materialIndex],
        uom: rowData[uomIndex],
        supplier: rowData[supplierIndex],
      });

      const tooltipText = priceTooltipInfo(!!buyoutItem, price);
      if (
        !inventoryItem &&
        value &&
        new DecimalSafe(price.value).equals(new DecimalSafe(value)) &&
        tooltipText
      ) {
        addIconWithTooltip({
          element: td,
          tooltipText: intl.$t({
            id: tooltipText,
          }),
          iconClasses: buyoutItem ? "text-green-800 hover:text-green-600" : "",
        });
      }

      if (extPriceColumnIndex !== -1) {
        return;
      }

      const extendedPrice = calcExtPrice(
        rowData[quantityIndex],
        rowData[prefilledPriceIndex],
      );
      if (extendedPrice && isNumber(extendedPrice)) {
        const extPriceEl = document.createElement("div");
        extPriceEl.className = "text-gray-500 text-3xs select-none mb-1";
        const formattedExtPrice = formatCurrency(extendedPrice);
        extPriceEl.innerText = intl.$t(
          { id: "EXT_WITH_PRICE" },
          { price: formattedExtPrice },
        );
        td.appendChild(extPriceEl);

        applyTooltip(
          extPriceEl,
          intl.$t({ id: "EXT_PRICE_TOOLTIP" }, { price: formattedExtPrice }),
        );
      }
    },
    [
      formatPrice,
      getPrefilledValue,
      getBuyoutItem,
      intl,
      formatCurrency,
      addIconWithTooltip,
      calcExtPrice,
      baseRenderer,
      getInventoryItem,
    ],
  );

  return renderer;
};

import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { hasProperty } from "@/common/utils/objectUtils";
import { ComplianceGroup } from "@/generated/graphql";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

type ComplianceGroupType =
  | Partial<Pick<ComplianceGroup, "code" | "description" | "id">>
  | string
  | null
  | undefined;

export const useComplianceGroups = () => {
  const { complianceGroups, loading } = useStartupDataStore(
    useShallow((state) => ({
      complianceGroups: state.complianceGroups,
      loading: state.loading,
    })),
  );

  const trimDescription = useCallback((description: string) => {
    return description.replace(/\s+/g, " ").trim();
  }, []);

  const formatComplianceGroup = useCallback(
    (complianceGroup: ComplianceGroupType) => {
      if (!complianceGroup) {
        return "";
      }
      if (typeof complianceGroup === "string") {
        const group = complianceGroups?.find(
          (group) =>
            group.code.toLowerCase() === complianceGroup.toLowerCase() ||
            trimDescription(group.description ?? "").toLowerCase() ===
              complianceGroup.toLowerCase() ||
            complianceGroup === group.id,
        );

        return group
          ? `${trimDescription(group?.description ?? "")} (${group?.code})`
          : complianceGroup;
      } else if (
        typeof complianceGroup === "object" &&
        hasProperty(complianceGroup, "id")
      ) {
        const group = complianceGroups?.find(
          (group) => group.id === complianceGroup.id,
        );
        return `${trimDescription(group?.description ?? "")} (${group?.code})`;
      }

      return `${trimDescription(complianceGroup.description ?? "")} (${complianceGroup.code})`;
    },
    [complianceGroups, trimDescription],
  );

  return {
    complianceGroups,
    loading,
    formatComplianceGroup,
  };
};

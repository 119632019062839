import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { SpreadSheetConfig } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import {
  Base,
  MdFixedContainer,
  SmFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { Connections } from "../../../../../home/projects/components/Connections";
import { CostCode } from "../providers/CostCodesListProvider";
import { CostCodeActions } from "./CostCodeActions";
import { CostCodeInput } from "./CostCodeInput";
import { CostCodeLinkLedgerAccount } from "./CostCodeLinkLedgerAccount";

const ActionsWrapper = tw(SmFixedContainer)`
  absolute right-2 top-0 lg:relative 
`;

const WideContainer = tw(Base)`
  lg:basis-auto lg:w-5/12 2xl:basis-auto 2xl:w-6/12
  `;

export const useCostCodesConfiguration = () => {
  const intl = useIntl();
  const { hasFeatureInConnectedSourceSystem, connectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const configuration: Array<GridCol<CostCode>> = useMemo(
    () => [
      {
        wrapper: WideContainer,
        header: <FormattedMessage id="DESCRIPTION" />,
        item: ({ item, editMode, rowError }) => (
          <CostCodeInput
            code={item}
            error={rowError}
            key={item.id}
            prop="description"
            placeholder="DESCRIPTION"
            readonly={!editMode && item.id !== ""}
          />
        ),
        sortItemFn: (item1: CostCode, item2: CostCode) =>
          item1.description.localeCompare(item2.description),
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="COST_CODE" />,
        item: ({ item, editMode, rowError }) => (
          <CostCodeInput
            code={item}
            error={rowError}
            key={item.id}
            prop="code"
            placeholder="COST_CODE"
            required
            readonly={!editMode && item.id !== ""}
          />
        ),
        sortItemFn: (item1: CostCode, item2: CostCode) =>
          item1.code.localeCompare(item2.code),
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="IMPORTED_FROM" />,
        item: ({ item }) => <Connections connections={item.mappings} />,
        position: "center",
      },
      {
        wrapper: MdFixedContainer,
        header: (
          <>
            <FormattedMessage id="GL_ACCOUNT_NUMBER" />
            <InfoTooltip
              message={intl.$t({ id: "GL_ACCOUNT_LINK_DESCRIPTION" })}
              iconClassName="ml-1"
            />
          </>
        ),
        item: ({ item }) =>
          !!connectedSourceSystem && item.id !== "" ? (
            <CostCodeLinkLedgerAccount
              costCode={item}
              sourceSystem={connectedSourceSystem}
            />
          ) : null,
        hidden: !hasFeatureInConnectedSourceSystem(
          IntegrationFeature.GLAccountForCostCodes,
        ),
      },
      {
        wrapper: ActionsWrapper,
        item: ({ item, editMode, setEditMode }) => {
          return (
            <CostCodeActions
              code={item}
              readonly={!editMode && item.id !== ""}
              setEditMode={setEditMode}
            />
          );
        },
        position: "center",
      },
    ],
    [hasFeatureInConnectedSourceSystem, connectedSourceSystem, intl],
  );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "DESCRIPTION" }),
        columnId: "description",
        columnType: COLUMN_TYPE.CodeDescription,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "code",
        columnType: COLUMN_TYPE.Code,
      },
    ],
    [intl],
  );

  return { configuration, spreadsheetConfig };
};

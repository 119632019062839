import { SeededRandom } from "@/common/utils/seedRadom";
import { useProjectTags } from "@/contractor/pages/home/project/hooks/useProjectTags";
import { TagExtendedFieldsFragment } from "@/generated/graphql";
import { darken, getLuminance, lighten } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { TAG_COLORS } from "../../../tag-picker/TagColors";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { applyClasses } from "../utils/applyClasses";
import { applyTooltip } from "../utils/applyTooltip";
import { isValueEmpty } from "../utils/isValueEmpty";

type Tag = {
  name: string;
  color: string;
  mapping?: {
    sourceSystem?: unknown;
  };
};

export const useTagRenderer = () => {
  const intl = useIntl();
  const { tags, loading } = useProjectTags();
  const baseRenderer = useBaseRenderer();

  const tagsMap = useMemo(() => {
    return tags.reduce(
      (acc, tag) => {
        acc[tag.name] = tag;
        return acc;
      },
      {} as Record<string, TagExtendedFieldsFragment>,
    );
  }, [tags]);

  const getBackgroundColor = useCallback(
    (tag: Tag | string, isImported?: boolean): string => {
      if (isImported) {
        return "white";
      }
      if (tag && typeof tag !== "string" && tag.color) {
        return lighten(tag.color, 0.8 - getLuminance(tag.color));
      }
      return "gray";
    },
    [],
  );

  const getTextColor = useCallback((tag: Tag): string => {
    if (tag && tag.color) {
      return darken(tag.color, 0.65);
    }
    return "darkgray";
  }, []);

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      baseRenderer(instance, td, row, col, prop, value, cellProperties);
      if (isValueEmpty(value)) {
        return;
      }

      const tag = tagsMap[value];
      const isImported = Boolean(tag?.mapping?.sourceSystem);

      const tagContainer = document.createElement("div");
      const tagNameEl = document.createElement("div");

      tagContainer.className = `relative inline-flex py-1 pl-2 pr-3 rounded-l rounded-r-4xl text-white font-normal items-center justify-between whitespace-nowrap w-fit max-w-[80%] ${
        isImported ? "border border-l-4" : ""
      }`;
      tagNameEl.className = "truncate text-xs font-medium";

      const random = new SeededRandom(value);
      const computedColor =
        tag?.color ?? TAG_COLORS[random.nextInt(TAG_COLORS.length)];

      const displayName = tag?.name ?? value;

      const bgColor = getBackgroundColor(
        { name: displayName, color: computedColor },
        isImported,
      );
      tagContainer.style.borderColor = bgColor;
      tagContainer.style.backgroundColor = bgColor;
      tagContainer.style.color = getTextColor({
        name: displayName,
        color: computedColor,
      });

      tagNameEl.innerText = value;
      td.textContent = "";
      tagContainer.appendChild(tagNameEl);
      td.appendChild(tagContainer);

      if (!tag && !loading) {
        applyClasses(td, "text-blue-800 bg-blue-100");
        const sup = document.createElement("sup");
        sup.className = "text-blue-500 font-semibold text-[8px] ml-1";
        sup.innerText = intl.$t({ id: "NEW" });
        tagContainer.appendChild(sup);
      }

      applyTooltip(tagContainer, value);
    },
    [baseRenderer, getBackgroundColor, getTextColor, intl, loading, tagsMap],
  );

  return renderer;
};

import { ReleaseExportStatusEnum } from "../types/ReleaseExportStatusEnum";

type Status = {
  color: string;
  label: string;
  tooltip?: string;
  includeDate?: boolean;
};

export const RELEASE_EXPORT_STATUSES: Record<ReleaseExportStatusEnum, Status> =
  {
    [ReleaseExportStatusEnum.OK]: {
      color: "green",
      label: "LAST_EXPORT_WITH_DATE",
      includeDate: true,
    },
    [ReleaseExportStatusEnum.Outdated]: {
      color: "orange",
      label: "MODIFIED_AFTER_SYNC_WITH_DATE",
      includeDate: true,
    },
    [ReleaseExportStatusEnum.ExportIssue]: {
      color: "red",
      label: "EXPORT_ISSUE_WITH_DATE",
      includeDate: true,
    },
    [ReleaseExportStatusEnum.Syncing]: {
      color: "orange",
      label: "SYNCING_WITH_DATE",
      includeDate: false,
    },
    [ReleaseExportStatusEnum.SyncIssue]: {
      color: "red",
      label: "SYNC_ISSUE_WITH_DATE",
      includeDate: true,
    },
    [ReleaseExportStatusEnum.BackgroundExportIssue]: {
      color: "red",
      label: "LAST_EXPORT_WITH_DATE",
      tooltip: "BACKGROUND_EXPORT_ISSUE",
      includeDate: true,
    },
    [ReleaseExportStatusEnum.PoExists]: {
      color: "blue",
      label: "NOT_CONNECTED",
    },
    [ReleaseExportStatusEnum.NoExport]: {
      color: "gray",
      label: "PO_SET_NOT_TO_BE_EXPORTED",
    },
  };

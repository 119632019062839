import { MAX_QUANTITY_DECIMALS, MIN_QUANTITY_DECIMALS } from "@/common/const";
import { UomFieldsFragment } from "@/generated/graphql";
import React, { FC } from "react";
import { FormattedNumber } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { Price } from "../price/Price";
import { Tooltip } from "../tooltip/Tooltip";

type ValueUnitProps = {
  value: string | number | null | undefined;
  uom?: UomFieldsFragment | null;
  label?: string;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  classNames?: { container?: string; item?: string; footer?: string };
  error?: boolean;
  includeMnemonic?: boolean;
  mnemonicFirst?: boolean;
  renderAsPrice?: boolean;
  showNegativeOrPositive?: boolean;
  minimumFractionDigits?: number;
  tooltip?: string;
  showTilde?: boolean;
  size?: "xs" | "sm";
};

const Container = tw.div<{
  $error?: boolean;
  $showNegativeOrPositive?: boolean;
  $size?: "xs" | "sm";
}>`
  flex flex-col justify-center items-center relative w-full text-sm
  ${(props) => props.$error && "text-red-500"}
  ${(props) =>
    props?.$showNegativeOrPositive === true
      ? "text-green-600"
      : props?.$showNegativeOrPositive === false
        ? "text-red-500"
        : ""}
  ${(props) => props.$size === "xs" && "text-xs"}
`;

const HeaderContainer = tw.div`
  text-2xs left-3 top-0 w-full
`;

const Header = tw.div`
  text-gray-600 left-1/2 transform -translate-x-1/2
`;

const FooterContainer = tw.div`
  text-[9px] w-full line-clamp-2 text-center relative text-gray-600
`;

export const ValueUnit: FC<ValueUnitProps> = ({
  value,
  uom,
  includeMnemonic,
  mnemonicFirst = true,
  footer = !includeMnemonic
    ? mnemonicFirst
      ? uom?.mnemonic || uom?.pluralDescription
      : uom?.pluralDescription || uom?.mnemonic
    : "",
  header,
  classNames,
  error,
  renderAsPrice,
  showNegativeOrPositive,
  minimumFractionDigits,
  tooltip,
  showTilde = false,
  size = "sm",
}) => {
  const val = Number(value);

  if (uom?.mnemonic || footer) {
    return (
      <Tooltip
        element={
          <Container $error={error}>
            <HeaderContainer>
              <Header>{header || null}</Header>
            </HeaderContainer>
            <Container
              className={classNames?.container}
              $showNegativeOrPositive={showNegativeOrPositive}
              $size={size}
            >
              <If isTrue={showTilde}>{`~ `}</If>
              <If isTrue={showNegativeOrPositive}>
                <If isTrue={val < 0}>-</If>
                <If isTrue={val > 0}>+</If>
              </If>
              {renderAsPrice ? (
                <Price price={val} />
              ) : (
                <FormattedNumber
                  value={val}
                  maximumFractionDigits={MAX_QUANTITY_DECIMALS}
                  minimumFractionDigits={MIN_QUANTITY_DECIMALS}
                />
              )}{" "}
              {includeMnemonic && uom?.mnemonic}
            </Container>
            <FooterContainer className={classNames?.footer}>
              {footer || null}
            </FooterContainer>
          </Container>
        }
        className="w-full"
        id={`value-unit-${uom?.mnemonic}`}
        hideTooltip={!uom?.pluralDescription && !tooltip}
      >
        {tooltip ?? uom?.pluralDescription}
      </Tooltip>
    );
  }

  return (
    <Container $error={error} $size={size}>
      <HeaderContainer>
        <Header>{header || null}</Header>
      </HeaderContainer>
      <Container className={classNames?.item}>
        <FormattedNumber
          value={val}
          minimumFractionDigits={minimumFractionDigits}
          maximumFractionDigits={MAX_QUANTITY_DECIMALS}
        />{" "}
        {mnemonicFirst ? uom?.mnemonic : uom?.pluralDescription}
      </Container>
      <FooterContainer className={classNames?.footer}>
        {footer || null}
      </FooterContainer>
    </Container>
  );
};

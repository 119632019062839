import { routes } from "@/config/routes";
import { COLUMN_TYPE } from "../../../enums/columnType";

const defaultReleaseDisabledColumns = [
  COLUMN_TYPE.Manufacturer,
  COLUMN_TYPE.Tag,
  COLUMN_TYPE.CostType,
  COLUMN_TYPE.Notes,
];

export const disabledColumns = {
  [routes.specifyDeliveryDetails]: [],
  [routes.invoiceVerification]: defaultReleaseDisabledColumns,
  [routes.deliverySlipVerification]: defaultReleaseDisabledColumns,
  [routes.editDeliveryFromNote]: defaultReleaseDisabledColumns,
  [routes.editDeliveryFromQuote]: defaultReleaseDisabledColumns,
};

import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import {
  OrgPreferredVendorsFieldsFragment,
  WarehouseFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { vendorLabelFormatter } from "../../supplier-picker/utils/vendorLabelFormatter";
import { useVendors } from "../../vendors/hooks/useVendors";

export const useTableMaps = () => {
  const { vendors, getVendorCode } = useVendors();
  const { warehouses } = useWarehouses();

  const vendorByNameMap = useMemo(() => {
    return vendors.reduce<Record<string, OrgPreferredVendorsFieldsFragment>>(
      (acc, vendor) => {
        const vendorName = vendorLabelFormatter(vendor.sellerOrgLocation, [], {
          vendorCode: getVendorCode(vendor),
        });
        acc[vendorName] = {
          ...vendor,
        };
        return acc;
      },
      {},
    );
  }, [vendors, getVendorCode]);

  const warehouseByNameMap = useMemo(() => {
    return warehouses.reduce<Record<string, WarehouseFieldsFragment>>(
      (acc, warehouse) => {
        acc[warehouse.name] = warehouse;
        return acc;
      },
      {},
    );
  }, [warehouses]);

  return {
    vendorByNameMap,
    warehouseByNameMap,
  };
};

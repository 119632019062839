export enum InvoiceFooterState {
  DEFAULT = "DEFAULT",
  KICKBACK = "KICKBACK",
  MATCH_ORDER = "MATCH_ORDER",
  CREATE_ORDER = "CREATE_ORDER",
  EDIT_INVOICE_COVERAGES = "EDIT_INVOICE_COVERAGES",
  EDIT_ORDER = "EDIT_ORDER",
  IMPORT_ORDER = "IMPORT_ORDER",
  HEADER_ONLY_INVOICE = "HEADER_ONLY_INVOICE",
  HEADER_ONLY_INVOICE_EDITABLE = "HEADER_ONLY_INVOICE_EDITABLE",
}

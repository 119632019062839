import { SearchOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = { onClick: () => void; textId?: string };

const Link = tw.div`
  fixed left-0 z-[200] text-blue-800 cursor-pointer bottom-0 pb-5 group/add-item
`;

const AddItemsContainer = tw.div`flex gap-1.5 relative pl-4 items-center text-xs pt-3 pointer-events-none
  group-hover/add-item:text-blue-100 transition-all
`;

const AddItemsText = tw.div`break-words w-10 font-normal text-xs
  group-hover/add-item:font-semibold pointer-events-none
`;

const AddItemCircle = tw.div`absolute -left-7 -bottom-12 h-33 w-33 bg-orange-500 rounded-full
  group-hover/add-item:scale-125
  group-hover/add-item:opacity-90
  group-hover/add-item:shadow
  transition-all ease-in-out duration-300
  delay-75
`;

export const AddItemsButton: FC<Props> = ({ onClick, textId }) => {
  return (
    <Link onClick={onClick} data-testid="add-item-button">
      <AddItemCircle />
      <AddItemCircle className="pointer-events-none animate-[custom-ping_30s_ease-in-out_infinite]" />
      <AddItemsContainer>
        <SearchOutlined />
        <FormattedMessage
          id={textId || "LOOKUP_ITEMS"}
          tagName={AddItemsText}
        />
      </AddItemsContainer>
    </Link>
  );
};

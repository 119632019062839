import { PDF_FONT, PDF_LEFT_SPACING } from "@/common/const";
import { DistributorReleaseFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import { IntlShape } from "react-intl";

export const generalInstructions = (
  doc: jsPDF,
  release: DistributorReleaseFieldsFragment,
  intl: IntlShape,
  topSpacing: number,
) => {
  if (release.instructions && release.instructions.text.length > 0) {
    doc
      .setFont(PDF_FONT, "", "bold")
      .setFontSize(10)
      .text(intl.$t({ id: "INSTRUCTIONS" }), PDF_LEFT_SPACING, topSpacing);
    doc.setFont(PDF_FONT, "", "normal").setFontSize(8);
    const splitText = doc.splitTextToSize(
      release.instructions.text,
      doc.internal.pageSize.width - 40,
    );
    doc.text(splitText, PDF_LEFT_SPACING, topSpacing + 5);
    topSpacing +=
      doc.getTextDimensions(splitText, {
        fontSize: 8,
      }).h + 10;
  }
  return topSpacing;
};

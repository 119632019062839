import { HeaderContainerWithSecondaryNavigation } from "@/common/components/header-container/HeaderContainer";
import { If } from "@/common/components/if/If";
import {
  LocalPaginationProvider,
  useLocalPagination,
} from "@/common/components/pagination-local/LocalPaginationProvider";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { ColumnMapperProvider } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { useLocalSearch } from "@/common/hooks/useLocalSearch";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useOrgSettings } from "../../../org-settings/hooks/useOrgSettings";
import { useOrgSettingsExtended } from "../../../org-settings/hooks/useOrgSettingsExtended";
import { LoaderStyled } from "../common/CostStructure.styles";
import { AddTypeMenu } from "./components/AddTypeMenu";
import { CostTypeList } from "./components/CostTypeList";
import { useCostTypesConfiguration } from "./components/CostTypes.config";
import { CostTypesFooter } from "./components/CostTypesFooter";
import { ImportExternalCostTypesButton } from "./components/ImportExternalCostTypesButton";
import { ImportExternalCostTypesModal } from "./components/import-external-codes/ImportExternalCostCodesModal";
import {
  CostTypesListProvider,
  useCostTypesList,
} from "./providers/CostTypesListProvider";
import { ImportExternalCostTypesProvider } from "./providers/ImportExternalCostTypesProvider";
import { SyncCostTypesProvider } from "./providers/SyncCostTypesProvider";

const ITEMS_PER_PAGE = 24;

const HeaderRightPanel = tw.div`flex flex-row`;
const Filters = tw.div`
  grid grid-flow-col gap-5 items-start
`;
const SpreadSheetContainer = tw.div`pt-12`;
const Spacer = tw.div`h-12`;

const CostTypesWithProvider = () => {
  const intl = useIntl();
  const { costTypes } = useCostTypesList();
  const { spreadsheetConfig } = useCostTypesConfiguration();
  const viewState = useTableViewStore((state) => state.viewState);
  const { loading } = useOrgSettings();
  const { setSearchText, filteredItems, searchText } =
    useLocalSearch(costTypes);
  const { getPaginatedItems, setPage } = useLocalPagination();

  const currentCostTypes = useMemo(() => {
    const newItem = costTypes.find((c) => c.id === "");
    if (newItem && searchText) {
      return getPaginatedItems([newItem, ...filteredItems]);
    }
    return getPaginatedItems(filteredItems);
  }, [costTypes, filteredItems, getPaginatedItems, searchText]);

  const setSearchTextAndResetPage = useCallback(
    (text: string) => {
      setSearchText(text);
      setPage(0);
    },
    [setPage, setSearchText],
  );

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "COST_TYPES" })}</title>
      </Helmet>
      <LoaderStyled loading={loading}>
        <If isTrue={viewState === TableViewState.normal}>
          <HeaderContainerWithSecondaryNavigation>
            <Filters>
              <SearchInput
                placeHolder={intl.$t({ id: "SEARCH_COST_TYPE" })}
                onChange={setSearchTextAndResetPage}
              />
            </Filters>
            <HeaderRightPanel>
              <ImportExternalCostTypesButton />
              <AddTypeMenu />
            </HeaderRightPanel>
          </HeaderContainerWithSecondaryNavigation>
          <CostTypeList
            items={currentCostTypes}
            totalCount={filteredItems.length}
            loading={loading}
          />
        </If>
        <If isTrue={viewState === TableViewState.spreadsheet}>
          <SpreadSheetContainer>
            <SpreadSheetTable
              items={costTypes}
              columns={spreadsheetConfig}
              height="calc(100vh - 320px)"
            />
          </SpreadSheetContainer>
        </If>
        <Spacer />
        <CostTypesFooter />
        <ImportExternalCostTypesModal />
      </LoaderStyled>
    </>
  );
};

export const CostTypes = () => {
  const { spreadsheetConfig } = useCostTypesConfiguration();
  const { connectedSourceSystem } = useOrgSettingsExtended();
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.normal);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  return (
    <NestedStepperProvider>
      <CostTypesListProvider>
        <ImportExternalCostTypesProvider
          defaultSourceSystem={connectedSourceSystem?.system}
        >
          <LocalPaginationProvider itemsPerPage={ITEMS_PER_PAGE}>
            <ColumnMapperProvider config={spreadsheetConfig}>
              <SyncCostTypesProvider>
                <CostTypesWithProvider />
              </SyncCostTypesProvider>
            </ColumnMapperProvider>
          </LocalPaginationProvider>
        </ImportExternalCostTypesProvider>
      </CostTypesListProvider>
    </NestedStepperProvider>
  );
};

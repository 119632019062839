import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useRoles } from "@/common/components/org-roles-wrapper/hasRoles";
import { SourceSystemWrapper } from "@/common/components/source-system-wrapper/SourceSystemWrapper";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import { ATTACHMENTS_MIME_TYPES } from "@/common/components/upload/FileUploadArea";
import { useFMAdmin } from "@/common/hooks/useFMAdmin";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useUpdateOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useUpdateOrgSettings";
import { OrgRole, PoNumberingMode } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { Container } from "../../common/Organization.styles";
import { ItemizationSelector } from "./itemization-setting/ItemizationSelector";
import { decodeItemizationValue } from "./itemization-setting/useItemizationSelector";
import { OrderNotificationsSelector } from "./order-notifications-management/OrderNotificationsSelector";
import { OrgFulfillmentLocation } from "./org-fulfillment-location/OrgFulfillmentLocation";
import { OrgPaymentTerms } from "./org-payment-terms/OrgPaymentTerms";
import { OrgSettingsFormInput } from "./OrgSettingsFormHookProvider";
import { PoNumberingSelector } from "./po-management/PoNumberingSelector";
import { ProjectDefaultUsers } from "./project-default-users/ProjectDefaultUsers";
import { UseProjectsCostCodesSelector } from "./use-projects-cost-codes-selector/UseProjectsCostCodesSelector";
import { decodeUseProjectCostCodes } from "./use-projects-cost-codes-selector/useUseProjectsCostCodesSelector";

const SettingsContainer = tw.div`grid w-fit`;
const ItemContainer = tw.div<{
  $disabled?: boolean | null;
}>`
  grid grid-flow-col gap-x-25 w-full items-center justify-between border-b border-gray-200 py-4 
  ${({ $disabled }) => ($disabled ? "opacity-50" : "")}
`;
const SwitchContainer = tw.div`flex items-center space-x-2`;
const Label = tw.div``;

export const OrgSettingsForm = () => {
  const intl = useIntl();
  const { updating, updateOrgSettings } = useUpdateOrgSettings();
  const { setSuccessAlert } = useSnackbar();
  const { hasRoles } = useRoles({ roles: [OrgRole.OrgRestrictedAdmin] });
  const { isFMAdmin } = useFMAdmin();
  const { connectedSourceSystem, connectedIntegrationType } = useOrgSettings();
  const { handleSubmit, formState, reset, watch, setValue } =
    useFormContext<OrgSettingsFormInput>();
  const { setAssets } = useUploadAssets();
  const { settings } = useOrgSettings();

  const poNumbering = watch("settings.releases.poNumbering");

  const onCancelClick = useCallback(() => {
    reset();
    setAssets(settings?.releases?.instructions?.assets ?? []);
  }, [reset, setAssets, settings?.releases?.instructions?.assets]);

  const onSaveHandler = useCallback(
    async (values: OrgSettingsFormInput) => {
      let result = null;
      if (values.id) {
        const defaultItemized = decodeItemizationValue(
          values?.settings?.display?.defaultItemized,
        );
        const receiptDefaultItemized = decodeItemizationValue(
          values?.settings?.display?.receiptDefaultItemized,
        );
        result = await updateOrgSettings({
          id: values.id,
          display: {
            enableManufacturers:
              values.settings?.display?.enableManufacturers ?? false,
            itemCostTypes: values.settings?.display?.itemCostTypes ?? false,
            enableAdditionalCharges:
              values.settings?.display?.enableAdditionalCharges ?? false,
            requireProjectAssignment:
              values.settings?.display?.requireProjectAssignment ?? false,
            enableTaxVariance:
              values.settings?.display?.enableTaxVariance ?? false,
            defaultItemized,
            clearDefaultItemized: defaultItemized == null,
            receiptDefaultItemized,
            clearReceiptDefaultItemized: receiptDefaultItemized == null,
            usesProjectCostCodes: decodeUseProjectCostCodes(
              values?.settings?.display?.usesProjectCostCodes,
            ),
            appendExternalCode: values.settings?.display?.appendExternalCode,
          },
          releases: {
            poNumbering: values.settings?.releases?.poNumbering,
            notifications: values.settings?.releases?.notifications,
            useSourceSystemPOs: values.settings?.releases?.useSourceSystemPOs,
            defaultWarehouseId: values.settings?.releases?.defaultWarehouseId,
            clearDefaultWarehouse:
              values.settings?.releases?.clearDefaultWarehouse,
            instructions: values.settings.releases.instructions,
          },
          invoices: {
            defaultPaymentTerm: values.settings?.invoices?.defaultPaymentTerm,
            clearDefaultPaymentTerm:
              values.settings?.invoices?.clearDefaultPaymentTerm,
            requireCostCodesDuringApproval:
              !!values.settings?.invoices?.requireCostCodesDuringApproval,
          },
          project: {
            defaultTeam: values.settings.project.defaultTeam,
          },
          inventory: {
            enabled: values.settings.inventory.enabled,
          },
        });
      }

      if (!result) {
        return;
      }
      setSuccessAlert(intl.$t({ id: "ORG_SETTINGS_UPDATE_SUCCESS" }));
    },
    [setSuccessAlert, intl, updateOrgSettings],
  );

  const connectedSystem = useMemo(() => {
    if (connectedSourceSystem) {
      return intl.$t({ id: `SOURCE_SYSTEM_${connectedSourceSystem}` });
    }
    if (connectedIntegrationType) {
      return intl.$t({ id: `INTEGRATION_${connectedIntegrationType}` });
    }
    return "";
  }, [connectedIntegrationType, connectedSourceSystem, intl]);

  return (
    <Container>
      <SettingsContainer>
        <If isTrue={isFMAdmin}>
          <ItemContainer>
            <Label>
              <FormattedMessage id="ENABLE_INVENTORY_MANAGEMENT" />
            </Label>
            <SwitchContainer>
              <SwitchControlled
                name="settings.inventory.enabled"
                width={60}
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
              />
            </SwitchContainer>
          </ItemContainer>
        </If>
        <If isTrue={!hasRoles}>
          <ItemContainer>
            <Label>
              <FormattedMessage id="ENABLE_MANUFACTURERS_ASSIGNMENT" />
            </Label>
            <SwitchContainer>
              <InfoTooltip
                message={intl.$t({
                  id: "ENABLE_MANUFACTURERS_ASSIGNMENT_TOOLTIP",
                })}
              />
              <SwitchControlled
                name="settings.display.enableManufacturers"
                width={60}
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
              />
            </SwitchContainer>
          </ItemContainer>
        </If>
        <ItemContainer>
          <Label>
            <FormattedMessage id="PO_MANAGEMENT" />
          </Label>
          <PoNumberingSelector />
        </ItemContainer>
        <If isTrue={poNumbering !== PoNumberingMode.Never}>
          <SourceSystemWrapper anyIntegrationType anySourceSystem>
            <ItemContainer>
              <Label>
                <FormattedMessage
                  id="USE_PO_IN_SOURCE_SYSTEM"
                  values={{ sourceSystem: connectedSystem }}
                />
              </Label>
              <SwitchContainer>
                <InfoTooltip
                  message={intl.$t(
                    {
                      id: "USE_SOURCE_SYSTEM_PO_TOOLTIP",
                    },
                    { sourceSystem: connectedSystem },
                  )}
                />
                <SwitchControlled
                  name="settings.releases.useSourceSystemPOs"
                  width={60}
                  offLabel={intl.$t({ id: "NO" })}
                  onLabel={intl.$t({ id: "YES" })}
                />
              </SwitchContainer>
            </ItemContainer>
          </SourceSystemWrapper>
        </If>
        <ItemContainer>
          <Label>
            <FormattedMessage id="ORDER_NOTIFICATION" />
          </Label>
          <OrderNotificationsSelector />
        </ItemContainer>
        <If isTrue={isFMAdmin}>
          <ItemContainer>
            <Label>
              <FormattedMessage id="ENABLE_COST_TYPES_SUPPORT" />
            </Label>
            <SwitchContainer>
              <InfoTooltip
                message={intl.$t({
                  id: "ENABLE_COST_TYPES_SUPPORT_TOOLTIP",
                })}
              />
              <SwitchControlled
                name="settings.display.itemCostTypes"
                width={60}
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
              />
            </SwitchContainer>
          </ItemContainer>
        </If>
        <ItemContainer>
          <Label>
            <FormattedMessage id="ENABLE_ADDITIONAL_CHARGES" />
          </Label>
          <SwitchContainer>
            <SwitchControlled
              name="settings.display.enableAdditionalCharges"
              width={60}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
            />
          </SwitchContainer>
        </ItemContainer>
        <ItemContainer>
          <Label>
            <FormattedMessage id="INCLUDE_ITEM_ID" />
          </Label>
          <SwitchContainer>
            <SwitchControlled
              name="settings.display.appendExternalCode"
              width={60}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
            />
          </SwitchContainer>
        </ItemContainer>
        <ItemContainer>
          <Label>
            <FormattedMessage id="REQUIRE_PROJECT_FOR_RECEIPTS_SLIPS" />
          </Label>
          <SwitchContainer>
            <InfoTooltip
              message={intl.$t({
                id: "REQUIRE_PROJECT_FOR_RECEIPTS_SLIPS_TOOLTIP",
              })}
            />
            <SwitchControlled
              name="settings.display.requireProjectAssignment"
              width={60}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
            />
          </SwitchContainer>
        </ItemContainer>
        <ItemContainer>
          <Label>
            <FormattedMessage id="REQUIRE_COST_CODES_WHEN_APPROVING_INVOICES_RECEIPTS" />
          </Label>
          <SwitchContainer>
            <InfoTooltip
              message={intl.$t({
                id: "REQUIRE_COST_CODES_WHEN_APPROVING_INVOICES_RECEIPTS_TOOLTIP",
              })}
            />
            <SwitchControlled
              name="settings.invoices.requireCostCodesDuringApproval"
              width={60}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
            />
          </SwitchContainer>
        </ItemContainer>
        <ItemContainer>
          <Label>
            <FormattedMessage id="ENABLE_SALES_TAX_VARIANCE" />
          </Label>
          <SwitchContainer>
            <InfoTooltip
              message={intl.$t({
                id: "ENABLE_SALES_TAX_VARIANCE_TOOLTIP",
              })}
            />
            <SwitchControlled
              name="settings.display.enableTaxVariance"
              width={60}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
            />
          </SwitchContainer>
        </ItemContainer>
        <ItemContainer>
          <Label>
            <FormattedMessage
              id="DEFAULT_COST_CODES_SOURCE"
              values={{ br: <br key="break" /> }}
            />
          </Label>
          <UseProjectsCostCodesSelector />
        </ItemContainer>
        <ItemContainer>
          <Label>
            <FormattedMessage id="DEFAULT_PAYMENT_TERMS" />
          </Label>
          <OrgPaymentTerms />
        </ItemContainer>
        <ItemContainer>
          <Label>
            <FormattedMessage id="DEFAULT_FULFILLMENT_LOCATION" />
          </Label>
          <SwitchContainer>
            <OrgFulfillmentLocation />
          </SwitchContainer>
        </ItemContainer>
        <ItemContainer>
          <Label>
            <FormattedMessage id="INVOICE_ITEMIZATION_SETTINGS" />
          </Label>
          <ItemizationSelector name="settings.display.defaultItemized" />
        </ItemContainer>
        <ItemContainer>
          <Label>
            <FormattedMessage id="RECEIPT_ITEMIZATION_SETTINGS" />
          </Label>
          <ItemizationSelector name="settings.display.receiptDefaultItemized" />
        </ItemContainer>
        <ItemContainer className="items-start">
          <Label>
            <FormattedMessage
              id="PROJECT_DEFAULT_USERS"
              values={{ br: <br key="project-default-users-id" /> }}
            />
          </Label>
          <ProjectDefaultUsers />
        </ItemContainer>
        <ItemContainer className="border-b-0">
          <Label>
            <FormattedMessage id="GENERAL_INSTRUCTIONS" />
          </Label>
        </ItemContainer>
        <TextFieldControlled
          variant="outlined"
          multiline
          rows={2.3}
          size="small"
          name="settings.releases.instructions.text"
          placeholder={intl.$t({ id: "GENERAL_INSTRUCTIONS" })}
          className="mb-4"
        />
        <UploadAsset
          mode="horizontal"
          accept={ATTACHMENTS_MIME_TYPES}
          onChange={(assets) =>
            setValue(
              "settings.releases.instructions.assetUrls",
              assets.map((asset) => asset.url),
              { shouldDirty: true },
            )
          }
          classes={{
            dndContainer: "py-3.5 bg-white",
            uploadContainer: {
              container: "px-2",
              image: "w-10 h-10",
              text: "w-24 text-xs",
            },
          }}
        />
      </SettingsContainer>
      <FloatingFooter classes={{ childrenContainer: "flex justify-end gap-2" }}>
        <If isTrue={formState.isDirty}>
          <OutlinedButton wide onClick={onCancelClick}>
            <FormattedMessage id="CANCEL" />
          </OutlinedButton>
        </If>
        <PrimaryButton
          wide
          onClick={handleSubmit(onSaveHandler)}
          loading={updating}
        >
          <FormattedMessage id="SAVE" />
        </PrimaryButton>
      </FloatingFooter>
    </Container>
  );
};

import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  formattedDate,
  SHORT_DATE_OPTION,
} from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { InvoiceFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceExportStatusEnum } from "./enums/InvoiceExportStatusEnum";
import { useInvoiceExportStatus } from "./hooks/useInvoiceExportStatus";
import { InvoiceExportStatusProps } from "./types/InvoiceExportStatusProps";

type DotColor = "green" | "red" | "orange";

const COLOR_MAP = {
  green: "bg-green-800",
  red: "bg-red-500",
  orange: "bg-orange-500",
};

const INVOICE_EXPORT_STATUSES = {
  [InvoiceExportStatusEnum.OK]: {
    color: "green",
    getLabel: (invoice: InvoiceFieldsFragment) =>
      !!invoice.exportedBy || !!invoice.link?.createdBy
        ? "EXPORTED_BY_ON"
        : invoice.link
          ? "AUTO_EXPORTED_AT"
          : "EXPORTED_AT",
  },
  [InvoiceExportStatusEnum.InProgress]: {
    color: "orange",
    getLabel: () => "INVOICE_EXPORT_IN_PROGRESS",
  },
  [InvoiceExportStatusEnum.Failed]: {
    color: "red",
    getLabel: () => "INVOICE_EXPORT_FAILED",
  },
};

const Container = tw.div`flex flex-row gap-3`;
const ExportedAt = tw.div`flex flex-row items-center text-2xs text-gray-600`;
const PoContainer = tw.div`flex flex-col`;
const Item = tw.div``;
const Status = tw.div`w-2 h-2 rounded-full inline-block mr-2 bg-green-800
${({ status }: { status: InvoiceExportStatusEnum }) =>
  COLOR_MAP[INVOICE_EXPORT_STATUSES[status].color as DotColor]}
`;
const ErrorMessage = tw.div`whitespace-break-spaces`;

export const InvoiceExportStatus: FC<InvoiceExportStatusProps> = ({
  invoice,
  toggleExportPanel,
}) => {
  const intl = useIntl();
  const { status, unlink, integrationLabel } = useInvoiceExportStatus({
    invoice,
  });

  return (
    <Container>
      <ExportedAt>
        <Status status={status} />
        <PoContainer>
          <If
            isTrue={invoice.link?.batch?.number || invoice.link?.batch?.month}
          >
            <FormattedMessage
              id="BATCH_NUMBER_DATE"
              values={{
                number: invoice.link?.batch?.number,
                date: invoice.link?.batch?.month,
              }}
            />
          </If>
          <FormattedMessage
            id="INVOICE_NUMBER_INTEGRATION"
            values={{
              number: invoice.number,
              integration: intl.$t({
                id: integrationLabel,
              }),
            }}
            tagName={Item}
          />
          <FormattedMessage
            id={INVOICE_EXPORT_STATUSES[status].getLabel(invoice)}
            values={{
              date: formattedDate({
                date:
                  status === InvoiceExportStatusEnum.Failed
                    ? invoice.link?.failedAt
                    : (invoice.exportedAt ?? invoice.link?.exportedAt),
                options: SHORT_DATE_OPTION,
              }),
              username:
                invoice.exportedBy || invoice.link?.createdBy
                  ? getUserName(invoice.exportedBy ?? invoice.link?.createdBy)
                  : undefined,
              message: (
                <Tooltip
                  id="link-failed"
                  className="inline"
                  element={intl.$t({ id: "AUTOMATED_EXPORT_FAILED" })}
                >
                  {invoice.link?.failedMessage ? (
                    <FormattedMessage
                      id="AUTOMATED_EXPORT_FAILED_BECAUSE_OF"
                      values={{ error: invoice.link?.failedMessage }}
                      tagName={ErrorMessage}
                    />
                  ) : undefined}
                </Tooltip>
              ),
            }}
            tagName={Item}
          />
        </PoContainer>
      </ExportedAt>
      <If isTrue={invoice.link && !invoice.link?.failedAt}>
        <OutlinedButton $small onClick={unlink}>
          {intl.$t({ id: "DISCONNECT" })}
        </OutlinedButton>
      </If>
      <If isTrue={invoice.link?.failedAt}>
        <OutlinedButton $small onClick={toggleExportPanel}>
          {intl.$t({ id: "RETRY" })}
        </OutlinedButton>
      </If>
    </Container>
  );
};

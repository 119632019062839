import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { SpreadSheetConfig } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useIntl } from "react-intl";

export const useCreateRfqFromNoteConfig = (): SpreadSheetConfig[] => {
  const intl = useIntl();
  const { hasPhaseCodes } = useOrgSettings();

  return [
    {
      header: intl.$t({ id: "ITEM_NAME" }),
      columnId: "name",
      columnType: COLUMN_TYPE.Material,
    },
    {
      header: intl.$t({ id: "UOM" }),
      columnId: "UOM",
      columnType: COLUMN_TYPE.UOM,
    },
    {
      header: intl.$t({ id: "QUANTITY" }),
      columnId: "quantityDecimal",
      columnType: COLUMN_TYPE.PositiveQuantity,
    },
    {
      header: intl.$t({ id: "COST_CODE" }),
      columnId: "costCode",
      columnType: COLUMN_TYPE.CostCode,
      hidden: hasPhaseCodes,
    },
    {
      header: intl.$t({ id: "NOTES" }),
      columnId: "notes",
      columnType: COLUMN_TYPE.Notes,
    },
  ];
};

export const pascalCaseToUnderscore = (str: string) =>
  str
    .slice(0, 1)
    .concat(str.slice(1).replace(/([A-Z])/g, "_$1"))
    .toUpperCase();

export const convertUnderscoreToNormalCase = (str: string) =>
  str
    .split("_")
    .map((word) => word.toLowerCase())
    .join(" ");

import { usePrefillPricesFlag } from "@/common/components/org-roles-wrapper/usePrefillPricesFlag";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableMaps } from "@/common/components/spreadsheet-table/hooks/useTableMaps";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  SpreadsheetSaveType,
  useColumnMapper,
} from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { rowIsChecked } from "@/common/components/spreadsheet-table/utils/rowIsChecked";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import {
  HOLD_FOR_RELEASE_TEXT,
  LUMP_SUM_UOM_PLURAL_DESCRIPTION,
} from "@/common/const";
import { useGetProjectItemFromSku } from "@/common/hooks/useGetProjectItem";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import { useDeliverySlipVerification } from "@/contractor/pages/home/releases/pages/delivery-slips/providers/DeliverySlipVerificationProvider";
import {
  AddToReleaseItemInput,
  ManufacturerFieldsFragment,
  TagExtendedFieldsFragment,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
  ZoneFieldsFragment,
} from "@/generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";
import { useReleaseItemsValidators } from "./useReleaseItemsValidators";

type GetSyncedReleaseProps = {
  importedItems?: { id: string }[];
  addItemsProps?: Partial<AddToReleaseItemInput>;
  projectId?: string;
};

type SyncReleaseProps = {
  type?: SpreadsheetSaveType;
  syncProps?: GetSyncedReleaseProps;
  updateProps?: Partial<UpdateContractorReleaseInput>;
};

export type SyncedRelease = {
  releaseId: string;
  version: number;
  updates: UpdateContractorReleaseItemInput[];
  addedItems: AddToReleaseItemInput[];
  removedItems: string[];
  buyoutId?: string;
};

export const useSyncReleaseItems = () => {
  const { manufacturers } = useManufacturers();
  const { spreadsheetData, getRemovedRowIds, rowHasChanges, gotoInvalidRow } =
    useColumnMapper();
  const {
    getCostCodeId,
    getRowTagIds,
    getCostTypeId,
    addMissingZones,
    addMissingTags,
    addMissingMaterials,
    addMissingManufacturers,
    getCellWithAdditionalData,
    getPrefilledValue,
    getBuyoutItem,
    getEstimatedItem,
    tableHasColumn,
  } = useTableHelpers();
  const findMaterialByName = useFindMaterialByName();
  const {
    validateWarehouseQuantity,
    validateAtLeastOneItemMatchesReleaseSupplier,
  } = useReleaseItemsValidators();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { release } = useRelease();
  const { deliverySlip } = useDeliverySlipVerification();
  const { zones } = useProjectZones();
  const { vendorByNameMap, warehouseByNameMap } = useTableMaps();
  const { materials } = useMaterials();
  const [saving, setSaving] = useState(false);
  const prefillPrices = usePrefillPricesFlag();
  const { setInputError } = useReleaseActions();
  const {
    release: releaseStore,
    hasChanges,
    releaseUpdateOptions,
  } = useReleaseStore(
    useShallow((state) => ({
      release: state.release,
      hasChanges: state.hasChanges,
      releaseUpdateOptions: state.releaseUpdateOptions,
    })),
  );
  const { updateRelease, updating } = useReleaseUpdate();
  const { hasPhaseCodes } = useOrgSettings();
  const getProjectItem = useGetProjectItemFromSku();
  const autoFillItemsDeliveryDate = useMemo(
    () => !!releaseUpdateOptions.requestedTime,
    [releaseUpdateOptions],
  );

  const addUpdatedItemOptionalFields = useCallback(
    (
      item: UpdateContractorReleaseItemInput,
      row: Record<string, string>,
      newZones: ZoneFieldsFragment[],
      newManufacturers: ManufacturerFieldsFragment[],
      newTags: TagExtendedFieldsFragment[],
    ): UpdateContractorReleaseItemInput => {
      const supplierText = getCellValue(row, COLUMN_TYPE.Supplier);
      const supplier =
        vendorByNameMap[supplierText] || warehouseByNameMap[supplierText];
      const receivedQuantityDecimal = getCellValue(
        row,
        COLUMN_TYPE.ReceivedQuantity,
      );
      const zone = [...(zones || []), ...newZones].find(
        (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );
      const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );
      const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";
      const existingItem = release?.items.find((item) => item.id === row.id);

      if (tableHasColumn(COLUMN_TYPE.Notes)) {
        item.instructions = {
          text: notes,
        };
      }
      if (
        tableHasColumn(COLUMN_TYPE.Manufacturer) &&
        !existingItem?.buyoutItem
      ) {
        item.manufacturerId = manufacturer?.id;
        item.clearManufacturer = !manufacturer;
      }
      if (
        tableHasColumn(COLUMN_TYPE.Tag) ||
        tableHasColumn(COLUMN_TYPE.PhaseCode)
      ) {
        item.tags = getRowTagIds(row, newTags);
      }
      if (tableHasColumn(COLUMN_TYPE.Supplier)) {
        if (supplier && vendorByNameMap[supplierText]) {
          item.sellerOrgLocationId = supplier.sellerOrgLocation.id;
          item.clearSourceWarehouse = true;
        } else if (supplier && warehouseByNameMap[supplierText]) {
          item.sourceWarehouseId = supplier.id;
          item.clearSellerOrgLocation = true;
        } else {
          item.clearSellerOrgLocation = true;
          item.clearSourceWarehouse = true;
        }
      }
      if (tableHasColumn(COLUMN_TYPE.Zone)) {
        item.zoneId = zone?.id;
      }
      if (tableHasColumn(COLUMN_TYPE.CostCode)) {
        item.costCodeId = getCostCodeId(row);
        item.clearCostCode = !getCostCodeId(row);
      }
      if (tableHasColumn(COLUMN_TYPE.CostType)) {
        item.costTypeId = getCostTypeId(row);
        item.clearCostType = !getCostTypeId(row);
      }
      if (tableHasColumn(COLUMN_TYPE.ReceivedQuantity)) {
        item.receivedQuantityDecimal =
          receivedQuantityDecimal === "" ? undefined : receivedQuantityDecimal;
      }
      if (tableHasColumn(COLUMN_TYPE.DeliveryDate)) {
        const date = getCellValue(row, COLUMN_TYPE.DeliveryDate);
        const isTBD = date === HOLD_FOR_RELEASE_TEXT;
        if (date && !isTBD) {
          item.deliveryDate = defaultReleaseDate(new Date(date)).getTime();
        } else if (autoFillItemsDeliveryDate || isTBD) {
          item.deliveryDateTBD = true;
          item.clearDeliveryDate = true;
        } else {
          item.clearDeliveryDate = !date;
        }
      }
      if (tableHasColumn(COLUMN_TYPE.EndDate)) {
        const date = getCellValue(row, COLUMN_TYPE.EndDate);
        if (date) {
          item.deliveryDate = defaultReleaseDate(new Date(date)).getTime();
        }
        item.clearDeliveryDate = !date;
      }
      if (tableHasColumn(COLUMN_TYPE.Taxable)) {
        item.taxable = rowIsChecked(row, COLUMN_TYPE.Taxable);
      }
      return item;
    },
    [
      release,
      manufacturers,
      zones,
      autoFillItemsDeliveryDate,
      getCostCodeId,
      getCostTypeId,
      getRowTagIds,
      vendorByNameMap,
      warehouseByNameMap,
      tableHasColumn,
    ],
  );

  const addNewItemOptionalFields = useCallback(
    (
      newItem: AddToReleaseItemInput,
      row: Record<string, string>,
      newZones: ZoneFieldsFragment[],
      newManufacturers: ManufacturerFieldsFragment[],
      newTags: TagExtendedFieldsFragment[],
    ): AddToReleaseItemInput => {
      const supplierText = getCellValue(row, COLUMN_TYPE.Supplier);
      const supplier =
        vendorByNameMap[supplierText] || warehouseByNameMap[supplierText];
      const receivedQuantityDecimal = getCellValue(
        row,
        COLUMN_TYPE.ReceivedQuantity,
      );

      const zone = [...(zones || []), ...newZones].find(
        (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );
      const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );
      const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";
      const existingItem = release?.items.find((item) => item.id === row.id);
      if (tableHasColumn(COLUMN_TYPE.Notes)) {
        newItem.instructions = {
          text: notes,
        };
      }

      if (
        tableHasColumn(COLUMN_TYPE.Manufacturer) &&
        !existingItem?.buyoutItem
      ) {
        newItem.manufacturerId = manufacturer?.id;
      }
      if (
        tableHasColumn(COLUMN_TYPE.Tag) ||
        tableHasColumn(COLUMN_TYPE.PhaseCode)
      ) {
        newItem.tags = getRowTagIds(row, newTags);
      }
      if (tableHasColumn(COLUMN_TYPE.Supplier)) {
        if (supplier) {
          if (vendorByNameMap[supplierText]) {
            newItem.sellerOrgLocationId = supplier.sellerOrgLocation.id;
          } else if (warehouseByNameMap[supplierText]) {
            newItem.sourceWarehouseId = supplier.id;
          }
        }
      }
      if (tableHasColumn(COLUMN_TYPE.Zone)) {
        newItem.zoneId = zone?.id;
      }
      if (tableHasColumn(COLUMN_TYPE.CostCode)) {
        newItem.costCodeId = getCostCodeId(row);
      }
      if (tableHasColumn(COLUMN_TYPE.CostType)) {
        newItem.costTypeId = getCostTypeId(row);
      }
      if (tableHasColumn(COLUMN_TYPE.ReceivedQuantity)) {
        newItem.receivedQuantityDecimal =
          receivedQuantityDecimal === "" ? undefined : receivedQuantityDecimal;
      }
      if (tableHasColumn(COLUMN_TYPE.DeliveryDate)) {
        const date = getCellValue(row, COLUMN_TYPE.DeliveryDate);
        const isTBD = date === HOLD_FOR_RELEASE_TEXT;
        if (date && !isTBD) {
          newItem.deliveryDate = defaultReleaseDate(new Date(date)).getTime();
        } else if (autoFillItemsDeliveryDate || isTBD) {
          newItem.deliveryDateTBD = true;
        }
      }
      if (tableHasColumn(COLUMN_TYPE.EndDate)) {
        const date = getCellValue(row, COLUMN_TYPE.EndDate);
        if (date) {
          newItem.deliveryDate = defaultReleaseDate(new Date(date)).getTime();
        }
      }
      if (tableHasColumn(COLUMN_TYPE.Taxable)) {
        newItem.taxable = rowIsChecked(row, COLUMN_TYPE.Taxable);
      }

      return newItem;
    },
    [
      release,
      manufacturers,
      zones,
      autoFillItemsDeliveryDate,
      getCostCodeId,
      getCostTypeId,
      getRowTagIds,
      vendorByNameMap,
      warehouseByNameMap,
      tableHasColumn,
    ],
  );

  const getSyncedRelease = useCallback(
    async (
      { importedItems, addItemsProps, projectId }: GetSyncedReleaseProps = {
        importedItems: [],
      },
    ) => {
      const newItems: AddToReleaseItemInput[] = [];
      const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
      let itemsToRemove = [] as string[];

      setSaving(true);
      itemsToRemove = getRemovedRowIds(release?.items ?? []);
      const newMaterials = (await addMissingMaterials()) || [];
      const newManufacturers = (await addMissingManufacturers()) || [];
      const newZones =
        (await addMissingZones(release?.project?.id || projectId || null)) ||
        [];
      const newTags =
        (await addMissingTags(release?.project?.id || projectId || null)) || [];
      setSaving(false);

      spreadsheetData.forEach((row, index) => {
        const rowMaterialText = getCellWithAdditionalData(
          row,
          COLUMN_TYPE.Material,
        );
        let material = findMaterialByName(rowMaterialText, [
          ...materials,
          ...newMaterials,
        ]);

        const uom = getCellValue(row, COLUMN_TYPE.UOM);
        const isLumpSum = isLumpSumUomText(uom);
        if (isLumpSum) {
          material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
            ...materials,
            ...newMaterials,
          ]);
        }

        if (!material || rowIsEmpty(row)) {
          if (row.id) {
            itemsToRemove.push(row.id);
          }
          return false;
        }

        let quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity) || "0";
        const supplierText = getCellValue(row, COLUMN_TYPE.Supplier);
        let unitPrice = tableHasColumn(COLUMN_TYPE.PrefilledPrice)
          ? getCellValue(row, COLUMN_TYPE.PrefilledPrice)
          : getCellValue(row, COLUMN_TYPE.UnitPrice);

        if (
          isLumpSum &&
          Number(unitPrice) > 1 &&
          Number(quantityDecimal) === 1
        ) {
          quantityDecimal = unitPrice;
          unitPrice = "1";
        }

        const hasUnitPrice =
          unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;
        const manufacturer = [
          ...manufacturers,
          ...(newManufacturers ?? []),
        ].find((m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer));
        const buyoutItem = getBuyoutItem({
          id: getCellValue(row, COLUMN_TYPE.BuyoutItemId),
          material: rowMaterialText,
          uom,
        });
        const estimatedItem = getEstimatedItem({
          id: getCellValue(row, COLUMN_TYPE.EstimatedItemId),
          material: rowMaterialText,
          uom,
          vendor: supplierText,
        });
        const prefilledPrice = getPrefilledValue({
          material: rowMaterialText,
          vendor: supplierText,
          manufacturer: manufacturer?.name ?? "",
          uom,
        });

        const importedItem = importedItems?.find((_, i) => i === index);
        const existingItem = release?.items.find((item) => item.id === row.id);

        const matchingLumpSumMaterial =
          isLumpSum &&
          isLumpSumUomText(existingItem?.uom?.pluralDescription) &&
          existingItem?.name === rowMaterialText;

        const matchingMaterials =
          existingItem &&
          (existingItem.projectItem?.material.id === material.id ||
            matchingLumpSumMaterial);

        const finalUnitPrice = hasUnitPrice
          ? isLumpSum
            ? "1"
            : String(unitPrice)
          : undefined;

        if (matchingMaterials) {
          const deliveryDate = getCellValue(row, COLUMN_TYPE.DeliveryDate);
          if (
            rowHasChanges(row) ||
            existingItem.position !== index ||
            (!deliveryDate && autoFillItemsDeliveryDate) ||
            existingItem.costCode?.id !== getCostCodeId(row)
          ) {
            const item = {
              releaseItemId: row.id,
              position: index,
              uom,
              buyoutItemId: buyoutItem ? buyoutItem?.id : undefined,
              unitPrice: finalUnitPrice,
              ...(isLumpSum && { name: rowMaterialText }),
              clearUnitPrice: !hasUnitPrice,
              quantityDecimal,
              poItemExternalId: importedItem?.id,
              costCodeId: getCostCodeId(row),
            } as UpdateContractorReleaseItemInput;
            itemsToUpdate.push(
              addUpdatedItemOptionalFields(
                item,
                row,
                newZones,
                newManufacturers,
                newTags,
              ),
            );
          }
        } else {
          const newItem = {
            ...(!buyoutItem &&
              !estimatedItem && {
                projectItem: getProjectItem(material, uom),
              }),
            position: index,
            unitPrice: finalUnitPrice,
            ...(isLumpSum && { name: rowMaterialText }),
            poItemExternalId: importedItem?.id,
            buyoutItemId: buyoutItem ? buyoutItem?.id : undefined,
            estimatedItemId: estimatedItem ? estimatedItem?.id : undefined,
            deliverySlipId: deliverySlip ? deliverySlip?.id : undefined,
            quantityDecimal,
            pricePrenegotiated:
              prefilledPrice.isVendorPrice &&
              prefilledPrice.value === String(unitPrice),
            ...addItemsProps,
          } as AddToReleaseItemInput;
          newItems.push(
            addNewItemOptionalFields(
              newItem,
              row,
              newZones,
              newManufacturers,
              newTags,
            ),
          );
          if (row.id) {
            itemsToRemove.push(row.id);
          }
        }
      });

      return {
        releaseId: release?.id ?? "",
        version: releaseStore?.version ?? 0,
        updates: itemsToUpdate,
        addedItems: newItems,
        removedItems: itemsToRemove,
        assignDefaultCostCodes: false,
        prefillPrices,
      };
    },
    [
      release,
      releaseStore,
      addMissingMaterials,
      addMissingManufacturers,
      addMissingTags,
      addMissingZones,
      addNewItemOptionalFields,
      autoFillItemsDeliveryDate,
      findMaterialByName,
      getBuyoutItem,
      getCellWithAdditionalData,
      getCostCodeId,
      getEstimatedItem,
      getPrefilledValue,
      getProjectItem,
      getRemovedRowIds,
      materials,
      manufacturers,
      spreadsheetData,
      tableHasColumn,
      addUpdatedItemOptionalFields,
      deliverySlip,
      rowHasChanges,
      prefillPrices,
    ],
  );

  const validateItems = useCallback(
    async (props?: {
      type?: SpreadsheetSaveType;
      requirePrice?: boolean;
    }): Promise<boolean> => {
      const { type, requirePrice } = props ?? {};
      const isToggleOrSaveButton =
        type === SpreadsheetSaveType.Toggle ||
        type === SpreadsheetSaveType.SaveButton;

      const requiredColumns = [
        COLUMN_TYPE.Material,
        ...(!isToggleOrSaveButton && tableHasColumn(COLUMN_TYPE.Quantity)
          ? [COLUMN_TYPE.Quantity]
          : []),
        ...(requirePrice
          ? tableHasColumn(COLUMN_TYPE.PrefilledPrice)
            ? [COLUMN_TYPE.PrefilledPrice]
            : []
          : tableHasColumn(COLUMN_TYPE.UnitPrice)
            ? [COLUMN_TYPE.UnitPrice]
            : []),
        COLUMN_TYPE.UOM,
      ];

      const rowValidationColumns = [
        ...(!isToggleOrSaveButton && tableHasColumn(COLUMN_TYPE.Quantity)
          ? [COLUMN_TYPE.Quantity]
          : []),
        ...(tableHasColumn(COLUMN_TYPE.Supplier) ? [COLUMN_TYPE.Supplier] : []),
        ...(tableHasColumn(COLUMN_TYPE.PrefilledPrice)
          ? [COLUMN_TYPE.PrefilledPrice]
          : []),
        ...(tableHasColumn(COLUMN_TYPE.UnitPrice)
          ? [COLUMN_TYPE.UnitPrice]
          : []),
        COLUMN_TYPE.UOM,
        ...(hasPhaseCodes
          ? tableHasColumn(COLUMN_TYPE.PhaseCode)
            ? [COLUMN_TYPE.PhaseCode]
            : []
          : tableHasColumn(COLUMN_TYPE.CostCode)
            ? [COLUMN_TYPE.CostCode]
            : []),
        ...(tableHasColumn(COLUMN_TYPE.CostType) ? [COLUMN_TYPE.CostType] : []),
        ...(tableHasColumn(COLUMN_TYPE.DeliveryDate)
          ? [COLUMN_TYPE.DeliveryDate]
          : []),
      ];

      const isValid =
        (await validateRequiredValues(requiredColumns)) &&
        (await validateRowValues(rowValidationColumns, undefined, {
          minPrice: undefined,
        })) &&
        validateWarehouseQuantity(type) &&
        validateAtLeastOneItemMatchesReleaseSupplier(type);

      if (!isValid) {
        setInputError(ReleaseErrorType.SPREADSHEET_ERROR);
        gotoInvalidRow();
        return false;
      }

      return true;
    },
    [
      validateRequiredValues,
      validateRowValues,
      validateWarehouseQuantity,
      validateAtLeastOneItemMatchesReleaseSupplier,
      tableHasColumn,
      hasPhaseCodes,
      gotoInvalidRow,
      setInputError,
    ],
  );

  const syncReleaseItems = useCallback(
    async (options?: SyncReleaseProps) => {
      if (!(await validateItems({ type: options?.type }))) {
        return undefined;
      }

      if (hasChanges) {
        const syncedRelease = await getSyncedRelease(options?.syncProps);
        await updateRelease({ ...syncedRelease, ...options?.updateProps });
      }

      return release;
    },
    [getSyncedRelease, validateItems, hasChanges, updateRelease, release],
  );

  return {
    getSyncedRelease,
    validateItems,
    syncReleaseItems,
    saving: updating || saving,
  };
};

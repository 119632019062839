import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { ProjectOrderedQuantityProvider } from "@/contractor/pages/home/project/providers/ProjectOrderedQuantityProvider";
import {
  ProjectReportCostCodeFieldsFragment,
  ProjectReportCostCodeVendorFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { SpendingReportValueType } from "../../SpendingReportListItem.configuration";
import { CurrencyDisplay } from "../CurrencyDisplay";
import {
  HeaderType,
  ReportItemOrderedInvoicedModal,
  ReportType,
} from "../report-item-orders-modal/ReportItemOrderedInvoicedModal";
import { TotalContainer, ValueWithTax } from "./CostCodeTotal.Styles";

const LinkLikeStyled = tw(LinkLike)`font-medium text-xs`;

type Props = {
  item:
    | ProjectReportCostCodeVendorFieldsFragment
    | ProjectReportCostCodeFieldsFragment
    | null
    | undefined;
  zoneId?: string | undefined;
  reportType: ReportType;
  valueType: SpendingReportValueType;
  hideQuantities?: boolean;
};

export const CostCodeOrderedInvoiced: FC<Props> = ({
  item,
  zoneId,
  reportType,
  valueType,
  hideQuantities = false,
}) => {
  const { formatCurrency } = useFormatNumberToCurrency();
  const [modalOpened, setModalOpened] = useState(false);

  const showTaxes = useMemo(() => {
    if (valueType === SpendingReportValueType.ORDERED) {
      return (
        !!item?.orderedTotal &&
        !!item?.ordered &&
        item?.orderedTotal !== item?.ordered
      );
    }
    if (valueType === SpendingReportValueType.INVOICED) {
      return (
        !!item?.invoicedTotal &&
        !!item.invoiced &&
        item?.invoicedTotal !== item.invoiced
      );
    }
    return false;
  }, [item, valueType]);

  const value = useMemo(() => {
    if (valueType === SpendingReportValueType.ORDERED && item?.orderedTotal) {
      return item.orderedTotal;
    }
    if (valueType === SpendingReportValueType.RECEIVED && item?.receivedTotal) {
      return item.receivedTotal;
    }
    if (valueType === SpendingReportValueType.INVOICED && item?.invoicedTotal) {
      return item.invoicedTotal;
    }
  }, [item, valueType]);

  if (!item) {
    return null;
  }
  return (
    <>
      <NotNullableRenderer value={value}>
        <LinkLikeStyled
          onClick={() => {
            setModalOpened(true);
          }}
        >
          <TotalContainer>
            <CurrencyDisplay value={value} />
            <If isTrue={showTaxes}>
              <FormattedMessage
                id="VALUE_TAX_AND_FEES"
                tagName={ValueWithTax}
                values={{
                  value: formatCurrency(
                    valueType === SpendingReportValueType.ORDERED
                      ? new DecimalSafe(item?.orderedTotal ?? 0).minus(
                          item?.ordered ?? 0,
                        )
                      : new DecimalSafe(item?.invoicedTotal ?? 0).minus(
                          item?.invoiced ?? 0,
                        ),
                  ),
                }}
              />
            </If>
          </TotalContainer>
        </LinkLikeStyled>
      </NotNullableRenderer>
      <If isTrue={modalOpened}>
        <ProjectOrderedQuantityProvider
          item={{
            zoneId,
            costCodeId: item.costCode?.id,
            ordered: item.ordered,
            orderedTotal: item.orderedTotal,
            invoiced: item.invoiced,
            invoicedTotal: item.invoicedTotal,
          }}
        >
          <ReportItemOrderedInvoicedModal
            opened={modalOpened}
            close={() => setModalOpened(false)}
            reportType={reportType}
            headerType={HeaderType.CostCode}
            hideQuantities={hideQuantities}
          />
        </ProjectOrderedQuantityProvider>
      </If>
    </>
  );
};

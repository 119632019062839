import { DecimalSafe } from "@/common/utils/decimalSafe";
import { InventoryItemSummaryFieldsFragment } from "@/generated/graphql";
import { WarehouseInventoryItem } from "../../../types/WarehouseInventoryItem";
import inventoryItemsUtils from "../inventoryItemsUtils";

export const updateScheduledInflows = (
  item: InventoryItemSummaryFieldsFragment,
  warehouses: WarehouseInventoryItem[],
  warehouseIds?: string[] | null,
) => {
  item.pendingInflows.forEach((inflow) => {
    const warehouse = warehouses.find(
      (w) => w.warehouse.id === inflow.release.warehouse?.id,
    );
    if (warehouse) {
      warehouse.scheduledInflowTotal = new DecimalSafe(
        warehouse.scheduledInflowTotal,
      )
        .plus(
          new DecimalSafe(inflow.quantityDecimal).minus(
            inflow.receivedQuantityDecimal,
          ),
        )
        .toNumber();
      warehouse.pendingInflows = [...(warehouse.pendingInflows || []), inflow];
    } else if (
      inflow.release.warehouse &&
      (warehouseIds?.length === 0 ||
        warehouseIds?.includes(inflow.release.warehouse.id))
    ) {
      const newWarehouse = inventoryItemsUtils.generateWarehouseStateEntry(
        item,
        {
          warehouse: inflow.release.warehouse,
          remainingQuantity: new DecimalSafe(inflow.quantityDecimal)
            .minus(inflow.receivedQuantityDecimal)
            .toString(),
        },
      );
      newWarehouse.scheduledOutflowTotal = new DecimalSafe(
        inflow.quantityDecimal,
      )
        .minus(inflow.receivedQuantityDecimal)
        .toNumber();
      newWarehouse.pendingOutflows = [inflow];
      warehouses.push(newWarehouse);
    }
  });
};

import { If } from "@/common/components/if/If";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { MAX_COLUMN_WIDTH } from "@/contractor/pages/home/rfq-quotes/constants";
import {
  RfqItemFieldsFragment,
  RfqQuotesQuoteFieldsFragment,
  RfqQuotesQuoteItemFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useBidsPrices } from "../../../../../providers/BidsPricesProvider";
import { useRfqQuotes } from "../../../../../providers/RfqQuotesProvider";
import { AlternativeDetailsRow } from "./AlternativeDetailsRow";
import { ComponentsRow } from "./ComponentsRow";
import { ExpirationRow } from "./ExpirationRow";
import { ManufacturerRow } from "./ManufacturerRow";
import { PricePositionRow } from "./PricePositionRow";
import { UnitPriceRow } from "./UnitPriceRow";
import { useQuoteItemCard } from "./useQuoteItemCard";

type Props = {
  rfqItem: RfqItemFieldsFragment;
  quoteItem?: RfqQuotesQuoteItemFieldsFragment;
  quote: RfqQuotesQuoteFieldsFragment;
  showItemDescription: boolean;
  hasComponents: boolean;
  hasExpiration: boolean;
};

type ContainerProps = {
  $selected: boolean;
  $canBeSelected: boolean;
  $awarded: boolean;
};

const Container = tw.div`
  relative block
`;

const CardContainer = tw.div<ContainerProps>`
  grid relative w-full transition z-10 content-start h-fit
  rounded-3xl overflow-hidden border p-2 cursor-pointer border-gray-400 bg-white
  ${(p) => p.$awarded && `border-green-300 border-2 p-[7px]`}
  ${(p) => p.$selected && `border-2 border-blue-500 p-[7px] bg-blue-500 bg-opacity-10`}
  ${(p) => p.$canBeSelected && `hover:border-blue-500`}
`;

const AwardedDecoration = tw.div`
  absolute -top-2 left-2.5 bg-green-300 text-black font-normal py-0.5 px-1 rounded-[4px] z-20 text-2xs
`;

const RejectedDecoration = tw.div`
  absolute -top-2 left-2.5 bg-gray-300 text-black font-normal py-0.5 px-1 rounded-[4px] z-20 text-2xs
`;

const Separator = tw.div`
  border-b border-gray-400 border-dashed h-0.5 mx-4
`;

export const QuoteItemCard: FC<Props> = ({
  rfqItem,
  quoteItem,
  quote,
  showItemDescription,
  hasComponents,
  hasExpiration,
}) => {
  const { rfq } = useRfqQuotes();
  const { findPriceByItemAndQuoteItem } = useBidsPrices();
  const { hasManufacturersSetting } = useManufacturersSettings();
  const quoteItemPrice = findPriceByItemAndQuoteItem(
    rfqItem.id,
    quoteItem?.id || "",
  );
  const {
    isLess,
    missingItem,
    isSelected,
    isAwarded,
    isRejected,
    selectQuoteItem,
  } = useQuoteItemCard({ rfqItem, quoteItem, quote });

  const quantityDiff = useMemo(() => {
    return isLess
      ? new DecimalSafe(rfqItem.quantityDecimal || 0)
          .sub(quoteItem?.quantityDecimal || 0)
          .toNumber()
      : 0;
  }, [isLess, rfqItem.quantityDecimal, quoteItem?.quantityDecimal]);

  const manufacturerName = useMemo(
    () => quoteItem?.manufacturer?.name,
    [quoteItem?.manufacturer?.name],
  );

  const isManufacturerMismatch = useMemo(() => {
    return (
      quoteItem?.manufacturer?.id !== rfqItem?.manufacturer?.id &&
      rfqItem.manufacturer !== null
    );
  }, [quoteItem?.manufacturer?.id, rfqItem?.manufacturer]);

  const totalPrice = useMemo(() => {
    return (
      new DecimalSafe(quoteItem?.unitPrice ?? 0)
        .mul(quoteItem?.quantityDecimal ?? 0)
        .toNumber() || 0
    );
  }, [quoteItem?.quantityDecimal, quoteItem?.unitPrice]);

  const isAlternative = useMemo(() => {
    const group = quote.itemGroups.find(
      (itemGroup) => itemGroup.rfqItem.id === rfqItem?.id,
    );
    if (group) {
      return (
        group.quoteItems.length > 1 ||
        group.quoteItems[0].description !== rfqItem.description
      );
    } else {
      return false;
    }
  }, [quote.itemGroups, rfqItem.description, rfqItem?.id]);

  const index = useMemo(() => {
    return (
      quote.itemGroups
        .find((itemGroup) => itemGroup.rfqItem.id === rfqItem?.id)
        ?.quoteItems.findIndex((item) => item.id === quoteItem?.id) || 0
    );
  }, [quote.itemGroups, rfqItem?.id, quoteItem?.id]);

  const isSingleAlternative = useMemo(() => {
    return (
      quote.itemGroups.find((itemGroup) => itemGroup.rfqItem.id === rfqItem?.id)
        ?.quoteItems.length === 1
    );
  }, [quote.itemGroups, rfqItem?.id]);

  return (
    <Container>
      <If isTrue={isAwarded && !isSelected}>
        <AwardedDecoration>
          <FormattedMessage id="ACCEPTED_QUOTE" />
        </AwardedDecoration>
      </If>
      <If isTrue={isRejected && !isSelected}>
        <RejectedDecoration>
          <FormattedMessage id="LOST_BID" />
        </RejectedDecoration>
      </If>
      <CardContainer
        data-testid="quote-item-card"
        $selected={isSelected}
        $awarded={isAwarded}
        $canBeSelected={rfq?.status === RfqStatus.Active}
        onClick={selectQuoteItem}
        style={{
          maxWidth: `${MAX_COLUMN_WIDTH}px`,
        }}
      >
        <If isTrue={!missingItem}>
          <If isTrue={showItemDescription}>
            <AlternativeDetailsRow
              name={quoteItem?.description || rfqItem.description}
              isAlternative={isAlternative}
              isSingleAlternative={isSingleAlternative}
              index={index}
            />
          </If>
          <If isTrue={hasManufacturersSetting}>
            <ManufacturerRow
              quoteItemId={quoteItem?.id}
              manufacturerName={manufacturerName}
              notes={quoteItem?.notes}
              assets={quoteItem?.assets}
              manufacturerMismatch={isManufacturerMismatch}
            />
          </If>
          <Separator />
          <UnitPriceRow
            quantityDiff={quantityDiff}
            quantityDecimal={quoteItem?.quantityDecimal}
            unit={rfqItem.projectItem.estimateUom?.mnemonic || ""}
            unitPrice={Number(quoteItem?.unitPrice)}
          />
          <If isTrue={hasExpiration}>
            <Separator />
            <ExpirationRow expirationDate={quoteItem?.expirationDate} />
          </If>
          <If isTrue={hasComponents}>
            <Separator />
            <ComponentsRow components={[]} />
          </If>
          <PricePositionRow
            quoteItemPrice={quoteItemPrice}
            price={totalPrice}
            isSelected={isSelected}
            leadTimeDays={quoteItem?.leadTimeDays}
          />
        </If>
      </CardContainer>
    </Container>
  );
};

import {
  AUTO_HIDE_DURATIONS,
  MAX_NUMBER_OF_SNACKBAR_ALERTS,
  REASON,
} from "@/common/const";
import {
  Message,
  Severity,
  useSnackbar,
} from "@/common/providers/SnackbarProvider";
import { Alert, Snackbar } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import tw from "tailwind-styled-components";
import { SystemAlertType } from "../system-alert/SystemAlert";

type Props = {
  messages: Message[];
};

const AlertStyled = tw(Alert)`
  border border-gray-100 mt-1
`;
const SystemAlertStyled = tw(Alert)`rounded-lg p-0 overflow-hidden border mt-1`;

const ErrorContainer = tw.div`
  print:hidden
`;

export const Alerts: FC<Props> = ({ messages }) => {
  const [open, setOpen] = useState(false);
  const { removeMessage } = useSnackbar();
  const [autoHideIds, setAutoHideIds] = useState<string[]>([]);
  const autoHideTypes = useMemo(
    () =>
      Object.entries(AUTO_HIDE_DURATIONS)
        .filter(([, value]) => !!value)
        .map(([key]) => key),
    [],
  );

  useEffect(() => {
    if (messages && messages.length > 0) {
      setOpen(true);
    }
    const notMappedIds = messages
      .filter((message) => autoHideTypes.includes(message.type))
      .map((message) => message.id)
      .filter((id) => !autoHideIds.includes(id));
    if (notMappedIds.length) {
      setAutoHideIds((oldIds) => [...oldIds, ...notMappedIds]);
      notMappedIds.forEach((id) => {
        const message = messages.find((message) => message.id === id);
        const isNonErrorSystemAlert =
          message?.type === Severity.SystemAlert &&
          message.systemAlertType !== SystemAlertType.ERROR;
        const duration = isNonErrorSystemAlert
          ? AUTO_HIDE_DURATIONS[Severity.Success]
          : AUTO_HIDE_DURATIONS[message?.type as Severity];
        setTimeout(() => removeMessage(id), duration || 0);
      });
    }
  }, [autoHideIds, autoHideTypes, messages, removeMessage]);

  const handleClose = useCallback(
    (message: Message, reason?: REASON) => {
      if (reason === REASON.CLICKAWAY) {
        return;
      }
      removeMessage(message.id);
      setOpen(false);
    },
    [setOpen, removeMessage],
  );

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        zIndex: 1400,
        " .MuiAlert-action": {
          paddingLeft: 0,
        },
      }}
    >
      <ErrorContainer>
        {messages
          .slice(0, MAX_NUMBER_OF_SNACKBAR_ALERTS)
          .map((message: Message, key: number) => {
            if (message.type === Severity.SystemAlert) {
              return (
                <SystemAlertStyled
                  onClose={() => handleClose(message)}
                  key={key}
                  classes={{
                    root: `max-w-lg ${message.systemAlertType === SystemAlertType.ERROR ? "border-red-500 bg-red-500" : "border-green-800 bg-green-800"}`,
                    icon: "hidden",
                    message: "p-0",
                    action: "text-white pl-2 pr-4 flex items-center",
                  }}
                  icon={undefined}
                >
                  {message.text}
                </SystemAlertStyled>
              );
            }
            return (
              <AlertStyled
                onClose={() => handleClose(message)}
                severity={message.type}
                key={key}
                classes={{ root: "max-w-lg" }}
              >
                <span>{message.text}</span>
              </AlertStyled>
            );
          })}
      </ErrorContainer>
    </Snackbar>
  );
};

import { ImageInitials } from "@/common/components/image-initials/ImageInitials";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { FC } from "react";
import { InventoryItemWithWarehouse } from "../../../types/InventoryItemWithWarehouse";
type Props = {
  item: InventoryItemWithWarehouse;
};
export const InventoryItemWarehouse: FC<Props> = ({ item }) => {
  const { warehouses } = useWarehouses();
  const warehouse = warehouses.find(
    (warehouse) => warehouse.id === item.warehouse,
  );

  return (
    <ImageInitials name={warehouse?.name ?? ""} width={30} includeTooltip />
  );
};

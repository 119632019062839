import { Instructions } from "@/common/components/instructions/Instructions";
import { useReleaseAdditionalChargesInput } from "@/common/components/release-additional-charges-and-taxes/hooks/useReleaseAdditionalChargesInput";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { usePrefillHelpers } from "@/common/components/spreadsheet-table/hooks/usePrefillHelpers";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useGlobalReleaseInstructions } from "@/common/hooks/useGlobalReleaseInstructions";
import { useInstruction } from "@/common/hooks/useInsruction";
import { useInstructionReleaseSetup } from "@/common/hooks/useInstructionReleaseSetup";
import { routes } from "@/config/routes";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { ReleaseDescription } from "@/contractor/pages/home/release/components/ReleaseDescription";
import { useIsWarehouseRelease } from "@/contractor/pages/home/release/hooks/useIsWarehouseRelease";
import { useItemizedReleaseProjectBudgetSync } from "@/contractor/pages/home/release/hooks/useItemizedReleaseProjectBudgetSync";
import { useOrderTableHelpers } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderTableHelpers";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AdditionalChargesFieldsFragment,
  ManufacturerFieldsFragment,
  OrderTypeSummaryFieldsFragment,
  OrgMaterialFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useCreateOrderFromQuoteConfig } from "./CreateOrderFromQuote.config";
import { CreateOrderFromQuoteFormValues } from "./CreateOrderFromQuoteForm";

const InnerContainer = tw.div`flex flex-col h-full`;
const Footer = tw.div`mt-10 w-full pb-7`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;

export type ItemizedQuoteTableItem = {
  id: string;
  quantityDecimal: string;
  name: string;
  material:
    | (OrgMaterialFieldsFragment & {
        manufacturer: ManufacturerFieldsFragment;
      })
    | undefined;
  costCode: string | undefined;
  UOM: string | null | undefined;
  unitPrice: string | undefined;
  extPrice: number;
};

type Props = {
  // form: UseFormReturn<CreateOrderFromQuoteFormValues, unknown, undefined>;
  tableItems: ItemizedQuoteTableItem[];
  orderTypes: OrderTypeSummaryFieldsFragment[];
  total: number;
  updateSubtotal: (data: Record<string, string>[]) => void;
};

export const CreateOrderFromQuoteItemizedView: FC<Props> = ({
  tableItems,
  total,
  updateSubtotal,
}) => {
  const form = useFormContext<CreateOrderFromQuoteFormValues>();
  const { release, loading: releaseLoading } = useRelease();
  const { loading } = useQuoteDocument();
  const { saveDescription, saveInstruction } = useInstruction();
  const spreadsheetViewColumns = useCreateOrderFromQuoteConfig();
  const { releaseInput } = useReleaseAdditionalChargesInput();
  const {
    prefillExtraOptions,
    prefillMaterialFields,
    prefillPrices,
    prefillForLumpSum,
    prefillTaxableValues,
    updateExtPriceDependence,
  } = usePrefillHelpers();
  const { prefillQuantityChange } = useOrderTableHelpers();
  const { instructionText, instructionAssets } = useGlobalReleaseInstructions();
  const { isWarehouseRelease } = useIsWarehouseRelease();

  const { watch, setValue } = form;

  const projectId = watch("projectId");
  const customTaxAmount = watch("customTaxAmount");

  useSetCurrentProjectId(projectId);

  useItemizedReleaseProjectBudgetSync();

  useInstructionReleaseSetup(!release && !releaseLoading);

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (values.additionalCharges) {
        setValue("additionalCharges", values.additionalCharges);
      }

      if (values.clearCustomTaxAmount !== undefined) {
        setValue("clearCustomTaxAmount", !!values.clearCustomTaxAmount);
      }
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue("customTaxAmount", values.customTaxAmount || undefined);
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }
      if (values.taxVariance || values.clearTaxVariance) {
        setValue(
          "taxVariance",
          values.clearTaxVariance ? undefined : values.taxVariance,
        );
      }
      if (values.timeTBD !== undefined && values.timeTBD !== null) {
        setValue("timeTBD", values.timeTBD);
      }

      return true;
    },
    [customTaxAmount, setValue],
  );

  return (
    <>
      <InnerContainer>
        <SpreadSheetView>
          <SpreadSheetTable
            items={tableItems}
            columns={spreadsheetViewColumns}
            saving={loading || releaseLoading}
            height="550px"
            onChanges={updateSubtotal}
            customPrefillFunctions={[
              prefillExtraOptions,
              prefillMaterialFields,
              prefillPrices,
              prefillForLumpSum,
              prefillTaxableValues,
              updateExtPriceDependence,
              prefillQuantityChange,
            ]}
            pageId={routes.editDeliveryFromQuote}
          />
        </SpreadSheetView>
      </InnerContainer>
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={`${total}`}
          releaseInput={releaseInput}
          customPaymentTerm={watch("paymentTerm")?.toString()}
          editableByContractor
          updateRelease={handleUpdateRelease}
          editablePaymentTerms
          additionalCharges={
            watch("additionalCharges") as AdditionalChargesFieldsFragment[]
          }
          includePaymentTerms={!isWarehouseRelease}
          includeAdditionalCharges={!isWarehouseRelease}
          includeSalesTax={!isWarehouseRelease}
          includeSubtotal={!isWarehouseRelease}
        />
        <Instructions
          projectId={projectId}
          instruction={release?.instructions}
          saveInstruction={saveInstruction}
          instructionsText={
            release || releaseLoading ? undefined : instructionText
          }
          instructionsAssets={
            release || releaseLoading ? undefined : instructionAssets
          }
        >
          <ReleaseDescription
            release={release}
            directlyEditable
            saveDescriptionCustomMethod={saveDescription}
          />
        </Instructions>
      </Footer>
    </>
  );
};

import { useDialog } from "@/common/components/dialog/DialogProvider";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { routes } from "@/config/routes";
import { useInventoryHelpers } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryHelpers";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { CreateInventoryAdjustmentItemInput } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useInventoryItemSequence } from "../../components/current-stock-list/components/hooks/useInventoryItemSequence";
import { InventoryAdjustmentFormValues } from "../components/inventory-adjustment-form/InventoryAdjustmentForm";
import { useInventoryAdjustmentMutations } from "./useInventoryAdjustmentMutations";
import { useInventoryAdjustmentTableValidators } from "./useInventoryAdjustmentTableValidators";

export const useSyncInventoryAdjustment = () => {
  const intl = useIntl();
  const { createInventoryAdjustment, createInventoryAdjustmentLoading } =
    useInventoryAdjustmentMutations();
  const { spreadsheetData, gotoInvalidRow } = useColumnMapper();
  const { addMissingMaterials, getCellWithAdditionalData } = useTableHelpers();
  const findMaterialByName = useFindMaterialByName();
  const { validateRequiredValues } = useTableValidators();
  const { validateRowValues } = useInventoryAdjustmentTableValidators();
  const { findInventoryItemByNameAndUom } = useInventoryHelpers();
  const { materials } = useMaterials();
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const { sequenceActive, navigateToNextSequence } = useInventoryItemSequence();

  const getItems = useCallback(async () => {
    const newItems: CreateInventoryAdjustmentItemInput[] = [];
    const newMaterials = (await addMissingMaterials()) || [];

    spreadsheetData
      .filter((row) => !rowIsEmpty(row))
      .forEach((row) => {
        const rowMaterialText = getCellWithAdditionalData(
          row,
          COLUMN_TYPE.Material,
        );
        const uom = getCellValue(row, COLUMN_TYPE.UOM);
        const inventoryItem = findInventoryItemByNameAndUom(
          rowMaterialText,
          uom,
        );
        let material = findMaterialByName(rowMaterialText, [
          ...materials,
          ...newMaterials,
        ]);
        const notes = getCellValue(row, COLUMN_TYPE.Notes);
        const quantity = getCellValue(row, COLUMN_TYPE.Quantity) || "0";
        const price = getCellValue(row, COLUMN_TYPE.UnitPrice) || "0";

        const newItem = {
          ...(inventoryItem
            ? {
                inventoryItemId: inventoryItem.id,
              }
            : {
                orgMaterialId: material?.id,
                uom: uom,
              }),
          notes,
          quantity,
          price: new DecimalSafe(quantity).gt(0) ? price : undefined,
        } as CreateInventoryAdjustmentItemInput;
        newItems.push(newItem);
      });

    return newItems;
  }, [
    spreadsheetData,
    materials,
    addMissingMaterials,
    getCellWithAdditionalData,
    findMaterialByName,
    findInventoryItemByNameAndUom,
  ]);

  const validateItems = useCallback(async () => {
    if (
      !(await validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ])) ||
      !(await validateRowValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.NewStock,
        COLUMN_TYPE.UnitPrice,
      ]))
    ) {
      gotoInvalidRow();
      return false;
    }
    return true;
  }, [gotoInvalidRow, validateRequiredValues, validateRowValues]);

  const onSave = useCallback(
    async (values: InventoryAdjustmentFormValues) => {
      const { warehouseId, date } = values;
      if (!(await validateItems())) {
        return undefined;
      }

      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "CONFIRM" }),
        includeWarningIcon: true,
        title: intl.$t({ id: "ADJUST_WAREHOUSE_STOCK" }),
        handleConfirm: async () => {
          const items = await getItems();
          const result = await createInventoryAdjustment({
            warehouseId,
            date: date ? new Date(date).getTime() : 0,
            instructions: values.instructions,
            items,
          });
          if (result) {
            if (sequenceActive) {
              navigateToNextSequence();
            } else {
              navigate(routes.currentStock);
            }
          }
        },
      });
    },
    [
      createInventoryAdjustment,
      getItems,
      intl,
      openDialog,
      navigate,
      validateItems,
      sequenceActive,
      navigateToNextSequence,
    ],
  );

  return {
    onSave,
    saving: createInventoryAdjustmentLoading,
  };
};

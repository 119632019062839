import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useInvoiceExtPrices } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoiceExtPrices";
import { useInvoiceMatchedOrder } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { useInvoiceValidation } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceValidationProvider";
import {
  PoFormat,
  ReleaseItemFieldsFragment,
  UpdateInvoicedReleaseItemInput,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";

export const useInvoiceExtPriceInput = (item: ReleaseItemFieldsFragment) => {
  const { useInvoiceItemPrice } = useInvoiceMatchedOrder();
  const { updateInvoice, invoice, excludePendingInvoices } =
    useInvoiceVerification();
  const { calcExtPrice, calcUnitPrice } = usePriceCalculation();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { loading } = useInvoiceValidation();

  const { extPrice: initialExtPrice, invoicedItem } = useInvoiceExtPrices(item);
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { invoiceValidation } = useInvoiceValidation();
  const { hasPhaseCodes } = useOrgSettings();

  const [extPrice, setExtPrice] = useState<string>(initialExtPrice);

  useEffect(() => {
    if (!isLumpSumItem(item)) {
      setExtPrice(initialExtPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialExtPrice, useInvoiceItemPrice]);

  const onSave = useCallback(
    async (extPrice: string | null) => {
      if (extPrice) {
        setExtPrice(extPrice);
      }

      if (!invoicedItem) {
        return;
      }

      let updatedItem: UpdateInvoicedReleaseItemInput & {
        releaseItemId: string;
        quantitySoFar?: string | null;
      } = {
        id: invoicedItem.id,
        releaseItemId: item.id,
        quantitySoFar: invoicedItem.quantitySoFar || "0",
      };
      if (isLumpSumItem(item)) {
        updatedItem = {
          ...updatedItem,
          unitPrice: "1",
          quantity: extPrice ? extPrice.toString() : undefined,
        };
      } else {
        updatedItem = {
          ...updatedItem,
          quantity: invoicedItem.quantity ?? undefined,
          unitPrice: calcUnitPrice(
            invoicedItem.quantity ?? 0,
            extPrice,
          ).toString(),
        };
      }

      await updateInvoice(
        {
          id: invoice?.id || "",
          updatedInvoicedReleaseItems: [updatedItem],
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
    },
    [
      item,
      invoice?.id,
      invoice?.release?.id,
      invoicedItem,
      updateInvoice,
      calcUnitPrice,
    ],
  );

  const inputClassName = useMemo(() => {
    if (!isLumpSumItem(item)) {
      return "text-blue-800 bg-white relative";
    }

    if (
      new DecimalSafe(extPrice)
        .plus(invoicedItem?.quantitySoFar ?? 0)
        .minus(
          excludePendingInvoices
            ? (invoicedItem?.unexportedQuantitySoFar ?? "0")
            : "0",
        )
        .greaterThan(item.quantityDecimal)
    ) {
      return "text-red-500 bg-white relative";
    }
    return "text-blue-800 bg-white relative";
  }, [
    excludePendingInvoices,
    extPrice,
    invoicedItem?.quantitySoFar,
    invoicedItem?.unexportedQuantitySoFar,
    item,
  ]);

  const isFullyInvoiced = useMemo(() => {
    return (
      isLumpSumItem(item) &&
      Number(extPrice) > 0 &&
      new DecimalSafe(item.quantityDecimal)
        .sub(invoicedItem?.quantitySoFar || 0)
        .add(
          excludePendingInvoices
            ? (invoicedItem?.unexportedQuantitySoFar ?? "0")
            : "0",
        )
        .equals(Number(extPrice))
    );
  }, [
    excludePendingInvoices,
    extPrice,
    invoicedItem?.quantitySoFar,
    invoicedItem?.unexportedQuantitySoFar,
    item,
  ]);

  const quantitySoFar = useMemo(() => {
    return new DecimalSafe(item.invoiceItems?.[0]?.quantitySoFar || "0")
      .plus(item.invoiceItems?.[0]?.quantity || "0")
      .minus(
        excludePendingInvoices
          ? (invoicedItem?.unexportedQuantitySoFar ?? "0")
          : "0",
      );
  }, [
    excludePendingInvoices,
    invoicedItem?.unexportedQuantitySoFar,
    item.invoiceItems,
  ]);

  const usingExternalPoRemainingAmount = useMemo(
    () =>
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.POItemRemainingAmount,
      ) &&
      !!invoice?.release?.poLink &&
      invoice.release.type.poFormat === PoFormat.Detail &&
      !hasPhaseCodes,
    [
      hasFeatureInConnectedSourceSystem,
      invoice?.release?.poLink,
      invoice?.release?.type.poFormat,
      hasPhaseCodes,
    ],
  );

  const externalPoItem = useMemo(
    () =>
      invoiceValidation?.po?.items.find(
        (externalItem) =>
          externalItem.externalId === item.poItemLink?.poItemExternalId,
      ),
    [invoiceValidation?.po?.items, item.poItemLink?.poItemExternalId],
  );

  const currentCoverage = useMemo(
    () =>
      invoice?.link
        ? 0
        : calcExtPrice(item.invoiceItems?.[0]?.quantity, item.unitPrice),
    [invoice?.link, calcExtPrice, item.invoiceItems, item.unitPrice],
  );

  const remaining = useMemo(() => {
    if (usingExternalPoRemainingAmount && externalPoItem) {
      return new DecimalSafe(externalPoItem.remainingAmount || 0)
        .minus(
          excludePendingInvoices ? 0 : item.invoicedRemainingAdjustment || 0,
        )
        .minus(currentCoverage)
        .toNumber();
    }

    const releaseItemExtPrice = calcExtPrice(
      item.unitPrice,
      item.quantityDecimal,
    );
    return new DecimalSafe(releaseItemExtPrice)
      .minus(item.invoicedRemainingAdjustment || 0)
      .minus(currentCoverage)
      .toNumber();
  }, [
    usingExternalPoRemainingAmount,
    externalPoItem,
    calcExtPrice,
    item.unitPrice,
    item.quantityDecimal,
    item.invoicedRemainingAdjustment,
    currentCoverage,
    excludePendingInvoices,
  ]);

  const isRemainingNegative = useMemo(() => remaining < 0, [remaining]);

  const remainingAmount = useMemo(() => {
    if (usingExternalPoRemainingAmount) {
      return new DecimalSafe(externalPoItem?.remainingAmount || 0).toNumber();
    }
  }, [externalPoItem?.remainingAmount, usingExternalPoRemainingAmount]);

  const pendingInvoicesTotal = useMemo(() => {
    if (usingExternalPoRemainingAmount) {
      return new DecimalSafe(item.invoicedRemainingAdjustment || 0)
        .plus(currentCoverage)
        .toNumber();
    }
  }, [
    currentCoverage,
    item.invoicedRemainingAdjustment,
    usingExternalPoRemainingAmount,
  ]);

  const invoiceTotal = useMemo(() => {
    if (usingExternalPoRemainingAmount) {
      return;
    }
    return new DecimalSafe(item.invoicedRemainingAdjustment || 0)
      .plus(extPrice || 0)
      .toNumber();
  }, [
    extPrice,
    item.invoicedRemainingAdjustment,
    usingExternalPoRemainingAmount,
  ]);

  const tooltipItems = useMemo(() => {
    if (usingExternalPoRemainingAmount) {
      return [
        {
          id: "INVOICE_TOOLTIP_REMAINING_AMOUNT_FOR_PO_ITEM",
          values: {
            value: formatCurrency(remainingAmount),
          },
        },
        {
          id: "INVOICE_TOOLTIP_PENDING_TOTAL",
          values: {
            value: formatCurrency(pendingInvoicesTotal),
          },
        },
      ];
    }
    return [
      {
        id: "INVOICE_TOOLTIP_TOTAL",
        values: {
          value: formatCurrency(invoiceTotal),
        },
      },
    ];
  }, [
    usingExternalPoRemainingAmount,
    formatCurrency,
    invoiceTotal,
    remainingAmount,
    pendingInvoicesTotal,
  ]);

  return {
    extPrice,
    setExtPrice,
    onSave,
    inputClassName,
    isFullyInvoiced,
    quantitySoFar,
    usingExternalPoRemainingAmount,
    externalPoItem,
    currentCoverage,
    remaining,
    isRemainingNegative,
    tooltipItems,
    loading,
  };
};

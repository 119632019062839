import { DiscardButton } from "@/common/components/button/DiscardButton";
import { If } from "@/common/components/if/If";
import { ExternalVendorFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const GrayContainer = tw.div`bg-gray-100 p-8 w-96 h-full`;
const Title = tw.div`text-sm font-medium pt-3`;
const Projects = tw.div`flex flex-col gap-2 mt-5`;
const Item = tw.div`flex justify-between text-sm bg-white items-center p-3 h-9`;
const Value = tw.div`text-xs`;

const EmptyList = tw.div`text-gray-500`;
const StyledDiscardButton = tw(DiscardButton)`m-0`;

type Props = {
  externalVendors: ExternalVendorFieldsFragment[];
  onRemove: (id: string) => void;
};

export const SelectedExternalVendors: FC<Props> = ({
  externalVendors,
  onRemove,
}) => (
  <GrayContainer>
    <Title>
      <FormattedMessage id="SELECTED" />
    </Title>
    <Projects>
      {externalVendors.map((externalVendor) => (
        <Item key={externalVendor.externalId}>
          <Value>{externalVendor.name}</Value>
          <StyledDiscardButton
            onClick={() => onRemove(externalVendor.externalId)}
          />
        </Item>
      ))}
      <If isTrue={!externalVendors.length}>
        <FormattedMessage id="NO_VENDORS_SELECTED" tagName={EmptyList} />
      </If>
    </Projects>
  </GrayContainer>
);

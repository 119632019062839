import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useGroupEditStore } from "../../stores/useGroupEditStore";
import {
  ADD_NEW_GROUP_ID,
  ALL_GROUP_ID,
  GroupFilterProps,
  UNASSIGNED_GROUP_ID,
} from "./GroupFilter";

type GroupInput = {
  name: string;
};

export const useGroupFilter = ({
  translationKeys,
  folders,
  value,
  onChange,
  createGroup,
  deleteGroup,
}: GroupFilterProps) => {
  const intl = useIntl();
  const [newFolder, setNewFolder] = useState<GroupInput>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);
  const { reset } = useGroupEditStore();
  const ref = useRef<HTMLDivElement>(null);
  const { setWarningAlert } = useSnackbar();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    reset();
  }, [reset]);

  const defaultOptions = useMemo(() => {
    return [
      ...(!inputValue
        ? [
            {
              id: ADD_NEW_GROUP_ID,
              name: intl.$t({ id: translationKeys.addNew }),
            },
          ]
        : []),
      {
        id: ALL_GROUP_ID,
        name: intl.$t({ id: translationKeys.all }),
      },
      {
        id: UNASSIGNED_GROUP_ID,
        name: intl.$t({ id: translationKeys.unassigned }),
      },
    ];
  }, [
    inputValue,
    intl,
    translationKeys.addNew,
    translationKeys.all,
    translationKeys.unassigned,
  ]);

  const options = useMemo(() => {
    return [
      ...defaultOptions,
      ...folders.toSorted((a, b) => (a.name || "").localeCompare(b.name || "")),
    ];
  }, [folders, defaultOptions]);

  const computedValue = useMemo(() => {
    if (!value) {
      return [];
    }
    if (!value.length) {
      return [UNASSIGNED_GROUP_ID];
    }
    return value.filter(
      (selectedValue) =>
        folders.some((folder) => folder.id === selectedValue) ?? [],
    );
  }, [folders, value]);

  const handleChange = useCallback(
    (folderIds: string[] | null) => {
      if (folderIds?.includes(ADD_NEW_GROUP_ID)) {
        setNewFolder({
          name: inputValue,
        });
        setAnchorEl(ref.current);
      } else if (
        (folderIds?.length && folderIds.slice(-1)[0] === ALL_GROUP_ID) ||
        !folderIds?.length
      ) {
        onChange(undefined);
      } else if (
        folderIds?.length &&
        folderIds.slice(-1)[0] === UNASSIGNED_GROUP_ID
      ) {
        onChange([]);
      } else if (
        folderIds?.length &&
        !folders.some((folder) => folder.id === folderIds.slice(-1)[0])
      ) {
        setNewFolder({
          name: folderIds.slice(-1)[0],
        });
        setAnchorEl(ref.current);
      } else {
        onChange(
          folderIds?.filter(
            (folderId) =>
              folderId !== ALL_GROUP_ID &&
              folderId !== UNASSIGNED_GROUP_ID &&
              folders.some((folder) => folder.id === folderId),
          ) ?? undefined,
        );
      }
    },
    [onChange, folders, inputValue],
  );

  const onClose = useCallback(() => {
    setNewFolder(undefined);
    setAnchorEl(null);
  }, []);

  const handleCreate = useCallback(async () => {
    if (newFolder) {
      const existingFolder = folders.find(
        (folder) => folder.name === newFolder.name.trim(),
      );
      if (existingFolder) {
        setWarningAlert(intl.$t({ id: "GROUP_ALREADY_EXISTS" }));
        return;
      }
      setLoading(true);
      const result = await createGroup(newFolder);
      if (result) {
        onClose();
      }
    }
    setLoading(false);
  }, [newFolder, createGroup, onClose, folders, setWarningAlert, intl]);

  const onChangeNewFolder = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewFolder({
        ...newFolder,
        name: event.target.value,
      });
    },
    [newFolder],
  );

  const handleDeleteGroup = useCallback(
    async (id: string) => {
      onChange(undefined);
      return await deleteGroup(id);
    },
    [deleteGroup, onChange],
  );

  const emptyFolder = useMemo(
    () => ({
      id: ADD_NEW_GROUP_ID,
      name: intl.$t({ id: translationKeys.addNew }),
      readOnly: true,
    }),
    [intl, translationKeys.addNew],
  );

  return {
    computedValue,
    handleChange,
    handleCreate,
    onChangeNewFolder,
    handleDeleteGroup,
    loading,
    anchorEl,
    ref,
    newFolder,
    options,
    setNewFolder,
    onClose,
    inputValue,
    setInputValue,
    emptyFolder,
  };
};

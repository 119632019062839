import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  ExpandedReleaseItem,
  useRelease,
} from "../../../providers/ReleaseProvider";
import { useOrderItemPoItemReferences } from "./useOrderItemPoItemReferences";

export const useReleaseItemsToSubmit = () => {
  const { release } = useRelease();
  const intl = useIntl();
  const { setMetadata } = useColumnMapper();
  const { connectedSourceSystem } = useOrgSettings();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();
  const { vendors, getVendorCode } = useVendors();
  const { warehouses } = useWarehouses();

  return useMemo(() => {
    return (
      release?.items.map((item) => {
        if (
          hasOrderItemPoItemReferences(item, release) &&
          item.position !== undefined &&
          item.position !== null
        ) {
          setMetadata(item.position, {
            warningIconTooltipText: intl.$t(
              { id: "ITEM_WILL_NEED_TO_BE_MANUALLY_ADDED_TO_EXTERNAL_PO" },
              { sourceSystem: connectedSourceSystem },
            ),
          });
        }

        const estimatedItems =
          item.projectItem?.estimatedItems.filter(
            (ei) => ei.zone?.id === item.zone?.id,
          ) ?? [];

        if (estimatedItems.length === 0 && item.uom) {
          estimatedItems.push({
            id: item.id,
            quantityDecimal: item.quantityDecimal,
            orderedQuantity: "0",
            duplicateCount: 0,
            zone: item.zone,
            tags: item.tags,
            uom: item.uom,
          });
        }

        const itemVendor = item.sellerOrgLocation?.id
          ? vendors.find(
              (vendor) =>
                vendor.sellerOrgLocation.id === item.sellerOrgLocation?.id,
            )
          : null;

        const itemWarehouse = item.sourceWarehouse?.id
          ? warehouses.find(
              (warehouse) => warehouse.id === item.sourceWarehouse?.id,
            )
          : null;

        return {
          ...item.projectItem,
          ...item,
          material: {
            ...item.projectItem?.material,
            material: {
              ...item.projectItem?.material.material,
              name: item.name,
            },
          },
          estimatedItems,
          vendorName: itemVendor
            ? vendorLabelFormatter(itemVendor.sellerOrgLocation, [], {
                vendorCode: getVendorCode(itemVendor),
              })
            : itemWarehouse
              ? itemWarehouse.name
              : "",
        } as ExpandedReleaseItem;
      }) ?? []
    );
  }, [
    connectedSourceSystem,
    getVendorCode,
    hasOrderItemPoItemReferences,
    intl,
    release,
    setMetadata,
    vendors,
    warehouses,
  ]);
};

import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  ExternalComplianceGroupFieldsFragment,
  SourceSystem,
  useComplianceGroupsQuery,
} from "@/generated/graphql";
import React, { FC, createContext, useContext } from "react";
import { useAgaveHostedWizard } from "../../AgaveHostedWizardProvider";

type ExternalComplianceGroupType = Omit<
  ExternalComplianceGroupFieldsFragment,
  "__typename"
> & {
  id: string;
  index: number;
};

type ProviderContextType = {
  externalComplianceGroups: ExternalComplianceGroupType[];
  loadingExternalComplianceGroups: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  externalComplianceGroups: [],
  loadingExternalComplianceGroups: false,
});

export const ExternalMaterialComplianceGroupsProvider: FC<{
  children: React.ReactNode;
  defaultSourceSystem?: SourceSystem;
}> = ({ children, defaultSourceSystem }) => {
  const { sourceSystem } = useAgaveHostedWizard();

  const {
    data: externalComplianceGroupsData,
    loading: loadingExternalComplianceGroups,
    error: externalComplianceGroupsError,
  } = useComplianceGroupsQuery({
    variables: {
      sourceSystem:
        defaultSourceSystem ?? sourceSystem ?? SourceSystem.Foundation,
    },
    skip: !defaultSourceSystem && !sourceSystem,
  });
  useErrorEffect(externalComplianceGroupsError);

  return (
    <ProviderContext.Provider
      value={{
        externalComplianceGroups:
          externalComplianceGroupsData?.externalComplianceGroups.map(
            (i, index) => ({
              ...i,
              id: i.externalId,
              index,
            }),
          ) ?? [],
        loadingExternalComplianceGroups,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExternalMaterialComplianceGroups = () =>
  useContext(ProviderContext);

import { If } from "@/common/components/if/If";
import { useAllItemsCheckboxSelection } from "@/common/components/pagination/hooks/useAllItemsCheckboxSelection";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useDeliveries } from "../providers/DeliveriesProvider";
import { useReleaseSequence } from "../providers/ReleaseSequenceProvider";

const Container = tw.div`
  flex -ml-2
`;

export const DeliveryAllItemsCheckbox: FC = () => {
  const {
    sequenceIds,
    selectedReleases,
    setSelectedReleases,
    allReleasesSelected,
  } = useReleaseSequence();
  const { deliveries } = useDeliveries();
  const { checked, handleChange } = useAllItemsCheckboxSelection(
    deliveries,
    selectedReleases,
    setSelectedReleases,
  );

  return (
    <Container>
      <If isTrue={!allReleasesSelected}>
        <SelectionCheckbox
          items={sequenceIds}
          setSelection={handleChange}
          checked={checked}
        />
      </If>
    </Container>
  );
};

import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useRefreshExternalPOsMutation } from "@/generated/graphql";

export const useRefreshExternalPOs = () => {
  const [refreshExternalPOsMutation, { loading }] =
    useRefreshExternalPOsMutation();
  const { connectedSourceSystem } = useOrgSettings();
  const { setError } = useGlobalError();

  const refreshExternalPOs = async (projectId: string) => {
    if (!connectedSourceSystem) {
      return;
    }
    try {
      const { errors } = await refreshExternalPOsMutation({
        variables: {
          projectId,
          sourceSystem: connectedSourceSystem,
        },
      });
      if (errors) {
        setError(errors);
      }
    } catch (error) {
      setError(error);
    }
  };
  return { refreshExternalPOs, loading };
};

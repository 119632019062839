import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import tw from "tailwind-styled-components";
import { Supplier, SupplierVendor } from "../../types/Supplier";

const Container = tw.div`ml-2 gap-2 absolute inset-0 flex items-center pl-2 right-8 overflow-hidden text-nowrap`;

export const SupplierSelectorCustomRendererWithIcon = ({
  item,
}: {
  item: Supplier | undefined;
}) => {
  const vendor = item as SupplierVendor;
  return (
    <Container>
      <OrgLogo
        logoImageUrl={vendor.sellerOrgLocation?.org?.photoUrl}
        name={
          vendor.sellerOrgLocation?.org.id
            ? vendor.sellerOrgLocation?.org?.name
            : ""
        }
        width={24}
      />
      {item?.name ?? vendor.sellerOrgLocation.name}
    </Container>
  );
};

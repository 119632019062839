import { composeMaterialName } from "@/common/utils/composeMaterialName";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { OrgMaterialFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { sanitizeMaterialName } from "../utils/sanitizeMaterialName";

export const useFormattedMaterialName = () => {
  const { materials } = useMaterials();
  const { settings } = useOrgSettings();

  return useCallback(
    (material: OrgMaterialFieldsFragment | string) => {
      if (!material) {
        return "";
      }
      if (typeof material === "string") {
        return sanitizeMaterialName(material);
      } else {
        return composeMaterialName(
          material,
          materials,
          !!settings?.display?.appendExternalCode,
        );
      }
    },
    [materials, settings?.display?.appendExternalCode],
  );
};

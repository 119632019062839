import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { useUsers } from "@/common/hooks/useUsers";
import { getUserName } from "@/common/utils/users/getUserName";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { OrgSettingsFormInput } from "../OrgSettingsFormHookProvider";

type ProjectDefaultUsersSelectorProps = {
  locationId: string;
  locationName: string;
};

export const ProjectDefaultUsersSelector = ({
  locationId,
  locationName,
}: ProjectDefaultUsersSelectorProps) => {
  const { users } = useUsers({ requireLocation: true, locationId });
  const { watch, setValue } = useFormContext<OrgSettingsFormInput>();

  const projectDefaultTeam = watch("settings.project.defaultTeam");

  const values = useMemo(
    () =>
      projectDefaultTeam?.find((team) => team.orgLocationId === locationId)
        ?.userIds ?? [],
    [locationId, projectDefaultTeam],
  );

  const options = useMemo(
    () =>
      users?.map((user) => ({ value: user.id, label: getUserName(user) })) ??
      [],
    [users],
  );

  const handleMultipleChange = useCallback(
    (value: string[] | null) => {
      const teamExists =
        projectDefaultTeam?.some((team) => team.orgLocationId === locationId) ??
        false;
      if (teamExists) {
        setValue(
          "settings.project.defaultTeam",
          projectDefaultTeam?.map((team) =>
            team.orgLocationId === locationId
              ? { orgLocationId: team.orgLocationId, userIds: value ?? [] }
              : team,
          ),
        );
        return;
      }
      setValue("settings.project.defaultTeam", [
        ...(projectDefaultTeam ?? []),
        { orgLocationId: locationId, userIds: value ?? [] },
      ]);
    },
    [locationId, projectDefaultTeam, setValue],
  );

  return (
    <Multiselect
      options={options}
      values={values}
      getLabel={(option) => option.label}
      getValue={(option) => option.value}
      chipSize="small"
      includeCheckbox
      limitTags={1}
      disableCloseOnSelect
      label={locationName}
      onMultipleChange={handleMultipleChange}
    />
  );
};

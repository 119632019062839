import { isAuthorized } from "@/common/utils/isAuthorized";
import { routes } from "@/config/routes";
import { Project } from "@/generated/graphql";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router";

type ProjectForNavigation = {
  permissions: Pick<Project["permissions"], "report">;
  id: Project["id"];
};

export const useProjectNavigate = () => {
  const navigate = useNavigate();

  const getProjectPath = useCallback(
    (project: ProjectForNavigation | undefined | null) => {
      if (!project) {
        return undefined;
      }
      return generatePath(
        isAuthorized(project.permissions.report)
          ? routes.projectBudgetReport
          : routes.projectDetails,
        { id: project.id },
      );
    },
    [],
  );

  const navigateToProject = useCallback(
    (project: ProjectForNavigation | undefined) => {
      const path = getProjectPath(project);
      if (path) {
        navigate(path);
      }
    },
    [getProjectPath, navigate],
  );

  return {
    navigateToProject,
    getProjectPath,
  };
};

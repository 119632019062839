import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { SpreadsheetSaveType } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { useCallback } from "react";
import { useSyncEstimatedItems } from "../../hooks/useSyncEstimatedItems";
import { UPLOAD_MATERIALS_DRAWER } from "../import-project-items/upload-materials-drawer/UploadMaterialsDrawer";

export const ProjectAddItems = () => {
  const { toggle } = useGlobalDrawer();
  const { syncEstimatedItems } = useSyncEstimatedItems();

  const showImportItemsPanel = useCallback(async () => {
    const result = await syncEstimatedItems({
      trigger: SpreadsheetSaveType.Toggle,
    });
    if (result) {
      toggle(UPLOAD_MATERIALS_DRAWER, true);
    }
  }, [syncEstimatedItems, toggle]);

  return (
    <>
      <AddItemsButton textId="IMPORT_ITEMS" onClick={showImportItemsPanel} />
    </>
  );
};

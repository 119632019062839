import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useColumnMapper } from "../../providers/ColumnMapperProvider";
import { rowIsEmpty } from "../../utils/rowIsEmpty";

const LinkLikeStyled = tw(LinkLike)`mx-4 my-2 w-fit`;

type Props = {
  hideAddNewRow?: boolean;
  readOnly?: boolean;
};

export const AddNewRowButton = ({ hideAddNewRow, readOnly }: Props) => {
  const { handsonInstance } = useColumnMapper();

  const addNewRow = useCallback(() => {
    let lastNonEmptyRow =
      handsonInstance?.getData().findLastIndex((row) => !rowIsEmpty(row)) ?? -1;
    handsonInstance?.alter(
      "insert_row_below",
      lastNonEmptyRow === -1 ? 0 : lastNonEmptyRow,
      1,
    );
    handsonInstance?.selectRows(lastNonEmptyRow + 1, lastNonEmptyRow + 1);
  }, [handsonInstance]);

  return (
    <If isTrue={!hideAddNewRow && !readOnly}>
      <LinkLikeStyled onClick={addNewRow}>
        <FormattedMessage id="ADD_NEW_ROW" />
      </LinkLikeStyled>
    </If>
  );
};

import { gql } from "@apollo/client";

export const VENDOR_CONTACT_FIELDS = gql`
  fragment VendorContactFields on VendorContact {
    id
    name
    cellPhone
    cellPhoneExtension
    email
    receivesQuoteNotifications
    receivesBuyoutNotifications
    receivesOrderNotifications
    receivesInvoiceNotifications
  }
`;

import { useMemo } from "react";
import { useColumnConfig } from "../../../hooks/useColumnConfig";
import {
  SpreadSheetConfig,
  useColumnMapper,
} from "../../../providers/ColumnMapperProvider";

export const useExtendedColumnConfig = (baseConfig?: SpreadSheetConfig[]) => {
  const { config: contextConfig } = useColumnMapper();
  const config = baseConfig || contextConfig;
  const getExtendedColumnConfig = useColumnConfig();

  return useMemo(
    () => getExtendedColumnConfig(config),
    [config, getExtendedColumnConfig],
  );
};

import {
  CheckCircleOutlineRounded,
  SettingsSuggestOutlined,
} from "@mui/icons-material";
import { ReactNode } from "react";
import { BsExclamationCircle } from "react-icons/bs";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

export enum SystemAlertType {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
  ERROR = "ERROR",
}

interface SystemAlertProps {
  messages: string[] | ReactNode;
  type?: SystemAlertType;
}

const AlertContainer = tw.div`flex`;
const AlertIconContainer = tw.div`bg-white w-20 min-h-20 flex justify-center items-center mr-4`;
const AlertInfoContainer = tw.div`flex flex-col gap-2 flex-1 py-4 justify-center`;
const AlertComponentContainer = tw.div`text-white`;
const AlertInfoBlock = tw.p`text-white`;
const GearIcon = tw(SettingsSuggestOutlined)`w-10 h-10 text-green-800`;
const CheckCircleOutlineRoundedStyled = tw(
  CheckCircleOutlineRounded,
)`w-10 h-10 text-green-800`;
const ExclamationCircleRed = tw(BsExclamationCircle)`w-10 h-10 fill-red-500`;

export const SystemAlert = ({
  messages,
  type = SystemAlertType.AUTOMATIC,
}: SystemAlertProps) => {
  return (
    <AlertContainer
      className={type === SystemAlertType.ERROR ? "bg-red-500" : "bg-green-800"}
    >
      <AlertIconContainer>
        <If isTrue={type === SystemAlertType.AUTOMATIC}>
          <GearIcon />
        </If>
        <If isTrue={type === SystemAlertType.MANUAL}>
          <CheckCircleOutlineRoundedStyled />
        </If>
        <If isTrue={type === SystemAlertType.ERROR}>
          <ExclamationCircleRed />
        </If>
      </AlertIconContainer>
      <AlertInfoContainer>
        {messages && Array.isArray(messages) ? (
          messages.map((message, index) => (
            <AlertInfoBlock key={index}>{message}</AlertInfoBlock>
          ))
        ) : (
          <AlertComponentContainer>{messages}</AlertComponentContainer>
        )}
      </AlertInfoContainer>
    </AlertContainer>
  );
};

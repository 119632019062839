import { EDITABLE_RELEASE_STATUSES } from "@/common/const";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { useRelease } from "../../providers/ReleaseProvider";
import { SpecifyDetailsDraftHeader } from "./components/header/SpecifyDetailsDraftHeader";
import { SpecifyDetailsNonDraftHeader } from "./components/header/SpecifyDetailsNonDraftHeader";

export const SpecifyDetailsHeader = () => {
  const { release } = useRelease();
  const intl = useIntl();

  const isReleaseFullyEditable = useMemo(
    () => checkReleaseStatus(release, EDITABLE_RELEASE_STATUSES),
    [release],
  );
  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "RELEASE" })}</title>
      </Helmet>
      {isReleaseFullyEditable ? (
        <SpecifyDetailsDraftHeader />
      ) : (
        <SpecifyDetailsNonDraftHeader />
      )}
    </>
  );
};

import { useSyncPoMutation } from "@/generated/graphql";

export const useSyncPO = () => {
  const [syncPoMutation, { loading }] = useSyncPoMutation();

  const syncPO = async (poLinkId?: string) => {
    if (poLinkId) {
      const { data } = await syncPoMutation({
        variables: {
          poLinkId,
        },
      });
      return !!data?.syncPO;
    }
    return false;
  };

  return { syncPO, syncingPO: loading };
};

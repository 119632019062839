import { Dialog } from "@/common/components/dialog/Dialog";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useSwitchInventoryItems } from "./hooks/useSwitchInventoryItems";
import { SwitchInventoryItemList } from "./inventory-items-list/SwitchInventoryItemList";
import { useSwitchInventoryItemConfiguration } from "./inventory-items-list/SwitchInventoryItemList.configuration";

const Container = tw.div`w-[calc(80vw)] h-[calc(80vh)] flex-1 overflow-scroll flex flex-col`;

export const SwitchInventoryItemDialog = () => {
  const intl = useIntl();
  const configuration = useSwitchInventoryItemConfiguration();
  const {
    switchItem,
    openInventoryModal,
    setOpenInventoryModal,
    resetFilters,
    selectedItem,
  } = useSwitchInventoryItems();

  const handleCancel = useCallback(() => {
    setOpenInventoryModal(false);
  }, [setOpenInventoryModal]);

  const handleConfirm = useCallback(() => {
    resetFilters();
    switchItem();
    setOpenInventoryModal(false);
  }, [setOpenInventoryModal, switchItem, resetFilters]);

  const dialogContent = useMemo(
    () => (
      <Container>
        <SwitchInventoryItemList columns={configuration} />
      </Container>
    ),
    [configuration],
  );

  const buttonsConfiguration = useMemo(
    () => ({
      cancel: {
        wide: true,
      },
      confirm: {
        wide: true,
      },
    }),
    [],
  );

  const dialogSx = useMemo(
    () => ({
      "& .MuiDialog-paper": {
        minHeight: "80vh",
      },
    }),
    [],
  );

  return (
    <Dialog
      confirmButtonText={intl.$t({ id: "REPLACE" })}
      handleConfirm={handleConfirm}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      buttonsConfiguration={buttonsConfiguration}
      disabledConfirmButton={!selectedItem}
      sx={dialogSx}
      extraButtonMargin={false}
      handleCancel={handleCancel}
      maxWidth={false}
      show={openInventoryModal}
      content={dialogContent}
    />
  );
};

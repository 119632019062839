import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFormattedMaterialName } from "@/common/components/spreadsheet-table/hooks/useFormattedMaterialName";
import { ExtraOption } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { addExtraItemDetails } from "@/common/components/spreadsheet-table/utils/addExtraItemDetails";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";

export const useInventoryAdjustmentItemsDecorator = (): {
  extraOptions: ExtraOption[];
} => {
  const { materials } = useMaterials();
  const { inventoryItems } = useInventoryItems();
  const getFormattedMaterialName = useFormattedMaterialName();

  const sortedInventoryItems = useMemo(() => {
    return [...inventoryItems].sort((a, b) => {
      const totalA = getInventoryItemRemainingQuantity(a);
      const totalB = getInventoryItemRemainingQuantity(b);
      const quantityA = totalA.toNumber();
      const quantityB = totalB.toNumber();

      if (quantityA === 0 && quantityB === 0) {
        return 0;
      }
      if (quantityA === 0) {
        return 1;
      }
      if (quantityB === 0) {
        return -1;
      }

      const nameA = a.orgMaterial.material?.name ?? "";
      const nameB = b.orgMaterial.material?.name ?? "";
      return nameA.localeCompare(nameB);
    });
  }, [inventoryItems]);

  const mapToExtraOption = useCallback(
    (inventoryItem: InventoryItemFieldsFragment): ExtraOption => {
      const material = materials.find(
        (m) => m.id === inventoryItem.orgMaterial.id,
      );
      const totalQuantity = getInventoryItemRemainingQuantity(inventoryItem);
      const totalQuantityNumber = totalQuantity.toNumber();

      const itemDetails = addExtraItemDetails(
        `${totalQuantity.toString()} ${inventoryItem.uom.pluralDescription}`,
        totalQuantityNumber > 0 ? "text-blue-800" : "text-blue-200",
      );

      return {
        name: material ? getFormattedMaterialName(material) : "",
        content: itemDetails,
        value: inventoryItem.id,
        prefilling: [
          [COLUMN_TYPE.UOM, inventoryItem.uom.pluralDescription],
          [
            COLUMN_TYPE.ExistingStock,
            getInventoryItemRemainingQuantity(inventoryItem).toNumber(),
          ],
          [COLUMN_TYPE.NewStock, 0],
        ],
      };
    },
    [materials, getFormattedMaterialName],
  );

  const extraOptions = useMemo(
    () => sortedInventoryItems.map(mapToExtraOption),
    [sortedInventoryItems, mapToExtraOption],
  );

  return { extraOptions };
};

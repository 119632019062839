import { DatePicker } from "@/common/components/picker/components/DatePicker";
import {
  TextField,
  TextFieldProps,
} from "@/common/components/textfield/TextField";
import { useIntl } from "react-intl";
import { InvoiceDocumentOptionsProps } from "./types/InvoiceDocumentOptions.types";

type Props = Pick<
  InvoiceDocumentOptionsProps,
  "readonly" | "includeInputLabels"
> & {
  accountingDate: Date | null;
  setAccountingDate: (date: Date | null) => void;
};

const DatePickerTextField = (props: TextFieldProps) => (
  <TextField
    {...props}
    size="small"
    sx={{
      ".MuiInputBase-input": {
        fontSize: "14px",
        textAlign: "center",
      },
      ".MuiButtonBase-root": {
        color: "#293B59",
      },
      ".MuiInputAdornment-root": {
        marginRight: "0.25rem",
      },
    }}
  />
);

export const InvoiceAccountingDate = ({
  includeInputLabels,
  readonly,
  accountingDate,
  setAccountingDate,
}: Props) => {
  const intl = useIntl();

  return (
    <DatePicker
      date={accountingDate}
      onChange={setAccountingDate}
      utc
      className="overflow-hidden rounded bg-white"
      textFieldComponent={DatePickerTextField}
      readonly={readonly}
      label={
        includeInputLabels ? intl.$t({ id: "INVOICE_ACCOUNTING_DATE" }) : ""
      }
    />
  );
};

import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  AddOrgLocationInput,
  AddOrgPreferredVendorInput,
  AddVendorOrgInput,
  ImportVendorsInput,
  OrgPreferredVendorsDocument,
  UpdateOrgLocationInput,
  UpdateOrgPreferredVendorInput,
  useAddVendorLocationMutation,
  useAddVendorOrgMutation,
  useCreateVendorMutation,
  useImportVendorsMutation,
  useRemoveVendorMutation,
  useUpdateVendorLocationMutation,
  useUpdateVendorMutation,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useAdminVendors } from "./useAdminVendors";

export const useVendorMutations = () => {
  const { setError } = useGlobalError();
  const { refetch: refetchAdminVendors } = useAdminVendors();
  const { refetch: refetchVendors } = useVendors({ skipQuery: true });

  const [createVendorMutation, { loading: createVendorLoader }] =
    useCreateVendorMutation();
  const [updateVendorMutation, { loading: updateVendorLoader }] =
    useUpdateVendorMutation();
  const [removeVendorMutation] = useRemoveVendorMutation();
  const [addVendorOrgMutation] = useAddVendorOrgMutation();
  const [addVendorLocationMutation] = useAddVendorLocationMutation();
  const [updateVendorLocationMutation] = useUpdateVendorLocationMutation();
  const [importVendorsMutation, { loading: importingVendors }] =
    useImportVendorsMutation();

  const loading = useMemo(
    () => createVendorLoader || updateVendorLoader,
    [createVendorLoader, updateVendorLoader],
  );

  const refetchCachedVendors = useCallback(() => {
    refetchAdminVendors();
    refetchVendors();
  }, [refetchAdminVendors, refetchVendors]);

  const createVendorOrg = useCallback(
    async (vendor: AddVendorOrgInput) => {
      try {
        const { data, errors } = await addVendorOrgMutation({
          variables: { input: vendor },
        });
        setError(errors);
        return data?.addVendorOrg;
      } catch (errors) {
        setError(errors);
      }
    },
    [addVendorOrgMutation, setError],
  );

  const createVendor = useCallback(
    async (vendor: AddOrgPreferredVendorInput) => {
      try {
        const { data, errors } = await createVendorMutation({
          variables: { input: vendor },
        });
        await refetchCachedVendors();
        setError(errors);
        return data?.addOrgPreferredVendor.find(
          (o) => o.sellerOrgLocation.id === vendor.orgLocationID,
        );
      } catch (errors) {
        setError(errors);
      }
    },
    [createVendorMutation, refetchCachedVendors, setError],
  );

  const updateVendor = useCallback(
    async (vendor: UpdateOrgPreferredVendorInput) => {
      try {
        const { data, errors } = await updateVendorMutation({
          variables: { input: vendor },
          refetchQueries: [{ query: OrgPreferredVendorsDocument }],
          awaitRefetchQueries: true,
        });
        await refetchCachedVendors();
        setError(errors);
        return data?.updateOrgPreferredVendor;
      } catch (errors) {
        setError(errors);
      }
    },
    [updateVendorMutation, refetchCachedVendors, setError],
  );

  const removeVendor = useCallback(
    async (id: string) => {
      try {
        const { errors } = await removeVendorMutation({
          variables: { id },
          refetchQueries: [{ query: OrgPreferredVendorsDocument }],
          awaitRefetchQueries: true,
        });
        await refetchCachedVendors();
        setError(errors);
        return !errors;
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [refetchCachedVendors, removeVendorMutation, setError],
  );

  const addVendorLocation = useCallback(
    async (vendorLocation: AddOrgLocationInput) => {
      try {
        const { data, errors } = await addVendorLocationMutation({
          variables: {
            input: { ...vendorLocation, name: vendorLocation.name || null },
          },
          refetchQueries: [{ query: OrgPreferredVendorsDocument }],
          awaitRefetchQueries: true,
        });
        await refetchCachedVendors();
        setError(errors);
        return data?.addVendorLocation;
      } catch (errors) {
        setError(errors);
      }
    },
    [addVendorLocationMutation, refetchCachedVendors, setError],
  );

  const updateVendorLocation = useCallback(
    async (updatedVendorLocation: UpdateOrgLocationInput) => {
      try {
        const { data, errors } = await updateVendorLocationMutation({
          variables: {
            input: updatedVendorLocation,
          },
          refetchQueries: [{ query: OrgPreferredVendorsDocument }],
        });
        await refetchCachedVendors();
        setError(errors);
        return data?.updateVendorLocation;
      } catch (errors) {
        setError(errors);
      }
    },
    [updateVendorLocationMutation, refetchCachedVendors, setError],
  );

  const importVendors = useCallback(
    async (input: ImportVendorsInput) => {
      try {
        const { data, errors } = await importVendorsMutation({
          variables: { input },
        });
        await refetchCachedVendors();
        setError(errors);
        return data?.importVendors;
      } catch (errors) {
        setError(errors);
      }
    },
    [importVendorsMutation, refetchCachedVendors, setError],
  );

  return {
    loading,
    createVendorOrg,
    createVendor,
    updateVendor,
    removeVendor,
    addVendorLocation,
    updateVendorLocation,
    importVendors,
    importingVendors,
    refetchCachedVendors,
  };
};

import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useUpdateContractorReleases } from "@/contractor/pages/home/release/components/connections/hooks/useUpdateContractorReleases";
import { PoLink, Release } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceValidation } from "../../scanned-invoices/providers/InvoiceValidationProvider";

const Order = tw.div`mt-2 text-sm text-gray-500`;

export const useMarkPoAsNotExportable = () => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { updateContractorReleases } = useUpdateContractorReleases();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const { connectedSourceSystem } = useOrgSettings();

  const markAsNotExportable = useCallback(
    (
      releases:
        | (Pick<Release, "poNumber" | "id" | "sequenceNumber"> & {
            poLink?: Pick<PoLink, "id"> | undefined | null;
          })[]
        | null
        | undefined,
      opts?: {
        unlink?: boolean;
        useInvoiceValidation?: boolean;
        callback?: () => void;
      },
    ) => {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        title: intl.$t({
          id: opts?.unlink
            ? "DISCONNECT_PO"
            : "SET_ORDER_AS_NOT_TO_BE_EXPORTED",
        }),
        text: intl.$t(
          {
            id: opts?.unlink
              ? "DISCONNECT_PO_CONFIRMATION"
              : "SET_ORDER_AS_NOT_TO_BE_EXPORTED_CONFIRMATION",
          },
          {
            poNumber: releases?.map((release) => release.poNumber).join(", "),
            orders: releases
              ?.filter((release) => release.poLink?.id)
              .map((release) => (
                <Order key="order">{`${intl.$t({ id: "RELEASE_NAME" }, { number: release.sequenceNumber })} (${intl.$t({ id: "PO_HASH" })}${release.poNumber})`}</Order>
              )),
            br: <br />,
            integration: connectedSourceSystem
              ? intl.$t({ id: `SOURCE_SYSTEM_${connectedSourceSystem}` })
              : "",
          },
        ),
        includeWarningIcon: true,
        handleConfirm: async () => {
          if (!releases?.length) {
            return;
          }
          await updateContractorReleases({
            releaseIds: releases.map((r) => r.id),
            useSourceSystemPO: false,
          });
          if (opts?.useInvoiceValidation) {
            refetchInvoiceValidation();
          }

          opts?.callback?.();
        },
      });
    },
    [
      openDialog,
      intl,
      connectedSourceSystem,
      updateContractorReleases,
      refetchInvoiceValidation,
    ],
  );

  return { markAsNotExportable };
};

import { MAX_VENDOR_NUMBER } from "@/common/const";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { GroupRenderer } from "../select/components/renderer/GroupRenderer";
import { SelectControlled } from "../select/components/single/SelectControlled";
import { SupplierInsuranceInfo } from "../supplier-insurance-info/SupplierInsuranceInfo";
import { Tooltip } from "../tooltip/Tooltip";
import { useVendors } from "../vendors/hooks/useVendors";
import { WarehouseSelectorCustomRendererWithIcon } from "../warehouse-selector/renderer/WarehouseSelectorCustomRendererWithIcon";
import { SupplierPickerCustomRender } from "./components/renderers/SupplierPickerCustomRender";
import { SupplierSelectorCustomRendererWithIcon } from "./components/renderers/SupplierSelectorCustomRendererWithIcon";
import { warningStyles } from "./const/warningStyles";
import { useWarehouseSupplier } from "./hooks/useWarehouseSupplier";
import { Supplier, SupplierVendor } from "./types/Supplier";
import { useSupplierOptions } from "./useSupplierOptions";
import { getVendorSupplierBySupplier } from "./utils/isVendorSupplier";
import { vendorLabelFormatter } from "./utils/vendorLabelFormatter";

type Props = {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  staticText?: boolean;
  autoSelectDependencies?: boolean;
  disableClearable?: boolean;
  filterResults?: (supplier: Supplier) => boolean;
  contactsFilter?: (
    contact: OrgPreferredVendorsFieldsFragment["contacts"][0],
  ) => boolean;
  formatFullLabel?: boolean;
  includeWarehouses?: boolean;
  withAdditionalAdornment?: boolean;
  warningTooltip?: string;
};

export const SupplierPickerControlled: FC<Props> = ({
  required,
  contactsFilter = () => true,
  name = "vendorId",
  autoSelectDependencies = true,
  filterResults,
  formatFullLabel,
  includeWarehouses,
  withAdditionalAdornment = false,
  warningTooltip,
  ...props
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contactsFilterMemoized = useCallback(contactsFilter, []);
  const { settings } = useOrgSettings();
  const {
    loading,
    supplierOptions,
    findOrderTypeByLocationId,
    findPaymentTermByLocationId,
    findCostCodeIdByLocationId,
  } = useSupplierOptions({
    contactsFilter: contactsFilterMemoized,
    includeWarehouses: includeWarehouses && settings?.inventory.enabled,
  });
  const { getVendorCode, shouldShowVendorCode } = useVendors();
  const intl = useIntl();
  const { watch, setValue, getValues } = useFormContext();
  const options = useMemo(
    () => supplierOptions.filter(filterResults ?? (() => true)),
    [filterResults, supplierOptions],
  );
  const { projectCostCodes } = useProjectCostCodes();
  const { isWarehouseSupplier } = useWarehouseSupplier();

  const vendorId = watch(name);

  useEffect(() => {
    if (options.length === 1) {
      setValue(name, options[0].id);
    }
  }, [name, setValue, options]);

  useEffect(() => {
    const supplier = options.find((v) => v.value === vendorId);
    if (!supplier) {
      return;
    }
    const contacts = (supplier as SupplierVendor)?.contacts?.filter(
      contactsFilterMemoized,
    );
    if (autoSelectDependencies && supplier && contacts?.length === 1) {
      setValue(
        "vendorContactIds",
        getValues("vendorContactIds") || contacts.map((c) => c.id),
      );
    }
  }, [
    vendorId,
    options,
    setValue,
    contactsFilterMemoized,
    autoSelectDependencies,
    getValues,
  ]);

  useEffect(() => {
    const orderTypeId = findOrderTypeByLocationId(vendorId);
    if (autoSelectDependencies && orderTypeId) {
      setValue("orderTypeId", orderTypeId);
    }
  }, [autoSelectDependencies, findOrderTypeByLocationId, setValue, vendorId]);

  useEffect(() => {
    const costCodeId = findCostCodeIdByLocationId(vendorId);
    if (
      autoSelectDependencies &&
      projectCostCodes.find(
        (projectScopedCostCode) => projectScopedCostCode.id === costCodeId,
      ) &&
      !getValues("costCodeId")
    ) {
      setValue("costCodeId", costCodeId);
    }
  }, [
    autoSelectDependencies,
    projectCostCodes,
    findCostCodeIdByLocationId,
    getValues,
    setValue,
    vendorId,
  ]);

  useEffect(() => {
    const paymentTerm =
      findPaymentTermByLocationId(vendorId) ||
      settings?.invoices.defaultPaymentTerm;
    if (autoSelectDependencies) {
      if (paymentTerm || !getValues("paymentTerm")) {
        setValue("paymentTerm", paymentTerm ?? 30);
      }
    }
  }, [
    autoSelectDependencies,
    findPaymentTermByLocationId,
    setValue,
    getValues,
    vendorId,
    settings?.invoices.defaultPaymentTerm,
  ]);

  const getLabel = useCallback(
    (option: Supplier) => {
      if (formatFullLabel) {
        const vendor = getVendorSupplierBySupplier(option);
        if (!vendor) {
          return option.name;
        }

        return vendorLabelFormatter(
          vendor.sellerOrgLocation,
          vendor.contacts.filter(contactsFilterMemoized),
          {
            vendorCode: getVendorCode(vendor),
          },
        );
      }

      return option.name;
    },
    [contactsFilterMemoized, formatFullLabel, getVendorCode],
  );

  const selectedVendor = useMemo(
    () =>
      !isWarehouseSupplier(vendorId)
        ? options.find((v) => v.value === vendorId)
        : null,
    [isWarehouseSupplier, options, vendorId],
  );

  const selectedSourceWarehouse = useMemo(
    () =>
      isWarehouseSupplier(vendorId)
        ? options.find((v) => v.value === vendorId)
        : null,
    [isWarehouseSupplier, options, vendorId],
  );

  return (
    <Tooltip
      id={`select-controlled-${name}`}
      element={
        <SelectControlled
          name={name}
          options={options}
          getLabel={getLabel}
          getValue={(option) => option.value as string}
          placeholder={intl.$t({ id: "SUPPLIER" })}
          loading={loading}
          rules={{
            required,
          }}
          groupBy={(option) => option.type}
          renderGroup={GroupRenderer}
          customRender={(item) =>
            SupplierPickerCustomRender(item, {
              filter: contactsFilterMemoized,
              shouldShowVendorCode,
            })
          }
          limitResults={MAX_VENDOR_NUMBER}
          selectedOptionCustomView={
            includeWarehouses ? (
              selectedSourceWarehouse ? (
                <WarehouseSelectorCustomRendererWithIcon
                  item={selectedSourceWarehouse}
                />
              ) : selectedVendor ? (
                <SupplierSelectorCustomRendererWithIcon item={selectedVendor} />
              ) : undefined
            ) : undefined
          }
          {...props}
          endAdornment={
            withAdditionalAdornment ? (
              <SupplierInsuranceInfo
                vendorId={vendorId}
                className="z-10 -mr-1 bg-white pl-2 pr-1"
              />
            ) : undefined
          }
          sx={warningTooltip ? warningStyles : {}}
        />
      }
      hideTooltip={!warningTooltip}
    >
      {warningTooltip}
    </Tooltip>
  );
};

import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useDeliveries } from "@/contractor/pages/home/releases/pages/deliveries/providers/DeliveriesProvider";
import { LinkPoInput } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useRefetchReleasePoLink } from "../components/common/release-export-status/hooks/useRefetchReleasePoLink";
import { useLinkPo } from "./useLinkPo";

const POLL_INTERVAL = 2000;

export const useExportReleases = () => {
  const intl = useIntl();
  const { setSystemAlert } = useSnackbar();
  const { linkPo } = useLinkPo();
  const { refetch } = useDeliveries();
  const { refetchReleasePOLink } = useRefetchReleasePoLink();

  const exportReleases = useCallback(
    (inputs: LinkPoInput[]) => {
      if (inputs.length === 0) {
        return;
      }
      const promises = inputs.map((input) => linkPo(input));
      Promise.all(promises);
      setSystemAlert(intl.$t({ id: "PO_EXPORT_INITIATED_IN_THE_BACKGROUND" }));
      if (inputs.length === 1) {
        refetchReleasePOLink(inputs[0].releaseId);
      }
      setTimeout(() => {
        refetch();
      }, POLL_INTERVAL);
    },
    [refetchReleasePOLink, intl, linkPo, refetch, setSystemAlert],
  );

  return { exportReleases };
};

import { DiscardButton } from "@/common/components/button/DiscardButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

import { ExternalVendorFieldsFragment } from "@/generated/graphql";
import { VendorFilledDetail } from "./VendorFilledData";
import { VendorImportForm } from "./VendorImportForm";
import { useImportExternalVendorsStore } from "./stores/useImportExternalVendorsWizardStore";

const Container = tw.div`p-8 mt-14 mx-10`;
const Vendors = tw.div`flex flex-col gap-4 mt-5`;
const Item = tw.div`grid grid-cols-[1fr_1fr_40px] text-sm bg-white items-start rounded-3xl border-dashed border border-gray-300`;
const VendorFilledData = tw.div`h-full flex flex-col gap-2 bg-gray-100 py-4 px-8 rounded-l-3xl`;
const Row = tw.div`flex flex-row`;

export const VendorsImportForm = () => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { selectedExternalVendors } = useImportExternalVendorsStore();
  const { getValues, trigger, control } = useFormContext();
  const vendors = getValues("vendors") as ExternalVendorFieldsFragment[];
  const { remove } = useFieldArray({
    control,
    name: "vendors",
  });

  useEffect(() => {
    trigger("vendors");
  }, [trigger]);

  const confirmDiscard = useCallback(
    (vendor: ExternalVendorFieldsFragment, index: number) => {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "EXCLUDE" }),
        includeWarningIcon: true,
        title: intl.$t(
          { id: "EXCLUDE_VENDOR_FROM_IMPORT" },
          { vendorName: vendor.name },
        ),
        handleConfirm: () => {
          remove(index);
        },
      });
    },
    [openDialog, intl, remove],
  );

  return (
    <Container>
      <Vendors>
        {vendors.map((vendor, key) => {
          const externalVendor = selectedExternalVendors.find(
            (v) => v.externalId === vendor.externalId,
          );
          return (
            <Item key={key}>
              <VendorFilledData>
                <VendorFilledDetail
                  detail={externalVendor?.name}
                  label={intl.$t({ id: "VENDOR_NAME" })}
                />
                <VendorFilledDetail
                  detail={externalVendor?.email}
                  label={intl.$t({ id: "EMAIL" })}
                />
                <VendorFilledDetail
                  detail={externalVendor?.phone}
                  label={intl.$t({ id: "PHONE" })}
                />
                <VendorFilledDetail
                  detail={externalVendor?.address?.addressLine1}
                  label={intl.$t({ id: "LOCATION_ADDRESS_LINE_1" })}
                />
                <VendorFilledDetail
                  detail={externalVendor?.address?.addressLine2}
                  label={intl.$t({ id: "LOCATION_ADDRESS_LINE_2" })}
                />
                <Row>
                  <VendorFilledDetail
                    detail={externalVendor?.address?.city}
                    label={intl.$t({ id: "ADDRESS_CITY" })}
                  />
                  <VendorFilledDetail
                    detail={externalVendor?.address?.postalCode}
                    label={intl.$t({ id: "ADDRESS_ZIP_CODE" })}
                  />
                </Row>
                <Row>
                  <VendorFilledDetail
                    detail={externalVendor?.address?.country}
                    label={intl.$t({ id: "COUNTRY" })}
                  />
                  <VendorFilledDetail
                    detail={externalVendor?.address?.state}
                    label={intl.$t({ id: "STATE" })}
                  />
                </Row>
              </VendorFilledData>
              <VendorImportForm index={key} externalVendor={externalVendor} />
              <DiscardButton onClick={() => confirmDiscard(vendor, key)} />
            </Item>
          );
        })}
      </Vendors>
    </Container>
  );
};

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { SpreadSheetConfig } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useInventoryHelpers } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryHelpers";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useInventoryItemRenderer } from "../../../../../../../../common/components/spreadsheet-table/renderers/material-renderer/useInventoryItemRenderer";
import { useGetPriceBreakdown } from "../../hooks/useGetPriceBreakdown";
import { useInventoryItemUomRenderer } from "../components/renderers/useInventoryItemUomRenderer";
import { useInventoryAdjustmentItemsDecorator } from "./useInventoryAdjustmentItemsDecorator";
import { useInventoryAdjustmentTableValidators } from "./useInventoryAdjustmentTableValidators";

export const useInventoryAdjustmentConfig = () => {
  const intl = useIntl();
  const { requiredValidator, combineValidators, positiveNumberValidator } =
    useTableValidators();
  const { extraOptions } = useInventoryAdjustmentItemsDecorator();
  const { findInventoryItemByNameAndUom } = useInventoryHelpers();
  const { uomAvailableValidator, uniqueInventoryItemValidator } =
    useInventoryAdjustmentTableValidators();
  const inventoryItemUomRenderer = useInventoryItemUomRenderer();
  const inventoryItemRenderer = useInventoryItemRenderer();
  const getUnitPriceBreakdown = useGetPriceBreakdown();

  const isPriceDisabled = useCallback(
    (row: Record<string, string>) => {
      if (row && !rowIsEmpty(row)) {
        const item = findInventoryItemByNameAndUom(
          row[COLUMN_TYPE.Material],
          row[COLUMN_TYPE.UOM],
        );
        const quantity = Number(row[COLUMN_TYPE.Quantity]);
        if (item && quantity && quantity < 0) {
          return item ? getUnitPriceBreakdown(item, quantity) : "";
        }
      }
      return "";
    },
    [findInventoryItemByNameAndUom, getUnitPriceBreakdown],
  );

  const isExtPriceDisabled = useCallback(
    (row: Record<string, string>) => {
      if (row && !rowIsEmpty(row)) {
        const item = findInventoryItemByNameAndUom(
          row[COLUMN_TYPE.Material],
          row[COLUMN_TYPE.UOM],
        );
        const quantity = Number(row[COLUMN_TYPE.Quantity]);
        if (item && quantity && quantity < 0) {
          return item ? getUnitPriceBreakdown(item, quantity, true) : "";
        }
      }
      return "";
    },
    [findInventoryItemByNameAndUom, getUnitPriceBreakdown],
  );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: "name",
        columnType: COLUMN_TYPE.Material,
        extraOptions,
        validator: combineValidators([
          requiredValidator,
          uniqueInventoryItemValidator,
        ]),
        renderer: inventoryItemRenderer,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "uom.pluralDescription",
        columnType: COLUMN_TYPE.UOM,
        validator: combineValidators([
          requiredValidator,
          uomAvailableValidator,
        ]),
        renderer: inventoryItemUomRenderer,
      },
      {
        header: intl.$t({ id: "EXISTING_STOCK" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.ExistingStock,
        readOnlyFn: () => intl.$t({ id: "READ_ONLY" }),
      },
      {
        header: intl.$t({ id: "ADJUSTMENT" }),
        columnId: "quantity",
        columnType: COLUMN_TYPE.Quantity,
      },
      {
        header: intl.$t({ id: "NEW_STOCK" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.NewStock,
        readOnlyFn: () => intl.$t({ id: "READ_ONLY" }),
        validator: positiveNumberValidator,
      },
      {
        header: intl.$t({ id: "UNIT_PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.UnitPrice,
        readOnlyFn: isPriceDisabled,
        validator: positiveNumberValidator,
      },
      {
        header: intl.$t({ id: "EXT_PRICE" }),
        columnId: "extPrice",
        columnType: COLUMN_TYPE.ExtPrice,
        readOnlyFn: isExtPriceDisabled,
      },
      {
        header: intl.$t({ id: "NOTES" }),
        columnId: "notes",
        columnType: COLUMN_TYPE.Notes,
      },
    ],
    [
      isPriceDisabled,
      isExtPriceDisabled,
      intl,
      requiredValidator,
      uniqueInventoryItemValidator,
      combineValidators,
      extraOptions,
      uomAvailableValidator,
      positiveNumberValidator,
      inventoryItemRenderer,
      inventoryItemUomRenderer,
    ],
  );

  return spreadsheetConfig;
};

import { QUERYSTRING } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { QueryExternalVendorsFilter, SourceSystem } from "@/generated/graphql";
import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useExternalVendorsFilterStore } from "../stores/useExternalVendorsFilterStore";
import { useAgaveExternalVendorsWithPagination } from "./useAgaveExternalVendorsWithPagination";

export const useAgaveExternalVendors = (
  sourceSystem: SourceSystem | null,
  opts?: { skip?: boolean },
) => {
  const { queryParams } = useQueryParams();
  const { filter, setFilter } = useExternalVendorsFilterStore(
    useShallow((state) => ({
      filter: state.filter,
      setFilter: state.setFilter,
    })),
  );

  const { externalVendors, loading, error, totalCount, setPage } =
    useAgaveExternalVendorsWithPagination(sourceSystem, filter, opts);

  useErrorEffect(error);

  useEffect(() => {
    setFilter({
      search: queryParams.get(QUERYSTRING.SEARCH) || "",
      mapped:
        queryParams.get(QUERYSTRING.MAPPED) !== null
          ? Boolean(queryParams.get(QUERYSTRING.MAPPED))
          : undefined,
    });
  }, [queryParams, setFilter]);

  const setFilterUpdate = (filter: QueryExternalVendorsFilter) => {
    setPage({
      page: 0,
      queryParams: {
        ...filter,
      },
    });
    setFilter(filter);
  };

  return {
    externalVendors:
      externalVendors.map((i) => ({ ...i, id: i.externalId })) ?? [],
    loading,
    totalCount,
    filter,
    isFiltered: !!(filter?.search || filter?.mapped !== undefined),
    setFilter: setFilterUpdate,
  };
};

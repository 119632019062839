import { If } from "@/common/components/if/If";
import { useTaxType } from "@/common/components/sales-tax-input/hooks/useTaxType";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  FooterCell,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterPropsWithTaxes,
  FooterTotal,
} from "../../../../../../../common/components/grid-table/styles/Footer.styles";
import { useReleaseItemsZoneProvider } from "../../../providers/ReleaseItemsZonesProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { isWarehouseRelease } from "../../../utils/isWarehouseRelease";

const TitleNormal = tw.div`text-right`;
const Title = tw.div`font-medium text-right`;
const InfoIcon = tw(InfoOutlined)`text-blue-500 mr-4 text-md`;
const FooterCellFull = tw(FooterCell)`w-full`;

export const ReleaseTitleColFooter: FC<FooterPropsWithTaxes> = ({
  includeAdditionalCharges,
  Wrapper,
}) => {
  const { noPrices } = useReleaseItemsZoneProvider();
  const { release } = useRelease();
  const { useTaxKey } = useTaxType(
    release?.type.id,
    undefined,
    release?.taxType,
  );

  return (
    <FooterCol className="min-w-[150px]">
      <Wrapper className="flex-col">
        <If isTrue={!isWarehouseRelease(release)}>
          <FooterHeader />
          <FooterCellFull>
            <FormattedMessage id="SUBTOTAL" tagName={TitleNormal} />:
          </FooterCellFull>
          <If isTrue={includeAdditionalCharges}>
            <FooterCellFull>
              <TitleNormal className="whitespace-nowrap">
                <FormattedMessage id="ADDITIONAL_CHARGES" />:
              </TitleNormal>
            </FooterCellFull>
          </If>
          <FooterCellFull>
            <TitleNormal>
              <FormattedMessage id={useTaxKey} />:
            </TitleNormal>
          </FooterCellFull>
          <FooterDashedLine />
        </If>
        <FooterTotal className="w-full">
          <If isTrue={noPrices}>
            <Tooltip id="no-prices-tooltip" element={<InfoIcon />}>
              <FormattedMessage id="TO_SEE_VALUES_SPECIFY_PRICES" />
            </Tooltip>
          </If>
          <FooterCellFull className="flex-col items-end pr-0 font-semibold">
            <Title>
              <FormattedMessage id="TOTAL" />:
            </Title>
            <If isTrue={!isWarehouseRelease(release)}>
              <FormattedMessage
                id="INCL_TAXES_AND_FEES"
                tagName={FooterHeaderText}
              />
            </If>
          </FooterCellFull>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};

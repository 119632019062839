import { useDialog } from "@/common/components/dialog/DialogProvider";
import {
  FileSourceType,
  PASTE_TABLE,
} from "@/common/components/file-source-selector/FileSourceSelector";
import { getFileType } from "@/common/components/upload/FileUploadArea";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  CustomFileFormat,
  CustomIntegrationInput,
  FieldMappingTargetProperty,
  IntegrationType,
  SourceSystem,
} from "@/generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useExternalEstimates } from "../../hooks/useExternalEstimates";
import { useProjectZones } from "../../hooks/useProjectZones";
import { useImportItems } from "./import-items-form/useImportItems";
import { ImportItemsType } from "./ImportItemsTabs";
import { ImportProjectItemsProps, NEW_ZONE_ID } from "./ImportProjectItems";
import { FILE_MAPPER_COLUMNS } from "./material-file-mapper/FileMapperColumns";
import { useMaterialFileUpload } from "./providers/MaterialFileUploadProvider";

type Props = ImportProjectItemsProps;

export const useImportProjectItems = ({ project, onClose }: Props) => {
  const { id } = useParams();
  const { setSuccessAlert } = useSnackbar();
  const { openDialog } = useDialog();
  const { addZone } = useProjectZones();
  const { zones } = useProjectZones();
  const {
    uploadFile,
    setFilePreview,
    setFieldPreviewType,
    fieldPreviews,
    previewError,
    assignedColumns,
    customFileFormats,
    createCustomFileFormat,
    deleteCustomFileFormat,
  } = useMaterialFileUpload();
  const intl = useIntl();
  const [tags, setTags] = useState<string[]>([]);
  const [clearAllEstimatedItems, setClearAllEstimatedItems] = useState(false);
  const [importZones, setImportZones] = useState(false);
  const [importManufacturers, setImportManufacturers] = useState(false);
  const [sourceType, setSourceType] = useState<FileSourceType>("");
  const [selectedZone, setSelectedZone] = useState<string | null>(null);
  const [newZoneName, setNewZoneName] = useState("");
  const [file, setFile] = useState<File>();
  const [loadingError, setLoadingError] = useState<unknown>();
  const [formatName, setFormatName] = useState("");
  const [saveFormat, setSaveFormat] = useState(false);
  const [mappingMode, setMappingMode] = useState(false);
  const [selectedEstimateExternalId, setSelectedEstimateExternalId] = useState<
    string | null
  >(null);
  const [activeTab, setActiveTab] = useState(ImportItemsType.ImportFromProject);
  const { importEstimate } = useExternalEstimates(project.id, sourceType);
  const onSourceChanged = useCallback((source: FileSourceType) => {
    setSourceType(source);
    setSaveFormat(false);
    if (source !== SourceSystem.Foundation) {
      setSelectedEstimateExternalId(null);
    }
  }, []);

  const { watch } = useFormContext();
  const sourceProjectId = watch("sourceProjectId");
  const { importItems } = useImportItems();
  const uploadedFileSelected = useMemo(() => {
    return sourceType === file?.name;
  }, [sourceType, file]);

  const customFileTypeSelected = useMemo(() => {
    return sourceType === IntegrationType.Custom;
  }, [sourceType]);

  const myCustomTypeSelected = useMemo(() => {
    return customFileFormats.find(
      (format: CustomFileFormat) => format.id === sourceType,
    );
  }, [customFileFormats, sourceType]);

  const integrationTypeSelected = useMemo(() => {
    return Object.values(IntegrationType).includes(
      sourceType as IntegrationType,
    );
  }, [sourceType]);

  const copyPasteTable = useMemo(() => {
    return sourceType === PASTE_TABLE;
  }, [sourceType]);

  const updateFile = useCallback(
    (files: File[]) => {
      const file = files[0];
      setFile(file);
      if (customFileTypeSelected || copyPasteTable) {
        setFilePreview(file);
        setFieldPreviewType(getFileType(file));
        setSourceType(file.name);
      }
    },
    [
      customFileTypeSelected,
      copyPasteTable,
      setFilePreview,
      setFieldPreviewType,
    ],
  );

  const pastedDataSelected = useMemo(() => {
    return sourceType === intl.$t({ id: "PASTED_TABLE_DATA" });
  }, [sourceType, intl]);

  const disableSave = useMemo(() => {
    if (activeTab === ImportItemsType.ImportFromProject) {
      return !sourceProjectId;
    }
    if (sourceType === SourceSystem.Foundation) {
      return !selectedEstimateExternalId;
    }

    if (!file) {
      return true;
    }

    if (saveFormat && !formatName) {
      return true;
    }

    const assignedColumnNames = assignedColumns
      .filter((col) => col.assigned)
      .map((col) => col.assigned);
    const duplicateColumnNames = assignedColumnNames.filter(
      (name, index) => assignedColumnNames.indexOf(name) !== index,
    );
    if (duplicateColumnNames.length > 0) {
      return true;
    }

    if (uploadedFileSelected) {
      return !assignedColumns
        .filter(
          (col) =>
            importZones ||
            (!importZones && col.value !== FieldMappingTargetProperty.Zone),
        )
        .every(
          (c) =>
            c.assigned !== "" ||
            FILE_MAPPER_COLUMNS.find((col) => col.value === c.value)
              ?.required === false,
        );
    }
  }, [
    activeTab,
    sourceType,
    file,
    saveFormat,
    formatName,
    assignedColumns,
    uploadedFileSelected,
    sourceProjectId,
    selectedEstimateExternalId,
    importZones,
  ]);

  const removeOption = useCallback(
    (option: { value: string }) => {
      openDialog({
        confirmButtonText: intl.$t({ id: "REMOVE" }),
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        includeWarningIcon: true,
        title: intl.$t({ id: "REMOVE_CUSTOM_OPTION" }),
        text: intl.$t({ id: "REMOVE_CUSTOM_OPTION_TEXT" }),
        handleConfirm: async () => {
          await deleteCustomFileFormat(option.value);
        },
      });
    },
    [deleteCustomFileFormat, intl, openDialog],
  );

  const createZone = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "CONFIRM" }),
      includeWarningIcon: true,
      title: intl.$t({ id: "ADD_NEW_ZONE" }, { zone: newZoneName }),
      handleConfirm: async () => {
        const zones = await addZone({ name: newZoneName, projectId: id || "" });
        if ((zones || [])?.length > 0) {
          const newZone = zones?.find((z) => z.name === newZoneName);
          if (newZone) {
            setSelectedZone(newZone.id);
            setNewZoneName(newZone.name);
          }
        }
      },
      handleCancel: () => setNewZoneName(""),
    });
  }, [addZone, id, intl, newZoneName, openDialog]);

  const setZone = useCallback(
    (value: string | null) => {
      if (value !== NEW_ZONE_ID) {
        setSelectedZone(value);
        setNewZoneName(zones.find((z) => z.id === value)?.name || "");
      } else {
        createZone();
      }
    },
    [createZone, zones],
  );

  const importExternalEstimates = useCallback(async () => {
    try {
      if (selectedEstimateExternalId) {
        const result = await importEstimate({
          projectId: project.id,
          sourceSystem: SourceSystem.Foundation,
          estimateExternalId: selectedEstimateExternalId,
          clearAllEstimatedItems,
          tags,
          zoneId: selectedZone,
        });

        if (result?.success) {
          setSuccessAlert(intl.$t({ id: "MATERIAL_LIST_IMPORT_SUCCESS" }));
        } else {
          return;
        }

        onClose();
      }
    } catch (error) {
      setLoadingError(error);
    }
  }, [
    selectedEstimateExternalId,
    importEstimate,
    project.id,
    clearAllEstimatedItems,
    tags,
    selectedZone,
    setSuccessAlert,
    onClose,
    intl,
  ]);

  const uploadMaterials = useCallback(async () => {
    if (file) {
      const mappings = assignedColumns
        .filter((col) => col.assigned)
        .map((col) => ({
          index: fieldPreviews.findIndex((f) => col.assigned === f.name),
          name: col.assigned,
          property: col.value,
        }));

      const customInput =
        uploadedFileSelected || myCustomTypeSelected
          ? ({
              fileFormat: {
                fileType: getFileType(file),
                mappings: myCustomTypeSelected?.mappings || mappings,
              },
            } as CustomIntegrationInput)
          : undefined;

      try {
        setLoadingError(undefined);
        const result = await uploadFile({
          projectId: id || "",
          fileFormat:
            uploadedFileSelected || myCustomTypeSelected
              ? IntegrationType.Custom
              : (sourceType as IntegrationType),
          file,
          custom: customInput,
          clearAllEstimatedItems,
          importZones,
          importManufacturers,
          tags,
          zoneId: importZones ? undefined : selectedZone || undefined,
        });

        if (result?.success) {
          if (!result.hasErrors) {
            setSuccessAlert(intl.$t({ id: "MATERIAL_FILE_UPLOAD_SUCCESS" }));
          }
        } else {
          return;
        }

        if (saveFormat) {
          await createCustomFileFormat({
            name: formatName,
            fileType: getFileType(file),
            mappings,
          });
        }

        onClose();
      } catch (error) {
        setLoadingError(error);
      }
    }
  }, [
    file,
    assignedColumns,
    uploadedFileSelected,
    myCustomTypeSelected,
    fieldPreviews,
    uploadFile,
    id,
    sourceType,
    clearAllEstimatedItems,
    importZones,
    importManufacturers,
    tags,
    selectedZone,
    saveFormat,
    onClose,
    setSuccessAlert,
    intl,
    createCustomFileFormat,
    formatName,
  ]);

  const handleSave = useCallback(async () => {
    if (activeTab === ImportItemsType.ImportFromProject) {
      return importItems(onClose);
    }
    if (sourceType === SourceSystem.Foundation) {
      await importExternalEstimates();
    } else {
      await uploadMaterials();
    }
  }, [
    activeTab,
    sourceType,
    importItems,
    onClose,
    importExternalEstimates,
    uploadMaterials,
  ]);

  return {
    sourceType,
    setSourceType,
    onSourceChanged,
    updateFile,
    uploadedFileSelected,
    customFileTypeSelected,
    myCustomTypeSelected,
    integrationTypeSelected,
    copyPasteTable,
    pastedDataSelected,
    removeOption,
    setZone,
    selectedZone,
    newZoneName,
    setNewZoneName,
    tags,
    setTags,
    clearAllEstimatedItems,
    setClearAllEstimatedItems,
    importZones,
    setImportZones,
    importManufacturers,
    setImportManufacturers,
    selectedEstimateExternalId,
    setSelectedEstimateExternalId,
    saveFormat,
    setSaveFormat,
    formatName,
    setFormatName,
    mappingMode,
    setMappingMode,
    disableSave,
    previewError,
    handleSave,
    loadingError,
    file,
    activeTab,
    setActiveTab,
  };
};

import { useCallback, useMemo } from "react";
import { useInventoryItemSequence } from "../hooks/useInventoryItemSequence";
import { InventoryItemCheckboxProps } from "./InventoryItemCheckbox";

export const useInventoryItemCheckbox = ({
  item,
}: InventoryItemCheckboxProps) => {
  const { selectedEntities, setSelectedEntities } = useInventoryItemSequence();

  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedEntities(
        newChecked
          ? [...selectedEntities, { id: item.id }]
          : selectedEntities.filter((entity) => entity.id !== item.id),
      );
    },
    [item, selectedEntities, setSelectedEntities],
  );

  const isIncluded = useMemo(() => !item.archivedAt, [item.archivedAt]);

  return { handleChange, selectedEntities, isIncluded };
};

import {
  formattedDate,
  SHORT_DATE_OPTION,
} from "@/common/utils/dates/DateView";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ReleasePoValidationErrorKeyMap } from "@/contractor/pages/home/common/hooks/usePoValidationMappings";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ReleaseExportValidationErrors } from "../../common/release-export-status/components/ReleaseExportValidationErrors";
import { RELEASE_EXPORT_STATUSES } from "../../common/release-export-status/config/ReleaseExportStatuses";
import { ReleaseExportStatusEnum } from "../../common/release-export-status/types/ReleaseExportStatusEnum";

type Props = {
  release:
    | Pick<
        ReleaseFieldsFragment,
        | "status"
        | "id"
        | "poLink"
        | "version"
        | "poNumber"
        | "syncVersion"
        | "poExists"
        | "exportedAt"
        | "useSourceSystemPO"
      >
    | undefined
    | null;
};

export const useReleaseExportStatus = ({ release }: Props) => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const status = useMemo(() => {
    if (!release?.useSourceSystemPO) {
      return ReleaseExportStatusEnum.NoExport;
    }
    if (release?.poExists && !release.poLink?.id) {
      return ReleaseExportStatusEnum.PoExists;
    }
    if (
      release?.poLink?.syncing ||
      (release.poLink && !release.poLink.syncedAt && !release.poLink.failedAt)
    ) {
      return ReleaseExportStatusEnum.Syncing;
    }
    if (
      release?.poLink &&
      release?.poLink?.syncedVersion !== release?.syncVersion
    ) {
      return ReleaseExportStatusEnum.Outdated;
    }
    if (release.poLink?.failedAt || release.poLink?.validationErrors?.length) {
      return ReleaseExportStatusEnum.BackgroundExportIssue;
    }
    if (release?.poLink?.autoSyncError) {
      return ReleaseExportStatusEnum.SyncIssue;
    }
    return ReleaseExportStatusEnum.OK;
  }, [
    release?.poExists,
    release?.poLink,
    release?.syncVersion,
    release?.useSourceSystemPO,
  ]);

  const validationErrors = useMemo(() => {
    if (release?.poLink?.validationErrors?.length) {
      return release.poLink.validationErrors.map((error) => {
        return intl.$t(
          {
            id: ReleasePoValidationErrorKeyMap[
              error as keyof typeof ReleasePoValidationErrorKeyMap
            ],
          },
          {
            integration: intl.$t({
              id: `INTEGRATION_${connectedSourceSystem}`,
            }),
          },
        );
      });
    }
    return [];
  }, [release?.poLink?.validationErrors, intl, connectedSourceSystem]);

  const tooltip = useMemo(() => {
    const header = release?.poLink?.validationErrors.length ? (
      <ReleaseExportValidationErrors validationErrors={validationErrors} />
    ) : release?.poLink?.autoSyncError ? (
      intl.$t({ id: "PO_SYNCHRONIZED_ERROR" })
    ) : !release?.poLink?.syncing &&
      release?.useSourceSystemPO &&
      !release.poLink?.failedAt ? (
      intl.$t({ id: "PO_SYNCHRONIZED" })
    ) : undefined;

    if (status === ReleaseExportStatusEnum.OK && !release?.poLink) {
      return null;
    }
    const hasPredefinedTooltip = RELEASE_EXPORT_STATUSES[status].tooltip;
    const body = !hasPredefinedTooltip
      ? intl.$t(
          { id: RELEASE_EXPORT_STATUSES[status].label },
          {
            date: formattedDate({
              date:
                release?.poLink?.syncedAt ||
                release?.exportedAt ||
                release?.poLink?.failedAt,
              options: SHORT_DATE_OPTION,
            }),
          },
        )
      : undefined;

    return (
      <>
        {header}
        {header && body ? <br /> : null}
        {body}
      </>
    );
  }, [
    release?.poLink,
    release?.useSourceSystemPO,
    release?.exportedAt,
    status,
    intl,
    validationErrors,
  ]);

  return {
    status,
    tooltip,
    validationErrors,
  };
};

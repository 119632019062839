import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  InlineNotesContainer,
  MdContainer,
  RfqItemContainer,
  SmContainer,
  SmFixedContainer,
  SmWideContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { useManufacturersSettings } from "@/distributor/common/hooks/useManufacturersSettings";
import {
  DistributorQuoteCommonFieldsFragment,
  DistributorQuoteFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { BidPriceTotalForItem } from "../../../../common/quote/BidPriceTotalForItem";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../../providers/DistributorQuoteItemsProvider";
import { DistributorQuoteCategoryToggle } from "../DistributorQuoteCategoryToggle";
import { DistributorQuoteCategoryTotal } from "../DistributorQuoteCategoryTotal";
import { DistributorQuoteCostCodeManufacturerSelector } from "../DistributorQuoteCostCodeManufacturerSelector";
import { DistributorQuoteItemToggle } from "../DistributorQuoteItemToggle";
import { DistributorQuoteManufacturerSelector } from "../DistributorQuoteManufacturerSelector";
import { DistributorQuoteRfqProductView } from "../DistributorQuoteRfqProductView";
import { DistributorQuoteTotalInput } from "../DistributorQuoteTotalInput";
import { DistributorQuoteUnitInput } from "../DistributorQuoteUnitInput";
import { DistributorQuoteUnitPriceInput } from "../DistributorQuoteUnitPriceInput";
import { IncludeIfNoAlternatives } from "../IncludeIfNoAlternatives";
import { WithdrawalWrapper } from "../WithdrawalWrapper";
import { DistributorQuoteItemAlternativeName } from "../alternatives/DistributorQuoteItemAlternativeName";
import { DistributorQuoteItemOptionsMenu } from "../alternatives/DistributorQuoteItemOptionsMenu";
import { DistributorQuoteNotes } from "../alternatives/DistributorQuoteNotes";
import { DistributorQuoteLeadTime } from "../lead-time/DistributorQuoteLeadTime";
import { QuoteNotes } from "../notes-and-expiration/QuoteNotes";

const Container = tw(RfqItemContainer)`justify-between`;
const ActionsContainer = tw(
  SmFixedContainer,
)`lg:w-30 lg:basis-30 md:basis-30 md:w-30`;

export const useDistributorQuoteAlternativesConfiguration = (
  quote:
    | (DistributorQuoteFieldsFragment & DistributorQuoteCommonFieldsFragment)
    | null
    | undefined,
) => {
  const { getVendorHasManufacturerSetting } = useManufacturersSettings();
  const hasManufacturersSetting = getVendorHasManufacturerSetting(
    quote?.rfq.project?.location?.org.settings?.display,
  );
  const configuration: Array<
    GridCol<DistributorQuoteItemGroupExtendedFieldsFragment>
  > = useMemo(
    () => [
      {
        wrapper: Container,
        item: ({ item, index, readonly, count }) => (
          <DistributorQuoteRfqProductView
            index={index}
            item={item}
            readonly={readonly}
            count={count}
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
          />
        ),
        position: "none",
        header: <FormattedMessage id="DISTRIBUTOR_QUOTE_ITEM_DESCRIPTION" />,
        details: ({ item, index, readonly, error }) => (
          <DistributorQuoteItemAlternativeName
            item={item}
            index={index}
            readonly={readonly}
            error={error}
          />
        ),
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => (
          <ValueUnit
            value={item.rfqItem.quantityDecimal}
            uom={item.rfqItem.projectItem.estimateUom}
          />
        ),
        position: "center",
        header: <FormattedMessage id="QUANTITY" />,
        group: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} />
        ),
      },
      {
        wrapper: SmContainer,
        item: ({ item, readonly }) => (
          <DistributorQuoteItemToggle item={item} readonly={readonly} />
        ),
        position: "center",
        header: <FormattedMessage id="INCLUDE_ITEM" />,
        group: (category, readonly) => (
          <DistributorQuoteCategoryToggle
            items={category.items}
            readonly={readonly}
          />
        ),
      },
      {
        wrapper: MdContainer,
        item: ({ item, readonly, error, index }) => (
          <WithdrawalWrapper item={item}>
            <IncludeIfNoAlternatives item={item}>
              <DistributorQuoteManufacturerSelector
                item={item}
                error={error}
                readonly={readonly}
                index={index}
              />
            </IncludeIfNoAlternatives>
          </WithdrawalWrapper>
        ),
        group: (category, readonly) => (
          <DistributorQuoteCostCodeManufacturerSelector
            category={category}
            readonly={readonly}
          />
        ),
        details: ({ item, readonly, error, index }) => {
          return (
            <DistributorQuoteManufacturerSelector
              item={item}
              error={error}
              readonly={readonly}
              index={index}
            />
          );
        },
        position: "center",
        header: <FormattedMessage id="PROVIDED_MANUFACTURER" />,
        hidden: !hasManufacturersSetting,
      },
      {
        wrapper: SmWideContainer,
        item: ({ item, index, readonly, error }) => (
          <WithdrawalWrapper item={item}>
            <IncludeIfNoAlternatives item={item}>
              <DistributorQuoteUnitInput
                item={item}
                readonly={readonly}
                index={index}
                error={error}
              />
            </IncludeIfNoAlternatives>
          </WithdrawalWrapper>
        ),
        details: ({ item, index, readonly, error }) => (
          <DistributorQuoteUnitInput
            item={item}
            readonly={readonly}
            index={index}
            error={error}
            hideErrorIcon
          />
        ),
        position: "center",
        header: <FormattedMessage id="DISTRIBUTOR_QUOTE_UNITS_IN_YOUR_QUOTE" />,
      },
      {
        wrapper: XxsFixedContainer,
        item: ({ item }) => (
          <WithdrawalWrapper item={item}>
            <IncludeIfNoAlternatives item={item}>
              <>x</>
            </IncludeIfNoAlternatives>
          </WithdrawalWrapper>
        ),
        details: () => <>x</>,
        position: "center",
      },
      {
        wrapper: SmWideContainer,
        item: ({ item, index, readonly, error }) => (
          <WithdrawalWrapper item={item}>
            <IncludeIfNoAlternatives item={item}>
              <DistributorQuoteUnitPriceInput
                item={item}
                readonly={readonly}
                index={index}
                error={error}
              />
            </IncludeIfNoAlternatives>
          </WithdrawalWrapper>
        ),
        details: ({ item, index, readonly, error }) => (
          <DistributorQuoteUnitPriceInput
            item={item}
            readonly={readonly}
            index={index}
            error={error}
            hideErrorIcon
          />
        ),
        position: "center",
        header: <FormattedMessage id="UNIT_PRICE" />,
      },
      {
        wrapper: XxsFixedContainer,
        item: ({ item }) => (
          <WithdrawalWrapper item={item}>
            <IncludeIfNoAlternatives item={item}>
              <>=</>
            </IncludeIfNoAlternatives>
          </WithdrawalWrapper>
        ),
        details: () => <>=</>,
        position: "center",
      },
      {
        wrapper: SmWideContainer,
        item: ({ item, readonly, error, index }) => (
          <WithdrawalWrapper item={item}>
            <IncludeIfNoAlternatives item={item}>
              <DistributorQuoteTotalInput
                item={item.quoteItems[0]}
                readonly={readonly}
                error={error}
                index={index}
              />
            </IncludeIfNoAlternatives>
          </WithdrawalWrapper>
        ),
        details: ({ item }) => (
          <BidPriceTotalForItem item={item.quoteItems[0]} />
        ),
        position: "center",
        header: <FormattedMessage id="EXT_PRICE" />,
        group: (category) => (
          <DistributorQuoteCategoryTotal items={category.items} />
        ),
      },
      {
        wrapper: ActionsContainer,
        item: ({ item, readonly }) => (
          <WithdrawalWrapper item={item}>
            <IncludeIfNoAlternatives item={item}>
              <QuoteNotes item={item.quoteItems[0]} readonly={readonly} />
              <DistributorQuoteLeadTime
                item={item.quoteItems[0]}
                readonly={readonly}
              />
              <DistributorQuoteItemOptionsMenu
                item={item}
                readonly={readonly}
              />
            </IncludeIfNoAlternatives>
          </WithdrawalWrapper>
        ),
        details: ({ item, readonly }) => (
          <>
            <QuoteNotes item={item.quoteItems[0]} readonly={readonly} />
            <DistributorQuoteLeadTime
              item={item.quoteItems[0]}
              readonly={readonly}
            />
            <DistributorQuoteItemOptionsMenu item={item} readonly={readonly} />
          </>
        ),
        position: "end",
      },
      {
        wrapper: InlineNotesContainer,
        item: ({ item, readonly }) => (
          <IncludeIfNoAlternatives item={item}>
            <DistributorQuoteNotes item={item} readonly={readonly} />
          </IncludeIfNoAlternatives>
        ),
        details: ({ item, readonly }) => (
          <DistributorQuoteNotes item={item} readonly={readonly} />
        ),
      },
    ],
    [hasManufacturersSetting],
  );

  return configuration;
};

import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  currentRow: number;
  currentItem: string;
  setCurrentItem: (itemName: string, row: number, quantity: number) => void;
  selectedQuantity: number;
  setSelectedQuantity: (quantity: number) => void;
  selectedItem: string;
  setSelectedItem: (item: string) => void;
  resetFilters: () => void;
  openInventoryModal: boolean;
  setOpenInventoryModal: (open: boolean) => void;
};

export const useSwitchInventoryStore = create<State>()(
  devtools((set) => ({
    selectedItem: "",
    setSelectedItem: (item) => {
      set({ selectedItem: item });
    },
    openInventoryModal: false,
    setOpenInventoryModal: (open) => {
      set({ openInventoryModal: open });
    },
    currentItem: "",
    currentRow: -1,
    selectedQuantity: 0,
    setSelectedQuantity: (quantity) => {
      set({ selectedQuantity: quantity });
    },
    setCurrentItem: (itemName, row, quantity) => {
      set({
        currentItem: itemName,
        currentRow: row,
        selectedQuantity: quantity,
        selectedItem: "",
      });
    },
    resetFilters: () => {
      set({
        selectedItem: "",
        currentItem: "",
        currentRow: -1,
      });
    },
  })),
);

import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useTemplateStringsStore } from "../../store/useTemplateStringsStore";
import { Template } from "../../types/Template";

export const useCustomTemplateTitle = (template: Template) => {
  const intl = useIntl();
  const [editing, setEditing] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>(template.name);
  const { openDialog } = useDialog();

  const { removeTemplate, replaceTemplate } = useTemplateStringsStore(
    useShallow((state) => ({
      removeTemplate: state.removeTemplate,
      replaceTemplate: state.replaceTemplate,
    })),
  );

  const handleEdit = useCallback(() => {
    setEditing(true);
    setTemplateName(template.name);
  }, [template.name]);

  const handleSave = useCallback(() => {
    setEditing(false);
    replaceTemplate(template, {
      ...template,
      name: templateName,
    });
  }, [template, templateName, replaceTemplate]);

  const handleCancel = useCallback(() => {
    setEditing(false);
  }, []);

  const handleDelete = useCallback(() => {
    setEditing(false);
    openDialog({
      title: intl.$t({ id: "DELETE_TEMPLATE" }),
      content: intl.$t({ id: "DELETE_TEMPLATE_CONFIRMATION" }),
      includeWarningIcon: true,
      confirmButtonText: intl.$t({ id: "DELETE" }),
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      handleConfirm: () => {
        removeTemplate(template);
      },
    });
  }, [removeTemplate, template, intl, openDialog]);

  return {
    editing,
    templateName,
    setTemplateName,
    handleEdit,
    handleSave,
    handleCancel,
    handleDelete,
    placeholder: intl.$t({ id: "TEMPLATE_NAME" }),
  };
};

import tw from "tailwind-styled-components";
import { TransactionHistoryScheduledOutflowsHeader } from "./components/transaction-history-scheduled-outflows-header/TransactionHistoryScheduledOutflowsHeader";
import { TransactionHistoryScheduledOutflowsList } from "./components/transaction-history-scheduled-outflows-list/TransactionHistoryScheduledOutflowsList";

const Container = tw.div`flex flex-col`;

export const TransactionHistoryScheduledOutflows = () => {
  return (
    <Container>
      <TransactionHistoryScheduledOutflowsHeader />
      <TransactionHistoryScheduledOutflowsList />
    </Container>
  );
};

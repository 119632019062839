import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  LgFixedContainer,
  SmFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import {
  InventoryItemPendingFlowFieldsFragment,
  InventoryTransactionType,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { TransactionRelease } from "../../../../../../transaction-release/TransactionRelease";
import { TransactionSymbol } from "../../../../../../transaction-symbol/TransactionSymbol";
import { ScheduledFlowDate } from "../../../../common/scheduled-inflow-outflow/scheduled-flow-date/ScheduledFlowDate";
import { ScheduledFlowExtPrice } from "../../../../common/scheduled-inflow-outflow/scheduled-flow-ext-price/ScheduledFlowExtPrice";
import { ScheduledFlowItemNotes } from "../../../../common/scheduled-inflow-outflow/scheduled-flow-item-notes/ScheduledFlowItemNotes";
import { ScheduledFlowQuantity } from "../../../../common/scheduled-inflow-outflow/scheduled-flow-quantity/ScheduledFlowQuantity";
import { ScheduledFlowUnitPrice } from "../../../../common/scheduled-inflow-outflow/scheduled-flow-unit-price/ScheduledFlowUnitPrice";
import { ScheduledOutflowTo } from "./components/scheduled-outflow-to/ScheduledOutflowTo";
import { ScheduledOutflowFrom } from "./components/scheduled-outflow-from/ScheduledOutflowFrom";

export const useTransactionHistoryScheduledOutflowsConfiguration = () => {
  const configuration: Array<GridCol<InventoryItemPendingFlowFieldsFragment>> =
    useMemo(() => {
      return [
        {
          wrapper: XxsFixedContainer,
          item: () => (
            <TransactionSymbol
              item={{ type: InventoryTransactionType.Outflow }}
            />
          ),
          position: "none",
        },
        {
          wrapper: SmFixedContainer,
          item: ({ item }) => <ScheduledFlowDate item={item} />,
          position: "none",
          header: <FormattedMessage id="ORDER_DATE" />,
        },
        {
          wrapper: LgFixedContainer,
          item: ({ item }) => <ScheduledOutflowFrom item={item} />,
          header: <FormattedMessage id="FROM" />,
        },
        {
          wrapper: LgFixedContainer,
          item: ({ item }) => <ScheduledOutflowTo item={item} />,
          header: <FormattedMessage id="TO" />,
        },
        {
          wrapper: SmFixedContainer,
          item: ({ item }) => <TransactionRelease release={item.release} />,
          position: "center",
          header: <FormattedMessage id="ORDER" />,
        },
        {
          wrapper: SmFixedContainer,
          item: ({ item }) => <ScheduledFlowQuantity item={item} />,
          position: "center",
          header: <FormattedMessage id="QUANTITY" />,
        },
        {
          wrapper: SmFixedContainer,
          item: ({ item }) => <ScheduledFlowUnitPrice item={item} />,
          position: "center",
          header: <FormattedMessage id="UNIT_PRICE" />,
        },
        {
          wrapper: SmFixedContainer,
          item: ({ item }) => <ScheduledFlowExtPrice item={item} />,
          position: "center",
          header: <FormattedMessage id="EXT_PRICE" />,
        },
        {
          wrapper: LgFixedContainer,
          item: ({ item }) => <ScheduledFlowItemNotes item={item} />,
          header: <FormattedMessage id="NOTES" />,
        },
      ];
    }, []);

  return configuration;
};

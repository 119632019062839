import { useCallback, useState } from "react";

export const useCommonTemplateVariables = () => {
  const [currentTemplate, setCurrentTemplate] = useState<string>("");
  const [includeConditional, setIncludeConditional] = useState<boolean>(true);

  const toggleIncludeConditional = useCallback(() => {
    setIncludeConditional((prev) => !prev);
  }, []);

  const handleCopyToClipboard = useCallback((template: string) => {
    setCurrentTemplate(template);
    navigator.clipboard.writeText(template);
  }, []);

  return {
    currentTemplate,
    includeConditional,
    toggleIncludeConditional,
    handleCopyToClipboard,
    setCurrentTemplate,
    setIncludeConditional,
  };
};

import { LOCAL_STORAGE_KEYS, QUERYSTRING } from "@/common/const";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { readValueFromKeys, setValue } from "../utils/localStorage";
import { decompressQueryParam } from "./utils/decompressQueryParam";

export enum ObjectType {
  Invoice = "invoice",
  Release = "release",
  Receipt = "receipt",
  DeliverySlip = "deliverySlip",
}

export type SortState = {
  objectType: ObjectType;
  field: string;
  descending: boolean;
};

type State = {
  sortEntities: SortState[];
  setSortEntity: (sortEntity: SortState) => void;
  getSortEntity: (object?: ObjectType) => SortState | undefined;
  removeSortEntity: (object?: ObjectType) => void;
};

const getDefaultSortEntities = () => {
  const search = new URLSearchParams(window.location.search);
  const queryParams: { [key: string]: string } = {};
  for (const [key, value] of search) {
    queryParams[key] = value;
  }
  const sortEntities: SortState[] = [];
  if (queryParams[QUERYSTRING.SORT]) {
    const params = decompressQueryParam(queryParams[QUERYSTRING.SORT]);
    sortEntities.push({
      objectType: params.sort_object_type as ObjectType,
      field: params.sort_by,
      descending: params.sort_desc === "true",
    });
  }
  const savedValue = readValueFromKeys<SortState[]>([
    LOCAL_STORAGE_KEYS.SORT_STATES,
  ]);
  if (savedValue && !sortEntities.length) {
    return savedValue;
  }
  return sortEntities;
};

export const useSortingStore = create<State>()(
  devtools((set, get) => ({
    sortEntities: getDefaultSortEntities(),
    setSortEntity: (sortEntity: SortState) => {
      const sortEntities = [...get().sortEntities];
      const index = sortEntities.findIndex(
        (entity) => entity.objectType === sortEntity.objectType,
      );
      if (index !== -1) {
        sortEntities[index] = sortEntity;
      } else {
        sortEntities.push(sortEntity);
      }
      setValue(LOCAL_STORAGE_KEYS.SORT_STATES, sortEntities);
      set(() => ({
        sortEntities,
      }));
    },
    removeSortEntity: (object?: ObjectType) => {
      const sortEntities = get().sortEntities.filter(
        (entity) => entity.objectType !== object,
      );
      set(() => ({
        sortEntities,
      }));
    },
    getSortEntity: (object?: ObjectType) =>
      get().sortEntities.find((entity) => entity.objectType === object),
  })),
);

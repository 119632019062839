import { If } from "@/common/components/if/If";
import { OrderTypePickerControlled } from "@/common/components/order-type-picker/OrderTypePickerControlled";
import { PoNumberInputControlled } from "@/common/components/po-number/PoNumberInputControlled";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { Popover } from "@/common/components/popover/Popover";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { MultiselectControlled } from "@/common/components/select/components/multiple/MultiselectControlled";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { SupplierPickerControlled } from "@/common/components/supplier-picker/SupplierPickerControlled";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { WarehouseSelectorControlled } from "@/common/components/warehouse-selector/WarehouseSelectorControlled";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useIsWarehouseRelease } from "@/contractor/pages/home/release/hooks/useIsWarehouseRelease";
import { ReleaseDateTimePickerControlled } from "@/contractor/pages/home/release/pages/specify-details/components/release-date-time-picker/ReleaseDateTimePickerControlled";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseWatchersControlled } from "@/contractor/pages/home/releases/pages/deliveries/components/ReleaseWatchersControlled";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { CreateOrderFromNoteFormValues } from "../CreateOrderFromNoteForm";
import { useCreateOrderFromNoteHeader } from "./useCreateOrderFromNoteHeader";

const Container = tw.div`w-full grid grid-cols-4 grid-row-2 gap-2 items-center z-20 p-4 -top-5 bg-blue-100 rounded-2xl drop-shadow-md`;
const SwitchContainer = tw.div`grid w-full grid-cols-[1fr_1fr] px-1 justify-between gap-2 items-center text-xs h-10`;
const InputsContainer = tw.div`
  col-span-3 h-full grid grid-cols-[1fr_1fr_1fr] 3xl:grid-cols-[1fr_1fr_1fr] gap-2 items-start content-start
`;
const FulfillmentContainer = tw.div`
  border-l pl-2 border-dashed border-gray-400 
  h-full grid gap-2 items-start content-start
`;
const VendorContactTooltip = tw.div`max-w-[300px]`;
const WatchersContainer = tw.div`self-stretch border-gray-400 text-xs flex flex-col`;

export const CreateOrderFromNoteHeader: FC = () => {
  const intl = useIntl();
  const { release } = useRelease();
  const { settings } = useOrgSettings();
  const { trigger, setValue, watch } =
    useFormContext<CreateOrderFromNoteFormValues>();
  const projectId = watch("projectId");
  const {
    locations,
    loadingLocations,
    loadingProjects,
    loadingPredictedPoNumber,
    isFoundationConnected,
    selectedProject,
    users,
    predictedPoNumber,
    projectOptions,
    orderTypeConfig,
    selectedVendor,
    isWarehouseOrder,
    requiresVendorContact,
    requiresVendor,
    projectJobSiteAddress,
    filterOrderTypes,
  } = useCreateOrderFromNoteHeader();
  const { isWarehouseRelease } = useIsWarehouseRelease();

  return (
    <Container>
      <InputsContainer>
        <If isTrue={locations.length < 1}>
          <SelectControlled
            name="businessLocationId"
            options={locations ?? []}
            getValue={(o) => o.id}
            getLabel={(o) => o.name}
            placeholder={intl.$t({ id: "BUSINESS_LOCATION" })}
            loading={loadingLocations}
            disabled={!!release}
            required
            rules={{
              required: true,
            }}
          />
        </If>
        <SupplierPickerControlled
          required={requiresVendor}
          contactsFilter={(c) => c.receivesOrderNotifications}
          includeWarehouses={settings?.inventory.enabled}
        />
        <SelectControlled
          name="projectId"
          options={projectOptions}
          getValue={(o) => o.id}
          getLabel={(o) => getProjectSelectorLabel(o)}
          placeholder={intl.$t({ id: "PROJECT_NAME" })}
          loading={loadingProjects}
          rules={{
            required: true,
          }}
        />
        <If isTrue={!isWarehouseOrder}>
          <WarehouseSelectorControlled
            name="fulfillmentLocationId"
            projectAddress={projectJobSiteAddress}
            includeDetails
          />
        </If>
        <If isTrue={!isWarehouseOrder}>
          {!!selectedVendor && selectedVendor.contacts.length === 0 ? (
            <Popover
              id="vendor-contact-ids-tooltip"
              $arrow
              classes={{}}
              element={
                <MultiselectControlled
                  name="vendorContactIds"
                  options={selectedVendor?.contacts || []}
                  getValue={(o) => o.id}
                  getLabel={(o) => o.name}
                  placeholder={intl.$t({ id: "VENDOR_CONTACT" })}
                  rules={{
                    required: requiresVendorContact,
                  }}
                  noWrap
                  limitTags={1}
                  chipSize="small"
                  includeCheckbox
                />
              }
            >
              <VendorContactTooltip>
                {intl.$t({ id: "VENDOR_WITHOUT_CONTACT_DETAILS" })}
              </VendorContactTooltip>
            </Popover>
          ) : (
            <MultiselectControlled
              name="vendorContactIds"
              options={selectedVendor?.contacts || []}
              getValue={(o) => o.id}
              getLabel={(o) => o.name}
              placeholder={intl.$t({ id: "VENDOR_CONTACT" })}
              rules={{
                required: requiresVendorContact,
              }}
              noWrap
              limitTags={1}
              chipSize="small"
              includeCheckbox
            />
          )}
        </If>
        <OrderTypePickerControlled
          name="orderTypeId"
          filterResults={filterOrderTypes}
        />
        <PoNumberingSettingsCheck>
          <PoNumberInputControlled
            setValue={(poNumber) => setValue("poNumber", poNumber)}
            trigger={() => trigger("poNumber")}
            predictedPoNumber={!isWarehouseRelease ? predictedPoNumber : ""}
            loading={loadingPredictedPoNumber}
            disabledWand={!projectId}
            disabled={isWarehouseRelease}
            skipMagicWand={isWarehouseRelease}
          />
        </PoNumberingSettingsCheck>
        <ReleaseDateTimePickerControlled />
        {selectedProject && users ? (
          <WatchersContainer>
            <FormattedMessage id="WATCHERS" />
            <ReleaseWatchersControlled
              name="watcherIds"
              moreTagCount={1}
              users={users}
            />
          </WatchersContainer>
        ) : null}
        <If
          isTrue={
            orderTypeConfig.features.includeVendorStocking &&
            isFoundationConnected
          }
        >
          <SwitchContainer>
            <FormattedMessage id="REQUIRES_INVENTORY_RECEIPT" />
            <SwitchControlled
              name="requiresInventoryReceipt"
              className="justify-self-end"
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
            />
          </SwitchContainer>
        </If>
      </InputsContainer>
      <FulfillmentContainer>
        <If isTrue={orderTypeConfig.features.includeWillCall}>
          <SwitchContainer>
            <FormattedMessage id="CLIENT_DELIVERY" />
            <SwitchControlled
              name="willCall"
              className="justify-self-end"
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
            />
          </SwitchContainer>
        </If>
        <If isTrue={orderTypeConfig.features.includeVendorStocking}>
          <SwitchContainer>
            <FormattedMessage id="VENDOR_STOCKING" />
            <SwitchControlled
              name="vendorStocking"
              className="justify-self-end"
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
            />
          </SwitchContainer>
        </If>
      </FulfillmentContainer>
    </Container>
  );
};

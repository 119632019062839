import { GridTable } from "@/common/components/grid-table/GridTable";
import { Instructions } from "@/common/components/instructions/Instructions";
import { FC } from "react";
import { useIntl } from "react-intl";
import { InventoryTransactionProps } from "../../../../../types/InventoryTransactionProps";
import { useTransactionHistoryAdjustmentsConfiguration } from "./TransactionHistoryAdjustments.configuration";

export const TransactionHistoryAdjustmentsList: FC<
  InventoryTransactionProps
> = ({ transaction }) => {
  const configuration = useTransactionHistoryAdjustmentsConfiguration();
  const intl = useIntl();
  return (
    <GridTable
      configuration={{ columns: configuration }}
      items={transaction?.items}
      footer={
        <Instructions
          hideHeader
          instructionsLabel={intl.$t({ id: "NOTES" })}
          instruction={transaction?.instructions}
          projectId={transaction?.deliverySlip?.release?.project?.id}
          classes={{ container: "bg-gray-100" }}
        />
      }
    />
  );
};

import { Dialog } from "@/common/components/dialog/Dialog";
import { ALL_WAREHOUSES_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useAddInventoryItems } from "./hooks/useAddInventoryItems";
import { AddToInventoryList } from "./inventory-items-list/AddToInventoryList";
import { useAddInventoryItemsConfiguration } from "./inventory-items-list/AddToInventoryList.configuration";

interface AddInventoryItemsDialogProps {
  setVisible: (value: boolean) => void;
  visible: boolean;
  limitToCurrentWarehouse?: boolean;
}

const Container = tw.div`w-[calc(80vw)] h-[calc(80vh)] flex-1 overflow-scroll flex flex-col`;

export const AddInventoryItemsDialog = ({
  setVisible,
  visible,
  limitToCurrentWarehouse,
}: AddInventoryItemsDialogProps) => {
  const intl = useIntl();
  const configuration = useAddInventoryItemsConfiguration();
  const {
    selectedItems,
    addSelectedItems,
    resetFilters,
    setLimitToCurrentWarehouse,
    setCurrentWarehouseId,
  } = useAddInventoryItems();

  const { currentWarehouseId } = useInventoryItems();

  useEffect(() => {
    if (visible) {
      resetFilters();
      setLimitToCurrentWarehouse(limitToCurrentWarehouse ?? false);
      if (limitToCurrentWarehouse) {
        setLimitToCurrentWarehouse(true);
      } else {
        setCurrentWarehouseId(currentWarehouseId ?? ALL_WAREHOUSES_ID);
        setLimitToCurrentWarehouse(false);
      }
    }
  }, [
    visible,
    resetFilters,
    setLimitToCurrentWarehouse,
    limitToCurrentWarehouse,
    currentWarehouseId,
    setCurrentWarehouseId,
  ]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleConfirm = useCallback(() => {
    addSelectedItems();
    setVisible(false);
  }, [setVisible, addSelectedItems]);

  return (
    <Dialog
      confirmButtonText={intl.$t(
        {
          id: "ADD_ITEMS_WITH_COUNT",
        },
        {
          count: selectedItems.length,
        },
      )}
      handleConfirm={handleConfirm}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
        confirm: {
          wide: true,
        },
      }}
      extraButtonMargin={false}
      handleCancel={handleCancel}
      maxWidth={false}
      show={visible}
      sx={{
        "& .MuiDialog-paper": {
          minHeight: "80vh",
        },
      }}
      content={
        <Container>
          <AddToInventoryList columns={configuration} />
        </Container>
      }
    />
  );
};

import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ApproverTemplateVariablesEnum } from "../../enums/ApproverTemplateVariables";
import { BuyoutTemplateVariablesEnum } from "../../enums/BuyoutTemplateVariables";
import { ExternalVendorTemplateVariablesEnum } from "../../enums/ExternalVendorTemplateVariables";
import { InvoiceTemplateVariablesEnum } from "../../enums/InvoiceTemplateVariables";
import { ProjectTemplateVariablesEnum } from "../../enums/ProjectTemplateVariables";
import { ReleaseTemplateVariablesEnum } from "../../enums/ReleaseTemplateVariables";
import { TemplateBaseVarEnum } from "../../enums/TemplateBaseVars.enum";
import { TemplateTypeEnum } from "../../enums/TemplateType.enum";
import {
  ConditionalToggle,
  Container,
  Suggestions,
  SuggestionsSubtitle,
  SuggestionsTitle,
  VariableList,
} from "./TemplateVariablesCommon";
import { useCommonTemplateVariables } from "./hooks/useCommonTemplateVariables";
import { useTemplateCustomVariables } from "./hooks/useTemplateCustomVariables";

export const TemplateCustomVariables = ({
  templateType,
}: {
  templateType: TemplateTypeEnum;
}) => {
  const {
    currentTemplate,
    includeConditional,
    toggleIncludeConditional,
    handleCopyToClipboard,
  } = useCommonTemplateVariables();

  const { formattedVariables, prefixDefaults, handleDefaultValueChange } =
    useTemplateCustomVariables({ includeConditional, templateType });
  const [defaultValues, setDefaultValues] = useState<Record<string, string>>(
    {},
  );

  useEffect(() => {
    const commonDefaultValues = {
      ...prefixDefaults(
        TemplateBaseVarEnum.PROJECT,
        ProjectTemplateVariablesEnum,
      ),
      ...prefixDefaults(
        TemplateBaseVarEnum.RELEASE,
        ReleaseTemplateVariablesEnum,
      ),
      ...prefixDefaults(
        TemplateBaseVarEnum.BUYOUT,
        BuyoutTemplateVariablesEnum,
      ),
      ...prefixDefaults(
        TemplateBaseVarEnum.EXTERNAL_VENDOR,
        ExternalVendorTemplateVariablesEnum,
      ),
    };

    const allDefaultValues =
      templateType === TemplateTypeEnum.PO
        ? commonDefaultValues
        : {
            ...commonDefaultValues,
            ...prefixDefaults(
              TemplateBaseVarEnum.INVOICE,
              InvoiceTemplateVariablesEnum,
            ),
            ...prefixDefaults(
              TemplateBaseVarEnum.APPROVER,
              ApproverTemplateVariablesEnum,
            ),
          };
    setDefaultValues(allDefaultValues);
  }, [templateType, prefixDefaults]);

  return (
    <Container>
      <Suggestions>
        <SuggestionsTitle>
          <FormattedMessage id="AVAILABLE_VARIABLES" />
          <ConditionalToggle
            checked={includeConditional}
            onChange={toggleIncludeConditional}
          />
        </SuggestionsTitle>
        <SuggestionsSubtitle>
          <FormattedMessage id="AVAILABLE_VARIABLES_SUBTITLE" />
        </SuggestionsSubtitle>
        <VariableList
          variables={formattedVariables}
          currentTemplate={currentTemplate}
          defaultValues={defaultValues}
          onCopy={handleCopyToClipboard}
          onDefaultValueChange={handleDefaultValueChange}
        />
      </Suggestions>
    </Container>
  );
};

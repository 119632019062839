import { InstructionInput, TaxType } from "@/generated/graphql";
import { FC } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useReceiptCreation } from "../../receipts/providers/ReceiptCreationProvider";

export type ReceiptCreateReleaseFormValues = {
  businessLocationId: string | null | undefined;
  projectId: string | undefined | null;
  vendorId: string | null | undefined;
  issueDate: Date | undefined;
  costCodeId: string | null | undefined;
  phaseCodeId: string | null | undefined;
  netAmount: number | null | undefined;
  taxRate: string | undefined;
  customTaxAmount: string | undefined;
  orderTypeId: string | null | undefined;
  taxType: TaxType | undefined;
  taxCodeId: string | undefined;
  paymentTerm: string | null;
  taxVariance: string | undefined | null;
  instructions: InstructionInput | undefined;
  description?: string | null | undefined;
  taxableNetAmount?: string | null | undefined;
};

type ReceiptCreateReleaseForm = UseFormReturn<
  ReceiptCreateReleaseFormValues,
  unknown,
  ReceiptCreateReleaseFormValues
>;

export const ReceiptVerificationForm: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { locationId } = useReceiptCreation();
  const receiptCreateReleaseForm: ReceiptCreateReleaseForm =
    useForm<ReceiptCreateReleaseFormValues>({
      defaultValues: {
        businessLocationId: locationId ?? undefined,
        projectId: undefined,
        vendorId: undefined,
        issueDate: undefined,
        phaseCodeId: undefined,
        costCodeId: undefined,
        customTaxAmount: undefined,
        netAmount: undefined,
        orderTypeId: undefined,
        instructions: undefined,
        description: undefined,
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });
  return <FormProvider {...receiptCreateReleaseForm}>{children}</FormProvider>;
};

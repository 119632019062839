import { routes } from "@/config/routes";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { InventoryAdjustmentFormValues } from "../../inventory-adjustment/components/inventory-adjustment-form/InventoryAdjustmentForm";
import { UNDEFINED_WAREHOUSE_ID } from "../../utils/getSelectedItemIdParts";
import { useInventoryItemSequence } from "../current-stock-list/components/hooks/useInventoryItemSequence";

export const useAddAdjustment = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { startSequence, sequenceIds } = useInventoryItemSequence();

  const handleAdjustment = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleConfirm = useCallback(
    (values: InventoryAdjustmentFormValues) => {
      if (
        sequenceIds.filter((id) => id !== UNDEFINED_WAREHOUSE_ID).length > 1
      ) {
        startSequence();
      }
      navigate({
        pathname: routes.inventoryAdjustment,
        search: `?warehouseId=${values.warehouseId}`,
      });
      setOpen(false);
    },
    [navigate, setOpen, startSequence, sequenceIds],
  );

  return {
    open,
    setOpen,
    handleAdjustment,
    handleConfirm,
  };
};

import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import { ZoneFieldsFragment } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { EditButton } from "../button/EditButton";
import { If } from "../if/If";
import { Select } from "../select/components/single/Select";

const Container = tw.div`flex flex-col`;
const Title = tw.div`text-xs text-gray-600`;
const Value = tw.div`flex items-center gap-1`;
const ValueText = tw.div`mt-1`;
const InfoIcon = tw(InfoOutlined)`text-blue-500 text-md`;

type Props = {
  zone: ZoneFieldsFragment | null | undefined;
  containerClassName?: string;
  onZoneChange?: (zoneId: string) => void;
};

export const SpecifyZone: FC<Props> = ({
  zone,
  containerClassName,
  onZoneChange,
}) => {
  const intl = useIntl();
  const { zones } = useProjectZones();

  const [editable, setEditable] = useState(false);

  const [selectedZone, setSelectedZone] = useState<
    ZoneFieldsFragment | null | undefined
  >(zone);

  useEffect(() => {
    setSelectedZone(zone);
  }, [zone]);

  const onZoneSelected = useCallback(
    (zoneId: string | null) => {
      setSelectedZone(zones.find((zone) => zone.id === zoneId));
      setEditable(false);
      if (zoneId) {
        onZoneChange?.(zoneId);
      }
    },
    [zones, onZoneChange],
  );

  if (!zone) {
    return null;
  }
  return (
    <Container className={containerClassName}>
      <If isTrue={!editable}>
        <FormattedMessage
          id={!editable ? "SPECIFIED_ZONE" : "SPECIFY_ZONE"}
          tagName={Title}
        />
      </If>
      <Value>
        <If isTrue={!editable}>
          <ValueText>{selectedZone?.name}</ValueText>
          <If isTrue={onZoneChange}>
            <EditButton onClick={() => setEditable(true)} />
          </If>
          <Tooltip element={<InfoIcon />} id="zone-info-tooltip">
            <FormattedMessage id="SPECIFY_ZONE_DESCRIPTION" />
          </Tooltip>
        </If>
        <If isTrue={editable}>
          <Select
            options={zones}
            getValue={(zone) => zone.id}
            getLabel={(zone) => zone.name}
            onChange={onZoneSelected}
            label={intl.$t({ id: "SPECIFY_ZONE" })}
            placeholder={zone?.name ?? intl.$t({ id: "SPECIFY_ZONE" })}
            value={selectedZone?.id}
            onClose={() => setEditable(false)}
            className="my-1"
          />
        </If>
      </Value>
    </Container>
  );
};

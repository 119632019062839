import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { routes } from "@/config/routes";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import {
  TransactionHistoryItemContainer,
  TransactionHistoryItemDetails,
} from "../transaction-history/components/transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";

type Props = {
  release: NonNullable<
    InventoryTransactionSummaryFieldsFragment["deliverySlip"]
  >["release"];
};

export const TransactionRelease = ({ release }: Props) => {
  const navigate = useNavigate();
  const onClick = useCallback(
    () =>
      navigate(
        generatePath(routes.delivery, {
          deliveryId: release?.id || "2",
        }),
      ),
    [release?.id, navigate],
  );
  return (
    <NotNullableRenderer value={release}>
      <TransactionHistoryItemContainer>
        <LinkLike onClick={onClick}>
          <FormattedMessage
            id="ORDER_WITH_NUMBER"
            values={{ orderNumber: release?.sequenceNumber }}
          />
        </LinkLike>
        <If isTrue={release?.poNumber}>
          <TransactionHistoryItemDetails>
            <FormattedMessage id="PO_NUMBER_SYMBOL" />
            {release?.poNumber}
          </TransactionHistoryItemDetails>
        </If>
      </TransactionHistoryItemContainer>
    </NotNullableRenderer>
  );
};

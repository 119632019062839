import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { RfqQuotesFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CreateBuyoutFromRFQDialog } from "./CreateBuyoutFromRFQDialog";

type Props = {
  rfq: RfqQuotesFieldsFragment | undefined | null;
  isDisabled: boolean;
};

export const CreateBuyoutButton: FC<Props> = ({ isDisabled }: Props) => {
  const [visible, setVisible] = useState(false);

  const proceedWithSelection = useCallback(() => {
    setVisible(true);
  }, []);

  return (
    <>
      <PrimaryButton
        onClick={proceedWithSelection}
        disabled={isDisabled}
        testId="accept-quotes-button"
        autoFocus
      >
        <FormattedMessage id="CREATE_BUYOUT" />
      </PrimaryButton>
      <CreateBuyoutFromRFQDialog visible={visible} setVisible={setVisible} />
    </>
  );
};

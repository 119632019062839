import { TransactionItemOrigin } from "@/contractor/pages/home/inventory-management/common/components/transaction-origin/TransactionItemOrigin";
import { InventoryItemPendingFlowFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { TbCrane } from "react-icons/tb";
import { TransactionOrigin } from "../../../../../transaction-history-stock/components/transaction-history-list/types/TransactionOrigin";

type Props = {
  item: InventoryItemPendingFlowFieldsFragment;
};

export const ScheduledInflowTo = ({ item }: Props) => {
  const transactionItem: TransactionOrigin = useMemo(
    () => ({
      typeName: item.release?.project?.jobNumber || "",
      name: item.release?.project?.name,
      icon: TbCrane,
    }),
    [item],
  );

  return <TransactionItemOrigin item={transactionItem} />;
};

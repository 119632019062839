import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { useInventoryItemTransactionsStore } from "../../../../../../store/useInventoryItemTransactionsStore";

export const TransactionHistoryDateRangePicker = () => {
  const { filter, setFilter } = useInventoryItemTransactionsStore();

  return (
    <ListDatesButton
      hideDates
      dates={[
        {
          startDate: filter?.minTransactionCreatedAt,
          endDate: filter?.maxTransactionCreatedAt,
        },
      ]}
      clearValues={() => {
        setFilter({
          ...filter,
          minTransactionCreatedAt: undefined,
          maxTransactionCreatedAt: undefined,
        });
      }}
      appliedFilters={filter?.minTransactionCreatedAt ? 1 : 0}
      includeFilterCount
      includeIconLabel={false}
    >
      {(onClose) => (
        <DateRangePicker
          staticView
          onClose={onClose}
          selectedValue={
            filter?.maxTransactionCreatedAt || filter?.minTransactionCreatedAt
              ? {
                  startDate: filter?.minTransactionCreatedAt
                    ? new Date(filter.minTransactionCreatedAt)
                    : new Date(),
                  endDate: filter?.maxTransactionCreatedAt
                    ? new Date(filter.maxTransactionCreatedAt)
                    : new Date(),
                }
              : undefined
          }
          onChange={(dateRange) => {
            setFilter({
              ...filter,
              minTransactionCreatedAt: dateRange.startDate?.getTime(),
              maxTransactionCreatedAt: dateRange.endDate?.getTime(),
            });
          }}
          onClear={() => {
            setFilter({
              ...filter,
              minTransactionCreatedAt: undefined,
              maxTransactionCreatedAt: undefined,
            });
          }}
        />
      )}
    </ListDatesButton>
  );
};

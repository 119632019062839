import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";

import { useUomOptions } from "@/common/hooks/useUomOptions";
import { ColumnSettings } from "handsontable/settings";
import { useCallback } from "react";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { isValueEmpty } from "../utils/isValueEmpty";

export const useValueWithUnitRenderer = () => {
  const { getUomByName } = useUomOptions();
  const baseRenderer = useBaseRenderer();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      baseRenderer(instance, td, row, col, prop, value, cellProperties);
      if (isValueEmpty(value)) {
        return;
      }

      const rowData = instance?.getDataAtRow(row);
      const columns = instance?.getSettings().columns;
      const uomValueIndex = (columns as ColumnSettings[]).findIndex((c) => {
        return c.columnType === COLUMN_TYPE.UOM;
      });
      if (!uomValueIndex) {
        return;
      }
      const uom = getUomByName(rowData[uomValueIndex]);
      if (uom && value && !Number.isNaN(Number(value))) {
        const unit = document.createElement("span");
        unit.className = "text-gray-600 font-light text-xs";
        unit.innerText = ` ${uom.mnemonic ?? uom.pluralDescription}`;
        td.appendChild(unit);
      }
    },
    [baseRenderer, getUomByName],
  );

  return renderer;
};

import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { useWarehouseSupplier } from "@/common/components/supplier-picker/hooks/useWarehouseSupplier";
import { PROJECT_ADDRESS_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useUpdateFormFieldBasedOnValue } from "@/common/hooks/useUpdateFormFieldBasedOnValue";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useUsers } from "@/common/hooks/useUsers";
import { useUser } from "@/common/providers/UserProvider";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  OrderTypeSummaryFieldsFragment,
  ReleaseNotificationsMode,
  ReleaseStatus,
  TransactionKind,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useOrderFromNote } from "../../../../../providers/OrderFromNoteProvider";
import { CreateOrderFromNoteFormValues } from "../CreateOrderFromNoteForm";

export const useCreateOrderFromNoteHeader = () => {
  const { settings } = useOrgSettings();
  const { locations, loading: loadingLocations } = useUserLocations();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const { viewer } = useUser();
  const { release } = useRelease();
  const { isWarehouseSupplier } = useWarehouseSupplier();
  const resetProjectStore = useProjectStore((state) => state.resetStore);
  const { setCurrentWarehouseId } = useInventoryItems();
  const { warehouses } = useWarehouses();

  const { vendors, findOrderTypeByLocationId, loadingPredictedPoNumber } =
    useOrderFromNote();

  const { watch, setValue } = useFormContext<CreateOrderFromNoteFormValues>();
  const isFoundationConnected =
    settings?.integrations.sourceSystems.some(
      (system) => system.connected && system.postInventoryReceipts,
    ) ||
    settings?.integrations.accounting.some(
      (accounting) => accounting.enabled && accounting.postInventoryReceipts,
    );

  const projectId = watch("projectId");
  const selectedProject = useMemo(
    () => projects.find((project) => project.id === projectId),
    [projectId, projects],
  );

  const { users } = useUsers({
    requireLocation: true,
    locationId: selectedProject?.location.id,
  });

  const predictedPoNumber = watch("predictedPoNumber");
  const vendorId = watch("vendorId");
  const { orderTypes } = useOrderTypeOptions();
  const fulfillmentLocation = watch("fulfillmentLocationId");
  const orderTypeId = watch("orderTypeId");
  const businessLocationId = watch("businessLocationId");
  const projectOptions = useMemo(
    () =>
      projects.filter((project) => project.location.id === businessLocationId),
    [projects, businessLocationId],
  );
  const { orderTypeConfig } = useOrderTypesConfig({
    release: {
      type: {
        transactionKind:
          orderTypes.find((ot) => ot.id === orderTypeId)?.transactionKind ||
          TransactionKind.Purchase,
      },
    },
  });

  const isWarehouseOrder = useMemo(
    () => isWarehouseSupplier(vendorId),
    [isWarehouseSupplier, vendorId],
  );

  useUpdateFormFieldBasedOnValue<CreateOrderFromNoteFormValues>(
    settings?.releases?.defaultWarehouse?.id ?? PROJECT_ADDRESS_ID,
    "fulfillmentLocationId",
  );

  useEffect(() => {
    if ((locations ?? []).length === 1) {
      setValue("businessLocationId", (locations ?? [])[0].id);
    }
  }, [locations, setValue]);

  useEffect(() => {
    if (projects.length === 1) {
      setValue("projectId", projects[0].id);
    }
  }, [projects, setValue]);

  useEffect(() => {
    if (vendors.length === 1) {
      setValue("vendorId", vendors[0].sellerOrgLocation.id);
    }
  }, [setValue, vendors]);

  useEffect(() => {
    if (vendorId) {
      const orderTypeId = findOrderTypeByLocationId(vendorId);
      if (orderTypeId) {
        setValue("orderTypeId", orderTypeId);
      }
    }
  }, [vendorId, setValue, findOrderTypeByLocationId]);

  useEffect(() => {
    if (vendorId) {
      const vendor = vendors.find(
        (vendor) => vendor.sellerOrgLocation.id === vendorId,
      );
      setValue(
        "vendorContactIds",
        (vendor?.contacts?.filter((c) => c.receivesOrderNotifications)
          .length === 1
          ? vendor?.contacts?.filter((c) => c.receivesOrderNotifications)
          : []
        ).map((c) => c.id) || [],
      );
      if (!vendor) {
        setValue("fulfillmentLocationId", PROJECT_ADDRESS_ID);
      }
    }
  }, [vendorId, vendors, setValue]);

  const selectedVendor = useMemo(
    () => vendors.find((v) => v.sellerOrgLocation.id === vendorId),
    [vendors, vendorId],
  );

  const requiresVendor = useMemo(() => {
    if (!release) {
      const locationRoles = viewer?.locationRoles || [];
      return !(
        settings?.releaseApproval.enabled &&
        !settings?.releaseApproval.exemptRoles.some((role) =>
          locationRoles.find(
            (lRole) =>
              lRole.role === role && lRole.orgLocID === businessLocationId,
          ),
        )
      );
    }
    if (settings?.releaseApproval.enabled) {
      if (isAuthorized(release?.permissions.submitDirectly)) {
        return true;
      }
      return (
        release?.status &&
        ![ReleaseStatus.AwaitingApproval, ReleaseStatus.Draft].includes(
          release?.status,
        )
      );
    } else {
      return true;
    }
  }, [
    businessLocationId,
    release,
    viewer?.locationRoles,
    settings?.releaseApproval.enabled,
    settings?.releaseApproval.exemptRoles,
  ]);

  const requiresVendorContact = useMemo(() => {
    const isWarehouseOrder = isWarehouseSupplier(vendorId);
    if (isWarehouseOrder) {
      return false;
    }
    return !requiresVendor
      ? false
      : selectedVendor
        ? selectedVendor.contacts.filter(
            (contact) => contact.receivesOrderNotifications,
          ).length > 0
        : true;
  }, [isWarehouseSupplier, requiresVendor, selectedVendor, vendorId]);

  useEffect(() => {
    if (selectedProject && users) {
      if (release?.project && selectedProject.id === release?.project.id) {
        setValue(
          "watcherIds",
          release?.watchers
            ?.map((watcher) => watcher.id)
            .filter((watcher) => users.some((user) => user.id === watcher)) ||
            [],
        );
        return;
      }
      if (
        settings?.releases?.notifications ===
        ReleaseNotificationsMode.SubmitterOnly
      ) {
        if (viewer?.id) {
          setValue("watcherIds", [viewer?.id]);
        }
        return;
      }
      setValue(
        "watcherIds",
        selectedProject.team
          .map((teamMember) => teamMember.id)
          .filter((teamMember) => users.some((user) => user.id === teamMember)),
      );

      if (selectedProject.preferredInventoryInflows) {
        if (orderTypes.filter((o) => o.trackInventory).length === 1) {
          const orderType = orderTypes.find((o) => o.trackInventory);
          if (orderType) {
            setValue("orderTypeId", orderType.id);
          }
        }
        if (warehouses.length === 1) {
          setValue("fulfillmentLocationId", warehouses[0].id);
        } else {
          setValue("fulfillmentLocationId", "");
        }
      }
    }
  }, [
    selectedProject,
    setValue,
    settings?.releases?.notifications,
    viewer?.id,
    release?.project,
    release?.watchers,
    users,
    orderTypes,
    warehouses,
  ]);

  useUpdateFormFieldBasedOnValue<CreateOrderFromNoteFormValues>(
    settings?.releases?.defaultWarehouse?.id ?? PROJECT_ADDRESS_ID,
    "fulfillmentLocationId",
  );

  useEffect(() => {
    if (!projectId) {
      resetProjectStore();
    }
  }, [projectId, resetProjectStore]);

  useEffect(() => {
    const warehouse = warehouses.find((w) => w.id === vendorId);
    const fulfillmentWarehouse = warehouses.find(
      (w) => w.id === fulfillmentLocation,
    );

    setCurrentWarehouseId(warehouse?.id ?? fulfillmentWarehouse?.id);
  }, [fulfillmentLocation, vendorId, warehouses, setCurrentWarehouseId]);

  const projectJobSiteAddress = useMemo(() => {
    const project = projects.find((p) => p.id === projectId);
    if (!project?.preferredInventoryInflows) {
      return project?.address;
    }
  }, [projectId, projects]);

  const filterOrderTypes = useCallback(
    (orderType: OrderTypeSummaryFieldsFragment) =>
      projects.find((p) => p.id === projectId)?.preferredInventoryInflows
        ? orderType.trackInventory
        : !orderType.trackInventory,
    [projectId, projects],
  );

  return {
    locations,
    loadingLocations,
    loadingProjects,
    loadingPredictedPoNumber,
    isFoundationConnected,
    selectedProject,
    users,
    predictedPoNumber,
    projectOptions,
    requiresVendor,
    orderTypeConfig,
    selectedVendor,
    isWarehouseOrder,
    requiresVendorContact,
    projectJobSiteAddress,
    filterOrderTypes,
  };
};

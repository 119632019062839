import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  SHORT_DATE_OPTION,
  formattedDate,
} from "@/common/utils/dates/DateView";
import {
  IntegrationType,
  PoLink,
  SourceSystem,
  useReverseSyncPoMutation,
} from "@/generated/graphql";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExternalPOModal } from "../../../../../../common/external-po/ExternalPOModal";
import { useRelease } from "../../../../../providers/ReleaseProvider";
import { ReleaseExportStatusIcon } from "./components/ReleaseExportStatusIcon";
import { RELEASE_EXPORT_STATUSES } from "./config/ReleaseExportStatuses";
import { useRefetchReleasePoLink } from "./hooks/useRefetchReleasePoLink";
import { ReleaseExportStatusEnum } from "./types/ReleaseExportStatusEnum";

const ExportedAt = tw.div`flex flex-row items-center text-2xs text-gray-600`;
const PoContainer = tw.div`flex flex-col`;
const Item = tw.div``;
const Title = tw.div`text-xs font-medium text-black`;

type Props = {
  status: ReleaseExportStatusEnum;
  poNumber?: string | null;
  projectId?: string | null;
  sourceSystem?: SourceSystem;
  date?: number | null;
  integrationType?: IntegrationType;
  poLink?: Pick<
    PoLink,
    | "immutable"
    | "id"
    | "validationErrors"
    | "failedAt"
    | "syncedAt"
    | "autoSyncError"
  > | null;
  poExists?: boolean | undefined | null;
  useSourceSystemPO?: boolean;
  iconOnly?: boolean;
  className?: string;
  tooltip?: React.ReactNode;
};

export const ReleaseExportStatus: FC<Props> = ({
  status,
  poNumber,
  projectId,
  sourceSystem,
  integrationType,
  date,
  poLink,
  poExists,
  useSourceSystemPO,
  iconOnly,
  className,
  tooltip,
}: Props) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { releaseId } = useRelease();
  useRefetchReleasePoLink({
    status,
    releaseId,
  });
  const [externalPOModalOpened, setExternalPOModalOpened] = useState(false);

  const [reverseSyncPoMutation, { loading: syncingPo }] =
    useReverseSyncPoMutation();

  const syncImmutablePo = async () => {
    if (!poLink?.id) {
      return true;
    }
    try {
      const { data } = await reverseSyncPoMutation({
        variables: {
          poLinkId: poLink.id,
        },
      });
      return !!data?.reverseSyncPO;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return (
    <>
      <Tooltip
        element={
          <ExportedAt className={className}>
            <ReleaseExportStatusIcon
              status={syncingPo ? ReleaseExportStatusEnum.Syncing : status}
              poLinkImmutable={!!poLink?.immutable}
              sourceSystem={sourceSystem}
            />
            <If isTrue={!iconOnly}>
              <PoContainer>
                <If
                  isTrue={
                    useSourceSystemPO &&
                    poLink?.syncedAt &&
                    (poLink?.validationErrors || [])?.length === 0
                  }
                >
                  <LinkLike
                    onClick={() => setExternalPOModalOpened(!!poNumber)}
                  >
                    <FormattedMessage
                      id="PO_NUMBER_INTEGRATION"
                      values={{
                        number: poNumber,
                        integration: intl.$t({
                          id: sourceSystem
                            ? `INTEGRATION_${sourceSystem}`
                            : `INTEGRATION_${integrationType}`,
                        }),
                      }}
                    />
                  </LinkLike>
                </If>
                <If
                  isTrue={
                    (poLink?.autoSyncError || [])?.length > 0 ||
                    poLink?.autoSyncError
                  }
                >
                  <FormattedMessage id="PO_EXPORT_FAILED" tagName={Title} />
                </If>
                {!!poLink?.immutable ? (
                  <FormattedMessage
                    id={"LAST_PO_UPDATE"}
                    values={{
                      date: formattedDate({
                        date,
                        options: SHORT_DATE_OPTION,
                      }),
                    }}
                    tagName={Item}
                  />
                ) : (
                  <>
                    <If
                      isTrue={
                        ((poLink || !useSourceSystemPO) &&
                          !RELEASE_EXPORT_STATUSES[status].includeDate) ||
                        date
                      }
                    >
                      <FormattedMessage
                        id={RELEASE_EXPORT_STATUSES[status].label}
                        values={{
                          date: formattedDate({
                            date,
                            options: SHORT_DATE_OPTION,
                          }),
                        }}
                        tagName={Item}
                      />
                    </If>
                    <If isTrue={poExists && !poLink && useSourceSystemPO}>
                      <FormattedMessage id="NOT_CONNECTED" tagName={Item} />
                    </If>
                  </>
                )}
              </PoContainer>
            </If>
          </ExportedAt>
        }
        hideTooltip={syncingPo || !tooltip || iconOnly}
      >
        {tooltip}
      </Tooltip>
      <ExternalPOModal
        opened={externalPOModalOpened}
        goBack={() => setExternalPOModalOpened(false)}
        poNumber={poNumber}
        projectId={projectId}
        syncImmutablePo={poLink?.immutable ? syncImmutablePo : undefined}
        syncingImmutablePo={syncingPo}
      />
    </>
  );
};

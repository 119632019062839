import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateStandaloneReleaseInput,
  DeliverySlipsDocument,
  OrgPreferredVendorsFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseStatus,
  ServiceType,
  UomsDocument,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
  useCreateStandaloneReleaseMutation,
  useProjectPredictedPoNumberQuery,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { usePrefillPricesFlag } from "@/common/components/org-roles-wrapper/usePrefillPricesFlag";
import { useTaxCodeSummaries } from "@/common/components/sales-tax-input/hooks/useTaxCodeSummaries";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useWarehouseSupplier } from "@/common/components/supplier-picker/hooks/useWarehouseSupplier";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { PROJECT_ADDRESS_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { routes } from "@/config/routes";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useIsWarehouseRelease } from "@/contractor/pages/home/release/hooks/useIsWarehouseRelease";
import { useLumpSumReleaseItems } from "@/contractor/pages/home/release/hooks/useLumpSumReleaseItems";
import { useSyncReleaseItems } from "@/contractor/pages/home/release/pages/specify-details/hooks/useSyncReleaseItems";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseStore } from "@/contractor/pages/home/release/store/useReleaseStore";
import { NoFunction, NoFunctionUndefined } from "@/types/NoFunction";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { CreateOrderFromQuoteFormValues } from "../components/order-from-quote/create-order-from-quote/components/CreateOrderFromQuoteForm";

type CreateReleaseFromQuoteArgs = {
  status?: ReleaseStatus;
};

type ProviderContextType = {
  syncCreateReleaseFromQuote: (
    args: CreateReleaseFromQuoteArgs,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
  syncUpdateReleaseFromQuote: ({
    skipVendorNotification,
  }: {
    skipVendorNotification: boolean | undefined;
  }) => Promise<ReleaseFieldsFragment | undefined | null>;
  validateSpreadsheet: () => Promise<boolean>;
  loading: boolean;
  vendors: OrgPreferredVendorsFieldsFragment[];
  loadingVendors: boolean;
  findOrderTypeByLocationId: (locationId: string) => string | undefined;
  loadingPredictedPoNumber: boolean;
  itemized: boolean;
  setItemized: (isItemized: boolean) => void;
  createLumpSumRelease: (
    args: CreateReleaseFromQuoteArgs,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
  updateLumpSumRelease: ({
    skipVendorNotification,
  }: {
    skipVendorNotification: boolean | undefined;
  }) => Promise<ReleaseFieldsFragment | undefined | null>;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateReleaseFromQuote: () => Promise.resolve(null),
  syncUpdateReleaseFromQuote: () => Promise.resolve(null),
  validateSpreadsheet: () => Promise.resolve(false),
  loading: false,
  vendors: [],
  loadingPredictedPoNumber: false,
  loadingVendors: false,
  findOrderTypeByLocationId: NoFunctionUndefined,
  itemized: true,
  setItemized: NoFunction,
  createLumpSumRelease: () => Promise.resolve(null),
  updateLumpSumRelease: () => Promise.resolve(null),
});

export const OrderFromQuoteProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const { quoteDocument } = useQuoteDocument();
  const { setError } = useGlobalError();
  const { release } = useRelease();
  const { spreadsheetData, resetPreviousData } = useColumnMapper();
  const { getSyncedRelease, validateItems, saving } = useSyncReleaseItems();
  const {
    vendors,
    loading: loadingVendors,
    findOrderTypeByLocationId,
  } = useVendors();
  const { setWarningAlert } = useSnackbar();
  const [itemized, setItemized] = useState(true);
  const { initViewStore, resetViewStore, setViewState } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
      setViewState: state.setViewState,
    })),
  );
  const prefillPrices = usePrefillPricesFlag();
  const { getLumpSumReleaseItem } = useLumpSumReleaseItems();
  const { isWarehouseSupplier } = useWarehouseSupplier();
  const { isWarehouseRelease } = useIsWarehouseRelease();
  const { updateStoreRelease } = useReleaseStore();
  const { taxCodes } = useTaxCodeSummaries();
  const { orderTypes } = useOrderTypeOptions();

  const { setValue, watch, getValues } =
    useFormContext<CreateOrderFromQuoteFormValues>();

  const projectId = watch("projectId");
  const poNumber = watch("poNumber");
  const orderDate = watch("orderDate");

  const {
    data: projectData,
    error: predictedPoNumberError,
    loading: loadingPredictedPoNumber,
  } = useProjectPredictedPoNumberQuery({
    variables: {
      id: projectId,
    },
    skip: !projectId || !!poNumber,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    initViewStore(TableViewState.spreadsheet);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  useEffect(() => {
    setViewState(itemized ? TableViewState.spreadsheet : TableViewState.normal);
  }, [itemized, setViewState]);

  useEffect(() => {
    const predictedPoNumber = projectData?.project?.predictedPoNumber;

    if (!predictedPoNumberError && predictedPoNumber) {
      return setValue("predictedPoNumber", predictedPoNumber);
    }

    return setValue("predictedPoNumber", "");
  }, [
    orderDate,
    projectData?.project?.predictedPoNumber,
    predictedPoNumberError,
    setValue,
  ]);

  const [createStandaloneRelease, { loading: creating }] =
    useCreateStandaloneReleaseMutation();
  const [updateReleaseMutation, { loading: updating }] =
    useUpdateContractorReleaseMutation();

  const validateSpreadsheet = useCallback(async () => {
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    return await validateItems();
  }, [spreadsheetData, validateItems, setWarningAlert]);

  const createRelease = async (input: CreateStandaloneReleaseInput) => {
    try {
      const { data, errors } = await createStandaloneRelease({
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
          {
            query: DeliverySlipsDocument,
          },
        ],
      });
      setError(errors);
      if (!errors) {
        resetPreviousData();
      }
      return data?.createStandaloneRelease ?? null;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  const updateRelease = async (input: UpdateContractorReleaseInput) => {
    try {
      const { data, errors } = await updateReleaseMutation({
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
          {
            query: DeliverySlipsDocument,
          },
        ],
      });
      if (data && input.releaseId) {
        updateStoreRelease(input, { taxCodes, orderTypes });
      }
      setError(errors);
      if (!errors) {
        resetPreviousData();
      }

      return data?.updateContractorRelease ?? null;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  const syncCreateReleaseFromQuote = async ({
    status,
  }: CreateReleaseFromQuoteArgs) => {
    const isValid = await validateSpreadsheet();
    const values = getValues();

    if (!isValid) {
      return null;
    }

    const { addedItems } = await getSyncedRelease();

    if (addedItems.length > 0) {
      const includeServices = [];
      if (!values.willCall) {
        includeServices.push({ type: ServiceType.Delivery });
      }
      if (!values.willCall && values.vendorStocking) {
        includeServices.push({ type: ServiceType.Stocking });
      }
      const isWarehouse = isWarehouseSupplier(values.vendorId);
      const sellerOrgLocationId = isWarehouse ? undefined : values.vendorId;

      return await createRelease({
        projectId: values.projectId ?? "",
        sellerOrgLocationId,
        sourceWarehouseId: isWarehouse ? values.vendorId : undefined,
        poNumber: !isWarehouseRelease
          ? values.poNumber || undefined
          : undefined,
        time: values.orderDate?.getTime() ?? undefined,
        timeTBD: values.timeTBD,
        items: addedItems,
        requiresInventoryReceipt: values.requiresInventoryReceipt,
        quoteDocumentId: quoteDocument?.id ?? "",
        includeServices,
        ...(isWarehouseRelease
          ? {}
          : {
              taxRate:
                values.taxRate ||
                (values.clearCustomTaxAmount ||
                values.customTaxAmount?.length === 0
                  ? "0"
                  : undefined),
              customTaxAmount:
                values.clearCustomTaxAmount ||
                values.customTaxAmount?.length === 0
                  ? undefined
                  : values.customTaxAmount,
              additionalCharges: values.additionalCharges,
              taxCodeId: values.taxCodeId,
              taxType: values.taxType,
              taxVariance: values.taxVariance,
              warehouseId: values.willCall
                ? undefined
                : values.fulfillmentLocationId === PROJECT_ADDRESS_ID
                  ? undefined
                  : values.fulfillmentLocationId,
            }),
        instructions: values.instructions,
        paymentTerm: values.paymentTerm,
        status: status,
        typeId: values.orderTypeId || undefined,
        assignDefaultCostCodes: false,
        vendorContactIds: values.vendorContactIds,
        watcherIds: values.watcherIds,
        description: values.description || undefined,
      });
    }
  };

  const syncUpdateReleaseFromQuote = async ({
    skipVendorNotification,
  }: {
    skipVendorNotification: boolean | undefined;
  }) => {
    const values = getValues();
    const isValid = await validateItems();
    if (!isValid) {
      return null;
    }

    const { addedItems, updates, removedItems } = await getSyncedRelease();

    const includeServices = [];
    if (!values.willCall) {
      includeServices.push({ type: ServiceType.Delivery });
    }
    if (!values.willCall && values.vendorStocking) {
      includeServices.push({ type: ServiceType.Stocking });
    }

    const isWarehouse = isWarehouseSupplier(values.vendorId);
    const sellerOrgLocationId = isWarehouse ? undefined : values.vendorId;

    if (release) {
      return await updateRelease({
        releaseId: release.id,
        version: release.version,
        sellerOrgLocationId,
        addedItems,
        updates,
        removedItems,
        ...(isWarehouseRelease
          ? {
              clearTaxRate: true,
              clearTaxCode: true,
              clearTaxVariance: true,
              clearCustomTaxAmount: true,
              additionalCharges: [],
            }
          : {
              taxRate: values.taxRate || undefined,
              customTaxAmount: values.customTaxAmount || undefined,
              clearTaxRate: !!values.customTaxAmount,
              clearCustomTaxAmount: !values.customTaxAmount,
              additionalCharges: values.additionalCharges.filter(
                (charge) => charge.description && Number(charge.amount) > 0,
              ),
              poNumber: values.poNumber,
              clearWarehouse:
                values.fulfillmentLocationId === PROJECT_ADDRESS_ID,
              warehouseId: values.willCall
                ? undefined
                : values.fulfillmentLocationId === PROJECT_ADDRESS_ID
                  ? undefined
                  : values.fulfillmentLocationId,
              taxVariance: values.taxVariance,
              clearTaxVariance: !values.taxVariance,
            }),
        requestedTime: values.orderDate?.getTime() ?? undefined,
        timeTBD: values.timeTBD,
        requiresInventoryReceipt: values.requiresInventoryReceipt,
        includeServices,
        instructions: values.instructions,
        typeId: values.orderTypeId || undefined,
        assignDefaultCostCodes: false,
        prefillPrices,
        watcherIds: values.watcherIds,
        description: values.description || "",
        skipVendorNotification,
      });
    }

    return release;
  };

  const createLumpSumRelease = async ({
    status,
  }: CreateReleaseFromQuoteArgs) => {
    const values = getValues();

    const items = await getLumpSumReleaseItem({
      ...values,
      netAmount: Number(values.netAmount || 0),
    });

    const data = await createRelease({
      projectId: values.projectId ?? "",
      sellerOrgLocationId: values.vendorId,
      time: values.orderDate?.getTime() ?? undefined,
      timeTBD: values.timeTBD,
      items,
      requiresInventoryReceipt: values.requiresInventoryReceipt,
      quoteDocumentId: quoteDocument?.id ?? "",
      ...(isWarehouseRelease
        ? {}
        : {
            poNumber: values.poNumber || undefined,
            taxRate:
              values.taxRate ||
              (values.clearCustomTaxAmount ||
              values.customTaxAmount?.length === 0
                ? "0"
                : undefined),
            customTaxAmount:
              values.clearCustomTaxAmount ||
              values.customTaxAmount?.length === 0
                ? undefined
                : values.customTaxAmount,
            additionalCharges: values.additionalCharges,
            taxVariance: values.taxVariance,
            taxCodeId: values.taxCodeId,
            taxType: values.taxType,
          }),
      instructions: values.instructions,
      paymentTerm: values.paymentTerm,
      status: status,
      typeId: values.orderTypeId || undefined,
      vendorContactIds: values.vendorContactIds,
      assignDefaultCostCodes: false,
      watcherIds: values.watcherIds,
      description: values.description || undefined,
    });

    if (data?.id) {
      navigate(
        generatePath(routes.delivery, {
          deliveryId: data.id,
        }),
      );
    }

    return data;
  };

  const updateLumpSumRelease = async ({
    skipVendorNotification,
  }: {
    skipVendorNotification: boolean | undefined;
  }) => {
    const values = getValues();
    const includeServices = [];
    if (!values.willCall) {
      includeServices.push({ type: ServiceType.Delivery });
    }
    if (!values.willCall && values.vendorStocking) {
      includeServices.push({ type: ServiceType.Stocking });
    }
    const newItems = await getLumpSumReleaseItem({
      ...values,
      netAmount: Number(values.netAmount || 0),
    });
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    const itemsToRemove = release?.items.map((item) => item.id);
    if (release) {
      return await updateRelease({
        releaseId: release.id,
        version: release.version,
        sellerOrgLocationId: values.vendorId,
        addedItems: newItems,
        updates: itemsToUpdate,
        removedItems: itemsToRemove,
        ...(isWarehouseRelease
          ? {
              clearTaxRate: true,
              clearTaxCode: true,
              clearTaxVariance: true,
              clearCustomTaxAmount: true,
              additionalCharges: [],
            }
          : {
              taxRate: values.taxRate || undefined,
              customTaxAmount: values.customTaxAmount || undefined,
              clearTaxRate: !!values.customTaxAmount,
              clearCustomTaxAmount: !values.customTaxAmount,
              additionalCharges: values.additionalCharges.filter(
                (charge) => charge.description && Number(charge.amount) > 0,
              ),
              poNumber: values.poNumber,
            }),
        requestedTime: values.orderDate?.getTime() ?? undefined,
        timeTBD: values.timeTBD,
        requiresInventoryReceipt: values.requiresInventoryReceipt,
        includeServices,
        instructions: values.instructions,
        typeId: values.orderTypeId || undefined,
        assignDefaultCostCodes: false,
        prefillPrices,
        watcherIds: values.watcherIds,
        description: values.description || "",
        taxVariance: values.taxVariance,
        clearTaxVariance: !values.taxVariance,
        skipVendorNotification,
      });
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCreateReleaseFromQuote,
        syncUpdateReleaseFromQuote,
        validateSpreadsheet,
        loading: saving || creating || updating,
        vendors,
        loadingVendors,
        findOrderTypeByLocationId,
        loadingPredictedPoNumber,
        itemized,
        setItemized,
        createLumpSumRelease,
        updateLumpSumRelease,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useOrderFromQuote = (): ProviderContextType =>
  useContext(ProviderContext);

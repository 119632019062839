import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { useWarehouseSupplier } from "@/common/components/supplier-picker/hooks/useWarehouseSupplier";
import { PROJECT_ADDRESS_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useUpdateFormFieldBasedOnValue } from "@/common/hooks/useUpdateFormFieldBasedOnValue";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useUsers } from "@/common/hooks/useUsers";
import { useUser } from "@/common/providers/UserProvider";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  OrderTypeSummaryFieldsFragment,
  ReleaseNotificationsMode,
  TransactionKind,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useOrderFromQuote } from "../../../../../providers/OrderFromQuoteProvider";
import { CreateOrderFromQuoteFormValues } from "../CreateOrderFromQuoteForm";

export const useCreateOrderFromQuoteHeader = () => {
  const { release } = useRelease();
  const { locations, loading: loadingLocations } = useUserLocations();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const { vendors, loadingPredictedPoNumber } = useOrderFromQuote();
  const { settings } = useOrgSettings();
  const { setCurrentWarehouseId } = useInventoryItems();
  const { warehouses } = useWarehouses();

  const { watch, setValue } = useFormContext<CreateOrderFromQuoteFormValues>();

  const isFoundationConnected =
    settings?.integrations.sourceSystems.some(
      (system) => system.connected && system.postInventoryReceipts,
    ) ||
    settings?.integrations.accounting.some(
      (accounting) => accounting.enabled && accounting.postInventoryReceipts,
    );

  const projectId = watch("projectId");

  const selectedProject = useMemo(
    () => projects.find((project) => project.id === projectId),
    [projectId, projects],
  );

  const { users } = useUsers({
    requireLocation: true,
    locationId: selectedProject?.location.id,
  });

  const predictedPoNumber = watch("predictedPoNumber");
  const vendorId = watch("vendorId");
  const { orderTypes } = useOrderTypeOptions();

  const orderTypeId = watch("orderTypeId");
  const businessLocationId = watch("businessLocationId");
  const fulfillmentLocation = watch("fulfillmentLocationId");
  const projectOptions = useMemo(
    () =>
      projects.filter((project) => project.location.id === businessLocationId),
    [projects, businessLocationId],
  );
  const willCall = watch("willCall");
  const { isWarehouseSupplier } = useWarehouseSupplier();

  const { orderTypeConfig } = useOrderTypesConfig({
    release: {
      type: {
        transactionKind:
          orderTypes.find((ot) => ot.id === orderTypeId)?.transactionKind ||
          TransactionKind.Purchase,
      },
    },
  });
  const { viewer } = useUser();

  useUpdateFormFieldBasedOnValue<CreateOrderFromQuoteFormValues>(
    settings?.releases?.defaultWarehouse?.id ?? PROJECT_ADDRESS_ID,
    "fulfillmentLocationId",
  );

  useEffect(() => {
    if ((locations ?? []).length === 1) {
      setValue("businessLocationId", (locations ?? [])[0].id);
    }
  }, [locations, setValue]);

  useEffect(() => {
    if (projects.length === 1) {
      setValue("projectId", projects[0].id);
    }
  }, [projects, setValue]);

  useEffect(() => {
    if (vendors.length === 1) {
      setValue("vendorId", vendors[0].sellerOrgLocation.id);
    }
  }, [setValue, vendors]);

  useEffect(() => {
    if (vendorId) {
      const vendor = vendors.find((v) => v.sellerOrgLocation.id === vendorId);
      setValue(
        "vendorContactIds",
        (vendor?.contacts?.filter((c) => c.receivesOrderNotifications)
          .length === 1
          ? vendor?.contacts?.filter((c) => c.receivesOrderNotifications)
          : []
        ).map((c) => c.id) || [],
      );
      if (!vendor) {
        setValue("fulfillmentLocationId", PROJECT_ADDRESS_ID);
      }
    }
  }, [vendorId, vendors, setValue]);

  const selectedVendor = useMemo(
    () => vendors.find((v) => v.sellerOrgLocation.id === vendorId),
    [vendors, vendorId],
  );

  useEffect(() => {
    if (selectedProject && users) {
      if (release?.project && selectedProject.id === release?.project.id) {
        setValue(
          "watcherIds",
          release?.watchers
            ?.map((watcher) => watcher.id)
            .filter((watcher) => users.some((user) => user.id === watcher)) ||
            [],
        );
        return;
      }
      if (
        settings?.releases?.notifications ===
        ReleaseNotificationsMode.SubmitterOnly
      ) {
        if (viewer?.id) {
          setValue("watcherIds", [viewer?.id]);
        }
        return;
      }
      setValue(
        "watcherIds",
        selectedProject.team
          .map((teamMember) => teamMember.id)
          .filter((teamMember) => users.some((user) => user.id === teamMember)),
      );
      if (selectedProject.preferredInventoryInflows) {
        if (orderTypes.filter((o) => o.trackInventory).length === 1) {
          const orderType = orderTypes.find((o) => o.trackInventory);
          if (orderType) {
            setValue("orderTypeId", orderType.id);
          }
        }
        if (warehouses.length === 1) {
          setValue("fulfillmentLocationId", warehouses[0].id);
        } else {
          setValue("fulfillmentLocationId", "");
        }
      }
    }
  }, [
    release?.project,
    release?.watchers,
    selectedProject,
    setValue,
    settings?.releases?.notifications,
    viewer?.id,
    users,
    orderTypes,
    warehouses,
  ]);

  useUpdateFormFieldBasedOnValue<CreateOrderFromQuoteFormValues>(
    settings?.releases?.defaultWarehouse?.id,
    "fulfillmentLocationId",
  );

  const isWarehouseOrder = useMemo(
    () => isWarehouseSupplier(vendorId),
    [isWarehouseSupplier, vendorId],
  );

  const requiresVendorContact = useMemo(() => {
    if (isWarehouseOrder) {
      return false;
    }
    return selectedVendor
      ? selectedVendor.contacts.filter(
          (contact) => contact.receivesOrderNotifications,
        ).length > 0
      : true;
  }, [isWarehouseOrder, selectedVendor]);

  useEffect(() => {
    const warehouse = warehouses.find((w) => w.id === vendorId);
    const fulfillmentWarehouse = warehouses.find(
      (w) => w.id === fulfillmentLocation,
    );

    setCurrentWarehouseId(warehouse?.id ?? fulfillmentWarehouse?.id);
    return () => {
      setCurrentWarehouseId(undefined);
    };
  }, [fulfillmentLocation, vendorId, warehouses, setCurrentWarehouseId]);

  const projectJobSiteAddress = useMemo(() => {
    const project = projects.find((p) => p.id === projectId);
    if (!project?.preferredInventoryInflows) {
      return project?.address;
    }
  }, [projectId, projects]);

  const filterOrderTypes = useCallback(
    (orderType: OrderTypeSummaryFieldsFragment) =>
      projects.find((p) => p.id === projectId)?.preferredInventoryInflows
        ? orderType.trackInventory
        : !orderType.trackInventory,
    [projectId, projects],
  );

  return {
    locations,
    loadingLocations,
    loadingProjects,
    loadingPredictedPoNumber,
    isFoundationConnected,
    selectedProject,
    users,
    predictedPoNumber,
    projectOptions,
    willCall,
    orderTypeConfig,
    selectedVendor,
    isWarehouseOrder,
    requiresVendorContact,
    projectJobSiteAddress,
    filterOrderTypes,
  };
};

import { DeleteButton } from "@/common/components/button/DeleteButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { BuyoutsBuyoutFieldsFragment, BuyoutStatus } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useBuyouts } from "../providers/BuyoutsProvider";
import { BuyoutExportStatus } from "./BuyoutExportStatus";

type Props = {
  buyout?: BuyoutsBuyoutFieldsFragment;
};

export const ArchiveBuyoutButton: FC<Props> = ({ buyout }) => {
  const intl = useIntl();
  const { archiveBuyout } = useBuyouts();

  const { openDialog } = useDialog();

  const openCancelBuyoutsDialog = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "DELETE" }),
      includeErrorIcon: true,
      title: intl.$t({
        id: "DELETE_BUYOUT",
      }),
      text: intl.$t({ id: "DELETE_BUYOUT_DETAILS" }),
      handleConfirm: async () => {
        if (buyout?.id) {
          await archiveBuyout(buyout.id);
        }
      },
    });
  }, [archiveBuyout, buyout?.id, intl, openDialog]);

  const isVisible = useMemo(() => {
    return (
      (buyout?.status === BuyoutStatus.Cancelled ||
        buyout?.status === BuyoutStatus.Declined ||
        buyout?.status === BuyoutStatus.Draft) &&
      !buyout?.deletedAt
    );
  }, [buyout]);

  return (
    <>
      <BuyoutExportStatus buyout={buyout} />
      <DeleteButton
        className={isVisible ? "" : "invisible"}
        onClick={openCancelBuyoutsDialog}
      />
    </>
  );
};

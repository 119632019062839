import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ArrowBack, ArrowForward, LockOutlined } from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ColumnItemType } from "../hooks/useColumnManagement";

const BaseColumnItem = tw.div`
  flex items-center justify-between py-1 px-2 mb-2 rounded-md 
  border border-gray-200 bg-white shadow-sm transition-shadow select-none
`;

type ColumnProps = {
  $isDragActive: boolean;
};

const ColumnItemContainer = tw(BaseColumnItem)<ColumnProps>`
  bg-gray-100 border-gray-300 text-gray-600 
  ${({ $isDragActive }) => ($isDragActive ? "border-blue-500 bg-blue-50 cursor-move hover:shadow-md" : "border-gray-100 shadow-none")}
`;

const ColumnHeader = tw.span<ColumnProps>`
  font-medium text-sm pl-2 select-none
  ${({ $isDragActive }) => ($isDragActive ? "text-blue-500" : "text-gray-600")}
`;

const HeaderContainer = tw.div`
  flex items-center gap-1
`;

const LockIcon = tw.div`text-gray-400 flex items-center`;
const TooltipContainer = tw.div`flex flex-col gap-1`;
const TooltipTitle = tw.span`font-medium`;
const TooltipDescription = tw.span`text-sm`;

type ColumnItemProps = {
  column: ColumnItemType;
  onDragStart: (
    e: React.DragEvent<HTMLDivElement>,
    column: ColumnItemType,
  ) => void;
  moveColumn: (columnId: string) => void;
  canBeMoved?: (column: ColumnItemType) => boolean;
  type: "enabled" | "disabled";
};

export const ColumnItem = ({
  column,
  onDragStart,
  moveColumn,
  canBeMoved = () => true,
  type,
}: ColumnItemProps) => {
  const intl = useIntl();
  const isEnabled = useMemo(() => type === "enabled", [type]);

  const handleMoveColumn = useCallback(() => {
    if (canBeMoved(column)) {
      moveColumn(column.id);
    }
  }, [canBeMoved, column, moveColumn]);

  return (
    <ColumnItemContainer
      key={column.id}
      draggable={canBeMoved(column)}
      onDragStart={(e) => onDragStart(e, column)}
      $isDragActive={canBeMoved(column)}
    >
      <HeaderContainer>
        <ColumnHeader $isDragActive={canBeMoved(column)}>
          {column.header}
        </ColumnHeader>
      </HeaderContainer>
      <IconButtonBorderless onClick={handleMoveColumn}>
        {canBeMoved(column) ? (
          isEnabled ? (
            <ArrowForward fontSize="small" />
          ) : (
            <ArrowBack fontSize="small" />
          )
        ) : (
          <Tooltip
            element={
              <LockIcon>
                <LockOutlined fontSize="small" />
              </LockIcon>
            }
          >
            <TooltipContainer>
              <TooltipTitle>{intl.$t({ id: "REQUIRED_COLUMN" })}</TooltipTitle>
              <TooltipDescription>
                {intl.$t({ id: "REQUIRED_COLUMN_DESCRIPTION" })}
              </TooltipDescription>
            </TooltipContainer>
          </Tooltip>
        )}
      </IconButtonBorderless>
    </ColumnItemContainer>
  );
};

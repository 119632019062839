import { useEffect, useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useOrgSettings } from "../../org-settings/hooks/useOrgSettings";
import { useInventoryItemsStore } from "../store/useInventoryItemsStore";

export const useInventoryItems = () => {
  const [retryIfEmpty, setRetryIfEmpty] = useState(false);

  const {
    loading,
    allInventoryItems,
    itemsByWarehouse,
    currentWarehouseId,
    setCurrentWarehouseId,
    fetchInventoryItems,
    reset,
  } = useInventoryItemsStore(
    useShallow((state) => ({
      allInventoryItems: state.inventoryItems,
      loading: state.loading,
      itemsByWarehouse: state.itemsByWarehouse,
      currentWarehouseId: state.currentWarehouseId,
      setCurrentWarehouseId: state.setCurrentWarehouseId,
      reset: state.reset,
      fetchInventoryItems: state.fetchInventoryItems,
    })),
  );

  const { settings } = useOrgSettings();

  const inventoryItems = useMemo(() => {
    if (!currentWarehouseId) {
      return allInventoryItems;
    }
    return itemsByWarehouse[currentWarehouseId] || [];
  }, [allInventoryItems, itemsByWarehouse, currentWarehouseId]);

  useEffect(() => {
    if (
      allInventoryItems.length === 0 &&
      !loading &&
      settings?.inventory.enabled &&
      !retryIfEmpty
    ) {
      fetchInventoryItems(true);
      setRetryIfEmpty(true);
    }
  }, [
    allInventoryItems.length,
    loading,
    fetchInventoryItems,
    settings?.inventory.enabled,
    retryIfEmpty,
  ]);

  return {
    inventoryItems,
    allInventoryItems,
    loading,
    itemsByWarehouse,
    currentWarehouseId,
    setCurrentWarehouseId,
    reset,
  };
};

import { FileMapper } from "@/common/components/upload-file-mapper/FileMapper";
import { FieldMappingTargetProperty, FieldPreview } from "@/generated/graphql";
import { FC } from "react";
import { useMaterialFileUpload } from "../providers/MaterialFileUploadProvider";
import { FILE_MAPPER_COLUMNS } from "./FileMapperColumns";

export type FMColumn = {
  name: string;
  details?: string;
  value: FieldMappingTargetProperty;
  required: boolean;
};

type MaterialFileMapperProps = {
  mappingMode: boolean;
  setMappingMode: (value: boolean) => void;
  fieldPreviews: FieldPreview[];
};

export const MaterialFileMapper: FC<MaterialFileMapperProps> = ({
  mappingMode,
  setMappingMode,
  fieldPreviews,
}) => {
  const { assignedColumns, setAssignedColumns } = useMaterialFileUpload();

  return (
    <FileMapper
      mappingMode={mappingMode}
      setMappingMode={setMappingMode}
      fieldPreviews={fieldPreviews}
      columns={FILE_MAPPER_COLUMNS}
      assignedColumns={assignedColumns}
      setAssignedColumns={setAssignedColumns}
    />
  );
};

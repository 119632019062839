import { InventoryManagementCheck } from "@/common/components/modules-check/components/InventoryManagementCheck";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex items-center justify-between`;
const Label = tw.div`font-medium`;
const SwitchContainer = tw.div`flex items-center gap-2`;

type Props = ControlledProps;

export const ProjectTrackInventory: FC<Props> = (props) => {
  const intl = useIntl();

  return (
    <InventoryManagementCheck>
      <Container>
        <Label>
          <FormattedMessage id="DEFAULT_PROJECT_FOR_INVENTORY_RESTOCKING" />
        </Label>
        <SwitchContainer>
          <SwitchControlled
            {...props}
            onLabel={intl.$t({ id: "YES" })}
            offLabel={intl.$t({ id: "NO" })}
          />
        </SwitchContainer>
      </Container>
    </InventoryManagementCheck>
  );
};

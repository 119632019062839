import {
  UpdateContractorReleaseInput,
  UpdateContractorReleaseOperation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useUpdateRelease } from "./useUpdateRelease";

export const useRejectRelease = () => {
  const { updateRelease, updating } = useUpdateRelease();

  const rejectRelease = useCallback(
    async (input: UpdateContractorReleaseInput) => {
      const updatedInput = {
        ...input,
        operation: UpdateContractorReleaseOperation.Reject,
      };
      return await updateRelease(updatedInput);
    },
    [updateRelease],
  );

  return {
    rejectRelease,
    rejecting: updating,
  };
};

import { ALL_WAREHOUSES_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { useInventoryItemsStore } from "@/contractor/pages/admin/inventory-items/store/useInventoryItemsStore";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { createSelectedItemId } from "../../../utils/getSelectedItemIdParts";
import { InventoryItemWithWarehouse } from "../types/InventoryItemWithWarehouse";

type State = {
  search: string;
  setSearch: (search: string) => void;
  selectedItems: string[];
  setSelectedItems: (items: string[]) => void;
  selectedCostCodes: string[];
  setSelectedCostCodes: (costCodes: string[]) => void;
  currentWarehouseId: string | null;
  setCurrentWarehouseId: (warehouseId: string | null) => void;
  limitToCurrentWarehouse: boolean;
  setLimitToCurrentWarehouse: (limit: boolean) => void;
  showZeroQuantityInventoryItems: boolean;
  setShowZeroQuantityInventoryItems: (show: boolean) => void;
  filteredInventoryItems: InventoryItemWithWarehouse[];
  filterItems: () => void;
  resetFilters: () => void;
};

export const useInventoryFiltersStore = create<State>()(
  devtools((set, get) => ({
    search: "",
    setSearch: (search) => {
      set({ search });
      get().filterItems();
    },
    selectedCostCodes: [],
    setSelectedCostCodes: (costCodes) => {
      set({ selectedCostCodes: costCodes });
      get().filterItems();
    },
    selectedItems: [],
    setSelectedItems: (items) => {
      set({ selectedItems: items });
    },
    currentWarehouseId: ALL_WAREHOUSES_ID,
    setCurrentWarehouseId: (warehouseId) => {
      set({ currentWarehouseId: warehouseId });
      get().filterItems();
    },
    showZeroQuantityInventoryItems: false,
    setShowZeroQuantityInventoryItems: (show: boolean) => {
      set({ showZeroQuantityInventoryItems: show });
      get().filterItems();
    },
    limitToCurrentWarehouse: false,
    setLimitToCurrentWarehouse: (limit: boolean) => {
      set({ limitToCurrentWarehouse: limit });
      get().filterItems();
    },
    filteredInventoryItems: [],
    filterItems: () => {
      const { itemsByWarehouse, currentWarehouseId } =
        useInventoryItemsStore.getState();
      const warehouses = Object.keys(itemsByWarehouse);
      const inventoryItems =
        get().limitToCurrentWarehouse && currentWarehouseId
          ? (itemsByWarehouse[currentWarehouseId] ?? []).map((item) => ({
              ...item,
              id: createSelectedItemId(currentWarehouseId, item.id),
              warehouse: currentWarehouseId,
            }))
          : warehouses
              .map((warehouse) =>
                itemsByWarehouse[warehouse].map((item) => ({
                  ...item,
                  id: createSelectedItemId(warehouse, item.id),
                  warehouse,
                })),
              )
              .flat();
      const filteredItems = inventoryItems
        .filter(
          (item) => item.state?.length || get().showZeroQuantityInventoryItems,
        )
        .filter((item) => {
          return (
            (!get().search ||
              item.orgMaterial.material.name
                .toLowerCase()
                .includes(get().search.toLowerCase())) &&
            (get().limitToCurrentWarehouse
              ? item.warehouse === currentWarehouseId
              : get().currentWarehouseId === ALL_WAREHOUSES_ID ||
                item.warehouse === get().currentWarehouseId) &&
            (get().selectedCostCodes.length === 0 ||
              get().selectedCostCodes.includes(
                item.orgMaterial.costCode?.id || UNSPECIFIED_COST_CODE_ID,
              ))
          );
        });
      set({
        filteredInventoryItems: filteredItems as InventoryItemWithWarehouse[],
      });
    },
    resetFilters: () => {
      set({
        selectedItems: [],
        selectedCostCodes: [],
        search: "",
        currentWarehouseId: ALL_WAREHOUSES_ID,
        showZeroQuantityInventoryItems: false,
      });
      get().filterItems();
    },
  })),
);

useInventoryItemsStore.subscribe(() => {
  useInventoryFiltersStore.getState().filterItems();
});

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { useMemo } from "react";

export const useGridLoadingInfo = () => {
  const { loading: loadingStartupData } = useStartupDataStore();
  const { loading: loadingPrices } = useVendorPrices();
  const { loading: loadingInventoryItems } = useInventoryItems();
  const { loading: loadingMaterials } = useMaterials();
  const loadingProject = useProjectStore((state) => state.loading);
  const { config } = useColumnMapper();

  const loadingData = useMemo(() => {
    let loading = loadingStartupData;
    config.forEach((col) => {
      switch (col.columnType) {
        case COLUMN_TYPE.Material:
          loading = loading || loadingMaterials;
          break;
        case COLUMN_TYPE.PrefilledPrice:
          loading = loading || loadingPrices;
          break;
        case COLUMN_TYPE.Supplier:
          loading = loading || loadingInventoryItems;
          break;
        case COLUMN_TYPE.Zone:
        case COLUMN_TYPE.Tag:
        case COLUMN_TYPE.CostCode:
        case COLUMN_TYPE.PhaseCode:
          loading = loading || loadingProject;
          break;
      }
    });
    return loading;
  }, [
    config,
    loadingPrices,
    loadingMaterials,
    loadingInventoryItems,
    loadingStartupData,
    loadingProject,
  ]);

  return {
    loadingData,
  };
};

import { PhoneView } from "@/common/components/phone-input/PhoneView";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { VendorDetailsList, VendorDetailsListItem } from "../Vendors.styles";

type Props = {
  contacts: OrgPreferredVendorsFieldsFragment["contacts"];
};

export const VendorContactPhones: FC<Props> = ({ contacts }) => {
  return (
    <VendorDetailsList>
      {contacts.map((contact, key) => (
        <VendorDetailsListItem key={key}>
          <PhoneView
            value={contact.cellPhone}
            phoneExtension={contact.cellPhoneExtension}
          />
        </VendorDetailsListItem>
      ))}
    </VendorDetailsList>
  );
};

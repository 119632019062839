import {
  AddressFieldsFragment,
  WarehouseFieldsFragment,
} from "@/generated/graphql";

export enum WarehouseType {
  Warehouse = "WAREHOUSE",
  Job = "JOB",
}

export type WarehouseOption = Pick<
  WarehouseFieldsFragment,
  "id" | "name" | "archivedAt"
> & {
  address?: AddressFieldsFragment | null;
  type?: WarehouseType;
};

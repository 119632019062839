import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { DiscardButton } from "@/common/components/button/DiscardButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { DatePicker } from "@/common/components/picker/components/DatePicker";
import { TextField } from "@/common/components/textfield/TextField";
import { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ProjectToImport,
  useImportExternalProjectsWizard,
} from "./ImportExternalProjectsWizardProvider";
import { ProjectImportForm } from "./ProjectImportForm";
import { ProjectImportInputStatus } from "./ProjectImportInputStatus";

const Container = tw.div`p-8 mt-14 mx-10`;
const Projects = tw.div`flex flex-col gap-4 mt-5`;
const Item = tw.div`grid grid-cols-[1fr_1fr_40px] text-sm bg-white items-start rounded-3xl border-dashed border border-gray-300`;
const ProjectFilledData = tw.div`h-full flex flex-col gap-2 bg-gray-100 py-4 px-8 rounded-l-3xl`;
const ProjectItemFilledData = tw.div`flex flex-row items-center gap-1`;

export const ProjectsImportForm = () => {
  const { getValues, trigger, control } = useFormContext();
  const { remove } = useFieldArray({
    control,
    name: "projects",
  });
  const { selectedExternalProjects } = useImportExternalProjectsWizard();
  const projects = getValues("projects") as ProjectToImport[];
  const intl = useIntl();
  const { openDialog } = useDialog();

  useEffect(() => {
    trigger("projects");
  }, [trigger]);

  const confirmDiscard = useCallback(
    (project: ProjectToImport, index: number) => {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "EXCLUDE" }),
        includeWarningIcon: true,
        title: intl.$t(
          { id: "EXCLUDE_PROJECT_FROM_IMPORT" },
          { projectName: project.name },
        ),
        handleConfirm: () => {
          remove(index);
        },
      });
    },
    [openDialog, intl, remove],
  );

  return (
    <Container>
      <Projects>
        {projects.map((project, key) => {
          const externalProject = selectedExternalProjects.find(
            (p) => p.externalId === project.externalId,
          );
          return (
            <Item key={key}>
              <ProjectFilledData>
                <If isTrue={externalProject?.name}>
                  <ProjectItemFilledData>
                    <ProjectImportInputStatus success />
                    <TextField
                      staticText
                      label={intl.$t({ id: "PROJECT_NAME" })}
                      value={externalProject?.name}
                      InputLabelProps={{
                        className: "ml-0",
                      }}
                      InputProps={{
                        className: "text-xl font-medium",
                      }}
                      className="w-full"
                    />
                  </ProjectItemFilledData>
                </If>
                <If isTrue={externalProject?.number}>
                  <ProjectItemFilledData>
                    <ProjectImportInputStatus success />
                    <TextField
                      staticText
                      label={intl.$t({ id: "JOB_NUMBER" })}
                      value={externalProject?.number}
                      InputLabelProps={{
                        className: "ml-0",
                      }}
                      className="w-full"
                    />
                  </ProjectItemFilledData>
                </If>
                <If isTrue={externalProject?.address?.addressLine1}>
                  <ProjectItemFilledData>
                    <ProjectImportInputStatus success />
                    <TextField
                      className="w-full"
                      staticText
                      label={intl.$t({ id: "PROJECT_ADDRESS" })}
                      value={addressToString(externalProject?.address)}
                      InputLabelProps={{
                        className: "ml-0",
                      }}
                    />
                  </ProjectItemFilledData>
                </If>
                <If isTrue={externalProject?.startDate}>
                  <ProjectItemFilledData>
                    <ProjectImportInputStatus success />
                    <DatePicker
                      staticText
                      label={intl.$t({ id: "PROJECT_START_DATE" })}
                      date={
                        externalProject?.startDate
                          ? new Date(externalProject?.startDate)
                          : null
                      }
                      textFieldComponent={(props) => (
                        <TextField
                          {...props}
                          InputLabelProps={{
                            className: "ml-0",
                          }}
                        />
                      )}
                    />
                  </ProjectItemFilledData>
                </If>
                <If isTrue={externalProject?.amount}>
                  <ProjectItemFilledData>
                    <ProjectImportInputStatus success />
                    <TextField
                      staticText
                      label={intl.$t({ id: "AMOUNT" })}
                      value={externalProject?.amount}
                      InputLabelProps={{
                        className: "ml-0",
                      }}
                    />
                  </ProjectItemFilledData>
                </If>
              </ProjectFilledData>
              <ProjectImportForm
                index={key}
                externalProject={externalProject}
              />
              <DiscardButton onClick={() => confirmDiscard(project, key)} />
            </Item>
          );
        })}
      </Projects>
    </Container>
  );
};

import { ClearFiltersButton } from "@/common/components/clean-filters-button/ClearFiltersButton";
import { HeaderFilters } from "@/common/components/header-container/HeaderContainer";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { WarehouseSelector } from "@/common/components/warehouse-selector/WarehouseSelector";
import { useIntl } from "react-intl";
import { TransactionTypePicker } from "../../../../common/components/transaction-type-picker/TransactionTypePicker";
import { InventoryManagementHeaderContainer } from "../../../../common/InventoryManagement.styles";
import { useInventoryLedgerFilter } from "../../hooks/useInventoryLedgerFilter";
import { TransactionHistoryDateRangePicker } from "./components/inventory-ledger-header-dates/InventoryLedgerHeaderDates";
import { useInventoryLedgerHeader } from "./hooks/useInventoryLedgerHeader";

export const InventoryLedgerHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useInventoryLedgerFilter();
  const { resetFilterToDefault, countNonDefaultFilters } =
    useInventoryLedgerHeader();

  return (
    <InventoryManagementHeaderContainer>
      <HeaderFilters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value) => setFilter({ ...filter, search: value })}
          value={filter?.search ?? ""}
          className="w-64"
        />
        <WarehouseSelector
          value={filter.warehouseID}
          onChange={(value) =>
            setFilter({
              ...filter,
              warehouseID: !value ? null : value,
            })
          }
          placeholder={intl.$t({ id: "ALL_WAREHOUSES" })}
          required={false}
          includeDetails
          hideGroup
          disableClearable={false}
        />
        <TransactionTypePicker
          setTransactionType={(value) => setFilter({ ...filter, type: value })}
          transactionType={filter?.type}
        />
        <TransactionHistoryDateRangePicker />
        <ClearFiltersButton
          count={countNonDefaultFilters}
          onClick={resetFilterToDefault}
        />
      </HeaderFilters>
    </InventoryManagementHeaderContainer>
  );
};

import { usePredictedReceiptNumberLazyQuery } from "@/generated/graphql";
import { useCallback } from "react";
import { useGlobalError } from "./useGlobalError";

export const useGenerateReceiptNumber = () => {
  const [fetchPredictedReceiptNumber, { loading }] =
    usePredictedReceiptNumberLazyQuery({
      fetchPolicy: "network-only",
    });
  const { setError } = useGlobalError();

  const generateReceiptNumber = useCallback(
    async (projectId: string, vendorId: string) => {
      try {
        const { data, error } = await fetchPredictedReceiptNumber({
          fetchPolicy: "network-only",
          variables: {
            projectId,
            sellerOrgLocationId: vendorId,
          },
        });
        if (error) {
          setError(error);
          return;
        }
        return data?.predictedReceiptNumber;
      } catch (error) {
        setError(error);
      }
    },
    [fetchPredictedReceiptNumber, setError],
  );

  return { loading, generateReceiptNumber };
};

import { usePageParamsStore } from "@/common/stores/usePageParamsStore";
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { FC, Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { IconButtonBorderless } from "../button/IconButton";
import { If } from "../if/If";
import { useBreadcrumbs } from "./BreadcrumbsProvider";

export const DeletedContainer = tw.div`text-2xs md:text-sm italic ml-2`;

export type BreadcrumbItem = {
  text: string | React.ReactElement;
  id: string;
  link?: string;
  keepSearchParams?: boolean;
  custom?: React.ReactElement;
};

type BreadcrumbsProps = {
  items: BreadcrumbItem[];
  appendItems?: React.ReactElement[];
  actions?: React.ReactElement[];
  floating?: boolean;
  classes?: {
    root?: string;
    text?: string;
  };
  hideRootChevronInMobile?: boolean;
};

const HeadlineContainer = tw.div`  
  flex h-23 py-2.5 items-center sticky pt-6 -mt-15 z-[190]
  print:static bg-gray-100 lg:bg-white
  ${({ $floating }: { $floating: boolean }) =>
    $floating
      ? `absolute top-24 pb-0 lg:left-16 text-xs`
      : `left-0 -top-14 lg:-top-14 pb-0 md:pb-5 text-lg`}
`;

const Actions = tw.div`
  flex flex-row flex-1 place-items-center justify-end gap-x-2 lg:gap-x-6 self-center ml-4
`;

const LinkStyled = tw(Link)`
  text-blue-500 hover:text-blue-300 print:hidden
`;

const Text = tw.div`
  font-normal text-sm md:text-base truncate
`;

const Separator = tw.span`
  print:hidden
`;

const BorderContainer = tw.div`
  flex border-b border-gray-300 w-full h-11 pb-1 print:static justify-start items-center
`;

const BreadcrumbsList = tw.div`
  grid-flow-col gap-1 items-center hidden md:grid pr-3
`;

const LeftContainer = tw.div`
  sticky left-0 grid grid-flow-col 
`;

const BreadcrumbsSmall = tw.div`
  grid grid-flow-col gap-1 justify-start items-center md:hidden p-2 text-left w-fit overflow-hidden
`;

const BreadcrumbDropdown = tw.div`
  flex items-center gap-1
`;

const ChevronRightIcon = tw(ChevronRight)`
  ${({ $xs }: { $xs: boolean }) => $xs && "text-xs"}
`;
type ItemProps = {
  item: BreadcrumbItem;
  classes?: {
    root?: string;
    text?: string;
  };
};

const Item: FC<ItemProps> = ({ item, classes }) => {
  const { search } = useLocation();
  const { pagesWithParams } = usePageParamsStore();
  return (
    <>
      {item.link ? (
        <LinkStyled
          to={
            item.keepSearchParams
              ? `${item.link}${search}`
              : pagesWithParams[item.link]
                ? `${item.link}?${pagesWithParams[item.link]}`
                : item.link
          }
          key={item.id}
        >
          <Text key={item.id} className={classes?.text}>
            {item.text}
          </Text>
        </LinkStyled>
      ) : item.custom ? (
        <>{item.custom}</>
      ) : (
        <Text key={item.id} className={classes?.text}>
          {item.text}
        </Text>
      )}
    </>
  );
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  items,
  appendItems,
  actions,
  classes,
  floating = false,
  hideRootChevronInMobile = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (event && event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { actions: additionalActions, setActions } = useBreadcrumbs();

  useEffect(() => {
    setActions([]);
  }, [setActions]);

  return (
    <HeadlineContainer $floating={floating}>
      <BorderContainer className={classes?.root}>
        <LeftContainer>
          <BreadcrumbsList data-testid="breadcrumbs-list">
            {items.map((item, index) => (
              <Fragment key={`bc-${index}`}>
                <Item item={item} classes={classes} />
                <If isTrue={index !== items.length - 1}>
                  <Separator>
                    <ChevronRightIcon $xs={floating} />
                  </Separator>
                </If>
              </Fragment>
            ))}
          </BreadcrumbsList>

          <BreadcrumbsSmall>
            <If isTrue={!hideRootChevronInMobile}>
              <IconButtonBorderless onClick={() => navigate(-1)}>
                <ChevronLeft />
              </IconButtonBorderless>
            </If>
            <BreadcrumbDropdown onClick={handleClick}>
              <Item item={items[items.length - 1]} classes={classes} />
              <If isTrue={items.length > 1}>
                <KeyboardArrowDown />
              </If>
            </BreadcrumbDropdown>
            <Menu
              anchorEl={anchorEl}
              open={open}
              variant="selectedMenu"
              onClose={handleClose}
              keepMounted
            >
              {items
                .filter((item) => item.link || item.custom)
                .map((item, index) => (
                  <MenuItem
                    key={`bc-menu-item-${index}`}
                    onClick={() => {
                      if (item.link) {
                        navigate(item.link);
                      }
                      handleClose();
                    }}
                  >
                    <Item item={item} classes={classes} />
                  </MenuItem>
                ))}
            </Menu>
          </BreadcrumbsSmall>
          {appendItems?.map((item, index) => (
            <Fragment key={`bc-app-item-${index}`}>{item}</Fragment>
          ))}
        </LeftContainer>
        <If isTrue={additionalActions?.length || actions?.length}>
          <Actions>
            {additionalActions &&
              additionalActions.map((action, index) => (
                <Fragment key={`bc-add-action-${index}`}>{action}</Fragment>
              ))}
            {actions &&
              actions.map((action, index) => (
                <Fragment key={`bc-action-${index}`}>{action}</Fragment>
              ))}
          </Actions>
        </If>
      </BorderContainer>
    </HeadlineContainer>
  );
};

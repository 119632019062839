import { useIsWarehouseRelease } from "@/contractor/pages/home/release/hooks/useIsWarehouseRelease";
import { checkboxRenderer, textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { COLUMN_TYPE } from "../../enums/columnType";
import { useTableMaps } from "../../hooks/useTableMaps";
import { getPhysicalColumnIndex } from "../../utils/getPhysicalColumnIndex";
import { rowIsEmpty } from "../../utils/rowIsEmpty";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
export const useTaxableRenderer = () => {
  const { vendorByNameMap } = useTableMaps();
  const { isWarehouseRelease } = useIsWarehouseRelease();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const rowData = instance?.getDataAtRow(row);
      if (rowIsEmpty(rowData)) {
        textRenderer(instance, td, row, col, prop, "", cellProperties);
        return;
      }

      const vendorName =
        rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)];
      const vendor = vendorByNameMap[vendorName];
      if (!isWarehouseRelease || vendor) {
        checkboxRenderer(instance, td, row, col, prop, value, cellProperties);
      } else {
        textRenderer(instance, td, row, col, prop, "", cellProperties);
      }
    },
    [isWarehouseRelease, vendorByNameMap],
  );

  return renderer;
};

import { DiscardButton } from "@/common/components/button/DiscardButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { routes } from "@/config/routes";
import { useEnabledPoInSourceSystem } from "@/contractor/common/hooks/useEnabledPoInSourceSystem";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useDeliveries } from "@/contractor/pages/home/releases/pages/deliveries/providers/DeliveriesProvider";
import { useReleaseSequence } from "@/contractor/pages/home/releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { FC, ReactNode, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { AdminUsersInfo } from "../common/AdminUsersInfo";
import { ConnectReleasePOsPanel } from "../panels/ConnectReleasePOsPanel";
import { ExportMultipleReleasePOsPanel } from "../panels/ExportMultipleReleasePOsPanel";
import { FoundationExportTypeDialog } from "./components/FoundationExportTypeDialog";
import { useExportMultipleReleasePOButton } from "./useExportMultipleReleasePOButton";

const Container = tw.div`
  grid grid-cols-[75px_repeat(5,_auto)] gap-2 relative w-fit place-items-center
`;
const NumberContainer = tw.div`
  text-sm font-medium
  ${({ $hasItems }: { $hasItems: boolean }) =>
    $hasItems ? "text-green-800" : ""}
`;
const InfoText = tw.span`grid text-2xs max-w-20 font-normal`;
const Link = tw(LinkLike)`inline`;

export const ExportMultipleReleasePOButton: FC = () => {
  const intl = useIntl();
  const { setWarningAlert } = useSnackbar();
  const { connectedIntegrationType, connectedSourceSystem } = useOrgSettings();
  const { exportEnabled, setExportEnabled } = useDeliveries();
  const {
    startSequence,
    sequenceIds,
    sequenceActive,
    selectedReleases,
    setSelectedReleases,
    stopSequence,
    allReleasesSelected,
  } = useReleaseSequence();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();
  const {
    disabledExport,
    disabledExportTooltip,
    showDialog,
    setShowDialog,
    toJob,
    setToJob,
    hasBothFoundationConnections,
    isOrgAdmin,
  } = useExportMultipleReleasePOButton();

  useEffect(() => {
    if (sequenceActive) {
      setExportEnabled(sequenceActive);
    }
  }, [exportEnabled, sequenceActive, sequenceIds, setExportEnabled]);

  const onClosePanel = useCallback(
    (togglePanel?: (opened: boolean) => void) => {
      togglePanel?.(false);
      setSelectedReleases([]);
      setExportEnabled(false);
      stopSequence();
    },
    [setExportEnabled, setSelectedReleases, stopSequence],
  );

  const startExportFlow = useCallback(() => {
    if (connectedIntegrationType || connectedSourceSystem) {
      setExportEnabled(true);
    } else if (!isOrgAdmin) {
      setWarningAlert(
        intl.$t(
          { id: "EXPORT_PO_VALIDATION_GL" },
          {
            sub: () => <AdminUsersInfo />,
          },
        ),
      );
    } else {
      setWarningAlert(
        intl.$t(
          {
            id: "EXPORT_PO_VALIDATION_GL_ADMIN",
          },
          {
            sub: (chunks: ReactNode) => (
              <Link to={generatePath(routes.connections)}>{chunks}</Link>
            ),
          },
        ),
      );
    }
  }, [
    connectedIntegrationType,
    connectedSourceSystem,
    intl,
    isOrgAdmin,
    setExportEnabled,
    setWarningAlert,
  ]);

  if (allReleasesSelected && selectedReleases.length === 0) {
    return null;
  }

  if (!exportEnabled) {
    return (
      <OutlinedButton testId="export-invoices" onClick={startExportFlow}>
        <FormattedMessage id="CREATE_SEQUENCE" />
      </OutlinedButton>
    );
  }
  return (
    <Container data-testid="batchControl">
      <FormattedMessage
        id="USE_CHECKBOXES_TO_SELECT_ITEMS"
        tagName={InfoText}
      />
      <NumberContainer $hasItems={selectedReleases.length > 0}>
        <FormattedMessage
          id="NUMBER_OF_SELECTED"
          values={{ count: sequenceIds.length }}
        />
      </NumberContainer>
      <DrawerPanel
        anchor={(togglePanel) => (
          <>
            <PrimaryButton
              testId="start-sequence"
              onClick={() => startSequence()}
              disabled={sequenceIds.length === 0}
            >
              <FormattedMessage id="START_SEQUENCE" />
            </PrimaryButton>
            <If isTrue={isPoEnabledInSourceSystem}>
              <PoNumberingSettingsCheck>
                <Tooltip
                  element={
                    <PrimaryButton
                      testId="export-multiple-pos"
                      onClick={() =>
                        hasBothFoundationConnections
                          ? setShowDialog(true)
                          : togglePanel(true)
                      }
                      disabled={disabledExport}
                    >
                      <FormattedMessage
                        id={connectedIntegrationType ? "EXPORT" : "CONNECT"}
                      />
                    </PrimaryButton>
                  }
                  id="export-button"
                >
                  {disabledExportTooltip}
                </Tooltip>

                <FoundationExportTypeDialog
                  toJob={toJob}
                  showDialog={showDialog}
                  setToJob={setToJob}
                  setShowDialog={setShowDialog}
                  togglePanel={togglePanel}
                />
              </PoNumberingSettingsCheck>
            </If>
          </>
        )}
        content={(togglePanel) => {
          if (hasBothFoundationConnections) {
            return toJob ? (
              <ConnectReleasePOsPanel
                releaseIds={selectedReleases.map((r) => r.id)}
                onClose={() => onClosePanel(togglePanel)}
                mode={ConnectionMode.Connect}
              />
            ) : (
              <ExportMultipleReleasePOsPanel
                releaseIds={selectedReleases.map((r) => r.id)}
                onClose={() => onClosePanel(togglePanel)}
              />
            );
          } else {
            return connectedSourceSystem ? (
              <ConnectReleasePOsPanel
                releaseIds={selectedReleases.map((r) => r.id)}
                onClose={() => onClosePanel(togglePanel)}
                mode={ConnectionMode.Connect}
              />
            ) : (
              <ExportMultipleReleasePOsPanel
                releaseIds={selectedReleases.map((r) => r.id)}
                onClose={() => onClosePanel(togglePanel)}
              />
            );
          }
        }}
      />
      <DiscardButton onClick={() => onClosePanel()} />
    </Container>
  );
};

import { DecimalSafe } from "@/common/utils/decimalSafe";
import { FC, useEffect } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../../../../store/useProjectStore";
import { useBudgetStore } from "../../store/useBudgetStore";

export type BudgetHeaderFormValues = {
  allowance: number | null | undefined;
  restrictCostCodes: boolean;
  zoneSpecificBudget: boolean;
};

type BudgetHeaderForm = UseFormReturn<BudgetHeaderFormValues, unknown>;

export const BudgetHeaderForm: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isZoneSpecificBudget } = useBudgetStore(
    useShallow((state) => ({
      isZoneSpecificBudget: state.isZoneSpecificBudget,
    })),
  );
  const { restrictCostCodes, allowance } = useProjectStore(
    useShallow((state) => ({
      restrictCostCodes: state.restrictCostCodes,
      allowance: state.allowance,
    })),
  );

  const budgetHeaderForm: BudgetHeaderForm = useForm<BudgetHeaderFormValues>({
    defaultValues: {
      allowance: null,
      restrictCostCodes: restrictCostCodes,
      zoneSpecificBudget: isZoneSpecificBudget,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { setValue } = budgetHeaderForm;

  useEffect(() => {
    setValue("zoneSpecificBudget", isZoneSpecificBudget);
  }, [isZoneSpecificBudget, setValue]);

  useEffect(() => {
    setValue(
      "allowance",
      new DecimalSafe(allowance.amount ?? "0").isZero()
        ? null
        : Number(allowance.amount),
    );
  }, [allowance, setValue]);

  return <FormProvider {...budgetHeaderForm}>{children}</FormProvider>;
};

import { InfoOutlined } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Tooltip } from "../../tooltip/Tooltip";

type Props = {
  invoice:
    | {
        duplicates: {
          id: string;
          archivedAt?: number | undefined | null;
        }[];
        vendorDuplicate?: boolean;
      }
    | null
    | undefined;
  className?: string;
};

const WarningIcon = tw(InfoOutlined)`text-gray-500`;
const Container = tw.div``;

export const ArchivedDuplicateInvoices: FC<Props> = ({
  invoice,
  className,
}) => {
  const tooltipKey = useMemo(() => {
    if (invoice?.duplicates.some((duplicate) => duplicate.archivedAt)) {
      return "ARCHIVED_DUPLICATED_INVOICES_TOOLTIP";
    }
    return "DUPLICATED_INVOICES_TOOLTIP";
  }, [invoice?.duplicates]);

  if (!invoice?.duplicates?.length || !!invoice?.vendorDuplicate) {
    return null;
  }

  return (
    <Tooltip
      id={`asset`}
      element={
        <Container className={className}>
          <WarningIcon />
        </Container>
      }
    >
      <FormattedMessage id={tooltipKey} tagName="div" />
    </Tooltip>
  );
};

import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { RELEASE_EXPORT_STATUSES } from "../config/ReleaseExportStatuses";
import { ReleaseExportStatusEnum } from "../types/ReleaseExportStatusEnum";

type Props = {
  validationErrors: string[];
};

const Container = tw.div`flex flex-col text-left`;
const ErrorMessage = tw.li``;

export const ReleaseExportValidationErrors = ({ validationErrors }: Props) => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();

  if (!validationErrors?.length) {
    return null;
  }
  return (
    <Container>
      <FormattedMessage
        id={
          RELEASE_EXPORT_STATUSES[ReleaseExportStatusEnum.BackgroundExportIssue]
            .tooltip
        }
        values={{
          validationMessages: (
            <>
              {validationErrors.map((error, index) => (
                <ErrorMessage key={index}>{error}</ErrorMessage>
              ))}
            </>
          ),
          count: validationErrors.length,
          integration: intl.$t({
            id: `INTEGRATION_${connectedSourceSystem}`,
          }),
        }}
      />
    </Container>
  );
};

import { If } from "@/common/components/if/If";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { UserInfoHeaderItem } from "@/common/components/info-header/components/UserInfoHeaderItem";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { OrderTypePicker } from "@/common/components/order-type-picker/OrderTypePicker";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { SupplierInsuranceInfo } from "@/common/components/supplier-insurance-info/SupplierInsuranceInfo";
import { PROJECT_ADDRESS_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { WarehouseSelector } from "@/common/components/warehouse-selector/WarehouseSelector";
import { useUser } from "@/common/providers/UserProvider";
import { useComplianceGroups } from "@/contractor/pages/admin/orders/pages/compliance-groups/hooks/useComplianceGroups";
import { ReleasePONumber } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleasePONumber";
import { useProjectNavigate } from "@/contractor/pages/home/projects/hooks/useProjectNavigate";
import { ServiceType, TransactionKind } from "@/generated/graphql";
import { WarehouseOutlined } from "@mui/icons-material";
import { FormControl } from "@mui/material";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useReleaseActions } from "../../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../../providers/ReleaseUpdateProvider";
import { ReleaseGroups } from "../../../release-details/ReleaseGroups";
import { ReleaseWatchers } from "../../../release-details/ReleaseWatchers";
import { useReleasePredictedPoNumber } from "../../hooks/useReleasePredictedPoNumber";
import { ReleaseVendorContactSelector } from "../../ReleaseVendorContactSelector";
import { ReleaseDateTimePicker } from "../release-date-time-picker/ReleaseDateTimePicker";
import { SiteContactPicker } from "../site-contact-picker/components/SiteContactPicker";
import {
  specifyDetailsInputLabelProps,
  specifyDetailsInputProps,
  SpecifyDetailsNonDraftHeaderContainer,
  WatchersContainer,
  WatchersLabel,
} from "./SpecifyDetailsHeader.styles";
import { useSpecifyDetailsHeader } from "./useSpecifyDetailsHeader";

const InnerContainer = tw.div`grid grid-cols-4 grid-rows-2 gap-x-2 gap-y-3 items-stretch`;
const InnerDetailsContainer = tw.div`grid grid-cols-3`;

export const SpecifyDetailsNonDraftHeader = () => {
  const { release } = useRelease();
  const { loading } = useReleasePredictedPoNumber(release?.id || "");
  const { updateRelease } = useReleaseUpdate();
  const { requestedDate } = useReleaseActions();
  const { includeOrderTypes } = useOrderTypeOptions();
  const { viewer } = useUser();
  const { getProjectPath } = useProjectNavigate();
  const { formatComplianceGroup } = useComplianceGroups();
  const {
    update,
    onDateConfirm,
    predictedPoNumberPlaceholder,
    setOrderTypeId,
    filterOrderTypes,
    projectJobSiteAddress,
  } = useSpecifyDetailsHeader();

  if (!release) {
    return;
  }
  return (
    <SpecifyDetailsNonDraftHeaderContainer>
      <InnerDetailsContainer>
        <OrgDetailsHeaderItem
          title={<FormattedMessage id="SUPPLIER" />}
          name={
            release.sellerOrgLocation?.org.name || release.sourceWarehouse?.name
          }
          logoImageUrl={
            !release.sourceWarehouse
              ? release.sellerOrgLocation?.org?.photoUrl
              : undefined
          }
          logoIcon={
            release.sourceWarehouse ? (
              <WarehouseOutlined className="h-[50px] w-[50px]" />
            ) : undefined
          }
          address={
            release.sourceWarehouse?.address ??
            release.sellerOrgLocation?.address
          }
          useDefaultTitlePosition={false}
          nameAdditionalComponent={
            <SupplierInsuranceInfo
              vendorId={release?.sellerOrgLocation?.id}
              className="ml-1"
            />
          }
          complianceGroup={formatComplianceGroup(release.complianceGroup)}
        />
        <OrgDetailsHeaderItem
          title={<FormattedMessage id="PROJECT" />}
          navTo={getProjectPath(release.project)}
          name={
            release.project
              ? `${release.project.name}${release.project.jobNumber ? ` (${release.project.jobNumber})` : ""}`
              : undefined
          }
          includeAddress={false}
        />
        <InfoHeaderItem>
          <UserInfoHeaderItem
            title={<FormattedMessage id="REQUESTED_BY" />}
            users={[release.createdBy]}
            displayDateFn={() => release.createdAt}
          />
        </InfoHeaderItem>
      </InnerDetailsContainer>
      <InnerContainer>
        <If isTrue={!release.sourceWarehouse?.id}>
          <ReleaseVendorContactSelector />
        </If>
        <FormControl fullWidth>
          <If
            isTrue={
              release?.includeServices.includes(ServiceType.Delivery) ||
              release?.type.transactionKind === TransactionKind.Purchase
            }
          >
            <WarehouseSelector
              value={release?.warehouse?.id}
              projectAddress={projectJobSiteAddress}
              onChange={(warehouseId) =>
                updateRelease({
                  releaseId: release?.id || "",
                  version: release?.version,
                  warehouseId:
                    warehouseId === PROJECT_ADDRESS_ID
                      ? undefined
                      : warehouseId,
                  clearWarehouse: warehouseId === PROJECT_ADDRESS_ID,
                })
              }
              readonly={!!release.sourceWarehouse?.id}
              inputProps={specifyDetailsInputProps}
              includeDetails
            />
          </If>
        </FormControl>
        <PoNumberingSettingsCheck alternativeCondition={!!release?.poNumber}>
          <FormControl fullWidth>
            <ReleasePONumber
              update={(_, poNumber?: string) => update(poNumber)}
              release={release}
              sourceSystem={release?.poLink?.sourceSystem}
              autoSize
              placeholder={predictedPoNumberPlaceholder}
              loading={loading}
              size="medium"
              inputProps={specifyDetailsInputProps}
              disabled={!!release.sourceWarehouse?.id}
            />
          </FormControl>
        </PoNumberingSettingsCheck>
        <FormControl fullWidth>
          <ReleaseDateTimePicker
            value={requestedDate}
            onChange={onDateConfirm}
          />
        </FormControl>
        <WatchersContainer>
          <FormattedMessage id="WATCHERS" tagName={WatchersLabel} />
          <ReleaseWatchers moreTagCount={1} />
        </WatchersContainer>
        <FormControl fullWidth>
          <SiteContactPicker
            value={release?.siteContact?.id}
            onChange={(contactId) =>
              updateRelease({
                releaseId: release?.id || "",
                version: release?.version,
                siteContactId: contactId || undefined,
                clearSiteContact: !contactId,
              })
            }
            countryCode={viewer?.org.hqAddress?.country}
            inputProps={specifyDetailsInputProps}
            InputLabelProps={specifyDetailsInputLabelProps}
          />
        </FormControl>
        <If isTrue={includeOrderTypes}>
          <FormControl fullWidth>
            <OrderTypePicker
              orderTypeId={release?.type.id}
              setOrderTypeId={setOrderTypeId}
              disabled={!!release?.buyout}
              inputProps={specifyDetailsInputProps}
              filterResults={filterOrderTypes}
            />
          </FormControl>
        </If>
        <ReleaseGroups includeLabel classes={{ label: "-top-2" }} />
      </InnerContainer>
    </SpecifyDetailsNonDraftHeaderContainer>
  );
};

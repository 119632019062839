import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  SpreadsheetSaveType,
  useColumnMapper,
} from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { SetVendorPriceInput } from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMaterials } from "../../materials/hooks/useMaterials";
import { useVendorPrices } from "../hooks/useVendorPrices";

type ProviderContextType = {
  syncMaterialPrices: (trigger?: SpreadsheetSaveType) => Promise<boolean>;
  saving: boolean;
};

type Props = {
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  syncMaterialPrices: NoFunctionBooleanPromise,
  saving: false,
});

export const SyncPricesProvider: FC<Props> = ({ children }) => {
  const { spreadsheetData, resetPreviousData, gotoInvalidRow } =
    useColumnMapper();
  const {
    addMissingMaterials,
    addMissingManufacturers,
    getCellWithAdditionalData,
    confirmRemove,
  } = useTableHelpers();
  const findMaterialByName = useFindMaterialByName();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { manufacturers } = useManufacturers();
  const { materials } = useMaterials();
  const { vendors } = useVendors();
  const { updatePrices, updating } = useVendorPrices();
  const viewState = useTableViewStore((state) => state.viewState);
  const [saving, setSaving] = useState(false);
  const { setSuccessAlert, setWarningAlert } = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    setSaving(updating);
  }, [updating]);

  const syncMaterialPrices = async () => {
    if (viewState !== TableViewState.spreadsheet) {
      return true;
    }

    const filtered = spreadsheetData.filter((row) => {
      const materialText = getCellWithAdditionalData(row, COLUMN_TYPE.Material);
      const material = findMaterialByName(materialText);
      return (
        row[COLUMN_TYPE.Supplier] ||
        row[COLUMN_TYPE.UnitPrice] ||
        row[COLUMN_TYPE.ExpirationDate] ||
        row[COLUMN_TYPE.LeadTime] ||
        row[COLUMN_TYPE.MinimumOrder] ||
        row[COLUMN_TYPE.OrderIncrement] ||
        !material?.id
      );
    });

    if (
      !(await validateRequiredValues(
        [
          COLUMN_TYPE.Material,
          COLUMN_TYPE.Supplier,
          COLUMN_TYPE.UOM,
          COLUMN_TYPE.UnitPrice,
        ],
        filtered,
      )) ||
      !(await validateRowValues(
        [
          COLUMN_TYPE.Supplier,
          COLUMN_TYPE.UOM,
          COLUMN_TYPE.UnitPrice,
          COLUMN_TYPE.ExpirationDate,
          COLUMN_TYPE.LeadTime,
          COLUMN_TYPE.MinimumOrder,
          COLUMN_TYPE.OrderIncrement,
        ],
        filtered,
      ))
    ) {
      gotoInvalidRow();
      return false;
    }

    const duplicateNames = filtered.find((row) => {
      const rowMaterialText = getCellValue(row, COLUMN_TYPE.Material);
      const vendor = getCellValue(row, COLUMN_TYPE.Supplier);
      const manufacturer = getCellValue(row, COLUMN_TYPE.Manufacturer);
      const uom = getCellValue(row, COLUMN_TYPE.UOM);

      return (
        rowMaterialText &&
        vendor &&
        manufacturer &&
        uom &&
        filtered.filter((r) => {
          return (
            getCellWithAdditionalData(r, COLUMN_TYPE.Material) ===
              rowMaterialText &&
            getCellValue(r, COLUMN_TYPE.Supplier) === vendor &&
            getCellValue(r, COLUMN_TYPE.Manufacturer) === manufacturer &&
            getCellValue(r, COLUMN_TYPE.UOM) === uom
          );
        }).length > 1
      );
    });
    if (duplicateNames) {
      setWarningAlert(
        intl.$t(
          { id: "DUPLICATE_MATERIAL_NAME" },
          { name: duplicateNames?.[COLUMN_TYPE.Material] },
        ),
      );
      return false;
    }

    const itemsToUpdate: SetVendorPriceInput[] = [];

    setSaving(true);
    const newManufacturers = (await addMissingManufacturers()) || [];
    const newMaterials = (await addMissingMaterials(newManufacturers)) || [];
    setSaving(false);

    const newItems = [] as string[];
    const itemsToRemove = [] as string[];

    filtered.forEach((row) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      const material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);
      const uom = getCellValue(row, COLUMN_TYPE.UOM);
      const vendorText = getCellValue(row, COLUMN_TYPE.Supplier);
      const vendor = vendors.find(
        (v) => vendorLabelFormatter(v.sellerOrgLocation) === vendorText,
      );
      const manufacturerText = getCellValue(row, COLUMN_TYPE.Manufacturer);
      const manufacturer = [...manufacturers, ...newManufacturers].find(
        (m) => m?.name === manufacturerText,
      );
      const price = getCellValue(row, COLUMN_TYPE.UnitPrice);
      const expirationDate = getCellValue(row, COLUMN_TYPE.ExpirationDate);
      const leadTime = getCellValue(row, COLUMN_TYPE.LeadTime);
      const minimumOrder = getCellValue(row, COLUMN_TYPE.MinimumOrder);
      const orderIncrement = getCellValue(row, COLUMN_TYPE.OrderIncrement);
      if (material && uom && vendor && price) {
        itemsToUpdate.push({
          id: row.id || undefined,
          orgMaterialId: material.id,
          manufacturerId: manufacturer?.id,
          sellerOrgLocationId: vendor.sellerOrgLocation.id,
          price: String(price),
          uom,
          expirationDate: expirationDate
            ? new Date(expirationDate).getTime()
            : undefined,
          leadTime: leadTime ? Number(leadTime) : undefined,
          minimumOrder: minimumOrder ? Number(minimumOrder) : undefined,
          orderIncrement: orderIncrement ? Number(orderIncrement) : undefined,
        });

        newItems.push(material.id);
      } else {
        itemsToRemove.push(row.id);
      }
    });

    const canRemoveItems = await confirmRemove(newItems, itemsToRemove, {
      title: "CONFIRM_REMOVE_MATERIAL_PRICES_TITLE",
      text: "CONFIRM_REMOVE_MATERIAL_PRICES",
    });
    if (!canRemoveItems) {
      return false;
    }

    const result = await updatePrices({
      items: itemsToUpdate,
    });

    if (result) {
      resetPreviousData();
      setSuccessAlert(intl.$t({ id: "PRICES_SAVED_SUCCESS" }));
    }
    return result;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncMaterialPrices,
        saving,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSyncPrices = (): ProviderContextType =>
  useContext(ProviderContext);

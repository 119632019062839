import { useCallback, useMemo } from "react";
import { useInventoryLedgerFilter } from "../../../hooks/useInventoryLedgerFilter";

export const useInventoryLedgerHeader = () => {
  const { filter, setFilter } = useInventoryLedgerFilter();

  const resetFilterToDefault = useCallback(() => {
    setFilter({});
  }, [setFilter]);

  const countNonDefaultFilters = useMemo(
    () =>
      Number(filter?.origin != null) +
      Number(filter?.type != null) +
      Number(filter.warehouseID != null) +
      Number(filter?.search != null && filter?.search !== "") +
      Number(filter.origin != null) +
      Number(filter?.minCreatedAt != null || filter?.maxCreatedAt != null),
    [
      filter.origin,
      filter?.type,
      filter.warehouseID,
      filter?.search,
      filter?.minCreatedAt,
      filter?.maxCreatedAt,
    ],
  );

  return {
    resetFilterToDefault,
    countNonDefaultFilters,
  };
};

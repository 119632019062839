import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ControlledProps } from "../textfield-controlled/TextFieldControlled";
import { PricePicker, PricePickerProps } from "./PricePicker";

type PricePickerControlledProps = ControlledProps &
  Omit<PricePickerProps, "value">;

export const PricePickerControlled: FC<PricePickerControlledProps> = ({
  name,
  rules,
  ...props
}) => {
  const { control } = useFormContext();
  const formState = control.getFieldState(name);

  return (
    <Controller
      name={name as string}
      rules={rules}
      control={control}
      render={({ field }) => {
        return (
          <PricePicker
            {...field}
            testId={name}
            error={!!formState.invalid}
            onBlur={field.onChange}
            {...props}
          />
        );
      }}
    />
  );
};

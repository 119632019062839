import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useExternalPoLazyQuery } from "@/generated/graphql";
import { useCallback } from "react";
import { MatchedOrderViewState } from "../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";
import { useInvoiceImportExternalPO } from "./useInvoiceImportExternalPO";

export const useLazyLoadExternalPo = () => {
  const { invoice } = useInvoiceVerification();
  const { importExternalPO } = useInvoiceImportExternalPO();
  const { connectedSourceSystem } = useOrgSettings();
  const [loadExternalPo, { loading }] = useExternalPoLazyQuery();
  const { setAutoMatching, setMatchedOrderViewState } =
    useInvoiceMatchedOrder();
  const { setError } = useGlobalError();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const fetchExternalPo = useCallback(async () => {
    const disableAutoMatching =
      !connectedSourceSystem ||
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.ExternalPoReadsProjectSpecific,
      );
    if (disableAutoMatching) {
      setAutoMatching(false);
      setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
      return;
    }

    try {
      const { data } = await loadExternalPo({
        variables: {
          input: {
            number: invoice?.poNumber,
            sourceSystem: connectedSourceSystem,
          },
        },
      });
      if (data?.externalPO) {
        importExternalPO(data?.externalPO);
      } else {
        setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
        setAutoMatching(false);
      }
    } catch (errors) {
      setError(errors);
      setAutoMatching(false);
    }
  }, [
    connectedSourceSystem,
    hasFeatureInConnectedSourceSystem,
    setAutoMatching,
    setMatchedOrderViewState,
    loadExternalPo,
    invoice?.poNumber,
    importExternalPO,
    setError,
  ]);

  return { fetchExternalPo, loading };
};

import tw from "tailwind-styled-components";
import { TransactionHistoryScheduledInflowsHeader } from "./components/transaction-history-scheduled-inflows-header/TransactionHistoryScheduledInflowsHeader";
import { TransactionHistoryScheduledInflowsList } from "./components/transaction-history-scheduled-inflows-list/TransactionHistoryScheduledInflowsList";

const Container = tw.div`flex flex-col`;

export const TransactionHistoryScheduledInflows = () => {
  return (
    <Container>
      <TransactionHistoryScheduledInflowsHeader />
      <TransactionHistoryScheduledInflowsList />
    </Container>
  );
};

import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { useWizardStore } from "@/common/components/wizard-modal/store/useWizardStore";
import { getWizardArg } from "@/common/components/wizard-modal/utils/getWizardArg";
import { WarehouseInventoryItem } from "@/contractor/pages/home/inventory-management/pages/current-stock/types/WarehouseInventoryItem";
import { WarehouseOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import {
  TransactionHistoryHeaderItem,
  TransactionHistoryHeaderItemDetails,
} from "../../../transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";
import { useInventoryItemScheduledTransactionsStore } from "../../store/useInventoryItemScheduledTransactionsStore";
import { TransactionHistoryScheduledInflowDateRangePicker } from "./components/TransactionHistoryScheduledInflowDateRangePicker";

const Item = tw(
  TransactionHistoryHeaderItem,
)`border-r border-gray-400 border-dashed`;
const ItemWithLabel = tw(Item)`flex flex-row items-center gap-2 border-none`;
const ItemWithoutBorder = tw(Item)`border-none`;
const Title = tw.div`text-lg font-medium max-w-25`;
const Gap = tw.div`2xl:w-30 w-0`;

export const TransactionHistoryScheduledInflowsHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useInventoryItemScheduledTransactionsStore();
  const { args } = useWizardStore(
    useShallow((state) => ({
      args: state.args,
    })),
  );

  const inventoryItem = useMemo(
    () => getWizardArg<WarehouseInventoryItem>(args, "inventoryItem"),
    [args],
  );
  return (
    <InfoHeader>
      <Item>
        <FormattedMessage id="SCHEDULED_INFLOW" tagName={Title} />
      </Item>
      <Item>
        <FormattedMessage
          id="ITEM_NAME"
          tagName={TransactionHistoryHeaderItemDetails}
        />
        {inventoryItem?.orgMaterial.material.name}
      </Item>
      <ItemWithLabel>
        <WarehouseOutlined className="text-blue-800" />
        <ItemWithoutBorder>
          <FormattedMessage
            id="ITEM_LOCATION"
            tagName={TransactionHistoryHeaderItemDetails}
          />
          {inventoryItem?.warehouse.name}
        </ItemWithoutBorder>
      </ItemWithLabel>
      <Gap />
      <ItemWithoutBorder>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value) => setFilter({ ...filter, search: value })}
          value={filter?.search ?? ""}
          className="2xl:w-64"
        />
      </ItemWithoutBorder>
      <ItemWithoutBorder>
        <TransactionHistoryScheduledInflowDateRangePicker />
      </ItemWithoutBorder>
    </InfoHeader>
  );
};

type DownloadFileOptions = {
  data: string;
  fileName: string;
  mimeType: string;
  extension: string;
};

export const downloadFile = ({
  data,
  fileName,
  mimeType,
  extension,
}: DownloadFileOptions) => {
  const blob = new Blob([data], { type: `${mimeType};charset=UTF-8"` });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}.${extension}`;
  a.click();
  window.URL.revokeObjectURL(url);
};

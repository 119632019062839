import { InventoryManagementCheck } from "@/common/components/modules-check/components/InventoryManagementCheck";
import { FC } from "react";
import { Outlet } from "react-router";
import tw from "tailwind-styled-components";
import { InventoryManagementTabNavigation } from "./InventoryManagementTabNavigation";

const Container = tw.div`mt-5 xl:px-16 xl:-mx-16 bg-gray-100 flex flex-col -mb-8 pb-8 min-h-[calc(100vh-210px)]`;

export const WithInventoryTabNavigation: FC = () => (
  <InventoryManagementCheck>
    <InventoryManagementTabNavigation />
    <Container>
      <Outlet />
    </Container>
  </InventoryManagementCheck>
);

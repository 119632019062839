import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useListSelection } from "@/common/hooks/useListSelection";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { readValue, removeValue, setValue } from "@/common/utils/localStorage";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { routes } from "@/config/routes";
import {
  ReleasePartialFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { generatePath, useLocation, useNavigate } from "react-router";
import { useReleaseNavigation } from "../hooks/useReleaseNavigation";
import { useDeliveries } from "./DeliveriesProvider";

type StoredRelease =
  | ReleasePartialFieldsFragment
  | Pick<ReleasePartialFieldsFragment, "id" | "status">;

type ProviderContextType = {
  step: number;
  setStep: (step: number) => void;
  sequenceIds: string[];
  selectedReleases: StoredRelease[];
  setSelectedReleases: (releases: StoredRelease[]) => void;
  sequenceActive: boolean;
  startSequence: (
    initialReleases?: StoredRelease[],
    initialStep?: number,
  ) => void;
  stopSequence: () => void;
  navigateToNextSequence: (params?: {
    navigateToReleases: boolean;
    navigateToReleaseId?: string | undefined;
  }) => void;
  redirectToReleases: () => void;
  selectAll: () => void;
  clearAll: () => void;
  allReleasesSelected: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  step: 0,
  setStep: NoFunction,
  sequenceIds: [],
  selectedReleases: [],
  setSelectedReleases: NoFunction,
  sequenceActive: false,
  startSequence: NoFunction,
  stopSequence: NoFunction,
  navigateToNextSequence: NoFunction,
  redirectToReleases: NoFunction,
  selectAll: NoFunction,
  clearAll: NoFunction,
  allReleasesSelected: false,
});

export const ReleaseSequenceProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pageSize } = usePagination();
  const { totalCount, deliveries } = useDeliveries();
  const [selectedReleases, setSelectedReleases] = useState<StoredRelease[]>(
    readValue(
      LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SELECTED_RELEASES,
      [],
    ) as StoredRelease[],
  );
  const [step, setStep] = useState(
    readValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_STEP, 0) as number,
  );
  const [sequenceActive, setSequenceActive] = useState(
    readValue(
      LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_ACTIVE,
      false,
    ) as boolean,
  );
  const { getReleaseEditPath } = useReleaseNavigation();
  const {
    allItemsSelected: allReleasesSelected,
    setAllItemsSelected: setAllReleasesSelected,
  } = useListSelection({
    totalCount,
    selectedCount: selectedReleases.length,
  });

  const setSelectedReleasesAndUpdateLocalStorage = useCallback(
    (releases: StoredRelease[]) => {
      setSelectedReleases(releases);
      setValue(
        LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SELECTED_RELEASES,
        releases,
      );
    },
    [],
  );

  const setSequenceActiveAndUpdateLocalStorage = useCallback(
    (active: boolean) => {
      setSequenceActive(active);
      setValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_ACTIVE, active);
    },
    [],
  );

  const setStepAndUpdateLocalStorage = useCallback((step: number) => {
    setStep(step);
    setValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_STEP, step);
  }, []);

  const redirectToRelease = useCallback(
    (sequenceStep: number, initialReleases?: StoredRelease[]) => {
      const firstRelease = initialReleases
        ? initialReleases[sequenceStep]
        : selectedReleases[sequenceStep];
      if (firstRelease) {
        setValue(
          LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.REDIRECT_ROUTE,
          location.search,
        );
        let path = routes.delivery;
        if (
          (checkReleaseStatus(firstRelease, [
            ReleaseStatus.Draft,
            ReleaseStatus.AwaitingApproval,
            ReleaseStatus.Rejected,
            ReleaseStatus.Reserved,
          ]) &&
            isAuthorized(
              (firstRelease as ReleasePartialFieldsFragment)?.permissions
                ?.submit,
            )) ||
          !(firstRelease as ReleasePartialFieldsFragment)?.permissions
        ) {
          path = getReleaseEditPath(
            firstRelease as ReleasePartialFieldsFragment,
          );
        }
        navigate(
          generatePath(path, {
            deliveryId: firstRelease.id,
            noteDocumentId: (firstRelease as ReleasePartialFieldsFragment)
              ?.noteDocument?.id,
            quoteDocumentId: (firstRelease as ReleasePartialFieldsFragment)
              ?.quoteDocument?.id,
          }),
        );
      }
    },
    [getReleaseEditPath, location.search, navigate, selectedReleases],
  );

  const startSequence = useCallback(
    (initialStoredReleases?: StoredRelease[], initialStep?: number) => {
      setStepAndUpdateLocalStorage(initialStep ?? 0);
      setSelectedReleasesAndUpdateLocalStorage(
        initialStoredReleases ?? selectedReleases,
      );
      setSequenceActiveAndUpdateLocalStorage(true);
      setValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_ACTIVE, true);
      const sequenceStep = readValue(
        LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_STEP,
        0,
      ) as number;
      redirectToRelease(initialStep ?? sequenceStep, initialStoredReleases);
    },
    [
      redirectToRelease,
      selectedReleases,
      setSelectedReleasesAndUpdateLocalStorage,
      setSequenceActiveAndUpdateLocalStorage,
      setStepAndUpdateLocalStorage,
    ],
  );

  const stopSequence = useCallback(() => {
    setSelectedReleasesAndUpdateLocalStorage([]);
    setSequenceActiveAndUpdateLocalStorage(false);
    setStepAndUpdateLocalStorage(0);
    removeValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.REDIRECT_ROUTE);
    removeValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_STEP);
  }, [
    setSelectedReleasesAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
  ]);

  const sequenceIds = useMemo(
    () => selectedReleases.map((i) => i.id),
    [selectedReleases],
  );

  const redirectToReleases = useCallback(() => {
    const redirectParams = readValue(
      LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.REDIRECT_ROUTE,
      "",
    ) as string | undefined;
    navigate(generatePath(`${routes.deliveries}${redirectParams}`));
  }, [navigate]);

  const navigateToNextSequence = useCallback(
    (
      {
        navigateToReleases,
        navigateToReleaseId,
      }: {
        navigateToReleases?: boolean;
        navigateToReleaseId?: string;
      } = {
        navigateToReleases: false,
        navigateToReleaseId: undefined,
      },
    ) => {
      if (sequenceActive) {
        if (sequenceIds.length === step + 1) {
          redirectToReleases();
        } else {
          setStepAndUpdateLocalStorage(step + 1);
          redirectToRelease(step + 1);
        }
      } else if (navigateToReleases) {
        redirectToReleases();
      } else if (navigateToReleaseId) {
        navigate(
          generatePath(routes.delivery, { deliveryId: navigateToReleaseId }),
        );
      }
    },
    [
      sequenceActive,
      sequenceIds.length,
      step,
      redirectToReleases,
      setStepAndUpdateLocalStorage,
      redirectToRelease,
      navigate,
    ],
  );

  const selectAll = useCallback(() => {
    if (totalCount > pageSize) {
      setSelectedReleases([]);
      setAllReleasesSelected(true);
    } else {
      setSelectedReleases(deliveries);
    }
  }, [deliveries, totalCount, pageSize, setAllReleasesSelected]);

  const clearAll = useCallback(() => {
    setAllReleasesSelected(false);
    setSelectedReleasesAndUpdateLocalStorage([]);
  }, [setAllReleasesSelected, setSelectedReleasesAndUpdateLocalStorage]);

  return (
    <ProviderContext.Provider
      value={{
        step,
        setStep: setStepAndUpdateLocalStorage,
        sequenceIds,
        selectedReleases,
        setSelectedReleases,
        sequenceActive,
        startSequence,
        stopSequence,
        navigateToNextSequence,
        redirectToReleases,
        selectAll,
        clearAll,
        allReleasesSelected,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseSequence = (): ProviderContextType =>
  useContext(ProviderContext);

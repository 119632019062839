import { If } from "@/common/components/if/If";
import {
  InfoHeader,
  InfoHeaderType,
} from "@/common/components/info-header/InfoHeader";
import { FulfillmentHeaderItem } from "@/common/components/info-header/components/FulfillmentHeaderItem";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { UserInfoHeaderItem } from "@/common/components/info-header/components/UserInfoHeaderItem";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { SupplierInsuranceInfo } from "@/common/components/supplier-insurance-info/SupplierInsuranceInfo";
import { TypeIndicator } from "@/common/components/type-indicator/TypeIndicator";
import { DateView } from "@/common/utils/dates/DateView";
import { useComplianceGroups } from "@/contractor/pages/admin/orders/pages/compliance-groups/hooks/useComplianceGroups";
import { ReleasePONumber } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleasePONumber";
import { WarehouseOutlined } from "@mui/icons-material";
import { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrderTypesConfig } from "../../../../../../common/hooks/order-types-config/useOrderTypesConfig";
import { ExternalPOModal } from "../../../common/external-po/ExternalPOModal";
import { useProjectNavigate } from "../../../projects/hooks/useProjectNavigate";
import { useRelease } from "../../providers/ReleaseProvider";
import { ReleasePageType } from "../../types/ReleasePageType";
import { isRestockRelease } from "../../utils/isRestockRelease";
import { DeliveryHeaderInformation } from "./DeliveryHeaderInformation";
import { ReleaseDetailsHeaderFooter } from "./ReleaseDetailsHeaderFooter";
import { ReleaseDetailsHeaderTitle } from "./ReleaseDetailsHeaderTitle";
import { ReleaseGroups } from "./ReleaseGroups";
import { ReleaseWatchers } from "./ReleaseWatchers";

type Props = {
  headerType?: ReleasePageType;
};

const Group = tw.div`
  grid gap-4
`;

const DeliveryInfoContainer = tw.div`flex items-center h-full`;
const DeliveryInfo = tw.div`flex flex-col`;

export const ReleaseDetailsHeader: FC<Props> = ({
  headerType = ReleasePageType.Default,
}) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { orderTypeConfig } = useOrderTypesConfig({ release });
  const [externalPOModalOpened, setExternalPOModalOpened] = useState(false);
  const { getProjectPath } = useProjectNavigate();
  const { formatComplianceGroup } = useComplianceGroups();

  if (!release) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "RELEASE" })}</title>
      </Helmet>
      <InfoHeader
        headerType={
          headerType === ReleasePageType.Default
            ? InfoHeaderType.Default
            : InfoHeaderType.Grayed
        }
        header={
          <If isTrue={headerType === ReleasePageType.Confirm}>
            <ReleaseDetailsHeaderTitle />
          </If>
        }
        footer={
          <If isTrue={headerType === ReleasePageType.Confirm}>
            <ReleaseDetailsHeaderFooter />
          </If>
        }
      >
        <OrgDetailsHeaderItem
          title={<FormattedMessage id="SUPPLIER" />}
          name={
            release.sellerOrgLocation?.org.name || release.sourceWarehouse?.name
          }
          logoImageUrl={
            !release.sourceWarehouse
              ? release.sellerOrgLocation?.org.photoUrl
              : undefined
          }
          logoIcon={
            release.sourceWarehouse ? (
              <WarehouseOutlined className="h-[50px] w-[50px]" />
            ) : undefined
          }
          address={
            release.sourceWarehouse?.address ??
            release.sellerOrgLocation?.address
          }
          useDefaultTitlePosition={false}
          vendorContacts={release.vendorContacts}
          nameAdditionalComponent={
            <SupplierInsuranceInfo
              vendorId={release?.sellerOrgLocation?.id}
              className="ml-1"
            />
          }
          complianceGroup={formatComplianceGroup(release.complianceGroup)}
        />
        <OrgDetailsHeaderItem
          title={<FormattedMessage id="PROJECT" />}
          details={
            isRestockRelease(release)
              ? `(${intl.$t({ id: "INVENTORY_RESTOCK" })})`
              : intl.$t(
                  { id: "JOB_NUMBER_WITH_NO" },
                  { jobNumber: release.project?.jobNumber },
                )
          }
          navTo={getProjectPath(release.project)}
          name={release.project?.name}
          includeAddress={false}
          classes={{ details: "text-xs font-light" }}
        />
        <OrgDetailsHeaderItem
          title={<FormattedMessage id="DELIVER_TO" />}
          name={
            release.warehouse
              ? release.warehouse.name
              : intl.$t({ id: "PROJECT_LOCATION_WITH_ADDRESS" })
          }
          address={release.warehouse?.address ?? release.project?.address}
          siteContact={release.siteContact}
        />
        <Group className="gap-0">
          <InfoHeaderItem
            title={<FormattedMessage id="ORDER_TYPE" />}
            classes={{ container: "pb-0" }}
          >
            <TypeIndicator orderType={release.type} hideTooltip />
            {release.type?.name}
          </InfoHeaderItem>
          <PoNumberingSettingsCheck alternativeCondition={!!release.poNumber}>
            <InfoHeaderItem
              title={<FormattedMessage id="PO_HASH" />}
              classes={{ container: "pb-1" }}
            >
              {release.poLink?.syncedAt ? (
                <LinkLike
                  className="text-sm"
                  onClick={() => setExternalPOModalOpened(true)}
                >
                  {release.poNumber}
                </LinkLike>
              ) : (
                <ReleasePONumber
                  hideLabel
                  readonly
                  readonlyClassName="-mt-1.5 -ml-1"
                  release={release}
                  sourceSystem={release.poLink?.sourceSystem}
                />
              )}
            </InfoHeaderItem>
          </PoNumberingSettingsCheck>
        </Group>
        <If isTrue={headerType !== ReleasePageType.Confirm}>
          <InfoHeaderItem>
            <Group>
              {release.createdBy && (
                <UserInfoHeaderItem
                  title={<FormattedMessage id="REQUESTED_BY" />}
                  users={[release.createdBy]}
                  displayDateFn={() => release.createdAt}
                />
              )}
              {release.approvals.length > 0 && (
                <UserInfoHeaderItem
                  title={<FormattedMessage id="APPROVED_BY" />}
                  users={release.approvals.map((a) => a.approver)}
                  approvals={release.approvals}
                  displayDateFn={(user) =>
                    release.approvals?.find((a) => a.approver.id === user.id)
                      ?.createdAt
                  }
                />
              )}
              {!release.approvals.length && release.rejectedBy && (
                <UserInfoHeaderItem
                  title={<FormattedMessage id="REJECTED_BY" />}
                  users={[release.rejectedBy]}
                  displayDateFn={() => release.rejectedAt}
                />
              )}
            </Group>
          </InfoHeaderItem>
        </If>
        <If isTrue={headerType !== ReleasePageType.Confirm}>
          <Group className="gap-0">
            {release.poLink?.createdBy && (
              <UserInfoHeaderItem
                title={<FormattedMessage id="EXPORTED_BY" />}
                users={[release.poLink?.createdBy]}
                displayDateFn={() => release.poLink?.createdAt}
              />
            )}
            <InfoHeaderItem
              title={<FormattedMessage id="WATCHERS" />}
              classes={{ container: "pb-1" }}
            >
              <ReleaseWatchers triggerMutation={true} moreTagCount={1} />
            </InfoHeaderItem>
            <InfoHeaderItem
              title={<FormattedMessage id="ORDER_GROUPS" />}
              classes={{ container: "py-1" }}
            >
              <ReleaseGroups triggerMutation={true} />
            </InfoHeaderItem>
          </Group>
        </If>
        <If isTrue={headerType !== ReleasePageType.Confirm}>
          <DeliveryInfoContainer>
            <DeliveryInfo>
              <DeliveryHeaderInformation release={release} />
              <FulfillmentHeaderItem
                isContractor
                includeServices={release.includeServices}
                release={release}
                hideHeader
              />
            </DeliveryInfo>
          </DeliveryInfoContainer>
        </If>
        <If isTrue={headerType === ReleasePageType.Confirm}>
          <InfoHeaderItem
            title={orderTypeConfig.labels.datePickerReadonlyLabel}
          >
            <DateView
              date={release.time}
              noDateLabel={
                release.timeTBD
                  ? intl.$t({ id: "HOLD_FOR_RELEASE" })
                  : undefined
              }
            />
          </InfoHeaderItem>
        </If>
      </InfoHeader>
      <ExternalPOModal
        opened={externalPOModalOpened}
        goBack={() => setExternalPOModalOpened(false)}
        poNumber={release.poNumber}
        projectId={release.project?.id}
      />
    </>
  );
};

import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { InventoryItemPendingFlowFieldsFragment } from "@/generated/graphql";
import { TransactionHistoryItemDetails } from "../../../transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";

type Props = {
  item: InventoryItemPendingFlowFieldsFragment;
};

export const ScheduledFlowItemNotes = ({ item }: Props) => {
  return (
    <NotNullableRenderer value={item?.instructions?.text}>
      <TransactionHistoryItemDetails>
        {item?.instructions?.text}
      </TransactionHistoryItemDetails>
    </NotNullableRenderer>
  );
};

import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { If } from "../../../if/If";
import { EquipmentItemServiceCodeSelectors } from "../components/EquipmentItemServiceCodeSelectors";
import { useInvoiceDocumentOptions } from "../hooks/useInvoiceDocumentOptions";
import { InvoicePaymentMethod } from "../InvoicePaymentMethod";
import { InvoiceAccountingDate } from "./InvoiceAccountingDate";
import { InvoiceDiscount } from "./InvoiceDiscount";
import { InvoiceDiscountDate } from "./InvoiceDiscountDate";
import { Container, Row } from "./InvoiceDocumentOptions.styles";
import { InvoiceRetention } from "./InvoiceRetention";
import { InvoiceDocumentOptionsProps } from "./types/InvoiceDocumentOptions.types";

export const InvoiceDocumentOptions = ({
  invoice,
  readonly = false,
  rescanInvoice,
  bulkUpdate,
  containerProps = {
    anchorPosition: "left",
    useColumns: false,
  },
}: InvoiceDocumentOptionsProps) => {
  const { connectedSourceSystem } = useOrgSettings();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { updateInvoice } = useInvoiceVerification();
  const {
    handleChangePaymentMethod,
    loadingEquipmentItems,
    loadingServiceCodes,
    updating,
    serviceCodes,
    equipmentItems,
    paymentMethods,
    setUpdatesCount,
    hasAccountingDate,
    discountDate,
    invoiceIssueDate,
    discountLabel,
    discountValue,
    discountType,
    setDiscountDate,
    setDiscountValue,
    handleDiscountTypeChange,
    discountValueWithError,
    retentionType,
    retentionValue,
    retentionLabel,
    setRetentionValue,
    handleRetentionTypeChange,
    accountingDate,
    setAccountingDate,
  } = useInvoiceDocumentOptions({
    invoice,
    readonly,
    bulkUpdate,
    rescanInvoice,
  });

  return (
    <Container $containerProps={containerProps}>
      <EquipmentItemServiceCodeSelectors
        invoice={invoice}
        readonly={readonly}
        setUpdatesCount={setUpdatesCount}
        updateInvoice={updateInvoice}
        equipmentItems={equipmentItems}
        loadingEquipmentItems={loadingEquipmentItems}
        serviceCodes={serviceCodes}
        loadingServiceCodes={loadingServiceCodes}
      />
      <Row $containerProps={containerProps}>
        <InvoiceDiscountDate
          discountDate={discountDate}
          invoiceIssueDate={invoiceIssueDate}
          includeInputLabels
          readonly={readonly}
          setDiscountDate={setDiscountDate}
          setDiscountValue={setDiscountValue}
        />
      </Row>
      <Row $containerProps={containerProps}>
        <InvoiceDiscount
          discountDate={discountDate}
          discountLabel={discountLabel}
          discountType={discountType}
          discountValue={discountValue}
          readonly={readonly}
          setDiscountValue={setDiscountValue}
          handleDiscountTypeChange={handleDiscountTypeChange}
          discountValueWithError={discountValueWithError}
        />
      </Row>
      <If
        isTrue={
          paymentMethods.length &&
          hasFeatureInConnectedSourceSystem(
            IntegrationFeature.IncludeInvoicePaymentMethods,
          ) &&
          !!connectedSourceSystem
        }
      >
        <Row $containerProps={containerProps}>
          <InvoicePaymentMethod
            paymentMethodId={invoice?.paymentMethod?.id}
            setPaymentMethodId={handleChangePaymentMethod}
            readonly={readonly || updating}
            includeInputLabels
          />
        </Row>
      </If>
      <Row $containerProps={containerProps}>
        <InvoiceRetention
          readonly={readonly}
          retentionType={retentionType}
          retentionValue={retentionValue}
          setRetentionValue={setRetentionValue}
          handleRetentionTypeChange={handleRetentionTypeChange}
          retentionLabel={retentionLabel}
        />
      </Row>
      <If isTrue={hasAccountingDate}>
        <Row
          $containerProps={containerProps}
          className="border-b border-dashed border-gray-500"
        >
          <InvoiceAccountingDate
            accountingDate={accountingDate}
            setAccountingDate={setAccountingDate}
            includeInputLabels
          />
        </Row>
      </If>
    </Container>
  );
};

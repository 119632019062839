import { extPriceCalculation } from "../../../../../../common/hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "../../providers/ReleaseProvider";
import { filterByDescriptionAndInstructions } from "./filterByInstructions";
import { filterByMaterialName } from "./filterByMaterialName";
import { checkPriceOrQty } from "./helpers/checkPriceOrQty";

export const filterByMaterialNamePriceAndQty = (
  item: Pick<
    ExpandedReleaseItem,
    | "name"
    | "unitPrice"
    | "quantityDecimal"
    | "material"
    | "instructions"
    | "notes"
  >,
  searchText: string,
  locale?: string | undefined,
) =>
  filterByMaterialName(item, searchText) ||
  filterByDescriptionAndInstructions(
    {
      description: item.name,
      instructions: item.instructions,
      notes: item.notes,
    },
    searchText,
  ) ||
  checkPriceOrQty(item.unitPrice, searchText, locale) ||
  checkPriceOrQty(item.quantityDecimal, searchText, locale) ||
  checkPriceOrQty(
    extPriceCalculation(item.quantityDecimal, item.unitPrice),
    searchText,
    locale,
  );

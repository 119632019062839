import { If } from "@/common/components/if/If";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { NewRfqFormType } from "./NewRfqFormType";
import { RfqFormVendorContacts } from "./RfqFormVendorContacts";
import { UpdateRfqVendorsFormType } from "./UpdateRfqVendorsFormType";

const Delimiter = tw.div`border-t border-dashed border-gray-500`;

export const RfqFormVendorsContacts = () => {
  const { watch } = useFormContext<UpdateRfqVendorsFormType | NewRfqFormType>();

  const vendorIds = watch("vendorIds");

  return (
    <If isTrue={vendorIds?.length}>
      <Delimiter />
      <FormattedMessage id="SPECIFY_SUPPLIER_CONTACTS" />
      <If isTrue={vendorIds?.length}>
        {vendorIds?.map((vendorId) => (
          <RfqFormVendorContacts
            key={vendorId.sellerOrgLocationId}
            sellerOrgLocationId={vendorId.sellerOrgLocationId}
            contactIds={vendorId.contactIDs}
          />
        ))}
      </If>
    </If>
  );
};

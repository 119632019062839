import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { useProjectPredictedPoNumber } from "@/common/components/po-number/hooks/useProjectPredictedPoNumber";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import {
  SupplierType,
  SupplierVendor,
} from "@/common/components/supplier-picker/types/Supplier";
import { useSupplierOptions } from "@/common/components/supplier-picker/useSupplierOptions";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useIsWarehouseManager } from "@/common/components/warehouse-selector/hooks/useIsWarehouseManager";
import { WarehouseType } from "@/common/components/warehouse-selector/types/WarehouseOption";
import {
  PROJECT_ADDRESS_ID,
  useWarehouseOptions,
} from "@/common/components/warehouse-selector/useWarehouseOptions";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useUsers } from "@/common/hooks/useUsers";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useNoteDocument } from "@/contractor/pages/home/common/note-document/providers/NoteDocumentProvider";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import {
  OrderTypeSummaryFieldsFragment,
  PoNumberingMode,
  ProjectExtendedFieldsFragment,
  ProjectListOptionFieldsFragment,
  ReleaseNotificationsMode,
  ReleaseStatus,
  ServiceType,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useBuyoutsByProject } from "../components/new-delivery/BuyoutsByProjectProvider";
import { NewOrderCreationType } from "../components/new-delivery/new-delivery-form/types/NewOrderCreationType";
import { NewDeliveryFormType } from "../components/new-delivery/NewDeliveryFormType";
import { useDeliveries } from "../providers/DeliveriesProvider";

const ConfirmationContainer = tw.div`max-w-38`;

enum NewOrderType {
  CreateNew = "createNew",
  ImportQuote = "quote",
  ImportNote = "note",
}

export const useNewDeliveryForm = ({
  onClose,
  project,
  type,
}: {
  onClose: () => void;
  project?: ProjectExtendedFieldsFragment | null;
  type: NewOrderCreationType;
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { buyouts, setProjectIdFilter } = useBuyoutsByProject();
  const { findOrderTypeByLocationId } = useVendors();
  const { locations } = useUserLocations();
  const { createReservedRelease, createStandaloneRelease, creating } =
    useCreateRelease();
  const { projectCostCodes } = useProjectCostCodes();
  const { zones } = useProjectZones();
  const { viewer, poNumbering } = useUser();
  const { settings } = useOrgSettings();
  const [view, setView] = useState(NewOrderType.CreateNew);
  const { createQuoteDocumentWithAsset } = useQuoteDocument();
  const { createNoteDocumentWithAsset } = useNoteDocument();
  const { openDialog } = useDialog();
  const { refetch } = useDeliveries();
  const { findSupplierTypeBySupplierId, supplierOptions } = useSupplierOptions({
    includeWarehouses: settings?.inventory.enabled,
  });
  const { isWarehouseManager } = useIsWarehouseManager();

  const formMethods = useForm<NewDeliveryFormType>({
    defaultValues: {
      locationId: "",
      projectId: project?.id || "",
      buyoutId: "",
      orderTypeId: "",
      warehouseId: settings?.releases?.defaultWarehouse?.id ?? "",
      vendorId: "",
      willCall: false,
      vendorStocking: false,
      poNumber: "",
      reservePoNumber: false,
      reserveSequenceNumber: false,
      costCodeId: undefined,
      watcherIds: [],
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const { watch, setValue, getValues } = formMethods;

  const locationId = watch("locationId");
  const projectId = watch("projectId");
  const orderTypeId = watch("orderTypeId");
  const vendorId = watch("vendorId");
  const buyoutId = watch("buyoutId");
  const reservePoNumber = watch("reservePoNumber");
  const reserveSequenceNumber = watch("reserveSequenceNumber");
  const warehouseId = watch("warehouseId");

  useSetCurrentProjectId(projectId);

  const costCodeOptions = useMemo(
    () =>
      projectCostCodes.map((costCode) => ({
        value: costCode.id,
        label: costCode.formatted,
      })),
    [projectCostCodes],
  );

  const zoneOptions = useMemo(
    () =>
      zones.map((zone) => ({
        value: zone.id,
        label: zone.name,
      })),
    [zones],
  );

  const { predictedPoNumber, loading: loadingPredictedPoNumber } =
    useProjectPredictedPoNumber(projectId);

  const supplierType = useMemo(
    () => findSupplierTypeBySupplierId(vendorId),
    [findSupplierTypeBySupplierId, vendorId],
  );
  const filterProjects = useCallback(
    (project: ProjectListOptionFieldsFragment) => {
      if (!isWarehouseManager) {
        return !project.preferredInventoryInflows;
      }
      return type === NewOrderCreationType.Restock
        ? project.preferredInventoryInflows
        : supplierType === SupplierType.WAREHOUSE
          ? !project.preferredInventoryInflows
          : true;
    },
    [isWarehouseManager, supplierType, type],
  );
  const groupByProject = useCallback(
    (project: ProjectListOptionFieldsFragment) =>
      !project.preferredInventoryInflows
        ? intl.$t({ id: "JOB" })
        : intl.$t({ id: "INVENTORY_RESTOCK" }),
    [intl],
  );
  const { projects } = useProjectListOptions({
    filterResults: filterProjects,
    groupBy: groupByProject,
  });

  const selectedProject = useMemo(
    () => projects.find((project) => project.id === projectId),
    [projectId, projects],
  );

  const warehouseProjectAddress = useMemo(() => {
    const selectedProject = projects.find(
      (project) => project.id === projectId,
    );
    return type === NewOrderCreationType.Default &&
      !selectedProject?.preferredInventoryInflows &&
      !project?.preferredInventoryInflows
      ? project?.address || selectedProject?.address
      : undefined;
  }, [
    project?.address,
    project?.preferredInventoryInflows,
    projectId,
    projects,
    type,
  ]);
  const { warehouseOptions } = useWarehouseOptions({
    projectAddress: warehouseProjectAddress,
  });
  const selectedWarehouse = useMemo(
    () => warehouseOptions.find((warehouse) => warehouse.id === warehouseId),
    [warehouseId, warehouseOptions],
  );

  const filterOrderTypes = useCallback(
    (orderType: OrderTypeSummaryFieldsFragment) => {
      if (!isWarehouseManager) {
        return !orderType.trackInventory;
      }
      if (
        selectedProject?.preferredInventoryInflows ||
        (project?.preferredInventoryInflows &&
          selectedWarehouse?.type === WarehouseType.Warehouse)
      ) {
        return !orderType.trackInventory;
      }
      return type === NewOrderCreationType.Restock
        ? orderType.trackInventory
        : true;
    },
    [
      isWarehouseManager,
      project?.preferredInventoryInflows,
      selectedProject?.preferredInventoryInflows,
      selectedWarehouse?.type,
      type,
    ],
  );

  const { users } = useUsers({
    requireLocation: true,
    locationId: selectedProject?.location.id,
  });

  const { getOrderType, defaultOrderType, orderTypes } = useOrderTypeOptions({
    filterResults: filterOrderTypes,
  });
  const transactionKind = useMemo(
    () => getOrderType(orderTypeId)?.transactionKind,
    [getOrderType, orderTypeId],
  );

  const { orderTypeConfig } = useOrderTypesConfig({
    release:
      orderTypeId && transactionKind
        ? {
            type: {
              transactionKind,
            },
          }
        : undefined,
  });

  const isInventoryRestockOrder = useMemo(
    () => type === NewOrderCreationType.Restock,
    [type],
  );

  const isToJobOrder = useMemo(
    () =>
      (!!vendorId && supplierType === SupplierType.VENDOR) ||
      isInventoryRestockOrder,
    [supplierType, isInventoryRestockOrder, vendorId],
  );

  useEffect(() => {
    setProjectIdFilter(projectId);
  }, [projectId, setProjectIdFilter]);

  useEffect(() => {
    if (projectId && !getValues("warehouseId")) {
      setValue("warehouseId", PROJECT_ADDRESS_ID);
    }
  }, [projectId, setValue, getValues]);

  useEffect(() => {
    if (!selectedProject && locations.length) {
      setValue("locationId", locations[0].id);
      return;
    }
    setValue("locationId", selectedProject?.location.id || "");
  }, [locations, selectedProject, setValue]);

  useEffect(() => {
    if (!project && projects?.length === 1 && !getValues("projectId")) {
      setValue("projectId", projects[0].id);
    }
  }, [project, projects, setValue, getValues]);

  useEffect(() => {
    if (isInventoryRestockOrder && orderTypes.length === 1) {
      setValue("orderTypeId", orderTypes[0].id);
    }
  }, [isInventoryRestockOrder, orderTypes, setValue]);

  useEffect(() => {
    if (defaultOrderType && getOrderType(defaultOrderType.id)) {
      setValue("orderTypeId", defaultOrderType.id);
    }
  }, [defaultOrderType, getOrderType, setValue]);

  useEffect(() => {
    if (buyoutId) {
      const buyout = buyouts.find((b) => b.id === buyoutId);
      setValue("orderTypeId", buyout?.releaseType?.id || "");
    }
  }, [buyoutId, buyouts, setValue]);

  useEffect(() => {
    if (vendorId) {
      const orderTypeId = findOrderTypeByLocationId(vendorId);
      if (orderTypeId && getOrderType(orderTypeId)) {
        setValue("orderTypeId", orderTypeId);
      }
    }
  }, [findOrderTypeByLocationId, getOrderType, setValue, vendorId]);

  useEffect(() => {
    if (isInventoryRestockOrder && orderTypeConfig?.features.includeWillCall) {
      if (warehouseOptions?.length === 1) {
        setValue("warehouseId", warehouseOptions[0].id);
      }
    }
  }, [
    supplierOptions,
    projects,
    warehouseOptions,
    buyouts,
    setValue,
    isInventoryRestockOrder,
    orderTypeConfig?.features.includeWillCall,
    orderTypes,
  ]);

  const hasBuyout = useMemo(
    () =>
      !!vendorId && buyouts.some((b) => b.sellerOrgLocation?.id === vendorId),
    [vendorId, buyouts],
  );

  useEffect(() => {
    if (projectId && users) {
      if (
        settings?.releases?.notifications ===
        ReleaseNotificationsMode.SubmitterOnly
      ) {
        if (viewer?.id) {
          setValue("watcherIds", [viewer?.id]);
        }
        return;
      }
      const project = projects.find((project) => project.id === projectId);
      if (project) {
        setValue(
          "watcherIds",
          project.team
            .map((teamMember) => teamMember.id)
            .filter((teamMember) =>
              users.some((user) => user.id === teamMember),
            ),
        );
      }
    }
  }, [
    projectId,
    projects,
    setValue,
    settings?.releases?.notifications,
    viewer?.id,
    users,
  ]);

  const onSubmit = useCallback(
    async (values: NewDeliveryFormType) => {
      const {
        projectId,
        buyoutId,
        vendorId,
        warehouseId,
        orderTypeId,
        poNumber,
        reservePoNumber,
        reserveSequenceNumber,
        costCodeId,
        zoneId,
        watcherIds,
      } = values;
      const newPoNumber = !isToJobOrder
        ? undefined
        : reservePoNumber
          ? poNumbering === PoNumberingMode.Always
            ? poNumber || predictedPoNumber
            : poNumber
          : undefined;
      if (hasBuyout && buyoutId) {
        const result = await createReservedRelease({
          buyoutId,
          typeId: orderTypeId || undefined,
          poNumber: newPoNumber,
          costCodeId,
          watcherIds,
          warehouseId:
            warehouseId === PROJECT_ADDRESS_ID ? undefined : warehouseId,
        });
        if (result) {
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: result.id,
            }),
          );
        }
      } else {
        const includeServices = [];
        if (!values.willCall) {
          includeServices.push({ type: ServiceType.Delivery });
        }
        if (!values.willCall && values.vendorStocking) {
          includeServices.push({ type: ServiceType.Stocking });
        }
        const result = await createStandaloneRelease({
          projectId,
          sellerOrgLocationId:
            supplierType === SupplierType.VENDOR ? vendorId : undefined,
          warehouseId:
            warehouseId === PROJECT_ADDRESS_ID ||
            supplierType === SupplierType.WAREHOUSE
              ? undefined
              : warehouseId,
          includeServices,
          typeId: orderTypeId || undefined,
          poNumber: newPoNumber,
          costCodeId,
          zoneId,
          status:
            reserveSequenceNumber || reservePoNumber
              ? ReleaseStatus.Reserved
              : undefined,
          watcherIds,
          sourceWarehouseId:
            supplierType === SupplierType.WAREHOUSE ? vendorId : undefined,
        });
        if (result) {
          if (reservePoNumber && result.poNumber) {
            onClose();
            refetch();
            openDialog({
              content: (
                <SuccessModal
                  message={
                    <ConfirmationContainer>
                      {intl.$t(
                        { id: "RESERVE_PO_NUMBER_CONFIRMATION" },
                        { poNumber: result.poNumber },
                      )}
                    </ConfirmationContainer>
                  }
                />
              ),
              closingTimer: DIALOG_AUTO_CLOSE_TIMER,
            });
            return;
          }
          if (reserveSequenceNumber && result.sequenceNumber) {
            onClose();
            refetch();
            openDialog({
              content: (
                <SuccessModal
                  message={
                    <ConfirmationContainer>
                      {intl.$t(
                        { id: "ORDER_NUMBER_WAS_RESERVED" },
                        { sequenceNumber: result.sequenceNumber },
                      )}
                    </ConfirmationContainer>
                  }
                />
              ),
              closingTimer: DIALOG_AUTO_CLOSE_TIMER,
            });
            return;
          }
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: result.id,
            }),
          );
        }
      }
    },
    [
      isToJobOrder,
      poNumbering,
      predictedPoNumber,
      hasBuyout,
      createReservedRelease,
      navigate,
      createStandaloneRelease,
      supplierType,
      onClose,
      refetch,
      openDialog,
      intl,
    ],
  );

  const requiresApproval = useMemo(() => {
    return (
      settings?.releaseApproval.enabled &&
      !settings?.releaseApproval.exemptRoles.some((role) =>
        viewer?.locationRoles.find(
          (lRole) => lRole.role === role && lRole.orgLocID === locationId,
        ),
      )
    );
  }, [settings, locationId, viewer?.locationRoles]);

  const onTabChange = useCallback(
    (viewState: string) => {
      setView(viewState as NewOrderType);
    },
    [setView],
  );

  const createFromQuote = useCallback(
    async (values: NewDeliveryFormType) => {
      const result = await createQuoteDocumentWithAsset(
        values.locationId || project?.location.id || "",
      );
      if (result) {
        navigate({
          pathname: generatePath(routes.deliveryFromQuote, {
            quoteDocumentId: result?.id,
          }),
          search: `?projectId=${projectId}`,
        });
      }
    },
    [createQuoteDocumentWithAsset, navigate, project?.location.id, projectId],
  );

  const createFromNote = useCallback(
    async (values: NewDeliveryFormType) => {
      const result = await createNoteDocumentWithAsset(
        values.locationId || project?.location.id || "",
      );

      if (result) {
        navigate({
          pathname: generatePath(routes.deliveryFromNote, {
            noteDocumentId: result?.id,
          }),
          search: `?projectId=${projectId}`,
        });
      }
    },
    [createNoteDocumentWithAsset, navigate, project?.location.id, projectId],
  );

  const saveLabel = useMemo(() => {
    const id =
      reservePoNumber || reserveSequenceNumber
        ? poNumbering !== PoNumberingMode.Never
          ? "RESERVE_PO_NUMBER"
          : "RESERVE_ORDER"
        : view === NewOrderType.CreateNew
          ? "CREATE_DELIVERY"
          : "IMPORT";
    return intl.$t({ id });
  }, [intl, poNumbering, reservePoNumber, reserveSequenceNumber, view]);

  const selectedLocation = useMemo(
    () => locations.find((location) => location.id === locationId),
    [locationId, locations],
  );

  const isVendorRequired = useMemo(() => {
    return !reservePoNumber && !reserveSequenceNumber && !requiresApproval;
  }, [requiresApproval, reservePoNumber, reserveSequenceNumber]);

  const selectedSourceWarehouse = useMemo(
    () =>
      warehouseOptions.find(
        (warehouse) => !isToJobOrder && warehouse.id === vendorId,
      ),
    [warehouseOptions, isToJobOrder, vendorId],
  );

  const selectedVendor = useMemo(
    () =>
      vendorId && supplierType === SupplierType.VENDOR
        ? supplierOptions.find(
            (supplier) =>
              (supplier as SupplierVendor)?.sellerOrgLocation?.id === vendorId,
          )
        : undefined,
    [vendorId, supplierType, supplierOptions],
  );

  return {
    selectedLocation,
    saveLabel,
    createFromNote,
    createFromQuote,
    onTabChange,
    requiresApproval,
    creating,
    onSubmit,
    costCodeOptions,
    zoneOptions,
    orderTypeConfig,
    loadingPredictedPoNumber,
    warehouseOptions,
    formMethods,
    predictedPoNumber,
    hasBuyout,
    view,
    users,
    isVendorRequired,
    isToJobOrder,
    selectedWarehouse,
    selectedSourceWarehouse,
    selectedVendor,
    warehouseProjectAddress,
    isInventoryRestockOrder,
    filterOrderTypes,
    filterProjects,
    projects,
  };
};

import { ApolloError } from "@apollo/client";
import { useCallback, useState } from "react";
import { useGlobalError } from "./useGlobalError";

const AbortError = "AbortError";

export const useAbortController = () => {
  const [abortController, setAbortController] = useState(new AbortController());
  const { setError } = useGlobalError();

  const dropAbortError = useCallback(
    (apolloError: ApolloError | undefined) => {
      if (
        apolloError?.networkError &&
        (apolloError.networkError as { name?: string })?.name === AbortError
      ) {
        setAbortController(new AbortController());
        return undefined;
      }
      return setError(apolloError);
    },
    [setError],
  );

  return { abortController, dropAbortError };
};

import { LuSettings2 } from "react-icons/lu";
import tw from "tailwind-styled-components";
import { EditColumnsDialog } from "./EditColumnsDianlog";
import { useEditColumns } from "./hooks/useEditColumns";

const Container = tw.div`
  absolute -top-4 -right-2 opacity-0 group-hover/table:opacity-100 transition-opacity duration-300 ease-in-out
`;

const ButtonContainer = tw.div`
  group/edit-column w-6 h-6 rounded z-[220] cursor-pointer p-0.5 border
  grid place-items-center border-gray-400 bg-blue-200 hover:bg-blue-500
`;

const EditIcon = tw(LuSettings2)`
  w-3 h-3 text-xs group-hover/edit-column:text-white transition-all duration-300 ease-in-out
`;

type EditColumnsButtonProps = {
  pageId?: string;
};

export const EditColumnsButton = ({ pageId }: EditColumnsButtonProps) => {
  const {
    dialogVisible,
    setDialogVisible,
    hasOptionalColumns,
    handleOpenDialog,
  } = useEditColumns(pageId);

  if (!hasOptionalColumns) {
    return null;
  }

  return (
    <Container>
      <ButtonContainer onClick={handleOpenDialog}>
        <EditIcon />
      </ButtonContainer>
      <EditColumnsDialog
        visible={dialogVisible}
        setVisible={setDialogVisible}
        pageId={pageId}
      />
    </Container>
  );
};

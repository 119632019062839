import { NumericalInput } from "@/common/components/numerical-input/NumericalInput";
import {
  PricePercentageSelectedType,
  PricePercentageSelector,
} from "@/common/components/price-percentage-selector/PricePercentageSelector";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { FormattedMessage } from "react-intl";
import { InvoiceDocumentOptionsProps } from "./types/InvoiceDocumentOptions.types";

type Props = Pick<InvoiceDocumentOptionsProps, "readonly"> & {
  discountDate: Date | null;
  discountValue: string;
  discountLabel: string | undefined;
  discountType: PricePercentageSelectedType;
  setDiscountValue: (value: string) => void;
  handleDiscountTypeChange: (
    type: PricePercentageSelectedType,
  ) => Promise<void>;
  discountValueWithError: (value: DecimalSafe) => boolean;
};

export const InvoiceDiscount = ({
  readonly,
  discountDate,
  discountValue,
  discountLabel,
  discountType,
  setDiscountValue,
  handleDiscountTypeChange,
  discountValueWithError,
}: Props) => {
  return (
    <Tooltip
      id="discountPercentage"
      element={
        <NumericalInput
          disabled={!discountDate}
          value={discountValue}
          onChange={(e) => setDiscountValue(e.target.value)}
          InputProps={{
            className: "h-9 bg-white text-sm pr-2 pl-12",
            readOnly: readonly,
            endAdornment: (
              <PricePercentageSelector
                value={discountType}
                onChange={handleDiscountTypeChange}
                readOnly={readonly || !discountDate}
              />
            ),
          }}
          fixedDecimalScale
          decimals={2}
          suffix={
            discountType === PricePercentageSelectedType.Percentage ? "%" : "$"
          }
          className="w-full"
          inputProps={{ className: "text-center" }}
          error={discountValueWithError(new DecimalSafe(discountValue))}
          label={discountLabel}
          InputLabelProps={{ className: "bg-white px-1 text-gray-500" }}
        />
      }
      hideTooltip={!!discountDate}
    >
      <FormattedMessage id="INVOICE_DISCOUNT_DATE_REQUIRED" />
    </Tooltip>
  );
};

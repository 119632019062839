import { InvoiceDetails } from "@/common/components/invoices/invoice-details/InvoiceDetails";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  INVOICE_READONLY_STATUSES,
  SIGNATURE_PROJECT_ID,
} from "@/common/const";
import { useQueryParams } from "@/common/hooks/useQueryParams";

import { ResizableColumns } from "@/common/components/resizable-columns/ResizableColumns";
import { usePreviousValue } from "@/common/hooks/usePreviousValue";
import { AssetContext } from "@/generated/graphql";
import { useEffect, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useExpandableFooterStore } from "../../../common/components/footer/stores/useExpandableFooterStore";
import { If } from "../../../common/components/if/If";
import { Loader } from "../../../common/components/loader/Loader";
import { InvoiceBreadcrumbs } from "./components/invoice-breadcrumbs/InvoiceBreadcrumbs";
import { InvoiceUploadModal } from "./components/invoice-upload-modal/InvoiceUploadModal";
import { InvoiceMatchedOrder } from "./components/matched-order/InvoiceMatchedOrder";
import { InvoiceFooter } from "./components/matched-order/components/invoice-footer/InvoiceFooter";
import {
  DistributorInvoiceProvider,
  useDistributorInvoice,
} from "./providers/DistributorInvoiceProvider";
import {
  UploadInvoiceModalProvider,
  useUploadInvoiceModal,
} from "./providers/UploadInvoiceProvider";

const Container = tw.div`relative mt-5`;
const PADDING_BOTTOM = 15;

const DistributorInvoiceWithProvider = () => {
  const { invoice, loading } = useDistributorInvoice();
  const previousInvoice = usePreviousValue(invoice);
  const { openUploadInvoiceModal } = useUploadInvoiceModal();
  const { height, expanded } = useExpandableFooterStore();

  useEffect(() => {
    if (
      invoice?.issues.filter((i) => !i.respondedAt).length === 0 &&
      previousInvoice?.issues.filter((i) => !i.respondedAt).length
    ) {
      openUploadInvoiceModal();
    }
  }, [invoice, previousInvoice, openUploadInvoiceModal]);

  const hasFooter = useMemo(
    () => !!invoice && !INVOICE_READONLY_STATUSES.includes(invoice?.status),
    [invoice],
  );

  return (
    <Loader loading={loading}>
      <Container>
        <InvoiceBreadcrumbs />
        <ResizableColumns
          hasFooter={hasFooter}
          paddingBottom={expanded ? height + PADDING_BOTTOM : PADDING_BOTTOM}
        >
          <InvoiceMatchedOrder />
          <InvoiceDetails
            invoice={invoice}
            displayInvoiceDetails={false}
            invoiceReadonly
            invoicePoNumberReadonly
          />
        </ResizableColumns>
        <If isTrue={hasFooter}>
          <InvoiceFooter />
        </If>
        <InvoiceUploadModal />
      </Container>
    </Loader>
  );
};

export const DistributorInvoice = () => {
  const { queryParams } = useQueryParams();
  const projectId = queryParams.get(SIGNATURE_PROJECT_ID);

  return (
    <UploadAssetProvider context={AssetContext.Invoice} projectId={projectId}>
      <DistributorInvoiceProvider>
        <UploadInvoiceModalProvider>
          <DistributorInvoiceWithProvider />
        </UploadInvoiceModalProvider>
      </DistributorInvoiceProvider>
    </UploadAssetProvider>
  );
};

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import {
  ColumnType,
  useColumnMapper,
} from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getPropertyDrillDown } from "@/common/utils/getPropertyDrillDown";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { Identity } from "@/types/Identity";
import { format } from "date-fns";
import { ColumnSettings } from "handsontable/settings";
import { isNumber } from "lodash";
import { useEffect, useState } from "react";
import { EMPTY_STATE_MIN_ROWS, MIN_ROWS } from "../constants/tableConstants";
import { rowIsEmpty } from "../utils/rowIsEmpty";
import { sanitizeValue } from "../utils/sanitizeValue";
import { useFormattedMaterialName } from "./useFormattedMaterialName";
import { useTableHelpers } from "./useTableHelpers";

type IdentityWithAdditional = Identity & {
  position?: number | null;
};

type UseTableDataParams<T> = {
  items: T[];
  rowNumber?: number;
  allColumns: ColumnType[];
  columnsConfig: ColumnSettings[];
  setSpreadsheetData: (data: Record<string, string>[]) => void;
};

export const useTableData = <T extends IdentityWithAdditional>({
  items,
  rowNumber,
  allColumns,
  columnsConfig,
  setSpreadsheetData,
}: UseTableDataParams<T>) => {
  const [data, setData] = useState<Record<string, string>[] | undefined>();
  const [loading, setLoading] = useState(true);
  const { formatCostCode } = useProjectCostCodes();
  const { formatCostType } = useCostTypes();
  const { getFirstTag, getPhaseCode } = useTableHelpers();
  const { rowHasChanges } = useColumnMapper();
  const getFormattedMaterialName = useFormattedMaterialName();

  useEffect(() => {
    if (data) {
      const newData = data.map((row) => ({
        ...row,
        [COLUMN_TYPE.CostCode]: formatCostCode(row[COLUMN_TYPE.CostCode]),
        [COLUMN_TYPE.CostType]: formatCostType(row[COLUMN_TYPE.CostType]),
      }));
      setData(newData);
      setSpreadsheetData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatCostCode, formatCostType, setSpreadsheetData]);

  useEffect(() => {
    let newData = [...items]
      .sort((a, b) =>
        isNumber(a.position) && isNumber(b.position) && a.position < b.position
          ? -1
          : 1,
      )
      .map((item) => {
        return columnsConfig.reduce(
          (acc: Record<string, string>, col) => {
            const propValue =
              typeof col.columnId === "string"
                ? getPropertyDrillDown(item, col.columnId)
                : getPropertyDrillDown(item, col.columnId?.(item.id));
            if (col.columnType === COLUMN_TYPE.Material) {
              const materialName = sanitizeValue(propValue);
              acc[col.columnType] =
                getFormattedMaterialName(materialName) ?? materialName;
            } else if (col.columnType === COLUMN_TYPE.CostCode) {
              acc[col.columnType] = formatCostCode(sanitizeValue(propValue));
            } else if (col.columnType === COLUMN_TYPE.CostType) {
              acc[col.columnType] = formatCostType(sanitizeValue(propValue));
            } else if (col.columnType === COLUMN_TYPE.Tag) {
              acc[col.columnType] =
                typeof propValue === "string"
                  ? propValue
                  : getFirstTag(propValue);
            } else if (col.columnType === COLUMN_TYPE.PhaseCode) {
              acc[col.columnType] = getPhaseCode(propValue);
            } else {
              acc[col.columnType] = sanitizeValue(propValue);
            }
            if (col.type === "date" && acc[col.columnType]) {
              acc[col.columnType] = format(
                new Date(acc[col.columnType]),
                "MM/dd/yyyy",
              );
            }
            if (typeof acc[col.columnType] === "string") {
              const skipSpaceReplacement = col.skipSpaceReplacement;
              acc[col.columnType] = skipSpaceReplacement
                ? acc[col.columnType].trim()
                : acc[col.columnType].replace(/\s+/g, " ").trim();
            }
            return acc;
          },
          {
            id: item.id,
          },
        );
      });

    Array(
      rowNumber
        ? Math.max(rowNumber - items.length, EMPTY_STATE_MIN_ROWS)
        : Math.max(MIN_ROWS - items.length, EMPTY_STATE_MIN_ROWS),
    )
      .fill("")
      .forEach(() => {
        newData = newData.concat(
          allColumns.reduce((acc: Record<string, string>, col) => {
            acc[col.columnType] = "";
            return acc;
          }, {}),
        );
      });

    setLoading(false);

    if (
      !data ||
      data.every(rowIsEmpty) ||
      newData.some((row) => rowHasChanges(row)) ||
      // update table data if it has ids that are not in the new data
      data.some(
        (row) => row.id && !newData.some((newRow) => newRow.id === row.id),
      ) ||
      // update table data if all incoming ids are different
      data.every((row) => newData.every((newRow) => newRow.id !== row.id))
    ) {
      setData(newData);
      setSpreadsheetData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return {
    data,
    setData,
    loading,
  };
};

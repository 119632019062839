import { Tooltip as MuiTooltip, TooltipClasses } from "@mui/material";
import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import tw from "tailwind-styled-components";

type ChildrenWrapperProps = {
  color: "primary" | "secondary" | "warning";
  $noWrap?: boolean;
};

const style = {
  primary: {
    popover: "bg-blue-500 text-white",
    arrow: "text-blue-500",
  },
  secondary: {
    popover: "bg-white text-black",
    arrow: "text-white",
  },
  warning: {
    popover: "bg-yellow-600 text-red-500",
    arrow: "text-yellow-600",
  },
};

const ChildrenWrapper = tw.div<ChildrenWrapperProps>`p-3 rounded-lg shadow-md text-base font-normal
${({ color }: ChildrenWrapperProps) => style[color].popover}
${({ $noWrap }) => $noWrap && "whitespace-nowrap w-[max-content]"}`;

const Container = tw.div`cursor:pointer`;

type Props = PropsWithChildren<{
  id?: string;
  element: ReactNode;
  color?: "primary" | "secondary" | "warning";
  position?: "top" | "bottom" | "left" | "right";
  className?: string;
  classes?: Partial<TooltipClasses>;
  hideTooltip?: boolean;
  noWrap?: boolean;
}>;

export const Tooltip: FC<Props> = ({
  id,
  element,
  children,
  color = "primary",
  position: placement = "top",
  className,
  classes,
  hideTooltip = false,
  noWrap = false,
}) => {
  const [open, setOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleTooltipToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  const handleClickInside = useCallback((event: MouseEvent) => {
    if (
      tooltipRef.current &&
      tooltipRef.current.contains(event.target as Node)
    ) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickInside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickInside]);

  return (
    <MuiTooltip
      enterTouchDelay={0}
      id={id}
      disableFocusListener
      disableTouchListener
      classes={{
        ...classes,
        tooltip: `bg-transparent p-0 pr-4 text-center ${classes?.tooltip}`,
        arrow: style[color].arrow,
      }}
      arrow
      open={open}
      onClose={() => setOpen(false)}
      onClick={handleTooltipToggle}
      onMouseEnter={() => setOpen(true)}
      placement={placement}
      title={
        children && !hideTooltip ? (
          <ChildrenWrapper ref={tooltipRef} color={color} $noWrap={noWrap}>
            {children}
          </ChildrenWrapper>
        ) : null
      }
    >
      <Container className={className}>{element}</Container>
    </MuiTooltip>
  );
};

import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  UpdateComplianceGroupsInput,
  useUpdateComplianceGroupsMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

export const useUpdateComplianceGroups = () => {
  const { setError } = useGlobalError();
  const [updateComplianceGroupsMutation] = useUpdateComplianceGroupsMutation();
  const { updateStartupData } = useStartupDataStore(
    useShallow((state) => ({
      complianceGroups: state.complianceGroups,
      updateStartupData: state.updateStartupData,
    })),
  );

  const updateComplianceGroups = useCallback(
    async (input: UpdateComplianceGroupsInput) => {
      try {
        const { data, errors } = await updateComplianceGroupsMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        if (data?.updateComplianceGroups) {
          updateStartupData({
            complianceGroups: data.updateComplianceGroups.map((c) => ({
              ...c,
              description: c.description ?? "",
            })),
          });
        }
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [updateComplianceGroupsMutation, setError, updateStartupData],
  );

  return {
    updateComplianceGroups,
  };
};

import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  UnarchiveInvoicesMutationVariables,
  useUnarchiveInvoicesMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useInvoicesUnarchive = () => {
  const { setError } = useGlobalError();

  const [unarchiveInvoicesMutation, { loading }] =
    useUnarchiveInvoicesMutation();

  const unarchiveInvoices = useCallback(
    async (input: UnarchiveInvoicesMutationVariables) => {
      try {
        const { data, errors } = await unarchiveInvoicesMutation({
          variables: {
            invoiceIds: input.invoiceIds,
          },
        });
        setError(errors);
        return data?.unarchiveInvoices;
      } catch (errors) {
        setError(errors);
      }
    },
    [setError, unarchiveInvoicesMutation],
  );

  return { unarchiveInvoices, loading };
};

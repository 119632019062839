import { DECIMAL_MAX_FRACTION_DIGITS } from "@/common/const";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useIsRestockRelease } from "@/contractor/pages/home/release/hooks/useIsRestockRelease";
import Handsontable from "handsontable";
import { CellValue } from "handsontable/common";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { COLUMN_TYPE } from "../../enums/columnType";
import { useTableHelpers } from "../../hooks/useTableHelpers";
import { getPhysicalColumnIndex } from "../../utils/getPhysicalColumnIndex";
import { getPhysicalRowIndex } from "../../utils/getPhysicalRowIndex";
import { insertTableRows } from "../../utils/insertTableRows";
import { setTableCells } from "../../utils/setTableCells";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { useRenderHelpers } from "../helpers/useRenderHelpers";
import { applyClasses } from "../utils/applyClasses";
import { applyTooltip } from "../utils/applyTooltip";
import { isValueEmpty } from "../utils/isValueEmpty";
import { useBestVendorForItem } from "./useBestVendorForItem";

export const useOrderQuantityRenderer = () => {
  const intl = useIntl();
  const baseRenderer = useBaseRenderer();
  const { warehouses } = useWarehouses();
  const { addIconWithTooltip } = useRenderHelpers();
  const { getInventoryItem, tableHasColumn } = useTableHelpers();
  const getBestVendorForItem = useBestVendorForItem();
  const { isRestockRelease } = useIsRestockRelease();
  const { currentWarehouseId } = useInventoryItems();

  const splitQuantity = useCallback(
    (
      sourceRow: Record<string, string>,
      rowData: CellValue[],
      instance: Handsontable,
      row: number,
      remainingQuantity: number,
    ) => {
      const value = Number(
        rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)],
      );
      const currentRow: [number, number, string | number | null][] = [
        [
          row,
          getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity),
          remainingQuantity,
        ],
      ];
      setTableCells(currentRow, instance);
      const bestVendorPrice = getBestVendorForItem({
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        manufacturer:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Manufacturer)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
      });
      const newRow = {
        ...sourceRow,
        [COLUMN_TYPE.Supplier]: bestVendorPrice?.vendorName ?? "",
        [COLUMN_TYPE.UOM]:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
        [COLUMN_TYPE.PrefilledPrice]: bestVendorPrice?.price ?? "",
        [COLUMN_TYPE.Quantity]: String(value - remainingQuantity),
      };
      insertTableRows([newRow], instance, row + 1);
    },
    [getBestVendorForItem],
  );

  const hasSupplier = useMemo(
    () => tableHasColumn(COLUMN_TYPE.Supplier),
    [tableHasColumn],
  );

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      if (isValueEmpty(value)) {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
        return;
      }

      const formattedValue = intl.formatNumber(Number(value), {
        maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
      });
      baseRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      const rowData = instance?.getDataAtRow(row);
      const sourceRow = instance?.getSourceDataAtRow(
        getPhysicalRowIndex(instance, row),
      );

      const supplier =
        rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)];
      const warehouseId = warehouses.find(
        (warehouse) => warehouse.name === supplier,
      )?.id;
      const inventoryItem = getInventoryItem({
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
        supplier,
      });

      if (inventoryItem) {
        const remainingQuantity = getInventoryItemRemainingQuantity(
          inventoryItem,
          warehouseId ?? currentWarehouseId ?? undefined,
        );

        const isPartOfWarehouse = inventoryItem.state.some(
          (state) =>
            state.warehouse.id === warehouseId ||
            state.warehouse.id === currentWarehouseId,
        );

        if (remainingQuantity.gte(0) && isPartOfWarehouse) {
          const remainingQuantityEl = document.createElement("div");
          remainingQuantityEl.className =
            "text-gray-500 text-3xs select-none mb-1";
          remainingQuantityEl.innerText = intl.$t(
            { id: "QUANTITY_IN_STOCK" },
            { quantity: remainingQuantity.toNumber() },
          );
          td.appendChild(remainingQuantityEl);

          const moreThanRemainingQuantity = remainingQuantity.lessThan(value);
          if (moreThanRemainingQuantity && !isRestockRelease) {
            applyClasses(td, "bg-red-200");
            applyTooltip(
              remainingQuantityEl,
              intl.$t(
                {
                  id: "QUANTITY_EXCEEDS_STOCK_WITH_VALUE",
                },
                {
                  quantity: remainingQuantity.toNumber(),
                  br: "\n",
                },
              ),
            );
            if (hasSupplier) {
              addIconWithTooltip({
                element: td,
                icon: "fa-arrows-split-up-and-left",
                tooltipText: intl.$t(
                  { id: "ORDER_MISSING_QUANTITY_FROM_VENDOR" },
                  {
                    quantity: remainingQuantity.toNumber(),
                    br: "\n",
                  },
                ),
                iconClasses: "text-red-500 scale-y-[-1] cursor-pointer",
                onClick: () =>
                  splitQuantity(
                    sourceRow as Record<string, string>,
                    rowData,
                    instance,
                    row,
                    remainingQuantity.toNumber(),
                  ),
              });
            }
          }
        }
      }
    },
    [
      intl,
      baseRenderer,
      getInventoryItem,
      isRestockRelease,
      warehouses,
      addIconWithTooltip,
      splitQuantity,
      hasSupplier,
      currentWarehouseId,
    ],
  );

  return renderer;
};

import { gql } from "@apollo/client";

export const ASSET_FIELDS = gql`
  fragment AssetFields on Asset {
    id
    url
    type
    context
    pages {
      url
      thumbnailUrl
    }
    thumbnailUrl
    createdAt
    isPrivate
  }
`;

import { PDF_FONT, PDF_LEFT_SPACING } from "@/common/const";
import { ExpandedRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { DistributorReleaseFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import { IntlShape } from "react-intl";
import {
  formattedDate,
  SHORT_DATE_OPTION,
  TIME_OPTION,
} from "../dates/DateView";

export const releaseTimestamp = (
  doc: jsPDF,
  release: ExpandedRelease | DistributorReleaseFieldsFragment,
  intl: IntlShape,
  topSpacing: number,
) => {
  if (!release.updatedAt) {
    return topSpacing;
  }
  const date = formattedDate({
    date: new Date(release.updatedAt),
    options: { ...SHORT_DATE_OPTION, ...TIME_OPTION, second: "numeric" },
    hideTimeZone: true,
  });
  if (!date) {
    return topSpacing;
  }
  doc.setFont(PDF_FONT, "", "normal").setFontSize(8);
  doc.text(
    intl.$t({ id: "LAST_EDITED_AT" }, { time: date.replace(",", "") }),
    doc.internal.pageSize.getWidth() - PDF_LEFT_SPACING,
    topSpacing,
    {
      align: "right",
    },
  );
  return topSpacing + 7;
};

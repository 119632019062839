import { useCallback } from "react";
import { useIntl } from "react-intl";

export const usePartRenderers = () => {
  const intl = useIntl();

  const setPartNumberComponent = useCallback(
    (td: HTMLTableCellElement, namePart: string, partNumber: string) => {
      td.innerText = namePart;
      const partText = document.createElement("span");
      partText.className = "text-gray-600 font-light text-xs select-none";
      partText.innerText = `〈${partNumber}〉`;
      td.appendChild(partText);
    },
    [],
  );

  const setNewLabel = useCallback(
    (
      td: HTMLTableCellElement,
      autocompleteOptions: string[],
      value: string,
      isLumpSumItem: boolean,
    ) => {
      if (
        value &&
        !value?.match(/ ⟮(.*)⟯/) &&
        autocompleteOptions?.length &&
        !autocompleteOptions.includes(value.toString()) &&
        !isLumpSumItem
      ) {
        td.className = "htMiddle relative text-blue-800 bg-blue-100";
        const newSup = document.createElement("sup");
        newSup.className = "text-blue-500 font-semibold text-[8px] ml-1";
        newSup.innerText = intl.$t({ id: "NEW" });
        td.appendChild(newSup);
      }
    },
    [intl],
  );

  return { setPartNumberComponent, setNewLabel };
};

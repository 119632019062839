import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { appendTableRows } from "@/common/components/spreadsheet-table/utils/appendTableRows";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectMaps } from "@/contractor/pages/home/project/hooks/useProjectMaps";
import { useProjectTags } from "@/contractor/pages/home/project/hooks/useProjectTags";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { useCallback } from "react";
import { AddToBuyoutItem } from "../providers/BuyoutProjectItemsProvider";

export const useAddItemsToBuyoutSpreadsheet = () => {
  const { handsonInstance } = useColumnMapper();
  const { projectCostCodes, formatCostCode } = useProjectCostCodes();
  const { tags } = useProjectTags();
  const { project } = useProject();
  const { estimatedItemsMap } = useProjectMaps(project);
  const { getPreferredCostCode } = useTableHelpers();
  const transformInputData = useCallback(
    (itemsToAdd: AddToBuyoutItem[]) => {
      const items = itemsToAdd.map((item) => {
        const tag = tags.find((tag) => tag.id === item.tags?.[0]);

        const estimatedItem = item.estimatedItemIds.length
          ? estimatedItemsMap.get(item.estimatedItemIds[0])
          : undefined;

        const costCode = projectCostCodes.find(
          (costCode) =>
            costCode.id === (item.costCodeId ?? estimatedItem?.costCode?.id),
        );

        const selectedCostCode = getPreferredCostCode(costCode);

        return {
          [COLUMN_TYPE.Material]: item.description ?? "",
          [COLUMN_TYPE.Quantity]: item.quantityDecimal ?? "",
          [COLUMN_TYPE.PrefilledPrice]: estimatedItem?.unitPrice ?? "",

          [COLUMN_TYPE.CostCode]: formatCostCode(selectedCostCode),
          [COLUMN_TYPE.Tag]: tag?.name ?? "",
          [COLUMN_TYPE.Manufacturer]: estimatedItem?.manufacturer?.name ?? "",
          [COLUMN_TYPE.UOM]: estimatedItem?.uom.pluralDescription ?? "",
          [COLUMN_TYPE.Zone]: estimatedItem?.zone?.name ?? "",
          [COLUMN_TYPE.Notes]: estimatedItem?.notes ?? "",
        };
      });
      return items;
    },
    [
      estimatedItemsMap,
      formatCostCode,
      tags,
      getPreferredCostCode,
      projectCostCodes,
    ],
  );

  const addItemsToBuyoutSpreadsheet = useCallback(
    (itemsToAdd: AddToBuyoutItem[]) => {
      const items = transformInputData(itemsToAdd);
      appendTableRows(items, handsonInstance);
    },

    [handsonInstance, transformInputData],
  );

  return { addItemsToBuyoutSpreadsheet };
};

import { useIsWarehouseManager } from "@/common/components/warehouse-selector/hooks/useIsWarehouseManager";
import { isRetroactiveRelease } from "@/contractor/pages/home/releases/pages/deliveries/utils/isRetroactiveRelease";
import { ReleaseStatus } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useReleaseActions } from "../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { isWarehouseRelease } from "../../../utils/isWarehouseRelease";

export const useSubmitReleaseDialog = () => {
  const { requestedDate } = useReleaseActions();
  const { release } = useRelease();
  const { isWarehouseManager } = useIsWarehouseManager();
  const retroactiveRelease = useMemo(
    () => isRetroactiveRelease(requestedDate),
    [requestedDate],
  );
  const intl = useIntl();

  const schedule = useMemo(
    () =>
      isWarehouseManager &&
      isWarehouseRelease(release) &&
      release?.status === ReleaseStatus.AwaitingApproval,
    [isWarehouseManager, release],
  );

  const dialogTitle = useMemo(
    () =>
      schedule
        ? intl.$t({ id: "CONFIRM_WH_RELEASE_MESSAGE" })
        : retroactiveRelease
          ? intl.$t(
              { id: "SAVE_THE_ORDER_QUESTION" },
              { br: <br key="break" /> },
            )
          : intl.$t(
              { id: "SUBMIT_ORDER_CONFIRMATION" },
              { br: <br key="break" /> },
            ),
    [schedule, intl, retroactiveRelease],
  );

  const splitItems = useMemo(() => {
    return release?.items.reduce((acc: number, item) => {
      if (
        item.sellerOrgLocation?.id &&
        item.sellerOrgLocation?.id !== release.sellerOrgLocation?.id
      ) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [release]);

  const confirmButtonText = useMemo(
    () =>
      intl.$t({
        id: schedule
          ? "CONFIRM"
          : splitItems
            ? "SUBMIT_AND_PROCEED_TO_NEXT_ORDER"
            : "SUBMIT",
      }),
    [schedule, intl, splitItems],
  );
  return { dialogTitle, retroactiveRelease, confirmButtonText, schedule };
};

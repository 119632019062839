import { MESSAGES_SEGMENT, routes } from "@/config/routes";
import {
  ReleasePartialFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { generatePath, useLocation, useNavigate } from "react-router";

type ReleaseType = Pick<
  ReleasePartialFieldsFragment,
  "id" | "status" | "itemCount"
> & {
  quoteDocument?: { id: string } | null;
  noteDocument?: { id: string } | null;
};

export const useReleaseNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isMessagesPath = useMemo(
    () => location.pathname.includes(MESSAGES_SEGMENT),
    [location.pathname],
  );

  const getReleaseEditPath = (release: ReleaseType) => {
    let path;
    if (release.status === ReleaseStatus.Draft && release.quoteDocument) {
      path = isMessagesPath
        ? routes.editDeliveryFromQuoteMessages
        : routes.editDeliveryFromQuote;
    } else if (
      (release.status === ReleaseStatus.Draft ||
        release.status === ReleaseStatus.AwaitingApproval) &&
      release.noteDocument &&
      !release.itemCount
    ) {
      path = isMessagesPath
        ? routes.editDeliveryFromNoteMessages
        : routes.editDeliveryFromNote;
    } else {
      path = routes.specifyDeliveryDetails;
    }

    return generatePath(path, {
      deliveryId: release.id,
      noteDocumentId: release.noteDocument?.id,
      quoteDocumentId: release.quoteDocument?.id,
    });
  };

  const navigateToRelease = useCallback(
    (
      releaseId: string,
      opts: { edit: boolean } = {
        edit: true,
      },
    ) => {
      navigate(
        generatePath(
          opts?.edit ? routes.specifyDeliveryDetails : routes.delivery,
          {
            deliveryId: releaseId,
          },
        ),
      );
    },
    [navigate],
  );

  return { getReleaseEditPath, navigateToRelease };
};

import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ColumnItemType } from "../hooks/useColumnManagement";
import { ColumnItem } from "./ColumnItem";

const DropzoneContainer = tw.div`
  border-2 border-dashed rounded-lg p-4 min-h-[200px] transition-colors
`;

const DropzoneStyled = tw(DropzoneContainer)<{ $isDragActive: boolean }>`
  ${({ $isDragActive }) => ($isDragActive ? "border-blue-500 bg-blue-50" : "border-gray-300")}
`;

const AreaTitle = tw.h3`
  text-lg font-medium mb-4 ml-8
`;

const AreaContainer = tw.div`min-w-96`;

const EmptyMessage = tw.div`
  py-4 text-center text-gray-500
`;

type ColumnSectionProps = {
  columns: ColumnItemType[];
  isDragActive: boolean;
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: () => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragStart: (
    e: React.DragEvent<HTMLDivElement>,
    column: ColumnItemType,
  ) => void;
  moveColumn: (columnId: string) => void;
  canBeMoved: (column: ColumnItemType) => boolean;
  type: "enabled" | "disabled";
};

export const ColumnSection = ({
  columns,
  isDragActive,
  onDragOver,
  onDragLeave,
  onDrop,
  onDragStart,
  moveColumn,
  canBeMoved,
  type,
}: ColumnSectionProps) => {
  const isEnabled = useMemo(() => type === "enabled", [type]);

  return (
    <AreaContainer>
      <AreaTitle>
        <FormattedMessage
          id={isEnabled ? "ENABLED_COLUMNS" : "DISABLED_COLUMNS"}
        />
      </AreaTitle>
      <DropzoneStyled
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        $isDragActive={isDragActive}
      >
        {columns.map((column) => (
          <ColumnItem
            key={column.id}
            column={column}
            canBeMoved={canBeMoved}
            onDragStart={onDragStart}
            moveColumn={moveColumn}
            type={type}
          />
        ))}
        {columns.length === 0 && (
          <EmptyMessage>
            <FormattedMessage id="DROP_COLUMNS_HERE" />
          </EmptyMessage>
        )}
      </DropzoneStyled>
    </AreaContainer>
  );
};

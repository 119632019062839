import {
  TransactionFooterCell,
  TransactionFooterCellHeader,
  TransactionFooterCol,
} from "../../../../../TransactionHistoryModal.styles";

export const TransactionHistoryInflowsTotalLabel = () => {
  return (
    <TransactionFooterCol>
      <TransactionFooterCellHeader />
      <TransactionFooterCell />
      <TransactionFooterCell />
      <TransactionFooterCell />
      <TransactionFooterCell />
    </TransactionFooterCol>
  );
};

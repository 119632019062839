import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useInvoiceExtPrices } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoiceExtPrices";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { InvoiceStatus } from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";

export const useInvoiceItemQuantityInput = (item: ExpandedReleaseItem) => {
  const { updateInvoice, invoice, excludePendingInvoices } =
    useInvoiceVerification();
  const invoiceQty = useMemo(
    () => item.invoiceItems?.[0]?.quantity || "0",
    [item.invoiceItems],
  );
  const [quantity, setQuantity] = useState<string>(invoiceQty);
  const [invoiced, setInvoiced] = useState<string>(invoiceQty);

  useEffect(() => {
    setQuantity(invoiceQty);
    setInvoiced(invoiceQty);
  }, [invoiceQty]);

  const { invoicedItem } = useInvoiceExtPrices(item);

  const onSave = useCallback(
    async (quantity: string | null) => {
      if (quantity) {
        setInvoiced(quantity);
      }
      if (!invoicedItem) {
        return;
      }
      await updateInvoice(
        {
          id: invoice?.id || "",
          updatedInvoicedReleaseItems: [
            {
              id: invoicedItem.id,
              releaseItemId: item.id,
              quantity: quantity ?? undefined,
              quantitySoFar: invoicedItem.quantitySoFar || "0",
              unitPrice:
                invoicedItem.unitPrice ?? (isLumpSumItem(item) ? "1" : "0"),
            },
          ],
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
    },
    [invoicedItem, updateInvoice, invoice?.id, invoice?.release?.id, item],
  );

  const invoiceIsProcessed = useMemo(
    () =>
      invoice?.status === InvoiceStatus.Approved ||
      invoice?.status === InvoiceStatus.Paid,
    [invoice?.status],
  );

  const invoicedSoFar = useMemo(() => {
    return new DecimalSafe(invoicedItem?.quantitySoFar || "0")
      .plus(invoiceIsProcessed ? invoicedItem?.quantity || "0" : 0)
      .minus(
        excludePendingInvoices
          ? (invoicedItem?.unexportedQuantitySoFar ?? "0")
          : "0",
      );
  }, [
    excludePendingInvoices,
    invoiceIsProcessed,
    invoicedItem?.quantity,
    invoicedItem?.quantitySoFar,
    invoicedItem?.unexportedQuantitySoFar,
  ]);

  const inputClassName = useMemo(() => {
    const quantity = new DecimalSafe(item.quantityDecimal);
    if (
      quantity.lessThan(invoicedSoFar) ||
      new DecimalSafe(invoiced)
        .plus(invoicedItem?.quantitySoFar ?? 0)
        .minus(
          excludePendingInvoices
            ? (invoicedItem?.unexportedQuantitySoFar ?? "0")
            : "0",
        )
        .greaterThan(item.quantityDecimal)
    ) {
      return "text-red-500 bg-white relative";
    }
    return "text-blue-800 bg-white relative";
  }, [
    item.quantityDecimal,
    invoicedSoFar,
    invoiced,
    invoicedItem?.quantitySoFar,
    invoicedItem?.unexportedQuantitySoFar,
    excludePendingInvoices,
  ]);

  const isFullyInvoiced = useMemo(() => {
    return (
      Number(invoiced) > 0 &&
      new DecimalSafe(item.quantityDecimal)
        .sub(invoicedItem?.quantitySoFar || 0)
        .add(
          excludePendingInvoices
            ? (invoicedItem?.unexportedQuantitySoFar ?? "0")
            : "0",
        )
        .equals(Number(invoiced))
    );
  }, [
    excludePendingInvoices,
    invoiced,
    invoicedItem?.quantitySoFar,
    invoicedItem?.unexportedQuantitySoFar,
    item.quantityDecimal,
  ]);

  const remainingInvoiceQuantity = useMemo(() => {
    return new DecimalSafe(item.quantityDecimal)
      .minus(new DecimalSafe(invoicedItem?.quantitySoFar || "0"))
      .plus(
        excludePendingInvoices
          ? (invoicedItem?.unexportedQuantitySoFar ?? "0")
          : "0",
      )
      .minus(invoiced)
      .toNumber();
  }, [
    excludePendingInvoices,
    invoiced,
    invoicedItem?.quantitySoFar,
    invoicedItem?.unexportedQuantitySoFar,
    item.quantityDecimal,
  ]);

  const invoicedByOtherInvoices = useMemo(
    () =>
      new DecimalSafe(invoicedItem?.quantitySoFar || "0")
        .minus(
          excludePendingInvoices
            ? (invoicedItem?.unexportedQuantitySoFar ?? "0")
            : "0",
        )
        .toString(),
    [
      excludePendingInvoices,
      invoicedItem?.quantitySoFar,
      invoicedItem?.unexportedQuantitySoFar,
    ],
  );

  return {
    quantity,
    setQuantity,
    onSave,
    inputClassName,
    isFullyInvoiced,
    remainingInvoiceQuantity,
    invoicedByOtherInvoices,
  };
};

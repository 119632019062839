import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { If } from "../../../if/If";
import { OrgLogo } from "../../../org-logo/OrgLogo";
import { Supplier, SupplierType } from "../../types/Supplier";
import { vendorLabelFormatter } from "../../utils/vendorLabelFormatter";

const Item = tw.div`w-full grid grid-flow-col gap-x-2 justify-start items-center py-[6px]
${({ $grouped }: { $grouped?: boolean }) => ($grouped ? "pl-2" : "")}
`;
const Separator = tw.span`mx-1`;
const VendorOrg = tw.span``;
const Name = tw.span`ml-4`;
const VendorContacts = tw.span`text-xs relative bottom-[1px]`;

export const SupplierPickerCustomRender = (
  item: Supplier,
  opts: {
    filter?: (
      contact: OrgPreferredVendorsFieldsFragment["contacts"][0],
    ) => boolean;
    shouldShowVendorCode?: boolean;
    grouped?: boolean;
  } = {
    grouped: true,
  },
) => {
  const vendor = item as OrgPreferredVendorsFieldsFragment;
  const sellerOrgLocation = vendor?.sellerOrgLocation;
  const contacts = vendor?.contacts;
  const vendorCode = vendor?.externalCode;
  const vendorName = (item as { name: string })?.name;

  return (
    <Item $grouped={opts.grouped}>
      {sellerOrgLocation ? (
        <>
          <If isTrue={item.type === SupplierType.VENDOR}>
            <OrgLogo
              className="ml-2"
              logoImageUrl={sellerOrgLocation?.org?.photoUrl}
              name={
                sellerOrgLocation?.org.id ? sellerOrgLocation?.org?.name : ""
              }
              width={24}
            />
          </If>
          <div>
            <VendorOrg>
              {vendorLabelFormatter(sellerOrgLocation, [], {
                vendorCode: opts?.shouldShowVendorCode ? vendorCode : null,
              })}
            </VendorOrg>
            {opts?.filter && contacts?.length > 0 ? (
              <>
                <Separator>-</Separator>
                <VendorContacts>
                  {contacts
                    .filter(opts.filter)
                    .map((c) => c.name)
                    .join(", ")}
                </VendorContacts>
              </>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <If isTrue={item.type === SupplierType.VENDOR}>
            <OrgLogo name={" "} width={24} />
          </If>
          <Name>{vendorName ?? null}</Name>
        </>
      )}
    </Item>
  );
};

import { GraphDataType } from "@/common/components/charting/enums/GraphDataType";
import { camelToTitle } from "@/contractor/pages/admin/gmv-report/GMVReport";
import {
  DataResponse,
  Granularity,
  useGMVYearlyData,
} from "@/contractor/pages/admin/gmv-report/hooks/useGMVYearlyData";
import {
  addMonths,
  addQuarters,
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";

const COLORS = [
  "#F40018",
  "#FF4382",
  "#C260E7",
  "#715EFA",
  "#0046E2",
  "#009BD9",
  "#0084B5",
  "#00BDD2",
  "#00BC9D",
  "#00CB76",
  "#FFD63D",
  "#FF7628",
  "#F44F1E",
  "#B5BDC2",
];

const COUNT_LABEL = "count";
const VALUE_ALL_CUSTOMERS_DATA = "all customers";
const VALUE_AGGREGATED_ONLY = "aggregated only";
const VALUE_SHOW_ALL = "show all";
const DEFAULT_PERIOD = "12";

type Graph = {
  title: string;
  labels: string[];
  data: number[];
  format: GraphDataType;
};

type HeaderOption = {
  name: string;
  value: string;
};

export const periods = [
  {
    name: "Quarterly",
    value: "8",
    granularity: Granularity.Quarterly,
  },
  {
    name: "12 Months",
    value: "12",
    granularity: Granularity.Monthly,
  },
  {
    name: "24 Months",
    value: "24",
    granularity: Granularity.Monthly,
  },
  {
    name: "36 Months",
    value: "36",
    granularity: Granularity.Monthly,
  },
  {
    name: "Yearly",
    value: "4",
    granularity: Granularity.Yearly,
  },
];

const DATE_FORMATS = {
  [Granularity.Daily]: "dd MMM yyyy",
  [Granularity.Weekly]: "dd MMM yyyy",
  [Granularity.Monthly]: "MMM yyyy",
  [Granularity.Quarterly]: "Qo 'Q' yyyy",
  [Granularity.Yearly]: "yyyy",
};

const getFirstDayOfQuarter = (quarterStr: string): Date => {
  const [quarter, year] = quarterStr.split("Q");
  const quarterNum = parseInt(quarter);
  return startOfQuarter(addQuarters(new Date(year), quarterNum - 1));
};

type UseGMVChartDataProps = {
  excludedOrgs: string[] | null;
};

export const useGMVChartData = ({ excludedOrgs }: UseGMVChartDataProps) => {
  const { fetchData } = useGMVYearlyData({
    excludedOrgs: excludedOrgs ?? [],
  });
  const [graphs, setGraphs] = useState<Graph[]>([]);
  const [selectedHeader, setSelectedHeader] = useState("total");
  const [headers, setHeaders] = useState<HeaderOption[]>([]);
  const [customers, setCustomers] = useState<HeaderOption[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState(
    VALUE_AGGREGATED_ONLY,
  );
  const [selectedPeriod, setSelectedPeriod] = useState<number>(
    Number(DEFAULT_PERIOD),
  );

  const sortCustomers = useCallback((a: HeaderOption, b: HeaderOption) => {
    const nameA = a?.name ?? "";
    const nameB = b?.name ?? "";

    if (nameA === "All Customers") {
      return -1;
    }
    if (nameB === "All Customers") {
      return 1;
    }

    if (nameA === "Show All") {
      return -1;
    }
    if (nameB === "Show All") {
      return 1;
    }

    return nameA.localeCompare(nameB);
  }, []);

  const updateHeaders = useCallback(
    (periodsData: DataResponse) => {
      if (!periodsData?.[0]?.data?.[0]) {
        setHeaders([]);
        return;
      }
      setHeaders(
        Object.keys(periodsData[0].data[0])
          .filter((header) => header !== "name")
          .map((header) => ({
            name: camelToTitle(header),
            value: header,
          })) ?? [],
      );
    },
    [setHeaders],
  );

  const updateCustomers = useCallback(
    (periodsData: DataResponse) => {
      const customerMap = new Map<string, string>();
      customerMap.set("All Customers", VALUE_AGGREGATED_ONLY);
      customerMap.set("Show All", VALUE_SHOW_ALL);

      periodsData.forEach((d) => {
        d.data.forEach((row) => {
          const name = row.name;
          if (
            typeof name === "string" &&
            name.trim() &&
            name.toLowerCase() !== VALUE_ALL_CUSTOMERS_DATA &&
            name !== "All Customers" &&
            name !== "Show All" &&
            !customerMap.has(name)
          ) {
            customerMap.set(name, name.toLocaleLowerCase());
          }
        });
      });

      setCustomers(
        Array.from(customerMap.entries())
          .map(([name, value]) => ({
            name: name,
            value: value,
          }))
          .toSorted(sortCustomers),
      );
    },
    [sortCustomers],
  );

  const showGraph = useCallback(async () => {
    const granularity =
      periods.find((m) => m.value === String(selectedPeriod))?.granularity ??
      Granularity.Monthly;
    let startDate;
    let endDate;
    switch (granularity) {
      case Granularity.Quarterly:
        startDate = startOfQuarter(addQuarters(new Date(), -selectedPeriod));
        endDate = endOfQuarter(new Date());
        break;
      case Granularity.Yearly:
        startDate = startOfYear(addYears(new Date(), -selectedPeriod));
        endDate = endOfYear(new Date());
        break;
      default:
        startDate = startOfMonth(addMonths(new Date(), -selectedPeriod));
        endDate = endOfMonth(new Date());
    }

    const resultData = await fetchData({
      startDate,
      endDate,
      granularity,
    });

    if (!resultData) {
      setGraphs([]);
      setHeaders([]);
      setCustomers([]);
      return;
    }

    updateHeaders(resultData);
    updateCustomers(resultData);

    const allGraphs = [] as Graph[];
    resultData.forEach((d) => {
      d.data.forEach((row) => {
        const dates = resultData.map((r) => new Date(r.date));
        const graphName =
          row.name === VALUE_ALL_CUSTOMERS_DATA ? "All Customers" : row.name;
        const title = `${graphName} ${camelToTitle(selectedHeader) ?? ""}`;

        if (!allGraphs.find((g) => g.title === title)) {
          allGraphs.push({
            title,
            format: selectedHeader.includes(COUNT_LABEL)
              ? GraphDataType.Numeric
              : GraphDataType.Currency,
            labels: dates.map((date) =>
              format(date, DATE_FORMATS[granularity]),
            ),
            data: dates.map((date) => {
              return Number(
                resultData
                  .find(
                    (res) =>
                      format(new Date(res.date), DATE_FORMATS[granularity]) ===
                      format(date, DATE_FORMATS[granularity]),
                  )
                  ?.data.find((r) => r.name === row.name)?.[selectedHeader] ??
                  0,
              );
            }),
          });
        }
      });
    });

    setGraphs(
      allGraphs.toSorted((a, b) => {
        const titleA = a.title ?? "";
        const titleB = b.title ?? "";
        const isAAllCustomers = titleA
          .toLowerCase()
          .startsWith(VALUE_ALL_CUSTOMERS_DATA);
        const isBAllCustomers = titleB
          .toLowerCase()
          .startsWith(VALUE_ALL_CUSTOMERS_DATA);

        if (isAAllCustomers && !isBAllCustomers) {
          return -1;
        }
        if (!isAAllCustomers && isBAllCustomers) {
          return 1;
        }

        return titleA.localeCompare(titleB);
      }),
    );
  }, [
    fetchData,
    selectedHeader,
    selectedPeriod,
    updateCustomers,
    updateHeaders,
  ]);

  useEffect(() => {
    if (excludedOrgs) {
      showGraph();
    }
  }, [selectedHeader, selectedPeriod, excludedOrgs, showGraph]);

  const randomizedColors = useMemo(() => {
    return COLORS.sort(() => Math.random() - 0.5);
  }, []);

  const loadDataForOneMonth = useCallback(
    async (dateString: string, index: number, graphTitle: string) => {
      const granularity =
        periods.find((m) => m.value === String(selectedPeriod))?.granularity ??
        Granularity.Monthly;
      const date =
        granularity === Granularity.Quarterly
          ? getFirstDayOfQuarter(dateString)
          : new Date(dateString);
      let startDate;
      let endDate;
      let gran;
      switch (granularity) {
        case Granularity.Quarterly:
          startDate = startOfQuarter(date);
          endDate = endOfQuarter(date);
          gran = Granularity.Weekly;
          break;
        case Granularity.Yearly:
          startDate = startOfYear(date);
          endDate = endOfYear(date);
          gran = Granularity.Monthly;
          break;
        default:
          startDate = startOfMonth(date);
          endDate = endOfMonth(date);
          gran = Granularity.Daily;
      }

      const result = await fetchData({
        startDate,
        endDate,
        granularity: gran,
      });

      if (!result) {
        return {
          labels: [],
          values: [],
        };
      }

      const graphTitleParts = graphTitle.split(" ");
      const customerName = graphTitleParts[0];

      const isAllCustomersGraph = customerName.toLowerCase() === "all";

      const customerData = result
        .map((d) => {
          if (isAllCustomersGraph) {
            return d.data.find((r) => {
              if (typeof r.name === "string") {
                return (
                  r.name.toLowerCase() ===
                    VALUE_ALL_CUSTOMERS_DATA.toLowerCase() ||
                  r.name.toUpperCase() === "ALL CUSTOMERS"
                );
              }
              return false;
            });
          } else {
            return d.data.find((r) => {
              if (typeof r.name === "string") {
                return (
                  r.name.toLowerCase() === customerName.toLowerCase() ||
                  r.name === customerName
                );
              }
              return false;
            });
          }
        })
        .filter(Boolean);

      if (!customerData.length) {
        return {
          labels: [],
          values: [],
        };
      }

      const formattedValues = customerData.map((d) =>
        Number(d?.[selectedHeader] ?? 0),
      );

      let total = 0;
      const cumulativeValues = formattedValues.map((value) => {
        total += value;
        return total;
      });

      return {
        labels: result.map((r) => {
          if (granularity === Granularity.Yearly) {
            return format(new Date(r.date), DATE_FORMATS[Granularity.Monthly]);
          } else {
            return format(new Date(r.date), DATE_FORMATS[Granularity.Daily]);
          }
        }),
        values: cumulativeValues,
      };
    },
    [fetchData, selectedHeader, selectedPeriod],
  );

  return {
    graphs,
    headers,
    customers,
    selectedHeader,
    setSelectedHeader,
    selectedCustomer,
    setSelectedCustomer,
    selectedPeriod,
    setSelectedPeriod,
    randomizedColors,
    loadDataForOneMonth,
    VALUE_AGGREGATED_ONLY,
    VALUE_SHOW_ALL,
    VALUE_ALL_CUSTOMERS_DATA,
  };
};

import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { TextField } from "@/common/components/textfield/TextField";
import Close from "@mui/icons-material/Close";
import { Popover } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ArchiveGroupFunction,
  CreateGroupFunction,
  Group,
  UpdateGroupFunction,
} from "../../common/types/types";
import { GroupFilterCustomRender } from "./custom-render/GroupFilterCustomRender";
import { EditGroupDialog } from "./edit/EditGroupDialog";
import { useGroupFilter } from "./useGroupFilter";

const NewGroup = tw.div`z-50 flex flex-row justify-between items-center pl-4 bg-blue-100 border border-gray-300 rounded-t-lg border-b-0 text-sm`;
const NewGroupItem = tw.div`truncate 2xl:whitespace-normal`;
const GroupForm = tw.div`flex flex-row gap-2 p-3 bg-blue-100`;
const Container = tw.div``;
const TextFieldStyled = tw(TextField)`bg-white min-w-52`;

export const ADD_NEW_GROUP_ID = "ADD_NEW_GROUP";
export const ALL_GROUP_ID = "ALL_GROUP";
export const UNASSIGNED_GROUP_ID = "UNASSIGNED_GROUP";

export type GroupFilterProps = {
  value: string[] | null | undefined;
  onChange: (groupId: string[] | undefined) => void;
  translationKeys: {
    all: string;
    unassigned: string;
    addNew: string;
    groupName: string;
    archiveTitle: string;
    archiveContent: string;
  };
  folders: Group[];
  createGroup: CreateGroupFunction;
  updateGroup: UpdateGroupFunction;
  deleteGroup: ArchiveGroupFunction;
  classes?: {
    paper?: string;
  };
};

export const GroupFilter = (props: GroupFilterProps) => {
  const intl = useIntl();
  const { translationKeys, updateGroup, classes } = props;
  const {
    computedValue,
    handleChange,
    handleCreate,
    onChangeNewFolder,
    handleDeleteGroup,
    loading,
    anchorEl,
    ref,
    newFolder,
    options,
    setNewFolder,
    onClose,
    emptyFolder,
    inputValue,
    setInputValue,
  } = useGroupFilter(props);

  return (
    <Container ref={ref}>
      <If isTrue={!newFolder}>
        <Multiselect
          className="max-w-full"
          testId="folder-picker"
          label={
            computedValue?.length ? "" : intl.$t({ id: translationKeys.all })
          }
          getLabel={(folder) => folder.name}
          getValue={(folder) => folder.id}
          inputValue={inputValue}
          onInputChange={(value) => setInputValue(value ?? "")}
          values={computedValue}
          options={options}
          onMultipleChange={handleChange}
          customRender={(item) => (
            <GroupFilterCustomRender
              item={item}
              archiveGroup={handleDeleteGroup}
              contentKey={translationKeys.archiveContent}
              titleKey={translationKeys.archiveTitle}
              checked={computedValue.includes(item.id)}
            />
          )}
          loading={loading}
          forcePopupIcon
          chipSize="small"
          disableCloseOnSelect
          limitTags={1}
          creatableFn={() => emptyFolder}
          creatableTextKey={translationKeys.addNew}
          creatable
          creatableFirstOption
          creatableAlwaysVisible
        />
      </If>
      <If isTrue={newFolder}>
        <NewGroup>
          <FormattedMessage
            id={translationKeys.addNew}
            tagName={NewGroupItem}
          />
          <IconButtonBorderless onClick={() => setNewFolder(undefined)}>
            <Close />
          </IconButtonBorderless>
        </NewGroup>
      </If>
      <Popover
        anchorEl={anchorEl}
        onClose={onClose}
        open={!!newFolder}
        classes={{
          paper: `mt-10 xl:-ml-[61px] md:-ml-[9px] shadow-none border border-gray-300 border-t-0 ${classes?.paper}`,
        }}
      >
        <GroupForm>
          <TextFieldStyled
            testId="group-name"
            label={intl.$t({ id: translationKeys.groupName })}
            value={newFolder?.name}
            onChange={onChangeNewFolder}
            onClickEnter={handleCreate}
          />
          <PrimaryButton onClick={handleCreate} testId="addBtn">
            <FormattedMessage id="ADD" />
          </PrimaryButton>
        </GroupForm>
      </Popover>
      <EditGroupDialog updateGroup={updateGroup} />
    </Container>
  );
};

import { addressToString } from "@/common/components/adress-location/AddressLocation";
import tw from "tailwind-styled-components";
import { If } from "../../if/If";
import { ImageInitials } from "../../image-initials/ImageInitials";
import { WarehouseOption } from "../types/WarehouseOption";

const Item = tw.div`py-2 inline-grid grid-flow-col relative gap-1.5
  ${({ $archived }: { $archived: boolean }) => $archived && "text-gray-400"}
`;
const ItemName = tw.span`text-sm
  ${({ $includeMargin }: { $includeMargin: boolean }) => $includeMargin && "ml-6"}
`;
const AddressContainer = tw.span`text-2xs relative text-gray-600`;
const Group = tw.div`inline-grid grid-flow-row`;

export const WarehouseSelectorCustomRenderer = (
  item: WarehouseOption,
  opts: {
    includeDetails: boolean;
  } = { includeDetails: true },
) => {
  return (
    <Item $archived={!!item.archivedAt}>
      <If isTrue={opts?.includeDetails}>
        <ImageInitials name={item.name} width={30} includeTooltip />
      </If>
      <Group>
        <ItemName $includeMargin={!opts.includeDetails}>{item.name}</ItemName>
        <If isTrue={opts?.includeDetails}>
          <AddressContainer>{addressToString(item.address)}</AddressContainer>
        </If>
      </Group>
    </Item>
  );
};

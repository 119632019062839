import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { routes } from "@/config/routes";
import {
  InvoiceFieldsFragment,
  InvoiceSummaryFieldsFragment,
  InvoiceType,
  ReceiptSummaryFieldsFragment,
} from "@/generated/graphql";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";

const ReleaseInfoContainer = tw.div`flex justify-between items-center flex-1 w-full`;
const Container = tw.div`flex flex-col items-center justify-center w-full`;
const BuyoutNumber = tw.div`text-2xs`;
const OrderNumber = tw.div`text-sm`;
const LinkLikeStyled = tw(LinkLike)`
${({ disabled }) => (disabled ? "text-black font-normal" : "")}
`;
const PoNumber = tw.div`text-2xs`;
const CheckIcon = tw(CheckCircleOutlinedIcon)`w-5 h-5 text-green-800`;

type Props = {
  item: InvoiceSummaryFieldsFragment | ReceiptSummaryFieldsFragment;
  disabled?: boolean;
  type: InvoiceType;
};

export const InvoiceOrderAndBuyout: FC<Props> = ({ item, disabled, type }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ReleaseInfoContainer>
      <Container>
        <If isTrue={item.release?.buyout?.id}>
          <LinkLikeStyled
            onClick={() =>
              navigate(
                generatePath(routes.buyout, {
                  id: item.release?.buyout?.id || "",
                }),
              )
            }
            disabled={disabled}
          >
            <FormattedMessage
              id="BUYOUT"
              values={{ number: item.release?.buyout?.clientIdentifier }}
              tagName={BuyoutNumber}
            />
          </LinkLikeStyled>
        </If>
        <NotNullableRenderer
          value={item.release?.id && item.release?.sequenceNumber}
        >
          <LinkLikeStyled
            className="text-center"
            onClick={() =>
              navigate(
                generatePath(routes.delivery, {
                  deliveryId: item.release?.id || "",
                }),
              )
            }
            disabled={disabled}
          >
            <FormattedMessage
              id="DELIVERY_WITH_NUMBER"
              values={{ number: item.release?.sequenceNumber }}
              tagName={OrderNumber}
            />
          </LinkLikeStyled>
        </NotNullableRenderer>
        <If
          isTrue={
            item.release?.poNumber ||
            (item.poNumber && (item as InvoiceFieldsFragment).headerOnly)
          }
        >
          <FormattedMessage
            id="PO_WITH_NUM"
            values={{ number: item.release?.poNumber ?? item.poNumber }}
            tagName={PoNumber}
          />
        </If>
      </Container>
      <If
        isTrue={
          type === InvoiceType.Invoice &&
          (item as InvoiceSummaryFieldsFragment).release?.deliverySlips?.length
        }
      >
        <Tooltip
          id={`delivery-slip-indicator-${item.release?.id}`}
          element={<CheckIcon />}
        >
          {intl.$t({ id: "PACKING_SLIP_UPLOADED" })}
        </Tooltip>
      </If>
    </ReleaseInfoContainer>
  );
};

import { useUser } from "@/common/providers/UserProvider";
import {
  OrderTypeSummaryFieldsFragment,
  useOrderTypeSummariesQuery,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";

type Props = {
  filterResults?: (orderType: OrderTypeSummaryFieldsFragment) => boolean;
};

export const useOrderTypeOptions = (props?: Props) => {
  const { viewer } = useUser();
  const { data, loading } = useOrderTypeSummariesQuery({
    variables: {
      id: viewer?.org?.id || "",
      archived: false,
    },
    skip: !viewer?.authId || !viewer?.org?.id,
  });

  const getOrderType = useCallback(
    (id: string | null | undefined) =>
      data?.org?.releaseTypes
        .filter(props?.filterResults ?? (() => true))
        .find((rt) => rt.id === id),
    [data?.org?.releaseTypes, props?.filterResults],
  );

  return useMemo(
    () => ({
      orderTypes:
        data?.org?.releaseTypes
          .filter(props?.filterResults ?? (() => true))
          .sort((a, b) => {
            if (a.trackInventory === b.trackInventory) {
              return 0;
            }
            return a.trackInventory ? -1 : 1;
          }) || [],
      loading,
      getOrderType,
      includeOrderTypes: (data?.org?.releaseTypes || []).length > 1,
      defaultOrderType:
        data?.org?.releaseTypes?.find((rt) => rt.default) || null,
    }),
    [data?.org?.releaseTypes, getOrderType, loading, props?.filterResults],
  );
};

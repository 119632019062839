import { DeleteButton } from "@/common/components/button/DeleteButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { RfqsRfqFieldsFragment, RfqStatus } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useRfqs } from "../providers/RfqsProvider";

type ArchiveRfqIconButtonProps = {
  rfq: RfqsRfqFieldsFragment;
};

export const ArchiveRfqIconButton: FC<ArchiveRfqIconButtonProps> = ({
  rfq,
}) => {
  const { removeRfq } = useRfqs();
  const { openDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();

  const remove = useCallback(async () => {
    if (await removeRfq(rfq.id)) {
      setSuccessAlert(
        intl.$t(
          { id: "RFQ_DELETE_RFQ_QUESTION_SUCCESS" },
          {
            rfqNumber: strongify(rfq?.clientIdentifier),
          },
        ),
      );
    }
  }, [intl, removeRfq, rfq?.clientIdentifier, rfq.id, setSuccessAlert]);

  return (
    <If
      isTrue={
        !rfq.deletedAt &&
        (rfq.status === RfqStatus.Draft ||
          rfq.status === RfqStatus.Cancelled) &&
        isAuthorized(rfq.permissions.archive)
      }
    >
      <DeleteButton
        onClick={() =>
          openDialog({
            cancelButtonText: intl.$t({ id: "CANCEL" }),
            confirmButtonText: intl.$t({ id: "PROCEED" }),
            includeErrorIcon: true,
            title: intl.$t({ id: "RFQ_DELETE_RFQ_QUESTION" }),
            text: intl.$t({ id: "RFQ_DELETE_RFQ_QUESTION_TEXT" }),
            handleConfirm: remove,
          })
        }
      />
    </If>
  );
};

import { Dialog } from "@/common/components/dialog/Dialog";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { Switch } from "@/common/components/switch/Switch";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { routes } from "@/config/routes";
import { useReleaseVendorActiveContacts } from "@/contractor/pages/home/release/pages/specify-details/hooks/useReleaseVendorActiveContacts";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
const Container = tw.div`
  grid gap-1 px-40 items-start justify-center
`;

const InfoText = tw.div`
  text-sm w-fit grid grid-cols-auto-auto gap-x-2 gap-y-2 items-center font-normal place-self-center
  bg-gray-200 py-1.5 px-4 col-span-3 rounded-3xl 
`;
const AdditionalInfo = tw.div`text-xs col-span-3 text-center leading-tight`;

type SubmitDialogProps = {
  visible: boolean;
  isSaving: boolean;
  setVisible: (visible: boolean) => void;
  onSubmit: ({
    skipVendorNotification,
  }: {
    skipVendorNotification: boolean;
  }) => Promise<ReleaseFieldsFragment | null | undefined>;
};

export const SubmitOrderUpdatesDialog: FC<SubmitDialogProps> = ({
  visible = true,
  setVisible,
  onSubmit,
  isSaving,
}) => {
  const intl = useIntl();
  const [skipVendorNotification, setSkipVendorNotification] = useState(false);
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const { vendorActiveContacts } = useReleaseVendorActiveContacts();

  const handleCancel = () => {
    setVisible(false);
  };

  const handleConfirm = useCallback(async () => {
    const result = await onSubmit({ skipVendorNotification });
    if (result) {
      setVisible(false);
      openDialog({
        content: (
          <SuccessModal
            message={intl.$t({
              id: "UPDATED_RELEASE",
            })}
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
      setTimeout(() => {
        navigate(
          generatePath(routes.delivery, {
            deliveryId: result.id,
          }),
        );
      }, DIALOG_AUTO_CLOSE_TIMER);
    }
  }, [
    onSubmit,
    skipVendorNotification,
    setVisible,
    openDialog,
    intl,
    navigate,
  ]);

  return (
    <Dialog
      includeWarningIcon
      title={
        <FormattedMessage
          id="DELIVERY_SUBMIT_TITLE_REQUESTED"
          values={{ br: <br key="break" /> }}
        />
      }
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({
        id: "SAVE_ORDER",
      })}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      loading={isSaving}
      show={visible}
      content={
        <Container>
          {vendorActiveContacts.length > 0 ? (
            <>
              <InfoText>
                <FormattedMessage id="NOTIFY_VENDOR_ABOUT_ORDER_CHANGES" />
                <Switch
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  value={!skipVendorNotification}
                  onChange={() =>
                    setSkipVendorNotification((prevValue) => !prevValue)
                  }
                />
              </InfoText>
              <If isTrue={!skipVendorNotification}>
                <AdditionalInfo className="mt-1">
                  <FormattedMessage id="VENDOR_WILL_BE_NOTIFIED_WO_CONFIRMATION" />
                </AdditionalInfo>
              </If>
            </>
          ) : (
            <AdditionalInfo className="text-sm">
              <FormattedMessage id="VENDOR_WILL_NOT_BE_NOTIFIED" />
            </AdditionalInfo>
          )}
        </Container>
      }
    />
  );
};

import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { Loader } from "@/common/components/loader/Loader";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { ReleaseDeliverySlips } from "@/common/components/release-images/ReleaseDeliverySlips";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { InvoiceReleaseEditInfo } from "@/contractor/pages/home/invoices/pages/invoice-verification/components/matched-order/components/invoice-edit-info/InvoiceReleaseEditInfo";
import { useInvoiceReleaseConfiguration } from "@/contractor/pages/home/invoices/pages/invoice-verification/components/matched-order/components/invoice-release-items/components/InvoiceReleaseConfiguration";
import { ReleaseInvoices } from "@/contractor/pages/home/release/pages/release-details/ReleaseInvoices";
import { ReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseItemList } from "@/contractor/pages/home/release/release-items-list/ReleaseItemList";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReceipt } from "../../../providers/ReceiptProvider";

const NotesCard = tw(AssetsCard)`my-[1px] lg:border-0 mt-3 overflow-visible`;
const InvoicesCard = tw(ReleaseInvoices)`my-[1px] lg:border-0 overflow-visible`;
const Footer = tw.div`mr-7 mt-10 pb-5`;

type Props = {
  toggleEdit: () => void;
};

export const ReceiptReleaseItems: FC<Props> = ({ toggleEdit }) => {
  const { release } = useRelease();
  const { receipt } = useReceipt();
  const intl = useIntl();

  const items = useMemo(
    () =>
      release?.items.map((item) => {
        const estimatedItems = item.projectItem?.estimatedItems.filter(
          (ei) => ei.zone?.id === item.zone?.id,
        );
        return {
          ...{
            ...item.projectItem,
            material: {
              ...item.projectItem?.material,
              material: {
                ...item.projectItem?.material.material,
                name: item.name,
              },
            },
            estimatedItems,
          },
          ...item,
        } as ExpandedReleaseItem;
      }) ?? [],
    [release?.items],
  );
  const configuration = useInvoiceReleaseConfiguration({
    release,
    readonly: true,
    items,
    includeToggle: true,
    includeInvoiceQty: false,
  });

  if (!release) {
    return <Loader loading />;
  }

  return (
    <ReleaseItemsZoneProvider items={items}>
      <ReleaseItemList
        columns={configuration}
        classNames={{
          header: "-top-6 bg-gray-100 -ml-1",
          category: "top-3",
          subCategory: (groupedByZones: boolean) =>
            groupedByZones ? "top-11" : "top-3",
        }}
      />
      <OutlinedButton className="ml-3 mt-3" $small onClick={toggleEdit}>
        <FormattedMessage
          id="EDIT_ORDER_WITH_NUMBER"
          values={{
            orderNumber: receipt?.release?.sequenceNumber,
          }}
        />
      </OutlinedButton>
      <InvoiceReleaseEditInfo />
      <NotesCard
        headerClassName="bg-blue-100"
        title={intl.$t({ id: "NOTES_AND_ATTACHMENTS" })}
        assets={release.instructions?.assets || []}
        expanded={false}
      />
      <QuoteDocumentPanel
        readonly
        quoteDocument={release.quoteDocument}
        quote={release.quote}
      />
      <InvoicesCard release={release} />
      <ReleaseDeliverySlips deliverySlips={release.deliverySlips} />
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          release={release}
          total={release.total}
          includeNotesPanel={false}
          includePaymentTerms={false}
          taxExempt={{
            isProjectTaxExempt: release.project?.taxExempt,
            isVendorTaxExempt: release.preferredVendor?.taxExempt,
            vendorName: vendorLabelFormatter(release.sellerOrgLocation),
          }}
        />
      </Footer>
    </ReleaseItemsZoneProvider>
  );
};

import {
  ReleaseStatus,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseOperation,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useAddDeliverySlipStore } from "../../releases/pages/delivery-slips/store/useAddDeliverySlipStore";
import { useSyncReleaseItems } from "../pages/specify-details/hooks/useSyncReleaseItems";
import { useRelease } from "../providers/ReleaseProvider";
import { useReleaseUpdate } from "../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../store/useReleaseStore";
import { RELEASE_STATUS_TRANSITIONS } from "./useReleaseBreadcrumbsAdditionalItems";

export const useReleaseStatusUpdate = () => {
  const { release } = useRelease();
  const { submitUpdate } = useReleaseUpdate();
  const { setAddSlipVisible, setSlipRelease } = useAddDeliverySlipStore();
  const { getSyncedRelease } = useSyncReleaseItems();
  const { hasChanges } = useReleaseStore();

  const statusOptions = useMemo(() => {
    if (release) {
      return RELEASE_STATUS_TRANSITIONS[release.status] || [];
    }
    return [];
  }, [release]);

  const changeStatus = useCallback(
    async (newStatus: ReleaseStatus, onSubmitRelease: () => void) => {
      if (!release) {
        return;
      }
      let input: Partial<UpdateContractorReleaseInput> = {};
      if (hasChanges) {
        input = await getSyncedRelease();
      }
      if (
        newStatus === ReleaseStatus.Received ||
        newStatus === ReleaseStatus.PartiallyReceived
      ) {
        setSlipRelease(release);
        setAddSlipVisible(true);
      } else if (
        release?.status === ReleaseStatus.Draft &&
        newStatus === ReleaseStatus.Reserved
      ) {
        const result = await submitUpdate({
          ...input,
          skipConfirmation: true,
          skipVendorNotification: true,
          operation: UpdateContractorReleaseOperation.Reserve,
        });

        if (result?.data) {
          onSubmitRelease();
        }
      } else if (
        release?.status === ReleaseStatus.Received ||
        release?.status === ReleaseStatus.PartiallyReceived ||
        (release?.status === ReleaseStatus.Requested &&
          newStatus === ReleaseStatus.Scheduled)
      ) {
        await submitUpdate({
          ...input,
          releaseId: release.id,
          version: release.version,
          skipConfirmation: true,
          skipVendorNotification: true,
          operation: UpdateContractorReleaseOperation.Schedule,
        });
      }
    },
    [
      getSyncedRelease,
      hasChanges,
      release,
      setAddSlipVisible,
      setSlipRelease,
      submitUpdate,
    ],
  );

  return {
    options: statusOptions,
    changeStatus,
  };
};

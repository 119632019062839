import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterPropsWithTaxes,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useReleaseItemsZoneProvider } from "../../../providers/ReleaseItemsZonesProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { isWarehouseRelease } from "../../../utils/isWarehouseRelease";

const SalesTaxContainer = tw.div`flex flex-col text-center`;
const SalesTaxVarianceContainer = tw.div`flex flex-row text-2xs text-gray-600 whitespace-nowrap`;

export const ReleaseExtPriceFooter: FC<FooterPropsWithTaxes> = ({
  Wrapper,
  includeAdditionalCharges,
}) => {
  const { release } = useRelease();
  const {
    extPriceSubtotal,
    extPriceSalesTax,
    extPriceSalesVariance,
    extPriceAdditionalCharges,
    extPriceTaxRate,
    noPrices,
  } = useReleaseItemsZoneProvider();

  return (
    <FooterCol>
      <Wrapper className="flex-col">
        <If isTrue={!isWarehouseRelease(release)}>
          <FooterHeader>
            <FormattedMessage id="CLIENT_ORDERED" tagName={FooterHeaderText} />
          </FooterHeader>
          <FooterCell>
            <NotNullableRenderer value={!noPrices}>
              <Price price={extPriceSubtotal} />
            </NotNullableRenderer>
          </FooterCell>
          <If isTrue={includeAdditionalCharges}>
            <FooterCell>
              <NotNullableRenderer value={!noPrices}>
                <Price price={extPriceAdditionalCharges} />
              </NotNullableRenderer>
            </FooterCell>
          </If>
          <FooterCell>
            <NotNullableRenderer value={!noPrices}>
              <SalesTaxContainer>
                <Price price={extPriceSalesTax} />
                <If isTrue={Number(extPriceSalesVariance)}>
                  <SalesTaxVarianceContainer>
                    {extPriceTaxRate.toString()}% (
                    <Price price={extPriceSalesVariance} />)
                  </SalesTaxVarianceContainer>
                </If>
              </SalesTaxContainer>
            </NotNullableRenderer>
          </FooterCell>
          <FooterDashedLine />
        </If>
        <FooterTotal
          $isWarehouseRelease={isWarehouseRelease(release)}
          className="font-semibold"
        >
          <FooterCellCol>
            <NotNullableRenderer value={!noPrices}>
              <Price
                price={extPriceSubtotal
                  .plus(extPriceAdditionalCharges)
                  .plus(extPriceSalesTax)}
              />
            </NotNullableRenderer>
          </FooterCellCol>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};

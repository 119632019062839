import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { useOrderTypes } from "@/contractor/pages/admin/orders/pages/order-types/hooks/useOrderTypes";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

type Options = {
  includeUnspecifiedCostCode?: boolean;
  selectedCostCodeId?: string | null;
  zoneId?: string | null;
};

export const useCostCodeIsNotSupported = (options?: Options) => {
  const { orderTypes } = useOrderTypes();
  const intl = useIntl();
  const { projectCostCodes, allowance, restrictCostCodes } =
    useProjectCostCodes();
  const { release } = useRelease();
  const { buyout } = useContractorBuyout();

  const costCodeIsAllowed = useCallback(
    (costCodeId: string) => {
      return projectCostCodes.some(
        (projectCostCode) => projectCostCode.id === costCodeId,
      );
    },
    [projectCostCodes],
  );

  const budgetIsPerZone = useMemo(() => {
    return (
      allowance.costCodes.reduce((acc, costCode) => {
        if (costCode.zone?.id) {
          acc.push(costCode.zone?.id);
        }
        return acc;
      }, [] as string[]).length > 0
    );
  }, [allowance]);

  const zoneBudgetAllowance = useCallback(
    (costCodeId: string, zoneId?: string) => {
      if (!zoneId || zoneId === UNSPECIFIED_ZONE_ID) {
        return allowance.costCodes.find(
          (costCode) => costCode.costCode.id === costCodeId,
        );
      }
      return allowance.costCodes.find(
        (costCode) =>
          costCode.costCode.id === costCodeId && costCode.zone?.id === zoneId,
      );
    },
    [allowance],
  );

  const zoneHasBudgetAllowance = useCallback(
    (costCodeId: string, zoneId?: string) => {
      const allowance = zoneBudgetAllowance(costCodeId, zoneId);
      return allowance?.amount !== "0";
    },
    [zoneBudgetAllowance],
  );

  const allowanceHasCostType = useCallback(
    (costCodeId: string, zoneId?: string) => {
      const costCode = zoneBudgetAllowance(costCodeId, zoneId);
      return !!costCode?.costTypes?.length;
    },
    [zoneBudgetAllowance],
  );

  const projectUsingCostTypes = useMemo(() => {
    return allowance.costCodes.some(
      (costCode) => costCode.costTypes && costCode.costTypes?.length > 0,
    );
  }, [allowance]);

  const costTypeMatchesOrderType = useCallback(
    (costCodeId: string, zoneId?: string) => {
      if (!buyout && !release) {
        return true;
      }

      const orderType = orderTypes.find(
        (type) =>
          type.id === buyout?.releaseType.id || type.id === release?.type.id,
      );
      if (!orderType) {
        return true;
      }

      const costCode = zoneBudgetAllowance(costCodeId, zoneId);
      return costCode?.costTypes?.some(
        (costType) => costType.id === orderType.costType?.id,
      );
    },
    [zoneBudgetAllowance, buyout, release, orderTypes],
  );

  const costCodeIsNotSupported = useCallback(
    (costCodeText?: null | string, zoneId?: string): string => {
      if (!restrictCostCodes || !costCodeText) {
        return "";
      }

      const costCode = projectCostCodes.find(
        (costCode) =>
          costCode.formatted === costCodeText || costCode.id === costCodeText,
      );
      if (!costCode) {
        return intl.$t({ id: "MISSING_COST_CODE_ERROR" });
      }

      if (!costCodeIsAllowed(costCode.id)) {
        return intl.$t({ id: "MISSING_COST_CODE_ERROR" });
      }

      if (!budgetIsPerZone) {
        return "";
      }

      if (!zoneId && zoneBudgetAllowance(costCode.id, zoneId)?.amount === "0") {
        return "";
      }

      if (!zoneHasBudgetAllowance(costCode.id, zoneId)) {
        return intl.$t({ id: "MISSING_ZONE_ERROR" });
      }

      if (!projectUsingCostTypes) {
        return "";
      }

      if (
        !allowanceHasCostType(costCode.id, zoneId) ||
        !costTypeMatchesOrderType(costCode.id, zoneId)
      ) {
        return intl.$t({ id: "COST_TYPE_DOES_NOT_MATCH_ITEM_COST_TYPE" });
      }

      return "";
    },
    [
      restrictCostCodes,
      intl,
      zoneHasBudgetAllowance,
      allowanceHasCostType,
      costTypeMatchesOrderType,
      budgetIsPerZone,
      projectCostCodes,
      costCodeIsAllowed,
      zoneBudgetAllowance,
      projectUsingCostTypes,
    ],
  );

  const isNotSupportedCostCode = useMemo(() => {
    return !!costCodeIsNotSupported(
      options?.selectedCostCodeId,
      options?.zoneId || undefined,
    );
  }, [costCodeIsNotSupported, options]);

  return {
    costCodeIsNotSupported,
    isNotSupportedCostCode,
  };
};

import { If } from "@/common/components/if/If";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { OrderTypePicker } from "@/common/components/order-type-picker/OrderTypePicker";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { ProjectPicker } from "@/common/components/project-picker/ProjectPicker";
import { PROJECT_ADDRESS_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { WarehouseSelector } from "@/common/components/warehouse-selector/WarehouseSelector";
import { useUser } from "@/common/providers/UserProvider";
import { useComplianceGroups } from "@/contractor/pages/admin/orders/pages/compliance-groups/hooks/useComplianceGroups";
import { ReleasePONumber } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleasePONumber";
import { ServiceType, TransactionKind } from "@/generated/graphql";
import { FormControl } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useReleaseActions } from "../../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../../providers/ReleaseUpdateProvider";
import { isWarehouseRelease } from "../../../../utils/isWarehouseRelease";
import { ReleaseGroups } from "../../../release-details/ReleaseGroups";
import { ReleaseWatchers } from "../../../release-details/ReleaseWatchers";
import { AssignBuyoutButton } from "../../assign-buyout/AssignBuyoutButton";
import { useCanEditReleaseVendor } from "../../hooks/useCanEditReleaseVendor";
import { useReleasePredictedPoNumber } from "../../hooks/useReleasePredictedPoNumber";
import { ReleaseVendorContactSelector } from "../../ReleaseVendorContactSelector";
import { ReleaseVendorSelector } from "../../ReleaseVendorSelector";
import { ComplianceGroupSelector } from "../compliance-group-selector/ComplianceGroupSelector";
import { ReleaseDateTimePicker } from "../release-date-time-picker/ReleaseDateTimePicker";
import { SiteContactPicker } from "../site-contact-picker/components/SiteContactPicker";
import {
  EmptyCell,
  SpecifyDetailsDraftHeaderContainer,
  specifyDetailsInputLabelProps,
  specifyDetailsInputProps,
  WatchersContainer,
  WatchersLabel,
} from "./SpecifyDetailsHeader.styles";
import { useSpecifyDetailsHeader } from "./useSpecifyDetailsHeader";

export const SpecifyDetailsDraftHeader = () => {
  const { release } = useRelease();
  const intl = useIntl();
  const { loading } = useReleasePredictedPoNumber(release?.id || "");
  const { updateRelease } = useReleaseUpdate();
  const { requestedDate } = useReleaseActions();
  const { includeOrderTypes } = useOrderTypeOptions();
  const { viewer } = useUser();
  const { canEditVendor } = useCanEditReleaseVendor();
  const {
    update,
    onDateConfirm,
    predictedPoNumberPlaceholder,
    setOrderTypeId,
    filterOrderTypes,
    projectJobSiteAddress,
  } = useSpecifyDetailsHeader();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { complianceGroups } = useComplianceGroups();

  if (!release) {
    return;
  }

  return (
    <SpecifyDetailsDraftHeaderContainer>
      <ReleaseVendorSelector disabled={!canEditVendor} />
      <ProjectPicker
        projectId={release?.project?.id ?? null}
        placeholder={intl.$t({ id: "PROJECT" })}
        disabled
      />
      <AssignBuyoutButton release={release} />
      <FormControl fullWidth>
        <If
          isTrue={
            release?.includeServices.includes(ServiceType.Delivery) ||
            release?.type.transactionKind === TransactionKind.Purchase
          }
        >
          <WarehouseSelector
            value={release?.warehouse?.id}
            projectAddress={projectJobSiteAddress}
            onChange={(warehouseId) => {
              updateRelease({
                releaseId: release?.id || "",
                version: release?.version,
                warehouseId:
                  warehouseId === PROJECT_ADDRESS_ID ? undefined : warehouseId,
                clearWarehouse: warehouseId === PROJECT_ADDRESS_ID,
              });
            }}
            readonly={isWarehouseRelease(release)}
            inputProps={specifyDetailsInputProps}
            includeDetails
          />
        </If>
      </FormControl>
      <PoNumberingSettingsCheck alternativeCondition={!!release?.poNumber}>
        <FormControl fullWidth>
          <ReleasePONumber
            update={(_, poNumber?: string) => update(poNumber)}
            release={release}
            sourceSystem={release?.poLink?.sourceSystem}
            autoSize
            placeholder={predictedPoNumberPlaceholder}
            loading={loading}
            size="small"
            inputProps={specifyDetailsInputProps}
            disabled={isWarehouseRelease(release)}
          />
        </FormControl>
      </PoNumberingSettingsCheck>
      <FormControl fullWidth>
        <ReleaseDateTimePicker value={requestedDate} onChange={onDateConfirm} />
      </FormControl>
      <If isTrue={!release.sourceWarehouse?.id}>
        <ReleaseVendorContactSelector />
      </If>
      <If isTrue={complianceGroups.length > 0}>
        <ComplianceGroupSelector
          complianceGroupId={release?.complianceGroup?.id}
          inputProps={specifyDetailsInputProps}
          setComplianceGroupId={(value) =>
            updateRelease({
              releaseId: release?.id || "",
              version: release?.version,
              complianceGroupId: value || undefined,
            })
          }
        />
      </If>
      <WatchersContainer>
        <FormattedMessage id="WATCHERS" tagName={WatchersLabel} />
        <ReleaseWatchers
          moreTagCount={1}
          classes={{ popper: "absolute -right-7" }}
        />
      </WatchersContainer>
      <If isTrue={release.sourceWarehouse?.id && includePoNumbering}>
        <EmptyCell />
      </If>
      <If isTrue={includePoNumbering && !complianceGroups.length}>
        <EmptyCell />
      </If>
      <FormControl fullWidth>
        <SiteContactPicker
          value={release?.siteContact?.id}
          onChange={(contactId) =>
            updateRelease({
              releaseId: release?.id || "",
              version: release?.version,
              siteContactId: contactId || undefined,
              clearSiteContact: !contactId,
            })
          }
          countryCode={viewer?.org.hqAddress?.country}
          inputProps={specifyDetailsInputProps}
          InputLabelProps={specifyDetailsInputLabelProps}
        />
      </FormControl>
      <If isTrue={includeOrderTypes}>
        <FormControl fullWidth>
          <OrderTypePicker
            orderTypeId={release?.type.id}
            setOrderTypeId={setOrderTypeId}
            disabled={!!release?.buyout}
            inputProps={specifyDetailsInputProps}
            filterResults={filterOrderTypes}
          />
        </FormControl>
      </If>
      <ReleaseGroups includeLabel classes={{ label: "-top-4" }} />
    </SpecifyDetailsDraftHeaderContainer>
  );
};

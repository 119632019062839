import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { WrapperProps } from "@/common/components/grid-table/types/WrapperProps";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { isMasterSku, isProductSku } from "@/common/components/material/utils";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { SpreadSheetConfig } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  Base,
  LgFixedContainer,
  MdContainer,
  SmFixedContainer,
  SmWideContainer,
} from "@/common/layout/ResponsiveClasses";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import {
  OrgMaterialFieldsFragment,
  VendorPriceFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { Connections } from "../../../../../../home/projects/components/Connections";
import { useMaterials } from "../../hooks/useMaterials";
import { MaterialAccountingSoftwareID } from "./components/MaterialAccountingSoftwareID";
import { MaterialCostCodePicker } from "./components/MaterialCostCodePicker";
import { MaterialEstimationSoftwareID } from "./components/MaterialEstimationSoftwareID";
import { MaterialItemActions } from "./components/MaterialItemActions";
import { MaterialManufacturerSelector } from "./components/MaterialManufacturerSelector";
import { MaterialUomPicker } from "./components/MaterialUomPicker";
import { OrgMaterialVIew } from "./components/OrgMaterialView";
const Container = tw(Base)`
  basis-full lg:basis-1/12 lg:w-1/12
  ${({ $group }: WrapperProps) => ($group ? "lg:w-1/4" : "")} flex-1
`;

export const useMaterialsConfiguration = () => {
  const intl = useIntl();
  const { materials } = useMaterials();
  const { manufacturers } = useManufacturers();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { hasManufacturersSetting } = useManufacturersSettings();

  const configuration: Array<
    GridCol<OrgMaterialFieldsFragment, VendorPriceFieldsFragment>
  > = useMemo(
    () => [
      {
        wrapper: Container,
        item: ({ item, editMode, count }) => (
          <OrgMaterialVIew item={item} editMode={editMode} count={count} />
        ),
        position: "none",
        header: <FormattedMessage id="ITEM_DESCRIPTION" />,
      },
      {
        header: <FormattedMessage id="MANUFACTURER" />,
        wrapper: MdContainer,
        item: ({ item, error, index, editMode }) => (
          <MaterialManufacturerSelector
            readonly={!editMode}
            error={error}
            item={item}
            index={index}
          />
        ),
        position: "center",
        hidden: !hasManufacturersSetting,
      },
      {
        wrapper: MdContainer,
        item: ({ item, editMode }) => (
          <MaterialUomPicker item={item} readonly={!editMode} />
        ),
        header: (
          <FormattedMessage
            id="DEFAULT_ESTIMATED_UOM"
            values={{ br: <br key="break" /> }}
          />
        ),
        position: "center",
      },
      {
        wrapper: MdContainer,
        item: ({ item, editMode }) => (
          <MaterialEstimationSoftwareID item={item} readonly={!editMode} />
        ),
        header: (
          <FormattedMessage
            id="ESTIMATION_SOFTWARE_ID"
            values={{ br: <br key="break" /> }}
          />
        ),
        position: "center",
      },
      {
        wrapper: MdContainer,
        item: ({ item, editMode }) => (
          <MaterialAccountingSoftwareID item={item} readonly={!editMode} />
        ),
        header: (
          <FormattedMessage
            id="ACCOUNTING_SOFTWARE_ID"
            values={{ br: <br key="break" /> }}
          />
        ),
        position: "center",
      },
      {
        wrapper: LgFixedContainer,
        item: ({ item, editMode }) => (
          <MaterialCostCodePicker item={item} readonly={!editMode} />
        ),
        header: <FormattedMessage id="COST_CODE" />,
        position: "center",
      },
      {
        wrapper: SmWideContainer,
        position: "center",
        item: ({ item }) => (
          <Connections
            connections={item.externalItems}
            item={item}
            displayConnectButton
            disableClick={false}
          />
        ),
        header: <FormattedMessage id="CONNECTIONS" />,
        hidden: !hasFeatureInConnectedSourceSystem(
          IntegrationFeature.OrgMaterialsMapping,
        ),
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item, editMode, setEditMode }) => {
          return (
            <MaterialItemActions
              item={item}
              readonly={!editMode}
              setEditMode={setEditMode}
            />
          );
        },
        position: "end",
      },
    ],
    [hasFeatureInConnectedSourceSystem, hasManufacturersSetting],
  );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "MATERIAL_NAME" }),
        columnId: "material.name",
        columnType: COLUMN_TYPE.MaterialName,
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer.name",
        columnType: COLUMN_TYPE.Manufacturer,
        optionsFn: (row: Record<string, string>) => {
          const item = materials.find((m) => m.id === row?.id);
          return isMasterSku(item?.material)
            ? [item?.manufacturer?.name ?? ""]
            : isProductSku(item?.material)
              ? []
              : manufacturers.map((m) => m.name);
        },
        hidden: !hasManufacturersSetting,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "defaultEstimateUom.pluralDescription",
        columnType: COLUMN_TYPE.UOM,
      },
      {
        header: intl.$t({ id: "ESTIMATION_SOFTWARE_ID" }, { br: "" }),
        columnId: "material.customPartNumber",
        columnType: COLUMN_TYPE.CustomPartNumber,
        skipSpaceReplacement: true,
      },
      {
        header: intl.$t({ id: "ACCOUNTING_SOFTWARE_ID" }, { br: "" }),
        columnId: "externalCode",
        columnType: COLUMN_TYPE.ExternalCode,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode.code",
        columnType: COLUMN_TYPE.CostCode,
      },
    ],
    [intl, hasManufacturersSetting, materials, manufacturers],
  );

  return { configuration, spreadsheetConfig };
};

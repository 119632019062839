import {
  ComplianceGroupFieldsFragment,
  CostCodeFieldsFragment,
  CostTypeFieldsFragment,
  EnterpriseFieldsFragment,
  ExternalLedgerAccountFieldsFragment,
  IntegrationType,
  InvoiceFolderFieldsFragment,
  ManufacturerFieldsFragment,
  ManufacturersDocument,
  OrgSettingsFieldsFragment,
  ReleaseGroupFieldsFragment,
  SourceSystem,
  StartupDataDocument,
  StartupDataFieldsFragment,
  UomFieldsFragment,
  UomsDocument,
  UserViewerFieldsFragment,
  ViewerDocument,
  ViewerOrgLocationFieldsFragment,
  ViewerQuery,
  WarehouseFieldsFragment,
} from "@/generated/graphql";
import { ApolloError } from "@apollo/client";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { formatCostCodes } from "../utils/cost-codes-and-zones/formatCostCodes";
import { sortByCode } from "../utils/sorting/sortByCode";
import { sortByDescription } from "../utils/sorting/sortByDescription";
import { sortByName } from "../utils/sorting/sortbyName";
import { useApolloClientStore } from "./useApolloClientStore";

export const NonFormattingSourceSystems = [
  SourceSystem.QbOnline,
  SourceSystem.QbDesktop,
  SourceSystem.QbDesktopAlt,
];

type CostCodeWithLedgerAccount = CostCodeFieldsFragment & {
  ledgerAccount?: ExternalLedgerAccountFieldsFragment | null;
};

export type FormattedCostCode = CostCodeWithLedgerAccount & {
  formatted: string;
};

type State = {
  viewer: UserViewerFieldsFragment | undefined | null;
  enterprise: EnterpriseFieldsFragment | undefined | null;
  settings: OrgSettingsFieldsFragment | undefined | null;
  connectedIntegrationType: IntegrationType | undefined;
  connectedSourceSystem: SourceSystem | undefined;
  hasPhaseCodes: boolean;
  uoms: UomFieldsFragment[];
  costCodes: CostCodeWithLedgerAccount[];
  formattedCostCodes: FormattedCostCode[];
  costTypes: CostTypeFieldsFragment[];
  manufacturers: ManufacturerFieldsFragment[];
  warehouses: WarehouseFieldsFragment[];
  locations: ViewerOrgLocationFieldsFragment[];
  invoiceFolders: InvoiceFolderFieldsFragment[];
  releaseGroups: ReleaseGroupFieldsFragment[];
  complianceGroups: ComplianceGroupFieldsFragment[];
  updateStartupData: (newState: Partial<State>) => void;
  fetchStartupData: (force?: boolean) => Promise<void>;
  loading: boolean;
  viewerLoading: boolean;
  error: ApolloError | undefined;
  getWarehouseById: (
    warehouseId: string | undefined,
  ) => WarehouseFieldsFragment | undefined;
};

const sortUomsByName = (a: UomFieldsFragment, b: UomFieldsFragment) => {
  return a.pluralDescription === "units"
    ? -1
    : b.pluralDescription === "units"
      ? 1
      : a.pluralDescription.localeCompare(b.pluralDescription);
};

export const useStartupDataStore = create<State>()(
  devtools((set, get) => ({
    viewer: undefined,
    enterprise: undefined,
    settings: undefined,
    connectedIntegrationType: undefined,
    connectedSourceSystem: undefined,
    hasPhaseCodes: false,
    uoms: [],
    costCodes: [],
    formattedCostCodes: [],
    costTypes: [],
    manufacturers: [],
    warehouses: [],
    locations: [],
    locationRoles: [],
    invoiceFolders: [],
    releaseGroups: [],
    complianceGroups: [],
    loading: true,
    viewerLoading: true,
    error: undefined,
    updateStartupData: (newState: Partial<State>) =>
      set((state) => ({
        ...state,
        manufacturers: (newState.manufacturers ?? state.manufacturers).toSorted(
          sortByName,
        ),
        costCodes: newState.costCodes ?? state.costCodes,
        formattedCostCodes: (newState.costCodes
          ? formatCostCodes(
              newState.costCodes,
              !state.connectedSourceSystem ||
                !NonFormattingSourceSystems.includes(
                  state.connectedSourceSystem,
                ),
            )
          : state.formattedCostCodes
        ).toSorted(
          !state.connectedSourceSystem ||
            !NonFormattingSourceSystems.includes(state.connectedSourceSystem)
            ? sortByCode
            : sortByDescription,
        ),
        costTypes: (newState.costTypes ?? state.costTypes).toSorted(
          sortByDescription,
        ),
        complianceGroups: (
          newState.complianceGroups ?? state.complianceGroups
        ).toSorted(sortByCode),
        uoms: (newState.uoms ?? state.uoms).toSorted(sortUomsByName),
        warehouses: (newState.warehouses ?? state.warehouses).toSorted(
          sortByName,
        ),
        enterprise: newState.enterprise ?? state.enterprise,
        settings: newState.settings ?? state.settings,
        connectedIntegrationType:
          newState.settings?.integrations.accounting.find((a) => a.enabled)
            ?.integration ?? state.connectedIntegrationType,
        connectedSourceSystem:
          newState.settings?.integrations.sourceSystems.find((s) => s.connected)
            ?.system ?? state.connectedSourceSystem,
        hasPhaseCodes:
          newState.settings?.integrations.sourceSystems.find((s) => s.connected)
            ?.wbsTags ?? state.hasPhaseCodes,
        locations: (newState.locations ?? state.locations).toSorted(sortByName),
        invoiceFolders: (
          newState.invoiceFolders ?? state.invoiceFolders
        ).toSorted(sortByName),
        releaseGroups: (newState.releaseGroups ?? state.releaseGroups).toSorted(
          sortByName,
        ),
        loading: false,
        viewerLoading: false,
      })),
    fetchStartupData: async (force = false) => {
      const { client } = useApolloClientStore.getState();
      if (!client) {
        return;
      }
      set({ viewerLoading: true, loading: true });
      try {
        const viewerResult = await client.query<ViewerQuery>({
          query: ViewerDocument,
        });
        const isContractor = viewerResult.data?.viewer?.authId;
        set({
          viewer: viewerResult.data?.viewer,
          viewerLoading: false,
        });
        if (isContractor) {
          await Promise.all([
            client.query({
              query: StartupDataDocument,
              fetchPolicy: force ? "network-only" : "cache-first",
            }),
            client.query({
              query: ManufacturersDocument,
              variables: { searchPrefix: "" },
            }),
          ]).then(([startupDataResult, manufacturersResult]) => {
            const data = startupDataResult.data
              .viewer as StartupDataFieldsFragment;
            const connectedIntegrationType =
              data.org.settings?.integrations.accounting.find(
                (a) => a.enabled,
              )?.integration;
            const connectedSourceSystem =
              data.org.settings?.integrations.sourceSystems.find(
                (s) => s.connected,
              )?.system;
            const hasPhaseCodes = !!(
              connectedSourceSystem &&
              data.org.settings?.integrations.sourceSystems.find(
                (i) => i.connected,
              )?.wbsTags
            );
            const showCodeWithNumber =
              !connectedSourceSystem ||
              !NonFormattingSourceSystems.includes(connectedSourceSystem);
            set({
              uoms: data.org.uoms.toSorted(sortUomsByName),
              costCodes: data.org.costCodes.toSorted(sortByDescription),
              formattedCostCodes: formatCostCodes(
                data.org.costCodes,
                showCodeWithNumber,
              ).toSorted(showCodeWithNumber ? sortByCode : sortByDescription),
              costTypes: data.org.costTypes.toSorted(sortByDescription),
              warehouses: data.org.warehouses.toSorted(sortByName),
              manufacturers:
                manufacturersResult.data.manufacturers.toSorted(sortByName),
              invoiceFolders: data.org.invoiceFolders.toSorted(sortByName),
              releaseGroups: data.org.releaseGroups.toSorted(sortByName),
              locations: data.org.locations.toSorted(sortByName),
              complianceGroups: data.org.complianceGroups.toSorted(sortByCode),
              loading: false,
              viewerLoading: false,
              settings: data.org.settings,
              enterprise: data.enterprise,
              connectedIntegrationType,
              connectedSourceSystem,
              hasPhaseCodes,
            });
          });
        } else {
          await Promise.all([
            client.query({
              query: UomsDocument,
            }),
            client.query({
              query: ManufacturersDocument,
              variables: { searchPrefix: "" },
            }),
          ]).then(([uomsResult, manufacturersResult]) => {
            set({
              uoms: uomsResult.data.uoms.toSorted(sortUomsByName),
              manufacturers:
                manufacturersResult.data.manufacturers.toSorted(sortByName),
              loading: false,
            });
          });
        }
      } catch (error) {
        set({ error: error as ApolloError, loading: false });
      }
    },
    getWarehouseById: (warehouseId: string) =>
      (get().warehouses ?? []).find(
        (warehouse) => warehouse.id === warehouseId,
      ),
  })),
);

import { CountType } from "@/common/components/circle-counter/CountType";
import {
  AssetFieldsFragment,
  CostCodeFieldsFragment,
  EstimatedItemFieldsFragment,
  ManufacturerFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { ProjectItemMaterialView } from "../../../../../../common/components/project-item-material-view/ProjectItemMaterialView";
import { CategoryLike } from "../../../../../../common/hooks/useToggleCategory";
import { useBuyoutGrouped } from "../../../buyout/components/document/providers/BuyoutGroupedProvider";
import { useMaterialCostCodeAndZone } from "../../../common/hooks/useMaterialCostCodeAndZone";

type Props = {
  item: Pick<ProjectItemFieldsFragment, "material" | "id" | "manufacturer">;
  estimatedItem?: EstimatedItemFieldsFragment | null;
  costCode?: CostCodeFieldsFragment | null | undefined;
  zone?: CategoryLike;
  count: CountType;
  manufacturer?: ManufacturerFieldsFragment | null;
  expandable?: boolean;
  notes?: string | null;
  assets?: AssetFieldsFragment[] | null;
  displayRowToggle?: boolean;
  hasAlternatives?: boolean;
  projectId?: string;
  groupedByCostCode?: boolean;
};

export const ProjectItemMaterialEditableView = ({
  projectId,
  groupedByCostCode = false,
  ...props
}: Props) => {
  const { groupedByCostCode: buyoutCostCodeGrouped } = useBuyoutGrouped();

  const { itemDetails } = useMaterialCostCodeAndZone(
    {
      costCode: props.costCode,
      zone: props.zone,
    },
    groupedByCostCode || buyoutCostCodeGrouped,
    projectId,
  );

  return <ProjectItemMaterialView {...props} extraDetails={itemDetails} />;
};

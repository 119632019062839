import { CategoryState } from "@/common/hooks/useToggleCategory";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { InventoryItemWithWarehouse } from "@/contractor/pages/home/inventory-management/pages/current-stock/components/add-inventory-items/types/InventoryItemWithWarehouse";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useEffect, useState } from "react";
import { useSwitchInventoryItems } from "./useSwitchInventoryItems";
type CostCodeCategory = CategoryState<InventoryItemWithWarehouse>;

export const useSwitchInventoryItemList = () => {
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { projectCostCodes } = useProjectCostCodes();
  const { selectedCostCodes, search, filteredInventoryItems } =
    useSwitchInventoryItems();
  const [costCodes, setCostCodes] = useState<CostCodeCategory[]>([]);

  useEffect(() => {
    const costCodeMap = filteredInventoryItems.reduce((acc, item) => {
      const costCode =
        projectCostCodes.find(
          (cost) => cost.id === item.orgMaterial.costCode?.id,
        ) ?? unassignedCostCode;
      const key = costCode?.id;

      if (!acc.has(key)) {
        acc.set(key, {
          id: costCode?.id,
          name: costCode?.description,
          isOpened: true,
          items: [],
        });
      }

      acc.get(key)?.items.push(item);
      return acc;
    }, new Map<string, CostCodeCategory>());

    setCostCodes(Array.from(costCodeMap.values()));
  }, [
    filteredInventoryItems,
    projectCostCodes,
    unassignedCostCode,
    search,
    selectedCostCodes,
  ]);

  return { costCodes, setCostCodes };
};

import { Instructions } from "@/common/components/instructions/Instructions";
import { usePrefillHelpers } from "@/common/components/spreadsheet-table/hooks/usePrefillHelpers";
import { ColumnMapperProvider } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { InstructionInput } from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInventoryItemSequence } from "../components/current-stock-list/components/hooks/useInventoryItemSequence";
import { getSelectedItemIdParts } from "../utils/getSelectedItemIdParts";
import { InventoryTransferBreadcrumbs } from "./components/inventory-transfer-breadcrumbs/InventoryTransferBreadcrumbs";
import { InventoryTransferFooter } from "./components/inventory-transfer-footer/InventoryTransferFooter";
import {
  InventoryTransferForm,
  InventoryTransferFormValues,
} from "./components/inventory-transfer-form/InventoryTransferForm";
import { InventoryTransferHeader } from "./components/inventory-transfer-header/InventoryTransferHeader";
import { useInventoryTransferConfig } from "./hooks/useInventoryTransferConfig";
import { useInventoryTransferTableHelpers } from "./hooks/useInventoryTransferTableHelpers";
import { useSyncInventoryTransfer } from "./hooks/useSyncInventoryTransfer";
import { useTransferTableWarehouseListener } from "./hooks/useTransferTableWarehouseListener";

const Container = tw.div``;

const InventoryTransferWithProvider = () => {
  const intl = useIntl();
  const spreadsheetConfig = useInventoryTransferConfig();
  const { prefillStock, prefillTransfer } = useInventoryTransferTableHelpers();
  useTransferTableWarehouseListener();
  const { prefillExtraOptions } = usePrefillHelpers();
  const { setValue } = useFormContext<InventoryTransferFormValues>();
  const { loading, inventoryItems } = useInventoryItems();
  const { saving } = useSyncInventoryTransfer();

  const [items, setItems] = useState<Identity[]>([]);
  const { selectedEntities, sequenceIds, step } = useInventoryItemSequence();
  const currentSequenceId = useMemo(() => {
    return sequenceIds[step];
  }, [sequenceIds, step]);

  useEffect(() => {
    if (sequenceIds.length === 1) {
      setItems(
        selectedEntities.map(({ id }) => {
          const { inventoryItemId } = getSelectedItemIdParts(id);
          const item = inventoryItems.find(
            (item) => item.id === inventoryItemId,
          );
          return {
            id: inventoryItemId,
            name: item?.orgMaterial,
            uom: {
              pluralDescription: item?.uom.pluralDescription,
            },
            quantityDecimal: getInventoryItemRemainingQuantity(item).toNumber(),
          };
        }),
      );
    }
  }, [
    selectedEntities,
    inventoryItems,
    loading,
    currentSequenceId,
    step,
    sequenceIds.length,
  ]);

  const saveInstructions = useCallback(
    (instruction: InstructionInput) => {
      setValue("instructions", instruction);
    },
    [setValue],
  );

  return (
    <Container>
      <InventoryTransferBreadcrumbs />
      <InventoryTransferHeader />
      <SpreadSheetTable
        items={items}
        columns={spreadsheetConfig}
        saving={saving}
        height="calc(100vh - 500px)"
        rowNumber={30}
        fetchingData={loading}
        customPrefillFunctions={[
          prefillExtraOptions,
          prefillStock,
          prefillTransfer,
        ]}
      />
      <Instructions
        saveInstruction={saveInstructions}
        title={intl.$t({ id: "NOTES_AND_DOCUMENTS" })}
      />
      <InventoryTransferFooter />
    </Container>
  );
};

export const InventoryTransfer = () => {
  const spreadsheetConfig = useInventoryTransferConfig();
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <InventoryTransferForm>
        <InventoryTransferWithProvider />
      </InventoryTransferForm>
    </ColumnMapperProvider>
  );
};

import { useTransactionHistoryModal } from "@/contractor/pages/home/inventory-management/common/components/transaction-history/hooks/useTransactionHistoryModal";
import { InventoryTransactionScheduledTypes } from "@/contractor/pages/home/inventory-management/common/components/transaction-history/types/InventoryTransactionExtendedType";
import { useCallback, useMemo } from "react";
import { CurrentStockListItemProps } from "../../types/CurrentStockListItemProps";

export const useCurrentStockScheduledInflow = ({
  item,
}: CurrentStockListItemProps) => {
  const { onOpen } = useTransactionHistoryModal();

  const onClick = useCallback(() => {
    if (item) {
      onOpen(
        { inventoryItem: item },
        InventoryTransactionScheduledTypes.SCHEDULED_INFLOW,
      );
    }
  }, [item, onOpen]);

  const disabled = useMemo(() => !item?.scheduledInflowTotal, [item]);

  const className = useMemo(() => (disabled ? "font-normal" : ""), [disabled]);

  return {
    onClick,
    disabled,
    className,
  };
};

import { useMemo } from "react";
import { getNameInitials } from "../utils/getNameInitials";

const useInitials = (name: string) => {
  return useMemo(() => {
    return getNameInitials(name);
  }, [name]);
};

export default useInitials;

import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { GridSortDirection } from "@/common/components/grid-table/types/GridSortDirection";
import { ListViewDate } from "@/common/components/list-view-date/ListViewDate";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { ProjectNameListDetails } from "@/common/components/projects-filter-selector/ProjectNameListDetails";
import { TypeIndicator } from "@/common/components/type-indicator/TypeIndicator";
import {
  Base,
  LogoContainer,
  MdContainer,
  SmContainer,
  SmFixedContainer,
  XsFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { isRestockRelease } from "@/contractor/pages/home/release/utils/isRestockRelease";
import {
  QueryReleasesSortBy,
  ReleasePartialFieldsFragment,
  ServiceType,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliveries } from "../providers/DeliveriesProvider";
import { DeliveryActionButton } from "./DeliveryActionButton";
import { DeliveryAllItemsCheckbox } from "./DeliveryAllItemsCheckbox";
import { DeliveryCheckbox } from "./DeliveryCheckbox";
import { DeliveryIssues } from "./DeliveryIssues";
import { ReleaseActions } from "./ReleaseActions";
import { ReleaseAmount } from "./ReleaseAmount";
import { ReleaseMissingCostCode } from "./ReleaseMissingCostCode";
import { ReleaseName } from "./ReleaseName";
import { ReleaseStatusChipWithApprovals } from "./ReleaseStatusChipWithApprovals";
import { ReleaseTimeAndType } from "./ReleaseTimeAndType";
import { SupplierLogo } from "./supplier-logo/SupplierLogo";

const PoHashWrapper = tw(SmContainer)``;
const ReleaseWrapper = tw(Base)`col-span-2 basis-32`;
const ProjectWrapper = tw(MdContainer)`col-span-2`;
const StatusContainer = tw(Base)`w-25 basis-25`;
const TypeContainer = tw(Base)`2xl:w-4 lg:w-4`;
const PoNumber = tw.div`text-xs`;
const WarningsContainer = tw(Base)`w-5 basis-5`;
const ActionsContainer = tw(Base)`w-48 basis-48`;
const GroupsContainer = tw.div`grid`;
const GroupContainer = tw.div`text-gray-600 text-xs w-full`;

export const useDeliveriesConfiguration = () => {
  const { connectedSourceSystem } = useOrgSettings();
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { deliveries } = useDeliveries();
  const intl = useIntl();

  const configuration: Array<GridCol<ReleasePartialFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: XxsFixedContainer,
        position: "center",
        item: ({ item }) => <DeliveryCheckbox delivery={item} />,
        header: <DeliveryAllItemsCheckbox />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => (
          <NotNullableRenderer value={item.groups.length}>
            <GroupsContainer>
              {item.groups.map((group) => (
                <GroupContainer key={group.id}>{group.name}</GroupContainer>
              ))}
            </GroupsContainer>
          </NotNullableRenderer>
        ),
        header: <FormattedMessage id="ORDER_GROUPS" />,
        hidden: deliveries.every((release) => release.groups.length === 0),
      },
      {
        wrapper: ReleaseWrapper,
        header: <FormattedMessage id="RELEASE" />,
        item: ({ item }) => <ReleaseName release={item} />,
      },
      {
        wrapper: TypeContainer,
        item: ({ item }) => <TypeIndicator orderType={item?.type} />,
      },
      {
        wrapper: PoHashWrapper,
        header: <FormattedMessage id="PO_HASH" />,
        item: ({ item }) => <PoNumber>{item.poNumber}</PoNumber>,
        hidden: !includePoNumbering,
        sortKey: QueryReleasesSortBy.PoNumber,
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="CREATE_DATE" />,
        position: "center",
        item: ({ item }) => <ListViewDate date={item.createdAt} />,
        sortKey: QueryReleasesSortBy.CreatedAt,
        defaultSort: GridSortDirection.Descending,
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="DELIVERY" />,
        position: "center",
        item: ({ item }) => (
          <ReleaseTimeAndType
            release={item}
            isDelivery={item.includeServices.includes(ServiceType.Delivery)}
          />
        ),
        sortKey: QueryReleasesSortBy.DeliveryDate,
        defaultSort: GridSortDirection.Descending,
      },
      {
        wrapper: LogoContainer,
        header: <FormattedMessage id="SUPPLIER" />,
        item: ({ item }) => <SupplierLogo release={item} />,
        position: "center",
        sortKey: QueryReleasesSortBy.SupplierName,
      },
      {
        wrapper: ProjectWrapper,
        header: <FormattedMessage id="PROJECT" />,
        item: ({ item }) => (
          <ProjectNameListDetails
            project={item.project}
            details={
              isRestockRelease(item)
                ? intl.$t({ id: "INVENTORY_RESTOCK" })
                : undefined
            }
          />
        ),
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="ORDER_VALUE" />,
        position: "center",
        item: ({ item }) => <ReleaseAmount release={item} />,
        hidden: !hasPermissions,
        sortKey: QueryReleasesSortBy.Total,
      },
      {
        wrapper: WarningsContainer,
        position: "center",
        item: ({ item }) => <ReleaseMissingCostCode release={item} />,
        hidden: !!connectedSourceSystem,
      },
      {
        wrapper: XxsFixedContainer,
        header: <FormattedMessage id="ISSUES" />,
        position: "center",
        item: ({ item }) => <DeliveryIssues count={item.issues.length} />,
      },
      {
        wrapper: XsFixedContainer,
        header: "",
        position: "center",
        item: ({ item }) => <DeliveryActionButton delivery={item} />,
      },
      {
        wrapper: StatusContainer,
        header: <FormattedMessage id="STATUS" />,
        position: "center",
        item: ({ item }) => <ReleaseStatusChipWithApprovals release={item} />,
      },
      {
        wrapper: ActionsContainer,
        header: "",
        position: "start",
        item: ({ item }) => <ReleaseActions id={item.id} release={item} />,
      },
    ],
    [
      connectedSourceSystem,
      deliveries,
      hasPermissions,
      includePoNumbering,
      intl,
    ],
  );

  return configuration;
};

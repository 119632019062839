import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import { EstimatedItemsProvider } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { ProjectProvider } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { AddEstimatedItemsDialog } from "../import-to-buyout/AddEstimatedItemsDialog";
import { BuyoutProjectItemsProvider } from "../providers/BuyoutProjectItemsProvider";
import { useBuyoutAddItems } from "./useBuyoutAddItems";

export const BuyoutAddItems = () => {
  const { buyout } = useContractorBuyout();
  const {
    gotoItemSelection,
    setShowEstimatedItemsDialog,
    showEstimatedItemsDialog,
  } = useBuyoutAddItems();

  return (
    <>
      <AddItemsButton onClick={gotoItemSelection} />
      {showEstimatedItemsDialog && buyout ? (
        <BuyoutProjectItemsProvider>
          <EstimatedItemsProvider>
            <ProjectProvider id={buyout.project.id}>
              <AddEstimatedItemsDialog
                setShowEstimatedItemsDialog={setShowEstimatedItemsDialog}
              />
            </ProjectProvider>
          </EstimatedItemsProvider>
        </BuyoutProjectItemsProvider>
      ) : null}
    </>
  );
};

import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { InventoryItemPendingFlowFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";

type Props = {
  item: InventoryItemPendingFlowFieldsFragment;
};

export const ScheduledFlowQuantity = ({ item }: Props) => {
  return (
    <NotNullableRenderer value={item}>
      <ValueUnit
        classNames={{
          container: "text-xs",
        }}
        value={new Decimal(item?.quantityDecimal)
          .minus(item?.receivedQuantityDecimal)
          .toString()}
        uom={item?.uom}
      />
    </NotNullableRenderer>
  );
};

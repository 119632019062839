import { useCallback } from "react";

import { useMemo, useState } from "react";

import { useTemplateStringsStore } from "../../../store/useTemplateStringsStore";

import { useShallow } from "zustand/react/shallow";
import { TemplateVariablesEnum } from "../../../enums/TemplateVariables.enum";
import { TemplateVariable } from "../TemplateVariablesCommon";

const additionalVariables = ["InvoiceDownloadURL", "InvoiceShortURL"];

type UseTemplateVariablesProps = {
  includeConditional: boolean;
};

export const useTemplateVariables = ({
  includeConditional,
}: UseTemplateVariablesProps) => {
  const { updateDefaults } = useTemplateStringsStore(
    useShallow((state) => ({
      updateDefaults: state.updateDefaults,
    })),
  );

  const formattedVariables = useMemo(
    (): TemplateVariable[] =>
      Object.keys(TemplateVariablesEnum)
        .map((variableKey) => ({
          label: `.${variableKey}`,
          value: variableKey,
          template: includeConditional
            ? `{{if .${variableKey}}}\n  {{.${variableKey}}}\n{{end}}`
            : `{{.${variableKey}}}`,
        }))
        .concat(
          additionalVariables.map((variable) => ({
            label: `.${variable}`,
            value: variable,
            template: includeConditional
              ? `{{if .${variable}}}\n  {{.${variable}}}\n{{end}}`
              : `{{.${variable}}}`,
          })),
        ),
    [includeConditional],
  );

  const [defaultValues, setDefaultValues] = useState<Record<string, string>>(
    Object.keys(TemplateVariablesEnum).reduce(
      (acc, variable) => {
        acc[variable] =
          TemplateVariablesEnum[variable as keyof typeof TemplateVariablesEnum];
        return acc;
      },
      {} as Record<string, string>,
    ),
  );

  const handleDefaultValueChange = useCallback(
    (key: string, value: string) => {
      const newDefaultValues = {
        ...defaultValues,
        [key]: value,
      };
      setDefaultValues(newDefaultValues);
      updateDefaults(newDefaultValues as Record<TemplateVariablesEnum, string>);
    },
    [defaultValues, updateDefaults],
  );

  return { formattedVariables, defaultValues, handleDefaultValueChange };
};

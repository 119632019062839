import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useImportExternalComplianceGroups } from "../../providers/ImportExternalComplianceGroupsProvider";

export const ImportExternalComplianceGroupsModal = () => {
  const { modalOpened, pages, closeModal } =
    useImportExternalComplianceGroups();

  return (
    <WizardModal
      mode={WizardModalMode.SINGLE_PAGE}
      opened={modalOpened}
      pages={pages}
      onClose={closeModal}
    />
  );
};

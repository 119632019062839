import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { SwitchGroup } from "@/common/components/switch/SwitchGroup";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportItems } from "./useImportItems";

const ReplaceText = tw.div`font-medium`;

export const ImportItemsForm = () => {
  const intl = useIntl();
  const { projectOptions, loadingProjectOptions } = useImportItems();

  return (
    <>
      <SelectControlled
        name="sourceProjectId"
        options={projectOptions}
        getLabel={(project) => project.name}
        getValue={(project) => project.id}
        loading={loadingProjectOptions}
        label={intl.$t({
          id: "IMPORT_BILL_OF_MATERIALS_FROM_EXISTING_PROJECT",
        })}
        placeholder={intl.$t({ id: "SELECT_PROJECT" })}
        rules={{
          required: true,
        }}
      />
      <SwitchGroup
        label={
          <FormattedMessage id="REPLACE_EXISTING_ITEMS" tagName={ReplaceText} />
        }
        className="mt-5"
      >
        <SwitchControlled
          name="replace"
          onLabel={intl.$t({ id: "YES" })}
          offLabel={intl.$t({ id: "NO" })}
        />
      </SwitchGroup>
    </>
  );
};

import { NumericalInput } from "@/common/components/numerical-input/NumericalInput";
import {
  PricePercentageSelectedType,
  PricePercentageSelector,
} from "@/common/components/price-percentage-selector/PricePercentageSelector";
import { InvoiceDocumentOptionsProps } from "./types/InvoiceDocumentOptions.types";

type Props = Pick<InvoiceDocumentOptionsProps, "readonly"> & {
  retentionType: PricePercentageSelectedType;
  retentionValue: string;
  retentionLabel: string | undefined;
  setRetentionValue: (value: string) => void;
  handleRetentionTypeChange: (
    type: PricePercentageSelectedType,
  ) => Promise<void>;
};

export const InvoiceRetention = ({
  readonly,
  retentionType,
  retentionValue,
  retentionLabel,
  setRetentionValue,
  handleRetentionTypeChange,
}: Props) => {
  return (
    <NumericalInput
      value={retentionValue}
      onChange={(e) => setRetentionValue(e.target.value)}
      InputProps={{
        className: "h-9 bg-white text-sm pr-2 pl-10",
        readOnly: readonly,
        endAdornment: (
          <PricePercentageSelector
            value={retentionType}
            onChange={handleRetentionTypeChange}
            readOnly={readonly}
          />
        ),
      }}
      fixedDecimalScale
      decimals={2}
      suffix={
        retentionType === PricePercentageSelectedType.Percentage ? "%" : "$"
      }
      className="w-full"
      inputProps={{ className: "text-center" }}
      error={Boolean(
        retentionValue &&
          (parseFloat(retentionValue) < 0 ||
            (parseFloat(retentionValue) > 100 &&
              retentionType === PricePercentageSelectedType.Percentage)),
      )}
      label={retentionLabel}
      InputLabelProps={{ className: "bg-white px-1 text-gray-500" }}
      classes={{
        container: "w-full",
      }}
    />
  );
};

import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ConnectReleasePOsPanel } from "@/contractor/pages/home/release/components/connections/components/panels/ConnectReleasePOsPanel";
import {
  IntegrationType,
  PoFormat,
  ReleaseFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import { FC, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSubmitChanges } from "../../../../pages/specify-details/hooks/useSubmitReleaseChanges";
import { useIsValidFoundationReleaseExport } from "../../hooks/isValidFoundationReleaseExport";
import { useIsValidSage300ReleaseExport } from "../../hooks/isValidSage300ReleaseExport";
import { ReleaseExportStatus } from "../common/release-export-status/ReleaseExportStatus";
import { ReleaseExportStatusEnum } from "../common/release-export-status/types/ReleaseExportStatusEnum";
import { ExportMultipleReleasePOsPanel } from "../panels/ExportMultipleReleasePOsPanel";
import { FoundationExportTypeDialog } from "./components/FoundationExportTypeDialog";
type Props = {
  release: ReleaseFieldsFragment;
};

export const ConnectFoundationReleasePOButton: FC<Props> = ({ release }) => {
  const intl = useIntl();
  const { connectedIntegrationType } = useOrgSettings();
  const { isValidFoundationReleaseExport } =
    useIsValidFoundationReleaseExport();
  const { isValidSage300ReleaseExport } = useIsValidSage300ReleaseExport();
  const { submitChanges, submitting } = useSubmitChanges();

  const [showDialog, setShowDialog] = useState(false);
  const [toJob, setToJob] = useState(!(release.exportedAt && !release.poLink));

  const status = useMemo(() => {
    if (!release.useSourceSystemPO) {
      return ReleaseExportStatusEnum.NoExport;
    }
    if (release.poExists && !release.poLink && !release.exportedAt) {
      return ReleaseExportStatusEnum.PoExists;
    }
    if (
      (connectedIntegrationType === IntegrationType.Foundation &&
        !isValidFoundationReleaseExport({
          release,
          poFormatBasic: release.type.poFormat === PoFormat.Basic,
        })) ||
      (connectedIntegrationType === IntegrationType.Sage300 &&
        !isValidSage300ReleaseExport({
          release,
        }))
    ) {
      return ReleaseExportStatusEnum.ExportIssue;
    }
    if (release.exportedVersion !== release.syncVersion) {
      return ReleaseExportStatusEnum.Outdated;
    }
    return ReleaseExportStatusEnum.OK;
  }, [
    connectedIntegrationType,
    isValidFoundationReleaseExport,
    isValidSage300ReleaseExport,
    release,
  ]);

  const handleConnectClick = useCallback(async () => {
    await submitChanges({
      callback: () => {
        setShowDialog(true);
      },
      validationKey: "NEED_TO_SAVE_CHANGES_EXPORT_SETTINGS",
    });
  }, [submitChanges]);

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <>
          <If
            isTrue={
              release.exportedAt ||
              release.poExists ||
              !release.useSourceSystemPO
            }
          >
            <ReleaseExportStatus
              status={status}
              sourceSystem={SourceSystem.Foundation}
              date={release.exportedAt}
              poNumber={release.poNumber}
              projectId={release.project?.id}
              poExists={release.poExists}
              poLink={release.poLink}
              useSourceSystemPO={release.useSourceSystemPO}
            />
          </If>
          <OutlinedButton $small onClick={handleConnectClick}>
            <Loader loading={submitting} small />
            {intl.$t({ id: "EXPORT_SETTINGS" })}
          </OutlinedButton>
          <FoundationExportTypeDialog
            toJob={toJob}
            showDialog={showDialog}
            setToJob={setToJob}
            setShowDialog={setShowDialog}
            togglePanel={togglePanel}
          />
        </>
      )}
      content={(togglePanel) =>
        toJob ? (
          <ConnectReleasePOsPanel
            releaseIds={[release.id]}
            onClose={() => togglePanel(false)}
            mode={ConnectionMode.Connect}
          />
        ) : (
          <ExportMultipleReleasePOsPanel
            releaseIds={[release.id]}
            onClose={() => togglePanel(false)}
            titleId="EXPORT_WAREHOUSE_PO"
          />
        )
      }
    />
  );
};

import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { getIsNativeSalesTax } from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import {
  OrderTypeSummaryFieldsFragment,
  ReleaseStatus,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useReleaseActions } from "../../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../../providers/ReleaseUpdateProvider";
import { isWarehouseRelease } from "../../../../utils/isWarehouseRelease";
import { useReleasePredictedPoNumber } from "../../hooks/useReleasePredictedPoNumber";

export const useSpecifyDetailsHeader = () => {
  const { release } = useRelease();
  const { predictedPoNumber } = useReleasePredictedPoNumber(release?.id || "");
  const { updateRelease } = useReleaseUpdate();
  const { setRequestedDate } = useReleaseActions();
  const { getOrderType } = useOrderTypeOptions();
  const { setCurrentWarehouseId } = useInventoryItems();

  useEffect(() => {
    setCurrentWarehouseId(
      release?.warehouse?.id || release?.sourceWarehouse?.id || undefined,
    );
    return () => {
      setCurrentWarehouseId(undefined);
    };
  }, [
    release?.sourceWarehouse?.id,
    release?.warehouse?.id,
    setCurrentWarehouseId,
  ]);

  const update = useCallback(
    async (poNumber?: string) => {
      if (release) {
        const input: UpdateContractorReleaseInput = {
          releaseId: release.id,
          version: release.version,
          poNumber,
        };

        await updateRelease(input);
      }
    },
    [release, updateRelease],
  );

  const onDateConfirm = useCallback(
    async (date: Date | null | undefined) => {
      if (release) {
        const isTBD = date === undefined;
        const requestedDate = defaultReleaseDate(date);
        setRequestedDate(requestedDate ?? null);
        const input: UpdateContractorReleaseInput = {
          releaseId: release?.id,
          version: release?.version,
        };

        input.requestedTime = !isTBD ? requestedDate.getTime() : undefined;
        input.timeTBD = isTBD;
        await updateRelease(input);
      }
    },
    [release, setRequestedDate, updateRelease],
  );

  const predictedPoNumberPlaceholder = useMemo(() => {
    if (
      checkReleaseStatus(release, [
        ReleaseStatus.Draft,
        ReleaseStatus.Reserved,
        ReleaseStatus.AwaitingApproval,
        ReleaseStatus.Rejected,
      ]) &&
      !isWarehouseRelease(release)
    ) {
      return predictedPoNumber || undefined;
    }
  }, [predictedPoNumber, release]);

  const setOrderTypeId = useCallback(
    (id: string | null) => {
      const isColumnTaxMode = getIsNativeSalesTax(getOrderType(id));

      updateRelease({
        releaseId: release?.id || "",
        version: release?.version,
        typeId: id,
        clearTaxCode: !isColumnTaxMode,
        clearCustomTaxAmount: isColumnTaxMode,
      });
    },
    [getOrderType, release?.id, release?.version, updateRelease],
  );

  const filterOrderTypes = useCallback(
    (orderType: OrderTypeSummaryFieldsFragment) => {
      if (release?.project?.preferredInventoryInflows) {
        return orderType.trackInventory;
      }
      return !orderType.trackInventory;
    },
    [release?.project?.preferredInventoryInflows],
  );

  const projectJobSiteAddress = useMemo(() => {
    if (!release?.project?.preferredInventoryInflows) {
      return release?.project?.address;
    }
  }, [release?.project?.address, release?.project?.preferredInventoryInflows]);

  return {
    update,
    onDateConfirm,
    predictedPoNumberPlaceholder,
    setOrderTypeId,
    filterOrderTypes,
    projectJobSiteAddress,
  };
};

import Handsontable from "handsontable";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";

type MaterialRenderer = (
  instance: Handsontable,
  td: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: string,
  cellProperties: Handsontable.CellProperties,
) => void;

export const useCreatableRenderer = () => {
  const intl = useIntl();
  const baseRenderer = useBaseRenderer();

  const renderer: MaterialRenderer = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      baseRenderer(instance, td, row, col, prop, value, cellProperties);
      const autocompleteOptions: string[] = cellProperties.source as string[];
      if (
        value &&
        autocompleteOptions &&
        !autocompleteOptions.includes(value.toString())
      ) {
        td.className = "htCenter htMiddle text-blue-800 bg-blue-100";
        const container = document.createElement("sup");
        container.className = "text-blue-500 font-semibold text-[8px] ml-1";
        container.innerText = intl.$t({ id: "NEW" });
        td.appendChild(container);
      }
    },
    [baseRenderer, intl],
  );

  return renderer;
};

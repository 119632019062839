import { routes } from "@/config/routes";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import { useCallback, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { useInventoryItems } from "../../hooks/useInventoryItems";
import { getSelectedItemIdParts } from "../../utils/getSelectedItemIdParts";
import { useInventoryItemSequence } from "../current-stock-list/components/hooks/useInventoryItemSequence";
import { SelectProjectDialogFormValues } from "../select-project-dialog/SelectProjectDialogForm";

export const useAddJobOrder = () => {
  const navigate = useNavigate();
  const { inventoryItems } = useInventoryItems();
  const { createStandaloneRelease } = useCreateRelease();
  const { selectedEntities } = useInventoryItemSequence();
  const [open, setOpen] = useState(false);

  const handleAdjustment = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleConfirm = useCallback(
    async (data: SelectProjectDialogFormValues) => {
      if (!data.projectId) {
        return;
      }

      const firstWarehouseId = getSelectedItemIdParts(
        selectedEntities[0].id,
      ).warehouseId;
      const release = await createStandaloneRelease({
        projectId: data.projectId,
        sourceWarehouseId: data.warehouseId ?? firstWarehouseId,
        items: selectedEntities
          .filter((entity) => {
            const { inventoryItemId } = getSelectedItemIdParts(entity.id);
            return inventoryItems.some((item) => item.id === inventoryItemId);
          })
          .map((entity) => {
            const { warehouseId, inventoryItemId } = getSelectedItemIdParts(
              entity.id,
            );
            const inventoryItem = inventoryItems.find(
              (item) => item.id === inventoryItemId,
            );

            const remainingQuantity = getInventoryItemRemainingQuantity(
              inventoryItem,
              warehouseId,
            );
            return {
              quantityDecimal: "0",
              projectItem: {
                orgCatalogSkuId: inventoryItem?.orgMaterial.material.id,
                estimateUom: inventoryItem?.uom.pluralDescription ?? "",
              },
              sourceWarehouseId: remainingQuantity.gt(0)
                ? warehouseId
                : undefined,
            };
          }),
      });

      if (release) {
        navigate(
          generatePath(routes.specifyDeliveryDetails, {
            deliveryId: release.id,
          }),
        );
      }
      setOpen(false);
    },
    [
      navigate,
      setOpen,
      createStandaloneRelease,
      selectedEntities,
      inventoryItems,
    ],
  );

  return {
    handleConfirm,
    handleAdjustment,
    open,
    setOpen,
  };
};

import { ViewToggle } from "@/common/components/view-toggle/ViewToggle";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { FormattedMessage } from "react-intl";
import { useShallow } from "zustand/react/shallow";

const toggleOptions = [
  {
    label: <FormattedMessage id="VENDORS" />,
    icon: <CoPresentOutlinedIcon className="text-lg" />,
    viewState: TableViewState.vendors,
  },
  {
    label: <FormattedMessage id="NOT_ORG_MATERIALS" />,
    icon: <Inventory2Icon className="text-lg" />,
    viewState: TableViewState.materials,
  },
];

export const MaterialsVendorsToggle = () => {
  const { viewState, setViewState } = useTableViewStore(
    useShallow((state) => ({
      viewState: state.viewState,
      setViewState: state.setViewState,
    })),
  );

  return (
    <ViewToggle
      options={toggleOptions}
      tableView={viewState}
      setView={setViewState}
    />
  );
};

import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { TextField } from "@/common/components/textfield/TextField";
import { Check, Close, EditOutlined } from "@mui/icons-material";
import React, { FC, useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import {
  Container,
  Icons,
  NonEditableView,
  Title,
} from "./InvoiceHeaderDetails.styles";

const TextContainer = tw.div`grid grid-flow-col place-items-center gap-1 w-fit`;
const TextValue = tw.div`line-clamp-2`;
const Row = tw.div`flex flex-1`;

type Props = {
  title: string;
  text: string;
  isEditing: boolean;
  toggleEditing: () => void;
  onSave: (t: string) => void;
  readonly?: boolean;
  error?: boolean;
  info?: React.ReactNode;
  testIds?: { edit?: string; input?: string; check?: string };
};

export const InvoiceHeaderTextEdit: FC<Props> = ({
  title,
  text,
  isEditing,
  toggleEditing,
  onSave,
  readonly = false,
  error = false,
  info,
  testIds,
}) => {
  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  return (
    <Container $error={error}>
      <Title>{title}:</Title>
      <If isTrue={!isEditing}>
        <NonEditableView>
          <NotNullableRenderer
            value={
              <TextContainer>
                <TextValue>{value}</TextValue>
                {info}
              </TextContainer>
            }
          />
          <If isTrue={!readonly}>
            <LinkLike>
              <EditOutlined
                data-testid={testIds?.edit}
                onClick={() => {
                  setValue(text);
                  toggleEditing();
                }}
                fontSize="small"
              />
            </LinkLike>
          </If>
        </NonEditableView>
      </If>
      <If isTrue={isEditing}>
        <Row>
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onClickEnter={() => onSave(value)}
            InputProps={{ className: "max-h-8 flex-1 mx-1 bg-white" }}
            testId={testIds?.input}
          />
          <Icons>
            <Check
              className="cursor-pointer text-blue-500"
              onClick={() => onSave(value)}
              data-testid={testIds?.check}
            />
            <Close
              className="cursor-pointer text-blue-500"
              onClick={() => {
                setValue(text);
                toggleEditing();
              }}
            />
          </Icons>
        </Row>
      </If>
    </Container>
  );
};

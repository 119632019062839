import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import {
  ReleaseAdditionalChargesAndTaxes,
  ReleaseAdditionalChargesAndTaxesProps,
} from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTaxCodeSummaries } from "@/common/components/sales-tax-input/hooks/useTaxCodeSummaries";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import {
  ReleaseFieldsFragment,
  UpdateContractorReleaseInput,
  UpdateVendorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../store/useReleaseStore";
import { isWarehouseRelease } from "../../utils/isWarehouseRelease";

type Props = {
  release?: ReleaseFieldsFragment | null;
  additionalItems?: React.ReactNode;
  editableByContractor?: boolean;
  includeInvoicedTotals?: boolean;
  classes?: ReleaseAdditionalChargesAndTaxesProps["classes"];
};

export const ContractorReleaseAdditionalChargesAndTaxes: FC<Props> = ({
  release,
  additionalItems = null,
  editableByContractor = false,
  includeInvoicedTotals = false,
  classes,
}) => {
  const { updateRelease } = useReleaseUpdate();
  const { release: storeRelease, updateStoreRelease } = useReleaseStore();
  const { taxCodes } = useTaxCodeSummaries();
  const { orderTypes } = useOrderTypeOptions();

  const itemsCount = useMemo(
    () => ({
      partial: release?.items.filter((i) => i.unitPrice !== null).length || 0,
      total: release?.items.length || 0,
    }),
    [release?.items],
  );

  const onReleaseUpdate = useCallback(
    async (
      input:
        | UpdateContractorReleaseInput
        | Omit<UpdateVendorReleaseInput, "addedItems">,
    ) => {
      if (input.releaseId) {
        updateStoreRelease(input, { taxCodes, orderTypes });
        return await updateRelease(input);
      }
    },
    [orderTypes, taxCodes, updateRelease, updateStoreRelease],
  );

  if (!storeRelease) {
    return null;
  }

  return (
    <ReleaseAdditionalChargesAndTaxes
      release={storeRelease}
      total={storeRelease?.total}
      itemsCount={itemsCount}
      additionalItems={additionalItems}
      editableByContractor={editableByContractor}
      editablePaymentTerms
      updateRelease={onReleaseUpdate}
      includeInvoicedTotals={includeInvoicedTotals}
      includePaymentTerms={!isWarehouseRelease(release)}
      includeAdditionalCharges={!isWarehouseRelease(release)}
      includeSubtotal={!isWarehouseRelease(release)}
      includeSalesTax={!isWarehouseRelease(release)}
      taxExempt={{
        isProjectTaxExempt: storeRelease.project?.taxExempt,
        isVendorTaxExempt: storeRelease.preferredVendor?.taxExempt,
        vendorName: vendorLabelFormatter(storeRelease.sellerOrgLocation),
      }}
      classes={classes}
    />
  );
};

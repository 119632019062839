import { If } from "@/common/components/if/If";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { ColumnMapperProvider } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { ImportExternalItemsModal } from "./components/import-external-items/ImportExternalItemsModal";
import { MaterialHeader } from "./components/list/MaterialHeader";
import { MaterialList } from "./components/list/MaterialList";
import { useMaterialsConfiguration } from "./components/list/MaterialList.configuration";
import { MaterialsFooter } from "./components/list/MaterialsFooter";
import { useMaterials } from "./hooks/useMaterials";
import { ImportExternalItemsProvider } from "./providers/ImportExternalItemsProvider";
import {
  MaterialListProvider,
  useMaterialList,
} from "./providers/MaterialListProvider";
import {
  SyncMaterialsProvider,
  useSyncMaterials,
} from "./providers/SyncMaterialsProvider";

const MaterialsListWithProvider = () => {
  const { materials, loading, totalCount, isFiltered } = useMaterialList();
  const { materials: allMaterials } = useMaterials();
  const intl = useIntl();
  const { spreadsheetConfig } = useMaterialsConfiguration();
  const { saving } = useSyncMaterials();
  const viewState = useTableViewStore((state) => state.viewState);

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "MATERIALS" })}</title>
      </Helmet>
      <MaterialHeader />
      <If isTrue={viewState === TableViewState.normal}>
        <MaterialList
          materials={materials}
          loading={loading}
          totalCount={totalCount}
          isFiltered={isFiltered}
        />
      </If>
      <If isTrue={viewState === TableViewState.spreadsheet}>
        <SpreadSheetTable
          items={allMaterials}
          columns={spreadsheetConfig}
          saving={saving}
          height="calc(100vh - 320px)"
        />
      </If>
      <MaterialsFooter />
      <ImportExternalItemsModal />
    </>
  );
};

export const MaterialsList = () => {
  const { spreadsheetConfig } = useMaterialsConfiguration();
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.normal);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  return (
    <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
      <MaterialListProvider>
        <NestedStepperProvider>
          <ImportExternalItemsProvider>
            <ColumnMapperProvider config={spreadsheetConfig}>
              <SyncMaterialsProvider>
                <MaterialsListWithProvider />
              </SyncMaterialsProvider>
            </ColumnMapperProvider>
          </ImportExternalItemsProvider>
        </NestedStepperProvider>
      </MaterialListProvider>
    </PaginationProvider>
  );
};

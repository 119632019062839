import { AttachMoney, Percent } from "@mui/icons-material";
import tw from "tailwind-styled-components";
import { OutlinedButton } from "../button/OutlinedButton";
import { PrimaryButton } from "../button/PrimaryButton";
import { If } from "../if/If";

const Container = tw.div`flex items-center gap-1`;
const StyledOutlineButton = tw(
  OutlinedButton,
)`m-0 h-6 min-h-0 w-6 min-w-0 rounded-sm border-gray-350 p-2`;
const StyledPrimaryButton = tw(
  PrimaryButton,
)`m-0 h-6 min-h-0 w-6 min-w-0 rounded-sm p-2`;

export enum PricePercentageSelectedType {
  Price = "price",
  Percentage = "percentage",
}

type PricePercentageSelectorProps = {
  value: PricePercentageSelectedType;
  onChange: (value: PricePercentageSelectedType) => void;
  readOnly?: boolean;
};

export const PricePercentageSelector = ({
  value,
  onChange,
  readOnly = false,
}: PricePercentageSelectorProps) => {
  return (
    <Container>
      <If isTrue={value === PricePercentageSelectedType.Price}>
        <StyledOutlineButton
          onClick={() => onChange(PricePercentageSelectedType.Percentage)}
          disabled={readOnly}
        >
          <Percent />
        </StyledOutlineButton>
        <StyledPrimaryButton
          onClick={() => onChange(PricePercentageSelectedType.Price)}
          disabled={readOnly}
        >
          <AttachMoney />
        </StyledPrimaryButton>
      </If>
      <If isTrue={value !== PricePercentageSelectedType.Price}>
        <StyledPrimaryButton
          onClick={() => onChange(PricePercentageSelectedType.Percentage)}
          disabled={readOnly}
        >
          <Percent />
        </StyledPrimaryButton>
        <StyledOutlineButton
          onClick={() => onChange(PricePercentageSelectedType.Price)}
          disabled={readOnly}
        >
          <AttachMoney />
        </StyledOutlineButton>
      </If>
    </Container>
  );
};

import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import TextWithShowMore from "@/common/components/line-item-name-notes/TextWithShowMore";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { DateView } from "@/common/utils/dates/DateView";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { MatchedOrderViewState } from "../../../../../../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../../../providers/InvoiceVerificationProvider";
import {
  OrderMatchedHeader,
  OrderNumberHeader,
} from "../../../../../../styles/InvoiceVerification.styles";

const Container = tw.div`w-full`;

export const InvoiceHeaderOnlyHeader: FC = () => {
  const { invoice, updateInvoice, setFooterState } = useInvoiceVerification();
  const intl = useIntl();
  const { setMatchedOrderViewState } = useInvoiceMatchedOrder();
  const additionalTitleItems = useMemo(() => {
    return {
      [intl.$t({ id: "INVOICE_NUMBER_SYMBOL" })]: invoice?.number,
      [intl.$t({ id: "PO_NUMBER" })]: invoice?.poNumber,
      [intl.$t({ id: "ISSUED" })]: invoice?.issueDate ? (
        <DateView date={invoice.issueDate} />
      ) : undefined,
      [intl.$t({ id: "DUE_DATE" })]: invoice?.dueDate ? (
        <DateView date={invoice.dueDate} />
      ) : undefined,
      [intl.$t({ id: "DESCRIPTION" })]: (
        <TextWithShowMore
          text={invoice?.description}
          classes={{ content: "text-sm", text: "text-black" }}
        />
      ),
    };
  }, [intl, invoice]);

  const onClear = useCallback(async () => {
    if (!invoice) {
      return;
    }
    const result = await updateInvoice({ id: invoice.id, headerOnly: false });
    if (result) {
      setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
      setFooterState(InvoiceFooterState.DEFAULT);
    }
  }, [invoice, setFooterState, setMatchedOrderViewState, updateInvoice]);

  return (
    <Container>
      <OrderMatchedHeader>
        <OrderNumberHeader>
          <FormattedMessage id="HEADER_ONLY_INVOICE" />
          <OutlinedButton onClick={onClear} $small className="ml-1">
            <FormattedMessage id="CLEAR" />
          </OutlinedButton>
        </OrderNumberHeader>
      </OrderMatchedHeader>
      <OrderMatchedHeader className="mt-0 pt-0">
        <OrgDetailsHeaderItem
          includeAddress={false}
          additionalKeyValueItems={additionalTitleItems}
          includeRightDivider
        />
        <OrgDetailsHeaderItem
          title={<FormattedMessage id="SUPPLIER" />}
          name={
            <VendorList
              contacts={invoice?.release?.preferredVendor?.contacts.filter(
                (c) => c.receivesInvoiceNotifications,
              )}
            />
          }
          details={invoice?.sellerOrgLocation?.org.name}
          logoImageUrl={invoice?.sellerOrgLocation?.org.photoUrl}
          address={invoice?.sellerOrgLocation?.address}
        />
        <OrgDetailsHeaderItem
          title={<FormattedMessage id="PROJECT" />}
          name={invoice?.project?.name}
          address={invoice?.project?.address}
        />
      </OrderMatchedHeader>
    </Container>
  );
};

import { useDialog } from "@/common/components/dialog/DialogProvider";
import { Group } from "@/common/components/groups/common/types/types";
import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import {
  UNASSIGNED_ACTION,
  useSideActionsStore,
} from "@/common/components/side-action-bar/useSideActionsStore";
import { AnimatedLoading } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import { DeleteOutline, ReplayOutlined } from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import { HiOutlineFolderAdd, HiOutlineFolderRemove } from "react-icons/hi";
import { PiFileCsv } from "react-icons/pi";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useArchiveReleases } from "../../hooks/useArchiveReleases";
import { useReleasesToCsvExport } from "../../hooks/useReleasesToCsvExport";
import { useRestoreReleases } from "../../hooks/useRestoreReleases";
import { useDeliveries } from "../../providers/DeliveriesProvider";
import { useReleaseSequence } from "../../providers/ReleaseSequenceProvider";
import { ReleaseGroupSelector } from "./ReleaseGroupSelector";

const AnimationContainer = tw.div`w-96 h-96`;

export const ReleasesSideActions = () => {
  const {
    sequenceIds,
    selectedReleases: allStoredReleases,
    setSelectedReleases,
    allReleasesSelected,
  } = useReleaseSequence();
  const intl = useIntl();
  const { setSelectedAction } = useSideActionsStore();
  const { archiveReleases } = useArchiveReleases();
  const { restoreReleases } = useRestoreReleases();
  const { openDialog } = useDialog();
  const { refetch, filter, totalCount } = useDeliveries();
  const { exportReleasesToCsv } = useReleasesToCsvExport();

  const selectedReleases = useMemo(
    () =>
      allStoredReleases.filter(
        (release) => (release as ReleasePartialFieldsFragment).version,
      ) as ReleasePartialFieldsFragment[],
    [allStoredReleases],
  );
  const exportItemsToCsv = useCallback(() => {
    exportReleasesToCsv(() => {
      openDialog({
        content: (
          <AnimationContainer>
            <AnimatedLoading
              loading
              text={intl.$t({ id: "EXPORTING_ORDERS" })}
              className="mt-20 h-5/6"
            />
          </AnimationContainer>
        ),
      });
    });
  }, [exportReleasesToCsv, openDialog, intl]);

  const deleteItems = useCallback(() => {
    const removableItems = selectedReleases.filter(
      (release) => !release.deletedAt,
    );
    if (removableItems.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        includeWarningIcon: true,
        title: intl.$t({ id: "DELETE_CONFIRMATION" }),
        text:
          removableItems.length === 1 && removableItems[0]?.sequenceNumber
            ? intl.$t(
                { id: "DELETE_ORDER_MESSAGE" },
                {
                  sequenceNumber: selectedReleases[0]?.sequenceNumber ?? "",
                },
              )
            : intl.$t(
                { id: "DELETE_ORDERS_MESSAGE" },
                { number: removableItems.length },
              ),
        handleConfirm: async () => {
          if (
            await archiveReleases(removableItems.map((release) => release.id))
          ) {
            setSelectedReleases([]);
            refetch();
          }
        },
      });
    }
  }, [
    selectedReleases,
    intl,
    openDialog,
    archiveReleases,
    setSelectedReleases,
    refetch,
  ]);

  const restoreItems = useCallback(() => {
    const restorableItemsIds = selectedReleases
      .filter((release) => !!release.deletedAt)
      .map((release) => release.id);
    if (restorableItemsIds.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        includeWarningIcon: true,
        title: intl.$t({ id: "RESTORE" }),
        text: intl.$t(
          { id: "RESTORE_ORDERS_MESSAGE_WITH_NUMBER" },
          { number: restorableItemsIds.length },
        ),
        handleConfirm: async () => {
          if (await restoreReleases(restorableItemsIds)) {
            setSelectedReleases([]);
            refetch();
          }
        },
      });
    }
  }, [
    selectedReleases,
    intl,
    restoreReleases,
    setSelectedReleases,
    refetch,
    openDialog,
  ]);

  const actions = useMemo(() => {
    const exportToCsvAction = {
      icon: <PiFileCsv className="h-6 w-6" />,
      tooltipKey: "EXPORT_TO_CSV",
      callback: exportItemsToCsv,
    };

    if (
      allReleasesSelected &&
      (selectedReleases.length === 0 || totalCount > selectedReleases.length)
    ) {
      return [exportToCsvAction];
    }

    const existingGroups = selectedReleases
      .map((release) => release.groups)
      .flat()
      .reduce((acc: Group[], group) => {
        if (!acc.find((g) => g.id === group.id)) {
          acc.push(group);
        }
        return acc;
      }, []);

    const sideActions = [
      {
        icon: <HiOutlineFolderAdd className="h-6 w-6" />,
        tooltipKey: "ADD_TO_GROUP",
        component: (
          <ReleaseGroupSelector
            onClose={() => {
              setSelectedAction(UNASSIGNED_ACTION);
            }}
          />
        ),
        preventClose: true,
      },
      {
        icon: <HiOutlineFolderRemove className="h-6 w-6" />,
        tooltipKey: "REMOVE_FROM_GROUP",
        component: (
          <ReleaseGroupSelector
            removing
            onClose={() => {
              setSelectedAction(UNASSIGNED_ACTION);
            }}
            existingGroups={existingGroups}
          />
        ),
        preventClose: true,
      },
      exportToCsvAction,
    ];

    if (
      selectedReleases.some((release) => !release.deletedAt) &&
      filter?.deleted !== true
    ) {
      sideActions.push({
        icon: <DeleteOutline />,
        tooltipKey: "DELETE_ITEMS",
        callback: deleteItems,
      });
    }

    if (
      selectedReleases.some((release) => !!release.deletedAt) &&
      filter?.deleted !== false
    ) {
      sideActions.push({
        icon: <ReplayOutlined />,
        tooltipKey: "RESTORE_ITEMS",
        callback: restoreItems,
      });
    }

    return sideActions;
  }, [
    exportItemsToCsv,
    allReleasesSelected,
    selectedReleases,
    totalCount,
    filter?.deleted,
    setSelectedAction,
    deleteItems,
    restoreItems,
  ]);

  return (
    <SideActionBar
      visible={sequenceIds.length > 0 || allReleasesSelected}
      actions={actions}
    />
  );
};

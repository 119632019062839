import { If } from "@/common/components/if/If";
import { InvoiceOptionsButton } from "@/common/components/invoices/invoice-details/header/invoice-options/InvoiceOptionsButton";
import { InvoiceDocumentOptions } from "@/common/components/invoices/invoice-details/invoice-document-options/InvoiceDocumentOptions";
import { DatePickerControlled } from "@/common/components/picker/components/DatePickerControlled";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { SupplierPickerControlled } from "@/common/components/supplier-picker/SupplierPickerControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../../../InvoiceVerificationForm";
import { useInvoiceHeaderOnly } from "../../common/hooks/useInvoiceHeaderOnly";

const Container = tw.div`w-full grid grid-row-2 gap-2 items-center z-20 p-4 -top-5 bg-blue-100 rounded-2xl drop-shadow-md grid-cols-3`;
const InvoiceDocumentOptionsContainer = tw.div`col-span-3`;
const InvoiceOptionsButtonContainer = tw.div`relative`;

export const InvoiceHeaderOnlyEditableHeader: FC = () => {
  const intl = useIntl();
  const { invoice } = useInvoiceVerification();
  const { watch } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { projects, loading: loadingProjects } = useProjectListOptions();

  const projectId = watch("projectId");

  useSetCurrentProjectId(projectId);
  const { open, setOpen } = useInvoiceHeaderOnly();

  return (
    <Container>
      <TextFieldControlled
        name="invoice.number"
        label={intl.$t({ id: "INVOICE_NO" })}
        className="bg-white"
        rules={{
          required: true,
        }}
      />
      <DatePickerControlled
        name="invoice.issueDate"
        label={intl.$t({ id: "ISSUED" })}
        className="bg-white"
        maxDate={new Date()}
        rules={{
          required: true,
        }}
        utc
      />
      <SelectControlled
        name="invoice.projectId"
        options={projects}
        getValue={(o) => o.id}
        getLabel={(o) => getProjectSelectorLabel(o)}
        placeholder={intl.$t({ id: "PROJECT_NAME" })}
        loading={loadingProjects}
      />
      <PoNumberingSettingsCheck>
        <TextFieldControlled
          name="invoice.poNumber"
          label={intl.$t({ id: "PO_NUMBER" })}
          className="bg-white"
        />
      </PoNumberingSettingsCheck>
      <DatePickerControlled
        name="invoice.dueDate"
        label={intl.$t({ id: "DUE_DATE" })}
        className="bg-white"
        utc
      />
      <SupplierPickerControlled
        name="invoice.sellerOrgLocationId"
        contactsFilter={(c) => c.receivesInvoiceNotifications}
        formatFullLabel
        required
      />
      <TextFieldControlled
        name="invoice.description"
        label={intl.$t({ id: "DESCRIPTION" })}
        className="bg-white"
        required
      />
      <span />
      <InvoiceOptionsButtonContainer>
        <InvoiceOptionsButton
          opened={open}
          handleOptionsClick={() => setOpen(!open)}
          className="absolute -top-4 h-11"
          withChevron
        />
      </InvoiceOptionsButtonContainer>
      <If isTrue={open}>
        <InvoiceDocumentOptionsContainer>
          <InvoiceDocumentOptions
            containerProps={{
              anchorPosition: "right",
              useColumns: true,
            }}
            invoice={invoice}
            bulkUpdate
            closeOptions={() => setOpen(false)}
          />
        </InvoiceDocumentOptionsContainer>
      </If>
    </Container>
  );
};

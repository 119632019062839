import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useUpdateOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useUpdateOrgSettings";

export const useManufacturersSettings = () => {
  const { updateOrgSettings } = useUpdateOrgSettings();
  const { settings } = useOrgSettings();

  return {
    hasManufacturersSetting: settings?.display?.enableManufacturers ?? false,
    updateOrgSettings,
  };
};

import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { useInventoryItemScheduledTransactionsStore } from "../../../store/useInventoryItemScheduledTransactionsStore";

export const TransactionHistoryScheduledOutflowDateRangePicker = () => {
  const { filter, setFilter } = useInventoryItemScheduledTransactionsStore();

  return (
    <ListDatesButton
      hideDates
      dates={[
        {
          startDate: filter?.minDate,
          endDate: filter?.maxDate,
        },
      ]}
      clearValues={() => {
        setFilter({
          ...filter,
          minDate: undefined,
          maxDate: undefined,
        });
      }}
      includeIconLabel={false}
      appliedFilters={(filter?.minDate ? 1 : 0) + (filter?.search ? 1 : 0)}
      includeFilterCount
    >
      {(onClose) => (
        <DateRangePicker
          staticView
          onClose={onClose}
          selectedValue={
            filter?.minDate || filter?.maxDate
              ? {
                  startDate: filter?.minDate
                    ? new Date(filter.minDate)
                    : new Date(),
                  endDate: filter?.maxDate
                    ? new Date(filter.maxDate)
                    : new Date(),
                }
              : undefined
          }
          onChange={(dateRange) => {
            setFilter({
              ...filter,
              minDate: dateRange.startDate?.getTime(),
              maxDate: dateRange.endDate?.getTime(),
            });
          }}
          onClear={() => {
            setFilter({
              ...filter,
              minDate: undefined,
              maxDate: undefined,
            });
          }}
        />
      )}
    </ListDatesButton>
  );
};

import { InventoryItemPendingFlowFieldsFragment } from "@/generated/graphql";
import { InventoryItemScheduledTransactionFilter } from "../types/InventoryItemScheduledTransactionFilter";

export const applyScheduledFlowFilter = (
  flows: InventoryItemPendingFlowFieldsFragment[] | undefined,
  filter: InventoryItemScheduledTransactionFilter,
) => {
  const search = (filter.search || "").toLocaleLowerCase();
  return (
    flows?.filter(
      (item) =>
        (!search ||
          item.release.project?.name.toLocaleLowerCase().includes(search) ||
          item.release.poNumber?.toLocaleLowerCase().includes(search) ||
          item.release.sequenceNumber
            ?.toLocaleString()
            .toLocaleLowerCase()
            .includes(search) ||
          item.release.sourceWarehouse?.name
            .toLocaleLowerCase()
            .includes(search) ||
          item.release.warehouse?.name.toLocaleLowerCase().includes(search)) &&
        (!filter.minDate ||
          !item.release.time ||
          item.release.time >= filter.minDate) &&
        (!filter.maxDate ||
          !item.release.time ||
          item.release.time <= filter.maxDate),
    ) || []
  );
};

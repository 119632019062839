import { useUser } from "@/common/providers/UserProvider";
import { AssetFieldsFragment } from "@/generated/graphql";
import { VisibilityOff, VisibilityOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { useUploadAssets } from "../upload-asset/UploadAssetProvider";

const Container = tw.div<{ $isPrivate: boolean }>`
  absolute top-0 right-0 flex items-center justify-center w-5 h-5 cursor-pointer rounded shadow
  ${({ $isPrivate }) => ($isPrivate ? "bg-white" : "bg-blue-400")}
`;
const CircularProgressStyled = tw(CircularProgress)<{
  $isPrivate: boolean;
}>`${({ $isPrivate }) => ($isPrivate ? "bg-white text-blue-400" : "bg-blue-400 text-white")}`;

type Props = Pick<AssetFieldsFragment, "url" | "isPrivate" | "id">;

export const AssetItemPrivacy: FC<Props> = ({ id, url, isPrivate }) => {
  const { isContractor } = useUser();
  const { updateAsset, updatingAssetIds } = useUploadAssets();

  const updatingAsset = useMemo(
    () => updatingAssetIds.includes(id),
    [updatingAssetIds, id],
  );

  const onClick = useCallback(() => {
    if (!updatingAsset) {
      updateAsset({ id, url, isPrivate: !isPrivate });
    }
  }, [id, url, isPrivate, updatingAsset, updateAsset]);

  if (!isContractor) {
    return null;
  }

  return (
    <Container $isPrivate={isPrivate} onClick={onClick}>
      <If isTrue={isPrivate && !updatingAsset}>
        <VisibilityOff className="text-base text-blue-400" />
      </If>
      <If isTrue={!isPrivate && !updatingAsset}>
        <VisibilityOutlined className="text-base text-white" />
      </If>
      <If isTrue={updatingAsset}>
        <CircularProgressStyled
          size={14}
          thickness={6}
          $isPrivate={isPrivate}
        />
      </If>
    </Container>
  );
};

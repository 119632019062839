import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useIsRestockRelease } from "@/contractor/pages/home/release/hooks/useIsRestockRelease";
import { useIsWarehouseRelease } from "@/contractor/pages/home/release/hooks/useIsWarehouseRelease";
import { useSwitchInventoryItems } from "@/contractor/pages/home/release/pages/specify-details/components/switch-inventory-item/hooks/useSwitchInventoryItems";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseStatus } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { COLUMN_TYPE } from "../../enums/columnType";
import { useTableHelpers } from "../../hooks/useTableHelpers";
import { getPhysicalColumnIndex } from "../../utils/getPhysicalColumnIndex";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useRenderHelpers } from "../helpers/useRenderHelpers";
import { applyClasses } from "../utils/applyClasses";
import { applyTooltip } from "../utils/applyTooltip";
import { isValueEmpty } from "../utils/isValueEmpty";
import { removeTooltip } from "../utils/removeTooltip";
import { useItemRenderer } from "./useItemRenderer";
import { useMaterialRenderer } from "./useMaterialRenderer";

export const useOrderItemRenderer = () => {
  const intl = useIntl();
  const materialRenderer = useMaterialRenderer();
  const { hasPhaseCodes } = useOrgSettings();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { release } = useRelease();
  const {
    getBuyoutItem,
    getEstimatedItem,
    getInventoryItem,
    getInventoryItemAlternatives,
    tableHasColumn,
  } = useTableHelpers();
  const { addIconWithTooltip } = useRenderHelpers();
  const { showItemAlternatives } = useSwitchInventoryItems();
  const { isWarehouseRelease } = useIsWarehouseRelease();
  const { isRestockRelease } = useIsRestockRelease();

  const requiresApproval = useMemo(() => {
    return (
      release?.id &&
      isAuthorized(release?.permissions.submitDirectly) &&
      isAuthorized(release?.permissions.submit) &&
      release?.status !== ReleaseStatus.Requested
    );
  }, [release?.id, release?.permissions, release?.status]);

  const translations = useMemo(
    () => ({
      buyoutNotice: intl.$t({ id: "BUYOUT_ITEM_NOTICE" }),
      estimatedNotice: intl.$t({ id: "ESTIMATED_ITEM_NOTICE" }),
      inventoryNotice: intl.$t({ id: "INVENTORY_ITEM_NOTICE" }),
    }),
    [intl],
  );

  const hasSupplierColumn = useMemo(
    () => tableHasColumn(COLUMN_TYPE.Supplier),
    [tableHasColumn],
  );

  const { setItemRenderer } = useItemRenderer();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      materialRenderer(instance, td, row, col, prop, value, cellProperties);
      if (isValueEmpty(value)) {
        return;
      }

      const rowData = instance?.getDataAtRow(row);
      const buyoutItem = getBuyoutItem({
        id: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.BuyoutItemId)],
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
      });

      if (buyoutItem) {
        setItemRenderer(td, {
          item: buyoutItem,
          textGetter: (item) =>
            `${hasPhaseCodes ? item.tags[0]?.name : item.costCode?.description || unassignedCostCode.description}, ${item.quantityDecimal} ${item.projectItem.estimateUom.pluralDescription}`,
          tooltip: translations.buyoutNotice,
          iconClasses: "text-green-800",
        });

        return;
      }

      const inventoryItem = getInventoryItem({
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
        supplier:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)],
      });

      if (inventoryItem) {
        const remainingQuantity =
          getInventoryItemRemainingQuantity(inventoryItem);
        const inStock = remainingQuantity.greaterThan(0) || isRestockRelease;
        setItemRenderer(td, {
          item: inventoryItem,
          textGetter: () =>
            `${remainingQuantity} ${inventoryItem.uom.pluralDescription}`,
          tooltip: intl.$t({
            id: inStock ? "INVENTORY_ITEM" : "INVENTORY_ITEM_NOT_IN_STOCK",
          }),
          iconClasses: inStock ? "text-blue-800" : "text-orange-500",
          icon:
            inStock || !hasSupplierColumn ? undefined : "fa-solid fa-retweet",
          onClick:
            inStock || !hasSupplierColumn
              ? undefined
              : () =>
                  showItemAlternatives({
                    itemName:
                      rowData[
                        getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)
                      ],
                    row,
                    quantity:
                      rowData[
                        getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)
                      ],
                  }),
        });

        if (!inStock && isWarehouseRelease) {
          applyClasses(td, "bg-red-200");
          applyTooltip(td, intl.$t({ id: "ITEM_NOT_FOUND_IN_STOCK" }));
        }

        return;
      }

      const inventoryItemOptions = getInventoryItemAlternatives({
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
        supplier:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)],
      });

      if (
        inventoryItemOptions?.length &&
        (!release?.sellerOrgLocation || hasSupplierColumn)
      ) {
        applyClasses(td, "pr-5");
        setItemRenderer(td, {
          item: inventoryItemOptions[0],
          textGetter: () => "",
          tooltip: intl.$t({ id: "INVENTORY_ITEM_ALTERNATIVES" }, { br: "\n" }),
          iconClasses: "text-orange-500",
          icon: "fa-solid fa-retweet",
          onClick: () =>
            showItemAlternatives({
              itemName:
                rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
              row,
              quantity:
                rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)],
              limitToCurrentWarehouse: !hasSupplierColumn || isRestockRelease,
            }),
        });
        return;
      }

      const estimatedItem = getEstimatedItem({
        id: rowData[
          getPhysicalColumnIndex(instance, COLUMN_TYPE.EstimatedItemId)
        ],
        material:
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        uom: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
        vendor: rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)],
      });

      if (estimatedItem) {
        setItemRenderer(td, {
          item: estimatedItem,
          textGetter: (item) =>
            `${hasPhaseCodes ? item.tags[0]?.name : item.costCode?.description || unassignedCostCode.description}, ${item.quantityDecimal}`,
          tooltip: translations.estimatedNotice,
          iconClasses: "text-blue-500",
        });

        return;
      }

      const nameHasExtraDetails = value?.match(/ ⟮(.*)⟯/);
      if (
        isWarehouseRelease &&
        !rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Supplier)] &&
        !nameHasExtraDetails
      ) {
        if (requiresApproval) {
          removeTooltip(td);
          addIconWithTooltip({
            element: td,
            tooltipText: intl.$t({ id: "ITEM_NOT_FOUND_IN_STOCK" }),
            iconClasses: "text-gray-600",
          });
        } else {
          applyClasses(td, "bg-red-200");
          applyTooltip(td, intl.$t({ id: "ITEM_NOT_FOUND_IN_STOCK" }));
          addIconWithTooltip({
            element: td,
            icon: hasSupplierColumn ? "fa-solid fa-retweet" : undefined,
            tooltipText: intl.$t({ id: "ITEM_NOT_FOUND_IN_STOCK" }),
            iconClasses: "text-orange-500",
            onClick: () =>
              showItemAlternatives({
                itemName:
                  rowData[
                    getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)
                  ],
                row,
                quantity:
                  rowData[
                    getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)
                  ],
              }),
          });
        }
      }
    },
    [
      intl,
      setItemRenderer,
      hasPhaseCodes,
      unassignedCostCode,
      translations,
      getBuyoutItem,
      getEstimatedItem,
      getInventoryItem,
      getInventoryItemAlternatives,
      materialRenderer,
      addIconWithTooltip,
      isWarehouseRelease,
      showItemAlternatives,
      isRestockRelease,
      hasSupplierColumn,
      requiresApproval,
      release?.sellerOrgLocation,
    ],
  );

  return renderer;
};

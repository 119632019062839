import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { DECIMAL_MAX_FRACTION_DIGITS } from "@/common/const";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../../value-currency/hooks/useFormatNumberToCurrency";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { useRenderHelpers } from "../helpers/useRenderHelpers";
import { applyClasses } from "../utils/applyClasses";
import { applyTooltip } from "../utils/applyTooltip";
import { isValueEmpty } from "../utils/isValueEmpty";

export const useUnitPriceCopyRenderer = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { getMetadata } = useColumnMapper();
  const { formatPrice, addIconWithTooltip } = useRenderHelpers();
  const baseRenderer = useBaseRenderer();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      if (isValueEmpty(value)) {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
        return;
      }

      const rowData = instance.getDataAtRow(row);
      const formattedValue = formatPrice(rowData, instance, value);
      baseRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      if (!value) {
        return;
      }

      const meta = getMetadata(row);
      const valueFixed = Number(value).toFixed(2);
      const copiedFixed =
        meta?.copiedPrice != null ? Number(meta.copiedPrice).toFixed(2) : null;

      if (copiedFixed && valueFixed === copiedFixed) {
        applyClasses(td, "relative bg-green-100");
        td.innerText = "";
        const partText = document.createElement("span");
        partText.className = "text-green-800 pr-2";
        partText.innerText = formattedValue;
        td.appendChild(partText);
        applyTooltip(
          partText,
          intl.$t({ id: "COPIED_PRICE_TOOLTIP" }, { price: formattedValue }),
        );
      }

      const previousFixed =
        meta?.previousPrice != null
          ? Math.round(Number(meta.previousPrice) * 1e6)
          : null;
      const copiedFixedNum =
        meta?.copiedPrice != null
          ? Math.round(Number(meta.copiedPrice) * 1e6)
          : null;

      if (previousFixed && copiedFixedNum && previousFixed !== copiedFixedNum) {
        addIconWithTooltip({
          element: td,
          tooltipText: intl.$t(
            { id: "PREVIOUS_PRICE_TOOLTIP" },
            {
              price: formatCurrency(Number(meta?.previousPrice), {
                maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
              }),
            },
          ),
          iconClasses: "text-green-800 hover:text-green-600",
        });
      }
    },
    [
      baseRenderer,
      formatPrice,
      getMetadata,
      intl,
      formatCurrency,
      addIconWithTooltip,
    ],
  );

  return renderer;
};

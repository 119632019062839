import { ListSelectAll } from "@/common/components/list-select-all/ListSelectAll";
import { useIntl } from "react-intl";
import { useBuyoutSequence } from "../../hooks/useBuyoutSequence";
import { useBuyouts } from "../../providers/BuyoutsProvider";

export const BuyoutsSelectAll = () => {
  const intl = useIntl();
  const { totalCount } = useBuyouts();
  const { selectedEntities, setSelectedEntities } = useBuyoutSequence();

  return (
    <ListSelectAll
      allItemsSelected={false}
      totalCount={totalCount}
      selectedCount={selectedEntities.length}
      itemNamePlural={intl.$t({ id: "BUYOUTS" })}
      itemNameSingular={intl.$t({ id: "BUYOUT_LABEL" })}
      clearAll={() => setSelectedEntities([])}
      hideSelectAll
    />
  );
};

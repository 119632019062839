import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { ColumnType } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { readValue } from "@/common/utils/localStorage";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { disabledColumns as defaultDisabledColumns } from "../components/edit-columns/config/defaultConfiguration";
import { useEditColumnsStore } from "../components/edit-columns/store/useEditColumnsStore";
import { PRICE_RELATED_COLUMNS } from "../constants/tableConstants";
import { useColumnConfig } from "./useColumnConfig";

type UseTableColumnsParams = {
  columns: ColumnType[];
  extraColumns: ColumnType[];
  includePermissionCheck?: boolean;
  pageId?: string;
};

export const useTableColumns = ({
  columns,
  extraColumns,
  includePermissionCheck = true,
  pageId,
}: UseTableColumnsParams) => {
  const getExtendedColumnConfig = useColumnConfig();
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { hiddenColumns: storeHiddenColumns } = useEditColumnsStore(
    useShallow((state: { hiddenColumns: string[] }) => ({
      hiddenColumns: state.hiddenColumns,
    })),
  );

  const allColumns = useMemo(() => {
    const Columns: ColumnType[] = [];
    const filteredColumns = columns.filter((c) => {
      return (
        !c.hidden &&
        !c.metadataColumn &&
        !c.deactivated &&
        (hasPermissions ||
          !includePermissionCheck ||
          !PRICE_RELATED_COLUMNS.includes(c.columnType as COLUMN_TYPE))
      );
    });

    const deactivatedColumns = pageId ? storeHiddenColumns : [];
    const defaultDeactivatedColumns =
      pageId && defaultDisabledColumns[pageId]
        ? defaultDisabledColumns[pageId]
        : [];

    const savedColumnsExist = pageId
      ? !!readValue(`${LOCAL_STORAGE_KEYS.CUSTOM_COLUMNS}_${pageId}`)
      : false;
    const columnsToHide = savedColumnsExist
      ? deactivatedColumns
      : defaultDeactivatedColumns;

    for (let i = 0; i < filteredColumns.length; i++) {
      if (pageId && columnsToHide.includes(filteredColumns[i].columnType)) {
        continue;
      }

      Columns.push(filteredColumns[i]);
      for (let j = 0; j < extraColumns.length; j++) {
        if (extraColumns[j].additional === filteredColumns[i].columnType) {
          Columns.push(extraColumns[j]);
        }
      }
    }
    return Columns;
  }, [
    columns,
    extraColumns,
    hasPermissions,
    includePermissionCheck,
    pageId,
    storeHiddenColumns,
  ]);

  const tableHiddenColumns = useMemo(() => {
    const hiddenColumnIndices = [] as number[];
    const columnsConfig = getExtendedColumnConfig(allColumns);

    columnsConfig.forEach((col, index) => {
      if (col.hidden) {
        hiddenColumnIndices.push(index);
      }
    });

    return {
      columns: hiddenColumnIndices,
    };
  }, [allColumns, getExtendedColumnConfig]);

  const columnsConfig = useMemo(
    () => getExtendedColumnConfig(allColumns),
    [allColumns, getExtendedColumnConfig],
  );

  return {
    allColumns,
    tableHiddenColumns,
    columnsConfig,
  };
};

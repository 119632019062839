import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { getUserName } from "@/common/utils/users/getUserName";
import { UsersUserFieldsFragment } from "@/generated/graphql";
import { Close } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

const Tag = tw.span`
  grid relative place-items-center bg-blue-400 text-white h-6 text-2xs px-3 rounded-3xl group/tag min-w-12
`;

const Name = tw.span`truncate w-full`;

const ActionContainer = tw.div`
  absolute grid grid-flow-col place-items-center right-0 top-0 pr-0.5 rounded-r-3xl cursor-pointer 
  opacity-0 group-hover/tag:opacity-100 bg-blue-400 h-6
`;

const Dots = tw.span`
  text-2xs py-1 font-medium pl-0.5
`;

const CloseIcon = tw(Close)`
  text-sm pb-0.5 text-white hover:scale-110
`;

const CloseButton = tw(IconButtonBorderless)`
  w-5 h-5 min-h-5 place-items-center
`;

type Props = {
  user: UsersUserFieldsFragment;
  onRemove?: (id: string) => void;
  readonly?: boolean;
};

export const WatcherTag: FC<Props> = ({ user, onRemove, readonly }) => (
  <Tag key={user.id}>
    <Name>{getUserName(user)}</Name>
    <If isTrue={!readonly}>
      <ActionContainer>
        <Dots>...</Dots>
        <CloseButton onClick={() => onRemove?.(user.id)}>
          <CloseIcon />
        </CloseButton>
      </ActionContainer>
    </If>
  </Tag>
);

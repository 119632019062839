import { BuyoutPickerCustomRender } from "@/common/components/buyout-picker/BuyoutPickerCustomRender";
import { If } from "@/common/components/if/If";
import { OrderTypePickerControlled } from "@/common/components/order-type-picker/OrderTypePickerControlled";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { SupplierPickerCustomRender } from "@/common/components/supplier-picker/components/renderers/SupplierPickerCustomRender";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { useUser } from "@/common/providers/UserProvider";
import { QuoteDocumentImport } from "@/contractor/pages/home/common/quote-document/components/QuoteDocumentImport";
import { QuoteDocumentProvider } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import {
  AssetFieldsFragment,
  OrgPreferredVendorsFieldsFragment,
  PoNumberingMode,
  ProjectExtendedFieldsFragment,
  usePredictedReleaseSequenceNumberQuery,
} from "@/generated/graphql";
import { FC, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { PoNumberInputControlled } from "@/common/components/po-number/PoNumberInputControlled";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { MultiselectControlled } from "@/common/components/select/components/multiple/MultiselectControlled";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { MAX_VENDOR_NUMBER } from "@/common/const";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { getUserName } from "@/common/utils/users/getUserName";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InfoOutlined } from "@mui/icons-material";
import { useNewDeliveryForm } from "../../../hooks/useNewDeliveryForm";

import { LocationSelectorControlled } from "@/common/components/location-selector/LocationSelectorControlled";
import { GroupRenderer } from "@/common/components/select/components/renderer/GroupRenderer";
import { SupplierSelectorCustomRendererWithIcon } from "@/common/components/supplier-picker/components/renderers/SupplierSelectorCustomRendererWithIcon";
import { useSupplierOptions } from "@/common/components/supplier-picker/useSupplierOptions";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { WarehouseSelectorCustomRendererWithIcon } from "@/common/components/warehouse-selector/renderer/WarehouseSelectorCustomRendererWithIcon";
import { WarehouseSelectorControlled } from "@/common/components/warehouse-selector/WarehouseSelectorControlled";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { NoteDocumentImport } from "@/contractor/pages/home/common/note-document/components/NoteDocumentImport";
import { NoteDocumentProvider } from "@/contractor/pages/home/common/note-document/providers/NoteDocumentProvider";
import {
  BuyoutsByProjectProvider,
  useBuyoutsByProject,
} from "../BuyoutsByProjectProvider";
import { NewDeliveryFormContainer } from "../NewDeliveryForm.styles";
import { PoPredictedNumber } from "../PoPredictedNumber";
import { NewOrderCreationType } from "./types/NewOrderCreationType";
import { NewOrderType } from "./types/NewOrderType";

const SwitchGroup = tw.div`flex flex-row gap-2 items-center justify-between`;
const GrayContainer = tw.div`flex flex-col bg-gray-100 gap-3 rounded-3xl shadow-md p-5`;
const Row = tw.div`flex gap-2 items-center`;
const InfoIcon = tw(InfoOutlined)`text-blue-500`;
const WatchersContainer = tw.div`relative`;
const InfoTooltipContainer = tw.div`absolute right-10 top-1/2 z-10 -translate-y-1/2`;

const tabs = [
  {
    label: <FormattedMessage id="CREATE_NEW" />,
    viewState: NewOrderType.CreateNew,
    includedCreationTypes: [
      NewOrderCreationType.Default,
      NewOrderCreationType.Restock,
    ],
  },
  {
    label: (
      <FormattedMessage
        id="IMPORT_FROM_VENDOR_QUOTE"
        values={{ br: <br key="vendor-quote-br" /> }}
      />
    ),
    viewState: NewOrderType.ImportQuote,
    includedCreationTypes: [NewOrderCreationType.Default],
  },
  {
    label: (
      <FormattedMessage
        id="IMPORT_FROM_NOTE"
        values={{ br: <br key="note-br" /> }}
      />
    ),
    viewState: NewOrderType.ImportNote,
    includedCreationTypes: [NewOrderCreationType.Default],
  },
];

type Props = {
  onClose: () => void;
  project?: ProjectExtendedFieldsFragment | null;
  type?: NewOrderCreationType;
  title?: string;
};

const NewDeliveryFormWithProvider: FC<Props> = ({
  onClose,
  project,
  title,
  type = NewOrderCreationType.Default,
}) => {
  const {
    selectedLocation,
    saveLabel,
    createFromNote,
    createFromQuote,
    onTabChange,
    creating,
    onSubmit,
    costCodeOptions,
    zoneOptions,
    orderTypeConfig,
    loadingPredictedPoNumber,
    formMethods,
    predictedPoNumber,
    hasBuyout,
    view,
    users,
    isVendorRequired,
    isToJobOrder,
    selectedSourceWarehouse,
    warehouseProjectAddress,
    selectedVendor,
    isInventoryRestockOrder,
    filterOrderTypes,
    projects,
  } = useNewDeliveryForm({ onClose, project, type });
  const intl = useIntl();
  const { buyouts } = useBuyoutsByProject();
  const { hasPhaseCodes, settings } = useOrgSettings();
  const { supplierOptions, loading, shouldShowVendorCode } = useSupplierOptions(
    {
      includeWarehouses:
        type === NewOrderCreationType.Default && settings?.inventory.enabled,
    },
  );
  const { getVendorCode } = useVendors();
  const { locations } = useUserLocations();
  const { poNumbering } = useUser();
  const [asset, setAsset] = useState<AssetFieldsFragment | null>(null);

  const { data: predictedReleaseSequenceData } =
    usePredictedReleaseSequenceNumberQuery({
      fetchPolicy: "network-only",
      skip: poNumbering !== PoNumberingMode.Never,
    });

  const { handleSubmit, setValue, watch, getValues, ...formProps } =
    formMethods;

  const locationId = watch("locationId");
  const projectId = watch("projectId");
  const buyoutId = watch("buyoutId");
  const reservePoNumber = watch("reservePoNumber");
  const reserveSequenceNumber = watch("reserveSequenceNumber");
  const watcherIds = watch("watcherIds");

  const userOptions = useMemo(() => users || [], [users]);

  const filteredTabs = useMemo(() => {
    const defaultTabs = tabs.filter((tab) =>
      tab.includedCreationTypes.includes(type),
    );
    if (defaultTabs.length === 1) {
      return undefined;
    }
    return defaultTabs;
  }, [type]);

  return (
    <OverlayPanel
      title={
        title || (
          <FormattedMessage id="NEW_RELEASE">{(msg) => msg}</FormattedMessage>
        )
      }
      onSave={
        view === NewOrderType.CreateNew
          ? handleSubmit(onSubmit)
          : view === NewOrderType.ImportQuote
            ? handleSubmit(createFromQuote)
            : handleSubmit(createFromNote)
      }
      disableSave={
        (view === NewOrderType.ImportQuote ||
          view === NewOrderType.ImportNote) &&
        ((!locationId && !project) || !asset)
      }
      onCancel={onClose}
      saveLabel={saveLabel}
      flexDirectionRow
      isLoading={creating}
      tabs={filteredTabs}
      activeTab={view}
      onTabChange={onTabChange}
    >
      <FormProvider
        {...formProps}
        handleSubmit={handleSubmit}
        setValue={setValue}
        watch={watch}
        getValues={getValues}
      >
        <If isTrue={view === NewOrderType.CreateNew}>
          <NewDeliveryFormContainer>
            <SelectControlled
              name="vendorId"
              testId="form-vendors-selector"
              placeholder={intl.$t({
                id: isVendorRequired ? "SUPPLIER" : "SUPPLIER_OPTIONAL",
              })}
              groupBy={
                !isInventoryRestockOrder ? (option) => option.type : undefined
              }
              options={supplierOptions || []}
              loading={loading}
              customRender={(item) =>
                SupplierPickerCustomRender(item, {
                  filter: (c) => c.receivesOrderNotifications,
                  shouldShowVendorCode,
                  grouped: !isInventoryRestockOrder,
                })
              }
              renderGroup={GroupRenderer}
              rules={{
                required: isVendorRequired,
              }}
              getLabel={(o) =>
                (o as OrgPreferredVendorsFieldsFragment).sellerOrgLocation
                  ? vendorLabelFormatter(
                      (o as OrgPreferredVendorsFieldsFragment)
                        .sellerOrgLocation,
                      (o as OrgPreferredVendorsFieldsFragment).contacts.filter(
                        (c) => c.receivesOrderNotifications,
                      ),
                      {
                        vendorCode: getVendorCode(o),
                      },
                    )
                  : o.name
              }
              getValue={(option) =>
                (option as OrgPreferredVendorsFieldsFragment).sellerOrgLocation
                  ? (option as OrgPreferredVendorsFieldsFragment)
                      .sellerOrgLocation.id
                  : option.id
              }
              noOptionsText={intl.$t({ id: "NO_VENDORS_FOUND" })}
              selectedOptionCustomView={
                selectedSourceWarehouse ? (
                  <WarehouseSelectorCustomRendererWithIcon
                    item={selectedSourceWarehouse}
                  />
                ) : selectedVendor ? (
                  <SupplierSelectorCustomRendererWithIcon
                    item={selectedVendor}
                  />
                ) : null
              }
              limitResults={MAX_VENDOR_NUMBER}
            />
            <SelectControlled
              name="projectId"
              placeholder={intl.$t({ id: "PROJECT" })}
              options={project ? [project] : projects}
              rules={{ required: true }}
              getLabel={(option) => getProjectSelectorLabel(option)}
              getValue={(option) => option.id}
              noOptionsText={intl.$t({ id: "NO_PROJECTS_FOUND" })}
              staticText={!!project?.id}
              groupBy={(option) =>
                !option.preferredInventoryInflows
                  ? intl.$t({ id: "JOB" })
                  : intl.$t({ id: "INVENTORY_RESTOCK" })
              }
            />
            <If
              isTrue={isToJobOrder && orderTypeConfig?.features.includeWillCall}
            >
              <WarehouseSelectorControlled
                name="warehouseId"
                required
                projectAddress={warehouseProjectAddress}
                grouped={!isInventoryRestockOrder}
                includeDetails={isInventoryRestockOrder}
              />
            </If>
            <OrderTypePickerControlled
              name="orderTypeId"
              required
              disabled={!!buyoutId}
              filterResults={filterOrderTypes}
              grouped={!isInventoryRestockOrder}
            />
            <If isTrue={hasBuyout}>
              <SelectControlled
                name="buyoutId"
                testId="form-buyouts-selector"
                placeholder={intl.$t({ id: "BUYOUT_TITLE_OPTIONAL" })}
                options={buyouts || []}
                customRender={(item) =>
                  BuyoutPickerCustomRender(
                    item,
                    (c) => c.receivesQuoteNotifications,
                  )
                }
                getLabel={(option) =>
                  `#${option.clientIdentifier} - ${
                    option.sellerOrgLocation.org.name
                  } - ${option.preferredVendor?.contacts
                    .filter((c) => c.receivesQuoteNotifications)
                    .map((c) => c.name)
                    .join(", ")}`
                }
                getValue={(option) => option.id}
                noOptionsText={intl.$t({ id: "NO_BUYOUTS_FOUND" })}
                disableClearable={false}
              />
            </If>
            <If isTrue={projectId}>
              <WatchersContainer>
                <MultiselectControlled
                  name="watcherIds"
                  options={userOptions}
                  getLabel={(o) => getUserName(o)}
                  getValue={(o) => o.id}
                  values={watcherIds}
                  chipSize="small"
                  includeCheckbox
                  limitTags={2}
                  disableCloseOnSelect
                  className="w-full min-w-45"
                  placeholder={intl.$t({ id: "WATCHERS" })}
                  required
                />
                <InfoTooltipContainer>
                  <InfoTooltip
                    message={intl.$t({
                      id: "WATCHERS_MULTISELECT_INFO",
                    })}
                  />
                </InfoTooltipContainer>
              </WatchersContainer>
            </If>
            <If
              isTrue={
                orderTypeConfig?.features.includeVendorStocking ||
                orderTypeConfig?.features.includeWillCall
              }
            >
              <GrayContainer>
                <If isTrue={orderTypeConfig?.features.includeWillCall}>
                  <SwitchGroup>
                    <FormattedMessage id="REQUEST_VENDOR_RELEASE" />
                    <SwitchControlled
                      name="willCall"
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                      testId="will-call-switch"
                    />
                  </SwitchGroup>
                </If>
                <If
                  isTrue={
                    !watch("willCall") &&
                    orderTypeConfig?.features.includeVendorStocking
                  }
                >
                  <SwitchGroup>
                    <FormattedMessage id="REQUEST_VENDOR_STOCKING" />
                    <SwitchControlled
                      name="vendorStocking"
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                      testId="vendor-stocking-switch"
                    />
                  </SwitchGroup>
                </If>
              </GrayContainer>
              <If
                isTrue={isAuthorized(
                  selectedLocation?.permissions.reserveRelease,
                )}
              >
                <SwitchGroup>
                  <FormattedMessage
                    id={
                      poNumbering === PoNumberingMode.Never
                        ? "RESERVE_PO_BLANK_ORDER_NUMBER"
                        : "RESERVE_PO_NUMBER_FOR_ORDER"
                    }
                  />
                  <If isTrue={poNumbering === PoNumberingMode.Never}>
                    <Tooltip
                      id="subtotal-price"
                      element={
                        <LinkLike onClick={() => null} forwardEvent={false}>
                          <InfoOutlined />
                        </LinkLike>
                      }
                      className="ml-auto"
                    >
                      <FormattedMessage id="RESERVE_ORDER_TOOLTIP" />
                    </Tooltip>
                  </If>
                  {poNumbering === PoNumberingMode.Never ? (
                    <SwitchControlled
                      name="reserveSequenceNumber"
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                      testId="reserver-sequence-number-switch"
                    />
                  ) : (
                    <SwitchControlled
                      name="reservePoNumber"
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                      testId="reserver-po-number-switch"
                    />
                  )}
                </SwitchGroup>
              </If>
              <If isTrue={reservePoNumber}>
                <PoNumberInputControlled
                  setValue={(poNumber) => setValue("poNumber", poNumber)}
                  trigger={() => formProps.trigger("poNumber")}
                  predictedPoNumber={predictedPoNumber}
                  loading={loadingPredictedPoNumber}
                  required={
                    reservePoNumber &&
                    (poNumbering !== PoNumberingMode.Always ||
                      !predictedPoNumber)
                  }
                  disabledWand={!projectId}
                />
                <If isTrue={costCodeOptions.length && !hasPhaseCodes}>
                  <Row>
                    <SelectControlled
                      name="costCodeId"
                      placeholder={intl.$t({ id: "COST_CODE" })}
                      label={intl.$t({ id: "COST_CODE_OPTIONAL" })}
                      options={costCodeOptions}
                      getValue={(o) => o.value}
                      getLabel={(o) => o.label}
                      className="flex-1"
                    />
                    <Tooltip element={<InfoIcon />} id="cost-code-tooltip">
                      <FormattedMessage id="NEW_ORDER_COST_CODE_DESCRIPTION" />
                    </Tooltip>
                  </Row>
                </If>
                <If isTrue={zoneOptions.length}>
                  <Row>
                    <SelectControlled
                      name="zoneId"
                      placeholder={intl.$t({ id: "ZONE" })}
                      label={intl.$t({ id: "ZONE_OPTIONAL" })}
                      options={zoneOptions}
                      getValue={(o) => o.value}
                      getLabel={(o) => o.label}
                      className="flex-1"
                    />
                  </Row>
                </If>
              </If>
              <If isTrue={reserveSequenceNumber}>
                <PoPredictedNumber
                  predictedNumber={
                    predictedReleaseSequenceData?.viewer?.org
                      .predictedReleaseSequenceNumber
                  }
                />
              </If>
            </If>
          </NewDeliveryFormContainer>
        </If>
        <If isTrue={view === NewOrderType.ImportQuote}>
          <NewDeliveryFormContainer>
            <If isTrue={locations && locations.length !== 1 && !project?.id}>
              <LocationSelectorControlled permission="createQuoteDocument" />
            </If>
            <QuoteDocumentImport onChange={setAsset} />
          </NewDeliveryFormContainer>
        </If>
        <If isTrue={view === NewOrderType.ImportNote}>
          <NewDeliveryFormContainer>
            <If isTrue={locations && locations.length !== 1 && !project?.id}>
              <LocationSelectorControlled permission="createQuoteDocument" />
            </If>
            <NoteDocumentImport onChange={setAsset} />
          </NewDeliveryFormContainer>
        </If>
      </FormProvider>
    </OverlayPanel>
  );
};

export const NewDeliveryForm: FC<Props> = (props) => {
  return (
    <BuyoutsByProjectProvider>
      <QuoteDocumentProvider>
        <NoteDocumentProvider>
          <NewDeliveryFormWithProvider {...props} />
        </NoteDocumentProvider>
      </QuoteDocumentProvider>
    </BuyoutsByProjectProvider>
  );
};

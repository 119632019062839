import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { formatPhone } from "./formatPhone";

type Props = {
  value: string;
  phoneExtension?: string | null;
};

export const PhoneView: FC<Props> = ({ value, phoneExtension }) => {
  const formattedPhone = useMemo(() => formatPhone(value), [value]);
  const intl = useIntl();

  return (
    <>{`${formattedPhone}${phoneExtension ? ` ${intl.$t({ id: "PHONE_EXTENSION" }, { phoneExtension })}` : ""}`}</>
  );
};

import { InvoiceIssues } from "@/common/components/invoices/invoice-issue/InvoiceIssues";
import { InvoiceDocumentType } from "@/common/components/invoices/invoice-issue/common/InvoiceIssue";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";
import { InvoiceSelectModeHeader } from "../../invoice-select-mode-header/InvoiceSelectModeHeader";
import { InvoiceHeaderOnlyTotals } from "../common/invoice-header-only-footer/InvoiceHeaderOnlyTotals";
import { InvoiceHeaderOnlyEditableHeader } from "./invoice-header-only-header/InvoiceHeaderOnlyEditableHeader";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const HeaderGroup = tw.div`flex w-full place-items-center gap-1 mb-2`;

export const InvoiceHeaderOnlyEditable: FC = () => {
  const { invoice } = useInvoiceVerification();
  return (
    <Container>
      <HeaderGroup>
        <InvoiceSelectModeHeader disabled={invoice?.headerOnly} />
      </HeaderGroup>
      <InvoiceHeaderOnlyEditableHeader />
      <InvoiceHeaderOnlyTotals />
      <InvoiceIssues
        invoice={invoice}
        documentType={InvoiceDocumentType.INVOICE}
      />
    </Container>
  );
};

import { ListSelectAll } from "@/common/components/list-select-all/ListSelectAll";
import { useIntl } from "react-intl";
import { RELEASES_TO_CSV_EXPORT_LIMIT } from "../../hooks/useReleasesToCsvExport";
import { useDeliveries } from "../../providers/DeliveriesProvider";
import { useReleaseSequence } from "../../providers/ReleaseSequenceProvider";

export const ReleasesSelectAll = () => {
  const intl = useIntl();
  const { totalCount } = useDeliveries();
  const { selectedReleases, allReleasesSelected, selectAll, clearAll } =
    useReleaseSequence();

  if (totalCount > RELEASES_TO_CSV_EXPORT_LIMIT) {
    return null;
  }

  return (
    <ListSelectAll
      allItemsSelected={allReleasesSelected}
      totalCount={totalCount}
      selectedCount={selectedReleases.length}
      itemNamePlural={intl.$t({ id: "ORDERS" })}
      itemNameSingular={intl.$t({ id: "ORDER" })}
      selectAll={selectAll}
      clearAll={clearAll}
    />
  );
};

import { InputBaseComponentProps } from "@mui/material";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { GroupRenderer } from "../select/components/renderer/GroupRenderer";
import { SelectControlled } from "../select/components/single/SelectControlled";
import { WarehouseSelectorCustomRenderer } from "./renderer/WarehouseSelectorCustomRenderer";
import { WarehouseSelectorCustomRendererWithIcon } from "./renderer/WarehouseSelectorCustomRendererWithIcon";
import {
  useWarehouseOptions,
  WarehouseOptionsProps,
} from "./useWarehouseOptions";

type Props = {
  name: string;
  disabledOptionIds?: string[];
  readonly?: boolean;
  inputProps?: InputBaseComponentProps | undefined;
  disableClearable?: boolean;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
  includeDetails?: boolean;
  grouped?: boolean;
} & WarehouseOptionsProps;

export const WarehouseSelectorControlled: FC<Props> = ({
  name,
  projectAddress,
  existingWarehouseId,
  includeWarehouseInName,
  disabledOptionIds,
  readonly = false,
  inputProps,
  disableClearable,
  placeholder,
  required = true,
  loading = false,
  includeDetails = false,
  grouped = true,
}) => {
  const intl = useIntl();
  const { warehouseOptions } = useWarehouseOptions({
    projectAddress,
    existingWarehouseId,
    includeWarehouseInName,
  });
  const { watch } = useFormContext();
  const warehouseId = watch(name);
  const defaultOptions = useMemo(
    () =>
      warehouseOptions.filter(
        (option) => !disabledOptionIds?.includes(option.id),
      ),
    [warehouseOptions, disabledOptionIds],
  );

  const selectedWarehouse = useMemo(
    () => warehouseOptions.find((warehouse) => warehouse.id === warehouseId),
    [warehouseId, warehouseOptions],
  );

  return (
    <SelectControlled
      name={name}
      options={defaultOptions}
      placeholder={placeholder ?? intl.$t({ id: "DELIVER_TO" })}
      inputProps={inputProps}
      getLabel={(option) => option.name}
      getValue={(option) => option.id}
      required={required}
      rules={{ required }}
      disabled={readonly}
      disableClearable={disableClearable}
      loading={loading}
      selectedOptionCustomView={
        selectedWarehouse && (
          <WarehouseSelectorCustomRendererWithIcon item={selectedWarehouse} />
        )
      }
      customRender={(item) =>
        WarehouseSelectorCustomRenderer(item, {
          includeDetails,
        })
      }
      groupBy={grouped ? (option) => option.type as string : undefined}
      renderGroup={GroupRenderer}
    />
  );
};

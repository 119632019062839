import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { usePriceCalculation } from "../../../../../../common/hooks/usePriceCalculation";

const Container = tw.div`
  grid w-full text-center text-2xs
`;

const ValueWithTax = tw.div`
  w-full text-center text-2xs text-gray-600
`;

type Props = {
  items: ExpandedReleaseItem[];
  weightedAmount?: number;
};

export const ReleaseItemExtPriceGroup: FC<Props> = ({
  items,
  weightedAmount,
}) => {
  const { formatCurrency } = useFormatNumberToCurrency();
  const { calcExtPrice } = usePriceCalculation();

  const total = useMemo(() => {
    return items
      .filter((item) => item.isIncluded)
      .reduce(
        (acc, item) =>
          acc.add(calcExtPrice(item.quantityDecimal, item.unitPrice)),
        new DecimalSafe(0),
      );
  }, [calcExtPrice, items]);

  const grossValue = useMemo(
    () => new DecimalSafe(total).add(weightedAmount || 0),
    [total, weightedAmount],
  );

  return (
    <Container>
      <Price price={total} minimumFractionDigits={2} />
      <If isTrue={!grossValue.eq(total)}>
        <FormattedMessage
          id="VALUE_WITH_TAX_AND_FEES"
          tagName={ValueWithTax}
          values={{ value: formatCurrency(grossValue.toNumber()) }}
        />
      </If>
    </Container>
  );
};

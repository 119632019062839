import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";

import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../../value-currency/hooks/useFormatNumberToCurrency";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { applyClasses } from "../utils/applyClasses";
import { applyTooltip } from "../utils/applyTooltip";
import { isValueEmpty } from "../utils/isValueEmpty";

export const useExtPriceRenderer = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { getMetadata } = useColumnMapper();
  const baseRenderer = useBaseRenderer();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      if (isValueEmpty(value)) {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
        return;
      }

      const formattedValue = formatCurrency(value);
      baseRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );
      const meta = getMetadata(row);
      if (
        meta?.extPrice &&
        value &&
        Number(value).toFixed(2) === Number(meta.extPrice).toFixed(2)
      ) {
        applyClasses(td, "bg-green-100 text-green-800");
        applyTooltip(
          td,
          intl.$t({ id: "COPIED_PRICE_TOOLTIP" }, { price: formattedValue }),
        );
      }
    },
    [formatCurrency, getMetadata, intl, baseRenderer],
  );

  return renderer;
};

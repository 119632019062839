import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import {
  ReleaseDocument,
  ReleaseQuery,
  useReleasePoLinkLazyQuery,
} from "@/generated/graphql";
import { useCallback, useEffect, useRef } from "react";
import { ReleaseExportStatusEnum } from "../types/ReleaseExportStatusEnum";

const POLL_INTERVAL = 10000;

export const useRefetchReleasePoLink = (opts?: {
  releaseId: string | null | undefined;
  status?: ReleaseExportStatusEnum;
}) => {
  const { setError } = useGlobalError();

  const timerId = useRef<NodeJS.Timeout>();
  const [fetchReleasePOLink] = useReleasePoLinkLazyQuery({
    fetchPolicy: "network-only",
  });
  const client = useApolloClientStore((state) => state.client);

  const refetchReleasePOLink = useCallback(
    async (releaseId: string | null | undefined) => {
      try {
        if (!releaseId) {
          return;
        }
        const { data, error } = await fetchReleasePOLink({
          variables: {
            id: releaseId,
          },
        });
        if (data && client && !error) {
          client.cache.updateQuery(
            {
              query: ReleaseDocument,
              variables: {
                id: releaseId,
              },
            },
            (cacheData: ReleaseQuery | null) => {
              if (!cacheData?.release) {
                return cacheData;
              }
              return {
                ...cacheData,
                release: {
                  ...cacheData.release,
                  poLink: data.release?.poLink,
                },
              };
            },
          );
        }
        if (error) {
          setError(error);
        }
      } catch (error) {
        setError(error);
      }
    },
    [client, fetchReleasePOLink, setError],
  );

  useEffect(() => {
    if (opts?.status === ReleaseExportStatusEnum.Syncing) {
      timerId.current = setInterval(() => {
        refetchReleasePOLink(opts?.releaseId);
      }, POLL_INTERVAL);
    } else {
      clearInterval(timerId.current);
    }
    return () => {
      clearInterval(timerId.current);
    };
  }, [refetchReleasePOLink, opts?.releaseId, opts?.status]);

  return { refetchReleasePOLink };
};

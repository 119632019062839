import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { ProductName } from "@/common/components/product/ProductName";
import { RfqProductLabel } from "@/common/components/rfq-product-view/RfqProductLabel";
import { isEmptyString } from "@/common/utils/validationUtils";
import { ManufacturerFieldsFragment, OrgCatalogSku } from "@/generated/graphql";
import { Circle } from "@mui/icons-material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { CountType } from "../circle-counter/CountType";
import { If } from "../if/If";
import { ProductBrand } from "../product/ProductBrand";
import { Tooltip } from "../tooltip/Tooltip";
import { MaterialProductBrand } from "./MaterialProductBrand";
import { MaterialType } from "./MaterialType";
import { MaterialViewActions } from "./MaterialViewActions";
import { isOrgCatalogSku } from "./utils";

type ExtraDetailsPosition = "right" | "bottom";

type Props = {
  material: MaterialType;
  description?: string;
  updateDescription?: (description: string) => void;
  readonly?: boolean;
  label?: string;
  labelClassName?: string;
  labelTooltip?: React.ReactNode;
  nowrap?: boolean;
  customCounter?: React.ReactNode;
  includeCounter?: boolean;
  className?: string;
  displayBrand?: boolean;
  manufacturer?: ManufacturerFieldsFragment | null;
  count?: CountType;
  editMode?: boolean;
  immediateUpdate?: boolean;
  hideActions?: boolean;
  showOutsideOfEstimatesInfo?: boolean;
  extraDetails?: React.ReactNode;
  extraDetailsPosition?: "right" | "bottom";
  bottomExtraDetails?: React.ReactNode;
  isLocked?: boolean;
  counterInnerClassName?: string;
  includeMaterialInfo?: boolean;
};

const OrderProductViewContainer = tw.div`
  flex items-center justify-between w-full gap-0 lg:gap-2
`;

const NameAndBrandContainer = tw.div`
  w-full self-center text-xs md:text-sm flex-1 flex items-center
`;

const BrandContainer = tw.div<{ $isEditMode: boolean }>`
  flex flex-col items-left text-left relative pr-2 w-full overflow-hidden
  ${({ $isEditMode }: { $isEditMode: boolean }) =>
    $isEditMode ? "w-full" : "w-fit"}
`;

const PartNumberContainer = tw.div`flex flex-row items-center font-light text-gray-600`;

const BrandAndLabelContainer = tw.div`flex flex-row items-center`;

const RfqProductNameContainer = tw.div`flex flex-row w-full items-center gap-2`;

const RfqProductNameStyled = tw(ProductName)`
  line-clamp-2
  ${({ $nowrap }: { $nowrap: boolean }) =>
    $nowrap ? "whitespace-normal lg:whitespace-nowrap" : ""}
  ${({ readonly }: { readonly?: boolean }) => (readonly ? "w-fit" : "w-full")}
`;

const CircleCounterStyled = tw(CircleCounter)`
  print:hidden mx-0 justify-center
`;

const Dot = tw(Circle)`
  text-2xs text-blue-500 mr-1
`;

const Container = tw.div`flex px-2
${({
  $extraDetailsPosition,
}: {
  $extraDetailsPosition: ExtraDetailsPosition;
}) =>
  $extraDetailsPosition === "right"
    ? "flex-row items-center justify-center"
    : "flex-col"}
`;

export const MaterialView: FC<Props> = ({
  material,
  includeCounter = true,
  counterInnerClassName,
  customCounter,
  description,
  readonly = true,
  displayBrand = true,
  manufacturer,
  label,
  labelClassName,
  labelTooltip,
  className,
  updateDescription,
  nowrap = false,
  count,
  editMode = false,
  immediateUpdate = false,
  hideActions = false,
  showOutsideOfEstimatesInfo = false,
  extraDetails,
  isLocked = false,
  extraDetailsPosition = "right",
  bottomExtraDetails,
  includeMaterialInfo = true,
}) => {
  const [isEditMode, setEditMode] = useState<boolean>(editMode);
  const [name, setName] = useState<string>(description || material.name || "");
  const resetName = () => {
    setName(description || material.name || "");
    setEditMode(false);
  };
  useEffect(() => {
    setName(description || material.name || "");
  }, [description, material.name]);

  useEffect(() => {
    setEditMode(editMode);
  }, [editMode]);

  const updateItemHandler = useCallback(() => {
    if (updateDescription) {
      updateDescription(name);
    }
  }, [name, updateDescription]);

  const updateName = useCallback(
    (name: string) => {
      setName(name);
      if (immediateUpdate && updateDescription) {
        updateDescription(name);
      }
    },
    [immediateUpdate, updateDescription],
  );

  return (
    <OrderProductViewContainer className={className}>
      <NameAndBrandContainer>
        <If isTrue={includeCounter}>
          <CircleCounterStyled
            count={count}
            innerClassName={counterInnerClassName}
          />
        </If>
        <Container $extraDetailsPosition={extraDetailsPosition}>
          <BrandContainer $isEditMode={!hideActions && !readonly}>
            <If isTrue={displayBrand}>
              <BrandAndLabelContainer>
                <If isTrue={!manufacturer}>
                  <MaterialProductBrand
                    material={material}
                    includeInfo={includeMaterialInfo}
                  />
                </If>
                <If isTrue={manufacturer}>
                  <ProductBrand brand={manufacturer?.name} />
                </If>
                <RfqProductLabel
                  label={label}
                  className={labelClassName}
                  tooltip={labelTooltip}
                />
              </BrandAndLabelContainer>
            </If>
            <RfqProductNameContainer>
              <RfqProductNameStyled
                readonly={readonly || !isEditMode}
                name={name}
                setName={updateName}
                resetName={resetName}
                $nowrap={nowrap}
                readonlyClassName={`w-fit text-xs md:text-sm ${
                  (readonly && !isOrgCatalogSku(material)) ?? "py-0"
                }`}
              />
            </RfqProductNameContainer>
            {bottomExtraDetails}
          </BrandContainer>
          <If isTrue={(material as OrgCatalogSku).customPartNumber}>
            <PartNumberContainer>
              ({(material as OrgCatalogSku).customPartNumber})
            </PartNumberContainer>
          </If>

          <If isTrue={showOutsideOfEstimatesInfo}>
            <Tooltip id={`${material.id}-key`} element={<Dot />}>
              <FormattedMessage id="OUTSIDE_OF_ESTIMATES_NOTE" />
            </Tooltip>
          </If>
          <If isTrue={!hideActions}>
            <MaterialViewActions
              isLocked={isLocked}
              error={isEmptyString(name)}
              readonly={readonly}
              isEditMode={isEditMode}
              setIsEditMode={setEditMode}
              onClose={resetName}
              updateItem={updateItemHandler}
              extraDetails={extraDetails}
            />
          </If>
          {customCounter}
        </Container>
      </NameAndBrandContainer>
    </OrderProductViewContainer>
  );
};

import { useSourceSystemLogo } from "@/common/hooks/integrations/source-system-config/useSourceSystemLogo";
import {
  InsuranceInfo,
  useSupplierInsurance,
} from "@/common/hooks/useSupplierInsurance";
import {
  MdFixedContainer,
  XsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { DateView } from "@/common/utils/dates/DateView";
import { IntegrationLogo } from "@/contractor/pages/admin/integrations/components/form/components/IntegrationLogo";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { Refresh } from "@mui/icons-material";
import { useMemo } from "react";
import { BsCheck, BsExclamation } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { Address } from "../address/Address";
import { PrimaryButton } from "../button/PrimaryButton";
import { GridTable } from "../grid-table/GridTable";
import { GridCol } from "../grid-table/types/GridCol";
import { If } from "../if/If";
import { ListRenderer } from "../list-renderer/ListRenderer";
import { OrgLogo } from "../org-logo/OrgLogo";
import { Price } from "../price/Price";
import { useVendors } from "../vendors/hooks/useVendors";
import {
  WizardModal,
  WizardModalMode,
  WizardModalPage,
} from "../wizard-modal/WizardModal";
import { SupplierContacts } from "./SupplierContacts";

type Props = {
  modalOpened: boolean;
  closeModal: () => void;
  vendorId: string;
};

const HeaderContainer = tw.div`flex items-center gap-4 w-full mb-4`;
const VendorName = tw.span`text-lg font-medium`;
const FullWidthColumn = tw.div`flex-1`;
const DataWrapper = tw.div`text-sm font-medium flex items-center`;
const Delimiter = tw.div`border border-gray-300 border-dashed self-stretch`;
const CheckmarkCircleGreen = tw(
  BsCheck,
)`bg-green-600 text-xl rounded-full fill-white`;
const WarningIcon = tw(BsExclamation)`text-xl rounded-full m-0 fill-white`;

export const SupplierInsuranceModal = ({
  modalOpened,
  closeModal,
  vendorId,
}: Props) => {
  const intl = useIntl();
  const { refetch, loading } = useVendors();
  const { connectedSourceSystem } = useOrgSettings();
  const { sourceSystemLogo } = useSourceSystemLogo(connectedSourceSystem);
  const { vendor, insurances } = useSupplierInsurance(vendorId);

  const configuration: Array<GridCol<InsuranceInfo>> = useMemo(
    () => [
      {
        wrapper: XsFixedContainer,
        header: "",
        item: ({ item }) =>
          item.expired ? (
            <WarningIcon
              className={item.required ? "bg-red-500" : "bg-orange-500"}
            />
          ) : (
            <CheckmarkCircleGreen />
          ),
        position: "center",
      },
      {
        wrapper: FullWidthColumn,
        header: <FormattedMessage id="INSURANCE_TYPE" />,
        item: ({ item }) => (
          <DataWrapper
            className={`${item.expired && item.required ? "text-red-500" : ""}`}
          >
            {item.type}
            <If isTrue={!item.required}>
              <DataWrapper className="ml-2 rounded border border-gray-500 px-1 py-0.5 text-xs">
                <FormattedMessage id="NOT_REQUIRED" />
              </DataWrapper>
            </If>
          </DataWrapper>
        ),
      },
      {
        wrapper: FullWidthColumn,
        header: <FormattedMessage id="COMPANY" />,
        item: ({ item }) => <DataWrapper>{item.company}</DataWrapper>,
      },
      {
        wrapper: FullWidthColumn,
        header: <FormattedMessage id="POLICY_NUMBER" />,
        item: ({ item }) => <DataWrapper>{item.policyNumber}</DataWrapper>,
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="EFFECTIVE_DATE" />,
        item: ({ item }) => (
          <DataWrapper>
            <DateView date={item.startDate} />
          </DataWrapper>
        ),
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="EXPIRATION_DATE" />,
        item: ({ item }) => (
          <DataWrapper className={`${item.expired ? "text-red-500" : ""}`}>
            <DateView date={item.endDate} />
          </DataWrapper>
        ),
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="LIMIT" />,
        item: ({ item }) => (
          <DataWrapper>
            <Price price={item.limit} />
          </DataWrapper>
        ),
        position: "end",
      },
    ],
    [],
  );

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: null,
        pages: [
          {
            component: (
              <>
                <HeaderContainer>
                  <OrgLogo
                    logoImageUrl={vendor?.sellerOrgLocation.org.photoUrl}
                    width={40}
                    name={vendor?.sellerOrgLocation.org.name || ""}
                  />
                  <VendorName>{vendor?.sellerOrgLocation.org.name}</VendorName>
                  <Address
                    address={vendor?.sellerOrgLocation.address}
                    className="text-sm"
                  />
                  <Delimiter />
                  <SupplierContacts contacts={vendor?.contacts} />
                  <PrimaryButton
                    $small
                    className="h-5 text-xs font-normal"
                    loading={loading}
                    onClick={() => refetch()}
                  >
                    <Refresh className="h-5 w-5" />
                    {intl.$t({ id: "REFRESH" })}
                  </PrimaryButton>
                  <IntegrationLogo
                    url={sourceSystemLogo}
                    className="mr-8 h-14 w-30"
                  />
                </HeaderContainer>
                <ListRenderer>
                  <GridTable
                    configuration={{
                      columns: configuration,
                    }}
                    items={insurances}
                    loading={loading}
                  />
                </ListRenderer>
              </>
            ),
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "w-48",
              },
            ],
          },
        ],
      },
    ],
    [
      closeModal,
      intl,
      sourceSystemLogo,
      vendor,
      refetch,
      loading,
      configuration,
      insurances,
    ],
  );

  if (!vendor) {
    return null;
  }

  return (
    <WizardModal
      mode={WizardModalMode.SINGLE_PAGE}
      opened={modalOpened}
      pages={pages}
      onClose={closeModal}
    />
  );
};

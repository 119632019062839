import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  LgFixedContainer,
  SmdFixedContainer,
  SmFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { TransactionHistoryItemNote } from "../../../../common/components/transaction-history/components/common/transaction-history-item-note/TransactionHistoryItemNote";
import { TransactionDate } from "../../../../common/components/transaction-history/components/transaction-history-steps/transaction-history-stock/components/transaction-history-list/transaction-date/TransactionDate";
import { TransactionOriginFrom } from "../../../../common/components/transaction-origin/components/line-item/transaction-origin-from/TransactionOriginFrom";
import { TransactionOriginTo } from "../../../../common/components/transaction-origin/components/line-item/transaction-origin-to/TransactionOriginTo";
import { TransactionRelease } from "../../../../common/components/transaction-release/TransactionRelease";
import { TransactionSymbol } from "../../../../common/components/transaction-symbol/TransactionSymbol";
import { TransactionIdentifierWithDate } from "./components/transaction-identifier-with-date/TransactionIdentifierWithDate";
import { TransactionValue } from "./components/transaction-value/TransactionValue";
import { InventoryItem } from "./InventoryLedgerList.styles";

export const useInventoryLedgerConfiguration = () => {
  const configuration: Array<
    GridCol<InventoryTransactionSummaryFieldsFragment>
  > = useMemo(
    () => [
      {
        wrapper: XxsFixedContainer,
        item: ({ item }) => <TransactionSymbol item={item} />,
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        item: ({ item }) => <TransactionIdentifierWithDate item={item} />,
        position: "center",
        header: (
          <FormattedMessage
            id="TRANSACTION_ID_WITH_DATE"
            values={{ br: <br key="transaction-break" /> }}
          />
        ),
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => <TransactionDate item={item} />,
        position: "center",
        header: <FormattedMessage id="TRANSACTION_DATE" />,
      },
      {
        wrapper: LgFixedContainer,
        item: ({ item }) => <TransactionOriginFrom item={item} />,
        header: <FormattedMessage id="FROM" />,
      },
      {
        wrapper: LgFixedContainer,
        item: ({ item }) => <TransactionOriginTo item={item} />,
        header: <FormattedMessage id="TO" />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => (
          <TransactionRelease release={item.deliverySlip?.release} />
        ),
        position: "center",
        header: <FormattedMessage id="ORDER" />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => <InventoryItem>{item.items.length}</InventoryItem>,
        position: "center",
        header: <FormattedMessage id="ITEMS" />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => <TransactionValue item={item} />,
        position: "center",
        header: <FormattedMessage id="VALUE" />,
      },
      {
        wrapper: LgFixedContainer,
        item: ({ item }) => (
          <TransactionHistoryItemNote
            className="line-clamp-2"
            notes={item.instructions?.text}
          />
        ),
        header: <FormattedMessage id="GENERAL_NOTES" />,
      },
    ],
    [],
  );

  return configuration;
};

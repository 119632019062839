import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Item = tw.div`mx-10`;
export enum ImportItemsType {
  ImportFromProject = "import-from-project",
  ImportFromEstimates = "import-from-estimates",
}

export const ImportItemsTabs = [
  {
    label: (
      <FormattedMessage id="IMPORT_FROM_EXISTING_PROJECT" tagName={Item} />
    ),
    viewState: ImportItemsType.ImportFromProject,
  },
  {
    label: (
      <FormattedMessage id="IMPORT_FROM_PROJECT_ESTIMATES" tagName={Item} />
    ),
    viewState: ImportItemsType.ImportFromEstimates,
  },
];

import { ApproverTemplateVariablesEnum } from "../../enums/ApproverTemplateVariables";
import { BuyoutTemplateVariablesEnum } from "../../enums/BuyoutTemplateVariables";
import { ExternalVendorTemplateVariablesEnum } from "../../enums/ExternalVendorTemplateVariables";
import { InvoiceTemplateVariablesEnum } from "../../enums/InvoiceTemplateVariables";
import { ProjectTemplateVariablesEnum } from "../../enums/ProjectTemplateVariables";
import { ReleaseTemplateVariablesEnum } from "../../enums/ReleaseTemplateVariables";
import { TemplateBaseVarEnum } from "../../enums/TemplateBaseVars.enum";

const getEnumKey = (enumObj: Record<string, string>, value: string) =>
  Object.keys(enumObj).find((key) => enumObj[key] === value) ?? "";

export const getCustomTemplateDefaults = () => ({
  [TemplateBaseVarEnum.PROJECT]: [
    {
      name: getEnumKey(
        ProjectTemplateVariablesEnum,
        ProjectTemplateVariablesEnum.Name,
      ),
      value: ProjectTemplateVariablesEnum.Name,
    },
    {
      name: getEnumKey(
        ProjectTemplateVariablesEnum,
        ProjectTemplateVariablesEnum.JobNumber,
      ),
      value: ProjectTemplateVariablesEnum.JobNumber,
    },
  ],
  [TemplateBaseVarEnum.RELEASE]: [
    {
      name: getEnumKey(
        ReleaseTemplateVariablesEnum,
        ReleaseTemplateVariablesEnum.Description,
      ),
      value: ReleaseTemplateVariablesEnum.Description,
    },
    {
      name: getEnumKey(
        ReleaseTemplateVariablesEnum,
        ReleaseTemplateVariablesEnum.SequenceNumber,
      ),
      value: ReleaseTemplateVariablesEnum.SequenceNumber,
    },
    {
      name: getEnumKey(
        ReleaseTemplateVariablesEnum,
        ReleaseTemplateVariablesEnum.PoNumber,
      ),
      value: ReleaseTemplateVariablesEnum.PoNumber,
    },
    {
      name: getEnumKey(
        ReleaseTemplateVariablesEnum,
        ReleaseTemplateVariablesEnum.Notes,
      ),
      value: ReleaseTemplateVariablesEnum.Notes,
    },
  ],
  [TemplateBaseVarEnum.BUYOUT]: [
    {
      name: getEnumKey(
        BuyoutTemplateVariablesEnum,
        BuyoutTemplateVariablesEnum.Description,
      ),
      value: BuyoutTemplateVariablesEnum.Description,
    },
    {
      name: getEnumKey(
        BuyoutTemplateVariablesEnum,
        BuyoutTemplateVariablesEnum.ClientIdentifier,
      ),
      value: BuyoutTemplateVariablesEnum.ClientIdentifier,
    },
  ],
  [TemplateBaseVarEnum.INVOICE]: [
    {
      name: getEnumKey(
        InvoiceTemplateVariablesEnum,
        InvoiceTemplateVariablesEnum.Description,
      ),
      value: InvoiceTemplateVariablesEnum.Description,
    },
    {
      name: getEnumKey(
        InvoiceTemplateVariablesEnum,
        InvoiceTemplateVariablesEnum.Number,
      ),
      value: InvoiceTemplateVariablesEnum.Number,
    },
    {
      name: getEnumKey(
        InvoiceTemplateVariablesEnum,
        InvoiceTemplateVariablesEnum.PoNumber,
      ),
      value: InvoiceTemplateVariablesEnum.PoNumber,
    },
  ],
  [TemplateBaseVarEnum.EXTERNAL_VENDOR]: [
    {
      name: getEnumKey(
        ExternalVendorTemplateVariablesEnum,
        ExternalVendorTemplateVariablesEnum.Name,
      ),
      value: ExternalVendorTemplateVariablesEnum.Name,
    },
    {
      name: getEnumKey(
        ExternalVendorTemplateVariablesEnum,
        ExternalVendorTemplateVariablesEnum.AlternateName,
      ),
      value: ExternalVendorTemplateVariablesEnum.AlternateName,
    },
    {
      name: getEnumKey(
        ExternalVendorTemplateVariablesEnum,
        ExternalVendorTemplateVariablesEnum.PaymentTermsDescription,
      ),
      value: ExternalVendorTemplateVariablesEnum.PaymentTermsDescription,
    },
  ],
  [TemplateBaseVarEnum.APPROVER]: [
    {
      name: getEnumKey(
        ApproverTemplateVariablesEnum,
        ApproverTemplateVariablesEnum.Email,
      ),
      value: ApproverTemplateVariablesEnum.Email,
    },
    {
      name: getEnumKey(
        ApproverTemplateVariablesEnum,
        ApproverTemplateVariablesEnum.FirstName,
      ),
      value: ApproverTemplateVariablesEnum.FirstName,
    },
    {
      name: getEnumKey(
        ApproverTemplateVariablesEnum,
        ApproverTemplateVariablesEnum.LastName,
      ),
      value: ApproverTemplateVariablesEnum.LastName,
    },
  ],
});

import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { FC } from "react";
import { useIntl } from "react-intl";

type Props = {
  value: number;
  onBlur: (value: string | null) => void;
  helperText?: string | React.JSX.Element;
};

export const SalesTaxAmountInput: FC<Props> = ({
  value,
  onBlur,
  helperText,
}) => {
  const intl = useIntl();

  return (
    <PricePicker
      testId="tax"
      value={value}
      onBlur={onBlur}
      decimals={2}
      label={intl.$t({ id: "TAX" })}
      className="w-full text-right"
      helperText={helperText}
    />
  );
};

import { SuccessIcon } from "@/common/components/dialog-icons/SuccessIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { AssignedColumn } from "@/common/components/upload-file-mapper/FileMapper";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  CreateCustomFileFormatInput,
  CustomFileFormat,
  CustomFileFormatsDocument,
  CustomIntegrationInput,
  FieldPreview,
  FileType,
  IntegrationType,
  ProjectDocument,
  useCreateCustomFileFormatMutation,
  useCustomFileFormatsQuery,
  useDeleteCustomFileFormatMutation,
  useFieldsPreviewQuery,
  useUpdateEstimatesWithFileMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionStringPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import { ApolloError } from "@apollo/client";
import { AutoFixHigh } from "@mui/icons-material";
import { FC, createContext, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useProjectItemsZones } from "../../../providers/ProjectItemsZonesProvider";
import { FILE_MAPPER_COLUMNS } from "../material-file-mapper/FileMapperColumns";

type UploadFileInput = {
  projectId: string;
  fileFormat: IntegrationType;
  file: File;
  clearAllEstimatedItems: boolean;
  importZones: boolean;
  tags: string[];
  custom?: CustomIntegrationInput;
  importManufacturers: boolean;
  zoneId?: string;
};

type UploadFileResult = {
  success: boolean;
  hasErrors?: boolean;
};

type ProviderContextType = {
  uploadFile: (input: UploadFileInput) => Promise<UploadFileResult | undefined>;
  isUploading: boolean;
  setFilePreview: (file: File) => void;
  setFieldPreviewType: (type: FileType) => void;
  fieldPreviews: FieldPreview[];
  loadingPreview: boolean;
  previewError: ApolloError | undefined;
  assignedColumns: AssignedColumn[];
  setAssignedColumns: (assignedColumns: AssignedColumn[]) => void;
  createCustomFileFormat: (
    input: CreateCustomFileFormatInput,
  ) => Promise<string | null>;
  customFileFormats: CustomFileFormat[];
  deleteCustomFileFormat: (id: string) => Promise<string | null>;
};

const ProviderContext = createContext<ProviderContextType>({
  uploadFile: NoFunctionUndefinedPromise,
  isUploading: false,
  setFilePreview: NoFunction,
  setFieldPreviewType: NoFunction,
  fieldPreviews: [],
  loadingPreview: false,
  previewError: undefined,
  assignedColumns: [],
  setAssignedColumns: NoFunctionStringPromise,
  createCustomFileFormat: NoFunctionStringPromise,
  customFileFormats: [],
  deleteCustomFileFormat: NoFunctionStringPromise,
});

export const MaterialFileUploadProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [updateEstimatesWithFile, { loading: isUploading }] =
    useUpdateEstimatesWithFileMutation();

  const [createCustomFileFormatMutation] = useCreateCustomFileFormatMutation();
  const [deleteCustomFileFormatMutation] = useDeleteCustomFileFormatMutation();

  const { setError } = useGlobalError();
  const [filePreview, setFilePreview] = useState<File | undefined>();
  const [fieldPreviewType, setFieldPreviewType] = useState<FileType>(
    FileType.Csv,
  );
  const { openDialog } = useDialog();
  const { setWarningAlert } = useSnackbar();

  const intl = useIntl();
  const [assignedColumns, setAssignedColumns] = useState<AssignedColumn[]>([]);
  const { setGroupedByZones } = useProjectItemsZones();

  const uploadFile = async ({
    projectId,
    fileFormat,
    file,
    tags,
    custom,
    clearAllEstimatedItems,
    importZones,
    importManufacturers,
    zoneId,
  }: UploadFileInput) => {
    try {
      const { data, errors } = await updateEstimatesWithFile({
        variables: {
          input: {
            projectId,
            fileFormat,
            file,
            custom,
            clearAllEstimatedItems,
            importManufacturers,
            importZones,
            tags,
            zoneId,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ProjectDocument,
            variables: { id: projectId || "", excludePhantoms: true },
          },
        ],
      });
      setError(errors);
      data?.updateEstimatesWithFile.errors.forEach((i) =>
        setWarningAlert(
          intl.$t(
            { id: "UPLOAD_MATERIAL_LIST_ITEM_ERROR" },
            { lineNumber: i.lineNumber, error: i.errorMessage },
          ),
        ),
      );

      if (!errors) {
        setGroupedByZones(
          data?.updateEstimatesWithFile.estimatedItems.some(
            (item) => item.zone?.id,
          ) ?? false,
        );
      }
      return {
        success: !errors,
        hasErrors: (data?.updateEstimatesWithFile.errors?.length || 0) > 0,
      };
    } catch (errors) {
      setError(errors);
      return {
        success: false,
      };
    }
  };

  const createCustomFileFormat = async (input: CreateCustomFileFormatInput) => {
    try {
      const { data, errors } = await createCustomFileFormatMutation({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: CustomFileFormatsDocument,
          },
        ],
      });
      setError(errors);
      if (data?.createCustomFileFormat?.id) {
        return data.createCustomFileFormat.id;
      }
    } catch (errors) {
      setError(errors);
    }
    return null;
  };

  const deleteCustomFileFormat = async (id: string) => {
    try {
      const { data, errors } = await deleteCustomFileFormatMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: CustomFileFormatsDocument,
          },
        ],
      });
      setError(errors);
      if (data?.deleteCustomFileFormat?.id) {
        return data.deleteCustomFileFormat.id;
      }
    } catch (errors) {
      setError(errors);
    }
    return null;
  };

  const { data: customFileFormats } = useCustomFileFormatsQuery();

  const {
    data: fieldPreviews,
    loading: loadingPreview,
    error: previewError,
  } = useFieldsPreviewQuery({
    variables: {
      input: {
        fileType: fieldPreviewType,
        file: filePreview,
        records: 10,
      },
    },
    skip: !filePreview,
    onCompleted: (data) => {
      const newColumns = FILE_MAPPER_COLUMNS.map((c) => ({
        value: c.value,
        assigned:
          data?.fieldsPreview?.find((f) => f.hint === c.value)?.name || "",
      }));
      setAssignedColumns(newColumns);
      const hasAssignedColumns = newColumns.some((c) => c.assigned);
      if (hasAssignedColumns) {
        openDialog({
          confirmButtonText: intl.$t({ id: "OK" }),
          icon: <SuccessIcon customIcon={AutoFixHigh} />,
          title: intl.$t({ id: "AUTO_ASSIGNED" }),
          text: intl.$t({ id: "AUTO_ASSIGNED_INFO" }),
        });
      }
    },
    onError(error) {
      setError(error);
    },
    fetchPolicy: "no-cache",
  });

  return (
    <ProviderContext.Provider
      value={{
        uploadFile,
        isUploading,
        setFilePreview,
        setFieldPreviewType,
        fieldPreviews: fieldPreviews?.fieldsPreview || [],
        loadingPreview,
        previewError,
        assignedColumns,
        setAssignedColumns,
        createCustomFileFormat,
        customFileFormats: customFileFormats?.customFileFormats || [],
        deleteCustomFileFormat,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useMaterialFileUpload = (): ProviderContextType =>
  useContext(ProviderContext);

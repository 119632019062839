import { Option } from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useEnabledPoInSourceSystem } from "@/contractor/common/hooks/useEnabledPoInSourceSystem";
import { QueryInvoicesFilter } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useInvoices } from "../../../../providers/InvoicesProvider";
import { InvoicesAdditionalFilter } from "../types/InvoicesAdditionalFilter";

export const useInvoiceAdditionalFilters = () => {
  const intl = useIntl();
  const { filter, setFilter } = useInvoices();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const isInvoiceBatchingSupported = useMemo(
    () => hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceBatching),
    [hasFeatureInConnectedSourceSystem],
  );

  const options = useMemo<Option<QueryInvoicesFilter>[]>(() => {
    return [
      ...(includePoNumbering && isPoEnabledInSourceSystem
        ? [
            {
              id: InvoicesAdditionalFilter.PoExported,
              title: intl.$t({ id: "PO_EXPORTED" }),
              filter: (value: boolean | null) => ({
                poExported: value,
              }),
              value: (currentFilter: QueryInvoicesFilter | undefined) =>
                currentFilter?.poExported,
            },
          ]
        : []),
      ...(isPoEnabledInSourceSystem
        ? [
            {
              id: InvoicesAdditionalFilter.ReceiptPosted,
              title: intl.$t({ id: "RECEIPT_POSTED" }),
              filter: (value: boolean | null) => ({
                receiptPosted: value,
              }),
              value: (currentFilter: QueryInvoicesFilter | undefined) =>
                currentFilter?.receiptPosted,
            },
          ]
        : []),
      ...(isInvoiceBatchingSupported
        ? []
        : [
            {
              id: InvoicesAdditionalFilter.InvoiceExported,
              title: intl.$t({ id: "INVOICE_EXPORTED_FILTER" }),
              filter: (value: boolean | null) => ({
                exported: value,
              }),
              value: (currentFilter: QueryInvoicesFilter | undefined) =>
                currentFilter?.exported,
            },
          ]),
      {
        id: InvoicesAdditionalFilter.InvoiceDownloaded,
        title: intl.$t({ id: "INVOICE_DOWNLOADED" }),
        filter: (value) => ({
          downloaded: value,
        }),
        value: (currentFilter) => currentFilter?.downloaded,
      },
      {
        id: InvoicesAdditionalFilter.OrderAssigned,
        title: intl.$t({ id: "ORDER_ASSIGNED" }),
        filter: (value) => ({
          orderAssigned: value,
        }),
        value: (currentFilter) => currentFilter?.orderAssigned,
      },
      {
        id: InvoicesAdditionalFilter.ClosedProjects,
        title: intl.$t({ id: "INVOICES_FROM_CLOSED_PROJECTS" }),
        filter: (value) => ({
          closedProjects: value,
        }),
        value: (currentFilter) => currentFilter?.closedProjects,
      },
      {
        id: InvoicesAdditionalFilter.Deleted,
        title: intl.$t({ id: "DELETED" }),
        filter: (value) => ({
          archived: value,
        }),
        value: (currentFilter) => currentFilter?.archived,
      },
    ];
  }, [
    includePoNumbering,
    intl,
    isInvoiceBatchingSupported,
    isPoEnabledInSourceSystem,
  ]);

  const setFilterHandler = useCallback(
    (values: string[] | undefined) => {
      setFilter({
        ...filter,
        releaseTypeIds: values,
      });
    },
    [filter, setFilter],
  );

  return {
    options,
    setFilterHandler,
  };
};

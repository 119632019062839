import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  BuyoutStatus,
  Quote,
  QuoteDocumentSummaryFieldsFragment,
  Rfq,
} from "@/generated/graphql";
import { CircularProgress } from "@mui/material";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExpandablePanel } from "../expandable-panel/ExpandablePanel";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";
import { SupportedFormats, UploadAsset } from "../upload-asset/UploadAsset";
import { UploadAssetProvider } from "../upload-asset/UploadAssetProvider";
import { PDF_EXTENSIONS, PDF_MIME_TYPE } from "../upload/FileUploadArea";
import { QuoteFromRfqTile } from "./QuoteFromRfqTile";

type Props = {
  quoteDocument?: QuoteDocumentSummaryFieldsFragment | null;
  quote?:
    | (Pick<Quote, "id"> & {
        rfq: Pick<Rfq, "id" | "clientIdentifier">;
      })
    | null;
  readonly?: boolean;
  customRedirect?: () => void;
  vendorRedirect?: () => void;
  children?: React.ReactNode;
  onQuoteDelete?: () => void;
  quoteDeleting?: boolean;
};

const Container = tw.div`flex items-center gap-2`;

const QuoteDocumentPanelWithProvider: FC<Props> = ({
  readonly,
  quoteDocument,
  quote,
  customRedirect,
  vendorRedirect,
  children,
  onQuoteDelete,
  quoteDeleting = false,
}) => {
  const intl = useIntl();

  const { updateBuyout, buyout } = useContractorBuyout();

  const updateUrls = useCallback(
    async (assets: AssetFieldsFragment[]) => {
      if (!buyout) {
        return;
      }

      await updateBuyout(
        {
          quoteDocumentAssetUrl: assets.length
            ? assets.map((a) => a.url)[0]
            : null,
          buyoutId: buyout?.id,
          clearQuoteDocument: !assets.length,
        },
        buyout?.status === BuyoutStatus.Draft,
      );
    },
    [buyout, updateBuyout],
  );

  if (readonly && !quoteDocument?.asset && !children && !quote) {
    return null;
  }

  return (
    <ExpandablePanel header={intl.$t({ id: "QUOTE_DOCUMENT" })} isOpened={true}>
      {quote ? (
        <>
          <QuoteFromRfqTile quote={quote} vendorRedirect={vendorRedirect} />
        </>
      ) : (
        <Container>
          <If isTrue={children}>{children}</If>
          <If isTrue={!children}>
            <UploadAsset
              onChange={updateUrls}
              customRedirect={customRedirect}
              accept={{
                [PDF_MIME_TYPE]: PDF_EXTENSIONS,
              }}
              limit={1}
              xs
              mode="horizontal"
              className="flex w-auto items-start"
              readonly={readonly}
            >
              <If isTrue={!quoteDocument?.asset}>
                <SupportedFormats>
                  <FormattedMessage id="QUOTE_DOCUMENT_SUPPORTED_FORMATS" />
                </SupportedFormats>
              </If>
            </UploadAsset>
          </If>
          <If isTrue={onQuoteDelete}>
            {quoteDeleting ? (
              <CircularProgress
                size="small"
                className="ml-2 h-5 w-5 text-blue-500"
              />
            ) : (
              <LinkLike
                className="font-normal text-blue-500 hover:text-blue-300"
                onClick={onQuoteDelete}
              >
                {intl.$t({ id: "DELETE" })}
              </LinkLike>
            )}
          </If>
        </Container>
      )}
    </ExpandablePanel>
  );
};

export const QuoteDocumentPanel: FC<Props> = (props) => {
  return (
    <UploadAssetProvider
      initialAssets={props.quoteDocument ? [props.quoteDocument.asset] : []}
      context={AssetContext.QuoteDocument}
    >
      <QuoteDocumentPanelWithProvider {...props} />
    </UploadAssetProvider>
  );
};

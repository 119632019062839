import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { RendererFunctionType } from "@/common/components/spreadsheet-table/renderers/base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "@/common/components/spreadsheet-table/renderers/base-renderer/useBaseRenderer";
import { applyTooltip } from "@/common/components/spreadsheet-table/renderers/utils/applyTooltip";
import { removeTooltip } from "@/common/components/spreadsheet-table/renderers/utils/removeTooltip";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useInventoryItemUomRenderer = () => {
  const intl = useIntl();
  const { inventoryItems } = useInventoryItems();
  const findMaterialByName = useFindMaterialByName();
  const baseRenderer = useBaseRenderer();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      baseRenderer(instance, td, row, col, prop, value, cellProperties);

      if (!value) {
        removeTooltip(td);
        return;
      }

      const materialName =
        instance.getDataAtRow(row)[
          getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)
        ];
      const material = findMaterialByName(materialName);
      const availableUoms = inventoryItems
        .filter((item) => item.orgMaterial.id === material?.id)
        .map((item) => item.uom.pluralDescription);

      const quantity =
        instance.getDataAtRow(row)[
          getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)
        ];

      applyTooltip(
        td,
        intl.$t(
          {
            id:
              Number(quantity) >= 0 || !quantity
                ? "EXISTING_INVENTORY_ITEM_UOMS"
                : "ALLOWED_INVENTORY_ITEM_UOMS",
          },
          {
            uom: value,
            uoms: availableUoms.join(", "),
          },
        ),
      );
    },
    [intl, inventoryItems, findMaterialByName, baseRenderer],
  );

  return renderer;
};

import { SupplierType } from "@/common/components/supplier-picker/types/Supplier";
import { useSupplierOptions } from "@/common/components/supplier-picker/useSupplierOptions";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useRelease } from "../providers/ReleaseProvider";
import { isWarehouseRelease } from "../utils/isWarehouseRelease";

export const useIsWarehouseRelease = () => {
  const { release } = useRelease();
  const formContext = useFormContext<{ vendorId: string }>();
  const vendorId = formContext?.watch?.("vendorId");
  const { findSupplierTypeBySupplierId } = useSupplierOptions({
    includeWarehouses: true,
  });

  const isFromWarehouseRelease = useMemo(() => {
    if (vendorId !== undefined) {
      const supplierType = findSupplierTypeBySupplierId(vendorId);
      return supplierType === SupplierType.WAREHOUSE;
    }
    return isWarehouseRelease(release);
  }, [vendorId, findSupplierTypeBySupplierId, release]);

  return { isWarehouseRelease: isFromWarehouseRelease };
};

import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  namedOperations,
  useArchiveReleaseMutation,
} from "@/generated/graphql";

export const useArchiveRelease = () => {
  const { setError } = useGlobalError();
  const [archiveReleaseMutation, { loading: archiving }] =
    useArchiveReleaseMutation({
      update: (cache) => cleanQuery(cache, namedOperations.Query.Releases),
    });
  const archiveRelease = async (id: string) => {
    try {
      const { errors } = await archiveReleaseMutation({
        variables: {
          id,
        },
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    archiveRelease,
    archiving,
  };
};

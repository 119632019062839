import { DatePickerControlled } from "@/common/components/picker/components/DatePickerControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { FC } from "react";
import { useIntl } from "react-intl";

type Props = ControlledProps & {
  open?: boolean;
  setOpen?: (opened: boolean) => void;
};

export const ProjectJobDatePicker: FC<Props> = (props) => {
  const intl = useIntl();

  return (
    <DatePickerControlled
      {...props}
      className="bg-white"
      label={intl.$t({ id: "PROJECT_START_DATE" })}
    />
  );
};

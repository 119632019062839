import { routes } from "@/config/routes";
import { WithAdminNavigation } from "../Admin";
import { WithAdminOrderTabNavigation } from "./common/WithAdminOrderTabNavigation";
import { InvoiceApproval } from "./pages/invoice-approval/InvoiceApproval";
import { OrderApproval } from "./pages/order-approval/OrderApproval";
import { OrderTypes } from "./pages/order-types/OrderType";
import { ComplianceGroups } from "./pages/compliance-groups/ComplianceGroups";

export const adminOrderRoutes = [
  {
    path: routes.adminOrders,
    element: (
      <WithAdminNavigation>
        <WithAdminOrderTabNavigation />
      </WithAdminNavigation>
    ),
    children: [
      {
        path: "",
        element: <OrderTypes />,
      },
      {
        path: routes.orderApproval,
        element: <OrderApproval />,
      },
      {
        path: routes.invoiceApproval,
        element: <InvoiceApproval />,
      },
      {
        path: routes.complianceGroups,
        element: <ComplianceGroups />,
      },
    ],
  },
];

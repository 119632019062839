import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

export enum TabType {
  Templates = "templates",
  CustomPO = "custom-po-templates",
  CustomInvoice = "custom-invoice-templates",
}

export const useTemplateStrings = () => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState<TabType>(TabType.Templates);
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const tabs = useMemo(() => {
    const tabs = [
      {
        id: TabType.Templates,
        label: intl.$t({ id: "DEFAULT_TEMPLATES" }),
        onClick: () => setActiveTab(TabType.Templates),
      },
    ];

    if (
      hasFeatureInConnectedSourceSystem(IntegrationFeature.CustomTemplatesStep)
    ) {
      tabs.push({
        id: TabType.CustomPO,
        label: intl.$t({ id: "CUSTOM_PO_TEMPLATES" }),
        onClick: () => setActiveTab(TabType.CustomPO),
      });
      tabs.push({
        id: TabType.CustomInvoice,
        label: intl.$t({ id: "CUSTOM_INVOICE_TEMPLATES" }),
        onClick: () => setActiveTab(TabType.CustomInvoice),
      });
    }

    return tabs;
  }, [intl, hasFeatureInConnectedSourceSystem]);

  return { activeTab, tabs };
};

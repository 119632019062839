import {
  PreviewInvoiceTemplateDocument,
  PreviewInvoiceTemplateMutation,
  PreviewInvoiceTemplateMutationVariables,
  PreviewPoTemplateMutation,
  PreviewPoTemplateMutationVariables,
} from "@/generated/graphql";

import { PreviewPoTemplateDocument } from "@/generated/graphql";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { TemplateTypeEnum } from "../../enums/TemplateType.enum";
import { TemplateVariablesEnum } from "../../enums/TemplateVariables.enum";
import { Template } from "../../types/Template";

const poExcludeVariables: string[] = [
  "InvoiceDescription",
  "InvoiceNumber",
  "InvoicePONumber",
  "InvoiceShortURL",
  "ApproverEmail",
  "ApproverName",
];

export const getTemplatePreview = async (
  template: Template,
  defaults: Record<TemplateVariablesEnum, string>,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<string | undefined> => {
  if (template.type === TemplateTypeEnum.PO) {
    const templateDefaults = Object.fromEntries(
      Object.entries(defaults).filter(
        ([key]) => !poExcludeVariables.includes(key),
      ),
    );

    const { data } = await client.mutate<
      PreviewPoTemplateMutation,
      PreviewPoTemplateMutationVariables
    >({
      mutation: PreviewPoTemplateDocument,
      variables: {
        input: {
          Template: template.template,
          ...templateDefaults,
        },
      },
    });

    return data?.previewPoTemplate ?? "";
  }

  if (template.type === TemplateTypeEnum.INVOICE) {
    const { data } = await client.mutate<
      PreviewInvoiceTemplateMutation,
      PreviewInvoiceTemplateMutationVariables
    >({
      mutation: PreviewInvoiceTemplateDocument,
      variables: {
        input: {
          Template: template.template,
          ...defaults,
        },
      },
    });

    return data?.previewInvoiceTemplate ?? "";
  }
};

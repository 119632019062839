import { pascalCaseToUnderscore } from "@/common/utils/stringUtils";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { SimpleStepTitle } from "../../../../../Wizard.styles";
import { Template } from "../../types/Template";
import { TemplateEditor } from "../template-editor/TemplateEditor";
import { TemplatePreview } from "../template-preview/TemplatePreview";

const Container = tw.div`grid grid-flow-row gap-10 mt-2`;
const Row = tw.div`grid`;

type DefaultTemplatesProps = {
  templates: Template[];
  setTemplate: (template: Template) => void;
};

export const DefaultTemplates = ({
  templates,
  setTemplate,
}: DefaultTemplatesProps) => {
  return (
    <Container>
      {templates.map((template) => (
        <Row key={template.name}>
          <SimpleStepTitle className="text-md">
            <FormattedMessage
              id={`${pascalCaseToUnderscore(template.name)}_STRING`}
            />
          </SimpleStepTitle>
          <TemplateEditor template={template} setTemplate={setTemplate} />
          <TemplatePreview template={template} />
        </Row>
      ))}
    </Container>
  );
};

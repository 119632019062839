import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { UomFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { UNSPECIFIED_COST_CODE_ID } from "../../../../../../../../common/hooks/useUnspecifiedCostCode";
import { ReleaseReportItem } from "../components/report-item-orders-modal/ReportModalReleaseItem";

type SpendingReportValue = {
  uom: UomFieldsFragment | null | undefined;
  orderedQuantity: number;
  orderedTotal: number;
  invoicedQuantity: number;
  invoicedTotal: number;
  receivedQuantity: number;
  receivedTotal: number;
};

export const useSpendingReportValuesGroupedByUom = (
  item: ReleaseReportItem,
  gross: boolean,
  costCodeId?: string,
): SpendingReportValue[] => {
  const { uoms } = useStartupDataStore();

  return useMemo(
    () =>
      (item.releaseItems ?? [])
        .filter(
          (releaseItem) =>
            !costCodeId ||
            (costCodeId === UNSPECIFIED_COST_CODE_ID
              ? releaseItem.costCode === null
              : releaseItem.costCode?.id === costCodeId),
        )
        .reduce((acc: SpendingReportValue[], releaseItem) => {
          const existingItemIndex = acc.findIndex(
            (item) => item.uom?.id === releaseItem.uom?.id,
          );

          if (existingItemIndex !== -1) {
            acc[existingItemIndex] = {
              ...acc[existingItemIndex],
              orderedQuantity: new DecimalSafe(
                acc[existingItemIndex].orderedQuantity ?? 0,
              )
                .plus(releaseItem.quantityDecimal || 0)
                .toNumber(),
              orderedTotal: new DecimalSafe(acc[existingItemIndex].orderedTotal)
                .plus(
                  gross
                    ? new DecimalSafe(releaseItem.orderedAmount.net ?? 0)
                        .plus(releaseItem.orderedAmount.tax ?? 0)
                        .plus(releaseItem.orderedAmount.charges ?? 0)
                    : (releaseItem.orderedAmount.net ?? 0),
                )
                .toNumber(),
              invoicedQuantity: new DecimalSafe(
                acc[existingItemIndex].invoicedQuantity,
              )
                .plus(releaseItem.invoicedQuantity || 0)
                .toNumber(),
              invoicedTotal: new DecimalSafe(
                acc[existingItemIndex].invoicedTotal,
              )
                .plus(
                  gross
                    ? new DecimalSafe(releaseItem.invoicedAmount.net ?? 0)
                        .plus(releaseItem.invoicedAmount.tax ?? 0)
                        .plus(releaseItem.invoicedAmount.charges ?? 0)
                    : (releaseItem.invoicedAmount.net ?? 0),
                )
                .toNumber(),
              receivedQuantity: new DecimalSafe(
                acc[existingItemIndex].receivedQuantity,
              )
                .plus(releaseItem.receivedQuantityDecimal || 0)
                .toNumber(),
              receivedTotal: new DecimalSafe(
                acc[existingItemIndex].receivedQuantity,
              )
                .plus(
                  new DecimalSafe(releaseItem.receivedQuantityDecimal || 0).mul(
                    releaseItem.unitPrice ?? "0",
                  ),
                )
                .toNumber(),
            };
          } else {
            acc.push({
              uom: uoms.find((uom) => uom.id === releaseItem.uom?.id),
              orderedQuantity: new DecimalSafe(
                releaseItem.quantityDecimal || 0,
              ).toNumber(),
              orderedTotal: gross
                ? new DecimalSafe(releaseItem.orderedAmount.net ?? 0)
                    .plus(releaseItem.orderedAmount.tax ?? 0)
                    .plus(releaseItem.orderedAmount.charges ?? 0)
                    .toNumber()
                : Number(releaseItem.orderedAmount.net ?? 0),
              invoicedQuantity: new DecimalSafe(
                releaseItem.invoicedQuantity || 0,
              ).toNumber(),
              invoicedTotal: gross
                ? new DecimalSafe(releaseItem.invoicedAmount.net ?? 0)
                    .plus(releaseItem.invoicedAmount.tax ?? 0)
                    .plus(releaseItem.invoicedAmount.charges ?? 0)
                    .toNumber()
                : Number(releaseItem.invoicedAmount.net ?? 0),
              receivedQuantity: new DecimalSafe(
                releaseItem.receivedQuantityDecimal || 0,
              ).toNumber(),
              receivedTotal: new DecimalSafe(
                releaseItem.receivedQuantityDecimal || 0,
              )
                .mul(releaseItem.unitPrice ?? "0")
                .toNumber(),
            });
          }
          return acc;
        }, []),
    [item.releaseItems, gross, costCodeId, uoms],
  );
};

export const UNDEFINED_WAREHOUSE_ID = "undefined";

export const getSelectedItemIdParts = (id: string) => {
  const [warehouseId, inventoryItemId] = id.split("/");
  return { warehouseId, inventoryItemId };
};

export const createSelectedItemId = (
  warehouseId: string,
  inventoryItemId: string,
) => {
  return `${warehouseId ?? UNDEFINED_WAREHOUSE_ID}/${inventoryItemId}`;
};

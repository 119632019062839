import { useCallback, useMemo } from "react";
import { useInventoryItemsFilter } from "../../../hooks/useInventoryItemsFilter";

export const useCurrentStockHeader = () => {
  const { filter, setFilter } = useInventoryItemsFilter();

  const appliedFilters = useMemo(() => {
    return (
      Number(filter?.depleted !== false) + Number(filter?.archived !== false)
    );
  }, [filter?.archived, filter?.depleted]);

  const resetFilterToDefault = useCallback(() => {
    setFilter({
      ...filter,
      depleted: false,
      archived: false,
      warehouseIds: undefined,
      costCodeIds: undefined,
      search: undefined,
    });
  }, [filter, setFilter]);

  const countNonDefaultFilters = useMemo(
    () =>
      appliedFilters +
      Number(filter?.search != null && filter?.search !== "") +
      Number((filter?.costCodeIds || []).length !== 0) +
      Number((filter?.warehouseIds || []).length !== 0),
    [appliedFilters, filter?.costCodeIds, filter?.search, filter?.warehouseIds],
  );

  return {
    appliedFilters,
    resetFilterToDefault,
    countNonDefaultFilters,
  };
};

import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { isLumpSumItem } from "../../../../../../../common/utils/lumpSumItemUtils";
import { useRelease } from "../../../../release/providers/ReleaseProvider";
import { useInvoiceMatchedOrder } from "../providers/InvoiceMatchedOrderProvider";

export const useSelectedInvoiceItems = () => {
  const { release } = useRelease();
  const { useInvoiceItemPrice } = useInvoiceMatchedOrder();
  const { getTaxAmount } = useTaxCalculation();
  const { calcExtPrice } = usePriceCalculation();

  const selectedInvoiceItems = useMemo(
    () =>
      (release?.items ?? []).filter(
        (releaseItem) => (releaseItem.invoiceItems || [])?.length > 0,
      ),
    [release?.items],
  );

  const getInvoiceItemsSubtotal = useCallback(
    (
      selectedInvoiceItems: ReleaseFieldsFragment["items"],
      opts?: { taxable: boolean },
    ) => {
      return selectedInvoiceItems.reduce((acc, releaseItem) => {
        const invoiceItem = releaseItem.invoiceItems?.[0];
        const extPrice =
          !opts?.taxable || releaseItem.taxable
            ? calcExtPrice(
                invoiceItem?.quantity ?? 0,
                isLumpSumItem(releaseItem)
                  ? 1
                  : ((useInvoiceItemPrice
                      ? invoiceItem?.unitPrice
                      : releaseItem.unitPrice) ?? 0),
              )
            : 0;

        return acc.add(extPrice);
      }, new DecimalSafe(0));
    },
    [calcExtPrice, useInvoiceItemPrice],
  );

  const selectedInvoiceItemsSubtotal = useMemo(
    () => getInvoiceItemsSubtotal(selectedInvoiceItems),
    [getInvoiceItemsSubtotal, selectedInvoiceItems],
  );

  const selectedInvoiceItemsTaxableSubtotal = useMemo(
    () => getInvoiceItemsSubtotal(selectedInvoiceItems, { taxable: true }),
    [getInvoiceItemsSubtotal, selectedInvoiceItems],
  );

  const releaseCharges = useMemo(
    () =>
      (release?.additionalCharges ?? []).reduce(
        (acc, charge) => acc.plus(charge.amount),
        new DecimalSafe(0),
      ),
    [release?.additionalCharges],
  );

  const selectedInvoiceItemsCharges = useMemo(() => {
    if (!selectedInvoiceItemsSubtotal || !release?.additionalCharges.length) {
      return new DecimalSafe(0);
    }
    return selectedInvoiceItemsSubtotal
      .div(release?.netAmount || 1)
      .mul(releaseCharges);
  }, [
    selectedInvoiceItemsSubtotal,
    release?.additionalCharges.length,
    release?.netAmount,
    releaseCharges,
  ]);

  const selectedInvoiceItemsSalesTax = useMemo(() => {
    const subtotalWithCharges = new DecimalSafe(release?.taxableNetAmount || 0)
      .plus(releaseCharges)
      .toNumber();

    const taxAmount = getTaxAmount(
      release?.taxRate,
      release?.customTaxAmount,
      subtotalWithCharges,
      release?.taxVariance,
    );
    if (!selectedInvoiceItemsTaxableSubtotal || taxAmount === 0) {
      return 0;
    }
    return selectedInvoiceItemsTaxableSubtotal
      .div(
        (release?.taxableNetAmount ? Number(release?.taxableNetAmount) : 1) ||
          1,
      )
      .mul(taxAmount);
  }, [
    release?.taxableNetAmount,
    release?.taxRate,
    release?.customTaxAmount,
    release?.taxVariance,
    releaseCharges,
    getTaxAmount,
    selectedInvoiceItemsTaxableSubtotal,
  ]);

  const selectedInvoiceItemsTotal = useMemo(
    () =>
      selectedInvoiceItemsSubtotal
        .plus(selectedInvoiceItemsSalesTax)
        .plus(selectedInvoiceItemsCharges)
        .toDP(2),
    [
      selectedInvoiceItemsSubtotal,
      selectedInvoiceItemsSalesTax,
      selectedInvoiceItemsCharges,
    ],
  );

  return {
    selectedInvoiceItems,
    selectedInvoiceItemsSubtotal,
    selectedInvoiceItemsCharges,
    selectedInvoiceItemsSalesTax,
    selectedInvoiceItemsTotal,
  };
};

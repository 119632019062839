import { Dialog } from "@/common/components/dialog/Dialog";
import { If } from "@/common/components/if/If";
import { Switch } from "@/common/components/switch/Switch";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { ReleaseStatus } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReleaseActions } from "../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { isWarehouseRelease } from "../../../utils/isWarehouseRelease";
import { ReleaseDateTimePicker } from "../components/release-date-time-picker/ReleaseDateTimePicker";
import { useReleaseVendorActiveContacts } from "../hooks/useReleaseVendorActiveContacts";
import {
  CallbackFunction,
  useUpdateReleaseDialog,
} from "./useUpdateReleaseDialog";

const Container = tw.div`
  flex gap-1 px-2 justify-center items-center flex-col
`;

const InfoText = tw.div`
  text-sm w-fit grid grid-cols-auto-auto gap-x-2 gap-y-2 items-center font-normal place-self-center
  bg-gray-200 py-1.5 px-3 col-span-3 rounded-3xl text-center
`;

const AdditionalInfo = tw.div`text-xs col-span-3 text-center leading-tight`;

type UpdateDialogProps = {
  visible: boolean;
  callback?: CallbackFunction;
  setVisible: (visible: boolean) => void;
  status: ReleaseStatus;
  titleKey?: string;
  withRedirect?: boolean;
  withCompletionDialog?: boolean;
};

export const UpdateReleaseDialog: FC<UpdateDialogProps> = ({
  visible = true,
  callback,
  setVisible,
  status,
  titleKey: defaultTitleKey = "DELIVERY_SUBMIT_TITLE_REQUESTED",
  withRedirect = true,
  withCompletionDialog = true,
}) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { vendorActiveContacts } = useReleaseVendorActiveContacts();
  const { requestedDate } = useReleaseActions();
  const { orderTypeConfig } = useOrderTypesConfig({ release });

  const {
    onDateConfirm,
    handleConfirm,
    handleCancel,
    saving,
    skipVendorNotification,
    setSkipVendorNotification,
    isWarehouseManagerAndWarehouseRelease,
    titleKey,
    skipConfirmation,
    setSkipConfirmation,
  } = useUpdateReleaseDialog({
    callback,
    withRedirect,
    withCompletionDialog,
    visible,
    setVisible,
  });

  return (
    <Dialog
      includeWarningIcon
      title={
        <FormattedMessage
          id={titleKey || defaultTitleKey}
          values={{ br: <br key="break" /> }}
        />
      }
      saving={saving}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({
        id: isWarehouseManagerAndWarehouseRelease
          ? "CONFIRM"
          : "UPDATE_RELEASE",
      })}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      buttonsConfiguration={{
        confirm: {
          wide: true,
        },
        cancel: {
          wide: true,
        },
      }}
      loading={false}
      show={visible}
      extraButtonMargin={
        status !== ReleaseStatus.Received && vendorActiveContacts.length > 0
      }
      content={
        <Container
          className={
            status !== ReleaseStatus.Received && vendorActiveContacts.length > 0
              ? "mt-5"
              : "mb-3 mt-0"
          }
        >
          <If isTrue={!isWarehouseRelease(release)}>
            {status !== ReleaseStatus.Received &&
            vendorActiveContacts.length > 0 ? (
              <>
                <InfoText>
                  <FormattedMessage id="NOTIFY_VENDOR_ABOUT_ORDER_CHANGES" />
                  <Switch
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    value={!skipVendorNotification}
                    onChange={() =>
                      setSkipVendorNotification((prevValue) => !prevValue)
                    }
                  />
                  <If isTrue={!skipVendorNotification}>
                    <FormattedMessage id="ASK_VENDOR_CONFIRM_ORDER_DATE_AND_ITEMS" />
                    <Switch
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                      value={!skipConfirmation}
                      onChange={() => setSkipConfirmation(!skipConfirmation)}
                    />
                  </If>
                </InfoText>
                <If
                  isTrue={
                    !skipVendorNotification &&
                    skipConfirmation &&
                    release?.status !== ReleaseStatus.Requested
                  }
                >
                  <AdditionalInfo className="mt-1">
                    <FormattedMessage id="VENDOR_WILL_BE_NOTIFIED_WO_CONFIRMATION" />
                  </AdditionalInfo>
                </If>
              </>
            ) : (
              <AdditionalInfo className="text-sm">
                <FormattedMessage id="VENDOR_WILL_NOT_BE_NOTIFIED" />
              </AdditionalInfo>
            )}
          </If>
          <If isTrue={release?.timeTBD}>
            <ReleaseDateTimePicker
              className="mt-3"
              value={requestedDate}
              onChange={onDateConfirm}
              label={orderTypeConfig.labels.datePicker}
              prefillIfTbd
              hideIfNotAdjustmentSet
            />
          </If>
        </Container>
      }
    />
  );
};

import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useOrderTypes } from "@/contractor/pages/admin/orders/pages/order-types/hooks/useOrderTypes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { rowIsEmpty } from "../../utils/rowIsEmpty";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { applyClasses } from "../utils/applyClasses";

export const useCostTypeRenderer = () => {
  const { release } = useRelease();
  const { formatCostType } = useCostTypes();
  const formContext = useFormContext<{ orderTypeId: string }>();
  const orderTypeId = formContext?.watch?.("orderTypeId");
  const { orderTypes } = useOrderTypes();
  const baseRenderer = useBaseRenderer();

  const orderType = useMemo(
    () => orderTypes.find((ot) => ot.id === orderTypeId),
    [orderTypeId, orderTypes],
  );

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const rowData = instance?.getDataAtRow(row);
      if (!value && !rowIsEmpty(rowData)) {
        baseRenderer(
          instance,
          td,
          row,
          col,
          prop,
          formatCostType(
            release?.type?.costType?.id || orderType?.costType?.id,
          ),
          cellProperties,
        );
        applyClasses(td, "text-gray-500");
      } else {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
      }
    },
    [
      baseRenderer,
      formatCostType,
      release?.type?.costType?.id,
      orderType?.costType?.id,
    ],
  );

  return renderer;
};

import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  ExternalInsuranceFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import moment from "moment";
import { useMemo } from "react";
import { useVendors } from "../components/vendors/hooks/useVendors";

export type InsuranceInfo = ExternalInsuranceFieldsFragment & {
  expired: boolean;
};

export const useSupplierInsurance = (vendorId?: string) => {
  const { connectedSourceSystem } = useOrgSettings();
  const { vendors } = useVendors();

  const vendor = useMemo(
    () => vendors.find((vendor) => vendor.sellerOrgLocation.id === vendorId),
    [vendorId, vendors],
  );

  const insurances: InsuranceInfo[] = useMemo(
    () =>
      (
        vendor?.externalVendors?.flatMap(
          (externalVendor) => externalVendor.insurances,
        ) ?? []
      ).map((insurance) => ({
        ...insurance,
        expired: moment(insurance.endDate).isBefore(moment()),
      })),
    [vendor],
  );

  const insuranceExpired = useMemo(
    () =>
      insurances.some((insurance) => insurance.expired && insurance.required),
    [insurances],
  );

  const supplierInsuranceInfoIsVisible = useMemo(
    () =>
      connectedSourceSystem === SourceSystem.Sage300 &&
      insurances.length &&
      vendor &&
      vendorId,
    [connectedSourceSystem, insurances.length, vendor, vendorId],
  );

  return {
    vendor,
    insurances,
    insuranceExpired,
    supplierInsuranceInfoIsVisible,
  };
};

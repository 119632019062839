import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InvoiceStatus, TaxType } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { BsExclamation } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InfoTooltip } from "../../../../../../../../../common/components/info-tooltip/InfoTooltip";
import { Tooltip } from "../../../../../../../../../common/components/tooltip/Tooltip";
import { useInvoiceFooterTotalsWarning } from "../../../hooks/useInvoiceFooterTotalsWarning";
import { useSelectedInvoiceItems } from "../../../hooks/useSelectedInvoiceItems";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";
import {
  Column,
  ContentColumn,
  PanelDescription,
  PanelTitle,
  QuarterPanel,
} from "./InvoiceExpandable.styled";

const Row = tw.div`flex flex-row h-7 pr-0`;
const RowTitle = tw.div`flex w-50 items-center justify-end font-medium text-sm text-right`;
const RowIcon = tw.div`flex w-15 pl-2.5 items-center justify-start`;
const RowValue = tw.div`flex w-40 items-center h-8 justify-end pr-10 text-sm`;
const RedText = tw.div`text-red-500`;
const ExclamationCircleRed = tw(
  BsExclamation,
)`bg-red-500 fill-red-500 text-xl rounded-full fill-white ml-0.5`;
const ExclamationCircleGray = tw(
  BsExclamation,
)`text-xl fill-gray-500 bg-gray-500 rounded-full fill-white`;

export const InvoiceItemsPanel: FC = () => {
  const intl = useIntl();
  const { hasAdditionalCharges } = useOrgSettings();
  const { invoice } = useInvoiceVerification();
  const {
    selectedInvoiceItems,
    selectedInvoiceItemsCharges,
    selectedInvoiceItemsSalesTax,
    selectedInvoiceItemsSubtotal,
    selectedInvoiceItemsTotal,
  } = useSelectedInvoiceItems();
  const {
    displayTotalErrorIcon,
    displayGrayTotalErrorIcon,
    subTotalsAreNotEqual,
    totalsAreNotEqual,
  } = useInvoiceFooterTotalsWarning();

  const useTax = useMemo(
    () => invoice?.release?.taxType === TaxType.Use,
    [invoice],
  );

  const containerClassName = useMemo(() => {
    let className = "border-2 ";
    if (invoice?.status === InvoiceStatus.Approved) {
      className += "border-green-800 bg-green-150";
    } else if (selectedInvoiceItems.length) {
      className += "border-blue-500 bg-blue-100";
    } else {
      className += "border-gray-400 bg-white border-dashed border-white";
    }

    return className;
  }, [selectedInvoiceItems.length, invoice?.status]);

  return (
    <QuarterPanel data-testid="invoiceFooter" className={containerClassName}>
      <Column className="max-w-[45%] flex-1">
        <PanelTitle>
          <FormattedMessage id="INVOICED_ITEMS" />
          <FormattedMessage
            id="INVOICED_ITEMS_DESCRIPTION"
            tagName={PanelDescription}
          />
        </PanelTitle>
      </Column>
      <ContentColumn>
        <Row>
          <FormattedMessage id="SUBTOTAL" tagName={RowTitle} />
          <RowIcon>
            <If isTrue={subTotalsAreNotEqual}>
              <Tooltip
                id="INVOICE_FOOTER_SUBTOTAL_ERROR"
                element={<ExclamationCircleRed />}
              >
                <FormattedMessage id="INVOICE_FOOTER_SUBTOTAL_ERROR" />
              </Tooltip>
            </If>
          </RowIcon>
          <RowValue data-testid="invoiceFooter-subtotal">
            <If isTrue={selectedInvoiceItems.length}>
              <Price
                price={selectedInvoiceItemsSubtotal ?? "0"}
                maximumFractionDigits={2}
              />
            </If>
            <If isTrue={!selectedInvoiceItems.length}>
              <FormattedMessage id="SELECT_ITEMS" tagName={RedText} />
            </If>
          </RowValue>
        </Row>
        <If
          isTrue={
            hasAdditionalCharges || !!selectedInvoiceItemsCharges.toNumber()
          }
        >
          <Row>
            <FormattedMessage
              id="ALLOCATED_ADDITIONAL_CHARGES"
              tagName={RowTitle}
            />
            <RowIcon />
            <RowValue data-testid="invoiceFooter-additionalCharges">
              <If isTrue={selectedInvoiceItems.length}>
                <Price
                  price={selectedInvoiceItemsCharges ?? "0"}
                  maximumFractionDigits={2}
                />
              </If>
              <If isTrue={!selectedInvoiceItems.length}>
                <FormattedMessage id="SELECT_ITEMS" tagName={RedText} />
              </If>
            </RowValue>
          </Row>
        </If>
        <If isTrue={!useTax}>
          <Row>
            <FormattedMessage id="ALLOCATED_SALES_TAX" tagName={RowTitle} />
            <RowIcon />
            <RowValue data-testid="invoiceFooter-salesTax">
              <If isTrue={selectedInvoiceItems.length}>
                <Price
                  price={selectedInvoiceItemsSalesTax ?? "0"}
                  maximumFractionDigits={2}
                />
              </If>
              <If isTrue={!selectedInvoiceItems.length}>
                <FormattedMessage id="SELECT_ITEMS" tagName={RedText} />
              </If>
            </RowValue>
          </Row>
        </If>
        <Row className="border-1 mr-7 mt-1 h-8 justify-end self-stretch border-t border-dashed border-black">
          <RowTitle className="rounded-l-4xl text-lg">
            <FormattedMessage id="ALLOCATED_TOTAL" />
          </RowTitle>
          <RowIcon>
            <If isTrue={displayTotalErrorIcon && totalsAreNotEqual}>
              <Tooltip
                id="INVOICE_FOOTER_TOTALS_ERROR"
                element={
                  displayGrayTotalErrorIcon ? (
                    <ExclamationCircleGray />
                  ) : (
                    <ExclamationCircleRed />
                  )
                }
              >
                <FormattedMessage
                  id={
                    displayGrayTotalErrorIcon
                      ? "INVOICE_FOOTER_TOTAL_WARNING"
                      : "INVOICE_FOOTER_TOTALS_ERROR"
                  }
                />
              </Tooltip>
            </If>
            <If isTrue={!displayTotalErrorIcon}>
              <InfoTooltip
                message={intl.$t({
                  id: "INVOICED_TOTAL_TOOLTIP",
                })}
              />
            </If>
          </RowIcon>
          <RowValue
            className="-pr-6 -ml-7 h-auto items-center font-medium"
            data-testid="invoiceFooter-invoiceTotal"
          >
            <If isTrue={selectedInvoiceItems.length}>
              <Price
                price={selectedInvoiceItemsTotal}
                maximumFractionDigits={2}
                className="mr-3 text-lg"
              />
            </If>
            <If isTrue={!selectedInvoiceItems.length}>
              <RedText className="mr-3">
                <FormattedMessage id="SELECT_ITEMS" />
              </RedText>
            </If>
          </RowValue>
        </Row>
      </ContentColumn>
    </QuarterPanel>
  );
};

import { routes } from "@/config/routes";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { InventoryAdjustmentFormValues } from "../../inventory-adjustment/components/inventory-adjustment-form/InventoryAdjustmentForm";
import { useInventoryItemSequence } from "../current-stock-list/components/hooks/useInventoryItemSequence";

export const useAddTransfer = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { warehouses } = useWarehouses();
  const { sequenceIds } = useInventoryItemSequence();

  const multipleWarehousesSelected = useMemo(() => {
    return sequenceIds.length > 1;
  }, [sequenceIds]);

  const handleTransfer = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleConfirm = useCallback(
    (values: InventoryAdjustmentFormValues) => {
      navigate({
        pathname: routes.inventoryTransfer,
        search: `?warehouseId=${values.warehouseId}`,
      });
      setOpen(false);
    },
    [navigate, setOpen],
  );

  const isSingleWarehouse = useMemo(() => {
    return warehouses.length === 1;
  }, [warehouses]);

  return {
    open,
    setOpen,
    handleTransfer,
    handleConfirm,
    multipleWarehousesSelected,
    isSingleWarehouse,
  };
};

import { PaginationArgs } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  readValueFromKeys,
  removeValue,
  setValue,
} from "@/common/utils/localStorage";
import { useScheduleRelease } from "@/contractor/pages/home/release/pages/specify-details/hooks/release-mutations/useScheduleRelease";
import {
  QueryReleasesFilter,
  ReleasePartialFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useShallow } from "zustand/react/shallow";
import { useDeliveriesWithPagination } from "../hooks/useDeliveriesWithPagination";

const DELIVERIES_POLL_INTERVAL = 5000;

type ProviderContextType = {
  deliveries: ReleasePartialFieldsFragment[];
  filter?: QueryReleasesFilter | undefined;
  setFilter: (filter: QueryReleasesFilter | undefined) => void;
  loading: boolean;
  error: boolean;
  totalCount: number;
  isFiltered: boolean;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
  scheduleRelease: (input: UpdateContractorReleaseInput) => Promise<boolean>;
  refetch: () => void;
  paginationArgs: PaginationArgs;
  page: number | undefined;
};

export const DEFAULT_RELEASES_FILTER: QueryReleasesFilter = {
  deleted: false,
  closedProjects: false,
};

const ProviderContext = createContext<ProviderContextType>({
  deliveries: [],
  filter: undefined,
  setFilter: NoFunction,
  loading: false,
  error: false,
  totalCount: 0,
  isFiltered: false,
  exportEnabled: false,
  setExportEnabled: NoFunction,
  scheduleRelease: NoFunctionBooleanPromise,
  refetch: NoFunction,
  paginationArgs: {},
  page: 0,
});

export const DeliveriesProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { orgId } = useStartupDataStore(
    useShallow((state) => ({
      orgId: state.viewer?.org.id,
    })),
  );

  const [filter, setFilter] = useState<QueryReleasesFilter | undefined>(
    undefined,
  );

  const { deliveries, loading, error, totalCount, pagination, refetch, page } =
    useDeliveriesWithPagination(filter, { skip: !filter });

  useEffect(() => {
    if (deliveries.some((d) => d.poLink?.syncing)) {
      setInterval(() => {
        refetch();
      }, DELIVERIES_POLL_INTERVAL);
    }
  }, [deliveries, refetch]);

  const [exportEnabled, setExportEnabled] = useState<boolean>(false);

  useErrorEffect(error);

  useEffect(() => {
    const localStorageSettings = readValueFromKeys<QueryReleasesFilter>([
      [orgId || "", LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER],
      LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER,
    ]) as QueryReleasesFilter;
    setFilter({
      ...DEFAULT_RELEASES_FILTER,
      ...(localStorageSettings || {}),
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam, orgId]);

  const { scheduleRelease: scheduleReleaseMutation } = useScheduleRelease();
  const scheduleRelease = useCallback(
    async (input: UpdateContractorReleaseInput) => {
      const result = await scheduleReleaseMutation(input);
      if (result) {
        refetch();
      }
      return !!result;
    },
    [scheduleReleaseMutation, refetch],
  );

  const setFilterAndUpdateQueryString = (
    updatedFilter: QueryReleasesFilter | undefined,
  ) => {
    setFiltersQueryParams(updatedFilter);
    removeValue(LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER);
    setValue(
      [orgId || "", LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER],
      updatedFilter,
    );
    setFilter(updatedFilter);
  };

  return (
    <ProviderContext.Provider
      value={{
        deliveries,
        isFiltered: !!filter?.projectIds || !!filter?.statuses,
        loading,
        error: !!error,
        totalCount,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        exportEnabled,
        setExportEnabled,
        scheduleRelease,
        refetch,
        paginationArgs: pagination,
        page,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliveries = (): ProviderContextType =>
  useContext(ProviderContext);

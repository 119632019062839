import { SeededRandom } from "@/common/utils/seedRadom";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { darken, getLuminance, lighten } from "@mui/material";
import { useCallback, useMemo } from "react";
import { getNameInitials } from "../../../image-initials/utils/getNameInitials";
import { TAG_COLORS } from "../../../tag-picker/TagColors";
import { useVendors } from "../../../vendors/hooks/useVendors";
import { useTableMaps } from "../../hooks/useTableMaps";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { applyClasses } from "../utils/applyClasses";
import { isValueEmpty } from "../utils/isValueEmpty";

type VendorDetails = {
  initials: string;
  backgroundColor: string;
  color: string;
  borderColor: string;
};

const logoBaseClasses = `
  absolute top-1/2 -translate-y-1/2 left-2 w-[25px] h-[25px] 
  object-contain rounded-sm p-px text-[9px] grid place-items-center 
  font-semibold drop-shadow-sm tracking-wide select-none
`;

const getColors = (name: string) => {
  const random = new SeededRandom(name || "NA");
  const selectedColor = TAG_COLORS[random.nextInt(TAG_COLORS.length)];
  return {
    backgroundColor: lighten(selectedColor, 0.8 - getLuminance(selectedColor)),
    color: darken(selectedColor, 0.65),
    borderColor: darken(selectedColor, 0.3),
  };
};

export const useVendorRenderer = () => {
  const { vendors } = useVendors();
  const { vendorByNameMap } = useTableMaps();
  const baseRenderer = useBaseRenderer();

  const vendorMap = useMemo(() => {
    return vendors.reduce<Record<string, VendorDetails>>((acc, vendor) => {
      acc[vendor.sellerOrgLocation.id] = {
        initials: getNameInitials(vendor.sellerOrgLocation.org.name),
        ...getColors(vendor.sellerOrgLocation.org.name),
      };
      return acc;
    }, {});
  }, [vendors]);

  const renderVendorInitials = useCallback(
    (vendor: OrgPreferredVendorsFieldsFragment, td: HTMLTableCellElement) => {
      const vendorLogo = document.createElement("span");
      vendorLogo.className = logoBaseClasses;
      vendorLogo.innerText = vendorMap[vendor.sellerOrgLocation.id].initials;
      vendorLogo.style.backgroundColor =
        vendorMap[vendor.sellerOrgLocation.id].backgroundColor;
      vendorLogo.style.color = vendorMap[vendor.sellerOrgLocation.id].color;
      vendorLogo.style.borderColor =
        vendorMap[vendor.sellerOrgLocation.id].borderColor;
      td.appendChild(vendorLogo);
    },
    [vendorMap],
  );

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      baseRenderer(instance, td, row, col, prop, value, cellProperties);
      if (isValueEmpty(value)) {
        return;
      }

      const vendor = vendorByNameMap[value];
      if (vendor) {
        applyClasses(td, "pl-10 text-left");
        if (vendor.sellerOrgLocation.org.photoUrl) {
          const vendorLogo = document.createElement("img");
          vendorLogo.src = vendor.sellerOrgLocation.org.photoUrl ?? "";
          vendorLogo.fetchPriority = "low";
          vendorLogo.onerror = () => {
            renderVendorInitials(vendor, td);
          };
          vendorLogo.className = logoBaseClasses;
          td.appendChild(vendorLogo);
        } else {
          renderVendorInitials(vendor, td);
        }
      }
    },
    [baseRenderer, vendorByNameMap, renderVendorInitials],
  );

  return renderer;
};

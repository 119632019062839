import { InventoryTransactionType } from "@/generated/graphql";

export enum InventoryTransactionScheduledTypes {
  SCHEDULED_INFLOW = "SCHEDULED_INFLOW",
  SCHEDULED_OUTFLOW = "SCHEDULED_OUTFLOW",
}

export type InventoryTransactionExtendedType =
  | InventoryTransactionType
  | InventoryTransactionScheduledTypes;

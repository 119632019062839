import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import { WarehouseOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

type Props = {
  release: Pick<
    ReleasePartialFieldsFragment,
    "sellerOrgLocation" | "preferredVendor" | "sourceWarehouse"
  >;
};

export const SupplierLogo: FC<Props> = ({ release }) => {
  if (release.sourceWarehouse) {
    return (
      <Tooltip element={<WarehouseOutlined />}>
        <FormattedMessage id="WAREHOUSE" /> - {release.sourceWarehouse.name}
      </Tooltip>
    );
  }
  return (
    <VendorLogo
      logoImageUrl={release.sellerOrgLocation?.org.photoUrl}
      address={release.sellerOrgLocation?.address}
      contactName={getVendorContacts(
        release.preferredVendor?.contacts.filter(
          (c) => c.receivesOrderNotifications,
        ),
      )}
      orgName={release.sellerOrgLocation?.org.name}
    />
  );
};

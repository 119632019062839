import { WarehouseOutlined } from "@mui/icons-material";
import { TbCrane } from "react-icons/tb";
import tw from "tailwind-styled-components";
import { addressToString } from "../../adress-location/AddressLocation";
import { If } from "../../if/If";
import { SupplierType } from "../../supplier-picker/types/Supplier";
import { Tooltip } from "../../tooltip/Tooltip";
import { WarehouseOption, WarehouseType } from "../types/WarehouseOption";
import { ALL_WAREHOUSES_ID } from "../useWarehouseOptions";

const Container = tw.div`ml-2 gap-2 absolute inset-0 flex items-center pl-2 right-8 overflow-hidden h-10`;
const Item = tw.div`text-sm text-nowrap`;

type Props = {
  item:
    | (Pick<WarehouseOption, "name" | "address" | "id"> & {
        type?: WarehouseType | SupplierType;
      })
    | undefined;
};

export const WarehouseSelectorCustomRendererWithIcon = ({ item }: Props) => {
  return (
    <Container>
      <Tooltip
        id=""
        element={
          <If isTrue={item?.id !== ALL_WAREHOUSES_ID}>
            {item?.type === WarehouseType.Job ? (
              <TbCrane className="h-6 w-6" />
            ) : (
              <WarehouseOutlined />
            )}
          </If>
        }
      >
        <>{addressToString(item?.address)}</>
      </Tooltip>
      <Item>{item?.name}</Item>
    </Container>
  );
};

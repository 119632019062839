import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { ComplianceGroupFieldsFragment } from "@/generated/graphql";
import { DeleteOutline } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useUpdateComplianceGroups } from "../hooks/useUpdateComplianceGroups";

type Props = {
  complianceGroup: ComplianceGroupFieldsFragment;
};
const IconButtonContainer = tw.div`grid gap-2 grid-cols-[repeat(3,_19px)] px-1`;

export const ComplianceGroupActions: FC<Props> = ({
  complianceGroup,
}: Props) => {
  const { updateComplianceGroups } = useUpdateComplianceGroups();
  const { openDialog } = useDialog();
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();

  const onRemove = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "DELETE" }),
      includeWarningIcon: true,
      title: intl.$t({ id: "DELETE_COMPLIANCE_GROUP" }),
      text: intl.$t({ id: "DELETE_COMPLIANCE_GROUP_CONFIRMATION" }),
      handleConfirm: async () => {
        await updateComplianceGroups({
          deleted: [complianceGroup.id],
        });
        setSuccessAlert(intl.$t({ id: "COMPLIANCE_GROUP_DELETED_SUCCESS" }));
      },
    });
  }, [
    complianceGroup,
    intl,
    openDialog,
    setSuccessAlert,
    updateComplianceGroups,
  ]);

  const icons = useMemo(() => {
    return [
      <IconButtonBorderless key={2} onClick={onRemove} className="col-start-2">
        <DeleteOutline />
      </IconButtonBorderless>,
    ];
  }, [onRemove]);

  return <IconButtonContainer>{icons.map((icon) => icon)}</IconButtonContainer>;
};

import { OrderTypeSummaryFieldsFragment } from "@/generated/graphql";
import { useIntl } from "react-intl";
import { If } from "../if/If";
import { GroupRenderer } from "../select/components/renderer/GroupRenderer";
import { SelectControlled } from "../select/components/single/SelectControlled";
import { useOrderTypeOptions } from "./hooks/useOrderTypeOptions";
import { OrderTypeCustomRenderer } from "./renderers/OrderTypeCustomRenderer";

type Props = {
  name: string;
  required?: boolean;
  disabled?: boolean;
  filterResults?: (orderType: OrderTypeSummaryFieldsFragment) => boolean;
  grouped?: boolean;
};

export const OrderTypePickerControlled = ({
  name,
  required = false,
  disabled,
  filterResults,
  grouped = true,
}: Props) => {
  const intl = useIntl();
  const { orderTypes, loading, includeOrderTypes } = useOrderTypeOptions({
    filterResults,
  });

  return (
    <If isTrue={includeOrderTypes}>
      <SelectControlled
        name={name}
        className="w-full"
        testId="form-order-type-selector"
        label={intl.$t({ id: "ORDER_TYPE" })}
        options={orderTypes}
        loading={loading}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        rules={{ required }}
        required={required}
        disabled={disabled}
        groupBy={
          grouped
            ? (option) => (option.trackInventory ? "WAREHOUSE_RESTOCK" : "JOB")
            : undefined
        }
        renderGroup={GroupRenderer}
        customRender={OrderTypeCustomRenderer}
      />
    </If>
  );
};

import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { useCallback, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useFindMaterialByName } from "../../hooks/useFindMaterialByName";

export const useBestVendorForItem = () => {
  const findMaterialByName = useFindMaterialByName();
  const { getUomByName } = useUomOptions();
  const { vendors, getVendorCode } = useVendors();
  const { manufacturers } = useManufacturers();
  const { estimatedItems } = useProjectStore(
    useShallow((state) => ({
      estimatedItems: state.estimatedItems,
    })),
  );
  const { prices } = useVendorPrices();

  const vendorMap = useMemo(() => {
    return vendors.reduce<Record<string, string>>((acc, vendor) => {
      acc[vendor.sellerOrgLocation.id] = vendorLabelFormatter(
        vendor.sellerOrgLocation,
        [],
        {
          vendorCode: getVendorCode(vendor),
        },
      );
      return acc;
    }, {});
  }, [vendors, getVendorCode]);

  return useCallback(
    (item: { material: string; manufacturer: string; uom: string }) => {
      const material = findMaterialByName(item.material);
      const uom = getUomByName(item.uom);
      const manufacturer = manufacturers.find(
        (m) => m.name === item.manufacturer,
      );

      const estItem = estimatedItems.find(
        (item) =>
          item.material.id === material?.id &&
          item.uom.id === uom?.id &&
          item.manufacturer?.id === manufacturer?.id,
      );

      if (estItem && estItem.sellerOrgLocation?.id) {
        return {
          price: estItem.unitPrice,
          vendorId: estItem.sellerOrgLocation?.id,
          vendorName: vendorMap[estItem.sellerOrgLocation?.id ?? ""],
        };
      }

      const price = prices
        .filter(
          (p) =>
            p.orgMaterialId === material?.id &&
            p.uom?.id === uom?.id &&
            p.manufacturer?.id === manufacturer?.id &&
            p.sellerOrgLocation,
        )
        .sort((a, b) => Number(a.price) - Number(b.price));

      if (price.length > 0) {
        return {
          price: price[0].price,
          vendorId: price[0].sellerOrgLocation?.id,
          vendorName: vendorMap[price[0].sellerOrgLocation?.id ?? ""],
        };
      }

      const closestVendorPrice = prices.find(
        (p) => p.orgMaterialId === material?.id && p.sellerOrgLocation,
      );

      if (closestVendorPrice) {
        return {
          vendorId: closestVendorPrice?.sellerOrgLocation?.id ?? "",
          vendorName:
            vendorMap[closestVendorPrice?.sellerOrgLocation?.id ?? ""],
          price: "",
        };
      }

      return {
        vendorId: vendors[0]?.sellerOrgLocation.id ?? "",
        vendorName: vendorMap[vendors[0]?.sellerOrgLocation.id ?? ""],
        price: "",
      };
    },
    [
      prices,
      findMaterialByName,
      getUomByName,
      manufacturers,
      estimatedItems,
      vendors,
      vendorMap,
    ],
  );
};

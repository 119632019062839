import { ListEmptyState } from "@/common/components/list-empty-state/ListEmptyState";
import { Loader } from "@/common/components/loader/Loader";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useSetCurrentProjectId } from "../project/hooks/useSetCurrentProjectId";
import { RfqBreadcrumbs } from "./components/rfq-breadcrumbs/RfqBreadcrumbs";
import { TableView } from "./components/table-view/TableView";
import { BidsPricesProvider } from "./providers/BidsPricesProvider";
import {
  RfqQuoteOptionsProvider,
  useRfqQuoteOptions,
} from "./providers/RfqQuoteOptionsProvider";
import {
  OrderQuotesProvider,
  useRfqQuotes,
} from "./providers/RfqQuotesProvider";

const PageContainer = tw.div`
  relative pt-4
`;

const LeftSide = tw.div`
  w-3 h-full bg-white absolute top-0 left-0 z-70 xl:w-16
`;

const ListEmptyStateStyled = tw(ListEmptyState)`
  mt-5
`;

const Container = tw.div`pb-15`;

const RfqQuotesWithProvider: FC = () => {
  const { rfq, loading } = useRfqQuotes();
  const intl = useIntl();
  const { visibleQuotes } = useRfqQuoteOptions();
  useSetCurrentProjectId(rfq?.project.id);

  if (loading || !rfq) {
    return <Loader loading />;
  }

  return (
    <Container>
      <Helmet>
        <title>{intl.$t({ id: "QUOTES_REVIEW_QUOTES" })}</title>
      </Helmet>
      <RfqBreadcrumbs />
      <LeftSide />
      <PageContainer data-testid="bids-page">
        {visibleQuotes.length > 0 ? (
          <TableView />
        ) : (
          <ListEmptyStateStyled messageKey="NO_BIDS_FOUND" />
        )}
      </PageContainer>
    </Container>
  );
};

export const RfqQuotes: FC = () => (
  <OrderQuotesProvider>
    <RfqQuoteOptionsProvider>
      <BidsPricesProvider>
        <RfqQuotesWithProvider />
      </BidsPricesProvider>
    </RfqQuoteOptionsProvider>
  </OrderQuotesProvider>
);

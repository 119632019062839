import { If } from "@/common/components/if/If";
import { useAllItemsCheckboxSelection } from "@/common/components/pagination/hooks/useAllItemsCheckboxSelection";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { ReceiptSummaryFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useReceipts } from "../../providers/ReceiptsProvider";
import { useReceiptSequence } from "../../providers/ReceiptsSequenceProvider";

const Container = tw.div`
  flex -ml-2
`;

export const ReceiptAllItemsCheckbox: FC = () => {
  const { sequenceIds, setSelectedReceipts, selectedReceipts } =
    useReceiptSequence();
  const { receipts } = useReceipts();
  const filterItems = useCallback(
    (receipt: ReceiptSummaryFieldsFragment) =>
      isAuthorized(receipt.permissions.export),
    [],
  );
  const { checked, handleChange } = useAllItemsCheckboxSelection(
    receipts,
    selectedReceipts,
    setSelectedReceipts,
    filterItems,
  );

  return (
    <Container>
      <If isTrue={receipts.some(filterItems)}>
        <SelectionCheckbox
          items={sequenceIds}
          setSelection={handleChange}
          checked={checked}
        />
      </If>
    </Container>
  );
};

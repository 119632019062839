import { FC } from "react";
import { useIntl } from "react-intl";
import { StyledSelect } from "../select/components/single/StyledSelect";
import { ALL_PROJECTS, useProjectOptions } from "./hooks/useProjectOptions";
import { ProjectPickerProps } from "./types/ProjectPickerProps";

export const StyledProjectPicker: FC<ProjectPickerProps> = ({
  projectId,
  setProjectId,
  placeholder,
  disabledFn = () => false,
  disabledTooltip,
  staticText,
  required,
  disabled,
  includeAllOption = true,
}) => {
  const { projectOptions } = useProjectOptions();
  const intl = useIntl();

  return (
    <StyledSelect
      className="w-full"
      testId="form-projects-selector"
      placeholder={placeholder || intl.$t({ id: "PROJECT_NAME" })}
      options={projectOptions}
      value={projectId || (includeAllOption ? ALL_PROJECTS : null)}
      required={required}
      disabled={disabled}
      onChange={(value) =>
        setProjectId?.(value !== ALL_PROJECTS ? (value as string) : null)
      }
      getLabel={(option) => option.name}
      getValue={(option) => option.id}
      getOptionDisabled={disabledFn}
      disabledTooltip={disabledTooltip}
      noOptionsText={intl.$t({ id: "NO_PROJECTS_FOUND" })}
      staticText={staticText}
      size="xs"
    />
  );
};

import {
  AdditionalFiltersOverlay,
  DividerLine,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { EquipmentFilterSelector } from "@/common/components/equipment-filter/EquipmentFilterSelector";
import { If } from "@/common/components/if/If";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { KickbackFilters } from "@/common/components/kickback-filters/KickbackFilters";
import { MultiSelectUserFilter } from "@/common/components/multiselect-user-filter/MultiSelectUserFilter";
import { OrderTypesFilter } from "@/common/components/order-types-filter/OrderTypesFilter";
import { VendorsFilterSelector } from "@/common/components/vendors-filter-selector/VendorsFilterSelector";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useEquipmentItems } from "@/contractor/pages/admin/cost-structure/pages/equipment/hooks/useEquipmentItems";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoices } from "../../../providers/InvoicesProvider";
import { InvoiceExportedWithFiltering } from "../invoice-exported-with-filtering/InvoiceExportedWithFiltering";
import { useInvoiceAdditionalFilters } from "./hooks/useInvoiceAdditionalFilters";

export const CustomFilterContainer = tw.div`
  flex justify-between items-center gap-4
`;

export const InvoiceAdditionalFilters = () => {
  const { filter, setFilter } = useInvoices();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { equipmentItems } = useEquipmentItems();

  const isInvoiceBatchingSupported = useMemo(
    () => hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceBatching),
    [hasFeatureInConnectedSourceSystem],
  );

  const { options, setFilterHandler } = useInvoiceAdditionalFilters();

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      options={options}
      setFilter={setFilter}
      includeBottomBorder={false}
      twoColumns
      customBottomFilters={
        <>
          <KickbackFilters filter={filter} setFilter={setFilter} />
          <DividerLine className="mb-0 mt-0" />
          <If isTrue={isInvoiceBatchingSupported}>
            <InvoiceExportedWithFiltering
              filter={filter}
              setFilter={setFilter}
            />
            <DividerLine className="mb-0 mt-0" />
          </If>
          <CustomFilterContainer>
            <AdditionalFiltersOverlay.Name>
              <FormattedMessage id="SELECT_VENDOR" />
            </AdditionalFiltersOverlay.Name>
            <VendorsFilterSelector
              value={filter?.sellerOrgLocationIds?.[0]}
              onChange={(value) => {
                setFilter({
                  ...filter,
                  sellerOrgLocationIds: value ? [value] : undefined,
                });
              }}
              includeWarehouses={false}
            />
          </CustomFilterContainer>
          <MultiSelectUserFilter
            values={filter?.projectMemberUserIds}
            setFilter={(users) =>
              setFilter({ ...filter, projectMemberUserIds: users })
            }
            titleId="PROJECT_MEMBERS"
            className="mt-2 grid grid-cols-[1fr_1.5fr]"
            placeholderId="ALL_MEMBERS"
            withRoles
          />
          <If isTrue={equipmentItems.length}>
            <CustomFilterContainer>
              <AdditionalFiltersOverlay.Name>
                <FormattedMessage id="EQUIPMENT_ITEM" />
              </AdditionalFiltersOverlay.Name>
              <EquipmentFilterSelector
                value={filter?.equipmentItemIds?.[0]}
                onChange={(value) => {
                  setFilter({
                    ...filter,
                    equipmentItemIds: value ? [value] : undefined,
                  });
                }}
              />
            </CustomFilterContainer>
          </If>
          <OrderTypesFilter
            selectedOrderTypes={filter?.releaseTypeIds}
            onChange={setFilterHandler}
          />
        </>
      }
    />
  );
};

import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { useTaxCodeSummaries } from "@/common/components/sales-tax-input/hooks/useTaxCodeSummaries";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { mergeChanges } from "@/common/utils/mergeChanges";
import {
  AddToReleaseItemInput,
  ProjectFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseItemFieldsFragment,
  ReleaseReassignmentFieldsFragment,
  ReleaseStatus,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useUpdateRelease } from "../pages/specify-details/hooks/release-mutations/useUpdateRelease";
import { useReleaseVendorReassignments } from "../pages/specify-details/hooks/useReleaseVendorReassignments";
import { useReleaseStore } from "../store/useReleaseStore";
import { ReleaseUpdate } from "../types/ReleaseUpdate";
import { useRelease } from "./ReleaseProvider";
import { useReleaseCacheUpdate } from "./useReleaseCacheUpdate";

type Props = {
  children: React.ReactNode;
  items: ReleaseItemFieldsFragment[];
  release: ReleaseUpdate;
  project?: Pick<ProjectFieldsFragment, "id"> | undefined | null;
};

type SubmitUpdateInput =
  | Partial<UpdateContractorReleaseInput>
  | null
  | undefined;

type SubmitUpdateOutput = {
  data: ReleaseFieldsFragment | null | undefined;
  reassignedReleases: ReleaseReassignmentFieldsFragment[];
};

type ProviderContextType = {
  updateRelease: (
    input: UpdateContractorReleaseInput,
    options?:
      | { batch?: boolean; setLoading?: boolean; callback?: () => void }
      | undefined,
  ) => Promise<boolean>;
  removeReleaseItem: (ids: string) => void;
  hasChanges: () => boolean;
  submitUpdate: (input?: SubmitUpdateInput) => Promise<SubmitUpdateOutput>;
  updating: boolean;
  release: Pick<
    ReleaseFieldsFragment,
    "id" | "version" | "status" | "sellerOrgLocation" | "poLink"
  >;
  project?: Pick<ProjectFieldsFragment, "id"> | undefined | null;
  removing: boolean;
  addItems: (itemsToAdd: AddToReleaseItemInput[]) => Promise<boolean>;
  selectedReleaseItemIds: string[];
  setSelectedReleaseItemIds: (id: string[]) => void;
  updates: UpdateContractorReleaseItemInput[] | undefined | null;
  updatingChanges: boolean;
  changes?: SubmitUpdateInput;
  requireDeliverySlip: boolean;
  setRequireDeliverySlip: (val: boolean) => void;
  removeReleaseQuote: () => Promise<void>;
  removeReleaseNote: () => Promise<void>;
};

const ProviderContext = createContext<ProviderContextType>({
  updateRelease: NoFunctionBooleanPromise,
  submitUpdate: () => Promise.resolve({ data: null, reassignedReleases: [] }),
  hasChanges: () => false,
  updating: false,
  release: {
    id: "",
    version: 0,
    status: ReleaseStatus.Draft,
  },
  project: {
    id: "",
  },
  removeReleaseItem: NoFunctionBooleanPromise,
  removing: false,
  addItems: NoFunctionBooleanPromise,
  selectedReleaseItemIds: [],
  setSelectedReleaseItemIds: NoFunction,
  updates: [],
  updatingChanges: false,
  requireDeliverySlip: false,
  setRequireDeliverySlip: NoFunction,
  changes: undefined,
  removeReleaseQuote: NoFunctionPromise,
  removeReleaseNote: NoFunctionPromise,
});

export const ReleaseUpdateProvider: FC<Props> = ({
  children,
  release,
  project,
  items,
}) => {
  const [removing, setRemoving] = useState(false);
  const changes = useRef<SubmitUpdateInput>(undefined);
  const updateReleaseCache = useReleaseCacheUpdate();
  const { updateRelease: updateReleaseMutation, updating } = useUpdateRelease();
  const { addItemsToRelease, invoiceStatuses, invoiceId } = useRelease();
  const { updateStoreRelease } = useReleaseStore();
  const { taxCodes } = useTaxCodeSummaries();
  const { orderTypes } = useOrderTypeOptions();
  const { setReleaseVendorReassignments } = useReleaseVendorReassignments();

  const [selectedReleaseItemIds, setSelectedReleaseItemIds] = useState<
    string[]
  >([]);
  const [requireDeliverySlip, setRequireDeliverySlip] = useState(
    release.type.requireDeliverySlip,
  );

  const [updatingChanges, setUpdatingChanges] = useState(false);
  const { setHasChanges, updateStoreReleaseVersion } = useReleaseStore();

  const removeReleaseItem = async (id: string) => {
    return await updateRelease({ releaseId: release.id, removedItems: [id] });
  };

  const updateRelease = async (
    input: UpdateContractorReleaseInput,
    options?:
      | { batch?: boolean; setLoading?: boolean; callback?: () => void }
      | undefined,
  ) => {
    updateReleaseCache(input, changes, { invoiceStatuses, invoiceId });
    updateStoreRelease(input, { taxCodes, orderTypes });
    if (options?.setLoading) {
      setUpdatingChanges(true);
    }
    const result = await update(input, options?.batch, options?.callback);
    if (options?.setLoading) {
      setTimeout(() => {
        setUpdatingChanges(false);
      }, 500);
    }
    return result;
  };

  const addItems = async (
    itemsToAdd: AddToReleaseItemInput[],
  ): Promise<boolean> => {
    if (
      release &&
      itemsToAdd.length > 0 &&
      (await addItemsToRelease(itemsToAdd))
    ) {
      if (!changes.current) {
        // We want to ensure that changes are non-empty so that the update
        // mutation can be triggered following item addition/removal, even if
        // its input is a no-op (to send a notification).
        changes.current = {
          releaseId: release.id,
          version: release.version,
        };
      }
      return true;
    }
    return false;
  };

  const update = async (
    input: UpdateContractorReleaseInput,
    batch = true,
    callback?: () => void,
  ) => {
    accumulateChanges(input);
    if (batch) {
      return false;
    }

    if (input.version) {
      const result = await submitUpdate(input);
      if (changes.current && result) {
        await update(
          {
            ...changes.current,
            releaseId: release.id,
            warehouseId: input.warehouseId,
            clearWarehouse: !input.warehouseId,
            clearSiteContact: !input.siteContactId,
            skipConfirmation: input.skipConfirmation,
            assignDefaultCostCodes: false,
            costCodeId: input.costCodeId,
            complianceGroupId: input.complianceGroupId,
          },
          batch,
          callback,
        );
      }
    }
    return true;
  };

  const accumulateChanges = (input: SubmitUpdateInput) => {
    changes.current = {
      ...changes.current,
      ...input,
      updates: mergeChanges(
        changes.current?.updates,
        input?.updates,
        "releaseItemId",
      ),
    };
    changes.current.updates = changes.current?.updates?.filter(
      (item, index, self) =>
        index ===
          self.findIndex((t) => t.releaseItemId === item.releaseItemId) &&
        !changes.current?.removedItems?.includes(item.releaseItemId),
    );
    setHasChanges(true);
  };

  const hasChanges = () => Boolean(changes.current); // || itemsToRemove.length > 0;

  const submitUpdate = async (input: SubmitUpdateInput) => {
    if (input) {
      accumulateChanges(input);
    }

    const allChanges = changes.current;

    const result = await updateReleaseMutation({
      ...allChanges,
      updates: input?.receive
        ? items
            .filter((item) =>
              selectedReleaseItemIds.find((id) => id === item.id),
            )
            .filter(
              (item) =>
                !allChanges?.updates?.find(
                  (u) =>
                    u.releaseItemId === item.id &&
                    u.receivedQuantityDecimal === "0",
                ),
            )
            .map((item) => {
              const existingItem = items.find(
                (releaseItem) => releaseItem.id === item.id,
              );
              const update = allChanges?.updates?.find(
                (u) => u.releaseItemId === item.id,
              );
              return update
                ? {
                    ...update,
                    receivedQuantityDecimal: new DecimalSafe(
                      update.receivedQuantityDecimal || 0,
                    )
                      .plus(existingItem?.receivedQuantityDecimal || 0)
                      .toString(),
                  }
                : {
                    releaseItemId: item.id,
                    receivedQuantityDecimal: existingItem?.quantityDecimal,
                  };
            })
        : allChanges?.updates,
      releaseId: release.id,
      issues: allChanges?.issues,
    });
    changes.current = undefined;

    if (result?.data) {
      updateStoreReleaseVersion(result.data);
    }
    const newAssignments = setReleaseVendorReassignments(result?.data);

    setHasChanges(false);
    setRemoving(false);

    setHasChanges(false);
    return {
      data: result?.data,
      reassignedReleases: newAssignments,
    };
  };

  const removeReleaseQuote = useCallback(async () => {
    await updateRelease({
      releaseId: release.id,
      version: release.version,
      clearQuoteDocument: true,
    });
    // updateRelease is not memoised
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release.id, release.version]);

  const removeReleaseNote = useCallback(async () => {
    await updateRelease({
      releaseId: release.id,
      version: release.version,
      clearNoteDocument: true,
    });
    // updateRelease is not memoised
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release.id, release.version]);

  return (
    <ProviderContext.Provider
      value={{
        updateRelease,
        hasChanges,
        submitUpdate,
        updating,
        release,
        project,
        removeReleaseItem,
        removing,
        addItems,
        selectedReleaseItemIds,
        setSelectedReleaseItemIds,
        requireDeliverySlip,
        setRequireDeliverySlip,
        updates: changes.current?.updates,
        updatingChanges,
        changes: changes.current,
        removeReleaseQuote,
        removeReleaseNote,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseUpdate = (): ProviderContextType =>
  useContext(ProviderContext);

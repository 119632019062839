import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { SuccessIcon } from "@/common/components/dialog-icons/SuccessIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { formattedDate } from "@/common/utils/dates/DateView";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { Footer } from "@/distributor/common/components/Footer";
import {
  AlternativeFulfillmentInput,
  ReleaseStatus,
  UpdateVendorReleaseFieldsFragment,
} from "@/generated/graphql";
import { NoFunctionPromise } from "@/types/NoFunction";
import { CircularProgress } from "@mui/material";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  DistributorReleaseErrorType,
  useDistributorRelease,
} from "../../providers/DistributorReleaseProvider";
import { DistributorReleaseDatePicker } from "../DistributorReleaseDatePicker";
import { ReleaseDeliveryDate } from "../ReleaseDeliveryDate";
import { DistributorReleaseAdditionalChargesAndTaxes } from "../distributor-release-additional-charges-and-taxes/DistributorReleaseAdditionalChargesAndTaxes";

const FooterContainer = tw(ButtonsContainer)`
  justify-end text-sm md:text-base gap-2 font-medium grid-flow-row md:grid-flow-col grid-cols-2 md:grid-cols-3 md:flex
`;

const FooterStyled = tw(Footer)`
  relative md:fixed grid justify-end mt-6 md:mt-20 gap-4 justify-items-end pt-6 md:pt-0 border-dotted md:border-solid
`;

const Saving = tw.div`
  flex items-center gap-2 text-green-600 absolute -top-16 text-sm
`;

const Container = tw.div`mr-4 col-span-2 md:col-span-1`;

const ChangeDeliveryDateButton = tw(OutlinedButton)`grid-rows-2`;
const ConfirmOrderButton = tw(PrimaryButton)`grid-rows-2`;

const SuccessDialogContent = tw.div`flex w-full flex-col items-center`;
const CloseTabContainer = tw.div`mt-4`;

export const DistributorReleaseFooter = () => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { setSuccessAlert, setWarningAlert } = useSnackbar();
  const {
    release,
    confirmAndUpdateRelease,
    updateVendorRelease,
    updating,
    setInputError,
    removeInputError,
    inputErrors,
  } = useDistributorRelease();
  const { orderTypeConfig } = useOrderTypesConfig({ release });

  const submit = useCallback(async () => {
    const successfullySubmitted = await confirmAndUpdateRelease();
    if (successfullySubmitted) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CLOSE" }),
        title: intl.$t({ id: "SUCCESS" }),
        content: (
          <SuccessDialogContent>
            <FormattedMessage
              id="DISTRIBUTOR_RELEASE_CONFIRM_SUCCESS"
              values={{ identifier: release?.sequenceNumber }}
              tagName="div"
            />

            <FormattedMessage
              id="DISTRIBUTOR_RELEASE_CONFIRM_SUCCESS_NOTIFIED"
              tagName="div"
            />

            <CloseTabContainer>
              <FormattedMessage
                id="DISTRIBUTOR_RELEASE_CONFIRM_SUCCESS_CLOSE_TAB"
                tagName="div"
              />
            </CloseTabContainer>
          </SuccessDialogContent>
        ),
        handleConfirm: NoFunctionPromise,
        icon: <SuccessIcon />,
        extraButtonMargin: false,
        fullScreenCentered: true,
      });
    }
  }, [confirmAndUpdateRelease, intl, openDialog, release?.sequenceNumber]);

  const confirmOrder = useCallback(async () => {
    if (
      release?.additionalCharges.find(
        (i) => !Number(i.amount) || !i.description,
      )
    ) {
      setInputError(DistributorReleaseErrorType.ADDITIONAL_PRICE);
      setWarningAlert(
        intl.$t({ id: "DISTRIBUTOR_RELEASE_ADDITIONAL_CHARGES" }),
      );
      return;
    } else {
      removeInputError(DistributorReleaseErrorType.ADDITIONAL_PRICE);
    }

    if (release?.status === ReleaseStatus.Requested) {
      await submit();
    } else {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "CONFIRM" }),
        closeOnConfirm: false,
        includeWarningIcon: true,
        title: intl.$t({
          id: "CONFIRM_RELEASE_UPDATE_QUESTION",
        }),
        text: intl.$t({
          id: "CONFIRM_RELEASE_TEXT",
        }),
        handleConfirm: submit,
      });
    }
  }, [
    intl,
    openDialog,
    release,
    removeInputError,
    setInputError,
    setWarningAlert,
    submit,
  ]);

  const onSave = useCallback(
    async (time: AlternativeFulfillmentInput) => {
      const updatedRelease = (await updateVendorRelease({
        time: time?.time,
      })) as UpdateVendorReleaseFieldsFragment;
      if (updatedRelease && updatedRelease.time) {
        setSuccessAlert(
          intl.$t(
            { id: "CHANGE_DELIVERY_DATE_SUCCESS" },
            { date: formattedDate({ date: updatedRelease.time }) },
          ),
        );
      }
    },
    [intl, setSuccessAlert, updateVendorRelease],
  );

  const openReleaseDateDialog = useCallback(() => {
    openDialog({
      title: orderTypeConfig.labels.backorderDateTitleLabel,
      titleClassName: "text-base text-left font-medium",
      content: (
        <DistributorReleaseDatePicker
          release={release}
          onSave={onSave}
          includeReleaseOptions={false}
          includeBackorderQuantity={false}
        />
      ),
    });
  }, [
    openDialog,
    orderTypeConfig.labels.backorderDateTitleLabel,
    release,
    onSave,
  ]);

  return (
    <FooterStyled>
      <If isTrue={updating}>
        <Saving>
          <CircularProgress size={11} />
          <FormattedMessage id="SAVING" />
        </Saving>
      </If>
      <DistributorReleaseAdditionalChargesAndTaxes
        release={release}
        readonly={checkReleaseStatus(release, [
          ReleaseStatus.Received,
          ReleaseStatus.PartiallyReceived,
          ReleaseStatus.Canceled,
        ])}
        showError={inputErrors.includes(
          DistributorReleaseErrorType.ADDITIONAL_PRICE,
        )}
      />
      <FloatingFooter>
        <FooterContainer>
          <Container>
            {release && (
              <ReleaseDeliveryDate
                release={release}
                classes={{
                  date: "font-light",
                  label: "items-end flex flex-col",
                }}
              />
            )}
          </Container>
          <If
            isTrue={checkReleaseStatus(release, [
              ReleaseStatus.Scheduled,
              ReleaseStatus.Requested,
            ])}
          >
            <ChangeDeliveryDateButton onClick={openReleaseDateDialog}>
              {orderTypeConfig.labels.buttons.changeDateAndTime}
            </ChangeDeliveryDateButton>
            <ConfirmOrderButton
              onClick={confirmOrder}
              autoFocus
              disabled={updating}
              testId="confirm-update-vendor-release"
            >
              {checkReleaseStatus(release, [ReleaseStatus.Scheduled]) ? (
                <FormattedMessage id="UPDATE_RELEASE" />
              ) : (
                orderTypeConfig.labels.buttons.confirm
              )}
            </ConfirmOrderButton>
          </If>
        </FooterContainer>
      </FloatingFooter>
    </FooterStyled>
  );
};

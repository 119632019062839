import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { DateView } from "@/common/utils/dates/DateView";
import { EditOutlined } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { DeliverySlipDatePicker } from "../delivery-slip-date-picker/DeliverySlipDatePicker";

type Props = {
  title: string;
  date: Date | null;
  isEditing: boolean;
  toggleEditing: () => void;
  onChange: (d: Date | null) => void;
  readonly?: boolean;
};

const Container = tw.div`flex flex-row justify-end items-center h-10 text-sm flex-1 ${(props: { $error?: boolean }) => props.$error && "text-red-500"}`;
const NonEditableView = tw.div`ml-3 flex flex-1 flex-row items-center justify-between pr-3 gap-2`;
const Title = tw.div`font-bold flex items-center gap-1 justify-end text-right`;

export const DeliverySlipDateEdit: FC<Props> = ({
  title,
  date,
  isEditing,
  toggleEditing,
  onChange,
  readonly = false,
}) => {
  return (
    <Container>
      <Title>{title}:</Title>
      <If isTrue={!isEditing}>
        <NonEditableView>
          <NotNullableRenderer value={date}>
            <DateView date={date} utc />
          </NotNullableRenderer>
          <If isTrue={!readonly}>
            <LinkLike>
              <EditOutlined onClick={toggleEditing} fontSize="small" />
            </LinkLike>
          </If>
        </NonEditableView>
      </If>
      <If isTrue={isEditing}>
        <DeliverySlipDatePicker
          date={date}
          onSave={onChange}
          onClose={toggleEditing}
        />
      </If>
    </Container>
  );
};

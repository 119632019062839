import { TransactionItemOrigin } from "@/contractor/pages/home/inventory-management/common/components/transaction-origin/TransactionItemOrigin";
import { InventoryItemPendingFlowFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { TransactionOrigin } from "../../../../../transaction-history-stock/components/transaction-history-list/types/TransactionOrigin";

type Props = {
  item: InventoryItemPendingFlowFieldsFragment;
};

export const ScheduledInflowFrom = ({ item }: Props) => {
  const intl = useIntl();
  const transactionItem: TransactionOrigin = useMemo(
    () => ({
      name:
        item.release.sellerOrgLocation?.org.name ??
        item.release.warehouse?.name,
      typeName: intl.$t({
        id: item.release.warehouse?.name ? "WAREHOUSE" : "VENDOR",
      }),
      org: item.release.sellerOrgLocation?.org,
    }),
    [intl, item.release.sellerOrgLocation?.org, item.release.warehouse?.name],
  );

  return <TransactionItemOrigin item={transactionItem} />;
};

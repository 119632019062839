import { useMemo } from "react";
import { useIntegrationFeatureRequirement } from "../components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "./integrations/types/IntegrationFeature";

export const useHeaderOnlyInvoiceOption = () => {
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const hasHeaderOnlyInvoiceOption = useMemo(
    () =>
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.HeaderOnlyInvoiceProcessing,
      ),
    [hasFeatureInConnectedSourceSystem],
  );

  return {
    hasHeaderOnlyInvoiceOption,
  };
};

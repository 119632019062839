import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useGlobalReleaseInstructions } from "./useGlobalReleaseInstructions";

export const useInstructionReleaseSetup = (applyValues: boolean = true) => {
  const { instructionText, instructionAssets } = useGlobalReleaseInstructions();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!applyValues) {
      return;
    }
    if (instructionText) {
      setValue("instructions.text", instructionText);
    }
    if (instructionAssets.length) {
      setValue(
        "instructions.assetUrls",
        instructionAssets.map((asset) => asset.url),
      );
    }
  }, [instructionText, instructionAssets, setValue, applyValues]);
};

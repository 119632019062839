import { InfoOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import {
  SimpleStepRow,
  SimpleStepTitle,
  SimpleStepTitleContainer,
} from "../../../../../Wizard.styles";

export const TemplateHeader = () => (
  <SimpleStepRow className="mb-6">
    <InfoOutlined className="mr-4 h-12 w-12 text-gray-400" />
    <SimpleStepTitleContainer>
      <SimpleStepTitle>
        <FormattedMessage id="TEMPLATE_STRINGS" />
      </SimpleStepTitle>
    </SimpleStepTitleContainer>
  </SimpleStepRow>
);

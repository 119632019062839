import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { getInventoryItemFromTransaction } from "../../../../../../../utils/getInventoryItemFromTransaction";
import { TransactionHistoryItemNote } from "../../../../../../common/transaction-history-item-note/TransactionHistoryItemNote";

type Props = {
  item: InventoryTransactionSummaryFieldsFragment;
  inventoryItemId: string;
};

export const TransactionItemNotes = ({ item, inventoryItemId }: Props) => {
  const inventoryItem = useMemo(
    () => getInventoryItemFromTransaction(item, inventoryItemId),
    [inventoryItemId, item],
  );
  return <TransactionHistoryItemNote notes={inventoryItem?.notes} />;
};

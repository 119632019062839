import { useCallback, useMemo } from "react";
import { useInventoryItems } from "../../../../hooks/useInventoryItems";
import {
  createSelectedItemId,
  UNDEFINED_WAREHOUSE_ID,
} from "../../../../utils/getSelectedItemIdParts";
import { useInventoryItemSequence } from "../hooks/useInventoryItemSequence";

export const useInventoryItemAllItemsCheckbox = () => {
  const { selectedEntities, setSelectedEntities } = useInventoryItemSequence();
  const { groupedItems } = useInventoryItems();

  const nonArchivedItems = useMemo(
    () =>
      groupedItems
        .filter((item) => !item.archivedAt)
        .map((item) =>
          item.warehouses.length > 0
            ? item.warehouses.map((warehouse) => ({
                id: createSelectedItemId(warehouse.warehouse.id, item.id),
              }))
            : [
                {
                  id: createSelectedItemId(UNDEFINED_WAREHOUSE_ID, item.id),
                },
              ],
        )
        .flat(),
    [groupedItems],
  );

  const isIncluded = useMemo(
    () => nonArchivedItems.length > 0,
    [nonArchivedItems],
  );

  const checked = useMemo(
    () =>
      nonArchivedItems.length > 0 &&
      nonArchivedItems.every((d) =>
        selectedEntities.some((s) => s.id === d.id),
      ),
    [nonArchivedItems, selectedEntities],
  );

  const handleChange = useCallback(() => {
    const filteredItems = nonArchivedItems;
    setSelectedEntities(
      checked
        ? selectedEntities.filter(
            (i) => !filteredItems.some((f) => f.id === i.id),
          )
        : [
            ...selectedEntities.filter(
              (i) => !filteredItems.some((f) => f.id === i.id),
            ),
            ...filteredItems,
          ],
    );
  }, [nonArchivedItems, setSelectedEntities, checked, selectedEntities]);

  return { handleChange, selectedEntities, checked, isIncluded };
};

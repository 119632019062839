import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { routes } from "@/config/routes";
import { ProjectExtendedFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useLocation } from "react-router";
import { NewBuyoutForm } from "./NewBuyoutForm";

type Props = {
  disabled?: boolean;
  wide?: boolean;
  project?: ProjectExtendedFieldsFragment | null;
  small?: boolean;
  hasEstimatedItems?: boolean;
};

export const NewBuyoutButton: FC<Props> = ({
  disabled,
  wide,
  project,
  small = false,
  hasEstimatedItems,
}) => {
  const intl = useIntl();
  const location = useLocation();

  const forceOpenEstimatedItems = useMemo(() => {
    return (
      hasEstimatedItems &&
      !!project?.id &&
      location.pathname ===
        generatePath(routes.projectEstimatedItems, { id: project.id })
    );
  }, [hasEstimatedItems, location.pathname, project?.id]);

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <PrimaryButton
          wide={wide}
          $small={small}
          testId="new-release-button"
          onClick={() => togglePanel(true)}
          disabled={disabled}
        >
          <FormattedMessage id="NEW_BUYOUT" />
        </PrimaryButton>
      )}
      content={(togglePanel) => (
        <NewBuyoutForm
          project={project}
          label={intl.$t({ id: "NEW_BUYOUT" })}
          onClose={() => togglePanel(false)}
          forceEstimatedItemsOnNewBuyout={forceOpenEstimatedItems}
        />
      )}
    />
  );
};

import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { Check } from "@mui/icons-material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { UNASSIGNED_ACTION, useSideActionsStore } from "./useSideActionsStore";

const Container = tw.div`
  grid fixed left-0 top-0 h-screen rounded-r-lg py-1 content-center
  animate-[enter-left_0.3s_ease-in-out_forwards] z-70
`;

type ActionProps = {
  $active: boolean;
};

const ActionContainer = tw.div<ActionProps>`  
  grid grid-flow-col w-fit items-center gap-2 h-12
  first:rounded-tr-lg last:rounded-br-lg
  ${({ $active }) => ($active ? "bg-transparent" : "bg-blue-450")}
`;
const Action = tw.div<ActionProps>`
  grid grid-flow-col items-center gap-2 h-12 min-w-10
  ${({ $active }) =>
    $active ? "text-white bg-blue-800 rounded-r-lg" : "text-blue-800"}
`;

const ActionIconButton = tw(IconButtonBorderless)<ActionProps>`
  text-blue-800 hover:opacity-100 hover:text-blue-800 w-10 h-10 disabled:text-gray-600
  ${({ $active }) =>
    $active
      ? "text-white hover:text-white opacity-100"
      : "text-blue-800 opacity-60 "}
`;

const ComponentContainer = tw.div`
  grid grid-flow-col items-center gap-2 animate-[fade-in_0.1s_ease-in-out_forwards] min-w-10
`;

type Action = {
  icon: React.ReactNode;
  tooltipKey: string;
  disabled?: boolean;
  component?: React.ReactNode;
  callback?: () => void;
  preventClose?: boolean;
};

type Props = {
  actions: Action[];
  visible?: boolean;
  loading?: boolean;
};

export const SideActionBar: FC<Props> = ({ actions, visible, loading }) => {
  const [hideLoader, setHideLoader] = useState(true);
  const { selectedAction, setSelectedAction, reset } = useSideActionsStore(
    useShallow((state) => ({
      selectedAction: state.selectedAction,
      setSelectedAction: state.setSelectedAction,
      reset: state.reset,
    })),
  );

  useEffect(() => {
    reset();
  }, [reset]);

  const handleClick = useCallback(
    (index: number) => {
      if (selectedAction === index) {
        setSelectedAction(UNASSIGNED_ACTION);
      } else if (actions[index].component) {
        setSelectedAction(index);
      } else {
        actions[index].callback?.();
        setSelectedAction(UNASSIGNED_ACTION);
      }
    },
    [actions, selectedAction, setSelectedAction],
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        !target.closest("#action") &&
        !(
          target.closest(".MuiPopper-root") &&
          actions[selectedAction].preventClose
        )
      ) {
        setSelectedAction(UNASSIGNED_ACTION);
      }
    };

    if (selectedAction !== -1) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [actions, selectedAction, setSelectedAction]);

  useEffect(() => {
    if (loading) {
      setHideLoader(false);
    }

    if (!loading && hideLoader === false) {
      setTimeout(() => {
        setHideLoader(true);
      }, 500);
    }
  }, [hideLoader, loading]);

  if (!visible && hideLoader) {
    return null;
  }

  return (
    <Container>
      <If isTrue={loading || hideLoader === false}>
        <ActionContainer
          $active={false}
          className="h-fit animate-[warp-in_0.4s_ease-in-out_forwards] px-3 py-5"
        >
          <If isTrue={loading}>
            <Loader loading small circleClassName="text-blue-800" />
          </If>
          <If isTrue={!loading}>
            <Check className="text-base text-blue-500" />
          </If>
        </ActionContainer>
      </If>
      <If isTrue={!loading && hideLoader}>
        {actions.map((action, index) => (
          <ActionContainer
            data-testid="actionContainer"
            id="action"
            key={action.tooltipKey}
            $active={selectedAction === index}
          >
            <Tooltip
              id={`action-${action.tooltipKey}`}
              position="left"
              element={
                <Action $active={selectedAction === index}>
                  <If isTrue={selectedAction !== index}>
                    <ActionIconButton
                      onClick={() => handleClick(index)}
                      $active={false}
                      disabled={action.disabled}
                    >
                      {action.icon}
                    </ActionIconButton>
                  </If>
                  <If isTrue={selectedAction === index}>
                    <ComponentContainer>
                      <ActionIconButton
                        onClick={() => handleClick(index)}
                        $active
                        disabled={action.disabled}
                      >
                        {action.icon}
                      </ActionIconButton>
                      {action.component}
                    </ComponentContainer>
                  </If>
                </Action>
              }
            >
              <FormattedMessage id={action.tooltipKey} />
            </Tooltip>
          </ActionContainer>
        ))}
      </If>
    </Container>
  );
};

import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { ReleaseFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { isWarehouseRelease } from "../../../../utils/isWarehouseRelease";

export enum ReleaseAlertType {
  Submit = "submit",
  Confirm = "confirm",
  Update = "update",
}

export const useReleaseAlert = () => {
  const { setSystemAlert } = useSnackbar();
  const intl = useIntl();

  const triggerAlert = useCallback(
    (
      type: ReleaseAlertType,
      opts?: {
        isSplit?: boolean;
        release?: Pick<
          ReleaseFieldsFragment,
          "id" | "status" | "sourceWarehouse"
        > | null;
      },
    ) => {
      let messageKey = "";
      switch (type) {
        case ReleaseAlertType.Confirm:
          if (isWarehouseRelease(opts?.release)) {
            messageKey = opts?.isSplit
              ? "WAREHOUSE_ORDER_WAS_CONFIRMED_SPLIT"
              : "WAREHOUSE_ORDER_WAS_CONFIRMED";
          }
          break;
        case ReleaseAlertType.Submit:
          switch (opts?.release?.status) {
            case ReleaseStatus.AwaitingApproval: {
              messageKey = "ORDER_SUBMITTED_FOR_APPROVAL";
              break;
            }
            case ReleaseStatus.Requested: {
              if (isWarehouseRelease(opts.release)) {
                messageKey = "WAREHOUSE_ORDER_SUBMITTED_FOR_APPROVAL";
                break;
              }

              messageKey = opts.isSplit
                ? "ORDER_SUCCESSFULLY_SUBMITTED_SPLIT"
                : "ORDER_SUCCESSFULLY_SUBMITTED";
              break;
            }
            case ReleaseStatus.Scheduled: {
              messageKey = opts.isSplit
                ? "ORDER_SUCCESSFULLY_SUBMITTED_SPLIT"
                : "ORDER_SUCCESSFULLY_SUBMITTED";
              break;
            }
            case ReleaseStatus.Received: {
              messageKey = "ORDER_SUCCESSFULLY_SUBMITTED_RETROACTIVE";
              break;
            }
          }
          break;
        case ReleaseAlertType.Update:
          messageKey = "ORDER_SUCCESSFULLY_UPDATED";
          break;
      }
      if (messageKey) {
        setSystemAlert(intl.$t({ id: messageKey }, { br: "\n" }), {
          type: SystemAlertType.MANUAL,
        });
      }
    },
    [intl, setSystemAlert],
  );

  return { triggerAlert };
};

import { useCallback } from "react";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

export const useDistributorQuoteUnitPriceInput = ({
  item,
}: {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
}) => {
  const { updateQuote } = useDistributorQuote();
  const onChange = useCallback(
    async (unitPrice: string | null) => {
      const floatValue = parseFloat(unitPrice || "");
      const isNegative = unitPrice !== null && floatValue < 0;
      const isInvalidValue = unitPrice === null || isNaN(floatValue);
      await updateQuote({
        updates: [
          {
            quoteItemId: item.quoteItems[0]?.id,
            unitPrice: isInvalidValue
              ? undefined
              : isNegative
                ? "0"
                : unitPrice,
            clearUnitPrice: isInvalidValue,
          },
        ],
      });
    },
    [item.quoteItems, updateQuote],
  );

  return { onChange };
};

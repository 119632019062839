import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { CheckCircleOutline } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { MatchedOrderViewState } from "../../../../../../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceItemQuantityInput } from "./hooks/useInvoiceItemQuantityInput";

const Container = tw.div`relative`;
const ReadonlyField = tw.div`flex flex-col items-center text-xs`;
const CheckIcon = tw(
  CheckCircleOutline,
)`text-green-600 absolute left-1 top-1/2 transform -translate-y-1/2 text-lg`;
const InvoiceSoFarLabel = tw.span`text-[9px] text-gray-600`;
const RemContainer = tw.span`${({ $error }: { $error: boolean }) => ($error ? "text-red-500" : "")}`;

type Props = {
  item: ExpandedReleaseItem;
  readonly?: boolean;
};

export const InvoiceItemQuantityInput: FC<Props> = ({
  item,
  readonly = false,
}) => {
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const {
    quantity,
    setQuantity,
    onSave,
    inputClassName,
    isFullyInvoiced,
    remainingInvoiceQuantity,
    invoicedByOtherInvoices,
  } = useInvoiceItemQuantityInput(item);
  return (
    <NotNullableRenderer value={!isLumpSumItem(item)}>
      <>
        <If
          isTrue={
            matchedOrderViewState ===
              MatchedOrderViewState.EDIT_INVOICE_COVERAGES &&
            (item.invoiceItems || []).length > 0
          }
        >
          <Container>
            <QuantityPicker
              key={item.id}
              id="invoice-item-quantity-picker"
              quantity={quantity}
              changeQuantity={setQuantity}
              helperText={
                <Tooltip
                  position="bottom"
                  id="invoice-item-quantity-picker-tooltip"
                  element={
                    <RemContainer
                      $error={new DecimalSafe(
                        remainingInvoiceQuantity,
                      ).lessThan(0)}
                    >
                      <FormattedMessage
                        id="REMAINING_VALUE"
                        values={{ value: remainingInvoiceQuantity }}
                      />
                    </RemContainer>
                  }
                  hideTooltip={new DecimalSafe(
                    invoicedByOtherInvoices,
                  ).isZero()}
                >
                  <FormattedMessage
                    id="PREVIOUSLY_INVOICED"
                    values={{ quantity: invoicedByOtherInvoices }}
                  />
                </Tooltip>
              }
              saveQuantity={onSave}
              className={inputClassName}
              hideErrorIcon
            />
            {isFullyInvoiced && <CheckIcon />}
          </Container>
        </If>
        <If
          isTrue={
            matchedOrderViewState !==
              MatchedOrderViewState.EDIT_INVOICE_COVERAGES ||
            (!readonly && item.invoicedQuantity)
          }
        >
          <NotNullableRenderer
            value={
              !isNaN(Number(item.invoicedQuantity)) &&
              item.invoiceItems.length > 0
            }
          >
            <ReadonlyField>
              <QuantityPicker
                id="invoiced-so-far"
                staticText
                quantity={item.invoiceItems?.[0]?.quantity}
              />
              <FormattedMessage
                id="QUANTITY_SO_FAR"
                tagName={InvoiceSoFarLabel}
                values={{
                  value: new DecimalSafe(invoicedByOtherInvoices)
                    .plus(item.invoiceItems?.[0]?.quantity || "0")
                    .toString(),
                }}
              />
            </ReadonlyField>
          </NotNullableRenderer>
        </If>
      </>
    </NotNullableRenderer>
  );
};

import { CostCodesSelector } from "@/common/components/cost-codes-selector/CostCodesSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { Select } from "@/common/components/select/components/single/Select";
import { ALL_SUPPLIERS_ID } from "@/common/components/supplier-picker/const/allSuppliersId";
import { VendorsFilterSelector } from "@/common/components/vendors-filter-selector/VendorsFilterSelector";
import { GeneralFilter } from "@/common/const";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { VendorPricesCondition } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useMaterialPrices } from "../providers/MaterialPricesProvider";

const FILTER_OPTIONS = [
  { id: GeneralFilter.All, name: "SHOW_ALL" },
  {
    id: VendorPricesCondition.Expired,
    name: "VENDOR_PRICES_CONDITION_EXPIRED",
  },
  {
    id: VendorPricesCondition.WithoutPrices,
    name: "VENDOR_PRICES_CONDITION_WITHOUT_PRICES",
  },
  {
    id: VendorPricesCondition.WithPrices,
    name: "VENDOR_PRICES_CONDITION_WITH_PRICES",
  },
];

const HeaderContainer = tw.div`
  grid grid-cols-[repeat(4,minmax(auto,_1fr))_auto_1fr] bg-gray-100  
  sticky pb-4 pt-5 z-20 
  space-x-1 justify-start top-[57px] xl:top-[57px] gap-1
`;

const Spacer = tw.div`h-6`;

export const MaterialPricesHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useMaterialPrices();
  const { projectCostCodes } = useProjectCostCodes();
  const viewState = useTableViewStore((state) => state.viewState);

  const onVendorChange = useCallback(
    (value: string | null) => {
      setFilter({
        ...filter,
        sellerOrgLocationId: value === ALL_SUPPLIERS_ID ? undefined : value,
      });
    },
    [filter, setFilter],
  );

  if (viewState !== TableViewState.normal) {
    return <Spacer />;
  }

  return (
    <HeaderContainer>
      <SearchInput
        placeHolder={intl.$t({ id: "SEARCH_MATERIALS" })}
        onChange={(value: string) => setFilter({ ...filter, search: value })}
      />
      <VendorsFilterSelector
        value={filter?.sellerOrgLocationId}
        onChange={onVendorChange}
        includeAllOption={false}
        placeholder={intl.$t({ id: "SUPPLIER" })}
      />
      <CostCodesSelector
        className="max-w-80"
        options={projectCostCodes}
        selectedCostCodes={filter?.costCodeIds || []}
        setSelectedCostCodes={(costCodeIds) =>
          setFilter({ ...filter, costCodeIds })
        }
        limitTags={2}
      />
      <Select
        testId="filter-selector"
        options={FILTER_OPTIONS}
        placeholder={intl.$t({ id: "FILTERS" })}
        getLabel={(o) => intl.$t({ id: o.name })}
        getValue={(o) => o.id?.toString()}
        value={filter?.priceCondition}
        onChange={(id) => {
          setFilter({
            ...filter,
            priceCondition:
              id !== GeneralFilter.All
                ? (id as VendorPricesCondition)
                : undefined,
          });
        }}
      />
    </HeaderContainer>
  );
};

import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";

import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";

import { ItemizedNonItemizedToggle } from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { RELEASE_DRAFT_STATUSES } from "@/common/const";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseStatus } from "@/generated/graphql";
import { ChevronLeft } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { CreateOrderFromQuoteHeader } from "./create-order-from-quote-header/CreateOrderFromQuoteHeader";
import { CreateOrderFromQuoteItemizedView } from "./CreateOrderFromQuoteItemizedView";
import { CreateOrderFromQuoteNonItemizedView } from "./CreateOrderFromQuoteNonItemizedView";
import { useCreateOrderFromQuoteDetails } from "./useCreateOrderFromQuoteDetails";

const Container = tw.div`bg-gray-100 rounded-3xl pt-4 px-4 h-fit overflow-y-scroll`;
const HeaderRow = tw.div`flex flex-row justify-between mb-1`;
const Header = tw.div`grid pl-1 text-base font-medium items-center`;
const ViewToggleContainer = tw.div`flex flex-1 justify-end mb-2`;

const HeaderGroup = tw.div`grid grid-cols-[auto_auto_1fr] w-full place-content-center place-items-center gap-1 mb-4`;
const ButtonGroup = tw.div`grid grid-flow-col w-fit gap-2 justify-self-end pr-2`;
const BackButton = tw.button`grid place-items-center bg-blue-800 rounded-md text-white`;
const ViewContainer = tw.div<{ $show: boolean }>`
  ${({ $show }) => !$show && "opacity-0 h-0 fixed -bottom-full"}
`;

export const CreateOrderFromQuoteDetails: FC = () => {
  const { release } = useRelease();
  const {
    handleToggleItemizedView,
    itemized,
    handleClick,
    copyPriceFromQuote,
    addQuoteItems,
    itemizedQuoteTableItems,
    total,
    updateSubtotal,
  } = useCreateOrderFromQuoteDetails();

  const { orderTypes } = useOrderTypeOptions();

  return (
    <Container>
      <If isTrue={release}>
        <If
          isTrue={RELEASE_DRAFT_STATUSES.includes(
            release?.status as ReleaseStatus,
          )}
        >
          <ViewToggleContainer>
            <ItemizedNonItemizedToggle
              handleChange={handleToggleItemizedView}
              isItemized={itemized}
            />
          </ViewToggleContainer>
        </If>
        <HeaderGroup>
          <BackButton onClick={handleClick}>
            <ChevronLeft />
          </BackButton>
          <FormattedMessage id="ADD_ITEMS_AND_COPY_PRICES" tagName={Header} />
          <ButtonGroup>
            <OutlinedButton
              $small
              className="ml-2"
              onClick={() => copyPriceFromQuote()}
            >
              <FormattedMessage id="COPY_PRICES_FROM_QUOTE" />
            </OutlinedButton>
            <OutlinedButton $small onClick={() => addQuoteItems()}>
              <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_QUOTE" />
            </OutlinedButton>
          </ButtonGroup>
        </HeaderGroup>
      </If>
      <If isTrue={!release}>
        <HeaderRow>
          <FormattedMessage id="NEW_RELEASE" tagName={Header} />
          <ItemizedNonItemizedToggle
            handleChange={handleToggleItemizedView}
            isItemized={itemized}
          />
        </HeaderRow>
      </If>
      <CreateOrderFromQuoteHeader />
      <ViewContainer $show={itemized}>
        <CreateOrderFromQuoteItemizedView
          tableItems={itemizedQuoteTableItems}
          orderTypes={orderTypes}
          total={total}
          updateSubtotal={updateSubtotal}
        />
      </ViewContainer>
      <ViewContainer $show={!itemized}>
        <CreateOrderFromQuoteNonItemizedView />
      </ViewContainer>
    </Container>
  );
};

import { ExternalVendorFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

export type ImportExternalVendorsForm = {
  vendors: ExternalVendorFieldsFragment[];
};

type Props = {
  children: React.ReactNode;
};

export const ImportExternalVendorsFormProvider: FC<Props> = ({ children }) => {
  const form = useForm<ImportExternalVendorsForm>({
    defaultValues: {
      vendors: [],
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  return <FormProvider {...form}>{children}</FormProvider>;
};

import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import {
  PreviewCustomInvoiceTemplatesDocument,
  PreviewCustomInvoiceTemplatesMutation,
  PreviewCustomInvoiceTemplatesMutationVariables,
  PreviewCustomPoTemplatesDocument,
  PreviewCustomPoTemplatesMutation,
  PreviewCustomPoTemplatesMutationVariables,
} from "@/generated/graphql";
import { StateCreator } from "zustand";
import { TemplateBaseVarEnum } from "../../enums/TemplateBaseVars.enum";
import { TemplateTypeEnum } from "../../enums/TemplateType.enum";
import { Template } from "../../types/Template";
import { TemplateStringsState } from "../useTemplateStringsStore";
import { getCustomTemplateDefaults } from "../utils/getCustomTemplateDefaults";

export interface CustomTemplatesSlice {
  customPOTemplates: Template[];
  customInvoiceTemplates: Template[];
  newTemplateName: string;
  customTemplateDefaults: Record<
    TemplateBaseVarEnum,
    { name: string; value: string }[]
  >;
  addCustomPOTemplates: () => void;
  addCustomInvoiceTemplates: () => void;
  setCustomPOTemplates: (templates: Template[]) => Promise<void>;
  setCustomInvoiceTemplates: (templates: Template[]) => Promise<void>;
  updateNewTemplateName: (name: string) => void;
  updateCustomTemplateDefaults: (
    defaults: Record<TemplateBaseVarEnum, { name: string; value: string }[]>,
  ) => Promise<void>;
  replaceTemplate: (template: Template, newTemplate: Template) => void;
  removeTemplate: (template: Template) => void;
}

const addTemplate = (
  templates: Template[],
  name: string,
  type: TemplateTypeEnum,
): Template[] => [...templates, { name, template: "", type }];

const updateTemplatesWithPreview = (
  templates: Template[],
  previewData: string[] | undefined,
): Template[] =>
  templates.map((t, index) => ({
    ...t,
    preview: previewData?.[index] ?? "",
  }));

export const createCustomTemplatesSlice: StateCreator<
  TemplateStringsState,
  [],
  [],
  CustomTemplatesSlice
> = (set, get) => ({
  customPOTemplates: [],
  customInvoiceTemplates: [],
  newTemplateName: "",
  customTemplateDefaults: getCustomTemplateDefaults(),

  addCustomPOTemplates: () =>
    set((state) => ({
      customPOTemplates: addTemplate(
        state.customPOTemplates,
        state.newTemplateName,
        TemplateTypeEnum.PO,
      ),
      newTemplateName: "",
    })),

  addCustomInvoiceTemplates: () =>
    set((state) => ({
      customInvoiceTemplates: addTemplate(
        state.customInvoiceTemplates,
        state.newTemplateName,
        TemplateTypeEnum.INVOICE,
      ),
      newTemplateName: "",
    })),

  setCustomPOTemplates: async (templates) => {
    const client = useApolloClientStore.getState().client;
    if (!client) {
      return;
    }

    const {
      [TemplateBaseVarEnum.PROJECT]: project,
      [TemplateBaseVarEnum.RELEASE]: release,
      [TemplateBaseVarEnum.BUYOUT]: buyout,
      [TemplateBaseVarEnum.EXTERNAL_VENDOR]: externalVendor,
    } = get().customTemplateDefaults;

    const { data } = await client.mutate<
      PreviewCustomPoTemplatesMutation,
      PreviewCustomPoTemplatesMutationVariables
    >({
      mutation: PreviewCustomPoTemplatesDocument,
      variables: {
        input: {
          templates: templates.map(({ name, template }) => ({
            name,
            template,
          })),
          project,
          release,
          buyout,
          externalVendor,
        },
      },
    });

    set({
      customPOTemplates: updateTemplatesWithPreview(
        templates,
        data?.previewCustomPoTemplates ?? undefined,
      ),
    });
  },

  setCustomInvoiceTemplates: async (templates) => {
    const client = useApolloClientStore.getState().client;
    if (!client) {
      return;
    }

    const { data } = await client.mutate<
      PreviewCustomInvoiceTemplatesMutation,
      PreviewCustomInvoiceTemplatesMutationVariables
    >({
      mutation: PreviewCustomInvoiceTemplatesDocument,
      variables: {
        input: {
          templates: templates.map(({ name, template }) => ({
            name,
            template,
          })),
          ...get().customTemplateDefaults,
        },
      },
    });

    set({
      customInvoiceTemplates: updateTemplatesWithPreview(
        templates,
        data?.previewCustomInvoiceTemplates ?? undefined,
      ),
    });
  },

  updateNewTemplateName: (name) => set({ newTemplateName: name }),

  updateCustomTemplateDefaults: async (defaults) => {
    set({ customTemplateDefaults: defaults });
    await Promise.all([
      get().setCustomPOTemplates(get().customPOTemplates),
      get().setCustomInvoiceTemplates(get().customInvoiceTemplates),
    ]);
  },

  removeTemplate: (template: Template) =>
    set((state) => {
      type TemplateArrayKey = "customPOTemplates" | "customInvoiceTemplates";
      const keyMap: Record<TemplateTypeEnum, TemplateArrayKey> = {
        [TemplateTypeEnum.PO]: "customPOTemplates",
        [TemplateTypeEnum.INVOICE]: "customInvoiceTemplates",
      };
      const stateKey = keyMap[template.type];
      return {
        [stateKey]: (state[stateKey] as Template[]).filter(
          (t) => t.name !== template.name,
        ),
      };
    }),

  replaceTemplate: (template: Template, newTemplate: Template) =>
    set((state) => {
      type TemplateArrayKey = "customPOTemplates" | "customInvoiceTemplates";
      const keyMap: Record<TemplateTypeEnum, TemplateArrayKey> = {
        [TemplateTypeEnum.PO]: "customPOTemplates",
        [TemplateTypeEnum.INVOICE]: "customInvoiceTemplates",
      };
      const stateKey = keyMap[template.type];
      const templates = [...(state[stateKey] as Template[])];
      const idx = templates.findIndex((t) => t.name === template.name);
      if (idx !== -1) {
        templates[idx] = { ...newTemplate, type: template.type };
      }
      return { [stateKey]: templates };
    }),
});

import { If } from "@/common/components/if/If";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { DEFAULT_ADDRESS_COUNTRY } from "@/common/const";
import { countries } from "@/common/utils/countries";
import { usaStates } from "@/common/utils/usaStates";
import { ExternalVendorFieldsFragment } from "@/generated/graphql";
import { FC, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { VendorImportInputStatus } from "./VendorImportInputStatus";

const Container = tw.div`grid grid-cols-[3fr_1fr_2fr] gap-3 py-4 px-8 mt-3`;
const Row = tw.div`flex flex-row items-center gap-1 w-full`;
const Label = tw.div`text-sm font-medium`;

type Props = {
  index: number;
  externalVendor?: ExternalVendorFieldsFragment;
};

export const VendorImportForm: FC<Props> = ({ index, externalVendor }) => {
  const intl = useIntl();
  const { getFieldState, watch, trigger } = useFormContext();

  const country = watch(`vendors.${index}.address.country`);

  const requiredStateAndCode = useMemo(
    () => country === DEFAULT_ADDRESS_COUNTRY,
    [country],
  );

  useEffect(() => {
    trigger();
  }, [requiredStateAndCode, trigger]);

  return (
    <Container>
      <Label className="col-span-full">
        <FormattedMessage id="SPECIFY" />:
      </Label>
      <If isTrue={!externalVendor?.address?.addressLine1}>
        <Row className="col-span-full">
          <VendorImportInputStatus
            success={
              !getFieldState(`vendors.${index}.address.addressLine1`).error
            }
          />
          <TextFieldControlled
            name={`vendors.${index}.address.addressLine1`}
            size="small"
            label={intl.$t({ id: "LOCATION_ADDRESS_LINE_1" })}
            rules={{ required: true }}
          />
        </Row>
      </If>
      <If isTrue={!externalVendor?.address?.addressLine2}>
        <Row className="col-span-full pl-7">
          <TextFieldControlled
            name={`vendors.${index}.address.addressLine2`}
            size="small"
            label={`${intl.$t({ id: "LOCATION_ADDRESS_LINE_2" })} (${intl.$t({ id: "OPTIONAL" })})`}
            rules={{ required: false }}
          />
        </Row>
      </If>
      <If isTrue={!externalVendor?.address?.city}>
        <Row className="col-span-2">
          <VendorImportInputStatus
            success={!getFieldState(`vendors.${index}.address.city`).error}
          />
          <TextFieldControlled
            name={`vendors.${index}.address.city`}
            size="small"
            label={intl.$t({ id: "ADDRESS_CITY" })}
            rules={{ required: true }}
          />
        </Row>
      </If>
      <If isTrue={!externalVendor?.address?.postalCode}>
        <Row className="span-col">
          <If isTrue={requiredStateAndCode}>
            <VendorImportInputStatus
              success={
                !getFieldState(`vendors.${index}.address.postalCode`).error
              }
            />
          </If>
          <TextFieldControlled
            name={`vendors.${index}.address.postalCode`}
            size="small"
            label={intl.$t({ id: "ADDRESS_ZIP_CODE" })}
            rules={{ required: requiredStateAndCode }}
          />
        </Row>
      </If>
      <If isTrue={!externalVendor?.address?.country}>
        <Row className="col-span-2">
          <VendorImportInputStatus
            success={!getFieldState(`vendors.${index}.address.country`).error}
          />
          <SelectControlled
            options={countries}
            name={`vendors.${index}.address.country`}
            getLabel={(option) => option.name}
            getValue={(option) => option.abbreviation}
            placeholder={intl.$t({ id: "COUNTRY" })}
            rules={{ required: true }}
            className="w-full"
          />
        </Row>
      </If>
      <If isTrue={!externalVendor?.address?.state}>
        {requiredStateAndCode ? (
          <Row>
            <VendorImportInputStatus
              success={!getFieldState(`vendors.${index}.address.state`).error}
            />
            <SelectControlled
              options={usaStates}
              name={`vendors.${index}.address.state`}
              getLabel={(option) => option.abbreviation}
              getValue={(option) => option.abbreviation}
              className="min-w-32"
              placeholder={intl.$t({ id: "STATE" })}
              rules={{ required: true }}
            />
          </Row>
        ) : (
          <TextFieldControlled
            size="small"
            name={`vendor.${index}.address.state`}
            label={intl.$t({ id: "ADDRESS_STATE" })}
            rules={{ required: false }}
            className=""
          />
        )}
      </If>
    </Container>
  );
};

import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { TransactionHistoryItemDetails } from "../../transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";

type Props = {
  notes: string | null | undefined;
  className?: string;
};

export const TransactionHistoryItemNote = ({ notes, className }: Props) => {
  return (
    <NotNullableRenderer value={notes}>
      <TransactionHistoryItemDetails className={className}>
        {notes}
      </TransactionHistoryItemDetails>
    </NotNullableRenderer>
  );
};

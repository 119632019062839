import { If } from "@/common/components/if/If";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { InvoiceIssues } from "@/common/components/invoices/invoice-issue/InvoiceIssues";
import { Loader } from "@/common/components/loader/Loader";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { ValidationType } from "@/contractor/pages/home/release/components/connections/components/common/ExportErrorList";
import { InvoiceExportErrorList } from "@/contractor/pages/home/release/components/connections/components/common/InvoiceExportErrorList";
import { ValidationItem } from "@/contractor/pages/home/release/components/connections/components/common/ValidationItem";
import { InvoiceValidationWarning } from "@/generated/graphql";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceValidation } from "../../../../../scanned-invoices/providers/InvoiceValidationProvider";
import { useInvoiceReleaseValidation } from "../../../../hooks/useInvoiceValidation";
import { VENDOR_MISMATCH } from "../../../../hooks/validation-error-configurations/vendorMismatch";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";
import {
  OrderMatchedHeader,
  OrderNumberHeader,
} from "../../../../styles/InvoiceVerification.styles";
import { InvoiceItemCoverageWarning } from "./invoice-item-coverage-warning/InvoiceItemCoverageWarning";
import { InvoiceOrderInputSelector } from "./invoice-order-selector/InvoiceOrderInputSelector";

const Container = tw.div``;
const OrderSelectorGroup = tw.div`grid content-start gap-3 pt-2`;
const Success = tw(CheckCircleOutlineOutlined)`text-green-800`;
const OrderSelectorInnerContainer = tw.div`flex flex-row items-center`;

export const InvoiceMatchedHeader: FC = () => {
  const intl = useIntl();
  const { invoice } = useInvoiceVerification();
  const { loading } = useInvoiceValidation();
  // We need to hide the warning for duplicated posted link as BE is not aware that this invoice is opened in side by side view where this error is not relevant for already posted invoices.
  // This error is still valid and should be displayed to the user in Export Invoices panel when mutliple invoices are posted.
  const { validations } = useInvoiceReleaseValidation(
    invoice,
    invoice?.poNumber || invoice?.release?.poNumber?.toString() || "",
    !!invoice?.link
      ? [InvoiceValidationWarning.InvoicePostedDuplicateLink]
      : [],
  );

  const hasVendorMismatchWarning = useMemo(
    () =>
      validations.some((validation) => validation.id === VENDOR_MISMATCH.id),
    [validations],
  );

  const invoiceHasMoreItemsThanInvoiceOrder = useMemo(
    () => {
      // TODO: Disable this for now as we have issues with that
      // return !!invoice?.release && release
      //   ? invoice.items.length > (release?.items ?? []).length
      //   : false
      return false;
    },
    // TODO: Disable this for now as we have issues with that
    // [invoice?.release, invoice?.items.length, release],
    [],
  );

  return (
    <Container>
      <OrderMatchedHeader>
        <OrderSelectorGroup>
          <If isTrue={invoice?.release}>
            <FormattedMessage id="YOUR_ORDER" tagName={OrderNumberHeader} />
          </If>
          <OrderSelectorInnerContainer>
            <InvoiceOrderInputSelector>
              <Loader className="h-6 w-6" small loading={loading}>
                <If isTrue={validations.length === 0}>
                  <Success className="h-6 w-6" />
                </If>
              </Loader>
            </InvoiceOrderInputSelector>
          </OrderSelectorInnerContainer>
        </OrderSelectorGroup>
        <If isTrue={invoice?.release}>
          <OrgDetailsHeaderItem
            title={<FormattedMessage id="SUPPLIER" />}
            name={
              <VendorList
                contacts={invoice?.release?.preferredVendor?.contacts.filter(
                  (c) => c.receivesInvoiceNotifications,
                )}
                className={hasVendorMismatchWarning ? "text-orange-500" : ""}
              />
            }
            details={invoice?.release?.sellerOrgLocation?.org.name}
            detailsTooltip={
              hasVendorMismatchWarning
                ? intl.$t({ id: "VENDOR_MISMATCH_INFO" })
                : undefined
            }
            logoImageUrl={invoice?.release?.sellerOrgLocation?.org.photoUrl}
            address={invoice?.release?.sellerOrgLocation?.address}
            classes={{
              details: hasVendorMismatchWarning ? "text-orange-500" : undefined,
            }}
          />
          <OrgDetailsHeaderItem
            title={<FormattedMessage id="PROJECT" />}
            name={invoice?.release?.project?.name}
            address={invoice?.release?.project?.address}
            warehouseId={invoice?.release?.warehouse?.id}
          />
        </If>
      </OrderMatchedHeader>
      <InvoiceExportErrorList
        includedValidations={validations.filter(
          (validation) =>
            !validation.validationType ||
            validation.validationType === ValidationType.Error,
        )}
        classes={{ item: "text-xs" }}
        includeDivider={false}
        warnings={[
          ...(invoiceHasMoreItemsThanInvoiceOrder
            ? [
                {
                  id: "invoice-item-coverage-warning",
                  clearable: true,
                  child: <InvoiceItemCoverageWarning />,
                },
              ]
            : []),
          ...validations
            .filter(
              (validation) =>
                validation.validationType === ValidationType.Warning &&
                validation.alwaysInclude,
            )
            .map((validation) => ({
              id: validation.id,
              child: <ValidationItem validation={validation} grayed={false} />,
              excludeIcon: true,
            })),
        ]}
      />
      <InvoiceIssues invoice={invoice} />
    </Container>
  );
};

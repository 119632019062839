import { ClearFiltersButton } from "@/common/components/clean-filters-button/ClearFiltersButton";
import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import {
  HeaderContainer,
  HeaderFilters,
} from "@/common/components/header-container/HeaderContainer";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { ListStatusesFilter } from "@/common/components/list-statuses-filter/ListStatusesFilter";
import { PopoverWithTabs } from "@/common/components/popover-with-tabs/PopoverWithTabs";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { QueryInvoicesFilter } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  DEFAULT_RECEIPTS_FILTER,
  ReceiptType,
  useReceipts,
} from "../../providers/ReceiptsProvider";
import { ReceiptFolderFilter } from "./ReceiptFolderFilter";
import { ReceiptListActions } from "./ReceiptListActions";
import { ReceiptsAdditionalFilters } from "./ReceiptsAdditionalFilters";

const HeaderContainerStyled = tw(HeaderContainer)`
  grid lg:grid-flow-col justify-between gap-3 flex-wrap
`;

type FilterOption = {
  id: string;
  name: string;
  filter?: QueryInvoicesFilter;
};

enum DatesKeys {
  TransactionDate = "transactionDate",
  ScannedDate = "ScannedDate",
}

export const ReceiptsHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useReceipts();
  const { projects } = useProjectListOptions({
    includeClosedProjects: filter?.closedProjects,
  });

  const options = useMemo<FilterOption[]>(() => {
    return [
      {
        id: ReceiptType.AwaitingApproval,
        name: intl.$t({ id: "RECEIPT_STATUS_AWAITING_APPROVAL" }),
      },
      {
        id: ReceiptType.Approved,
        name: intl.$t({ id: "RECEIPT_STATUS_APPROVED" }),
      },
      {
        id: ReceiptType.Paid,
        name: intl.$t({ id: "RECEIPT_STATUS_PAID" }),
      },
    ];
  }, [intl]);

  const setFolderFilterHandler = useCallback(
    (folderIds: string[] | undefined) => {
      if (!folderIds) {
        setFilter({
          ...filter,
          folderIds: undefined,
        });
      } else {
        setFilter({
          ...filter,
          folderIds,
        });
      }
    },
    [filter, setFilter],
  );

  const appliedFilters = useMemo(() => {
    return (
      Number(filter?.kickbackToUserIds != null) +
      Number(filter?.kickbackByUserIds != null) +
      Number(filter?.kickbackResolved != null) +
      Number(filter?.hasKickback != null) +
      Number(filter?.exported != null) +
      Number(filter?.prePaid != null) +
      Number(filter?.archived !== false) +
      Number(filter?.closedProjects !== false) +
      Number(filter?.releaseTypeIds != null) +
      Number(filter?.downloaded != null) +
      Number(filter?.createdByIds != null) +
      Number(filter?.projectMemberUserIds != null) +
      Number(filter?.equipmentItemIds != null)
    );
  }, [
    filter?.archived,
    filter?.exported,
    filter?.prePaid,
    filter?.closedProjects,
    filter?.releaseTypeIds,
    filter?.kickbackToUserIds,
    filter?.kickbackByUserIds,
    filter?.kickbackResolved,
    filter?.downloaded,
    filter?.createdByIds,
    filter?.projectMemberUserIds,
    filter?.hasKickback,
    filter?.equipmentItemIds,
  ]);

  const appliedDateFilters = useMemo(() => {
    return (
      Number(filter?.minIssueDate != null || filter?.maxIssueDate != null) +
      Number(filter?.minCreatedAt != null || filter?.maxCreatedAt != null)
    );
  }, [
    filter?.minIssueDate,
    filter?.maxIssueDate,
    filter?.minCreatedAt,
    filter?.maxCreatedAt,
  ]);

  const countNonDefaultFilters = useMemo(() => {
    return (
      appliedFilters +
      appliedDateFilters +
      Number(filter?.search != null && filter?.search !== "") +
      Number((filter?.statuses || []).length !== 0) +
      Number((filter?.projectIds || []).length !== 0) +
      Number(filter?.folderIds != null)
    );
  }, [
    appliedDateFilters,
    appliedFilters,
    filter?.folderIds,
    filter?.projectIds,
    filter?.search,
    filter?.statuses,
  ]);

  const resetFilterToDefault = useCallback(() => {
    setFilter(DEFAULT_RECEIPTS_FILTER);
  }, [setFilter]);

  return (
    <HeaderContainerStyled>
      <HeaderFilters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => setFilter({ ...filter, search: value })}
          value={filter?.search}
          tooltip="RECEIPTS_SEARCH_INFO"
          applyHeaderAccent
          clear={() => setFilter({ ...filter, search: undefined })}
        />
        <ProjectsFilterSelector
          projects={projects}
          onChange={(projectId) =>
            setFilter({
              ...filter,
              projectIds: projectId ? [projectId] : undefined,
            })
          }
          value={filter?.projectIds?.[0]}
          withCustomView
          clear={() =>
            setFilter({
              ...filter,
              projectIds: undefined,
            })
          }
        />
        <ListStatusesFilter
          options={options}
          filter={filter}
          setFilter={setFilter}
          values={filter?.statuses || null}
          applyHeaderAccent
        />
        <ReceiptFolderFilter
          value={filter?.folderIds}
          onChange={setFolderFilterHandler}
        />
        <ListHeaderFilterButton
          isDefault={appliedFilters === 0}
          filter={<ReceiptsAdditionalFilters />}
          appliedFilters={appliedFilters}
        />
        <ListDatesButton
          isDefault={appliedDateFilters === 0}
          appliedFilters={appliedDateFilters}
          hideDates
          dates={[
            {
              startDate: filter?.minIssueDate,
              endDate: filter?.maxIssueDate,
              key: DatesKeys.TransactionDate,
              label: intl.$t({ id: "TRANSACTION_DATE" }),
            },
            {
              startDate: filter?.minCreatedAt,
              endDate: filter?.maxCreatedAt,
              key: DatesKeys.ScannedDate,
              label: intl.$t({ id: "SCAN_DATE" }),
            },
          ]}
          clearValues={(value) => {
            if (value === DatesKeys.TransactionDate) {
              setFilter({
                ...filter,
                minIssueDate: undefined,
                maxIssueDate: undefined,
              });
            } else {
              setFilter({
                ...filter,
                minCreatedAt: undefined,
                maxCreatedAt: undefined,
              });
            }
          }}
        >
          {(onClose) => (
            <PopoverWithTabs
              tabs={[
                {
                  label: intl.$t({ id: "TRANSACTION_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.minIssueDate || filter?.maxIssueDate
                          ? {
                              startDate: filter?.minIssueDate
                                ? new Date(filter.minIssueDate)
                                : new Date(),
                              endDate: filter?.maxIssueDate
                                ? new Date(filter.maxIssueDate)
                                : new Date(),
                            }
                          : undefined
                      }
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minIssueDate: dateRange.startDate?.getTime(),
                          maxIssueDate: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: DatesKeys.TransactionDate,
                },
                {
                  label: intl.$t({ id: "SCAN_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.minCreatedAt || filter?.maxCreatedAt
                          ? {
                              startDate: filter?.minCreatedAt
                                ? new Date(filter.minCreatedAt)
                                : new Date(),
                              endDate: filter?.maxCreatedAt
                                ? new Date(filter.maxCreatedAt)
                                : new Date(),
                            }
                          : undefined
                      }
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minCreatedAt: dateRange.startDate?.getTime(),
                          maxCreatedAt: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: DatesKeys.ScannedDate,
                },
              ]}
              activeTab={DatesKeys.TransactionDate}
            />
          )}
        </ListDatesButton>
        <ClearFiltersButton
          count={countNonDefaultFilters}
          onClick={resetFilterToDefault}
        />
      </HeaderFilters>
      <ReceiptListActions />
    </HeaderContainerStyled>
  );
};

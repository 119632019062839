import { useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { Dialog } from "../../../dialog/Dialog";
import { ColumnSection } from "./components/ColumnSection";
import { useColumnManagement } from "./hooks/useColumnManagement";
import { useEditColumns } from "./hooks/useEditColumns";

const Container = tw.div`grid grid-cols-2 gap-6 w-full h-full`;

type EditColumnsDialogProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  pageId?: string;
};

export const EditColumnsDialog = ({
  visible,
  setVisible,
  pageId,
}: EditColumnsDialogProps) => {
  const intl = useIntl();
  const { handleCloseDialog } = useEditColumns(pageId);

  const {
    enabledColumns,
    disabledColumns,
    isEnabledDragActive,
    isDisabledDragActive,
    onDragStart,
    onDragOverEnabled,
    onDragOverDisabled,
    onDragLeaveEnabled,
    onDragLeaveDisabled,
    onDropToEnabled,
    onDropToDisabled,
    moveToEnabled,
    moveToDisabled,
    canBeMoved,
    saveColumnLayout,
    resetColumns,
  } = useColumnManagement(visible, pageId);

  const handleCancel = useCallback(() => {
    resetColumns();
    setVisible(false);
    handleCloseDialog();
  }, [resetColumns, setVisible, handleCloseDialog]);

  const handleConfirm = useCallback(() => {
    saveColumnLayout();
    setVisible(false);
    handleCloseDialog();
  }, [saveColumnLayout, setVisible, handleCloseDialog]);

  return (
    <Dialog
      cancelButtonText={intl.$t({ id: "CANCEL" })}
      confirmButtonText={intl.$t({ id: "SAVE" })}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
        confirm: {
          wide: true,
        },
      }}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      maxWidth="xl"
      show={visible}
      sx={{
        "& .MuiDialog-paper": {
          minHeight: "70vh",
        },
        "& .MuiDialog-paper > div": {
          justifyContent: "space-between",
          height: "100%",
        },
      }}
      content={
        <Container>
          <ColumnSection
            type="enabled"
            columns={enabledColumns}
            isDragActive={isEnabledDragActive}
            onDragOver={onDragOverEnabled}
            onDragLeave={onDragLeaveEnabled}
            onDrop={onDropToEnabled}
            onDragStart={onDragStart}
            moveColumn={moveToDisabled}
            canBeMoved={canBeMoved}
          />

          <ColumnSection
            type="disabled"
            columns={disabledColumns}
            isDragActive={isDisabledDragActive}
            onDragOver={onDragOverDisabled}
            onDragLeave={onDragLeaveDisabled}
            onDrop={onDropToDisabled}
            onDragStart={onDragStart}
            moveColumn={moveToEnabled}
            canBeMoved={canBeMoved}
          />
        </Container>
      }
    />
  );
};

import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { InventoryItemPendingFlowFieldsFragment } from "@/generated/graphql";
import { TransactionHistoryItemName } from "../../../transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";

type Props = {
  item: InventoryItemPendingFlowFieldsFragment;
};

export const ScheduledFlowUnitPrice = ({ item }: Props) => {
  return (
    <NotNullableRenderer value={item}>
      <TransactionHistoryItemName>
        <Price price={item?.unitPrice} />
      </TransactionHistoryItemName>
    </NotNullableRenderer>
  );
};

import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import {
  ProjectAllowanceFieldsFragment,
  TagExtendedFieldsFragment,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { useProjectStore } from "../../../store/useProjectStore";

type BudgetCostCode = {
  id: string;
  amount: number;
  costCode: {
    id: string;
    code: string;
    description: string;
    inUse: boolean;
  };
  zones: {
    [key: string]: {
      name: string;
      amount: string;
    };
  };
};

type BudgetTag = {
  id: string;
  name: string;
  amount: number;
};

type State = {
  budgetByCostCodes: BudgetCostCode[];
  budgetByTags: BudgetTag[];
  isZoneSpecificBudget: boolean;
  updateStoreData: (data: ProjectAllowanceFieldsFragment) => void;
};

const getBudgetByCostCodes = (
  allowance: ProjectAllowanceFieldsFragment,
  isZoneSpecificBudget: boolean,
) => {
  const costCodeMap: Map<string, BudgetCostCode> = new Map();
  allowance.costCodes.forEach((cc) => {
    if (cc.zone || isZoneSpecificBudget) {
      costCodeMap.set(cc.costCode.id, {
        id: cc.costCode.id,
        costCode: cc.costCode,
        amount:
          Number(costCodeMap.get(cc.costCode.id)?.amount ?? 0) +
          Number(cc.amount),
        zones: costCodeMap.get(cc.costCode.id)?.zones
          ? {
              ...costCodeMap.get(cc.costCode.id)?.zones,
              [cc.zone?.id ?? UNSPECIFIED_ZONE_ID]: {
                name: cc.zone?.name ?? "",
                amount: cc.amount,
              },
            }
          : {
              [cc.zone?.id ?? UNSPECIFIED_ZONE_ID]: {
                name: cc.zone?.name ?? "",
                amount: cc.amount,
              },
            },
      });
    } else {
      costCodeMap.set(cc.costCode.id, {
        id: cc.costCode.id,
        costCode: cc.costCode,
        amount: Number(cc.amount),
        zones: {},
      });
    }
  });
  return Array.from(costCodeMap.values());
};

const getBudgetByTags = (
  allowance: ProjectAllowanceFieldsFragment,
  tags: TagExtendedFieldsFragment[],
) => {
  return tags.map((tag) => {
    const tagAllowance = allowance.tags.find((t) => t.tag.id === tag.id);
    return {
      id: tagAllowance?.id ?? tag.id,
      name: tag.name,
      amount: Number(tagAllowance?.amount ?? 0),
    };
  });
};

export const useBudgetStore = create<State>()(
  devtools((set) => ({
    budgetByCostCodes: [],
    budgetByTags: [],
    isZoneSpecificBudget: false,
    updateStoreData: (data: ProjectAllowanceFieldsFragment) => {
      const isZoneSpecificBudget =
        data.costCodes.length > 0 && data.costCodes.some((cc) => cc.zone);
      const { tags: projectTags, phaseCodes } = useProjectStore.getState();
      const budgetByCostCodes = getBudgetByCostCodes(
        data,
        isZoneSpecificBudget,
      );
      const budgetByTags = getBudgetByTags(data, [
        ...projectTags,
        ...phaseCodes,
      ]);
      set({
        budgetByCostCodes,
        budgetByTags,
        isZoneSpecificBudget,
      });
    },
  })),
);

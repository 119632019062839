import { Dialog } from "@/common/components/dialog/Dialog";
import { routes } from "@/config/routes";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";
import { useGetOrderFromQuote } from "../total-amount/useGetOrderFromQuote";

type CreateOrderFromRFQDialogProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const CreateOrderFromRFQDialog: FC<CreateOrderFromRFQDialogProps> = ({
  visible = true,
  setVisible,
}) => {
  const intl = useIntl();
  const {
    rfq,
    selectedQuotes,
    allQuoteItems,
    selectedAuxiliaryQuoteItems,
    auxiliaryItems,
  } = useRfqQuotes();
  const navigate = useNavigate();
  const getNewOrderFromRfqQuote = useGetOrderFromQuote();
  const { createStandaloneRelease, creating } = useCreateRelease();

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const createOrder = useCallback(async () => {
    const input = await getNewOrderFromRfqQuote({
      rfq,
      selectedQuotes,
      allQuoteItems,
      selectedAuxiliaryQuoteItems,
      auxiliaryItems,
    });
    const release = await createStandaloneRelease(input);
    if (release) {
      navigate(
        generatePath(routes.specifyDeliveryDetails, {
          deliveryId: release.id,
        }),
      );
    }
  }, [
    createStandaloneRelease,
    getNewOrderFromRfqQuote,
    navigate,
    rfq,
    selectedQuotes,
    allQuoteItems,
    selectedAuxiliaryQuoteItems,
    auxiliaryItems,
  ]);

  return (
    <Dialog
      includeWarningIcon
      title={intl.$t({ id: "PROCEED_WITH_SELECTED_ITEMS" })}
      text={intl.$t({ id: "CREATE_ORDER_FROM_SELECTED_ITEMS" })}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "CREATE_ORDER" })}
      handleCancel={handleCancel}
      handleConfirm={createOrder}
      saving={creating}
      show={visible}
      buttonsConfiguration={{
        confirm: {
          wide: true,
        },
        cancel: {
          wide: true,
        },
      }}
    />
  );
};

import { RELEASE_DRAFT_STATUSES } from "@/common/const";
import { routes } from "@/config/routes";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { generatePath, useNavigate, useParams } from "react-router";
import { useOrderFromQuote } from "../../../providers/OrderFromQuoteProvider";
import { CreateOrderFromQuoteFormValues } from "../create-order-from-quote/components/CreateOrderFromQuoteForm";

export const useCreateOrderFromQuote = () => {
  const navigate = useNavigate();
  const { deliveryId } = useParams();
  const { release } = useRelease();
  const { quoteDocument } = useQuoteDocument();
  const {
    syncCreateReleaseFromQuote,
    syncUpdateReleaseFromQuote,
    createLumpSumRelease,
    updateLumpSumRelease,
    itemized,
    validateSpreadsheet,
  } = useOrderFromQuote();
  const [isSaveDialogVisible, setIsSaveDialogVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { reset } = useFormContext<CreateOrderFromQuoteFormValues>();

  const handleSaveReleaseFromQuote = useCallback(
    async (opts?: {
      skipNavigate?: boolean;
      skipVendorNotification?: boolean | undefined;
    }) => {
      if (itemized && !(await validateSpreadsheet())) {
        return null;
      }
      let result: ReleaseFieldsFragment | null | undefined = null;
      setIsSaving(true);
      if (deliveryId) {
        if (itemized) {
          result = await syncUpdateReleaseFromQuote({
            skipVendorNotification: opts?.skipVendorNotification,
          });
        } else {
          result = await updateLumpSumRelease({
            skipVendorNotification: opts?.skipVendorNotification,
          });
        }
      } else {
        result = itemized
          ? await syncCreateReleaseFromQuote({
              status: ReleaseStatus.Draft,
            })
          : await createLumpSumRelease({
              status: ReleaseStatus.Draft,
            });

        if (result && !opts?.skipNavigate) {
          navigate(
            generatePath(routes.editDeliveryFromQuote, {
              quoteDocumentId: quoteDocument?.id,
              deliveryId: result.id,
            }),
          );
        }
      }
      setIsSaving(false);
      return result;
    },
    [
      validateSpreadsheet,
      deliveryId,
      itemized,
      syncUpdateReleaseFromQuote,
      updateLumpSumRelease,
      syncCreateReleaseFromQuote,
      createLumpSumRelease,
      navigate,
      quoteDocument?.id,
    ],
  );

  const finalizeOrder = useCallback(async () => {
    if (!release || RELEASE_DRAFT_STATUSES.includes(release.status)) {
      const release = await handleSaveReleaseFromQuote({
        skipNavigate: true,
      });
      if (release) {
        navigate(
          generatePath(routes.specifyDeliveryDetails, {
            deliveryId: deliveryId || release.id,
          }),
        );
      }
    } else {
      setIsSaveDialogVisible(true);
    }
  }, [deliveryId, handleSaveReleaseFromQuote, navigate, release]);

  const onCancel = useCallback(() => {
    if (release) {
      navigate(
        generatePath(routes.specifyDeliveryDetails, {
          deliveryId: release.id,
        }),
      );
    } else {
      reset();
      navigate(routes.deliveries);
    }
  }, [navigate, release, reset]);

  const onSave = useCallback(() => {
    handleSaveReleaseFromQuote();
  }, [handleSaveReleaseFromQuote]);

  return {
    onCancel,
    handleSaveReleaseFromQuote,
    isSaving,
    isSaveDialogVisible,
    setIsSaveDialogVisible,
    finalizeOrder,
    onSave,
  };
};

const wordsToReplace = [
  ["AND", "&"],
  ["LLC", ""],
  ["LLP", ""],
  ["INC", ""],
  ["THE", "T"],
  ["CO", ""],
  ["CORP", ""],
  ["CORPORATION", ""],
  ["LTD", ""],
];

export const getNameInitials = (name: string) => {
  if (!name) {
    return "";
  }

  let processedName = name.replace(/\([^)]*\)/g, "");

  const ampersandPattern = /^(\w+)\s+&\s+(\w+)/i;
  const ampersandMatch = processedName.match(ampersandPattern);

  if (ampersandMatch) {
    const firstWord = ampersandMatch[1];
    const secondWord = ampersandMatch[2];
    return `${firstWord.charAt(0)}&${secondWord.charAt(0)}`.toUpperCase();
  }

  let formattedName = processedName
    .replace(/[A-Z](\.[A-Z])+/g, "")
    .replace(/([A-Z]{2,})-([A-Z]{2,})/g, "$1$2")
    .replace(/(\b\w)&(\w\b)/g, "$1 $2")
    .replace(/[-/,.#&()]/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .trim();

  const words = formattedName
    .split(" ")
    .map((word) => {
      const search = wordsToReplace.find(
        (pair) => pair[0].toUpperCase() === word.toUpperCase(),
      );
      return search ? search[1] : word;
    })
    .filter((word) => word.length > 0);

  if (!words.length) {
    return "";
  }

  if (
    words[0].length >= 2 &&
    words[0].length <= 4 &&
    words[0] === words[0].toUpperCase() &&
    words.slice(1).some((word) => word !== word.toUpperCase())
  ) {
    return words[0];
  }

  const initials = words
    .filter(
      (part, index) => part.length > 1 || index < 2 || part.match(/[a-zA-Z]/),
    )
    .map((part) => `${part.slice(0, 1)}${part.slice(1).toLowerCase()}`);
  const result =
    initials.length > 1
      ? initials.reduce((acc, part) => acc + part.charAt(0), "").slice(0, 3)
      : initials[0].slice(0, 3);
  return result.toUpperCase();
};

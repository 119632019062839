import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { ColumnMapperProvider } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../../../../store/useProjectStore";
import { useBudgetStore } from "../../store/useBudgetStore";
import { useBudgetByTagsConfiguration } from "./BudgetByTags.config";
import { useBudgetByTags } from "./useBudgetByTags";

const Container = tw.div`pt-5`;

const BudgetByTagsWithProviders = () => {
  const { spreadsheetConfig } = useBudgetByTagsConfiguration();
  const { budgetByTags } = useBudgetStore(
    useShallow((state) => ({
      budgetByTags: state.budgetByTags,
    })),
  );
  const [saving, setSaving] = useState(false);
  const { saveTagBudget } = useBudgetByTags();

  const onSave = useCallback(async () => {
    setSaving(true);
    const result = await saveTagBudget();
    setSaving(false);

    if (!result) {
      return;
    }
  }, [saveTagBudget, setSaving]);

  return (
    <Container>
      <SpreadSheetTable
        items={budgetByTags}
        columns={spreadsheetConfig}
        height="calc(100vh - 330px)"
        saving={saving}
        hideAddNewRow
        rowNumber={20}
      />
      <FloatingFooter>
        <ButtonsContainer>
          <PrimaryButton
            onClick={onSave}
            type="submit"
            loading={saving}
            testId="save-tags"
          >
            <FormattedMessage id="SAVE_CHANGES" />
          </PrimaryButton>
        </ButtonsContainer>
      </FloatingFooter>
    </Container>
  );
};

const TagsWithColumnMapper = () => {
  const { spreadsheetConfig } = useBudgetByTagsConfiguration();
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <BudgetByTagsWithProviders />
    </ColumnMapperProvider>
  );
};

export const BudgetByTags = () => {
  const { allowance } = useProjectStore(
    useShallow((state) => ({
      allowance: state.allowance,
    })),
  );

  const { updateStoreData } = useBudgetStore(
    useShallow((state) => ({
      updateStoreData: state.updateStoreData,
    })),
  );

  useEffect(() => {
    updateStoreData(allowance);
  }, [allowance, updateStoreData]);

  return <TagsWithColumnMapper />;
};

import { Loader } from "@/common/components/loader/Loader";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { CurrentStockHeader } from "./components/current-stock-header/CurrentStockHeader";
import { CurrentStockList } from "./components/current-stock-list/CurrentStockList";
import { CurrentStockSelectAll } from "./components/current-stock-select-all/CurrentStockSelectAll";
import { CurrentStockSideActions } from "./components/current-stock-side-actions/CurrentStockSideActions";

const CurrentStockWithProvider = () => {
  const intl = useIntl();
  const { loading } = useStartupDataStore(
    useShallow((state) => ({ loading: state.loading })),
  );

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "CURRENT_STOCK" })}</title>
      </Helmet>
      <Loader loading={loading}>
        <CurrentStockHeader />
        <CurrentStockList />
        <CurrentStockSelectAll />
        <CurrentStockSideActions />
      </Loader>
    </>
  );
};

export const CurrentStock = () => (
  <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
    <CurrentStockWithProvider />
  </PaginationProvider>
);

import { useSplittingInvoices } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/hooks/useSplittingInvoices";
import {
  SplitType,
  useSplittingInvoicesWizard,
} from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/SplittingInvoicesWizardProvider";
import { useReceiptUploadModal } from "@/contractor/pages/home/receipts/pages/receipt-record-order/providers/UploadReceiptProvider";
import {
  DistributorInvoiceFieldsFragment,
  InvoiceFieldsFragment,
  InvoiceType,
  ReceiptFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useInvoiceDocuments } from "./useInvoiceDocuments";

export const useReadjustInvoiceDocument = ({
  invoice,
  type,
}: {
  invoice:
    | InvoiceFieldsFragment
    | null
    | ReceiptFieldsFragment
    | DistributorInvoiceFieldsFragment;
  type: InvoiceType;
}) => {
  const { openWizard } = useSplittingInvoicesWizard();
  const { openUploadReceiptModal } = useReceiptUploadModal();
  const { getPageRangeWithExcludedPages } = useSplittingInvoices();
  const { newDocument, originalDocument } = useInvoiceDocuments(invoice);

  const readjust = useCallback(() => {
    const document = newDocument ?? originalDocument;
    if (document) {
      if (type === InvoiceType.Receipt) {
        openUploadReceiptModal();
      } else {
        const pageRanges = getPageRangeWithExcludedPages(
          document.asset,
          document.pageRange,
        );

        openWizard({
          initialPage: 0,
          urls: [document.asset.url],
          pageRanges,
          type: SplitType.Restructure,
        });
      }
    }
  }, [
    getPageRangeWithExcludedPages,
    newDocument,
    openUploadReceiptModal,
    openWizard,
    originalDocument,
    type,
  ]);
  return { readjust };
};

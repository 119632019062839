import { ExternalVendorFieldsFragment } from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  modalOpened: boolean;
  openModal: () => void;
  closeModal: () => void;
  selectedExternalVendors: ExternalVendorFieldsFragment[];
  setSelectedExternalVendors: (vendors: ExternalVendorFieldsFragment[]) => void;
};

export const useImportExternalVendorsStore = create<State>()(
  devtools((set) => ({
    modalOpened: false,
    openModal: () => set({ modalOpened: true }),
    closeModal: () => set({ modalOpened: false }),
    selectedExternalVendors: [],
    setSelectedExternalVendors: (
      selectedExternalVendors: ExternalVendorFieldsFragment[],
    ) => set({ selectedExternalVendors }),
  })),
);

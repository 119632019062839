import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { ReleaseDateTimePickerProps } from "@/contractor/pages/home/release/pages/specify-details/components/release-date-time-picker/ReleaseDateTimePicker";
import { useReleaseStore } from "@/contractor/pages/home/release/store/useReleaseStore";
import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { DatePickerRelease } from "./useReleaseDateTimePickerConfig";

type Props = Pick<ReleaseDateTimePickerProps, "prefillIfTbd"> & {
  release: DatePickerRelease;
};

export const useReleaseDateTimeAdjustment = (props: Props) => {
  const { prefillIfTbd, release } = props;
  const { spreadsheetData } = useColumnMapper();
  const { getCellWithAdditionalData } = useTableHelpers();
  const { updateReleaseUpdateOptions } = useReleaseStore(
    useShallow((state) => ({
      updateReleaseUpdateOptions: state.updateReleaseUpdateOptions,
    })),
  );

  useEffect(() => {
    if (prefillIfTbd && release?.timeTBD) {
      let minDate: number | null = null;
      spreadsheetData.forEach((row) => {
        const date = getCellWithAdditionalData(row, COLUMN_TYPE.DeliveryDate);

        if (date && (!minDate || new Date(date).getTime() < minDate)) {
          minDate = new Date(date).getTime();
        }
      });

      if (minDate) {
        updateReleaseUpdateOptions({
          requestedTime: minDate,
        });
      } else {
        updateReleaseUpdateOptions({
          requestedTime: null,
        });
      }
    }
  }, [
    getCellWithAdditionalData,
    prefillIfTbd,
    release?.timeTBD,
    spreadsheetData,
    updateReleaseUpdateOptions,
  ]);
};

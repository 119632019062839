import { ValidatorFn } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { NoFunction, NoFunctionBoolean } from "@/types/NoFunction";
import { cloneDeep } from "@apollo/client/utilities";
import Handsontable from "handsontable";
import { CellType } from "handsontable/cellTypes";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { COLUMN_TYPE } from "../enums/columnType";
import { useTableDropdownOptions } from "../hooks/useTableDropdownOptions";
import { RendererFunctionType } from "../renderers/base-renderer/types/RendererFunctionType";

export type ValidatorContextType = {
  row: number;
  col: number;
  prop?: string;
};

export enum SpreadsheetSaveType {
  Toggle = "toggle",
  SaveButton = "saveButton",
}

export type ExtraOption = {
  name: string;
  value: string;
  content: HTMLElement;
  prefilling: [COLUMN_TYPE, string | number | undefined | null][];
};

export type SpreadSheetConfig = {
  header: string;
  columnId: string | ((id: string) => string);
  data?: string;
  type?: CellType;
  width?: number;
  source?: string[];
  placeholder?: string;
  options?: string[];
  optionsFn?: (row: Record<string, string>) => string[];
  extraOptions?: ExtraOption[];
  readOnlyFn?: (row: Record<string, string>) => string;
  columnType: COLUMN_TYPE | string;
  skipSpaceReplacement?: boolean;
  validator?: ValidatorFn;
  renderer?: string | RendererFunctionType | undefined;
  metadata?: Record<string, unknown>;
  disabledForLumpSum?: boolean;
  hidden?: boolean;
  deactivated?: boolean;
  metadataColumn?: boolean;
  optional?: boolean;
};

export type ColumnType = SpreadSheetConfig & {
  renderer?: string | RendererFunctionType | undefined;
  additional?: COLUMN_TYPE | string;
  strict?: boolean;
  allowInvalid?: boolean;
  readOnly?: boolean;
  numericFormat?: {
    pattern: string;
    culture: string;
  };
};

type MetaType = {
  [key: string]: string | number | boolean | undefined | null;
};

type ProviderContextType = {
  config: SpreadSheetConfig[];
  itemOptions: string[];
  manufacturerOptions: string[];
  uomOptions: string[];
  costCodeOptions: string[];
  phaseCodeOptions: string[];
  zoneOptions: string[];
  vendorOptions: string[];
  warehouseOptions: string[];
  supplierOptions: string[];
  tagOptions: string[];
  costTypeOptions: string[];
  getRemovedRowIds: (ids: { id: string }[]) => string[];
  rowHasChanges: (row: Record<string, string>) => boolean;
  spreadsheetData: Record<string, string>[];
  setSpreadsheetData: (data: Record<string, string>[]) => void;
  resetPreviousData: () => void;
  extraColumns: ColumnType[];
  setExtraColumns: React.Dispatch<React.SetStateAction<ColumnType[]>>;
  invalidCells: ValidatorContextType[];
  setInvalidCells: React.Dispatch<React.SetStateAction<ValidatorContextType[]>>;
  gotoInvalidRow: () => void;
  setHandsonInstance: (instance: Handsontable) => void;
  setMetadata: (id: string | number, data: MetaType) => void;
  getMetadata: (id: string | number) => MetaType | undefined;
  handsonInstance: Handsontable | undefined;
};

const ProviderContext = createContext<ProviderContextType>({
  config: [],
  itemOptions: [],
  manufacturerOptions: [],
  uomOptions: [],
  costCodeOptions: [],
  phaseCodeOptions: [],
  zoneOptions: [],
  vendorOptions: [],
  warehouseOptions: [],
  supplierOptions: [],
  tagOptions: [],
  costTypeOptions: [],
  getRemovedRowIds: () => [],
  rowHasChanges: NoFunctionBoolean,
  spreadsheetData: [],
  setSpreadsheetData: NoFunction,
  resetPreviousData: NoFunction,
  extraColumns: [],
  setExtraColumns: NoFunction,
  invalidCells: [],
  setInvalidCells: NoFunction,
  gotoInvalidRow: NoFunction,
  setHandsonInstance: NoFunction,
  setMetadata: NoFunction,
  getMetadata: () => undefined,
  handsonInstance: undefined,
});

type ProviderProps = {
  children: React.ReactNode;
  config: SpreadSheetConfig[];
};

export const ColumnMapperProvider: FC<ProviderProps> = ({
  children,
  config,
}) => {
  const {
    itemOptions,
    manufacturerOptions,
    uomOptions,
    costCodeOptions,
    phaseCodeOptions,
    zoneOptions,
    vendorOptions,
    tagOptions,
    costTypeOptions,
    supplierOptions,
    warehouseOptions,
  } = useTableDropdownOptions();
  const [extraColumns, setExtraColumns] = useState<ColumnType[]>([]);
  const [invalidCells, setInvalidCells] = useState<ValidatorContextType[]>([]);
  const [handsonInstance, setHandsonInstance] = useState<Handsontable>();

  const [spreadsheetData, setSpreadsheetData] = useState<
    Record<string, string>[]
  >([]);

  const metadata = useRef<{ [key: string | number]: MetaType }>();

  const previousData = useRef<Record<string, string>[]>([]);

  const resetPreviousData = useCallback(() => {
    previousData.current = cloneDeep(spreadsheetData);
  }, [spreadsheetData]);

  useEffect(() => {
    resetPreviousData();
  }, [resetPreviousData, spreadsheetData]);

  const getRemovedRowIds = useCallback(
    (ids: { id: string }[]) => {
      return ids
        .filter((item) => !spreadsheetData.some((r) => r.id === item.id))
        .map((item) => item.id);
    },
    [spreadsheetData],
  );

  const rowHasChanges = useCallback(
    (row: Record<string, string>) => {
      if (previousData.current.length === 0) {
        return false;
      }

      const originalRow = previousData.current.find(
        (prevRow) =>
          prevRow.id === row.id &&
          (prevRow.id !== null || prevRow.id !== undefined) &&
          (row.id !== null || row.id !== undefined),
      );

      if (!originalRow) {
        return !!row.id;
      }

      return Object.keys(row).some((key) => {
        if (
          (row[key] === "" || row[key] === null || row[key] === undefined) &&
          (originalRow[key] === "" ||
            originalRow[key] === null ||
            originalRow[key] === undefined)
        ) {
          return false;
        }
        return String(row[key]) !== String(originalRow[key]);
      });
    },
    [previousData],
  );

  const gotoInvalidRow = useCallback(() => {
    setTimeout(() => {
      const invalidCell = handsonInstance
        ?.getCellsMeta()
        .reverse()
        .find((c) => !c.valid);
      if (invalidCell) {
        handsonInstance?.scrollViewportTo(invalidCell.row, invalidCell.col);
      }
    }, 500);
  }, [handsonInstance]);

  const setMetadata = useCallback((id: string | number, data: MetaType) => {
    metadata.current = metadata.current ?? {};
    metadata.current[id] = data;
  }, []);

  const getMetadata = useCallback((id: string | number) => {
    return metadata.current?.[id];
  }, []);

  return (
    <ProviderContext.Provider
      value={{
        config,
        itemOptions,
        manufacturerOptions,
        uomOptions,
        costCodeOptions,
        zoneOptions,
        vendorOptions,
        supplierOptions,
        warehouseOptions,
        phaseCodeOptions,
        costTypeOptions,
        tagOptions,
        spreadsheetData,
        setSpreadsheetData,
        getRemovedRowIds,
        rowHasChanges,
        resetPreviousData,
        extraColumns,
        setExtraColumns,
        invalidCells,
        setInvalidCells,
        gotoInvalidRow,
        setHandsonInstance,
        setMetadata,
        getMetadata,
        handsonInstance,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useColumnMapper = (): ProviderContextType =>
  useContext(ProviderContext);

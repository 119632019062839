import { useTransactionHistoryModal } from "@/contractor/pages/home/inventory-management/common/components/transaction-history/hooks/useTransactionHistoryModal";
import { useCallback, useMemo } from "react";
import { CurrentStockListItemProps } from "../../types/CurrentStockListItemProps";

export const useCurrentStockInStock = ({ item }: CurrentStockListItemProps) => {
  const { onOpen } = useTransactionHistoryModal();
  const onClick = useCallback(() => {
    if (item) {
      onOpen({ inventoryItem: item });
    }
  }, [item, onOpen]);

  const classNames = useMemo(() => {
    return !item?.inStock
      ? { container: "bg-red-150 w-full h-14", item: "text-xs font-normal" }
      : { container: "h-14", item: "text-xs text-blue-500" };
  }, [item?.inStock]);

  const disabled = useMemo(() => !item || !item.inStock, [item]);

  return {
    onClick,
    classNames,
    disabled,
  };
};

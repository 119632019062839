import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useEnterprisesQuery } from "@/generated/graphql";
import { useMemo, useState } from "react";

export const useEnterprises = () => {
  const { data, loading, error, refetch } = useEnterprisesQuery();
  const [filter, setFilter] = useState<string | undefined>();

  useErrorEffect(error);

  const refetchOrgs = async () => {
    await refetch();
  };

  const orgs = useMemo(() => {
    return (
      data?.enterprises
        .flatMap((enterprise) => enterprise.orgs)
        ?.filter((org) => {
          if (filter) {
            return (
              org.id.includes(filter.toLowerCase()) ||
              org.name.toLowerCase().includes(filter.toLowerCase())
            );
          }
          return true;
        }) || []
    );
  }, [data?.enterprises, filter]);

  return { orgs, loading, error: !!error, refetchOrgs, setFilter };
};

import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import { XlContainer } from "@/common/layout/ResponsiveClasses";
import { ComplianceGroupFieldsFragment } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Row = tw.div`flex flex-row`;

export const useImportExternalComplianceGroupsTableConfiguration = (
  selectedItems?: string[],
): Array<GridCol<ComplianceGroupFieldsFragment>> => {
  return [
    {
      header: <FormattedMessage id="VENDOR_COMPLIANCE_GROUP" />,
      wrapper: XlContainer,
      item: ({ item, index, count }) => (
        <SelectableItem
          item={item}
          selectedItems={selectedItems ?? []}
          count={count}
          index={index}
          itemNameSelectorFn={(item) => item.code ?? ""}
        />
      ),
    },
    {
      header: <FormattedMessage id="DESCRIPTION" />,
      wrapper: XlContainer,
      item: ({ item }) => <Row>{item.description}</Row>,
    },
    {
      header: <FormattedMessage id="NOTES" />,
      wrapper: XlContainer,
      item: ({ item }) => (
        <NotNullableRenderer value={item.notes}>
          <Row>{item.notes}</Row>
        </NotNullableRenderer>
      ),
    },
  ];
};

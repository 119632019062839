import { ClearFiltersButton } from "@/common/components/clean-filters-button/ClearFiltersButton";
import { HeaderFilters } from "@/common/components/header-container/HeaderContainer";
import { If } from "@/common/components/if/If";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { useIsWarehouseManager } from "@/common/components/warehouse-selector/hooks/useIsWarehouseManager";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InventoryManagementHeaderContainer } from "../../../../common/InventoryManagement.styles";
import { useInventoryItemsFilter } from "../../hooks/useInventoryItemsFilter";
import { AddAdjustmentButton } from "../add-adjustment-button/AddAdjustmentButton";
import { AddJobOrderButton } from "../add-job-order-button/AddJobOrderButton";
import { AddTransferButton } from "../add-transfer-button/AddTransferButton";
import { NewRestockButton } from "../new-restock-button/NewRestockButton";
import { CurrentStockAdditionalFilters } from "./components/current-stock-additional-filters/CurrentStockAdditionalFilters";
import { CurrentStockCostCodesSelector } from "./components/current-stock-cost-codes-selector/CurrentStockCostCodesSelector";
import { CurrentStockWarehousesSelector } from "./components/current-stock-warehouses-selector/CurrentStockWarehousesSelector";
import { useCurrentStockHeader } from "./hooks/useCurrentStockHeader";

const RightSection = tw.div`grid items-end gap-2`;

export const CurrentStockHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useInventoryItemsFilter();
  const { appliedFilters, resetFilterToDefault, countNonDefaultFilters } =
    useCurrentStockHeader();
  const { isWarehouseManager } = useIsWarehouseManager();

  return (
    <InventoryManagementHeaderContainer>
      <HeaderFilters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value) => setFilter({ ...filter, search: value })}
          value={filter?.search ?? ""}
          className="w-64"
        />
        <CurrentStockWarehousesSelector />
        <CurrentStockCostCodesSelector />
        <ListHeaderFilterButton
          isDefault={appliedFilters === 0}
          filter={<CurrentStockAdditionalFilters />}
          appliedFilters={appliedFilters}
        />
        <ClearFiltersButton
          count={countNonDefaultFilters}
          onClick={resetFilterToDefault}
        />
      </HeaderFilters>
      <RightSection>
        <ButtonsContainer>
          <AddJobOrderButton />
          <If isTrue={isWarehouseManager}>
            <AddAdjustmentButton />
            <AddTransferButton />
            <NewRestockButton />
          </If>
        </ButtonsContainer>
      </RightSection>
    </InventoryManagementHeaderContainer>
  );
};

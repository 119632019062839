import { useInventoryItemSequence } from "../current-stock-list/components/hooks/useInventoryItemSequence";

import { generatePath, useNavigate } from "react-router";

import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { setValue } from "@/common/utils/localStorage";
import { routes } from "@/config/routes";
import { useOrderTypes } from "@/contractor/pages/admin/orders/pages/order-types/hooks/useOrderTypes";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import { ServiceType } from "@/generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { useInventoryItems } from "../../hooks/useInventoryItems";
import {
  getSelectedItemIdParts,
  UNDEFINED_WAREHOUSE_ID,
} from "../../utils/getSelectedItemIdParts";
import { SelectProjectDialogFormValues } from "../select-project-dialog/SelectProjectDialogForm";
export const useNewRestock = () => {
  const navigate = useNavigate();
  const { inventoryItems } = useInventoryItems();
  const { createStandaloneRelease, creating } = useCreateRelease();
  const { selectedEntities, sequenceIds } = useInventoryItemSequence();
  const { orderTypes } = useOrderTypes();
  const [open, setOpen] = useState(false);

  const restockOrderType = useMemo(
    () => orderTypes.find((type) => type.trackInventory),
    [orderTypes],
  );

  const handleConfirm = useCallback(
    async (data: SelectProjectDialogFormValues) => {
      if (!data.projectId || !data.warehouseId) {
        return;
      }

      if (sequenceIds.length === 0 && selectedEntities.length === 0) {
        const release = await createStandaloneRelease({
          projectId: data.projectId,
          warehouseId: data.warehouseId,
          typeId: restockOrderType?.id,
          includeServices: [
            {
              type: ServiceType.Delivery,
            },
          ],
        });

        if (release) {
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: release.id,
            }),
          );
        }

        return;
      }

      let filteredSequenceIds = sequenceIds.filter(
        (sequenceId) => sequenceId !== UNDEFINED_WAREHOUSE_ID,
      );

      if (filteredSequenceIds.length === 0) {
        filteredSequenceIds = [data.warehouseId];
      }

      const releases = await Promise.all(
        filteredSequenceIds.map(async (sequenceId) => {
          return await createStandaloneRelease({
            projectId: data.projectId,
            warehouseId: sequenceId,
            typeId: restockOrderType?.id,
            items: selectedEntities
              .filter(
                (entity) =>
                  getSelectedItemIdParts(entity.id).warehouseId ===
                    sequenceId ||
                  getSelectedItemIdParts(entity.id).warehouseId ===
                    UNDEFINED_WAREHOUSE_ID,
              )
              .map((entity) => {
                const { inventoryItemId } = getSelectedItemIdParts(entity.id);
                const inventoryItem = inventoryItems.find(
                  (item) => item.id === inventoryItemId,
                );
                return {
                  quantityDecimal: "0",
                  projectItem: {
                    orgCatalogSkuId: inventoryItem?.orgMaterial.material.id,
                    estimateUom: inventoryItem?.uom.pluralDescription ?? "",
                  },
                };
              }),
          });
        }),
      );

      //TODO replace it when we refactor the release sequence
      if (releases && releases.length > 0) {
        if (releases.length > 1) {
          setValue(
            LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SELECTED_RELEASES,
            releases.map((release) => ({
              id: release?.id,
              status: release?.status,
            })),
          );
          setValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_STEP, 0);
          setValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_ACTIVE, true);
        } else {
          setValue(
            LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SELECTED_RELEASES,
            undefined,
          );
          setValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_STEP, 0);
          setValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_ACTIVE, false);
        }
        setTimeout(() => {
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: releases[0]?.id,
            }),
          );
        });
      }
      setOpen(false);
    },
    [
      navigate,
      setOpen,
      createStandaloneRelease,
      selectedEntities,
      inventoryItems,
      sequenceIds,
      restockOrderType?.id,
    ],
  );

  const handleAdjustment = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return {
    handleConfirm,
    handleAdjustment,
    open,
    setOpen,
    restockOrderType,
    creating,
  };
};

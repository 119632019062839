import { useExternalPOUtils } from "@/common/components/import-external-po/hooks/useExternalPOUtils";
import { useImportExternalPos } from "@/common/components/import-external-po/hooks/useImportExternalPos";
import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { DEFAULT_UNIT_UOM } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { routes } from "@/config/routes";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useLumpSumReleaseItems } from "@/contractor/pages/home/release/hooks/useLumpSumReleaseItems";
import {
  AddToReleaseItemInput,
  CreateStandaloneReleaseInput,
  ExternalPoFieldsFragment,
  ExternalPoImportPossibility,
  ReleaseStatus,
  useCreateStandaloneReleaseMutation,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { useUpdateMaterialsAndGetMap } from "./useUpdateMaterialsAndGetMap";

export const useReleaseImportExternalPO = () => {
  const { showModal, variables, getPages, setOptions, ...props } =
    useImportExternalPos();
  const { getImportableItems, checkIfPOIsItemized } = useExternalPOUtils();
  const [createStandaloneRelease, { loading: creatingRelease }] =
    useCreateStandaloneReleaseMutation();
  const { setSystemAlert } = useSnackbar();
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { setError } = useGlobalError();
  const { getUomByName } = useUomOptions();
  const { materials } = useMaterials();
  const { projectCostCodes } = useProjectCostCodes();
  const { costTypes } = useCostTypes();
  const navigate = useNavigate();
  const { getLumpSumReleaseItem } = useLumpSumReleaseItems();
  const { updateMaterialsAndGetMap } = useUpdateMaterialsAndGetMap();

  useEffect(() => {
    setOptions({
      labelKeys: {
        importButton: "IMPORT",
      },
    });
  }, [setOptions]);

  const importExternalPO = useCallback(
    async (externalPO: ExternalPoFieldsFragment | null | undefined) => {
      if (externalPO && connectedSourceSystem) {
        const isNonItemizedPO = !checkIfPOIsItemized(externalPO);
        const importableItems = getImportableItems(externalPO);

        try {
          let items: AddToReleaseItemInput[] = [];
          if (isNonItemizedPO) {
            for await (const item of importableItems) {
              const itemIndex = importableItems.findIndex(
                (i) => i.id === item.id,
              );
              const lumpSumItem = await getLumpSumReleaseItem(
                {
                  costCodeId: projectCostCodes.find(
                    (costCode) => costCode?.code === item.costCode,
                  )?.id,
                  costTypeId: costTypes.find(
                    (type) => item.costType === type.code,
                  )?.id,
                  netAmount: Number(item.extPrice),
                },
                {},
              );
              items.push({
                ...lumpSumItem[0],
                position: itemIndex + 1,
                instructions: item.instructions?.text
                  ? { text: item.instructions?.text }
                  : undefined,
              });
            }
          } else {
            const uniqueMissingMaterialsNames = [
              ...new Set(
                importableItems
                  .filter(
                    (item) =>
                      !materials.find(
                        (material) =>
                          material.material.name === item.description,
                      ),
                  )
                  .map((item) => item.description),
              ),
            ];
            const addedMaterials = uniqueMissingMaterialsNames
              .map((row) => {
                const item = importableItems.find(
                  (item) => item.description === row,
                );
                if (!item) {
                  return;
                }
                return {
                  newOrgCatalogSKU: {
                    defaultUom: item.UOM || DEFAULT_UNIT_UOM,
                    name: item.description,
                  },
                };
              })
              .filter((item): item is NonNullable<typeof item> => !!item);

            const newMaterialsMap =
              await updateMaterialsAndGetMap(addedMaterials);

            items = importableItems.map((item) => ({
              poItemExternalId: item.id,
              quantityDecimal: isLumpSumUomText(item.UOM)
                ? item.unitPrice
                : item.quantityDecimal,
              projectItem: {
                estimateUom:
                  getUomByName(item.UOM)?.id ??
                  getUomByName(DEFAULT_UNIT_UOM)?.id ??
                  "",
                orgCatalogSkuId:
                  materials.find(
                    (material) => material.material.name === item.description,
                  )?.material.id ?? newMaterialsMap?.get(item.description),
              },
              costCodeId: item.costCode
                ? projectCostCodes.find((code) => item.costCode === code.code)
                    ?.id
                : null,
              costTypeId: item.costType
                ? costTypes.find((type) => item.costType === type.code)?.id
                : null,
              unitPrice: !isLumpSumUomText(item.UOM) ? item.unitPrice : "1",
              instructions: item.instructions?.text
                ? { text: item.instructions?.text }
                : undefined,
              name: item.description,
            }));
          }
          const time = variables.date ?? externalPO.date;
          const retroactive = time < new Date().getTime();
          const customTaxAmount = externalPO.itemGroups.taxAmount;
          const input: CreateStandaloneReleaseInput = {
            projectId: externalPO.project?.project?.id,
            mapping: {
              externalId: externalPO?.externalId,
              sourceSystem: connectedSourceSystem,
            },
            sellerOrgLocationId: variables.sellerOrgLocationId,
            typeId: variables.orderTypeId,
            poNumber: externalPO.number,
            assignPOItemZones: !isNonItemizedPO,
            time: time,
            ...(!retroactive && { status: ReleaseStatus.Scheduled }),
            assignDefaultCostCodes: false,
            retroactive,
            customTaxAmount: customTaxAmount
              ? customTaxAmount.toString()
              : undefined,
            taxCodeId: externalPO.itemGroups.taxCodeId,
            taxType: externalPO.itemGroups.taxType,
            items,
          };
          const result = await createStandaloneRelease({
            variables: {
              input,
            },
          });

          if (result.data?.createStandaloneRelease) {
            setSystemAlert(
              intl.$t(
                {
                  id:
                    externalPO.itemGroups.importPossibility ===
                    ExternalPoImportPossibility.Immutable
                      ? "IMPORT_ORDER_FROM_SOURCE_SYSTEM_IMMUTABLE"
                      : "IMPORT_ORDER_FROM_SOURCE_SYSTEM",
                },
                {
                  integration: connectedSourceSystem
                    ? intl.$t({
                        id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                      })
                    : "",
                  poNumber: externalPO.number,
                },
              ),
              {
                type: SystemAlertType.MANUAL,
              },
            );
            navigate(
              generatePath(routes.delivery, {
                deliveryId: result.data?.createStandaloneRelease.id,
              }),
            );
            props.closeModal();
          }
        } catch (error) {
          setError(error);
        }
      }
    },
    [
      checkIfPOIsItemized,
      connectedSourceSystem,
      projectCostCodes,
      costTypes,
      createStandaloneRelease,
      getImportableItems,
      getLumpSumReleaseItem,
      getUomByName,
      intl,
      materials,
      navigate,
      props,
      setError,
      setSystemAlert,
      updateMaterialsAndGetMap,
      variables.date,
      variables.orderTypeId,
      variables.sellerOrgLocationId,
    ],
  );

  const pages = useMemo(
    () =>
      getPages({
        importSelectedExternalPO: importExternalPO,
        includeDate: true,
      }),
    [getPages, importExternalPO],
  );

  return {
    ...variables,
    ...props,
    importModalOpened: showModal,
    importExternalPO,
    creatingRelease,
    pages,
  };
};

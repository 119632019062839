import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  SpreadsheetSaveType,
  useColumnMapper,
} from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useGetProjectItemFromSku } from "@/common/hooks/useGetProjectItem";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { PROJECT } from "@/contractor/pages/home/project/queries/project";
import {
  AddEstimatedItemInput,
  ManufacturerFieldsFragment,
  TagExtendedFieldsFragment,
  UomsDocument,
  UpdateEstimatedItemInput,
  useUpdateEstimatedItemsMutation,
  ZoneFieldsFragment,
} from "@/generated/graphql";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useProjectItemsZones } from "../providers/ProjectItemsZonesProvider";
import { useProject } from "../providers/ProjectProvider";
import { useProjectMaps } from "./useProjectMaps";
import { useProjectZones } from "./useProjectZones";

type SyncEstimatedItemsProps = {
  trigger?: SpreadsheetSaveType;
};

export const useSyncEstimatedItems = () => {
  const { manufacturers } = useManufacturers();
  const {
    spreadsheetData,
    getRemovedRowIds,
    rowHasChanges,
    resetPreviousData,
    gotoInvalidRow,
  } = useColumnMapper();
  const {
    addMissingMaterials,
    addMissingManufacturers,
    addMissingZones,
    addMissingTags,
    getCellWithAdditionalData,
    getRowTagIds,
    getCostCodeId,
    tableHasColumn,
  } = useTableHelpers();
  const findMaterialByName = useFindMaterialByName();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { project } = useProject();
  const { estimatedItemsMap } = useProjectMaps(project);
  const { setError } = useGlobalError();
  const [updateEstimatedItems, { loading: updating }] =
    useUpdateEstimatedItemsMutation();
  const { materials } = useMaterials();
  const [saving, setSaving] = useState(false);
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();
  const { zones } = useProjectZones();
  const { hasPhaseCodes } = useOrgSettings();
  const getProjectItem = useGetProjectItemFromSku();
  const { vendors, getVendorCode } = useVendors();
  const { filteredOutItems } = useProjectItemsZones();

  useEffect(() => {
    setSaving(updating);
  }, [updating]);

  const addUpdatedItemOptionalFields = useCallback(
    (
      item: UpdateEstimatedItemInput,
      row: Record<string, string>,
      newZones: ZoneFieldsFragment[],
      newManufacturers: ManufacturerFieldsFragment[],
      newTags: TagExtendedFieldsFragment[],
    ): UpdateEstimatedItemInput => {
      const notes = getCellValue(row, COLUMN_TYPE.Notes);
      const zone = [...(zones || []), ...newZones].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );
      const manufacturer = [...manufacturers, ...newManufacturers].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );

      const sellerOrgLocationId = vendors.find(
        (vendor) =>
          vendorLabelFormatter(vendor.sellerOrgLocation, [], {
            vendorCode: getVendorCode(vendor),
          }) === getCellValue(row, COLUMN_TYPE.Supplier),
      )?.sellerOrgLocation.id;

      if (tableHasColumn(COLUMN_TYPE.Zone)) {
        item.zoneId = zone?.id;
        item.clearZone = !zone;
      }

      if (tableHasColumn(COLUMN_TYPE.Manufacturer)) {
        item.manufacturerId = manufacturer?.id;
        item.clearManufacturer = !manufacturer;
      }

      if (tableHasColumn(COLUMN_TYPE.Tag)) {
        item.tags = getRowTagIds(row, newTags);
      }

      if (tableHasColumn(COLUMN_TYPE.Supplier)) {
        item.sellerOrgLocationId = sellerOrgLocationId;
        item.clearSellerOrgLocation = !sellerOrgLocationId;
      }

      if (tableHasColumn(COLUMN_TYPE.Notes)) {
        item.notes = notes;
      }

      const hasCostCode = tableHasColumn(COLUMN_TYPE.CostCode);
      const hasPhaseCode = tableHasColumn(COLUMN_TYPE.PhaseCode);

      if (hasCostCode || hasPhaseCode) {
        item.costCodeId = getCostCodeId(row);
        item.clearCostCode = !getCostCodeId(row);
      }

      return item;
    },
    [
      zones,
      manufacturers,
      vendors,
      getVendorCode,
      getRowTagIds,
      getCostCodeId,
      tableHasColumn,
    ],
  );

  const addNewItemOptionalFields = useCallback(
    (
      item: AddEstimatedItemInput,
      row: Record<string, string>,
      newZones: ZoneFieldsFragment[],
      newManufacturers: ManufacturerFieldsFragment[],
      newTags: TagExtendedFieldsFragment[],
    ): AddEstimatedItemInput => {
      const notes = getCellValue(row, COLUMN_TYPE.Notes);
      const zone = [...(zones || []), ...newZones].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );
      const manufacturer = [...manufacturers, ...newManufacturers].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );

      const sellerOrgLocationId = vendors.find(
        (vendor) =>
          vendorLabelFormatter(vendor.sellerOrgLocation, [], {
            vendorCode: getVendorCode(vendor),
          }) === getCellValue(row, COLUMN_TYPE.Supplier),
      )?.sellerOrgLocation.id;

      if (tableHasColumn(COLUMN_TYPE.Zone)) {
        item.zoneId = zone?.id;
      }

      if (tableHasColumn(COLUMN_TYPE.Manufacturer)) {
        item.manufacturerId = manufacturer?.id;
      }

      if (tableHasColumn(COLUMN_TYPE.Tag)) {
        item.tags = getRowTagIds(row, newTags);
      }

      if (tableHasColumn(COLUMN_TYPE.Supplier)) {
        item.sellerOrgLocationId = sellerOrgLocationId;
      }

      if (tableHasColumn(COLUMN_TYPE.Notes)) {
        item.notes = notes;
      }

      const hasCostCode = tableHasColumn(COLUMN_TYPE.CostCode);
      const hasPhaseCode = tableHasColumn(COLUMN_TYPE.PhaseCode);

      if (hasCostCode || hasPhaseCode) {
        item.costCodeId = getCostCodeId(row);
      }

      return item;
    },
    [
      zones,
      manufacturers,
      vendors,
      getVendorCode,
      getRowTagIds,
      getCostCodeId,
      tableHasColumn,
    ],
  );

  const getSyncedEstimatedItems = useCallback(async () => {
    if (!project) {
      return null;
    }

    const items =
      project.items
        .map((pi) =>
          pi.estimatedItems.map((ei) => ({
            ...pi,
            ...estimatedItemsMap.get(ei.id),
          })),
        )
        .flat() || [];

    const filteredOutItemIds = new Set(filteredOutItems.map((item) => item.id));

    const newItems: AddEstimatedItemInput[] = [];
    const itemsToUpdate: UpdateEstimatedItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(items ?? []).filter(
      (id) => !filteredOutItemIds.has(id),
    );

    setSaving(true);
    const newManufacturers = (await addMissingManufacturers()) || [];
    const newMaterials = (await addMissingMaterials(newManufacturers)) || [];
    const newZones = (await addMissingZones(project.id)) || [];
    const newTags = (await addMissingTags(project.id)) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      const material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);
      if (!material || rowIsEmpty(row)) {
        if (row.id && !filteredOutItemIds.has(row.id)) {
          itemsToRemove.push(row.id);
        }
        return;
      }

      const uom = getCellValue(row, COLUMN_TYPE.UOM);
      const quantityDecimal = getCellValue(row, COLUMN_TYPE.PositiveQuantity);
      const unitPrice = getCellValue(row, COLUMN_TYPE.PrefilledPrice);
      const hasUnitPrice =
        unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;

      const existingItem = items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.material.id === material.id;

      if (matchingMaterials) {
        if (rowHasChanges(row) || existingItem.position !== index) {
          const item = {
            id: row.id,
            quantityDecimal,
            unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
            clearUnitPrice: !hasUnitPrice,
            uom,
            position: index,
          } as UpdateEstimatedItemInput;

          itemsToUpdate.push(
            addUpdatedItemOptionalFields(
              item,
              row,
              newZones,
              newManufacturers,
              newTags,
            ),
          );
        }
      } else {
        const newItem = {
          item: getProjectItem(material, uom),
          quantityDecimal,
          unitPrice: unitPrice ? String(unitPrice) : undefined,
          position: index,
        } as AddEstimatedItemInput;

        newItems.push(
          addNewItemOptionalFields(
            newItem,
            row,
            newZones,
            newManufacturers,
            newTags,
          ),
        );

        if (row.id && !filteredOutItemIds.has(row.id)) {
          itemsToRemove.push(row.id);
        }
      }
    });

    return {
      projectId: project?.id ?? "",
      updates: itemsToUpdate,
      addedItems: newItems,
      removedItems: itemsToRemove,
    };
  }, [
    project,
    estimatedItemsMap,
    filteredOutItems,
    getRemovedRowIds,
    addMissingManufacturers,
    addMissingMaterials,
    addMissingZones,
    addMissingTags,
    spreadsheetData,
    getCellWithAdditionalData,
    findMaterialByName,
    materials,
    rowHasChanges,
    getProjectItem,
    addUpdatedItemOptionalFields,
    addNewItemOptionalFields,
  ]);

  const validateItems = useCallback(async (): Promise<boolean> => {
    const isValid =
      (await validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.PositiveQuantity,
      ])) &&
      (await validateRowValues(
        [
          COLUMN_TYPE.PositiveQuantity,
          COLUMN_TYPE.UOM,
          ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
        ],
        undefined,
        { prohibitLsUom: true },
      ));

    if (!isValid) {
      gotoInvalidRow();
      return false;
    }

    return true;
  }, [
    validateRequiredValues,
    validateRowValues,
    hasPhaseCodes,
    gotoInvalidRow,
  ]);

  const syncEstimatedItems = useCallback(
    async (props?: SyncEstimatedItemsProps) => {
      const { trigger } = props || {};

      if (!project) {
        return false;
      }

      if (!(await validateItems())) {
        return false;
      }

      const syncedItems = await getSyncedEstimatedItems();

      if (!syncedItems) {
        return false;
      }

      const { projectId, updates, addedItems, removedItems } = syncedItems;

      if (removedItems.length > 50) {
        setSuccessAlert(intl.$t({ id: "SAVING" }));
      }

      if (
        updates.length > 0 ||
        addedItems.length > 0 ||
        removedItems.length > 0
      ) {
        try {
          const { errors } = await updateEstimatedItems({
            variables: {
              input: {
                projectId,
                updates,
                addedItems,
                removedItems,
              },
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: PROJECT,
                variables: { id: projectId, excludePhantoms: true },
              },
              { query: UomsDocument },
            ],
          });

          setError(errors);
          if (!errors) {
            resetPreviousData();
            setSuccessAlert(intl.$t({ id: "ESTIMATED_ITEMS_SAVED_SUCCESS" }));
          }
          return !errors;
        } catch (error) {
          setError(error);
          return false;
        }
      } else if (trigger === SpreadsheetSaveType.SaveButton) {
        setSuccessAlert(intl.$t({ id: "NOTHING_TO_SAVE" }));
      }

      return true;
    },
    [
      project,
      validateItems,
      getSyncedEstimatedItems,
      updateEstimatedItems,
      setError,
      resetPreviousData,
      setSuccessAlert,
      intl,
    ],
  );

  return {
    getSyncedEstimatedItems,
    validateItems,
    syncEstimatedItems,
    saving,
  };
};

import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { ComplianceGroupFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useComplianceGroupsConfiguration } from "./ComplianceGroups.config";

type Props = {
  loading?: boolean;
  items: ComplianceGroupFieldsFragment[];
  totalCount: number;
};

export const ComplianceGroupList: FC<Props> = ({
  loading,
  items,
  totalCount,
}) => {
  const { configuration } = useComplianceGroupsConfiguration();

  return (
    <ListRenderer totalCount={totalCount} count={items.length} localPaginated>
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            header: "top-[134px] bg-transparent",
          },
        }}
        items={items}
        loading={loading}
        emptyList={
          <NoResults
            isFiltered={false}
            translationKey="COMPLIANCE_GROUP_LIST_NO_ITEMS"
          />
        }
      />
    </ListRenderer>
  );
};

import { EditButton } from "@/common/components/button/EditButton";
import { ConnectionStatusAdornment } from "@/common/components/connection-status-adornment/ConnectionStatusAdornment";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { SourceSystemFeatureRequirement } from "@/common/components/integration-feature-requirement/SourceSystemFeatureRequirement";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { InventoryManagementCheck } from "@/common/components/modules-check/components/InventoryManagementCheck";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { SourceSystemWrapper } from "@/common/components/source-system-wrapper/SourceSystemWrapper";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { SwitchGroup } from "@/common/components/switch/SwitchGroup";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { TextField } from "@/common/components/textfield/TextField";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useExternalGeneralLedgerAccounts } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/general-ledger-account/ExternalGeneralLedgerAccountsProvider";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import * as GraphQL from "@/generated/graphql";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useOrderTypeForm } from "../../hooks/useOrderTypeForm";
import { useOrderTypesActions } from "../../hooks/useOrderTypesActions";
import { useExternalLedgerAccount } from "../../providers/ExternalLedgerAccountProvider";
import { useExternalPoType } from "../../providers/ExternalPOTypeProvider";
import { AdditionalChargesModal } from "../allocation-settings/additional-charges/AdditionalChargesModal";
import { AdditionalChargesPicker } from "../allocation-settings/additional-charges/AdditionalChargesPicker";
import { DefaultItemModal } from "../allocation-settings/default-item/DefaultItemModal";
import { SalesTaxModal } from "../allocation-settings/sales-tax/SalesTaxModal";
import { SalesTaxPicker } from "../allocation-settings/sales-tax/SalesTaxPicker";
import { OrderTypeFormProps } from "./OrderTypeForm";
import * as OrderTypeFormStyle from "./OrderTypeForm.styles";
import { useOrderTypeFormWithProviders } from "./useOrderTypeFormWithProviders";

export const OrderTypeFormWithProviders: FC<OrderTypeFormProps> = ({
  onClose,
  title,
  orderType,
}) => {
  const intl = useIntl();
  const { creating, updating } = useOrderTypesActions();
  const { costTypes } = useCostTypes();
  const { materialsMap, materials, loading: loadingMaterials } = useMaterials();
  const {
    pages: pagesGl,
    closeModal: closeModalGl,
    open: openGlAccount,
    openModal: openModalGLAccount,
    setSelectedToJobLedgerAccountName,
    selectedToJobLedgerAccountName,
    existingLedgerAccount,
  } = useExternalLedgerAccount();
  const { loadingExternalLedgerAccounts } = useExternalGeneralLedgerAccounts();
  const [openedAdditionalChargesModal, setOpenedAdditionalChargesModal] =
    useState(false);
  const [openedDefaultItemModal, setOpenedDefaultItemModal] = useState(false);
  const [defaultItemName, setDefaultItemName] = useState("");
  const [openedSalesTaxModal, setOpenedSalesTaxModal] = useState(false);
  const {
    pages: pagesPoType,
    closeModal: closeModalPoType,
    open: openPoType,
    openModal: openModalPoType,
    setPoTypeName,
    poTypeName,
    existingPoType,
  } = useExternalPoType();
  const { connectedSourceSystem, loading } = useOrgSettingsExtended();
  const { formatCostCode, projectCostCodes } = useProjectCostCodes();
  const { methods } = useOrderTypeForm(orderType);
  const { hasFeatureInConnectedSourceSystem: hasFeature } =
    useIntegrationFeatureRequirement();
  const { onSave } = useOrderTypeFormWithProviders({
    orderType,
    onClose,
    methods,
  });
  const kind = methods.watch("kind");
  const chargesMode = methods.watch("chargesMode");
  const taxMode = methods.watch("taxMode");
  const salesTaxCostCodeId = methods.watch("salesTaxCostCodeId");
  const salesTaxCostTypeId = methods.watch("salesTaxCostTypeId");
  const additionalChargesCostCodeId = methods.watch(
    "additionalChargesCostCodeId",
  );
  const additionalChargesCostTypeId = methods.watch(
    "additionalChargesCostTypeId",
  );
  const salesTaxOrgMaterialId = methods.watch("salesTaxOrgMaterialId");
  const defaultOrgMaterialId = methods.watch("defaultOrgMaterialId");
  const trackInventory = methods.watch("trackInventory");

  const ledgerSubaccountOptions = useMemo(
    () =>
      existingLedgerAccount?.subaccounts?.map((subaccount) => ({
        value: subaccount.externalId,
        label: `(${subaccount.number}) ${subaccount.name}`,
      })) ?? [],
    [existingLedgerAccount?.subaccounts],
  );

  useEffect(() => {
    if (orderType?.ledgerAccount) {
      setSelectedToJobLedgerAccountName(orderType.ledgerAccount.name);
    }
  }, [orderType?.ledgerAccount, setSelectedToJobLedgerAccountName]);

  useEffect(() => {
    if (defaultOrgMaterialId) {
      setDefaultItemName(
        materialsMap.get(defaultOrgMaterialId)?.material.name || "",
      );
    }
  }, [defaultOrgMaterialId, materialsMap]);

  useEffect(() => {
    methods.setValue(
      "ledgerAccountId",
      existingLedgerAccount?.externalId || null,
    );
  }, [methods, existingLedgerAccount]);

  useEffect(() => {
    if (
      existingLedgerAccount &&
      !loadingExternalLedgerAccounts &&
      !ledgerSubaccountOptions.length
    ) {
      methods.setValue("ledgerSubaccountId", null);
    }
  }, [
    methods,
    existingLedgerAccount,
    loadingExternalLedgerAccounts,
    ledgerSubaccountOptions.length,
  ]);

  useEffect(() => {
    methods.setValue("poTypeId", existingPoType?.externalId || null);
  }, [methods, existingPoType]);

  useEffect(() => {
    if (kind !== GraphQL.TransactionKind.Purchase) {
      methods.setValue("requireDeliverySlip", false);
    }
  }, [kind, methods]);

  useEffect(() => {
    if (trackInventory) {
      methods.setValue("requireDeliverySlip", true);
    }
  }, [trackInventory, methods]);

  useEffect(() => {
    setPoTypeName(orderType?.poType?.name ?? "");
  }, [orderType?.poType?.name, setPoTypeName]);

  return (
    <UploadAssetProvider context={GraphQL.AssetContext.OrgImage}>
      <FormProvider {...methods}>
        <OrderTypeFormStyle.Form onSubmit={methods.handleSubmit(onSave)}>
          <OrderTypeFormStyle.OverlayPanelStyled
            title={
              <OrderTypeFormStyle.TitleContainer>
                {title}
              </OrderTypeFormStyle.TitleContainer>
            }
            onCancel={onClose}
            isLoading={loading}
            saving={creating || updating}
            disableSave={loading}
            onSave={methods.handleSubmit(onSave)}
          >
            <OrderTypeFormStyle.Container>
              <TextFieldControlled
                name="name"
                size="small"
                label={intl.$t({ id: "NAME_OF_ORDER_TYPE" })}
                rules={{ required: true }}
              />
              <SourceSystemWrapper
                allowedFeatures={[IntegrationFeature.GeneralLedgerAccount]}
              >
                <TextField
                  value={selectedToJobLedgerAccountName}
                  onChange={(e) =>
                    setSelectedToJobLedgerAccountName(e.target.value)
                  }
                  size="small"
                  label={intl.$t({ id: "GL_ACCOUNT_NUMBER" })}
                  InputProps={{
                    startAdornment: (
                      <>
                        <ConnectionStatusAdornment
                          connectedSourceSystem={connectedSourceSystem?.system}
                          isActive={!!existingLedgerAccount}
                          onClick={() => openModalGLAccount()}
                          disabledTooltip="GL_ACCOUNT_NOT_FOUND_IN_SOURCE_SYSTEM"
                          enabledTooltip="GL_ACCOUNT_FOUND_IN_SOURCE_SYSTEM"
                        />
                        <If isTrue={existingLedgerAccount}>
                          <OrderTypeFormStyle.LedgerNumber>
                            {"(" + existingLedgerAccount?.number + ")"}
                          </OrderTypeFormStyle.LedgerNumber>
                        </If>
                      </>
                    ),
                    endAdornment: loadingExternalLedgerAccounts ? (
                      <OrderTypeFormStyle.LoaderStyled loading small />
                    ) : (
                      <OrderTypeFormStyle.SearchIcon
                        onClick={() => openModalGLAccount()}
                      />
                    ),
                  }}
                  error={
                    !loadingExternalLedgerAccounts &&
                    !existingLedgerAccount &&
                    !!selectedToJobLedgerAccountName
                  }
                />
                <If isTrue={ledgerSubaccountOptions.length}>
                  <SelectControlled
                    name="ledgerSubaccountId"
                    getLabel={(option) => option.label}
                    getValue={(option) => option.value}
                    label={intl.$t({ id: "GL_SUBACCOUNT_NUMBER" })}
                    options={ledgerSubaccountOptions}
                    rules={{
                      required: true,
                    }}
                  />
                </If>
              </SourceSystemWrapper>
              <SourceSystemWrapper
                allowedFeatures={[
                  IntegrationFeature.DefaultItemOrderTypeMapping,
                ]}
              >
                <TextField
                  value={defaultItemName}
                  onChange={(e) => setDefaultItemName(e.target.value)}
                  onBlur={() => {
                    methods.setValue(
                      "defaultOrgMaterialId",
                      materials.find((m) => m.material.name === defaultItemName)
                        ?.id ?? null,
                    );
                  }}
                  size="small"
                  label={intl.$t({ id: "DEFAULT_ITEM" })}
                  InputProps={{
                    startAdornment: (
                      <ConnectionStatusAdornment
                        connectedSourceSystem={connectedSourceSystem?.system}
                        isActive={
                          !!materials.find(
                            (m) => m.material.name === defaultItemName,
                          )
                        }
                        onClick={() => setOpenedDefaultItemModal(true)}
                        disabledTooltip="DEFAULT_ITEM_NOT_FOUND_IN_SOURCE_SYSTEM"
                        enabledTooltip="DEFAULT_ITEM_FOUND_IN_SOURCE_SYSTEM"
                      />
                    ),
                    endAdornment: loadingMaterials ? (
                      <OrderTypeFormStyle.LoaderStyled loading small />
                    ) : (
                      <OrderTypeFormStyle.SearchIcon
                        onClick={() => setOpenedDefaultItemModal(true)}
                      />
                    ),
                  }}
                  error={
                    !loadingMaterials &&
                    !!defaultItemName &&
                    !materials.find((m) => m.material.name === defaultItemName)
                  }
                />
              </SourceSystemWrapper>
              <SourceSystemWrapper
                allowedFeatures={[IntegrationFeature.IncludeCostTypes]}
              >
                <SelectControlled
                  name="costType"
                  getLabel={(option) => option.description}
                  getValue={(option) => option.id}
                  label={intl.$t({ id: "COST_TYPE" })}
                  options={costTypes.filter(
                    (c) =>
                      !connectedSourceSystem ||
                      c.mappings.some(
                        (m) => m.sourceSystem === connectedSourceSystem?.system,
                      ),
                  )}
                  rules={{
                    required:
                      !connectedSourceSystem ||
                      !hasFeature(IntegrationFeature.OptionalCostType),
                  }}
                />
              </SourceSystemWrapper>
              <SourceSystemWrapper
                allowedFeatures={[IntegrationFeature.PoType]}
              >
                <TextField
                  value={poTypeName}
                  onChange={(e) => setPoTypeName(e.target.value)}
                  size="small"
                  label={intl.$t({ id: "PO_TYPE" })}
                  InputProps={{
                    startAdornment: (
                      <ConnectionStatusAdornment
                        connectedSourceSystem={connectedSourceSystem?.system}
                        isActive={!!existingPoType}
                        onClick={() => openModalPoType()}
                        disabledTooltip="PO_TYPE_NOT_FOUND_IN_SOURCE_SYSTEM"
                        enabledTooltip="PO_TYPE_FOUND_IN_SOURCE_SYSTEM"
                      />
                    ),
                    endAdornment: (
                      <OrderTypeFormStyle.SearchIcon
                        onClick={() => openModalPoType()}
                      />
                    ),
                  }}
                  error={!existingPoType && !!poTypeName}
                />
              </SourceSystemWrapper>
              <SelectControlled
                name="kind"
                getLabel={(option) =>
                  intl.$t({ id: `TRANSACTION_KIND_${option}` })
                }
                getValue={(option) => option}
                label={intl.$t({ id: "TRANSACTION_KIND" })}
                options={Object.values(GraphQL.TransactionKind)}
                rules={{ required: true }}
              />
              <SwitchGroup
                label={<FormattedMessage id="SET_AS_DEFAULT_ORDER_TYPE" />}
              >
                <SwitchControlled
                  name="default"
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  testId="form-default-switch"
                  disabled={orderType?.default}
                />
              </SwitchGroup>
              <InventoryManagementCheck>
                <SwitchGroup
                  label={<FormattedMessage id="TRACK_IN_INVENTORY" />}
                >
                  <SwitchControlled
                    name="trackInventory"
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    testId="form-default-switch"
                  />
                </SwitchGroup>
              </InventoryManagementCheck>
              <SwitchGroup label={<FormattedMessage id="PO_FORMAT" />}>
                <SwitchControlled
                  name="poFormatBasic"
                  width={90}
                  onLabel={intl.$t({ id: "BASIC" })}
                  offLabel={intl.$t({ id: "DETAILED" })}
                  testId="form-po-format-switch"
                />
              </SwitchGroup>
              <SwitchGroup
                label={<FormattedMessage id="ENABLE_DELIVERY_SLIPS" />}
                tooltip={
                  <FormattedMessage id="ENABLE_DELIVERY_SLIPS_TOOLTIP" />
                }
              >
                <SwitchControlled
                  name="requireDeliverySlip"
                  disabled={
                    kind !== GraphQL.TransactionKind.Purchase || trackInventory
                  }
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  testId="form-require-delivery-slip-switch"
                />
              </SwitchGroup>
              <SwitchGroup label={<FormattedMessage id="DEFAULT_AUTO_SYNC" />}>
                <SwitchControlled
                  name="defaultAutoSync"
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  testId="form-default-auto-sync"
                />
              </SwitchGroup>
              <SourceSystemWrapper
                allowedFeatures={[IntegrationFeature.NonJobPO]}
              >
                <SwitchGroup
                  label={<FormattedMessage id="PO_NOT_ASSIGNED_TO_JOB" />}
                >
                  <SwitchControlled
                    name="nonJobPO"
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    testId="form-non-job-po"
                  />
                </SwitchGroup>
              </SourceSystemWrapper>
              <SwitchGroup
                label={<FormattedMessage id="INCLUDE_SALES_TAX_TO_SUBTOTAL" />}
              >
                <SwitchControlled
                  name="includeTaxInInvoiceSubtotal"
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  testId="form-include-tax-in-invoice-subtotal"
                />
              </SwitchGroup>
              <SourceSystemFeatureRequirement
                feature={IntegrationFeature.IncludeOrderTypeBillable}
              >
                <SwitchGroup
                  label={<FormattedMessage id="MARK_ITEMS_AS_BILLABLE" />}
                >
                  <SwitchControlled
                    name="billable"
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    testId="form-billable"
                  />
                </SwitchGroup>
              </SourceSystemFeatureRequirement>
              <If isTrue={kind === GraphQL.TransactionKind.Purchase}>
                <SwitchGroup
                  label={
                    <FormattedMessage id="SEND_DELIVERY_START_DATE_REMINDERS" />
                  }
                  tooltip={
                    <FormattedMessage id="SEND_DELIVERY_START_DATE_REMINDERS_TOOLTIP" />
                  }
                >
                  <SwitchControlled
                    name="sendReminders"
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    testId="form-send-reminders-switch"
                  />
                </SwitchGroup>
              </If>
            </OrderTypeFormStyle.Container>
            <OrderTypeFormStyle.GrayContainer>
              <OrderTypeFormStyle.Subtitle>
                <FormattedMessage id="ALLOCATION_SETTINGS" />
                <InfoTooltip
                  message={intl.$t({ id: "ALLOCATION_SETTINGS_TOOLTIP" })}
                />
              </OrderTypeFormStyle.Subtitle>
              <AdditionalChargesPicker />
              <If isTrue={chargesMode === GraphQL.ChargesMode.Separate}>
                <OrderTypeFormStyle.ItemsContainer>
                  <OrderTypeFormStyle.Items>
                    <SourceSystemFeatureRequirement
                      feature={IntegrationFeature.IncludeCostCodes}
                    >
                      <OrderTypeFormStyle.Item>
                        <OrderTypeFormStyle.Label
                          $error={!additionalChargesCostCodeId}
                        >
                          <FormattedMessage id="COST_CODE" />:
                        </OrderTypeFormStyle.Label>
                        {additionalChargesCostCodeId ? (
                          <OrderTypeFormStyle.Value>
                            {formatCostCode(
                              projectCostCodes.find(
                                (c) => c.id === additionalChargesCostCodeId,
                              ),
                            )}
                          </OrderTypeFormStyle.Value>
                        ) : (
                          <OrderTypeFormStyle.Label $error>
                            <NotNullableRenderer />
                          </OrderTypeFormStyle.Label>
                        )}
                      </OrderTypeFormStyle.Item>
                    </SourceSystemFeatureRequirement>
                    <SourceSystemFeatureRequirement
                      feature={IntegrationFeature.IncludeCostTypes}
                    >
                      <OrderTypeFormStyle.Item>
                        <OrderTypeFormStyle.Label
                          $error={!additionalChargesCostTypeId}
                        >
                          <FormattedMessage id="COST_TYPE" />:
                        </OrderTypeFormStyle.Label>
                        {additionalChargesCostTypeId ? (
                          <OrderTypeFormStyle.Value>
                            {formatCostCode(
                              costTypes.find(
                                (c) => c.id === additionalChargesCostTypeId,
                              ),
                            )}
                          </OrderTypeFormStyle.Value>
                        ) : (
                          <OrderTypeFormStyle.Label $error>
                            <NotNullableRenderer />
                          </OrderTypeFormStyle.Label>
                        )}
                      </OrderTypeFormStyle.Item>
                    </SourceSystemFeatureRequirement>
                  </OrderTypeFormStyle.Items>
                  <EditButton
                    onClick={() => setOpenedAdditionalChargesModal(true)}
                  />
                </OrderTypeFormStyle.ItemsContainer>
              </If>
              <SalesTaxPicker />
              <OrderTypeFormStyle.ItemsContainer>
                <OrderTypeFormStyle.Items>
                  <If isTrue={taxMode === GraphQL.TaxMode.Separate}>
                    <SourceSystemFeatureRequirement
                      feature={IntegrationFeature.IncludeCostCodes}
                    >
                      <OrderTypeFormStyle.Item>
                        <OrderTypeFormStyle.Label $error={!salesTaxCostCodeId}>
                          <FormattedMessage id="COST_CODE" />:
                        </OrderTypeFormStyle.Label>
                        {salesTaxCostCodeId ? (
                          <OrderTypeFormStyle.Value>
                            {formatCostCode(
                              projectCostCodes.find(
                                (c) => c.id === salesTaxCostCodeId,
                              ),
                            )}
                          </OrderTypeFormStyle.Value>
                        ) : (
                          <OrderTypeFormStyle.Label $error>
                            <NotNullableRenderer />
                          </OrderTypeFormStyle.Label>
                        )}
                      </OrderTypeFormStyle.Item>
                    </SourceSystemFeatureRequirement>
                  </If>
                  <If isTrue={taxMode === GraphQL.TaxMode.Separate}>
                    <SourceSystemFeatureRequirement
                      feature={IntegrationFeature.IncludeCostTypes}
                    >
                      <OrderTypeFormStyle.Item>
                        <OrderTypeFormStyle.Label $error={!salesTaxCostTypeId}>
                          <FormattedMessage id="COST_TYPE" />:
                        </OrderTypeFormStyle.Label>
                        {salesTaxCostTypeId ? (
                          <OrderTypeFormStyle.Value>
                            {formatCostCode(
                              costTypes.find(
                                (c) => c.id === salesTaxCostTypeId,
                              ),
                            )}
                          </OrderTypeFormStyle.Value>
                        ) : (
                          <OrderTypeFormStyle.Label $error>
                            <NotNullableRenderer />
                          </OrderTypeFormStyle.Label>
                        )}
                      </OrderTypeFormStyle.Item>
                    </SourceSystemFeatureRequirement>
                  </If>
                  <If
                    isTrue={
                      taxMode === GraphQL.TaxMode.Majority ||
                      taxMode === GraphQL.TaxMode.Separate
                    }
                  >
                    <SourceSystemFeatureRequirement
                      feature={
                        IntegrationFeature.IncludeOrderTypeSalesTaxOrgMaterial
                      }
                    >
                      <OrderTypeFormStyle.Item>
                        <OrderTypeFormStyle.Label
                          $error={!salesTaxOrgMaterialId}
                        >
                          <FormattedMessage id="ITEM" />:
                        </OrderTypeFormStyle.Label>
                        {salesTaxOrgMaterialId ? (
                          <OrderTypeFormStyle.Value>
                            {
                              materialsMap.get(salesTaxOrgMaterialId)?.material
                                .name
                            }
                          </OrderTypeFormStyle.Value>
                        ) : (
                          <OrderTypeFormStyle.Label $error>
                            <NotNullableRenderer />
                          </OrderTypeFormStyle.Label>
                        )}
                      </OrderTypeFormStyle.Item>
                    </SourceSystemFeatureRequirement>
                  </If>
                </OrderTypeFormStyle.Items>
                <If
                  isTrue={
                    taxMode === GraphQL.TaxMode.Separate ||
                    taxMode === GraphQL.TaxMode.Majority
                  }
                >
                  <EditButton onClick={() => setOpenedSalesTaxModal(true)} />
                </If>
              </OrderTypeFormStyle.ItemsContainer>
            </OrderTypeFormStyle.GrayContainer>
          </OrderTypeFormStyle.OverlayPanelStyled>
        </OrderTypeFormStyle.Form>
        <WizardModal
          mode={WizardModalMode.SINGLE_PAGE}
          opened={openGlAccount}
          pages={pagesGl}
          onClose={closeModalGl}
        />
        <WizardModal
          mode={WizardModalMode.SINGLE_PAGE}
          opened={openPoType}
          pages={pagesPoType}
          onClose={closeModalPoType}
        />
        <AdditionalChargesModal
          opened={openedAdditionalChargesModal}
          setOpened={setOpenedAdditionalChargesModal}
          costCodeId={additionalChargesCostCodeId}
          costTypeId={additionalChargesCostTypeId}
        />
        <SalesTaxModal
          opened={openedSalesTaxModal}
          setOpened={setOpenedSalesTaxModal}
          costCodeId={salesTaxCostCodeId}
          costTypeId={salesTaxCostTypeId}
          orgMaterialId={salesTaxOrgMaterialId}
          taxMode={taxMode}
        />
        <DefaultItemModal
          defaultItemId={defaultOrgMaterialId}
          setOpened={setOpenedDefaultItemModal}
          opened={openedDefaultItemModal}
        />
      </FormProvider>
    </UploadAssetProvider>
  );
};

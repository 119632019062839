import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  NestedWizardModalPage,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useEnabledPoInSourceSystem } from "@/contractor/common/hooks/useEnabledPoInSourceSystem";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { UpdateSourceSystemsSettingsInput } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useAgaveHostedWizard } from "../../../AgaveHostedWizardProvider";
import { GLAccountForInvoicesAgainstInventoryReceipts } from "../../../steps/general-ledger-account/GLAccountForInvoicesAgainstInventoryReceipts";
import { AgaveWizardPOSettings } from "../../../steps/po-settings/AgaveWizardPOSettings";

export const usePoExportSettingsPage = (): WizardModalPage => {
  const intl = useIntl();
  const {
    moveToPreviousStep,
    moveToNextStep,
    moveToPreviousNestedStep,
    moveToNextNestedStep,
  } = useNestedStepper();
  const {
    handleSaveWizardData,
    automatedSynchronization,
    autoPostPos,
    postInventoryReceipts,
    inventoryReceiptLedgerAccountId,
  } = useAgaveHostedWizard();

  const { connectedSourceSystem } = useOrgSettingsExtended();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();

  const hasInventoryReceiptsFeature = useMemo(() => {
    return hasFeatureInConnectedSourceSystem(
      IntegrationFeature.PostInventoryReceipts,
    )
      ? postInventoryReceipts
      : false;
  }, [postInventoryReceipts, hasFeatureInConnectedSourceSystem]);

  const onNextClick = useCallback(async () => {
    const settings: Omit<UpdateSourceSystemsSettingsInput, "system"> = {
      defaultAutoSync: automatedSynchronization,
      autoPostPOs: autoPostPos,
      postInventoryReceipts: hasFeatureInConnectedSourceSystem(
        IntegrationFeature.PostInventoryReceipts,
      )
        ? postInventoryReceipts
        : undefined,
      inventoryReceiptLedgerAccountId: hasInventoryReceiptsFeature
        ? inventoryReceiptLedgerAccountId
        : undefined,
    };

    await handleSaveWizardData(settings);
    moveToNextStep();
  }, [
    automatedSynchronization,
    autoPostPos,
    hasFeatureInConnectedSourceSystem,
    postInventoryReceipts,
    hasInventoryReceiptsFeature,
    inventoryReceiptLedgerAccountId,
    handleSaveWizardData,
    moveToNextStep,
  ]);

  return useMemo(() => {
    const pages: NestedWizardModalPage[] = [
      {
        component: (
          <AgaveWizardPOSettings
            showReceiptPosted={
              hasFeatureInConnectedSourceSystem(
                IntegrationFeature.PostInventoryReceipts,
              ) && isPoEnabledInSourceSystem
            }
          />
        ),
        onPreviousClick: moveToPreviousStep,
        onNextClick: async () =>
          await (hasInventoryReceiptsFeature
            ? moveToNextNestedStep()
            : onNextClick()),
        footerDotsConfig: hasInventoryReceiptsFeature
          ? {
              selectedIndex: 0,
              count: 2,
            }
          : undefined,
      },
    ];
    if (hasInventoryReceiptsFeature) {
      pages.push({
        component: <GLAccountForInvoicesAgainstInventoryReceipts />,
        onPreviousClick: moveToPreviousNestedStep,
        onNextClick,
        footerDotsConfig: {
          selectedIndex: 1,
          count: 2,
        },
      });
    }
    return {
      feature: IntegrationFeature.PoExportSettingsStep,
      title: intl.$t({ id: "PO_EXPORT_SETTINGS" }),
      completed: connectedSourceSystem?.defaultAutoSync !== null,
      pages,
    };
  }, [
    hasFeatureInConnectedSourceSystem,
    isPoEnabledInSourceSystem,
    moveToPreviousStep,
    hasInventoryReceiptsFeature,
    intl,
    connectedSourceSystem?.defaultAutoSync,
    moveToNextNestedStep,
    onNextClick,
    moveToPreviousNestedStep,
  ]);
};

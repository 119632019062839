import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { getProjectSelectorLabel } from "../../projects-filter-selector/getProjectSelectorLabel";

export const ALL_PROJECTS = "-1";

type Props = {
  includeAllOption?: boolean;
};
export const useProjectOptions = (opts?: Props) => {
  const { projects } = useProjectListOptions();
  const intl = useIntl();

  const projectOptions = useMemo(() => {
    return [
      ...(opts?.includeAllOption
        ? [
            {
              id: ALL_PROJECTS,
              name: intl.$t({ id: "ALL_PROJECTS" }),
            },
          ]
        : []),
      ...projects.map((project) => ({
        id: project.id,
        name: getProjectSelectorLabel(project),
      })),
    ];
  }, [intl, opts?.includeAllOption, projects]);

  return { projectOptions };
};

import { QUERYSTRING } from "@/common/const";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useCallback, useEffect, useState } from "react";

export const useBuyoutAddItems = () => {
  const [showEstimatedItemsDialog, setShowEstimatedItemsDialog] =
    useState(false);
  const { queryParams } = useQueryParams();

  const gotoItemSelection = useCallback(() => {
    setShowEstimatedItemsDialog(true);
  }, []);

  useEffect(() => {
    if (queryParams.get(QUERYSTRING.FORCE_ESTIMATED_ITEMS) === "true") {
      setShowEstimatedItemsDialog(true);
    }
  }, [queryParams]);

  return {
    showEstimatedItemsDialog,
    gotoItemSelection,
    setShowEstimatedItemsDialog,
  };
};

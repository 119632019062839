import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import {
  ProjectsProvider,
  useProjects,
} from "@/contractor/pages/home/projects/providers/ProjectsProvider";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { ProjectsHeader } from "./components/ProjectsHeader";
import { ImportExternalProjectsModal } from "./components/import-external-projects/ImportExternalProjectsModal";
import { ImportExternalProjectsWizardProvider } from "./components/import-external-projects/ImportExternalProjectsWizardProvider";
import { ProjectsList } from "./components/projects-list/ProjectsList";
import { ProjectsSelectAll } from "./components/side-actions/ProjectSelectAll";
import { ProjectsSideActions } from "./components/side-actions/ProjectsSideActions";

const ProjectsWithProvider = () => {
  const { projects, loading, totalCount, isFiltered } = useProjects();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "PROJECTS" })}</title>
      </Helmet>
      <ProjectsHeader />
      <ProjectsList
        projects={projects}
        loading={loading}
        totalCount={totalCount}
        isFiltered={isFiltered}
      />
      <ProjectsSideActions />
      <ProjectsSelectAll />
    </>
  );
};
export const Projects = () => (
  <NestedStepperProvider>
    <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
      <ProjectsProvider>
        <ImportExternalProjectsWizardProvider>
          <ProjectsWithProvider />
          <ImportExternalProjectsModal />
        </ImportExternalProjectsWizardProvider>
      </ProjectsProvider>
    </PaginationProvider>
  </NestedStepperProvider>
);

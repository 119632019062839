import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import {
  Base,
  LgFixedContainer,
  SmFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { ComplianceGroupFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ComplianceGroupActions } from "./ComplianceGroupActions";

const ActionsWrapper = tw(SmFixedContainer)`
  absolute right-2 top-0 lg:relative 
`;

const WideContainer = tw(Base)`
  lg:basis-auto lg:w-5/12 2xl:basis-auto 2xl:w-6/12
`;

export const useComplianceGroupsConfiguration = () => {
  const configuration: Array<GridCol<ComplianceGroupFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: LgFixedContainer,
        header: <FormattedMessage id="VENDOR_COMPLIANCE_GROUP" />,
        item: ({ item }) => item.code,
      },
      {
        wrapper: LgFixedContainer,
        header: <FormattedMessage id="DESCRIPTION" />,
        item: ({ item }) => item.description,
      },
      {
        wrapper: WideContainer,
        header: <FormattedMessage id="NOTES" />,
        item: ({ item }) => (
          <NotNullableRenderer value={item.notes}>
            {item.notes}
          </NotNullableRenderer>
        ),
      },
      {
        wrapper: ActionsWrapper,
        item: ({ item }) => {
          return <ComplianceGroupActions complianceGroup={item} />;
        },
        position: "center",
      },
    ],
    [],
  );

  return { configuration };
};

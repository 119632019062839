import { getUTCDate } from "@/common/components/picker/components/utils/getUTCDate";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { QUERYSTRING } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import {
  DeliverySlipFieldsFragment,
  DeliverySlipStatsDocument,
  DeliverySlipStatus,
  ReleaseSelectorFieldsFragment,
  ReleaseStatus,
  UpdateDeliverySlipInput,
  useDeliverySlipQuery,
  useUpdateDeliverySlipMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import { DeliverySlipSortOptions } from "../enums/DeliverySlipSortOptions";
import {
  DeliverySlipFilter,
  DeliverySlipFilters,
} from "../types/DeliverySlipFilters";

export enum PackingSlipMatchViewState {
  DEFAULT = "DEFAULT",
  MATCH_ORDER = "MATCH_ORDER",
  CREATE_ORDER = "CREATE_ORDER",
}

export enum PackingSlipReceiveViewState {
  DEFAULT = "DEFAULT",
  EDIT_ORDER = "EDIT_ORDER",
  EDIT_COVERAGE = "EDIT_COVERAGE",
}

export type ReleaseType = ReleaseSelectorFieldsFragment | null;

type ProviderContextType = {
  deliverySlip: DeliverySlipFieldsFragment | null;
  refetch: () => void;
  updateDeliverySlip: (input: UpdateDeliverySlipInput) => Promise<boolean>;
  updating: boolean;
  showOnlySelectedItems: boolean;
  setShowOnlySelectedItems: (show: boolean) => void;
  scanningSlip: boolean;
  filter: DeliverySlipFilters;
  setFilter: (filters: DeliverySlipFilters) => void;
  setScanningSlip: (scanning: boolean) => void;
  packingSlipMatchViewState: PackingSlipMatchViewState;
  setPackingSlipMatchViewState: (status: PackingSlipMatchViewState) => void;
  packingSlipReceiveViewState: PackingSlipReceiveViewState;
  setPackingSlipReceiveViewState: (status: PackingSlipReceiveViewState) => void;
  selectedRelease: ReleaseType;
  setSelectedRelease: (release: ReleaseType) => void;
  sortingOrder: DeliverySlipSortOptions;
  setSortingOrder: (order: DeliverySlipSortOptions) => void;
  handleSaveFulfillmentDate: (date: Date | null) => Promise<void>;
};

const ProviderContext = createContext<ProviderContextType>({
  deliverySlip: null,
  refetch: NoFunction,
  updateDeliverySlip: NoFunctionBooleanPromise,
  updating: false,
  showOnlySelectedItems: false,
  setShowOnlySelectedItems: NoFunction,
  scanningSlip: false,
  filter: {
    [DeliverySlipFilter.FULLY_RECEIVED]: undefined,
  },
  setFilter: NoFunction,
  setScanningSlip: NoFunction,
  packingSlipReceiveViewState: PackingSlipReceiveViewState.DEFAULT,
  setPackingSlipReceiveViewState: NoFunction,
  packingSlipMatchViewState: PackingSlipMatchViewState.DEFAULT,
  setPackingSlipMatchViewState: NoFunction,
  selectedRelease: null,
  setSelectedRelease: NoFunction,
  sortingOrder: DeliverySlipSortOptions.OriginalOrder,
  setSortingOrder: NoFunction,
  handleSaveFulfillmentDate: NoFunctionPromise,
});

export const DeliverySlipVerificationProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [showOnlySelectedItems, setShowOnlySelectedItems] = useState(false);
  const { deliverySlipId } = useParams();
  const [scanningSlip, setScanningSlip] = useState(false);
  const { data, error, refetch } = useDeliverySlipQuery({
    variables: { id: deliverySlipId || "" },
    skip: !deliverySlipId,
  });
  const { setError } = useGlobalError();
  useErrorEffect(error);

  const [filter, setFilter] = useState<DeliverySlipFilters>({
    [DeliverySlipFilter.FULLY_RECEIVED]: undefined,
  });
  const [sortingOrder, setSortingOrder] = useState(
    DeliverySlipSortOptions.OriginalOrder,
  );

  const [updateSlipMutation, { loading: updating }] =
    useUpdateDeliverySlipMutation();
  const updateDeliverySlip = useCallback(
    async (input: UpdateDeliverySlipInput) => {
      try {
        const { data, errors } = await updateSlipMutation({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: DeliverySlipStatsDocument,
            },
          ],
        });
        setError(errors);
        return !!data?.updateDeliverySlip;
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [setError, updateSlipMutation],
  );

  const { queryParams } = useQueryParams();

  const [packingSlipMatchViewState, setPackingSlipMatchViewState] =
    useState<PackingSlipMatchViewState>(PackingSlipMatchViewState.DEFAULT);
  const [packingSlipReceiveViewState, setPackingSlipReceiveViewState] =
    useState<PackingSlipReceiveViewState>(
      queryParams.get(QUERYSTRING.SPREADSHEET_VIEW) === "true"
        ? PackingSlipReceiveViewState.EDIT_ORDER
        : PackingSlipReceiveViewState.DEFAULT,
    );
  const [selectedRelease, setSelectedRelease] = useState<ReleaseType>(null);
  const { includePoNumbering } = usePoNumberingSettingsCheck();

  const formContext = useFormContext();

  useEffect(() => {
    if (includePoNumbering) {
      formContext?.setValue(
        "poNumber",
        data?.deliverySlip?.predictedRelease?.poNumber || "",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.deliverySlip?.predictedRelease?.poNumber]);

  useEffect(() => {
    if (data) {
      if (data?.deliverySlip.fulfillmentDate) {
        formContext?.setValue(
          "orderDate",
          defaultReleaseDate(getUTCDate(data?.deliverySlip.fulfillmentDate)),
        );
      }
      formContext?.setValue("projectId", data?.deliverySlip?.project?.id);
      formContext?.setValue(
        "vendorId",
        data?.deliverySlip?.predictedSellerOrgLocation?.id,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.deliverySlip?.fulfillmentDate, data?.deliverySlip?.project?.id]);

  useEffect(() => {
    if (data?.deliverySlip?.release) {
      setSelectedRelease(data?.deliverySlip.release);
    } else if (data?.deliverySlip?.predictedRelease) {
      setSelectedRelease(data?.deliverySlip.predictedRelease);
    }
  }, [data?.deliverySlip?.predictedRelease, data?.deliverySlip?.release]);

  useEffect(() => {
    if (data?.deliverySlip?.project) {
      formContext?.setValue("projectId", data?.deliverySlip.project.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.deliverySlip.project]);

  useEffect(() => {
    if (
      data?.deliverySlip?.status === DeliverySlipStatus.Pending &&
      data?.deliverySlip?.release
    ) {
      const release = data?.deliverySlip?.release;
      setPackingSlipReceiveViewState(
        queryParams.get(QUERYSTRING.SPREADSHEET_VIEW) === "true" ||
          ([ReleaseStatus.Draft, ReleaseStatus.Reserved].includes(
            release.status,
          ) &&
            !release.itemCount)
          ? PackingSlipReceiveViewState.EDIT_ORDER
          : PackingSlipReceiveViewState.EDIT_COVERAGE,
      );
      setPackingSlipMatchViewState(PackingSlipMatchViewState.MATCH_ORDER);
    } else if (data?.deliverySlip?.predictedRelease) {
      setPackingSlipMatchViewState(PackingSlipMatchViewState.MATCH_ORDER);
      if (!selectedRelease) {
        setSelectedRelease(data?.deliverySlip.predictedRelease);
      }
    } else {
      setPackingSlipReceiveViewState(PackingSlipReceiveViewState.DEFAULT);
      setPackingSlipMatchViewState(PackingSlipMatchViewState.DEFAULT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.deliverySlip, deliverySlipId, queryParams]);

  const handleSaveFulfillmentDate = useCallback(
    async (date: Date | null) => {
      if (!deliverySlipId || !date) {
        return;
      }
      await updateDeliverySlip({
        id: deliverySlipId,
        fulfillmentDate: date ? date.getTime() : null,
      });
    },
    [deliverySlipId, updateDeliverySlip],
  );

  return (
    <ProviderContext.Provider
      value={{
        deliverySlip: data?.deliverySlip || null,
        refetch,
        updateDeliverySlip,
        updating,
        showOnlySelectedItems,
        setShowOnlySelectedItems,
        scanningSlip,
        filter,
        setFilter,
        setScanningSlip,
        packingSlipMatchViewState,
        setPackingSlipMatchViewState,
        packingSlipReceiveViewState,
        setPackingSlipReceiveViewState,
        selectedRelease,
        setSelectedRelease,
        sortingOrder,
        setSortingOrder,
        handleSaveFulfillmentDate,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliverySlipVerification = (): ProviderContextType =>
  useContext(ProviderContext);

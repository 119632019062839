import { formattedDate } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import {
  ReleasePartialFieldsFragment,
  ReleaseToCsvExportFieldsFragment,
  ServiceType,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";

export const useExportToCsvReleaseData = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();

  const getReleaseNumberWithBuyout = useCallback(
    (
      release: ReleasePartialFieldsFragment | ReleaseToCsvExportFieldsFragment,
    ) => {
      const sequenceNumber = release.sequenceNumber
        ? intl.$t({ id: "RELEASE_NAME" }, { number: release.sequenceNumber })
        : intl.$t({ id: "RELEASE_STATUS_DRAFT" });
      const buyoutNumber = release.buyout
        ? intl.$t({ id: "BUYOUT" }, { number: release.buyout.clientIdentifier })
        : "";
      return buyoutNumber
        ? `${sequenceNumber} (${buyoutNumber})`
        : sequenceNumber;
    },
    [intl],
  );

  const getReleaseExportToCsvData = useCallback(
    (
      releases: (
        | ReleasePartialFieldsFragment
        | ReleaseToCsvExportFieldsFragment
      )[],
    ) => {
      const headers = [
        intl.$t({ id: "SUPPLIER" }),
        intl.$t({ id: "RELEASE" }),
        intl.$t({ id: "TYPE" }),
        intl.$t({ id: "PO_HASH" }),
        intl.$t({ id: "RELEASE_DESCRIPTION" }),
        intl.$t({ id: "PROJECT" }),
        intl.$t({ id: "CREATE_DATE" }),
        intl.$t({ id: "DELIVERY" }),
        intl.$t({ id: "DELIVERY_DATE" }),
        intl.$t({ id: "ORDER_VALUE" }),
        intl.$t({ id: "DELIVERY_ISSUES" }),
        intl.$t({ id: "STATUS" }),
        intl.$t({ id: "SITE_CONTACT" }),
        intl.$t({ id: "CREATOR" }),
      ];
      const currentDate = formattedDate({ date: new Date() });
      const fileName = `${intl.$t({ id: "ORDERS" })} - ${currentDate}`;
      const data = releases.map((release) => [
        release.sellerOrgLocation?.org.name || intl.$t({ id: "NOT_SET" }),
        getReleaseNumberWithBuyout(release),
        release.type.name,
        release.poNumber,
        release.description,
        release.project?.jobNumber,
        formattedDate({ date: release.createdAt, includeTime: true }),
        release.includeServices.includes(ServiceType.Delivery)
          ? intl.$t({ id: "VENDOR_DELIVERY" })
          : intl.$t({ id: "CLIENT_DELIVERY" }),
        release.timeTBD
          ? intl.$t({ id: "HOLD_FOR_RELEASE" })
          : release.time
            ? formattedDate({ date: release.time })
            : "",
        formatCurrency(release.total),
        release.issues.length,
        intl.$t({ id: `RELEASE_STATUS_${release.status}` }),
        release.siteContact
          ? `${release.siteContact.name || ""}${release.siteContact.phone ? ` (${release.siteContact.phone})` : ""}`
          : "",
        getUserName(release.createdBy),
      ]);

      return { data, fileName, headers };
    },
    [intl, getReleaseNumberWithBuyout, formatCurrency],
  );

  return { getReleaseExportToCsvData };
};

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { getPhysicalRowIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalRowIndex";
import { setTableCells } from "@/common/components/spreadsheet-table/utils/setTableCells";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useCallback } from "react";

export const useCopyQuotePrices = () => {
  const { handsonInstance, spreadsheetData, setMetadata } = useColumnMapper();
  const { quoteDocument } = useQuoteDocument();
  const { calcExtPrice } = usePriceCalculation();

  const copyPriceFromQuote = useCallback(() => {
    if (!handsonInstance || !quoteDocument) {
      return;
    }

    const updatedCells = [] as [number, number, string | number][];
    spreadsheetData?.forEach((row, index) => {
      if (row.id) {
        const match = quoteDocument.releaseItemHints.find(
          (hint) => hint?.releaseItem.id === row.id,
        );

        const matchingQuoteItem = match?.quoteDocumentItem;
        const matchingReleaseItem = match?.releaseItem;

        if (matchingQuoteItem) {
          const copiedPrice = matchingQuoteItem.unitPrice;
          const extPrice = calcExtPrice(
            matchingQuoteItem.quantity,
            matchingQuoteItem.unitPrice,
          );
          const previousPrice = matchingReleaseItem?.unitPrice;
          setMetadata(index, { extPrice, copiedPrice, previousPrice });

          updatedCells.push([
            getPhysicalRowIndex(handsonInstance, index),
            getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.UnitPrice),
            matchingQuoteItem.unitPrice || "",
          ]);

          updatedCells.push([
            getPhysicalRowIndex(handsonInstance, index),
            getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.ExtPrice),
            extPrice,
          ]);
        }
      }
    });
    setTableCells(updatedCells, handsonInstance);
  }, [
    calcExtPrice,
    handsonInstance,
    quoteDocument,
    setMetadata,
    spreadsheetData,
  ]);

  return { copyPriceFromQuote };
};

import { ColumnType } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { useMemo } from "react";
import { useTableHeaders } from "./useTableHeaders";

type UseTableColumnHeadersParams = {
  allColumns: ColumnType[];
};

export const useTableColumnHeaders = ({
  allColumns,
}: UseTableColumnHeadersParams) => {
  const { additionalHeader, headerWithAddButton, regularHeader, rowHeader } =
    useTableHeaders();

  const columnHeaders = useMemo(() => {
    return allColumns.map((col) => {
      const header = col.header;
      if (col.renderer === "materialRenderer") {
        return headerWithAddButton(String(header));
      } else if (col.additional) {
        return additionalHeader(String(header));
      } else {
        return regularHeader(String(header));
      }
    });
  }, [allColumns, headerWithAddButton, additionalHeader, regularHeader]);

  const rowHeaders = (index: number) => {
    return rowHeader(String(index + 1));
  };

  return {
    columnHeaders,
    rowHeaders,
  };
};

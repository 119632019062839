import { useWindowSize } from "@/common/hooks/useWindowResize";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { Tooltip } from "../tooltip/Tooltip";

type Type = "secondary" | "primary";
type TabProps = {
  label: string | React.JSX.Element;
  path?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  excluded?: boolean;
  skipCase?: boolean;
  end?: boolean;
  count?: number;
  onClick?: () => void;
  id?: number | string;
};

type TabsProps = {
  tabs: Array<TabProps>;
  className?: string;
  narrow?: boolean;
  type?: Type;
  actions?: React.JSX.Element | null;
  findIndex?: (tab: TabProps) => boolean;
  onTabItemClick?: (tab?: string) => void;
};

const TabsContainer = tw.div<{ $type: Type }>`
  sticky lg:bg-white bg-gray-100 overflow-hidden
  -top-12 pt-14 -mt-14 -mx-2 px-2 xl:px-16 xl:-mx-16 
  ${({ $type }: { $type: Type }) =>
    $type === "primary"
      ? "-top-12 z-30"
      : $type === "secondary"
        ? "top-2 z-20"
        : ""}
`;

const TabItems = tw.div`
  grid items-center justify-start gap-1 pb-[5px]
`;

const HorizontalLine = tw.div`
  absolute h-px bg-gray-200 bottom-1 left-0 right-0 xl:left-16 xl:right-16 lg:left-2 lg:right-2
`;

const Count = tw.div`bg-red-500 rounded-3xl text-xs text-white min-w-5 py-0.5 px-1.5 h-5 font-medium ml-2`;
const CountText = tw.div`flex items-center justify-center h-full`;
const ActionsContainer = tw.div`self-end`;

type TabItemProps = {
  $active?: boolean;
  $disabled?: boolean;
  $narrow?: boolean;
  $type: Type;
  className?: string;
};

const TabItem = tw.a<TabItemProps>`
  relative grid grid-cols-auto-1fr whitespace-nowrap text-gray-600 px-2 
  mx-auto -bottom-0 transition-colors duration-200 ease-out tracking-[.005em] py-1
  user-select-none cursor-pointer
  ${({ $active }: TabItemProps) =>
    $active && "text-black font-medium tracking-[0em]"}
  ${({ $disabled }: TabItemProps) =>
    $disabled && "cursor-not-allowed opacity-50"}
  ${({ $narrow }: TabItemProps) => ($narrow ? "lg:px-3 px-1" : "lg:px-6 px-2")}
  ${({ $type, $active }: TabItemProps) =>
    $active && $type === "secondary" ? "bg-gray-100 rounded-t-md" : ""}
  ${({ $type }: TabItemProps) =>
    $type === "secondary" ? "text-base" : "text-lg"}
`;

const TabSlider = tw.div`
  absolute w-full bg-blue-500 transition-all ease-in-out bottom-0 duration-300 h-1.25 rounded-b-sm
`;

export const Tabs: React.FC<TabsProps> = memo(
  ({
    tabs,
    narrow,
    actions,
    type = "primary",
    findIndex,
    className,
    onTabItemClick,
  }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [totalCount, setTotalCount] = useState(0);
    const findTabIndex = useCallback(
      () =>
        tabs
          .filter((t) => !t.excluded)
          .findIndex((tab) => {
            return findIndex
              ? findIndex(tab)
              : !!matchPath(
                  {
                    path: tab.skipCase ? `${tab.path || ""}/*` : tab.path || "",
                    end: tab.end,
                  },
                  location.pathname,
                );
          }),
      [findIndex, location.pathname, tabs],
    );

    const tabsRef = useRef([] as HTMLAnchorElement[]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(findTabIndex());
    const { width } = useWindowSize();
    useEffect(() => {
      const tabIndex = findTabIndex();
      setSelectedTabIndex(tabIndex);
    }, [width, findTabIndex, location]);

    useEffect(() => {
      const newCount = tabs
        .filter((t) => !t.excluded)
        .reduce((acc, tab) => acc + (tab.count || 0), 0);
      if (newCount !== totalCount) {
        setTotalCount(newCount);
      }
    }, [tabs, totalCount]);

    return (
      <TabsContainer $type={type} className={className}>
        <TabItems
          style={{
            gridTemplateColumns: `repeat(${
              tabs.filter((t) => !t.excluded).length
            }, auto) 1fr`,
          }}
        >
          {tabs
            .filter((t) => !t.excluded)
            .map((tab, index) => (
              <Tooltip
                key={index}
                id={`tab-item-${index}`}
                element={
                  <TabItem
                    ref={(el: HTMLAnchorElement) => {
                      tabsRef.current[index] = el as HTMLAnchorElement;
                    }}
                    $active={index === selectedTabIndex}
                    $disabled={tab.disabled}
                    $narrow={narrow}
                    onClick={() => {
                      if (tab.disabled) {
                        return;
                      }
                      if (tab.onClick) {
                        tab.onClick();
                      }
                      if (onTabItemClick) {
                        onTabItemClick(tab.path);
                      }
                      if (tab.path) {
                        setSelectedTabIndex(index);
                        navigate(tab.path);
                      }
                    }}
                    data-testid={`tab-item-${index}`}
                    $type={type}
                  >
                    {tab.label}
                    <If isTrue={tab.count}>
                      <Count>
                        <CountText>{tab.count}</CountText>
                      </Count>
                    </If>
                  </TabItem>
                }
              >
                {tab.disabled && tab.disabledTooltip
                  ? tab.disabledTooltip
                  : null}
              </Tooltip>
            ))}
          <If isTrue={type === "primary"}>
            <HorizontalLine />
          </If>
          {selectedTabIndex !== null && (
            <TabSlider
              style={{
                width: tabsRef.current[selectedTabIndex || 0]?.offsetWidth || 0,
                left: tabsRef.current[selectedTabIndex || 0]?.offsetLeft || 0,
              }}
            />
          )}
          <If isTrue={actions}>
            <ActionsContainer>{actions}</ActionsContainer>
          </If>
        </TabItems>
      </TabsContainer>
    );
  },
);

import { useRoles } from "@/common/components/org-roles-wrapper/hasRoles";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useReleaseSequence } from "@/contractor/pages/home/releases/pages/deliveries/providers/ReleaseSequenceProvider";
import {
  IntegrationType,
  OrgRole,
  ReleasePartialFieldsFragment,
  ReleaseStatus,
  SourceSystem,
} from "@/generated/graphql";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { isWarehouseRelease } from "../../../../utils/isWarehouseRelease";

export const useExportMultipleReleasePOButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [toJob, setToJob] = useState(true);
  const { sequenceIds, selectedReleases } = useReleaseSequence();
  const { connectedIntegrationType, connectedSourceSystem } = useOrgSettings();

  const intl = useIntl();
  const allOrdersAreExportable = useMemo(
    () =>
      selectedReleases.every(
        (release) =>
          (release as ReleasePartialFieldsFragment).useSourceSystemPO,
      ),
    [selectedReleases],
  );

  const someOrdersAreWarehouseOrders = useMemo(
    () =>
      selectedReleases.some((release) =>
        isWarehouseRelease(release as ReleasePartialFieldsFragment),
      ),
    [selectedReleases],
  );

  const disabledExport = useMemo(
    () =>
      sequenceIds.length === 0 ||
      selectedReleases.some((i) =>
        checkReleaseStatus(i, [
          ReleaseStatus.Canceled,
          ReleaseStatus.AwaitingApproval,
          ReleaseStatus.Rejected,
        ]),
      ) ||
      !allOrdersAreExportable ||
      someOrdersAreWarehouseOrders,
    [
      sequenceIds.length,
      selectedReleases,
      allOrdersAreExportable,
      someOrdersAreWarehouseOrders,
    ],
  );

  const disabledExportTooltip = useMemo(() => {
    if (disabledExport) {
      if (
        selectedReleases.some((i) =>
          checkReleaseStatus(i, [
            ReleaseStatus.Canceled,
            ReleaseStatus.Rejected,
            ReleaseStatus.AwaitingApproval,
          ]),
        )
      ) {
        return intl.$t({ id: "DISABLE_EXPORT_RELEASE_STATUSES" });
      }
      if (!allOrdersAreExportable) {
        return intl.$t({ id: "DISABLE_EXPORT_RELEASE_NOT_EXPORTABLE" });
      }
      if (someOrdersAreWarehouseOrders) {
        return intl.$t({ id: "DISABLE_EXPORT_WAREHOUSE_ORDERS" });
      }

      return undefined;
    }
  }, [
    disabledExport,
    selectedReleases,
    allOrdersAreExportable,
    someOrdersAreWarehouseOrders,
    intl,
  ]);

  const hasBothFoundationConnections = useMemo(
    () =>
      connectedSourceSystem === SourceSystem.Foundation &&
      connectedIntegrationType === IntegrationType.Foundation,
    [connectedIntegrationType, connectedSourceSystem],
  );
  const { hasRoles: isOrgAdmin } = useRoles({ roles: [OrgRole.OrgAdmin] });

  return {
    disabledExport,
    disabledExportTooltip,
    showDialog,
    setShowDialog,
    toJob,
    setToJob,
    hasBothFoundationConnections,
    isOrgAdmin,
  };
};

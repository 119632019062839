import { SpreadsheetSaveType } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { ViewToggle } from "../view-toggle/ViewToggle";

const DEFAULT_OPTIONS = [
  {
    label: <FormattedMessage id="DEFAULT_VIEW" />,
    icon: <ListAltIcon className="text-lg" />,
    viewState: TableViewState.normal,
  },
  {
    label: <FormattedMessage id="SPREADSHEET_VIEW" />,
    icon: <AssignmentOutlinedIcon className="text-lg" />,
    viewState: TableViewState.spreadsheet,
  },
];

type Props = {
  options?: Array<{
    label: string | JSX.Element;
    icon: JSX.Element;
    viewState: TableViewState;
  }>;
  saveState: (trigger?: SpreadsheetSaveType) => Promise<boolean>;
  saving: boolean;
  beforeSave?: () => boolean;
};

export const GridViewToggle: FC<Props> = ({
  options = DEFAULT_OPTIONS,
  saveState,
  saving,
  beforeSave,
}) => {
  const { viewState, setViewState } = useTableViewStore(
    useShallow((state) => ({
      viewState: state.viewState,
      setViewState: state.setViewState,
    })),
  );
  const [syncing, setSyncing] = useState(false);

  const changeView = async (value: TableViewState) => {
    if (saving || syncing) {
      return;
    }
    if (beforeSave && !beforeSave()) {
      return;
    }

    if (viewState === value) {
      return;
    }

    if (viewState === TableViewState.spreadsheet) {
      setSyncing(true);
      const result = await saveState?.(SpreadsheetSaveType.Toggle);
      setSyncing(false);
      if (result) {
        setViewState(value);
      }
    } else {
      setViewState(value);
    }
  };

  return (
    <ViewToggle
      loading={syncing}
      options={options}
      tableView={viewState}
      setView={changeView}
    />
  );
};

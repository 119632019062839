import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useIsWarehouseManager } from "@/common/components/warehouse-selector/hooks/useIsWarehouseManager";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { useReleaseNavigation } from "@/contractor/pages/home/releases/pages/deliveries/hooks/useReleaseNavigation";
import { useReleaseSequence } from "@/contractor/pages/home/releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { isRetroactiveRelease } from "@/contractor/pages/home/releases/pages/deliveries/utils/isRetroactiveRelease";
import { useAddDeliverySlipStore } from "@/contractor/pages/home/releases/pages/delivery-slips/store/useAddDeliverySlipStore";
import * as GraphQL from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../../providers/ReleaseActionsProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";
import { isWarehouseRelease } from "../../../utils/isWarehouseRelease";
import {
  ReleaseAlertType,
  useReleaseAlert,
} from "../hooks/release-alerts/useReleaseAlert";
import { useReleaseVendorActiveContacts } from "../hooks/useReleaseVendorActiveContacts";
import { useSyncReleaseItems } from "../hooks/useSyncReleaseItems";
import { SubmitReleaseCallbackProps } from "./SubmitReleaseCallbackProps";
import { SubmitReleaseButtonType } from "./types/SubmitReleaseButtonType";
import { UseSubmitReleaseButtonProps } from "./types/UseSubmitReleaseButtonProps";

export const useSubmitReleaseButton = ({
  release,
  items,
  setSubmitDialogVisible,
  setUpdateDialogVisible,
  overrideNavigationFn,
}: UseSubmitReleaseButtonProps) => {
  const { requestedDate, vendorId, setInputError } = useReleaseActions();
  const { setSuccessAlert, setWarningAlert } = useSnackbar();
  const { submitUpdate, changes } = useReleaseUpdate();
  const intl = useIntl();
  const { setAddSlipVisible, setSlipRelease } = useAddDeliverySlipStore();
  const { triggerAlert } = useReleaseAlert();
  const { viewer } = useUser();
  const { validateItems, getSyncedRelease, syncReleaseItems } =
    useSyncReleaseItems();
  const itemsRef = useRef(items);
  const { navigateToNextSequence } = useReleaseSequence();
  const { spreadsheetData } = useColumnMapper();
  const { vendorActiveContacts } = useReleaseVendorActiveContacts();
  const { isWarehouseManager } = useIsWarehouseManager();
  const { updateReleaseUpdateOptions, hasChanges, setHasChanges } =
    useReleaseStore(
      useShallow((state) => ({
        updateReleaseUpdateOptions: state.updateReleaseUpdateOptions,
        hasChanges: state.hasChanges,
        setHasChanges: state.setHasChanges,
      })),
    );
  const { canConfirmWarehouseRelease } = useIsWarehouseManager();
  const { navigateToRelease } = useReleaseNavigation();
  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  const checkBeforeSubmit = useCallback(async () => {
    if (!(await validateItems())) {
      return false;
    }
    if (
      spreadsheetData.every((data) => rowIsEmpty(data)) &&
      itemsRef.current.length === 0
    ) {
      setWarningAlert(intl.$t({ id: "NO_RELEASE_ITEMS" }));
      return false;
    }
    if (
      release?.status === GraphQL.ReleaseStatus.Draft
        ? false
        : !!changes?.vendorContactIds &&
          changes.vendorContactIds.length === 0 &&
          (
            release?.preferredVendor?.contacts?.filter(
              (contact) => contact.receivesOrderNotifications,
            ) ?? []
          ).length > 0
    ) {
      setInputError(ReleaseErrorType.VENDOR_CONTACT);
      setWarningAlert(intl.$t({ id: "INVALID_VENDOR_CONTACTS" }));
      return false;
    }
    if (
      itemsRef.current.length &&
      release.sellerOrgLocation &&
      !itemsRef.current.find(
        (item) =>
          !item.sellerOrgLocation ||
          item.sellerOrgLocation?.id === release.sellerOrgLocation?.id,
      )
    ) {
      setInputError(ReleaseErrorType.ALL_ITEMS_ASSIGNED_TO_OTHER_VENDORS);
      setWarningAlert(intl.$t({ id: "ALL_ITEMS_ASSIGNED_TO_OTHER_VENDORS" }));
      return false;
    }
    if (itemsRef.current.find((item) => !item.uom)) {
      setInputError(ReleaseErrorType.REQUESTED_UOM);
      setWarningAlert(
        intl.$t({ id: "DELIVERY_SUBMIT_WARNING_NO_REQUESTED_UOM" }),
      );
      return false;
    }
    if (
      !vendorId &&
      !release?.sellerOrgLocation?.id &&
      (isAuthorized(release.permissions.submitDirectly) ||
        release.status === GraphQL.ReleaseStatus.AwaitingApproval)
    ) {
      setInputError(ReleaseErrorType.SUPPLIER);
      setWarningAlert(intl.$t({ id: "DELIVERY_SUBMIT_WARNING_NO_VENDOR" }));
      return false;
    }
    return true;
  }, [
    validateItems,
    spreadsheetData,
    release.status,
    release?.preferredVendor?.contacts,
    release.sellerOrgLocation,
    release.permissions.submitDirectly,
    changes?.vendorContactIds,
    vendorId,
    setWarningAlert,
    intl,
    setInputError,
  ]);

  const navigateAfterSubmit = useCallback(
    (
      result: boolean | GraphQL.ReleaseReassignmentFieldsFragment[],
      reassignedReleases: GraphQL.ReleaseReassignmentFieldsFragment[] = [],
    ) => {
      if (overrideNavigationFn) {
        overrideNavigationFn();
        return;
      }
      if (result) {
        if (typeof result === "boolean" || reassignedReleases.length) {
          updateReleaseUpdateOptions({ requestedTime: null });
          setHasChanges(false);
          const index = reassignedReleases.findIndex(
            (r) => r.id === release?.id,
          );
          if (index !== -1 && index < reassignedReleases.length - 1) {
            navigateToNextSequence();
          } else {
            navigateToNextSequence({
              navigateToReleases: false,
              navigateToReleaseId: release?.id,
            });
          }
        } else {
          navigateToRelease(result[0].id);
        }
      }
    },
    [
      navigateToNextSequence,
      navigateToRelease,
      overrideNavigationFn,
      release?.id,
      setHasChanges,
      updateReleaseUpdateOptions,
    ],
  );

  return useMemo<SubmitReleaseButtonType | undefined>(() => {
    const canSubmit = isAuthorized(release.permissions.submit);
    const canSubmitDirectly = isAuthorized(release.permissions.submitDirectly);
    switch (release.status) {
      case GraphQL.ReleaseStatus.Canceled:
        if (canSubmitDirectly && !release.deletedAt) {
          return {
            label: "SUBMIT",
            canBypassVendorConfirmation: true,
            onClick: async () => {
              if ((await checkBeforeSubmit()) && setSubmitDialogVisible) {
                setSubmitDialogVisible(true);
              }
            },
            callback: async ({
              skip,
              callback,
              skipVendorNotification,
            }: SubmitReleaseCallbackProps) => {
              if (!checkBeforeSubmit()) {
                return;
              }
              const result = await submitUpdate({
                skipConfirmation: skip || false,
                skipVendorNotification: skipVendorNotification || false,
                operation: GraphQL.UpdateContractorReleaseOperation.Submit,
              });
              if (result) {
                callback?.(true, result.data, result.reassignedReleases);
              } else {
                callback?.(false);
              }
            },
          };
        }
        break;
      case GraphQL.ReleaseStatus.Draft:
      case GraphQL.ReleaseStatus.Reserved:
        const retroactiveRelease = isRetroactiveRelease(release.time);
        if (retroactiveRelease) {
          return {
            label: "SUBMIT",
            canBypassVendorConfirmation: true,
            callback: async ({
              receive,
              addDeliveryRecord,
              callback,
            }: SubmitReleaseCallbackProps) => {
              if ((requestedDate || release.time) && receive) {
                if (!(await checkBeforeSubmit())) {
                  return;
                }

                const result = await submitUpdate({
                  skipConfirmation: true,
                  skipVendorNotification: true,
                  retroactive: true,
                  operation: GraphQL.UpdateContractorReleaseOperation.Submit,
                });
                if (result?.data) {
                  callback?.(true);
                  setTimeout(() => {
                    if (addDeliveryRecord) {
                      setSlipRelease(release);
                      setAddSlipVisible(true);
                    } else {
                      triggerAlert(ReleaseAlertType.Submit, {
                        release: result.data,
                      });
                      navigateAfterSubmit(true);
                    }
                  }, DIALOG_AUTO_CLOSE_TIMER);
                } else {
                  callback?.(false);
                }
              } else {
                setSubmitDialogVisible?.(true);
              }
            },
            onClick: async () => {
              if ((await checkBeforeSubmit()) && setSubmitDialogVisible) {
                setSubmitDialogVisible(true);
              }
            },
          };
        } else if (canSubmitDirectly && !release.deletedAt) {
          return {
            label: "SUBMIT",
            canBypassVendorConfirmation: true,
            onClick: async () => {
              if ((await checkBeforeSubmit()) && setSubmitDialogVisible) {
                setSubmitDialogVisible(true);
              }
            },
            callback: async ({
              skip,
              callback,
              skipVendorNotification,
            }: SubmitReleaseCallbackProps) => {
              if (!checkBeforeSubmit()) {
                return;
              }

              await syncReleaseItems();
              const result = await submitUpdate({
                skipConfirmation:
                  skip ||
                  false ||
                  (!!isWarehouseManager && isWarehouseRelease(release)),
                skipVendorNotification: skipVendorNotification || false,
                operation: GraphQL.UpdateContractorReleaseOperation.Submit,
              });
              if (result?.data) {
                callback?.(true, result.data, result.reassignedReleases);
              } else {
                callback?.(false);
              }
            },
          };
        } else if (!release.deletedAt && canSubmit) {
          return {
            label: "SUBMIT_FOR_APPROVAL",
            canBypassVendorConfirmation: false,
            onClick: async (
              { skipConfirmation, skipVendorNotification } = {
                skipConfirmation: false,
                skipVendorNotification: false,
              },
            ) => {
              if (!checkBeforeSubmit()) {
                return;
              }
              if (!requestedDate && !release.timeTBD) {
                setInputError(ReleaseErrorType.REQUESTED_FULFILLMENT_DATE);
                setWarningAlert(
                  intl.$t({ id: "DELIVERY_SUBMIT_WARNING_NO_RELEASE_DATE" }),
                );
                return;
              }

              await syncReleaseItems();
              const result = await submitUpdate({
                skipConfirmation,
                skipVendorNotification,
                operation: GraphQL.UpdateContractorReleaseOperation.Submit,
              });
              if (result) {
                triggerAlert(ReleaseAlertType.Submit, {
                  release: result.data,
                });
                navigateAfterSubmit(
                  result.data?.reassignedReleases.length
                    ? result.data.reassignedReleases
                    : true,
                  result.reassignedReleases,
                );
              }
            },
          };
        }
        break;
      case GraphQL.ReleaseStatus.AwaitingApproval:
        if (release.approvalProgress.canApprove) {
          if (release.submittedBy?.id === viewer?.id) {
            return {
              label: "UPDATE_RELEASE",
              canBypassVendorConfirmation: false,
              onClick: async () => {
                const input = await getSyncedRelease();
                const result = await submitUpdate({
                  ...input,
                  skipConfirmation: true,
                  skipVendorNotification: true,
                });
                if (result) {
                  setSuccessAlert(intl.$t({ id: "UPDATED_RELEASE" }));
                  navigateToRelease(release.id, { edit: false });
                }
              },
            };
          } else if (
            !release.approvals.find((a) => a.approver.id === viewer?.id)
          ) {
            return {
              label: release.approvalProgress.canApproveImmediately
                ? isWarehouseRelease(release)
                  ? isWarehouseManager
                    ? "APPROVE_AND_CONFIRM"
                    : "APPROVE_ORDER"
                  : "APPROVE_AND_SUBMIT"
                : "APPROVE_ORDER",
              canBypassVendorConfirmation: true,
              callback: async ({
                skip,
                callback,
                skipVendorNotification,
                schedule,
              }: SubmitReleaseCallbackProps) => {
                const input = await getSyncedRelease();
                const result = await submitUpdate({
                  ...input,
                  skipConfirmation: schedule || skip,
                  skipVendorNotification,
                  operation: GraphQL.UpdateContractorReleaseOperation.Approve,
                });
                callback?.(
                  !!result.data,
                  result.data,
                  result.reassignedReleases,
                );
              },
              onClick: async (opt) => {
                if (await checkBeforeSubmit()) {
                  await syncReleaseItems({
                    updateProps: { skipConfirmation: opt?.skipConfirmation },
                  });
                  if (
                    setSubmitDialogVisible &&
                    release.approvalProgress.canApproveImmediately
                  ) {
                    setSubmitDialogVisible(true);
                  } else {
                    const result = await submitUpdate({
                      skipConfirmation: true,
                      operation:
                        GraphQL.UpdateContractorReleaseOperation.Approve,
                    });
                    if (result) {
                      navigateAfterSubmit(
                        result.data?.reassignedReleases.length
                          ? result.data.reassignedReleases
                          : true,
                        result.reassignedReleases,
                      );
                    }
                  }
                }
              },
            };
          }
        }
        break;
      case GraphQL.ReleaseStatus.Rejected:
        if (canSubmit) {
          return {
            label: "RESUBMIT_FOR_APPROVAL",
            canBypassVendorConfirmation: false,
            onClick: async () => {
              if (await checkBeforeSubmit()) {
                const result = await submitUpdate({
                  skipConfirmation: false,
                  skipVendorNotification: false,
                  operation: GraphQL.UpdateContractorReleaseOperation.Submit,
                });
                if (result) {
                  setSuccessAlert(intl.$t({ id: "SUBMITTED_NEW_RELEASE" }));
                  navigateAfterSubmit(
                    result.data?.reassignedReleases.length
                      ? result.data.reassignedReleases
                      : true,
                    result.reassignedReleases,
                  );
                }
              }
            },
          };
        }
        if (canSubmitDirectly && !release.deletedAt) {
          return {
            label: "SUBMIT",
            canBypassVendorConfirmation: false,
            onClick: async () => {
              if ((await checkBeforeSubmit()) && setSubmitDialogVisible) {
                setSubmitDialogVisible(true);
              }
            },
            callback: async ({
              skip,
              callback,
              skipVendorNotification,
            }: SubmitReleaseCallbackProps) => {
              const result = await submitUpdate({
                skipConfirmation: skip || false,
                skipVendorNotification: skipVendorNotification || false,
                operation: GraphQL.UpdateContractorReleaseOperation.Submit,
              });
              if (result?.data) {
                callback?.(true, result.data, result.reassignedReleases);
              } else {
                callback?.(false);
              }
            },
          };
        }
        break;
      case GraphQL.ReleaseStatus.Scheduled:
      case GraphQL.ReleaseStatus.Requested:
        const canConfirmRelease = canConfirmWarehouseRelease(release);
        if (
          isAuthorized(release.permissions.edit) ||
          (canConfirmRelease &&
            release.permissions.edit ===
              GraphQL.AuthorizationStatus.SelfAssignable)
        ) {
          return {
            label:
              canConfirmRelease &&
              release.status === GraphQL.ReleaseStatus.Requested
                ? "CONFIRM_ORDER"
                : "UPDATE_RELEASE",
            canBypassVendorConfirmation:
              release.status !== GraphQL.ReleaseStatus.Requested,
            onClick: async () => {
              if ((await checkBeforeSubmit()) && setUpdateDialogVisible) {
                setUpdateDialogVisible(true);
              }
            },
            callback: async (
              { skip, callback, skipVendorNotification, schedule } = {
                skip: true,
                skipVendorNotification: false,
                schedule: false,
              },
            ) => {
              if (!checkBeforeSubmit()) {
                return;
              }
              const input = await getSyncedRelease();
              const result = await submitUpdate({
                ...input,
                skipConfirmation: vendorActiveContacts.length > 0 ? skip : true,
                skipVendorNotification:
                  vendorActiveContacts.length > 0
                    ? skipVendorNotification
                    : true,
                ...(schedule
                  ? {
                      operation:
                        GraphQL.UpdateContractorReleaseOperation.Schedule,
                    }
                  : {}),
              });
              if (result) {
                if (result.reassignedReleases.length) {
                  navigateAfterSubmit(
                    result.data?.reassignedReleases.length
                      ? result.data.reassignedReleases
                      : true,
                    result.reassignedReleases,
                  );
                  setTimeout(() => {
                    setUpdateDialogVisible?.(false);
                  });
                } else {
                  callback?.(!!result.data);
                }
              }
            },
          };
        }
        break;
      case GraphQL.ReleaseStatus.Received:
      case GraphQL.ReleaseStatus.PartiallyReceived:
        if (isAuthorized(release.permissions.edit)) {
          return {
            label: "UPDATE_RELEASE",
            canBypassVendorConfirmation: false,
            onClick: async () => {
              if (
                (await checkBeforeSubmit()) &&
                setUpdateDialogVisible &&
                hasChanges
              ) {
                setUpdateDialogVisible(true);
              }
            },
            callback: async (
              { skipVendorNotification } = {
                skipVendorNotification: false,
              },
            ) => {
              const input = await getSyncedRelease();
              const result = await submitUpdate({
                ...input,
                skipConfirmation: true,
                skipVendorNotification,
              });
              if (result) {
                setUpdateDialogVisible?.(false);
                setSuccessAlert(intl.$t({ id: "UPDATED_RELEASE" }));
                navigateAfterSubmit(true);
              }
            },
          };
        }
        break;
    }
  }, [
    release,
    canConfirmWarehouseRelease,
    checkBeforeSubmit,
    setSubmitDialogVisible,
    submitUpdate,
    requestedDate,
    setSlipRelease,
    setAddSlipVisible,
    setSuccessAlert,
    intl,
    navigateAfterSubmit,
    syncReleaseItems,
    setInputError,
    setWarningAlert,
    triggerAlert,
    viewer?.id,
    getSyncedRelease,
    navigateToRelease,
    isWarehouseManager,
    hasChanges,
    setUpdateDialogVisible,
    vendorActiveContacts.length,
  ]);
};

import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUser } from "@/common/providers/UserProvider";
import { UomFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const PricePickerStyled = tw(PricePicker)`
${({ readonly }: { readonly: boolean }) =>
  readonly ? "bg-transparent" : "bg-white"}
`;

type Props = {
  unitPrice: string | undefined | null;
  uom?: UomFieldsFragment | null;
  onChange: (value: string | null) => void;
  readonly: boolean;
  index?: number;
  error?: boolean;
  hideErrorIcon?: boolean;
  label?: string;
};

export const PricePickerWithUom: FC<Props> = ({
  unitPrice,
  readonly,
  index,
  error,
  onChange,
  hideErrorIcon,
  uom,
  label,
}) => {
  const hasError = useMemo(() => unitPrice === null, [unitPrice]);
  const { currencySymbol } = useUser();

  return (
    <PricePickerStyled
      value={unitPrice}
      label={
        <Tooltip
          id={`uom-description-${index}`}
          element={
            label ?? (
              <FormattedMessage
                id="PRICE_PER_UOM"
                values={{ uom: uom?.mnemonic, currency: currencySymbol }}
              />
            )
          }
        >
          {uom?.singularDescription ||
            (uom?.pluralDescription && (
              <FormattedMessage
                id="PER_UOM"
                values={{
                  uom: uom?.singularDescription || uom?.pluralDescription,
                  currency: currencySymbol,
                }}
              />
            ))}
        </Tooltip>
      }
      onBlur={onChange}
      error={hasError && error}
      readonly={readonly}
      index={index}
      hideErrorIcon={hideErrorIcon}
    />
  );
};

import Handsontable from "handsontable";
import { COLUMN_TYPE } from "../enums/columnType";
import { useSpreadSheetStore } from "../store/useSpreadSheetStore";
import { getPhysicalColumnIndex } from "./getPhysicalColumnIndex";

export const setPrefillCell = (
  hotInstance: Handsontable | null | undefined,
  row: number,
  column: COLUMN_TYPE,
  value: string | number | null,
): void => {
  if (!hotInstance) {
    return;
  }
  const physicalColumn = getPhysicalColumnIndex(hotInstance, column);
  useSpreadSheetStore
    .getState()
    .applyPrefillChanges([[row, physicalColumn, value]]);
};

import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { LinkPoInput, PoFormat } from "@/generated/graphql";
import { useCallback } from "react";
import { useDefaultReleasePo } from "../../../hooks/useDefaultReleasePo";
import { useReleaseConnectionOptions } from "../../../providers/ReleaseConnectionOptionsProvider";
import { useReleasesByIds } from "../../../providers/ReleasesByIdsProvider";

export const useBackgroundReleases = () => {
  const { releasesByIds } = useReleasesByIds();
  const { connectedSourceSystem } = useOrgSettings();
  const { getDefaultReleasePo } = useDefaultReleasePo();
  const { updatedReleases, autoSync } = useReleaseConnectionOptions();

  const generateBackgroundPoLinkInputs = useCallback(() => {
    if (!connectedSourceSystem) {
      return [];
    }
    const inputs: LinkPoInput[] = releasesByIds.map((r) => ({
      releaseId: r.id,
      format: r.type.poFormat || PoFormat.Basic,
      sourceSystem: connectedSourceSystem,
      autoSync,
      poNumber:
        updatedReleases.find(
          (updatedRelease) => updatedRelease.releaseId === r?.id,
        )?.poNumber ||
        r.poNumber ||
        getDefaultReleasePo(r) ||
        "",
      async: true,
    }));

    return inputs;
  }, [
    autoSync,
    connectedSourceSystem,
    getDefaultReleasePo,
    releasesByIds,
    updatedReleases,
  ]);

  return { generateBackgroundPoLinkInputs };
};

import { GroupFilter } from "@/common/components/groups/components/group-filter/GroupFilter";
import { InvoiceType } from "@/generated/graphql";
import { FC } from "react";
import { useInvoiceFolders } from "./hooks/useInvoiceFolders";

type Props = {
  value: string[] | null | undefined;
  onChange: (folderId: string[] | undefined) => void;
};

export const InvoiceFolderFilter: FC<Props> = (props: Props) => {
  const { folders, createFolder, updateFolder, deleteFolder } =
    useInvoiceFolders(InvoiceType.Invoice);

  return (
    <GroupFilter
      createGroup={createFolder}
      folders={folders}
      translationKeys={{
        addNew: "ADD_NEW_INVOICE_FOLDER",
        groupName: "INVOICE_GROUP_NAME",
        all: "ALL_INVOICE_GROUPS",
        unassigned: "UNASSIGNED_INVOICE_GROUPS",
        archiveTitle: "DELETE_INVOICE_GROUP_WITH_NAME",
        archiveContent: "DELETE_INVOICE_GROUP_CONFIRMATION",
      }}
      updateGroup={updateFolder}
      deleteGroup={deleteFolder}
      {...props}
    />
  );
};

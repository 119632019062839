import { MAX_VENDOR_NUMBER } from "@/common/const";
import { FC, useCallback, useMemo } from "react";
import { GroupRenderer } from "../select/components/renderer/GroupRenderer";
import { SelectCustomItemView } from "../select/components/single/components/SelectCustomItemView";
import { Select } from "../select/components/single/Select";
import { SupplierPickerCustomRender } from "../supplier-picker/components/renderers/SupplierPickerCustomRender";
import { ALL_SUPPLIERS_ID } from "../supplier-picker/const/allSuppliersId";
import { SupplierType } from "../supplier-picker/types/Supplier";
import { useSupplierOptions } from "../supplier-picker/useSupplierOptions";

type Props = {
  onChange: (newValue: string | null, type?: SupplierType) => void;
  value?: string | null;
  withCustomView?: boolean;
  clear?: () => void;
  includeAllOption?: boolean;
  placeholder?: string;
  includeWarehouses?: boolean;
};

export const VendorsFilterSelector: FC<Props> = ({
  onChange,
  value,
  withCustomView = false,
  clear,
  includeAllOption = true,
  includeWarehouses = true,
  placeholder,
}) => {
  const { supplierOptions, loading, shouldShowVendorCode } = useSupplierOptions(
    {
      includeAllOption,
      includeWarehouses,
    },
  );

  const selectedOption = useMemo(() => {
    return value === ALL_SUPPLIERS_ID
      ? undefined
      : supplierOptions.find((vendor) => vendor.value === value);
  }, [supplierOptions, value]);

  const onChangeHandler = useCallback(
    (newValue: string | null) => {
      const type = supplierOptions.find(
        (option) => option.value === newValue,
      )?.type;
      onChange(newValue === ALL_SUPPLIERS_ID ? null : newValue, type);
    },
    [onChange, supplierOptions],
  );

  return (
    <Select
      options={supplierOptions || []}
      value={value || ALL_SUPPLIERS_ID}
      onChange={onChangeHandler}
      customRender={(item) =>
        SupplierPickerCustomRender(item, { shouldShowVendorCode })
      }
      groupBy={(option) => option.type}
      getLabel={(option) => option.name}
      getValue={(option) => option.value as string}
      inputProps={{
        className: `${!value && "text-gray-600"} px-2 py-[2.5px]`,
      }}
      selectedOptionCustomView={
        withCustomView && selectedOption ? (
          <SelectCustomItemView text={selectedOption.name} clear={clear} />
        ) : null
      }
      loading={loading}
      placeholder={placeholder}
      limitResults={MAX_VENDOR_NUMBER}
      renderGroup={GroupRenderer}
      className="flex-1"
    />
  );
};

import { DisplaySettings } from "@/generated/graphql";
import { useCallback } from "react";

export const useManufacturersSettings = () => {
  const getVendorHasManufacturerSetting = useCallback(
    (
      displaySettings:
        | Pick<DisplaySettings, "enableManufacturers">
        | undefined
        | null,
    ) => {
      return displaySettings?.enableManufacturers ?? false;
    },
    [],
  );

  return {
    getVendorHasManufacturerSetting,
  };
};

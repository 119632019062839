import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { INVOICE_READONLY_STATUSES } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  InvoiceStatus,
  ReleaseSelectorFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { MatchedOrderViewState } from "../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";
import { useLazyLoadExternalPo } from "./useLazyLoadExternalPo";

export const useInvoiceViewAutoSelection = () => {
  const { invoice, setFooterState } = useInvoiceVerification();
  const { fetchExternalPo } = useLazyLoadExternalPo();
  const { setSystemAlert } = useSnackbar();
  const { connectedSourceSystem } = useOrgSettings();
  const intl = useIntl();
  const { setMatchedOrderViewState, setAutoMatching } =
    useInvoiceMatchedOrder();

  const autoSelectView = useCallback(
    (releases: ReleaseSelectorFieldsFragment[] = []) => {
      setAutoMatching(true);
      if (invoice?.headerOnly) {
        setMatchedOrderViewState(
          invoice.status === InvoiceStatus.Approved
            ? MatchedOrderViewState.HEADER_ONLY_INVOICE
            : MatchedOrderViewState.HEADER_ONLY_INVOICE_EDITABLE,
        );
        setFooterState(InvoiceFooterState.HEADER_ONLY_INVOICE);
        setAutoMatching(false);
      } else if (invoice?.release) {
        if (
          invoice.release.status === ReleaseStatus.Reserved &&
          !invoice.release.itemCount
        ) {
          setMatchedOrderViewState(MatchedOrderViewState.EDIT_ORDER);
          setFooterState(InvoiceFooterState.EDIT_ORDER);
        } else if (!INVOICE_READONLY_STATUSES.includes(invoice.status)) {
          setMatchedOrderViewState(
            MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
          );
          setFooterState(InvoiceFooterState.EDIT_INVOICE_COVERAGES);
        } else {
          if (invoice.autoMatched) {
            setSystemAlert([
              `${intl.$t(
                { id: "INVOICE_AUTOLINK_INFO" },
                { releaseNumber: invoice.release?.sequenceNumber },
              )}${invoice.release?.itemCount ? "" : ` ${intl.$t({ id: "INVOICE_AUTOLINK_BLANK_RELEASE" })}`}`,
              intl.$t({ id: "INVOICE_UNLINK_MESSAGE" }),
            ]);
          }
          setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
          setFooterState(InvoiceFooterState.DEFAULT);
        }
        setAutoMatching(false);
      } else if (releases.length > 0 && invoice?.poNumber) {
        setSystemAlert(
          intl.$t(
            { id: "INVOICE_MATCHED_MULTIPLE_ORDERS" },
            {
              integration: intl.$t({
                id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
              }),
              count: releases.length,
            },
          ),
        );

        setMatchedOrderViewState(MatchedOrderViewState.MATCH_ORDER);
        setFooterState(InvoiceFooterState.MATCH_ORDER);
        setAutoMatching(false);
      } else if (invoice?.poExists) {
        fetchExternalPo();
      } else {
        setSystemAlert(
          intl.$t(
            {
              id: connectedSourceSystem
                ? "INVOICE_MATCHED_NO_ORDER"
                : "INVOICE_MATCHED_NO_ORDER_NO_CONNECTION",
            },
            {
              integration: connectedSourceSystem
                ? intl.$t({
                    id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                  })
                : null,
            },
          ),
        );
        setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
        setFooterState(InvoiceFooterState.DEFAULT);
        setAutoMatching(false);
      }
    },
    [
      setAutoMatching,
      invoice?.headerOnly,
      invoice?.release,
      invoice?.poNumber,
      invoice?.poExists,
      invoice?.status,
      invoice?.autoMatched,
      setMatchedOrderViewState,
      setFooterState,
      setSystemAlert,
      intl,
      connectedSourceSystem,
      fetchExternalPo,
    ],
  );

  return { autoSelectView };
};

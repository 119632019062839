import {
  SourceSystem,
  useRefreshExternalVendorsMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useAgaveExternalVendors } from "./useAgaveExternalVendors";
import { useAgaveExternalVendorsWithPagination } from "./useAgaveExternalVendorsWithPagination";

export const useRefreshExternalVendors = (
  sourceSystem: SourceSystem | null,
) => {
  const { filter } = useAgaveExternalVendors(sourceSystem || null, {
    skip: true,
  });
  const { refetch: refetchExternalVendors } =
    useAgaveExternalVendorsWithPagination(sourceSystem, filter, {
      skip: true,
    });
  const [
    refreshExternalVendorsMutation,
    { loading: refreshingExternalVendors },
  ] = useRefreshExternalVendorsMutation();

  const handleRefreshExternalVendors = useCallback(async () => {
    if (sourceSystem) {
      const result = await refreshExternalVendorsMutation({
        variables: { sourceSystem },
      });
      if (
        result.data?.refreshExternalVendors &&
        result.data?.refreshExternalVendors > 0
      ) {
        await refetchExternalVendors();
      }
    }
  }, [refetchExternalVendors, refreshExternalVendorsMutation, sourceSystem]);

  return {
    handleRefreshExternalVendors,
    refreshingExternalVendors,
  };
};

import { DistributorQuotesAuxiliaryQuoteItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { PricePickerWithUom } from "../../../../../common/components/price-picker/PricePickerWithUom";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

type Props = {
  item: DistributorQuotesAuxiliaryQuoteItemFieldsFragment;
  readonly: boolean;
  index?: number;
  error?: boolean;
};

export const DistributorQuoteAuxiliaryUnitPriceInput: FC<Props> = ({
  item,
  ...props
}) => {
  const { updateQuote } = useDistributorQuote();

  return (
    <PricePickerWithUom
      onChange={async (unitPrice: string | null) => {
        await updateQuote({
          updatedAuxiliaryItems: [
            {
              auxiliaryItemId: item.id,
              unitPrice: unitPrice === null ? undefined : unitPrice,
              clearUnitPrice: unitPrice === null,
            },
          ],
        });
      }}
      unitPrice={item.unitPrice}
      uom={item.uom}
      {...props}
    />
  );
};

import { UpdateContractorReleaseItemInput } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useReleaseStore } from "../store/useReleaseStore";
import { ExpandedReleaseItem, useRelease } from "./ReleaseProvider";

export enum ReleaseErrorType {
  "REQUESTED_FULFILLMENT_DATE",
  "REQUESTED_UOM",
  "ALL_ITEMS_ASSIGNED_TO_OTHER_VENDORS",
  "SUPPLIER",
  "VENDOR_CONTACT",
  "SPREADSHEET_ERROR",
}

type ProviderContextType = {
  vendorId: string;
  setVendorId: (id: string) => void;
  po: string;
  setPo: (po: string) => void;
  requestedDate: Date | null;
  setRequestedDate: (date: Date | null) => void;
  siteContactName: string;
  setSiteContactName: (name: string) => void;
  contactCellPhone: string;
  setContactCellPhone: (phone: string) => void;
  inputErrors: ReleaseErrorType[];
  setInputError: (fieldName: ReleaseErrorType) => void;
  newCostCodes: UpdateContractorReleaseItemInput[];
  setNewCostCodes: (input: UpdateContractorReleaseItemInput[]) => void;
  assignCostCodeOptions: {
    isEditable: boolean;
    categoryItems: ExpandedReleaseItem[];
  };
  setAssignCostCodeOptions: (options: {
    isEditable: boolean;
    categoryItems: ExpandedReleaseItem[];
  }) => void;
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  vendorId: "",
  setVendorId: NoFunction,
  po: "",
  setPo: NoFunction,
  requestedDate: null,
  setRequestedDate: NoFunction,
  siteContactName: "",
  setSiteContactName: NoFunction,
  contactCellPhone: "",
  setContactCellPhone: NoFunction,
  inputErrors: [],
  setInputError: NoFunction,
  newCostCodes: [],
  setNewCostCodes: NoFunction,
  assignCostCodeOptions: { isEditable: false, categoryItems: [] },
  setAssignCostCodeOptions: NoFunction,
  expandedItems: [],
  setExpandedItems: NoFunction,
});

export const ReleaseActionsProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { release } = useRelease();
  const [po, setPo] = useState<string>(release?.poNumber ?? "");
  const [vendorId, setVendorId] = useState<string>("");
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const [requestedDate, setRequestedDate] = useState<Date | null>(
    release?.time ? new Date(release.time) : null,
  );
  const [siteContactName, setSiteContactName] = useState<string>(
    release?.siteContact?.name ?? "",
  );
  const [contactCellPhone, setContactCellPhone] = useState<string>(
    release?.siteContact?.phone ?? "",
  );
  const { updateStoreReleaseVersion } = useReleaseStore();

  useEffect(() => {
    if (release) {
      updateStoreReleaseVersion(release);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release?.version]);

  useEffect(() => {
    setPo(release?.poNumber ?? "");
  }, [release?.poNumber]);

  const [inputErrors, setInputErrors] = useState<ReleaseErrorType[]>([]);
  const [newCostCodes, setNewCostCodes] = useState<
    UpdateContractorReleaseItemInput[]
  >([]);
  const [assignCostCodeOptions, setAssignCostCodeOptions] = useState<{
    isEditable: boolean;
    categoryItems: ExpandedReleaseItem[];
  }>({ isEditable: false, categoryItems: [] });

  useEffect(() => {
    setPo(release?.poNumber ?? "");
    setRequestedDate(release?.time ? new Date(release.time) : null);
    setSiteContactName(release?.siteContact?.name ?? "");
    setContactCellPhone(release?.siteContact?.phone ?? "");
    setVendorId(
      release?.sellerOrgLocation?.id ?? release?.sourceWarehouse?.id ?? "",
    );
  }, [release]);

  useEffect(() => {
    setInputErrors([]);
  }, [requestedDate]);

  const setInputError = (fieldName: ReleaseErrorType) => {
    setInputErrors((errors) =>
      errors.includes(fieldName) ? errors : [fieldName, ...errors],
    );
  };

  return (
    <ProviderContext.Provider
      value={{
        vendorId,
        setVendorId,
        po,
        setPo,
        requestedDate,
        setRequestedDate,
        siteContactName,
        setSiteContactName,
        contactCellPhone,
        setContactCellPhone,
        inputErrors,
        setInputError,
        newCostCodes,
        setNewCostCodes,
        assignCostCodeOptions,
        setAssignCostCodeOptions,
        expandedItems,
        setExpandedItems,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseActions = (): ProviderContextType =>
  useContext(ProviderContext);

import { useExternalPOUtils } from "@/common/components/import-external-po/hooks/useExternalPOUtils";
import { useImportExternalPos } from "@/common/components/import-external-po/hooks/useImportExternalPos";
import { ExternalPoImportVariables } from "@/common/components/import-external-po/types/ExternalPoImportVariables";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useOrderTypes } from "@/contractor/pages/admin/orders/pages/order-types/hooks/useOrderTypes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  ExternalPoFieldsFragment,
  ExternalPoImportPossibility,
  TaxMode,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useExternalPoItemsValidation } from "../../../../../../../common/components/import-external-po/import-external-po-wizard/importable-external-po-items/hooks/useExternalPoItemsValidation";
import { MatchedOrderViewState } from "../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useInvoiceImportExternalPO = () => {
  const { setFooterState, invoice } = useInvoiceVerification();
  const { showModal, variables, getPages, ...props } = useImportExternalPos();
  const {
    setImportedItems,
    setSelectedBaseExternalPO,
    selectedBaseExternalPO,
    openModal,
    closeModal,
    setVariables,
    reset,
  } = props;
  const { getImportableItems } = useExternalPOUtils();
  const { setMatchedOrderViewState, setAutoMatching } =
    useInvoiceMatchedOrder();
  const { orderTypes } = useOrderTypes();
  const { connectedSourceSystem } = useOrgSettings();
  const { checkIfPOIsItemized } = useExternalPOUtils();
  const { validate } = useExternalPoItemsValidation({
    release: variables.release,
  });
  const intl = useIntl();
  const { setSystemAlert } = useSnackbar();

  useEffect(() => {
    if (invoice?.release) {
      setVariables({
        release: invoice.release,
      });
    }
  }, [invoice?.release, setVariables]);

  const importExternalPO = useCallback(
    (externalPO: ExternalPoFieldsFragment | null | undefined) => {
      if (externalPO) {
        const isItemizedPO = checkIfPOIsItemized(externalPO);
        setSelectedBaseExternalPO(externalPO);
        const variables: Partial<ExternalPoImportVariables> = {
          importedDescription: externalPO.name || externalPO.description,
          importedTaxAmount:
            orderTypes.find(
              (orderType) => orderType.id === externalPO?.releaseType?.id,
            )?.taxMode !== TaxMode.Column
              ? externalPO.itemGroups.taxAmount
              : "",
          importedTaxType: externalPO.itemGroups.taxType,
          importedTaxCodeId: externalPO.itemGroups.taxCodeId,
          ...(externalPO.itemGroups.importPossibility ===
            ExternalPoImportPossibility.Immutable && {
            importedPoIsImmutable: true,
          }),
          importedPoItemized: isItemizedPO,
          importedSellerOrgLocationId:
            externalPO?.vendor?.orgPreferredVendors?.[0]?.sellerOrgLocation.id,
          importedLocationId: externalPO.project?.project?.location?.id,
          importedProjectId: externalPO.project?.project?.id,
          importedOrderTypeId: externalPO.releaseType?.id,
          importedPoExternalId: externalPO.externalId,
          importedPoNumber: externalPO.number,
          importedPoDate: externalPO.date > 0 ? externalPO.date : null,
          importedAdditionalCharges:
            externalPO.itemGroups.additionalCharges.map((i) => ({
              amount: i.amount,
              description: i.description,
              id: i.externalId,
            })),
          importedCustomTaxAmount: externalPO.itemGroups?.salesTax.length
            ? externalPO.itemGroups.salesTax
                ?.reduce(
                  (acc, next) =>
                    new DecimalSafe(acc)
                      .add(new DecimalSafe(next.amount))
                      .toNumber(),
                  0,
                )
                .toString()
            : undefined,
        };
        setVariables(variables);
        setImportedItems(getImportableItems(externalPO));
        if (!validate(selectedBaseExternalPO ?? externalPO, externalPO)) {
          setFooterState(InvoiceFooterState.IMPORT_ORDER);
          setMatchedOrderViewState(MatchedOrderViewState.IMPORT_ORDER);
          setSystemAlert(
            intl.$t(
              { id: "INVOICE_MATCHED_TO_EXTERNAL_PO" },
              {
                poNumber: invoice?.poNumber,
                integration: intl.$t({
                  id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                }),
              },
            ),
          );
          closeModal();
        } else {
          setFooterState(InvoiceFooterState.DEFAULT);
          setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
          openModal({
            skipPoList: true,
          });
        }
        setAutoMatching(false);
        reset(externalPO.number);
      }
      setFooterState(InvoiceFooterState.IMPORT_ORDER);
      setMatchedOrderViewState(MatchedOrderViewState.IMPORT_ORDER);
    },
    [
      setFooterState,
      setMatchedOrderViewState,
      checkIfPOIsItemized,
      setSelectedBaseExternalPO,
      orderTypes,
      setVariables,
      setImportedItems,
      getImportableItems,
      validate,
      selectedBaseExternalPO,
      setAutoMatching,
      reset,
      setSystemAlert,
      intl,
      invoice?.poNumber,
      connectedSourceSystem,
      closeModal,
      openModal,
    ],
  );

  const pages = useMemo(
    () => getPages({ importSelectedExternalPO: importExternalPO }),
    [getPages, importExternalPO],
  );

  return {
    ...variables,
    ...props,
    importModalOpened: showModal,
    importExternalPO,
    pages,
  };
};

import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { PageBackButton } from "@/common/components/page-back-button/PageBackButton";
import { UpdateReleaseDialog } from "@/contractor/pages/home/release/pages/specify-details/footer-actions/UpdateReleaseDialog";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { DeliverySlipStatus, ReleaseStatus } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlipRelease } from "../../../../providers/DeliverySlipReleaseProvider";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipReceiveDialog } from "./DeliverySlipReceiveDialog";
import { useDeliverySlipReceiveFooter } from "./useDeliverySlipReceiveFooter";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;

type Props = {
  disabled?: boolean;
};

export const DeliverySlipReceiveFooter: FC<Props> = ({ disabled = false }) => {
  const { release } = useRelease();
  const {
    deliverySlip,
    scanningSlip,
    packingSlipReceiveViewState,
    setPackingSlipReceiveViewState,
  } = useDeliverySlipVerification();
  const { updating } = useDeliverySlipRelease();

  const {
    saveProgress,
    handleConfirm,
    close,
    archiveSlip,
    onSaveClick,
    updateDialogVisible,
    setUpdateDialogVisible,
    saveRelease,
    confirming,
    confirm,
    receiveDialogIsVisible,
    setReceiveDialogIsVisible,
  } = useDeliverySlipReceiveFooter();

  return (
    <FloatingFooterStyled>
      <ButtonContainer>
        <If isTrue={!scanningSlip}>
          <If
            isTrue={
              deliverySlip?.status === DeliverySlipStatus.Processed &&
              packingSlipReceiveViewState ===
                PackingSlipReceiveViewState.EDIT_COVERAGE
            }
          >
            <OutlinedButton
              disabled={disabled}
              className="py-0"
              onClick={() =>
                setPackingSlipReceiveViewState(
                  PackingSlipReceiveViewState.DEFAULT,
                )
              }
            >
              <FormattedMessage id="BACK" />
            </OutlinedButton>
          </If>
          <If
            isTrue={
              deliverySlip?.status !== DeliverySlipStatus.Processed ||
              packingSlipReceiveViewState ===
                PackingSlipReceiveViewState.DEFAULT
            }
          >
            <PageBackButton />
          </If>
          <If
            isTrue={
              deliverySlip?.status !== DeliverySlipStatus.Processed ||
              (packingSlipReceiveViewState !==
                PackingSlipReceiveViewState.EDIT_COVERAGE &&
                packingSlipReceiveViewState !==
                  PackingSlipReceiveViewState.EDIT_ORDER)
            }
          >
            <OutlinedButton onClick={archiveSlip} disabled={disabled}>
              <FormattedMessage id="DELETE" />
            </OutlinedButton>
          </If>
          <If
            isTrue={
              packingSlipReceiveViewState ===
              PackingSlipReceiveViewState.EDIT_COVERAGE
            }
          >
            <If isTrue={deliverySlip?.status !== DeliverySlipStatus.Processed}>
              <LoadingButton
                button={OutlinedButton}
                disabled={disabled}
                className="py-0"
                loading={confirming}
                onClick={saveProgress}
              >
                <FormattedMessage id="SAVE_CHANGES" />
              </LoadingButton>
            </If>
            <PrimaryButton
              disabled={disabled}
              className="py-0"
              loading={confirming}
              onClick={handleConfirm}
            >
              <FormattedMessage
                id={
                  deliverySlip?.status === DeliverySlipStatus.Processed
                    ? "SAVE_CHANGES"
                    : "RECEIVE_ORDER"
                }
              />
            </PrimaryButton>
          </If>
          <If
            isTrue={
              packingSlipReceiveViewState !==
                PackingSlipReceiveViewState.EDIT_COVERAGE &&
              packingSlipReceiveViewState !==
                PackingSlipReceiveViewState.EDIT_ORDER
            }
          >
            <OutlinedButton
              disabled={disabled}
              className="py-0"
              onClick={close}
            >
              <FormattedMessage id="CLOSE" />
            </OutlinedButton>
          </If>
          <If
            isTrue={
              packingSlipReceiveViewState ===
              PackingSlipReceiveViewState.EDIT_ORDER
            }
          >
            <OutlinedButton
              onClick={() => {
                setPackingSlipReceiveViewState(
                  deliverySlip?.status === DeliverySlipStatus.Processed
                    ? PackingSlipReceiveViewState.DEFAULT
                    : PackingSlipReceiveViewState.EDIT_COVERAGE,
                );
              }}
            >
              <FormattedMessage id="CANCEL_CHANGES" />
            </OutlinedButton>
            <PrimaryButton onClick={onSaveClick} loading={updating}>
              <FormattedMessage id="SAVE_CHANGES" />
            </PrimaryButton>
          </If>
        </If>
      </ButtonContainer>
      <DeliverySlipReceiveDialog
        visible={receiveDialogIsVisible}
        setVisible={setReceiveDialogIsVisible}
        handleConfirm={confirm}
        confirming={confirming}
      />
      <UpdateReleaseDialog
        visible={updateDialogVisible}
        setVisible={setUpdateDialogVisible}
        callback={saveRelease}
        status={release?.status || ReleaseStatus.Draft}
        withCompletionDialog={false}
      />
    </FloatingFooterStyled>
  );
};

import { Checkbox } from "@/common/components/checkbox/Checkbox";
import { If } from "@/common/components/if/If";
import { FaClipboardCheck } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

export const Container = tw.div`grid grid-cols-[1fr_auto] gap-4 min-w-max`;
export const Suggestions = tw.div`grid gap-2 items-start content-start`;
export const SuggestionsTitle = tw.div`grid grid-cols-[1fr_auto] items-center gap-6 mt-1 font-medium`;
export const SuggestionsSubtitle = tw.div`text-sm text-gray-500 mb-4`;

const SuggestionsList = tw.div`grid gap-2`;
const SuggestionListItem = tw.div`grid grid-cols-[1fr_1fr] items-center gap-2`;
const DefaultValue = tw.div`w-full text-xs border-none p-2.5 bg-gray-100`;
const HeaderLabel = tw.div`text-xs font-medium`;

type SuggestionButtonProps = {
  $currentTemplate: boolean;
};

const SuggestionButton = tw.button<SuggestionButtonProps>`
  grid grid-cols-[1fr_auto] items-center gap-2 pl-2 pr-4 py-2 text-sm text-left bg-blue-100 hover:bg-blue-200 rounded-md transition-colors w-fit
  ${({ $currentTemplate }) => ($currentTemplate ? "bg-blue-500 text-white pointer-events-none pr-2" : "")}
`;

const IncludeConditional = tw.button`
  grid grid-cols-[1fr_auto] items-center gap-2 pl-2 pr-4 py-1 text-sm text-left rounded-md transition-colors w-fit
`;

export type TemplateVariable = {
  label: string;
  value: string;
  template: string;
};

type VariableListProps = {
  variables: TemplateVariable[];
  currentTemplate: string;
  defaultValues: Record<string, string>;
  onCopy: (template: string) => void;
  onDefaultValueChange: (key: string, value: string) => void;
};

export const VariableList = ({
  variables,
  currentTemplate,
  defaultValues,
  onCopy,
  onDefaultValueChange,
}: VariableListProps) => {
  return (
    <SuggestionsList>
      <SuggestionListItem>
        <HeaderLabel>
          <FormattedMessage id="VARIABLE_NAME" />
        </HeaderLabel>
        <HeaderLabel>
          <FormattedMessage id="PREVIEW_VALUE" />
        </HeaderLabel>
      </SuggestionListItem>
      {variables.map(({ label, value, template }) => (
        <SuggestionListItem key={label}>
          <SuggestionButton
            onClick={() => onCopy(template)}
            title={template}
            $currentTemplate={currentTemplate === template}
          >
            {label}
            {currentTemplate === template && (
              <FaClipboardCheck className="h-4 w-4" />
            )}
          </SuggestionButton>
          <If isTrue={defaultValues[value]}>
            <DefaultValue
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) =>
                onDefaultValueChange(value, e.currentTarget.textContent || "")
              }
            >
              {defaultValues[value]}
            </DefaultValue>
          </If>
        </SuggestionListItem>
      ))}
    </SuggestionsList>
  );
};

type ConditionalToggleProps = {
  checked: boolean;
  onChange: () => void;
};

export const ConditionalToggle = ({
  checked,
  onChange,
}: ConditionalToggleProps) => (
  <IncludeConditional onClick={onChange}>
    <Checkbox checked={checked} onChange={onChange} />
    <FormattedMessage id="INCLUDE_CONDITIONAL" />
  </IncludeConditional>
);

import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useCallback } from "react";

export const useWarehouseSupplier = () => {
  const { warehouses } = useWarehouses();

  const isWarehouseSupplier = useCallback(
    (supplierId: string) => {
      return warehouses.some((warehouse) => warehouse.id === supplierId);
    },
    [warehouses],
  );

  return { isWarehouseSupplier };
};

import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  createCustomTemplatesSlice,
  CustomTemplatesSlice,
} from "./slices/customTemplatesSlice";
import {
  createDefaultTemplatesSlice,
  DefaultTemplatesSlice,
} from "./slices/defaultTemplatesSlice";

export type TemplateStringsState = DefaultTemplatesSlice & CustomTemplatesSlice;

export const useTemplateStringsStore = create<TemplateStringsState>()(
  devtools((...args) => ({
    ...createDefaultTemplatesSlice(...args),
    ...createCustomTemplatesSlice(...args),
  })),
);

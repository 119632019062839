import { VendorLabelFormatter } from "../types/VendorLabelFormatterType";

export const vendorLabelFormatter: VendorLabelFormatter = (
  location,
  contacts = [],
  opts = {},
) => {
  if (!location) {
    return "";
  }
  const locationName = location.address
    ? `(${location.address?.city}, ${location.address?.state})`
    : "";
  const name = `${location.org?.name} ${locationName}${opts?.vendorCode ? ` [${opts.vendorCode}]` : ""}`;
  return `${name}${contacts.length > 0 ? ` - ${contacts.map((c) => c.name).join(", ")}` : ""}`;
};

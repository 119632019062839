import { SystemRole } from "@/generated/graphql";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useStartupDataStore } from "../stores/useStartupDataStore";

export const useFMAdmin = () => {
  const { viewer } = useStartupDataStore(
    useShallow((state) => ({
      viewer: state.viewer,
    })),
  );
  const isFMAdmin = useMemo(() => {
    return viewer?.systemRoles.includes(SystemRole.FmAdmin);
  }, [viewer]);

  return { isFMAdmin };
};

import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { AddressFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { WarehouseOption, WarehouseType } from "./types/WarehouseOption";

export const PROJECT_ADDRESS_ID = "project-address";
export const ALL_WAREHOUSES_ID = "all-warehouses";
export type WarehouseOptionsProps = {
  projectAddress?: AddressFieldsFragment;
  existingWarehouseId?: string | null;
  includeWarehouseInName?: boolean;
  showAllWarehousesOption?: boolean;
  allWarehousesLabel?: string;
};

export const useWarehouseOptions = ({
  projectAddress,
  existingWarehouseId,
  includeWarehouseInName,
  showAllWarehousesOption,
  allWarehousesLabel,
}: WarehouseOptionsProps) => {
  const intl = useIntl();
  const { warehouses } = useWarehouses();

  const warehouseOptions: WarehouseOption[] = useMemo(() => {
    const existingWarehouse = warehouses.find(
      (o) => o.id === existingWarehouseId,
    );

    return [
      ...(showAllWarehousesOption
        ? [
            {
              id: ALL_WAREHOUSES_ID,
              name: allWarehousesLabel || intl.$t({ id: "ALL_WAREHOUSES" }),
              type: WarehouseType.Warehouse,
            },
          ]
        : []),
      ...(projectAddress
        ? [
            {
              id: PROJECT_ADDRESS_ID,
              name: intl.$t({ id: "PROJECT_LOCATION_WITH_ADDRESS" }),
              address: projectAddress,
              type: WarehouseType.Job,
            },
          ]
        : []),
      ...(existingWarehouse && existingWarehouse.archivedAt
        ? [
            {
              ...existingWarehouse,
              name: intl.$t(
                { id: "DELETED_WAREHOUSE" },
                { name: existingWarehouse.name },
              ),
              type: WarehouseType.Warehouse,
            },
          ]
        : []),
      ...warehouses.map((o) => ({
        ...o,
        name: includeWarehouseInName
          ? intl.$t({ id: "FORMATTED_WAREHOUSE_NAME" }, { name: o.name })
          : o.name,
        type: WarehouseType.Warehouse,
      })),
    ];
  }, [
    warehouses,
    showAllWarehousesOption,
    allWarehousesLabel,
    intl,
    projectAddress,
    existingWarehouseId,
    includeWarehouseInName,
  ]);

  return { warehouseOptions };
};

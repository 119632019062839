import { InventoryItemPendingFlowFieldsFragment } from "@/generated/graphql";
import { TransactionHistoryListViewDateRenderer } from "../../transaction-history-list-view-date-renderer/TransactionHistoryListViewDateRenderer";

type Props = {
  item: Pick<InventoryItemPendingFlowFieldsFragment, "release">;
};

export const ScheduledFlowDate = ({ item }: Props) => (
  <TransactionHistoryListViewDateRenderer date={item.release.time} />
);

import { Instructions } from "@/common/components/instructions/Instructions";
import { Loader } from "@/common/components/loader/Loader";
import { NoteDocumentPanel } from "@/common/components/note-document-panel/NoteDocumentPanel";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { ReleaseDeliverySlips } from "@/common/components/release-images/ReleaseDeliverySlips";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { ReleaseDescription } from "@/contractor/pages/home/release/components/ReleaseDescription";
import { ReleaseReceipts } from "@/contractor/pages/home/release/pages/release-details/ReleaseReceipts";
import { ReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseItemList } from "@/contractor/pages/home/release/release-items-list/ReleaseItemList";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { InvoiceReleaseEditInfo } from "../invoice-edit-info/InvoiceReleaseEditInfo";
import { InvoiceReleaseItemsFilterHeader } from "./components/invoice-release-items-filter-header/InvoiceReleaseItemsFilterHeader";
import { InvoicesCard } from "./components/invoices-card/InvoicesCard";
import { useInvoiceReleaseItemsTableConfiguration } from "./hooks/useInvoiceReleaseItemsTableConfiguration";

const ReceiptsCard = tw(ReleaseReceipts)`my-[1px] lg:border-0 overflow-visible`;

type Props = {
  readonly?: boolean;
};

export const InvoiceReleaseItems: FC<Props> = () => {
  const { release } = useRelease();
  const { items, tableConfiguration, classNames, itemFn } =
    useInvoiceReleaseItemsTableConfiguration();

  useSetCurrentProjectId(release?.project?.id);

  if (!release) {
    return <Loader loading />;
  }

  return (
    <ReleaseItemsZoneProvider items={items} positionAsCount>
      <InvoiceReleaseItemsFilterHeader />
      <ReleaseItemList
        columns={tableConfiguration}
        classNames={classNames}
        itemFn={itemFn}
      />
      <InvoiceReleaseEditInfo />
      <Instructions
        instruction={release.instructions}
        projectId={release.project?.id}
        isOpened={
          !!release.instructions?.text ||
          !!release.instructions?.assets?.length ||
          !!release.description
        }
      >
        <ReleaseDescription release={release} disableEdit />
      </Instructions>
      <QuoteDocumentPanel
        readonly
        quoteDocument={release.quoteDocument}
        quote={release.quote}
      />
      <NoteDocumentPanel readonly noteDocument={release.noteDocument} />
      <ReceiptsCard release={release} prePaid={false} />
      <ReleaseDeliverySlips deliverySlips={release.deliverySlips} />
      <InvoicesCard expanded />
    </ReleaseItemsZoneProvider>
  );
};

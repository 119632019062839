import {
  UpdateContractorReleasesInput,
  useUpdateContractorReleasesMutation,
} from "@/generated/graphql";
import { GraphQLError } from "graphql";

export const useUpdateContractorReleases = () => {
  const [updateContractorReleasesMutation, { loading }] =
    useUpdateContractorReleasesMutation();

  const updateContractorReleases = async (
    input: UpdateContractorReleasesInput,
  ) => {
    try {
      const { data } = await updateContractorReleasesMutation({
        variables: {
          input,
        },
      });
      return !!data?.updateContractorReleases;
    } catch (error) {
      if ((error as GraphQLError).message) {
        return (error as GraphQLError).message;
      }
      return false;
    }
  };

  return { updateContractorReleases, loading };
};

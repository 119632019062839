import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { CurrentStockItemContainer } from "../../CurrentStockList.styles";
import { CurrentStockListItemProps } from "../../types/CurrentStockListItemProps";
import { useCurrentStockScheduledInflow } from "./useCurrentStockScheduledInflow";

export const CurrentStockScheduledInflow = ({
  item,
}: CurrentStockListItemProps) => {
  const { onClick, disabled, className } = useCurrentStockScheduledInflow({
    item,
  });
  return (
    <CurrentStockItemContainer>
      <LinkLike onClick={onClick} className={className} disabled={disabled}>
        <ValueUnit
          value={item?.scheduledInflowTotal || 0}
          classNames={{ item: "text-xs" }}
          minimumFractionDigits={2}
        />
      </LinkLike>
    </CurrentStockItemContainer>
  );
};

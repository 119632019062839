export const warningStyles = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#F7763F",
  },
  ".Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F7763F",
  },
  ".MuiInputLabel-root": {
    color: "#F7763F",
  },
  ".MuiInputBase-input": {
    color: "#F7763F",
    WebkitTextFillColor: "#F7763F",
  },
  ".MuiInputBase-input:disabled": {
    WebkitTextFillColor: "#F39A74",
  },
};

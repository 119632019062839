import { debounce } from "lodash";
import Prism from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-go";
import "prismjs/themes/prism.css";
import { FC, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import Editor from "react-simple-code-editor";
import tw from "tailwind-styled-components";
import { Template } from "../../types/Template";

const Container = tw.div`grid grid-cols-[1fr_auto] gap-4`;
const EditorContainer = tw.div`flex gap-4 mt-2 bg-gray-100`;

type TemplateEditorProps = {
  template: Template;
  setTemplate: (template: Template) => void;
};

export const TemplateEditor: FC<TemplateEditorProps> = ({
  template,
  setTemplate,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState<string>(template.template ?? "");

  const throttledSetContent = useMemo(
    () =>
      debounce((updatedValue: string) => {
        setTemplate({ ...template, template: updatedValue });
      }, 300),
    [setTemplate, template],
  );

  const handleChange = useCallback(
    (code: string) => {
      setValue(code);
      throttledSetContent(code);
    },
    [throttledSetContent],
  );

  return (
    <Container>
      <EditorContainer>
        <Editor
          value={value}
          onValueChange={handleChange}
          highlight={(code) => Prism.highlight(code, Prism.languages.go, "go")}
          padding={10}
          placeholder={intl.$t({ id: "TEMPLATE_STRING_PLACEHOLDER" })}
          style={{
            width: "100%",
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
          }}
        />
      </EditorContainer>
    </Container>
  );
};

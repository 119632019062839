import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { Checkbox } from "@/common/components/checkbox/Checkbox";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { Add, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ArchiveGroupFunction } from "../../../common/types/types";
import { useGroupEditStore } from "../../../stores/useGroupEditStore";
import {
  ADD_NEW_GROUP_ID,
  ALL_GROUP_ID,
  UNASSIGNED_GROUP_ID,
} from "../GroupFilter";

const Item = tw.div`w-full py-2 grid grid-flow-col gap-x-2 justify-between items-center`;
const NewItem = tw(Item)`justify-start -ml-4 gap-x-0`;
const ItemLabel = tw.div`flex flex-col pr-20`;
const Name = tw.div`text-base`;
const NewItemLabel = tw.div`text-base font-medium`;
const IconButtonBorderlessStyled = tw(IconButtonBorderless)`my-0 h-6 w-6`;
const DefaultItem = tw(Item)`italic`;
const Actions = tw.div`flex`;
const ItemContainer = tw.div`flex items-center gap-2`;

type Option = {
  id: string;
  name: string;
};

type Props = {
  item: Option;
  archiveGroup: ArchiveGroupFunction;
  titleKey: string;
  contentKey: string;
  checked: boolean;
};

export const GroupFilterCustomRender: FC<Props> = ({
  item,
  archiveGroup,
  titleKey,
  contentKey,
  checked,
}) => {
  const { openDialog } = useDialog();
  const { setEditVisible, setGroup: setFolder } = useGroupEditStore();
  const intl = useIntl();

  const handleArchive = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      includeWarningIcon: true,
      title: intl.$t({ id: titleKey }, { name: item.name }),
      content: intl.$t({ id: contentKey }),
      handleConfirm: async () => {
        await archiveGroup(item.id);
      },
    });
  }, [
    openDialog,
    intl,
    titleKey,
    item.name,
    item.id,
    contentKey,
    archiveGroup,
  ]);

  if (item.id === ADD_NEW_GROUP_ID) {
    return (
      <NewItem>
        <IconButtonBorderlessStyled className="my-0 ml-2 mr-1 h-6 min-h-6">
          <Add />
        </IconButtonBorderlessStyled>
        <NewItemLabel>{item.name}</NewItemLabel>
      </NewItem>
    );
  }

  if (item.id === UNASSIGNED_GROUP_ID || item.id === ALL_GROUP_ID) {
    return (
      <DefaultItem>
        <ItemLabel>
          <Name>{item.name}</Name>
        </ItemLabel>
      </DefaultItem>
    );
  }

  return (
    <Item>
      <ItemContainer>
        <Checkbox checked={checked} onChange={() => {}} />
        <ItemLabel>
          <Name>{item.name}</Name>
        </ItemLabel>
      </ItemContainer>
      <Actions>
        <IconButtonBorderlessStyled
          testId="edit-group"
          onClick={() => {
            setEditVisible(true);
            setFolder(item);
          }}
        >
          <EditOutlined />
        </IconButtonBorderlessStyled>
        <IconButtonBorderlessStyled
          onClick={handleArchive}
          testId="archive-group"
        >
          <DeleteOutline />
        </IconButtonBorderlessStyled>
      </Actions>
    </Item>
  );
};

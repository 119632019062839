import { If } from "@/common/components/if/If";
import {
  Container,
  Content,
  Title,
} from "@/common/components/integration-batch-options/IntegrationBatchOptions.styles";
import {
  Label,
  Row,
} from "@/common/components/integration-options/IntegrationOptions.styles";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { SourceSystemWrapper } from "@/common/components/source-system-wrapper/SourceSystemWrapper";
import { Switch } from "@/common/components/switch/Switch";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useOrgSettingsExtended as useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { BatchType, ReleaseSummaryFieldsFragment } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IntegrationBatchOptions } from "../../../../../../../../common/components/integration-batch-options/IntegrationBatchOptions";
import { ConnectionMode } from "../../../../../../admin/integrations/components/common/ConnectionMode";
import { useReleaseConnectionOptions } from "../../providers/ReleaseConnectionOptionsProvider";
import { useReleasesByIds } from "../../providers/ReleasesByIdsProvider";
import {
  ErrorContainer,
  ErrorIcon,
  ErrorMessage,
} from "./ConnectionOptions.styles";

type Props = {
  includeAutoSync?: boolean;
  includeNoExport?: boolean;
  release?: ReleaseSummaryFieldsFragment;
  mode?: ConnectionMode;
  majorityDefaultSync?: boolean;
};

export const ReleaseConnectionOptions: FC<Props> = ({
  includeAutoSync,
  includeNoExport,
  release,
  mode,
  majorityDefaultSync,
}) => {
  const intl = useIntl();
  const { integrations, connectedSourceSystem } = useOrgSettings();
  const {
    autoSync,
    setAutoSync,
    hasBatchError,
    useSourceSystemPO,
    setUseSourceSystemPO,
    backgroundExport,
    setBackgroundExport,
  } = useReleaseConnectionOptions();
  const { releasesByIds } = useReleasesByIds();
  const disableBackgroundExport = useMemo(
    () => releasesByIds.some((release) => release.poLink?.syncedAt),
    [releasesByIds],
  );

  if (!includeNoExport && !includeAutoSync && !useSourceSystemPO) {
    return null;
  }

  if (!integrations) {
    return null;
  }

  return (
    <Container>
      <Title>
        <FormattedMessage id="OPTIONS" />
      </Title>
      <Content $opened>
        <If isTrue={includeNoExport}>
          <Row>
            <Label>
              <FormattedMessage id="SET_ORDER_TO_BE_EXPORTED" />
            </Label>
            <Switch
              width={110}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              onChange={setUseSourceSystemPO}
              value={useSourceSystemPO}
            />
          </Row>
        </If>
        <If isTrue={includeAutoSync && useSourceSystemPO}>
          <Row>
            <Label>
              <FormattedMessage id="AUTO_SYNC" />
              <Tooltip
                id="default-auto-sync"
                element={
                  <LinkLike>
                    <InfoOutlined />
                  </LinkLike>
                }
              >
                <FormattedMessage
                  id="AUTO_SYNC_INFO"
                  values={{ sub: (...chunks) => <i>{chunks}</i> }}
                />
                <If isTrue={majorityDefaultSync}>
                  <br />
                  <FormattedMessage id="AUTO_SYNC_INFO_MAJORITY_DEFAULT_SYNC" />
                </If>
              </Tooltip>
            </Label>
            <Switch
              width={110}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              onChange={setAutoSync}
              value={autoSync}
            />
          </Row>
        </If>
        <If isTrue={useSourceSystemPO}>
          <SourceSystemWrapper anySourceSystem>
            <Row>
              <Label>
                <FormattedMessage id="EXPORT_IN_THE_BACKGROUND" />
                <Tooltip
                  id="export-in-background"
                  element={
                    <LinkLike>
                      <InfoOutlined />
                    </LinkLike>
                  }
                >
                  <FormattedMessage
                    id={
                      disableBackgroundExport
                        ? "EXPORT_IN_THE_BACKGROUND_DISABLED_INFO"
                        : "EXPORT_IN_THE_BACKGROUND_INFO"
                    }
                    values={{
                      count: releasesByIds.length,
                      integration: connectedSourceSystem
                        ? intl.$t({
                            id: `INTEGRATION_${connectedSourceSystem.system}`,
                          })
                        : "",
                    }}
                  />
                </Tooltip>
              </Label>
              <Switch
                width={110}
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
                onChange={setBackgroundExport}
                value={backgroundExport}
                disabled={disableBackgroundExport}
              />
            </Row>
          </SourceSystemWrapper>
          <IntegrationBatchOptions
            mode={mode}
            batch={release?.poLink?.batch}
            type={BatchType.PurchaseOrder}
          />
          <If isTrue={hasBatchError}>
            <ErrorContainer>
              <ErrorIcon />
              <ErrorMessage>
                <FormattedMessage id="ERROR_CREATING_BATCH" />
              </ErrorMessage>
            </ErrorContainer>
          </If>
        </If>
      </Content>
    </Container>
  );
};

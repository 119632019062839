import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { useInventoryItemsQuery } from "@/generated/graphql";
import { useEffect, useMemo } from "react";
import { useInventoryItemsStore } from "../store/useInventoryItemsStore";
import { GroupedInventoryItems } from "../types/GroupedInventoryItems";
import { WarehouseInventoryItem } from "../types/WarehouseInventoryItem";
import inventoryItemsUtils from "./utils/inventoryItemsUtils";

export const useInventoryItems = () => {
  const { filter } = useInventoryItemsStore();
  const { setPageInfo, paginationArgs } = usePagination();
  const { calcExtPrice } = usePriceCalculation();
  const { data, loading, error, refetch } = useInventoryItemsQuery({
    variables: {
      ...paginationArgs,
      filter,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.inventoryItems) {
      setPageInfo(data.inventoryItems.pageInfo);
    }
  }, [data, setPageInfo]);

  const inventoryItems = useMemo(
    () => data?.inventoryItems.edges.map((e) => e.node) ?? [],
    [data],
  );

  const groupedItems: GroupedInventoryItems[] = useMemo(() => {
    return inventoryItems.map((item) => {
      const warehouses = item.state.reduce<WarehouseInventoryItem[]>(
        (acc, state) => {
          if (
            filter?.warehouseIds &&
            !filter.warehouseIds.includes(state.warehouse.id)
          ) {
            return acc;
          }
          const warehouse = acc.find(
            (w) => w.warehouse.id === state.warehouse.id,
          );
          const extPrice = calcExtPrice(
            state.unitPrice,
            state.remainingQuantity,
          );
          if (!warehouse) {
            const newWarehouse =
              inventoryItemsUtils.generateWarehouseStateEntry(
                item,
                state,
                extPrice,
              );
            acc.push(newWarehouse);
          } else {
            inventoryItemsUtils.updateWarehouseEntry(
              warehouse,
              state,
              extPrice,
            );
          }
          return acc;
        },
        [],
      );

      inventoryItemsUtils.updateScheduledOutflows(
        item,
        warehouses,
        filter?.warehouseIds,
      );
      inventoryItemsUtils.updateScheduledInflows(
        item,
        warehouses,
        filter?.warehouseIds,
      );
      inventoryItemsUtils.updateAverageWarehouseEntries(warehouses);

      return {
        ...item,
        warehouses,
      };
    });
  }, [calcExtPrice, filter?.warehouseIds, inventoryItems]);
  useErrorEffect(error);

  return {
    inventoryItems,
    groupedItems,
    loading,
    error: !!error,
    totalCount: data?.inventoryItems.totalCount ?? 0,
    refetch,
  };
};

import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { TotalProps } from "@/common/hooks/usePriceCalculation";
import { useFormPriceCalculation } from "@/contractor/pages/home/release/hooks/useFormPriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { BsCheck, BsExclamation } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useExpandableFooterStore } from "../../../../../../../../common/components/footer/stores/useExpandableFooterStore";
import { If } from "../../../../../../../../common/components/if/If";
import { Price } from "../../../../../../../../common/components/price/Price";
import { TOTAL_PRICE_DECIMAL_POINTS } from "../../../../../../../../common/const";
import { MatchedOrderViewState } from "../../enums/MatchedOrderViewState";
import { useInvoiceFooterTotalsWarning } from "../../hooks/useInvoiceFooterTotalsWarning";
import { useSelectedInvoiceItems } from "../../hooks/useSelectedInvoiceItems";
import { useInvoiceMatchedOrder } from "../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../matched-order/components/InvoiceVerificationForm";

export const ORDER_TOTAL_PANEL_WIDTH = 110;

const Container = tw.div`flex flex-row mt-2 justify-start items-center h-6 mr-2`;
const TotalPanel = tw.div`flex flex-col text-center text-2xs min-w-20 items-center`;
const HorizontalDivider = tw.div`border-l-0 border-r-[1px] h-9 border-dashed border-black mx-3`;
const Placeholder = tw.div``;
const TotalExtendedPanel = tw.div`flex bg-blue-250 h-12 rounded-xl ml-1 px-2 items-center`;
const CheckmarkCircleGreen = tw(
  BsCheck,
)`bg-green-600 fill-green-600 text-xl rounded-full m-0 fill-white ml-1.5`;
const ExclamationCircleRed = tw(
  BsExclamation,
)`bg-red-500 fill-red-500 text-xl rounded-full m-0 fill-white ml-1.5`;
const ExclamationCircleGray = tw(
  BsExclamation,
)`text-xl fill-gray-500 bg-gray-500 rounded-full fill-white`;

type Props = {
  displayExtended: boolean;
  form: UseFormReturn<InvoiceCreateReleaseFormValues>;
};

export const InvoiceFooterTotals: FC<Props> = ({ displayExtended, form }) => {
  const { invoice } = useInvoiceVerification();
  const { expanded } = useExpandableFooterStore();
  const { selectedInvoiceItemsTotal } = useSelectedInvoiceItems();
  const { selectedRelease, matchedOrderViewState } = useInvoiceMatchedOrder();
  const { release } = useRelease();
  const {
    displayTotalErrorIcon,
    displayGrayTotalErrorIcon,
    displayTotalGreenIcon,
  } = useInvoiceFooterTotalsWarning();

  const { total } = useFormPriceCalculation({
    form: form as unknown as UseFormReturn<TotalProps, unknown, TotalProps>,
  });

  const displayOrderTotal = useMemo(() => {
    switch (matchedOrderViewState) {
      case MatchedOrderViewState.CREATE_ORDER:
      case MatchedOrderViewState.EDIT_ORDER:
        return true;
      case MatchedOrderViewState.MATCH_ORDER:
        return !!selectedRelease;
      default:
        return !!invoice?.release;
    }
  }, [matchedOrderViewState, selectedRelease, invoice?.release]);

  const orderTotal = useMemo(() => {
    switch (matchedOrderViewState) {
      case MatchedOrderViewState.CREATE_ORDER:
      case MatchedOrderViewState.EDIT_ORDER:
        return total;
      case MatchedOrderViewState.MATCH_ORDER:
        return selectedRelease?.total ?? 0;
      default:
        return release?.total ?? 0;
    }
  }, [total, matchedOrderViewState, selectedRelease, release?.total]);

  if (expanded) {
    return <Placeholder />;
  }

  return (
    <Container>
      <TotalPanel
        className={displayExtended ? "mr-2.5" : ""}
        style={{ width: ORDER_TOTAL_PANEL_WIDTH }}
      >
        <If isTrue={displayOrderTotal}>
          <FormattedMessage id="ORDER_TOTAL" />
          <Price
            price={orderTotal}
            className="text-base font-medium"
            maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
          />
          <FormattedMessage id="WITH_TAX_AND_FEES" />
        </If>
      </TotalPanel>
      <If isTrue={displayExtended}>
        <TotalExtendedPanel>
          <TotalPanel style={{ width: ORDER_TOTAL_PANEL_WIDTH }}>
            <FormattedMessage id="INVOICED_ITEMS" />
            <Price
              price={selectedInvoiceItemsTotal}
              className="text-base font-medium"
              maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
            />
            <FormattedMessage id="WITH_TAX_AND_FEES" />
          </TotalPanel>
          <If isTrue={displayTotalGreenIcon}>
            <CheckmarkCircleGreen />
          </If>
          <If isTrue={displayTotalErrorIcon}>
            <Tooltip
              id="INVOICE_FOOTER_COLLAPSED_ERROR"
              element={
                displayGrayTotalErrorIcon ? (
                  <ExclamationCircleGray />
                ) : (
                  <ExclamationCircleRed />
                )
              }
            >
              <FormattedMessage id="INVOICE_FOOTER_COLLAPSED_ERROR" />
            </Tooltip>
          </If>
          <HorizontalDivider />
          <TotalPanel>
            <FormattedMessage id="INVOICE_TOTAL" />
            <Price
              price={invoice?.total ?? 0}
              className="text-base font-medium"
              maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
            />
            <FormattedMessage id="WITH_TAX_AND_FEES" />
          </TotalPanel>
        </TotalExtendedPanel>
      </If>
      <If
        isTrue={
          !displayExtended &&
          matchedOrderViewState !==
            MatchedOrderViewState.HEADER_ONLY_INVOICE_EDITABLE &&
          matchedOrderViewState !== MatchedOrderViewState.HEADER_ONLY_INVOICE
        }
      >
        <HorizontalDivider className="ml-0" />
        <TotalPanel>
          <FormattedMessage id="INVOICE_TOTAL" />
          <Price
            price={invoice?.total ?? 0}
            className="text-base font-medium"
            maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
          />
          <FormattedMessage id="WITH_TAX_AND_FEES" />
        </TotalPanel>
      </If>
    </Container>
  );
};

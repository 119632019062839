import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  CreateRfqInput,
  RfqsDocument,
  ViewerDocument,
  namedOperations,
  useCreateRfqMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useRfqCreation = () => {
  const [createRfqMutation] = useCreateRfqMutation();
  const { setError } = useGlobalError();
  const { paginationArgs } = usePagination();

  const createRfq = useCallback(
    async (input: CreateRfqInput) => {
      try {
        const { data, errors } = await createRfqMutation({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: RfqsDocument,
              variables: { ...paginationArgs, filter: {} },
            },
            { query: ViewerDocument },
          ],
          update: (cache) => cleanQuery(cache, namedOperations.Query.Rfqs),
        });
        setError(errors);

        return data?.createRfq || null;
      } catch (errors) {
        setError(errors);

        return null;
      }
    },
    [createRfqMutation, paginationArgs, setError],
  );

  return { createRfq };
};

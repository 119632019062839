import { LinkLikeStyled } from "@/common/components/additional-charges/AdditionalCharges.styles";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { InvoiceDocumentType } from "@/common/components/invoices/invoice-issue/common/InvoiceIssue";
import { InvoiceIssues } from "@/common/components/invoices/invoice-issue/InvoiceIssues";
import {
  ItemizedNonItemizedToggle,
  ViewType,
} from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { useAddReceiptItems } from "@/common/hooks/add-missing-items-to-order/useAddReceiptItems";
import { routes } from "@/config/routes";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { useClearOrderFromReceipt } from "../../hooks/useClearOrderFromReceipt";
import { useReceipt } from "../../providers/ReceiptProvider";
import { ReceiptMatchedOrderViewState } from "../../types/ReceiptMatchedOrderViewState";
import { ReceiptSelectModeHeader } from "../receipt-select-mode-header/ReceiptSelectModeHeader";
import { ReceiptCreateReleaseHeader } from "./ReceiptCreateReleaseHeader";

const Header = tw.div`flex flex-col`;
const OrderNumberHeader = tw.div`flex items-center gap-1 text-base font-medium`;
const Group = tw.div`flex flex-row justify-between first:px-2 mb-3`;
const ButtonContainer = tw.div`flex flex-col items-end gap-2`;
const OrderInfo = tw.div`flex flex-col gap-2`;
const ClearButtonText = tw.div`text-xs font-normal text-blue-800`;
const PoNumberContainer = tw.div`grid grid-flow-col items-center gap-1`;

export const ReceiptOrderHeader: FC = () => {
  const {
    itemized,
    receipt,
    refetch,
    hasReleaseRecorded,
    matchedOrderViewState,
    setItemized,
  } = useReceipt();
  const { addReceiptItems } = useAddReceiptItems();
  const { clearOrder } = useClearOrderFromReceipt();

  const releasePath = useMemo(() => {
    if (receipt?.release?.id) {
      return generatePath(routes.delivery, {
        deliveryId: receipt.release.id,
      });
    }
  }, [receipt?.release?.id]);

  return (
    <Header>
      <Group>
        <If isTrue={receipt?.release}>
          <OrderInfo>
            <FormattedMessage id="YOUR_ORDER" tagName={OrderNumberHeader} />
            <PoNumberContainer>
              <LinkLikeStyled to={releasePath} disabled={!releasePath}>
                <FormattedMessage
                  id="ORDER_WITH_NUMBER"
                  values={{ orderNumber: receipt?.release?.sequenceNumber }}
                />
              </LinkLikeStyled>
              <ReleaseStatusChip
                status={receipt?.release?.status}
                type="small"
                releaseType={receipt?.release?.type}
              />
              <OutlinedButton
                $small
                className="h-6 min-w-16"
                onClick={clearOrder}
              >
                <FormattedMessage id="CLEAR" tagName={ClearButtonText} />
              </OutlinedButton>
            </PoNumberContainer>
          </OrderInfo>
        </If>
        <If isTrue={!receipt?.release}>
          <ReceiptSelectModeHeader />
        </If>
        <If
          isTrue={
            matchedOrderViewState ===
              ReceiptMatchedOrderViewState.CREATE_ORDER ||
            (matchedOrderViewState === ReceiptMatchedOrderViewState.DEFAULT &&
              hasReleaseRecorded)
          }
        >
          <ButtonContainer>
            <ItemizedNonItemizedToggle
              handleChange={(view) => setItemized(view === ViewType.Itemized)}
              isItemized={itemized}
              size="large"
            />
            <If isTrue={itemized}>
              <OutlinedButton $small onClick={() => addReceiptItems()}>
                <FormattedMessage id="ADD_ITEMS_FROM_RECEIPT" />
              </OutlinedButton>
            </If>
          </ButtonContainer>
        </If>
      </Group>
      <If
        isTrue={
          matchedOrderViewState === ReceiptMatchedOrderViewState.CREATE_ORDER ||
          (matchedOrderViewState === ReceiptMatchedOrderViewState.DEFAULT &&
            hasReleaseRecorded)
        }
      >
        <Group>
          <ReceiptCreateReleaseHeader />
        </Group>
        <InvoiceIssues
          invoice={receipt}
          refetchInvoice={refetch}
          documentType={InvoiceDocumentType.RECEIPT}
        />
      </If>
    </Header>
  );
};

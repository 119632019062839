import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import {
  Base,
  MdFixedContainer,
  SmFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { ProjectItemUnitsInput } from "@/contractor/pages/home/project/components/project-items/ProjectItemUnitsInput";
import {
  TagContainer,
  WideRfqItemContainer,
} from "@/contractor/pages/home/project/components/project-list/ProjectListItem.styles";
import { getEstimatedItem } from "@/contractor/pages/home/project/utils/getEstimatedItem";

import { Price } from "@/common/components/price/Price";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { VendorView } from "@/contractor/pages/home/project/components/project-items/VendorView";
import { RfqProjectItemTagPicker } from "@/contractor/pages/home/rfq/components/import-project-items/rfq-project-item-tag-picker/RfqProjectItemTagPicker";
import {
  BuyoutFieldsFragment,
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { BuyoutProjectItemCheckbox } from "./BuyoutProjectItemCheckbox";
import { BuyoutProjectItemGroupCheckbox } from "./BuyoutProjectItemGroupCheckbox";

export const useBuyoutProjectListItemConfiguration = ({
  buyout,
}: {
  buyout?: BuyoutFieldsFragment | null;
}) => {
  const { hasPhaseCodes } = useOrgSettings();

  const configuration: Array<
    GridCol<ProjectItemFieldsFragment, EstimatedItemFieldsFragment>
  > = useMemo(
    () => [
      {
        wrapper: Base,
        item: ({ item }) => {
          return (
            <BuyoutProjectItemCheckbox
              item={item}
              estimatedItem={getEstimatedItem({ item })}
            />
          );
        },
        subgroup: (category) => {
          return <BuyoutProjectItemGroupCheckbox items={category.items} />;
        },
        position: "center",
      },
      {
        wrapper: WideRfqItemContainer,
        item: ({ item, count }) => (
          <ProjectItemMaterialView
            estimatedItem={getEstimatedItem({ item })}
            item={item}
            count={count}
            expandable={false}
            manufacturer={getEstimatedItem({ item })?.manufacturer}
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={buyout?.project.id}
          />
        ),
        includesCounter: true,
        header: <FormattedMessage id="PROJECT_ITEM_ESTIMATED_ITEMS" />,
      },
      {
        wrapper: TagContainer,
        header: <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />,
        item: ({ item }) => {
          return <RfqProjectItemTagPicker item={item} />;
        },
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => {
          const estimatedItem = getEstimatedItem({ item, sumIfMultiple: true });
          return <VendorView id={estimatedItem?.sellerOrgLocation?.id} />;
        },
        position: "center",
        header: <FormattedMessage id="VENDOR" />,
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => {
          const estimatedItem = getEstimatedItem({ item, sumIfMultiple: true });
          return (
            <ProjectItemUnitsInput
              item={item}
              estimatedItem={estimatedItem}
              staticText
            />
          );
        },
        group: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} $bold />
        ),
        subgroup: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} />
        ),
        position: "center",
        header: <FormattedMessage id="ESTIMATED_QUANTITY" />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => {
          const estimatedItem = getEstimatedItem({ item, sumIfMultiple: true });
          return <Price price={estimatedItem?.unitPrice} className="text-sm" />;
        },
        position: "end",
        header: <FormattedMessage id="UNIT_PRICE" />,
      },
    ],
    [buyout?.project.id, hasPhaseCodes],
  );

  return configuration;
};

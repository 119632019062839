import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { SpreadSheetConfig } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const useBudgetByTagsConfiguration = () => {
  const intl = useIntl();

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "TAG" }),
        columnId: "name",
        columnType: COLUMN_TYPE.Tag,
        readOnlyFn: () => intl.$t({ id: "READ_ONLY" }),
        editor: true,
      },
      {
        header: intl.$t({ id: "BUDGET" }),
        columnId: "amount",
        columnType: COLUMN_TYPE.Budget,
      },
    ],
    [intl],
  );

  return { spreadsheetConfig };
};

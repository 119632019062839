import { Instructions } from "@/common/components/instructions/Instructions";
import { usePrefillHelpers } from "@/common/components/spreadsheet-table/hooks/usePrefillHelpers";
import { ColumnMapperProvider } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { InstructionInput } from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInventoryItemSequence } from "../components/current-stock-list/components/hooks/useInventoryItemSequence";
import {
  getSelectedItemIdParts,
  UNDEFINED_WAREHOUSE_ID,
} from "../utils/getSelectedItemIdParts";
import { InventoryAdjustmentBreadcrumbs } from "./components/inventory-adjustment-breadcrumbs/InventoryAdjustmentBreadcrumbs";
import { InventoryAdjustmentFooter } from "./components/inventory-adjustment-footer/InventoryAdjustmentFooter";
import {
  InventoryAdjustmentForm,
  InventoryAdjustmentFormValues,
} from "./components/inventory-adjustment-form/InventoryAdjustmentForm";
import { InventoryAdjustmentHeader } from "./components/inventory-adjustment-header/InventoryAdjustmentHeader";
import { useInventoryAdjustmentConfig } from "./hooks/useInventoryAdjustmentConfig";
import { useInventoryAdjustmentTableHelpers } from "./hooks/useInventoryAdjustmentTableHelpers";
import { useSyncInventoryAdjustment } from "./hooks/useSyncInventoryAdjustment";
import { useTableWarehouseListener } from "./hooks/useTableWarehouseListener";

const Container = tw.div``;

const InventoryAdjustmentWithProvider = () => {
  const intl = useIntl();
  const spreadsheetConfig = useInventoryAdjustmentConfig();
  const { loading, inventoryItems } = useInventoryItems();
  const { prefillStock, prefillNewStock, prefillUomChange } =
    useInventoryAdjustmentTableHelpers();
  const { setValue } = useFormContext<InventoryAdjustmentFormValues>();
  const { saving } = useSyncInventoryAdjustment();
  useTableWarehouseListener();
  const { prefillExtraOptions, updateExtPriceDependence } = usePrefillHelpers();

  const [items, setItems] = useState<Identity[]>([]);
  const { selectedEntities, sequenceIds, step } = useInventoryItemSequence();
  const currentSequenceId = useMemo(() => {
    return sequenceIds[step];
  }, [sequenceIds, step]);

  useEffect(() => {
    if (selectedEntities.length > 0) {
      setItems(
        selectedEntities
          .filter(({ id }) => {
            const { warehouseId } = getSelectedItemIdParts(id);
            return (
              warehouseId === currentSequenceId ||
              (warehouseId === UNDEFINED_WAREHOUSE_ID && step === 0)
            );
          })
          .map(({ id }) => {
            const { inventoryItemId } = getSelectedItemIdParts(id);
            const item = inventoryItems.find(
              (item) => item.id === inventoryItemId,
            );
            return {
              id: inventoryItemId,
              name: item?.orgMaterial,
              uom: {
                pluralDescription: item?.uom.pluralDescription,
              },
              quantityDecimal:
                getInventoryItemRemainingQuantity(item).toNumber(),
            };
          }),
      );
    }
  }, [selectedEntities, inventoryItems, loading, currentSequenceId, step]);

  const saveInstructions = useCallback(
    (instruction: InstructionInput) => {
      setValue("instructions", instruction);
    },
    [setValue],
  );

  return (
    <Container>
      <InventoryAdjustmentBreadcrumbs />
      <InventoryAdjustmentHeader />
      <SpreadSheetTable
        items={items}
        columns={spreadsheetConfig}
        saving={saving}
        fetchingData={loading}
        height="calc(100vh - 500px)"
        rowNumber={30}
        customPrefillFunctions={[
          prefillExtraOptions,
          prefillStock,
          prefillNewStock,
          prefillUomChange,
          updateExtPriceDependence,
        ]}
      />
      <Instructions
        saveInstruction={saveInstructions}
        title={intl.$t({ id: "NOTES_AND_DOCUMENTS" })}
      />
      <InventoryAdjustmentFooter />
    </Container>
  );
};

export const InventoryAdjustment = () => {
  const spreadsheetConfig = useInventoryAdjustmentConfig();
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <InventoryAdjustmentForm>
        <InventoryAdjustmentWithProvider />
      </InventoryAdjustmentForm>
    </ColumnMapperProvider>
  );
};

import { useCallback } from "react";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";

export const useUomRenderer = () => {
  const baseRenderer = useBaseRenderer();
  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      baseRenderer(instance, td, row, col, prop, value, cellProperties);
    },
    [baseRenderer],
  );

  return renderer;
};

import Handsontable from "handsontable";
import { COLUMN_TYPE } from "../enums/columnType";
import { useSpreadSheetStore } from "../store/useSpreadSheetStore";
import { getPhysicalColumnIndex } from "./getPhysicalColumnIndex";

export const getCellValueWithPrefill = (
  hotInstance: Handsontable | null | undefined,
  row: number,
  column: COLUMN_TYPE,
): string => {
  if (!hotInstance) {
    return "";
  }
  const getState = useSpreadSheetStore.getState().getStateWithPrefillChanges;
  const updatedRows = getState(hotInstance.getData()) as Record<
    string,
    string | number
  >[];
  const columnIndex = getPhysicalColumnIndex(hotInstance, column);

  const cellValue = updatedRows[row][columnIndex];
  return cellValue == null || cellValue === undefined ? "" : String(cellValue);
};

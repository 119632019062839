import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useInvoiceStatsQuery } from "@/generated/graphql";
import { useCallback } from "react";
import { useInvoiceEmails } from "../../email-invoices/hooks/useInvoiceEmails";

export const useInvoiceStats = () => {
  const { canSeeInbox } = useInvoiceEmails({ skipQuery: true });
  const { data, error, loading, refetch } = useInvoiceStatsQuery({
    skip: !canSeeInbox,
  });

  const refetchInvoiceStats = useCallback(async () => {
    if (canSeeInbox) {
      await refetch();
    }
  }, [canSeeInbox, refetch]);

  useErrorEffect(error);

  return {
    loading,
    invoiceStats: data?.invoiceStats,
    refetch: refetchInvoiceStats,
  };
};

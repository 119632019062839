import { GroupTagPicker } from "@/common/components/groups/components/group-tag-picker/GroupTagPicker";
import { If } from "@/common/components/if/If";
import { InfoHeaderItemTitle } from "@/common/components/info-header/components/InfoHeaderItem.styles";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

const Container = tw.div`grid grid-flow-row gap-1 relative`;

const SmallInfoHeaderItemTitle = tw(
  InfoHeaderItemTitle,
)`text-xs absolute -top-2 text-gray-600 font-normal`;

type Props = {
  triggerMutation?: boolean;
  includeLabel?: boolean;
  classes?: {
    label?: string;
  };
};

export const ReleaseGroups: FC<Props> = ({
  includeLabel,
  triggerMutation = false,
  classes,
}) => {
  const { releaseGroups } = useStartupDataStore(
    useShallow((state) => ({ releaseGroups: state.releaseGroups })),
  );
  const { release } = useRelease();
  const { updateRelease } = useReleaseUpdate();
  const [updating, setUpdating] = useState(false);

  const onRemove = async (group: { id: string }) => {
    if (!release) {
      return;
    }
    setUpdating(true);
    try {
      await updateRelease(
        {
          releaseId: release.id,
          version: release.version,
          groupIds: release.groups
            .filter((existingGroup) => existingGroup.id !== group.id)
            .map((g) => g.id),
          skipConfirmation: true,
          skipVendorNotification: true,
        },
        { batch: !triggerMutation },
      );
    } finally {
      setUpdating(false);
    }
  };

  const onAdd = async (selectedFolderId: string | null) => {
    if (!release || !selectedFolderId) {
      return;
    }
    setUpdating(true);
    try {
      await updateRelease(
        {
          releaseId: release.id,
          version: release.version,
          groupIds: [
            ...(release.groups || []).map((g) => g.id),
            selectedFolderId,
          ],
          skipConfirmation: true,
          skipVendorNotification: true,
        },
        { batch: !triggerMutation },
      );
    } finally {
      setUpdating(false);
    }
  };

  if (!release) {
    return null;
  }

  return (
    <Container>
      <If isTrue={includeLabel}>
        <SmallInfoHeaderItemTitle className={classes?.label}>
          <FormattedMessage id="ORDER_GROUPS" />
        </SmallInfoHeaderItemTitle>
      </If>
      <GroupTagPicker
        groups={releaseGroups}
        existingGroups={release.groups}
        moreTagCount={1}
        onRemove={onRemove}
        onAdd={onAdd}
        loading={updating}
        placeholders={{ add: "ADD_TO_RELEASE_GROUP" }}
        hideAddActionIfNoGroups={false}
      />
    </Container>
  );
};

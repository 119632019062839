import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { FormattedMessage } from "react-intl";
import { SelectWarehouseDialog } from "../select-warehouse-dialog/SelectWarehouseDialog";
import { useAddAdjustment } from "./useAddAdjustment";

export const AddAdjustmentButton = () => {
  const { handleAdjustment, handleConfirm, open, setOpen } = useAddAdjustment();

  return (
    <>
      <OutlinedButton onClick={handleAdjustment}>
        <FormattedMessage id="ADJUSTMENT" />
      </OutlinedButton>
      <SelectWarehouseDialog
        open={open}
        onClose={() => setOpen(false)}
        labelKey="SELECT_WAREHOUSE"
        handleConfirm={handleConfirm}
      />
    </>
  );
};

import { PricePickerWithUom } from "@/common/components/price-picker/PricePickerWithUom";
import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { useUser } from "@/common/providers/UserProvider";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { DeliverySlipStatus } from "@/generated/graphql";
import { CheckCircleOutline } from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlipRelease } from "../../../../providers/DeliverySlipReleaseProvider";
import { useDeliverySlipVerification } from "../../../../providers/DeliverySlipVerificationProvider";

const Container = tw.div`relative`;
const CheckIcon = tw(
  CheckCircleOutline,
)`text-green-600 absolute left-1 top-1/2 transform -translate-y-1/2 text-lg`;

type Props = {
  item: ExpandedReleaseItem;
  receivedQuantity: string;
  slipItemQuantity: string;
};

export const DeliverySlipReceivedQuantityInput = ({
  item,
  receivedQuantity,
  slipItemQuantity,
}: Props) => {
  const { currency } = useUser();
  const { release } = useRelease();
  const [received, setReceived] = useState<string | undefined | null>(
    receivedQuantity,
  );
  const { deliverySlip } = useDeliverySlipVerification();
  const { updateReceivedItemQuantity } = useDeliverySlipRelease();

  const saveQuantity = useCallback(
    (receivedQuantity: string | null) => {
      if (item.id) {
        updateReceivedItemQuantity(item.id, receivedQuantity || "0");
        setReceived(receivedQuantity);
      }
    },
    [item.id, updateReceivedItemQuantity],
  );

  const isFullyReceived = useMemo(() => {
    return new DecimalSafe(item.quantityDecimal)
      .sub(item.receivedQuantityDecimal || 0)
      .equals(
        new DecimalSafe(received || 0).sub(
          deliverySlip?.status === DeliverySlipStatus.Processed
            ? slipItemQuantity || 0
            : 0,
        ),
      );
  }, [
    item.quantityDecimal,
    item.receivedQuantityDecimal,
    received,
    deliverySlip?.status,
    slipItemQuantity,
  ]);

  const inputClassName = useMemo(() => {
    if (
      release &&
      new DecimalSafe(item.quantityDecimal)
        .sub(item.receivedQuantityDecimal || 0)
        .lessThan(
          new DecimalSafe(received || 0).sub(
            deliverySlip?.status === DeliverySlipStatus.Processed
              ? slipItemQuantity || 0
              : 0,
          ),
        )
    ) {
      return "text-red-500 bg-white relative";
    }
    return "text-blue-800 bg-white relative";
  }, [
    release,
    item.quantityDecimal,
    item.receivedQuantityDecimal,
    received,
    deliverySlip?.status,
    slipItemQuantity,
  ]);

  const remainingQuantity = useMemo(() => {
    return Math.max(
      0,
      new DecimalSafe(item.quantityDecimal)
        .sub(item.receivedQuantityDecimal || 0)
        .toNumber(),
    );
  }, [item.quantityDecimal, item.receivedQuantityDecimal]);

  if (isLumpSumItem(item)) {
    return (
      <PricePickerWithUom
        unitPrice={received}
        onChange={saveQuantity}
        uom={item.uom}
        label={currency?.symbol}
        readonly={false}
      />
    );
  }

  return (
    <Container>
      <QuantityPicker
        quantity={received}
        id={item.id}
        testId={`received-quantity-${item.id}`}
        saveQuantity={saveQuantity}
        error={new DecimalSafe(item.quantityDecimal).lessThanOrEqualTo(0)}
        hideErrorIcon
        className={inputClassName}
        helperText={
          <FormattedMessage
            id="QUANTITY_LEFT"
            values={{ quantity: remainingQuantity }}
          />
        }
      />
      {isFullyReceived && Number(received) > 0 && <CheckIcon />}
    </Container>
  );
};

import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { SupplierCommonFields, SupplierVendor } from "../../types/Supplier";
import { vendorLabelFormatter } from "../../utils/vendorLabelFormatter";

const VendorItem = tw.div`
    w-full py-[6px] grid grid-flow-col gap-x-2 justify-start items-center
  `;
const Separator = tw.span`mx-1`;
const VendorOrg = tw.span``;
const VendorContacts = tw.span`text-xs relative bottom-[1px]`;

type Type = OrgPreferredVendorsFieldsFragment | SupplierCommonFields;

export const VendorPickerCustomRender = <T extends Type>(
  item: T,
  opts?: {
    filter?: (contact: SupplierVendor["contacts"][0]) => boolean;
    shouldShowVendorCode?: boolean;
    shouldShowContacts?: boolean;
  },
) => {
  const vendor = item as OrgPreferredVendorsFieldsFragment;
  const sellerOrgLocation = vendor?.sellerOrgLocation;
  const contacts = vendor?.contacts;
  const vendorCode = vendor?.externalCode;
  const vendorName = (item as SupplierCommonFields)?.name;

  return (
    <VendorItem>
      {sellerOrgLocation ? (
        <>
          <OrgLogo
            logoImageUrl={sellerOrgLocation?.org?.photoUrl}
            name={sellerOrgLocation?.org.id ? sellerOrgLocation?.org?.name : ""}
            width={24}
          />
          <div>
            <VendorOrg>
              {vendorLabelFormatter(sellerOrgLocation, [], {
                vendorCode: opts?.shouldShowVendorCode ? vendorCode : null,
              })}
            </VendorOrg>
            {opts?.filter &&
            contacts?.length > 0 &&
            (opts?.shouldShowContacts ?? true) ? (
              <>
                <Separator>-</Separator>
                <VendorContacts>
                  {contacts
                    .filter(opts.filter)
                    .map((c) => c.name)
                    .join(", ")}
                </VendorContacts>
              </>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <OrgLogo name={" "} width={24} />
          {vendorName ?? null}
        </>
      )}
    </VendorItem>
  );
};

import {
  TOOLTIP_EVENT_NAME,
  TooltipEvent,
} from "@/common/providers/TooltipProvider";

export const applyTooltip = (element: HTMLElement, tooltipText: string) => {
  element.onmouseover = (event: Event) => {
    window.dispatchEvent(
      new CustomEvent(TOOLTIP_EVENT_NAME, {
        detail: {
          content: tooltipText,
          event,
        },
      } as TooltipEvent),
    );
  };

  element.onmouseout = (event: Event) => {
    window.dispatchEvent(
      new CustomEvent(TOOLTIP_EVENT_NAME, {
        detail: {
          content: "",
          event,
        },
      } as TooltipEvent),
    );
  };
};

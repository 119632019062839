import { useMemo } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import {
  TotalProps,
  usePriceCalculation,
} from "../../../../../common/hooks/usePriceCalculation";
import { useIsWarehouseRelease } from "./useIsWarehouseRelease";

type Props = {
  form?: UseFormReturn<TotalProps, unknown, TotalProps>;
};

export const useFormPriceCalculation = (props?: Props) => {
  const { watch: defaultWatch } = useFormContext<TotalProps>();
  const watch = props?.form?.watch || defaultWatch;
  const { calcTotalPrice } = usePriceCalculation();
  const { isWarehouseRelease } = useIsWarehouseRelease();
  const [
    netAmount,
    customTaxAmount,
    taxCodeId,
    taxRate,
    orderTypeId,
    additionalCharges,
    taxVariance,
    taxableNetAmount,
  ] = watch([
    "netAmount",
    "customTaxAmount",
    "taxCodeId",
    "taxRate",
    "orderTypeId",
    "additionalCharges",
    "taxVariance",
    "taxableNetAmount",
  ]);

  const total = useMemo(
    () =>
      calcTotalPrice({
        netAmount,
        customTaxAmount: !isWarehouseRelease ? customTaxAmount : undefined,
        taxCodeId: !isWarehouseRelease ? taxCodeId : undefined,
        taxRate: !isWarehouseRelease ? taxRate : undefined,
        orderTypeId,
        additionalCharges: !isWarehouseRelease ? additionalCharges : undefined,
        taxVariance: !isWarehouseRelease ? taxVariance : undefined,
        taxableNetAmount: !isWarehouseRelease ? taxableNetAmount : undefined,
      }),
    [
      calcTotalPrice,
      netAmount,
      isWarehouseRelease,
      customTaxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
      additionalCharges,
      taxVariance,
      taxableNetAmount,
    ],
  );

  return {
    total,
  };
};

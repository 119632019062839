import { Select } from "@/common/components/select/components/single/Select";
import { usePaymentMethods } from "@/contractor/pages/admin/cost-structure/pages/payment-methods/hooks/usePaymentMethods";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

type Props = {
  paymentMethodId: string | undefined;
  setPaymentMethodId: (id: string | null) => void;
  readonly?: boolean;
  includeInputLabels?: boolean;
};

export const InvoicePaymentMethod: FC<Props> = ({
  paymentMethodId,
  setPaymentMethodId,
  readonly = false,
  includeInputLabels = false,
}) => {
  const intl = useIntl();
  const { paymentMethods, loading: loadingPaymentMethods } =
    usePaymentMethods();

  const paymentMethodsOptions = useMemo(() => {
    return [
      ...paymentMethods.map((paymentMethod) => ({
        id: paymentMethod.id,
        label: paymentMethod.description,
      })),
    ];
  }, [paymentMethods]);

  const handleValueChange = useCallback(
    (value: string | null) => {
      setPaymentMethodId(!value ? null : value);
    },
    [setPaymentMethodId],
  );

  return (
    <Select
      options={paymentMethodsOptions}
      loading={loadingPaymentMethods}
      getValue={(paymentMethod) => paymentMethod.id}
      getLabel={(paymentMethod) => paymentMethod.label}
      value={paymentMethodId}
      onChange={handleValueChange}
      disabled={readonly}
      disableClearable={!paymentMethodId}
      className="w-full"
      label={includeInputLabels ? intl.$t({ id: "PAYMENT_METHOD" }) : ""}
    />
  );
};

import { useFormattedSalesTax } from "@/common/components/sales-tax-input/hooks/useFormattedSalesTax";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { useUnspecifiedPhaseCode } from "@/common/hooks/useUnspecifiedPhaseCode";
import { getCostCodesByBuyoutItems } from "@/common/utils/cost-codes-and-zones/getCostCodesByBuyoutItems";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useBuyoutItemsGrouping } from "@/common/utils/hooks/useBuyoutItemsGrouping";
import { readValue, setValue } from "@/common/utils/localStorage";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  BuyoutFieldsFragment,
  BuyoutItemFieldsFragment,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import Decimal from "decimal.js";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CategoryState,
  useToggleCategory,
} from "../../../../../../../common/hooks/useToggleCategory";
import { useUnspecifiedCostCode } from "../../../../../../../common/hooks/useUnspecifiedCostCode";
import { BuyoutStandaloneReleaseItemFieldsFragmentWithId } from "../../common/buyout-standalone-release-items-list/BuyoutStandaloneReleaseItemsList";
import { useBuyoutItemsFilters } from "./hooks/useBuyoutItemsFilters";

type CostCodeCategory = CategoryState<BuyoutItemFieldsFragment>;

type ContextType = {
  costCodes: CostCodeCategory[];
  toggleCategory: (name: string) => void;
  groupedByCostCode: boolean;
  setGroupedByCostCode: (grouped: boolean) => void;

  groupedStandaloneItemsByCostCode: boolean;
  setGroupedStandaloneItemsByCostCode: (grouped: boolean) => void;

  orderedSubtotal: Decimal;
  orderedSalesTax: Decimal;
  orderedTotal: Decimal;
  receivedSubtotal: Decimal;
  receivedSalesTax: Decimal;
  receivedTotal: Decimal;
  netAmount: Decimal;
  salesTax: string | null | undefined;
  total: Decimal;
  receivedStandaloneSubtotal: Decimal;
  receivedStandaloneSalesTax: Decimal;
  receivedStandaloneTotal: Decimal;
  orderedStandaloneSubtotal: Decimal;
  orderedStandaloneSalesTax: Decimal;
  orderedStandaloneTotal: Decimal;
  standaloneItems: CategoryState<BuyoutStandaloneReleaseItemFieldsFragmentWithId>[];
  toggleStandaloneCategory: (name: string) => void;
  filteredItems: BuyoutItemFieldsFragment[];
};

const Context = createContext<ContextType>({
  costCodes: [],
  toggleCategory: NoFunction,
  groupedByCostCode: false,
  setGroupedByCostCode: NoFunction,
  groupedStandaloneItemsByCostCode: false,
  setGroupedStandaloneItemsByCostCode: NoFunction,
  orderedSubtotal: new DecimalSafe(0),
  orderedSalesTax: new DecimalSafe(0),
  orderedTotal: new DecimalSafe(0),
  receivedSubtotal: new DecimalSafe(0),
  receivedSalesTax: new DecimalSafe(0),
  receivedTotal: new DecimalSafe(0),
  netAmount: new DecimalSafe(0),
  salesTax: "",
  total: new DecimalSafe(0),
  receivedStandaloneSubtotal: new DecimalSafe(0),
  receivedStandaloneSalesTax: new DecimalSafe(0),
  receivedStandaloneTotal: new DecimalSafe(0),
  orderedStandaloneSubtotal: new DecimalSafe(0),
  orderedStandaloneSalesTax: new DecimalSafe(0),
  orderedStandaloneTotal: new DecimalSafe(0),
  standaloneItems: [],
  toggleStandaloneCategory: NoFunction,
  filteredItems: [],
});

export const BuyoutGroupedProvider: FC<
  PropsWithChildren<{ buyout: BuyoutFieldsFragment }>
> = ({ children, buyout }) => {
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const [costCodes, setCostCodes] = useState<CostCodeCategory[]>([]);
  const [standaloneItems, setStandaloneItems] = useState<
    CategoryState<BuyoutStandaloneReleaseItemFieldsFragmentWithId>[]
  >([]);
  const initialValue = readValue<boolean>(
    LOCAL_STORAGE_KEYS.GROUPED_BY_COST_CODE,
    !buyout.quoteDocument,
  );
  const [groupedByCostCode, setGroupedByCostCode] = useState(
    Boolean(initialValue),
  );
  const [
    groupedStandaloneItemsByCostCode,
    setGroupedStandaloneItemsByCostCode,
  ] = useState(Boolean(initialValue));
  const { calcExtPrice } = usePriceCalculation();
  const { toggleCategory } = useToggleCategory(costCodes, setCostCodes);
  const { toggleCategory: toggleStandaloneCategory } = useToggleCategory(
    standaloneItems,
    setStandaloneItems,
  );
  const { unassignedPhaseCode } = useUnspecifiedPhaseCode();
  const { hasPhaseCodes } = useOrgSettings();
  const {
    filterFullyReceived,
    filterExcludedFullyOrdered,
    filterSelectedCostCodes,
    filterSelectedTags,
  } = useBuyoutItemsFilters();
  const { formattedSalesTax } = useFormattedSalesTax({
    params: {
      salesTaxInput: {
        customTaxAmount: buyout.customTaxAmount,
        taxRate: buyout.taxRate,
        taxAmount: buyout.taxAmount,
        netAmount: buyout.netAmount,
        orderTypeId: buyout.releaseType?.id,
        taxCodeId: buyout.taxCode?.id,
        taxType: buyout.taxType,
        chargesAmount: buyout.additionalChargesAllowance,
      },
    },
    options: {},
  });

  const items = useMemo(
    () =>
      buyout.items
        .filter(filterFullyReceived)
        .filter(filterExcludedFullyOrdered)
        .filter(filterSelectedCostCodes)
        .filter(filterSelectedTags),
    [
      buyout.items,
      filterExcludedFullyOrdered,
      filterFullyReceived,
      filterSelectedCostCodes,
      filterSelectedTags,
    ],
  );
  const { filterBasedOnCostCodeAndPhaseCode, getProjectCodes } =
    useBuyoutItemsGrouping({
      items,
    });

  useEffect(() => {
    const projectCostCodes = getProjectCodes();

    const mappedCost: CostCodeCategory[] = projectCostCodes.map((costCode) => {
      const newCostCode = {
        id: costCode.id,
        name: costCode.description,
        isOpened: true,
      };
      return {
        ...newCostCode,
        items: buyout.items
          .filter(filterFullyReceived)
          .filter(filterExcludedFullyOrdered)
          .filter((i) => filterBasedOnCostCodeAndPhaseCode(i, costCode)),
      };
    });

    setCostCodes(mappedCost);

    const standaloneCostCodes = hasPhaseCodes
      ? [
          {
            id: unassignedPhaseCode.id,
            description: unassignedPhaseCode.description,
          },
        ]
      : getCostCodesByBuyoutItems(
          buyout?.standaloneReleaseItems || [],
          unassignedCostCode,
        );

    const mappedStandaloneCostCodes = standaloneCostCodes.map((costCode) => {
      const newCostCode = {
        id: costCode.id,
        name: costCode.description,
        isOpened: true,
      };
      return {
        ...newCostCode,
        items:
          buyout?.standaloneReleaseItems
            .filter(
              (i) =>
                hasPhaseCodes ||
                i.costCode?.id === costCode.id ||
                (!i.costCode && costCode.id === unassignedCostCode.id),
            )
            .map((i, key) => ({
              ...i,
              id: key.toString(),
            })) || [],
      };
    });

    setStandaloneItems(mappedStandaloneCostCodes);
  }, [
    buyout.items,
    unassignedCostCode,
    filterFullyReceived,
    buyout?.standaloneReleaseItems,
    filterExcludedFullyOrdered,
    filterSelectedCostCodes,
    getProjectCodes,
    filterBasedOnCostCodeAndPhaseCode,
    hasPhaseCodes,
    unassignedPhaseCode.id,
    unassignedPhaseCode.description,
  ]);

  const setGroupedByCostCodeAndUpdateLocalStorage = useCallback(
    (grouped: boolean) => {
      setGroupedByCostCode(grouped);
      setValue(LOCAL_STORAGE_KEYS.GROUPED_BY_COST_CODE, grouped);
    },
    [],
  );

  const customTaxAmount = useMemo(
    () =>
      new DecimalSafe(buyout?.customTaxAmount || 0).div(
        new DecimalSafe(buyout.netAmount).add(
          buyout.additionalChargesAllowance || 0,
        ),
      ),
    [
      buyout.customTaxAmount,
      buyout.netAmount,
      buyout.additionalChargesAllowance,
    ],
  );

  const filteredItems = useMemo(
    () =>
      buyout.items
        .filter(filterFullyReceived)
        .filter((item) => item.isIncluded),
    [buyout.items, filterFullyReceived],
  );

  const netAmount = useMemo(
    () =>
      filteredItems.reduce(
        (acc, i) => acc.add(calcExtPrice(i.quantityDecimal, i.unitPrice)),
        new DecimalSafe(buyout.additionalChargesAllowance || 0).toDP(2),
      ),
    [filteredItems, buyout.additionalChargesAllowance, calcExtPrice],
  );

  const salesTax = useMemo(
    () =>
      buyout.customTaxAmount
        ? customTaxAmount.mul(netAmount).toDP(2)
        : buyout.taxRate
          ? netAmount.mul(buyout.taxRate).toDP(2)
          : new DecimalSafe(buyout.taxAmount || 0).toDP(2),
    [
      buyout.customTaxAmount,
      buyout.taxRate,
      buyout.taxAmount,
      customTaxAmount,
      netAmount,
    ],
  );

  const total = useMemo(
    () => netAmount.add(salesTax || 0),
    [netAmount, salesTax],
  );

  const allowance = useMemo(
    () =>
      filteredItems.some((item) =>
        new DecimalSafe(item.releasedAmount || 0).toNumber(),
      )
        ? buyout.additionalChargesAllowance || 0
        : 0,
    [buyout.additionalChargesAllowance, filteredItems],
  );

  const orderedSubtotal = useMemo(
    () =>
      filteredItems.reduce(
        (acc, i) => acc.add(i.releasedAmount || 0),
        new DecimalSafe(allowance).toDP(2),
      ),
    [filteredItems, allowance],
  );

  const orderedSalesTax = useMemo(
    () =>
      buyout.customTaxAmount
        ? customTaxAmount.mul(orderedSubtotal).toDP(2)
        : buyout.taxRate
          ? orderedSubtotal.mul(buyout.taxRate).toDP(2)
          : new DecimalSafe(buyout.taxAmount || 0).toDP(2),
    [
      buyout.customTaxAmount,
      buyout.taxRate,
      buyout.taxAmount,
      customTaxAmount,
      orderedSubtotal,
    ],
  );

  const orderedTotal = useMemo(
    () => orderedSubtotal.add(orderedSalesTax || 0),
    [orderedSubtotal, orderedSalesTax],
  );

  const receivedSubtotal = useMemo(
    () =>
      filteredItems.reduce(
        (acc, i) => acc.add(new DecimalSafe(i.completedAmount || 0)),
        new DecimalSafe(allowance).toDP(2),
      ),
    [allowance, filteredItems],
  );

  const receivedSalesTax = useMemo(
    () =>
      buyout.customTaxAmount
        ? customTaxAmount.mul(receivedSubtotal).toDP(2)
        : buyout.taxRate
          ? receivedSubtotal.mul(buyout.taxRate).toDP(2)
          : new DecimalSafe(buyout.taxAmount || 0).toDP(2),
    [
      buyout.customTaxAmount,
      buyout.taxRate,
      buyout.taxAmount,
      customTaxAmount,
      receivedSubtotal,
    ],
  );

  const receivedTotal = useMemo(
    () => receivedSubtotal.add(receivedSalesTax || 0),
    [receivedSubtotal, receivedSalesTax],
  );

  const receivedStandaloneSubtotal = useMemo(
    () =>
      buyout.standaloneReleaseItems.reduce(
        (acc, i) => acc.add(new DecimalSafe(i.receivedSoFar || 0)),
        new DecimalSafe(0),
      ),
    [buyout.standaloneReleaseItems],
  );

  const receivedStandaloneSalesTax = useMemo(
    () =>
      buyout.taxRate
        ? receivedStandaloneSubtotal.mul(buyout.taxRate).toDP(2)
        : new DecimalSafe(buyout.taxAmount || 0).toDP(2),
    [buyout.taxRate, buyout.taxAmount, receivedStandaloneSubtotal],
  );

  const receivedStandaloneTotal = useMemo(
    () => receivedStandaloneSubtotal.add(receivedStandaloneSalesTax || 0),
    [receivedStandaloneSubtotal, receivedStandaloneSalesTax],
  );

  const orderedStandaloneSubtotal = useMemo(
    () =>
      buyout.standaloneReleaseItems.reduce(
        (acc, i) => acc.add(new DecimalSafe(i.orderedSoFar || 0)),
        new DecimalSafe(0),
      ),
    [buyout.standaloneReleaseItems],
  );

  const orderedStandaloneSalesTax = useMemo(
    () =>
      buyout.taxRate
        ? orderedStandaloneSubtotal.mul(buyout.taxRate).toDP(2)
        : new DecimalSafe(buyout.taxAmount || 0).toDP(2),
    [buyout.taxRate, buyout.taxAmount, orderedStandaloneSubtotal],
  );

  const orderedStandaloneTotal = useMemo(
    () => orderedStandaloneSubtotal.add(orderedStandaloneSalesTax || 0),
    [orderedStandaloneSubtotal, orderedStandaloneSalesTax],
  );

  return (
    <Context.Provider
      value={{
        costCodes,
        toggleCategory,
        groupedByCostCode,
        setGroupedByCostCode: setGroupedByCostCodeAndUpdateLocalStorage,
        groupedStandaloneItemsByCostCode,
        setGroupedStandaloneItemsByCostCode,
        orderedSubtotal,
        orderedSalesTax,
        orderedTotal,
        receivedSubtotal,
        receivedSalesTax,
        receivedTotal,
        netAmount,
        salesTax: formattedSalesTax,
        total,
        receivedStandaloneSubtotal,
        receivedStandaloneSalesTax,
        receivedStandaloneTotal,
        orderedStandaloneSubtotal,
        orderedStandaloneSalesTax,
        orderedStandaloneTotal,
        standaloneItems,
        toggleStandaloneCategory,
        filteredItems: items,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useBuyoutGrouped = () => useContext(Context);

import { useCallback, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { ApproverTemplateVariablesEnum } from "../../../enums/ApproverTemplateVariables";
import { BuyoutTemplateVariablesEnum } from "../../../enums/BuyoutTemplateVariables";
import { ExternalVendorTemplateVariablesEnum } from "../../../enums/ExternalVendorTemplateVariables";
import { InvoiceTemplateVariablesEnum } from "../../../enums/InvoiceTemplateVariables";
import { ProjectTemplateVariablesEnum } from "../../../enums/ProjectTemplateVariables";
import { ReleaseTemplateVariablesEnum } from "../../../enums/ReleaseTemplateVariables";
import { TemplateBaseVarEnum } from "../../../enums/TemplateBaseVars.enum";
import { TemplateTypeEnum } from "../../../enums/TemplateType.enum";
import { useTemplateStringsStore } from "../../../store/useTemplateStringsStore";

type UseTemplateCustomVariablesProps = {
  includeConditional: boolean;
  templateType: TemplateTypeEnum;
};

export const useTemplateCustomVariables = ({
  includeConditional,
  templateType,
}: UseTemplateCustomVariablesProps) => {
  const { updateCustomTemplateDefaults, customTemplateDefaults } =
    useTemplateStringsStore(
      useShallow((state) => ({
        updateCustomTemplateDefaults: state.updateCustomTemplateDefaults,
        customTemplateDefaults: state.customTemplateDefaults,
      })),
    );

  const getVariableTemplate = useCallback(
    (type: string, key: string): string => {
      const varPath = `.${type}.${key}`;
      return includeConditional
        ? `{{if ${varPath}}}\n  {{${varPath}}}\n{{end}}`
        : `{{${varPath}}}`;
    },
    [includeConditional],
  );

  const formatVariables = useCallback(
    (type: string, variables: Record<string, string>) => {
      const formattedType = type.charAt(0).toUpperCase() + type.slice(1);
      return Object.keys(variables).map((key) => {
        const fullKey = `${formattedType}.${key}`;
        return {
          label: `.${fullKey}`,
          value: fullKey,
          template: getVariableTemplate(formattedType, key),
        };
      });
    },
    [getVariableTemplate],
  );

  const formattedVariables = useMemo(() => {
    const commonVariables = [
      formatVariables(
        TemplateBaseVarEnum.PROJECT,
        ProjectTemplateVariablesEnum,
      ),
      formatVariables(
        TemplateBaseVarEnum.RELEASE,
        ReleaseTemplateVariablesEnum,
      ),
      formatVariables(TemplateBaseVarEnum.BUYOUT, BuyoutTemplateVariablesEnum),
      formatVariables(
        TemplateBaseVarEnum.EXTERNAL_VENDOR,
        ExternalVendorTemplateVariablesEnum,
      ),
    ];
    const allVariables =
      templateType === TemplateTypeEnum.PO
        ? commonVariables
        : [
            ...commonVariables,
            formatVariables(
              TemplateBaseVarEnum.INVOICE,
              InvoiceTemplateVariablesEnum,
            ),
            formatVariables(
              TemplateBaseVarEnum.APPROVER,
              ApproverTemplateVariablesEnum,
            ),
          ];
    return allVariables.flat();
  }, [formatVariables, templateType]);

  const prefixDefaults = useCallback(
    (type: string, obj: Record<string, string>) => {
      const formattedType = type.charAt(0).toUpperCase() + type.slice(1);
      return Object.keys(obj).reduce<Record<string, string>>((acc, key) => {
        const fullKey = `${formattedType}.${key}`;
        return { ...acc, [fullKey]: obj[key] };
      }, {});
    },
    [],
  );

  const handleDefaultValueChange = useCallback(
    (key: string, value: string) => {
      const [group, prop] = key.split(".");
      const groupKey = (group.charAt(0).toLowerCase() +
        group.slice(1)) as TemplateBaseVarEnum;
      const newGroupedDefaults = {
        ...customTemplateDefaults,
      };
      newGroupedDefaults[groupKey] = (newGroupedDefaults[groupKey] || []).map(
        (item) => (item.name === prop ? { ...item, value } : item),
      );
      updateCustomTemplateDefaults(newGroupedDefaults);
    },
    [customTemplateDefaults, updateCustomTemplateDefaults],
  );

  return { formattedVariables, prefixDefaults, handleDefaultValueChange };
};

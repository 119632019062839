import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  NestedWizardModalPage,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { TemplateNameEnum } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/template-strings/enums/TemplateName.enum";
import { TemplateTypeEnum } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/template-strings/enums/TemplateType.enum";
import { useTemplateStringsStore } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/template-strings/store/useTemplateStringsStore";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { UpdateSourceSystemsSettingsInput } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useAgaveHostedWizard } from "../../../AgaveHostedWizardProvider";
import { TemplateStrings } from "../../../steps/template-strings/TemplateStrings";
export const useTemplateStringsPage = (): WizardModalPage => {
  const intl = useIntl();
  const { moveToPreviousStep, moveToNextStep } = useNestedStepper();
  const { handleSaveWizardData } = useAgaveHostedWizard();
  const { templates, setTemplates, customPOTemplates, customInvoiceTemplates } =
    useTemplateStringsStore(
      useShallow((state) => ({
        templates: state.templates,
        setTemplates: state.setTemplates,
        customPOTemplates: state.customPOTemplates,
        customInvoiceTemplates: state.customInvoiceTemplates,
      })),
    );
  const { connectedSourceSystem } = useOrgSettingsExtended();

  const templateMap = useMemo(() => {
    return templates.reduce(
      (acc, { name, template }) => {
        acc[name] = template;
        return acc;
      },
      {} as Record<string, string | undefined>,
    );
  }, [templates]);

  const onNextClick = useCallback(async () => {
    const settings: Omit<UpdateSourceSystemsSettingsInput, "system"> = {
      ...templateMap,
      customPOTemplates: customPOTemplates.map(({ name, template }) => ({
        name,
        template,
      })),
      customInvoiceTemplates: customInvoiceTemplates.map(
        ({ name, template }) => ({ name, template }),
      ),
    };
    await handleSaveWizardData(settings);
    moveToNextStep();
  }, [
    handleSaveWizardData,
    moveToNextStep,
    templateMap,
    customPOTemplates,
    customInvoiceTemplates,
  ]);
  return useMemo(() => {
    const pages: NestedWizardModalPage[] = [
      {
        component: <TemplateStrings />,
        onPreviousClick: moveToPreviousStep,
        onNextClick,
        onCloseClick: () => {
          setTemplates([
            {
              name: TemplateNameEnum.PoNameTemplate,
              template: connectedSourceSystem?.poNameTemplate ?? "",
              type: TemplateTypeEnum.PO,
            },
            {
              name: TemplateNameEnum.PoDescriptionTemplate,
              template: connectedSourceSystem?.poDescriptionTemplate ?? "",
              type: TemplateTypeEnum.PO,
            },
            {
              name: TemplateNameEnum.InvoiceDescriptionTemplate,
              template: connectedSourceSystem?.invoiceDescriptionTemplate ?? "",
              type: TemplateTypeEnum.INVOICE,
            },
          ]);
        },
      },
    ];
    return {
      feature: IntegrationFeature.TemplateStringsStep,
      title: intl.$t({ id: "TEMPLATE_STRINGS" }),
      completed:
        connectedSourceSystem?.poNameTemplate !== null &&
        connectedSourceSystem?.poDescriptionTemplate !== null &&
        connectedSourceSystem?.invoiceDescriptionTemplate !== null,
      pages,
    };
  }, [
    moveToPreviousStep,
    onNextClick,
    intl,
    connectedSourceSystem?.poNameTemplate,
    connectedSourceSystem?.poDescriptionTemplate,
    connectedSourceSystem?.invoiceDescriptionTemplate,
    setTemplates,
  ]);
};

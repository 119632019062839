import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useSequence } from "@/common/hooks/useSequence";
import { routes } from "@/config/routes";
import { Identity } from "@/types/Identity";
import { useCallback } from "react";
import { generatePath } from "react-router";
import {
  getSelectedItemIdParts,
  UNDEFINED_WAREHOUSE_ID,
} from "../../../../utils/getSelectedItemIdParts";
import { useInventoryItemSequenceStore } from "../../store/useInventoryItemSequenceStore";

export const useInventoryItemSequence = () => {
  const extractSequenceIds = useCallback((ids: string[]) => {
    const uniqueWarehouseIds = new Set<string>();

    ids.forEach((id) => {
      const { warehouseId } = getSelectedItemIdParts(id);
      if (warehouseId && warehouseId.trim() !== "") {
        uniqueWarehouseIds.add(warehouseId);
      }
    });

    return Array.from(uniqueWarehouseIds).sort((a, b) =>
      a === UNDEFINED_WAREHOUSE_ID ? 1 : b === UNDEFINED_WAREHOUSE_ID ? -1 : 0,
    );
  }, []);

  return useSequence<Identity>({
    listPath: routes.currentStock,
    localStorageConfig: LOCAL_STORAGE_KEYS.INVENTORY_ITEM_SEQUENCES,
    useStore: useInventoryItemSequenceStore,
    extractSequenceIds,
    singEntityPath: () => generatePath(routes.inventoryAdjustment),
    getSearchParams: (id: string) => `?warehouseId=${id}`,
  });
};

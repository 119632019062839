import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { AssetFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";

export const useGlobalReleaseInstructions = () => {
  const { settings } = useOrgSettings();
  const releaseInstructions = useProjectStore(
    (state) => state.releaseInstructions,
  );

  const instructionText = useMemo(() => {
    const textArray: string[] = [];
    if (releaseInstructions?.text) {
      textArray.push(releaseInstructions.text);
    }
    if (settings?.releases?.instructions?.text) {
      textArray.push(settings.releases.instructions.text);
    }
    return textArray.join("\n");
  }, [releaseInstructions?.text, settings?.releases?.instructions?.text]);

  const instructionAssets = useMemo(() => {
    const assetArray: AssetFieldsFragment[] = [];
    if (releaseInstructions?.assets) {
      assetArray.push(...releaseInstructions.assets);
    }
    if (settings?.releases?.instructions?.assets) {
      assetArray.push(...settings.releases.instructions.assets);
    }
    return assetArray;
  }, [releaseInstructions?.assets, settings?.releases?.instructions?.assets]);

  return { instructionText, instructionAssets };
};

import { If } from "@/common/components/if/If";
import { ImportExternalResultTitle } from "@/common/components/import-external-result-title/ImportExternalResultTitle";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportExternalComplianceGroups } from "../../../providers/ImportExternalComplianceGroupsProvider";
import { useImportExternalComplianceGroupsTableConfiguration } from "./ImportExternalComplianceGroupsTable.configuration";

const Container = tw.div`flex flex-1 self-stretch flex-col`;

export const ImportExternalComplianceGroupsImportResults = () => {
  const intl = useIntl();
  const configuration = useImportExternalComplianceGroupsTableConfiguration();
  const { importedComplianceGroups } = useImportExternalComplianceGroups();

  return (
    <Container>
      <If isTrue={(importedComplianceGroups?.created ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedComplianceGroups?.created ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "CREATED_COMPLIANCE_GROUPS_COUNT" },
                { count: (importedComplianceGroups?.created ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16"
        />
      </If>
      <If isTrue={(importedComplianceGroups?.mapped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedComplianceGroups?.mapped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "MATCHED_COMPLIANCE_GROUPS_COUNT" },
                { count: (importedComplianceGroups?.mapped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
      <If isTrue={(importedComplianceGroups?.skipped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedComplianceGroups?.skipped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "SKIPPED_COMPLIANCE_GROUPS_COUNT" },
                { count: (importedComplianceGroups?.skipped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
    </Container>
  );
};

import { useDialog } from "@/common/components/dialog/DialogProvider";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  SpreadsheetSaveType,
  useColumnMapper,
} from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { AddCostCodeInput, UpdateCostCodeInput } from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useUpdateCostCodes } from "../hooks/useUpdateCostCodes";

type ProviderContextType = {
  syncCostCodes: (trigger?: SpreadsheetSaveType) => Promise<boolean>;
};

type Props = {
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCostCodes: NoFunctionBooleanPromise,
});

export const SyncCostCodesProvider: FC<Props> = ({ children }) => {
  const intl = useIntl();
  const { projectCostCodes } = useProjectCostCodes();
  const { updateCostCodes } = useUpdateCostCodes();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const viewState = useTableViewStore((state) => state.viewState);
  const {
    spreadsheetData,
    resetPreviousData,
    rowHasChanges,
    gotoInvalidRow,
    getRemovedRowIds,
  } = useColumnMapper();
  const { setSuccessAlert } = useSnackbar();
  const { openDialog } = useDialog();

  const confirmRemove = (itemsToRemove: Array<unknown>) => {
    let resolveFn: (value: boolean | undefined) => void;
    const modalResult: Promise<boolean | undefined> = new Promise((resolve) => {
      resolveFn = resolve;
    });

    if (itemsToRemove.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        includeWarningIcon: true,
        title: <FormattedMessage id="WARNING_REMOVING_COST_CODES_TITLE" />,
        text: (
          <FormattedMessage
            id={
              itemsToRemove.length > 1
                ? "WARNING_REMOVING_COST_CODES"
                : projectCostCodes?.find((item) => item.id === itemsToRemove[0])
                      ?.inUse
                  ? "DELETE_COST_CODE_IN_USE_CONFIRMATION"
                  : "DELETE_COST_CODE_CONFIRMATION"
            }
            values={{
              number: itemsToRemove.length,
            }}
          />
        ),
        handleConfirm: () => {
          resolveFn(true);
        },
        handleCancel() {
          resolveFn(undefined);
        },
      });
      return modalResult;
    } else {
      return Promise.resolve(true);
    }
  };

  const syncCostCodes = async (trigger?: SpreadsheetSaveType) => {
    if (viewState !== TableViewState.spreadsheet) {
      return true;
    }

    if (
      !(await validateRequiredValues([
        COLUMN_TYPE.CodeDescription,
        COLUMN_TYPE.Code,
      ])) ||
      !(await validateRowValues([COLUMN_TYPE.Code]))
    ) {
      gotoInvalidRow();
      return false;
    }

    const itemsToUpdate: UpdateCostCodeInput[] = [];
    const newItems: AddCostCodeInput[] = [];
    const itemsToRemove = getRemovedRowIds(projectCostCodes ?? []);

    spreadsheetData.forEach((row) => {
      if (rowIsEmpty(row)) {
        if (row.id) {
          itemsToRemove.push(row.id);
        }
        return false;
      }
      const codeText = getCellValue(row, COLUMN_TYPE.Code);
      const descriptionText = getCellValue(row, COLUMN_TYPE.CodeDescription);

      const existingItem = projectCostCodes?.find((item) => item.id === row.id);

      if (existingItem) {
        if (rowHasChanges(row)) {
          itemsToUpdate.push({
            id: row.id,
            code: codeText,
            description: descriptionText,
          });
        }
      } else {
        newItems.push({
          code: codeText,
          description: descriptionText,
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    const canRemoveItems = await confirmRemove(itemsToRemove);
    if (!canRemoveItems) {
      return false;
    }

    if (
      itemsToUpdate.length > 0 ||
      newItems.length > 0 ||
      itemsToRemove.length > 0
    ) {
      const result = await updateCostCodes({
        addedCostCodes: newItems,
        removedCostCodes: itemsToRemove,
        updates: itemsToUpdate,
      });

      if (result) {
        resetPreviousData();
        setSuccessAlert(intl.$t({ id: "COST_CODE_SAVED_SUCCESS" }));
      }
      return !!result;
    } else if (trigger === SpreadsheetSaveType.SaveButton) {
      setSuccessAlert(intl.$t({ id: "NOTHING_TO_SAVE" }));
    }

    return true;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCostCodes,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSyncCostCodes = (): ProviderContextType =>
  useContext(ProviderContext);

import jsPDF from "jspdf";

const LOGO_SIZE = 45;

export const drawLogo = (
  doc: jsPDF,
  logo: string,
  logoProportion: number,
  x: number,
  y: number,
) => {
  let scaleX = 1;
  let scaleY = 1;
  if (logoProportion >= 1) {
    scaleY = 1 / logoProportion;
  } else {
    scaleX = logoProportion;
  }

  const xCoordinate = x + LOGO_SIZE / 2 - (LOGO_SIZE * scaleX) / 2;
  const yCoordinate = y - 5 + LOGO_SIZE / 2 - (LOGO_SIZE * scaleY) / 2;

  doc.addImage(
    logo,
    xCoordinate,
    yCoordinate,
    LOGO_SIZE * scaleX,
    LOGO_SIZE * scaleY,
  );
  return yCoordinate + LOGO_SIZE * scaleY;
};

import { FC } from "react";
import tw from "tailwind-styled-components";

const IconContainer = tw.div`
flex items-center justify-center w-28 h-28 text-7xl text-red-500 border-[10px] border-red-500 rounded-full mb-4 mt-6
`;
const ExclamationContainer = tw.div``;

type Props = {
  className?: string;
};

export const ErrorIcon: FC<Props> = ({ className }) => (
  <IconContainer className={className}>
    <ExclamationContainer>!</ExclamationContainer>
  </IconContainer>
);

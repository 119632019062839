import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { FC } from "react";
import { Multiselect } from "../select/components/multiple/Multiselect";
import { WarehouseSelectorCustomRenderer } from "./renderer/WarehouseSelectorCustomRenderer";

type Props = {
  values: string[] | null | undefined;
  onChange: (value: string[] | null) => void;
  readonly?: boolean;
  className?: string;
  limitTags?: number;
  placeholder?: string;
};

export const MultiWarehouseSelector: FC<Props> = ({
  values,
  onChange,
  readonly = false,
  className,
  limitTags,
  placeholder,
}) => {
  const { warehouses } = useWarehouses();

  return (
    <Multiselect
      className={className}
      options={warehouses}
      placeholder={placeholder}
      values={values}
      onMultipleChange={onChange}
      getLabel={(option) => option.name}
      getValue={(option) => option.id}
      customRender={WarehouseSelectorCustomRenderer}
      includeCheckbox
      disableCloseOnSelect
      disabled={readonly}
      limitTags={limitTags}
      chipSize="small"
      noWrap
    />
  );
};

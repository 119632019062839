import {
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { routes } from "@/config/routes";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { useInventoryItemSequence } from "../../../components/current-stock-list/components/hooks/useInventoryItemSequence";
import { InventoryAdjustmentSequenceStepper } from "../inventory-adjustment-sequence-stepper/InventoryAdjustmentSequenceStepper";

export const InventoryAdjustmentBreadcrumbs: FC = () => {
  const intl = useIntl();
  const { sequenceActive } = useInventoryItemSequence();

  const breadcrumbs = useMemo(() => {
    const items = [
      {
        text: intl.$t({ id: "INVENTORY" }),
        link: routes.currentStock,
        id: "inventory",
      },
      {
        text: intl.$t({ id: "NEW_ADJUSTMENT" }),
        id: "new-adjustment",
      },
    ] as BreadcrumbItem[];

    return items;
  }, [intl]);

  const actions = useMemo(() => {
    const bActions = [];
    if (sequenceActive) {
      bActions.push(
        <InventoryAdjustmentSequenceStepper key="inventory-adjustment-sequence-stepper" />,
      );
    }
    return bActions;
  }, [sequenceActive]);

  const appendItems = useMemo(() => {
    return [];
  }, []);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      appendItems={appendItems}
      actions={actions}
    />
  );
};

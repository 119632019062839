import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { DEFAULT_ADDRESS_COUNTRY } from "@/common/const";
import { usaStates } from "@/common/utils/usaStates";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  AddressInput,
  ExternalVendorFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useVendorMutations } from "../../../hooks/useVendorMutations";
import { ImportExternalVendorsForm } from "../ImportExternalVendorsForm";
import { useImportExternalVendorsStore } from "../stores/useImportExternalVendorsWizardStore";

export const useImportExternalVendors = () => {
  const { connectedSourceSystem } = useOrgSettings();
  const { moveToNextNestedStep, setNestedStep, moveToPreviousNestedStep } =
    useNestedStepper();
  const { importVendors, importingVendors, refetchCachedVendors } =
    useVendorMutations();
  const { selectedExternalVendors, closeModal, setSelectedExternalVendors } =
    useImportExternalVendorsStore();
  const { reset, setValue, getValues } =
    useFormContext<ImportExternalVendorsForm>();

  const handleCloseModal = useCallback(() => {
    closeModal();
    setSelectedExternalVendors([]);
    setNestedStep(0);
    reset();
  }, [closeModal, reset, setSelectedExternalVendors, setNestedStep]);

  const onImportCompleted = useCallback(() => {
    refetchCachedVendors();
    handleCloseModal();
  }, [refetchCachedVendors, handleCloseModal]);

  const canImportVendor = useCallback(
    (vendor: ExternalVendorFieldsFragment) =>
      vendor.externalId &&
      vendor.address?.addressLine1 &&
      vendor.address?.city &&
      vendor.address?.state &&
      vendor.address?.postalCode &&
      (vendor.address?.country ||
        (!!vendor.address?.state &&
          usaStates.some(
            (usaState) =>
              usaState.abbreviation === vendor.address?.state ||
              usaState.name === vendor.address?.state,
          ))),
    [],
  );

  const handleCreateVendors = useCallback(async () => {
    if (!connectedSourceSystem) {
      return;
    }
    const validVendors = selectedExternalVendors
      .filter(canImportVendor)
      .map((vendor) => ({
        externalVendorId: vendor.externalId,
        address: {
          ...vendor.address,
          country: vendor.address?.country ?? DEFAULT_ADDRESS_COUNTRY,
        } as AddressInput,
      }));
    const invalidVendors = selectedExternalVendors.filter(
      (p) =>
        !validVendors
          .map((vendor) => vendor.externalVendorId)
          .includes(p.externalId),
    );
    if (validVendors.length) {
      moveToNextNestedStep();
      const result = await importVendors({
        system: connectedSourceSystem,
        vendors: validVendors,
      });
      if (result) {
        if (invalidVendors.length) {
          setValue("vendors", invalidVendors);
          moveToNextNestedStep();
        } else {
          setNestedStep(4);
        }
      } else {
        moveToPreviousNestedStep();
      }
    } else {
      setValue("vendors", invalidVendors);
      setNestedStep(2);
    }
  }, [
    setValue,
    selectedExternalVendors,
    connectedSourceSystem,
    canImportVendor,
    importVendors,
    moveToNextNestedStep,
    moveToPreviousNestedStep,
    setNestedStep,
  ]);

  const handleCreateRemainingVendors = useCallback(async () => {
    if (!connectedSourceSystem) {
      return;
    }
    const remainingVendors = getValues("vendors").map((vendor) => ({
      externalVendorId: vendor.externalId,
      address: vendor.address as AddressInput,
    }));
    moveToNextNestedStep();
    const result = await importVendors({
      system: connectedSourceSystem,
      vendors: remainingVendors,
    });
    if (result) {
      moveToNextNestedStep();
    } else {
      moveToPreviousNestedStep();
    }
  }, [
    connectedSourceSystem,
    getValues,
    importVendors,
    moveToNextNestedStep,
    moveToPreviousNestedStep,
  ]);

  return {
    handleCreateVendors,
    handleCreateRemainingVendors,
    handleCloseModal,
    onImportCompleted,
    importingVendors,
  };
};

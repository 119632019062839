import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import {
  ItemizedNonItemizedToggle,
  ViewType,
} from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { useFormattedMaterialName } from "@/common/components/spreadsheet-table/hooks/useFormattedMaterialName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { appendTableRows } from "@/common/components/spreadsheet-table/utils/appendTableRows";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { RELEASE_DRAFT_STATUSES } from "@/common/const";
import { useAddInvoiceItems } from "@/common/hooks/add-missing-items-to-order/useAddInvoiceItems";
import { usePreviousValue } from "@/common/hooks/usePreviousValue";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { routes } from "@/config/routes";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { usePrefillDefaultTaxable } from "@/contractor/pages/home/release/hooks/usePrefillDefaultTaxable";
import { AddItemsToReleaseFromBuyoutDialog } from "@/contractor/pages/home/release/pages/add-items-to-release/AddItemsToReleaseFromBuyoutDialog";
import { SelectableItem } from "@/contractor/pages/home/release/pages/add-items-to-release/AddItemsToReleaseProvider";
import { useOrderItemPoItemReferences } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderItemPoItemReferences";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseStatus } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { MatchedOrderViewState } from "../../../../enums/MatchedOrderViewState";
import { useCopyInvoicePrices } from "../../../../hooks/useCopyInvoicePrices";
import { useInvoiceMatchedOrder } from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceUpdateRelease } from "../../../../providers/InvoiceUpdateReleaseProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";
import { OrderNumberHeader } from "../../../../styles/InvoiceVerification.styles";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";
import { InvoiceUpdateReleaseHeader } from "./InvoiceUpdateReleaseHeader";
import { InvoiceUpdateReleaseItemizedView } from "./InvoiceUpdateReleaseItemizedView";
import { InvoiceUpdateReleaseNonItemizedView } from "./InvoiceUpdateReleaseNonItemizedView";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const HeaderGroup = tw.div`flex w-full place-items-center gap-1 mb-2`;
const ViewToggleContainer = tw.div`flex flex-1 justify-end`;
const ButtonGroup = tw.div`flex flex-row justify-end gap-x-2 self-stretch mb-2`;
const PoNumberContainer = tw.div`grid grid-flow-col items-center gap-1`;
const LinkLikeStyled = tw(LinkLike)`flex items-center gap-1 text-blue-500`;
const ClearButtonText = tw.div`text-xs font-normal text-blue-800`;
const OrderInfo = tw.div`flex flex-col gap-2 ml-2`;

export const InvoiceUpdateRelease: FC = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { invoice, updateInvoice, setFooterState } = useInvoiceVerification();
  const { release } = useRelease();
  const { buyout } = useContractorBuyout();
  const { materials } = useMaterials();
  const findMaterialByName = useFindMaterialByName();
  const getFormattedMaterialName = useFormattedMaterialName();
  const { handsonInstance, spreadsheetData, setMetadata, rowHasChanges } =
    useColumnMapper();
  const { calcExtPrice } = usePriceCalculation();
  const { calcTableTotal, getPreferredCostCode } = useTableHelpers();
  const { setGlobalVendorId } = useVendorPrices();
  const { addMissingInvoiceItems, setAddMissingInvoiceItems } =
    useInvoiceMatchedOrder();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();
  const { addInvoiceItems } = useAddInvoiceItems();
  const { copyPriceFromInvoice } = useCopyInvoicePrices();
  const { setMatchedOrderViewState } = useInvoiceMatchedOrder();
  const { itemized, setItemized } = useInvoiceUpdateRelease();
  const { taxable } = usePrefillDefaultTaxable();
  const form = useFormContext<InvoiceCreateReleaseFormValues>();
  const { watch, setValue, getValues } = form;
  const netAmount = watch("netAmount");

  const [addFromBuyoutDialog, setAddFromBuyoutDialog] =
    useState<boolean>(false);
  const isAutoAddedToReservedPo = useRef(false);

  const vendorId = watch("vendorId");
  const { openDialog } = useDialog();

  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
  }, [setGlobalVendorId, vendorId]);

  const releaseItems = useMemo(() => {
    let items = release?.items
      ?.toSorted((a, b) => (a.position || 0) - (b.position || 0))
      .map((item) => {
        const material = materials.find(
          (m) => m.id === item.projectItem?.material.id,
        );
        if (
          hasOrderItemPoItemReferences(item, release) &&
          item.position !== undefined &&
          item.position !== null
        ) {
          setMetadata(item.position, {
            warningIconTooltipText: intl.$t(
              { id: "ITEM_WILL_NEED_TO_BE_MANUALLY_ADDED_TO_EXTERNAL_PO" },
              { sourceSystem: connectedSourceSystem },
            ),
          });
        }
        const selectedCostCode = getPreferredCostCode(item?.costCode);
        return {
          id: item.id,
          material: material
            ? getFormattedMaterialName(material)
            : (item.name ?? ""),
          name: item.name ?? "",
          costCode: selectedCostCode?.code ?? "",
          notes: item.instructions?.text ?? "",
          UOM: item?.uom?.pluralDescription ?? item?.uom?.mnemonic ?? "",
          quantityDecimal: item?.quantityDecimal ?? "",
          unitPrice: item?.unitPrice ?? "",
          zone: item?.zone?.name ?? "",
          extPrice: calcExtPrice(item.quantityDecimal, item.unitPrice),
          tags: item.tags,
          taxable: item.taxable,
        };
      });

    if (items?.length === 0) {
      items = (invoice?.items ?? []).map((item) => {
        const matchingOrgMaterial = findMaterialByName(item.description);
        const isLumpSum = isLumpSumUomText(item.UOM);
        const selectedCostCode = getPreferredCostCode(
          matchingOrgMaterial?.costCode,
        );
        const extPrice = calcExtPrice(item.quantityDecimal, item.unitPrice);
        return {
          id: "",
          material: matchingOrgMaterial
            ? getFormattedMaterialName(matchingOrgMaterial)
            : (item.description ?? ""),
          name: item.description ?? "",
          costCode: selectedCostCode?.code ?? "",
          notes: "",
          UOM:
            item.UOM ??
            matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
            matchingOrgMaterial?.defaultEstimateUom?.mnemonic ??
            "",
          zone: "",
          quantityDecimal:
            (isLumpSum ? extPrice.toString() : item.quantityDecimal) ?? "",
          unitPrice: isLumpSum ? "1" : (item.unitPrice ?? ""),
          extPrice,
          tags: [],
          taxable,
        };
      });
    }

    return items ?? [];
  }, [
    release,
    materials,
    hasOrderItemPoItemReferences,
    getFormattedMaterialName,
    calcExtPrice,
    setMetadata,
    intl,
    connectedSourceSystem,
    invoice?.items,
    findMaterialByName,
    taxable,
    getPreferredCostCode,
  ]);

  useEffect(() => {
    if (
      release?.status === ReleaseStatus.Reserved &&
      !releaseItems.length &&
      !isAutoAddedToReservedPo.current &&
      handsonInstance
    ) {
      isAutoAddedToReservedPo.current = true;
      addInvoiceItems();
    }
  }, [release?.status, addInvoiceItems, releaseItems.length, handsonInstance]);

  const previousAddMissingInvoiceItems = usePreviousValue(
    addMissingInvoiceItems,
  );

  useEffect(() => {
    if (
      previousAddMissingInvoiceItems === false &&
      addMissingInvoiceItems === true
    ) {
      setTimeout(() => {
        addInvoiceItems();
        setAddMissingInvoiceItems(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMissingInvoiceItems, addInvoiceItems]);

  const addBuyoutItems = useCallback(
    (items: SelectableItem[]) => {
      const newItems =
        buyout?.items
          .filter((item) => items.some((i) => i.itemId === item.id))
          .map((item) => {
            const material = materials.find(
              (m) => m.id === item.projectItem.material.id,
            );
            const selectedCostCode = getPreferredCostCode(material?.costCode);
            return {
              [COLUMN_TYPE.Material]: getFormattedMaterialName(
                material ?? item.description ?? "",
              ),
              [COLUMN_TYPE.Manufacturer]: item.manufacturer?.name || "",
              [COLUMN_TYPE.UOM]:
                item.projectItem.estimateUom.pluralDescription || "UT",
              [COLUMN_TYPE.Quantity]: item.quantityDecimal || "",
              [COLUMN_TYPE.ReceivedQuantity]: item.quantityDecimal || "",
              [COLUMN_TYPE.UnitPrice]: item.unitPrice || "",
              [COLUMN_TYPE.ExtPrice]: String(
                calcExtPrice(item.quantityDecimal, item.unitPrice),
              ),
              [COLUMN_TYPE.CostCode]: selectedCostCode?.code || "",
              [COLUMN_TYPE.Taxable]: "true",
              [COLUMN_TYPE.Tag]: item.tags?.[0]?.name || "",
            };
          }) || [];
      appendTableRows(newItems, handsonInstance);
    },
    [
      buyout?.items,
      handsonInstance,
      materials,
      getFormattedMaterialName,
      calcExtPrice,
      getPreferredCostCode,
    ],
  );

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== netAmount) {
        setValue("netAmount", newTotal);
      }
      const taxableNetAmount = `${calcTableTotal(data, { taxable: true })}`;
      if (taxableNetAmount !== getValues("taxableNetAmount")) {
        setValue("taxableNetAmount", taxableNetAmount);
      }
    },
    [calcTableTotal, netAmount, getValues, setValue],
  );

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  const releasePath = useMemo(() => {
    if (release?.id) {
      return generatePath(routes.delivery, {
        deliveryId: release.id,
      });
    }
  }, [release?.id]);

  const clearInvoiceRelease = useCallback(async () => {
    if (invoice) {
      await updateInvoice({ clearRelease: true, id: invoice.id });
      setFooterState(InvoiceFooterState.DEFAULT);
      setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
    }
  }, [invoice, setFooterState, setMatchedOrderViewState, updateInvoice]);

  const handleToggleChange = useCallback(
    (viewType: ViewType) => {
      if (
        viewType === ViewType.NonItemized &&
        spreadsheetData
          .filter((row) => !rowIsEmpty(row))
          .some(
            (row) =>
              rowHasChanges(row) || row.id === null || row.id === undefined,
          )
      ) {
        openDialog({
          title: intl.$t({ id: "SWITCH_TO_NON_ITEMIZED_VIEW" }),
          text: intl.$t({ id: "SWITCH_TO_NON_ITEMIZED_VIEW_DETAILS" }),
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "CONFIRM" }),
          includeWarningIcon: true,
          handleConfirm: () => {
            setItemized(false);
          },
        });
      } else {
        setItemized(viewType === ViewType.Itemized);
      }
    },
    [intl, openDialog, rowHasChanges, setItemized, spreadsheetData],
  );

  return (
    <Container>
      <HeaderGroup>
        <OrderInfo>
          <FormattedMessage id="YOUR_ORDER" tagName={OrderNumberHeader} />
          <PoNumberContainer>
            <LinkLikeStyled to={releasePath} disabled={!releasePath}>
              <If isTrue={release}>
                <FormattedMessage
                  id="ORDER_WITH_NUMBER"
                  values={{ orderNumber: release?.sequenceNumber }}
                />
              </If>
            </LinkLikeStyled>
            <ReleaseStatusChip
              status={release?.status}
              type="small"
              releaseType={release?.type}
            />
            <OutlinedButton
              $small
              className="h-6 min-w-16"
              onClick={clearInvoiceRelease}
            >
              <FormattedMessage id="CLEAR" tagName={ClearButtonText} />
            </OutlinedButton>
          </PoNumberContainer>
        </OrderInfo>
        <If
          isTrue={RELEASE_DRAFT_STATUSES.includes(
            release?.status as ReleaseStatus,
          )}
        >
          <ViewToggleContainer>
            <ItemizedNonItemizedToggle
              handleChange={handleToggleChange}
              isItemized={itemized}
            />
          </ViewToggleContainer>
        </If>
      </HeaderGroup>
      <If isTrue={itemized}>
        <ButtonGroup>
          <OutlinedButton
            $small
            id="copy-prices"
            onClick={copyPriceFromInvoice}
            className="ml-2"
            disabled={invoice?.release?.poLink?.immutable}
          >
            <FormattedMessage id="COPY_PRICES" />
          </OutlinedButton>
          <If isTrue={buyout}>
            <OutlinedButton $small onClick={() => setAddFromBuyoutDialog(true)}>
              <FormattedMessage id="ADD_ITEMS_FROM_BUYOUT" />
            </OutlinedButton>
            <AddItemsToReleaseFromBuyoutDialog
              visible={addFromBuyoutDialog}
              setVisible={setAddFromBuyoutDialog}
              callback={addBuyoutItems}
            />
          </If>
          <OutlinedButton $small onClick={() => addInvoiceItems()}>
            <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_INVOICE" />
          </OutlinedButton>
        </ButtonGroup>
      </If>
      <InvoiceUpdateReleaseHeader />
      <If isTrue={itemized}>
        <InvoiceUpdateReleaseItemizedView
          releaseItems={releaseItems}
          updateSubtotal={updateSubtotal}
        />
      </If>
      <If isTrue={!itemized}>
        <InvoiceUpdateReleaseNonItemizedView />
      </If>
    </Container>
  );
};

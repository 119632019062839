import { PhoneAndroid } from "@mui/icons-material";
import tw from "tailwind-styled-components";
import { If } from "../../if/If";
import { PhoneView } from "../../phone-input/PhoneView";
import { Tooltip } from "../../tooltip/Tooltip";

const ContactName = tw.div``;
const ContactPhone = tw.span`font-light`;

type Contact = {
  name: string | undefined | null;
  phone: string | undefined | null;
};

export const ContactWithPhoneNumber = ({ name, phone }: Contact) => {
  if (!name) {
    return null;
  }
  return (
    <Tooltip
      element={
        <ContactName>
          {name}
          <If isTrue={phone}>
            <PhoneAndroid className="text-base" />
          </If>
        </ContactName>
      }
    >
      {phone ? (
        <ContactPhone>
          <PhoneView value={phone || ""} />
        </ContactPhone>
      ) : undefined}
    </Tooltip>
  );
};

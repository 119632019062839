import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ReceiptFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useIsReceiptItemized = () => {
  const intl = useIntl();
  const { settings } = useOrgSettings();

  return useCallback(
    (receipt: ReceiptFieldsFragment | null | undefined) => {
      if (!receipt || !receipt.release?.items?.length) {
        return settings?.display?.receiptDefaultItemized ?? false;
      }

      if (receipt.release.items.length > 1) {
        return true;
      }

      const hasOneLumpSumItem =
        receipt.release?.items?.length === 1 &&
        isLumpSumItem(receipt.release.items[0]);

      return hasOneLumpSumItem
        ? !receipt.release.items[0].name.endsWith(intl.$t({ id: "EXPENSES" }))
        : true;
    },
    [intl, settings?.display?.receiptDefaultItemized],
  );
};

import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { NoteDocumentFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";

export const useNoteDocumentItems = (
  noteDocument: NoteDocumentFieldsFragment | null | undefined,
) => {
  const { getPrefilledValue, getPreferredCostCode } = useTableHelpers();
  const { calcExtPrice } = usePriceCalculation();
  const findMaterialByName = useFindMaterialByName();

  const noteDocumentItems = useMemo(
    () =>
      (noteDocument?.items || []).map((item) => {
        const matchingOrgMaterial = findMaterialByName(item.description ?? "");
        const isLumpSum = isLumpSumUomText(item.uom);
        const selectedCostCode = getPreferredCostCode(
          matchingOrgMaterial?.costCode,
        );
        let prefilledPrice;
        if (matchingOrgMaterial) {
          prefilledPrice = getPrefilledValue({
            material: item.description ?? "",
            uom: item.uom ?? "",
          }).value;
        }

        const extPrice = calcExtPrice(item.quantity, item.unitPrice);

        return {
          id: item.id,
          quantityDecimal:
            (isLumpSum ? extPrice.toString() : item.quantity) ?? "",
          name: item.description ?? "",
          material: matchingOrgMaterial,
          costCode: selectedCostCode?.code,
          UOM:
            item.uom ??
            matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
            matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
          unitPrice: isLumpSum
            ? "1"
            : (item.unitPrice ?? prefilledPrice ?? undefined),
          extPrice,
          taxable: true,
        };
      }),
    [
      noteDocument?.items,
      findMaterialByName,
      calcExtPrice,
      getPrefilledValue,
      getPreferredCostCode,
    ],
  );

  return {
    noteDocumentItems,
  };
};

import { If } from "@/common/components/if/If";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { InvoiceForm } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/invoice-list-actions/add-invoice/InvoiceForm";
import { InvoiceFormType } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/invoice-list-actions/add-invoice/types/InvoiceFormType";
import {
  DistributorInvoiceFieldsFragment,
  InvoiceFieldsFragment,
  InvoiceType,
  ReceiptFieldsFragment,
  UpdateInvoiceInput,
  UpdateInvoiceMutation,
  UpdateReceiptMutation,
} from "@/generated/graphql";
import { Popover } from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  DuplicatedInvoiceInfo,
  isDuplicatedInvoice,
} from "../../common/DuplicatedInvoiceInfo";
import {
  NotMatchingPoNumberInfo,
  isNotMatchingPoNumber,
} from "../../common/NotMatchingPoNumberInfo";
import { ReceiptMagicWand } from "../../common/ReceiptMagicWand";

import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useEquipmentItems } from "@/contractor/pages/admin/cost-structure/pages/equipment/hooks/useEquipmentItems";
import { useServiceCodes } from "@/contractor/pages/admin/cost-structure/pages/service-codes/hooks/useServiceCodes";
import { InfoIconContainer } from "@/contractor/pages/home/projects/components/project-form-with-stepper/ProjectFormWithStepper.styles";
import { ArchivedDuplicateInvoices } from "../../common/ArchivedDuplicateInvoices";
import { InvoiceOptionsDialog } from "../invoice-document-options/InvoiceOptionsDialog";
import { ReceiptDocumentOptions } from "../ReceiptDocumentOptions";
import { InvoiceOptionsButton } from "./invoice-options/InvoiceOptionsButton";
import { InvoiceGroups } from "./InvoiceGroups";
import { InvoiceHeaderDateEdit } from "./InvoiceHeaderDateEdit";
import { InvoiceHeaderTextEdit } from "./InvoiceHeaderTextEdit";
import { InvoicePaidToggle } from "./InvoicePaidToggle";
import { ReceiptPaymentMethod } from "./receipt-payment-method/ReceiptPaymentMethod";
import { ReceiptGroups } from "./ReceiptGroups";
import { ReceiptHeaderCostCodeSelect } from "./ReceiptHeaderCostCodeSelect";
import { ReceiptHeaderCreatedApproved } from "./ReceiptHeaderCreatedApproved";
import { ReceiptNote } from "./ReceiptNote";
import { useInvoiceDetailsHeader } from "./useInvoiceDetailsHeader";

const Container = tw.div`px-6`;
const Header = tw.div`font-medium grid grid-flow-col items-center justify-between`;
const HeaderGroup = tw.div`flex flex-row gap-2`;
const Details = tw.div`grid grid-cols-[auto_auto] gap-x-4 justify-between my-2 mx-auto`;
const Detail = tw.div`text-sm items-center min-w-60`;

const VendorInvoiceHeader = tw.div`flex-1`;
const ButtonsContainer = tw.div`flex gap-2 items-center`;
const InvoiceNumberTooltipContainer = tw.div`flex`;

enum HeaderDetailType {
  NUMBER,
  PO_NUMBER,
  ISSUE_DATE,
  DUE_DATE,
  PAID,
  PRE_PAID,
  DESCRIPTION,
  PAYMENT_METHOD,
  NOTES,
  COST_CODE,
}

type Props = {
  invoice:
    | DistributorInvoiceFieldsFragment
    | InvoiceFieldsFragment
    | null
    | ReceiptFieldsFragment;
  updateInvoice?: (
    input: UpdateInvoiceInput,
  ) => Promise<
    UpdateInvoiceMutation | UpdateReceiptMutation | null | undefined
  >;
  displayHeaderDetails: boolean;
  readonly?: boolean;
  invoicePoNumberReadonly?: boolean;
  type?: InvoiceType;
  rescanInvoice?: () => void;
  readjust: () => void;
  skipAutoMatching?: boolean;
};

export const InvoiceDetailsHeader: FC<Props> = ({
  invoice,
  updateInvoice,
  displayHeaderDetails = true,
  readonly = false,
  invoicePoNumberReadonly = false,
  type = InvoiceType.Invoice,
  rescanInvoice,
  readjust,
  skipAutoMatching,
}) => {
  const intl = useIntl();
  const { serviceCodes, loading: loadingServiceCodes } = useServiceCodes();
  const { equipmentItems, loading: loadingEquipmentItems } =
    useEquipmentItems();

  const {
    isInvoice,
    handleOptionsClose,
    handleOptionsClick,
    handleSaveInvoiceDetail,
    handleEditToggleClick,
    optionsAnchorEl,
    editingInputs,
    number,
    issueDate,
    setIssueDate,
    poNumber,
    paymentMethodId,
    dueDate,
    setDueDate,
    description,
  } = useInvoiceDetailsHeader({
    type,
    invoice,
    updateInvoice,
    skipAutoMatching,
  });

  return (
    <Container>
      <Header className={displayHeaderDetails ? "" : "mb-10"}>
        <HeaderGroup>
          <FormattedMessage
            id={isInvoice ? "VENDOR_INVOICE" : "RECEIPT"}
            tagName={VendorInvoiceHeader}
          />
          {type === InvoiceType.Invoice ? <InvoiceGroups /> : <ReceiptGroups />}
        </HeaderGroup>
        <ButtonsContainer>
          <If isTrue={!readonly}>
            <If isTrue={isInvoice}>
              <InvoicePaidToggle
                invoice={invoice}
                onChange={(value) =>
                  handleSaveInvoiceDetail(HeaderDetailType.PAID, value)
                }
              />
            </If>
            <If isTrue={!isInvoice}>
              <ReceiptPaymentMethod
                receipt={invoice as ReceiptFieldsFragment}
                selectedPaymentMethodId={paymentMethodId}
                setSelectedPaymentMethodId={(id) =>
                  handleSaveInvoiceDetail(
                    HeaderDetailType.PAYMENT_METHOD,
                    id as string,
                  )
                }
                onPaidToggle={(value) =>
                  handleSaveInvoiceDetail(HeaderDetailType.PRE_PAID, value)
                }
              />
            </If>
          </If>
        </ButtonsContainer>
      </Header>
      <If isTrue={!isInvoice}>
        <ReceiptNote
          receipt={invoice as ReceiptFieldsFragment}
          onSaveNotes={(notes) =>
            handleSaveInvoiceDetail(HeaderDetailType.NOTES, notes)
          }
        />
        <ReceiptHeaderCreatedApproved
          receipt={invoice as ReceiptFieldsFragment}
        />
      </If>
      <If isTrue={displayHeaderDetails}>
        <Details>
          <Detail>
            <InvoiceHeaderTextEdit
              title={intl.$t({
                id: isInvoice
                  ? "INVOICE_NUMBER_SYMBOL"
                  : "RECEIPT_NUMBER_SYMBOL",
              })}
              text={number}
              isEditing={editingInputs.includes(HeaderDetailType.NUMBER)}
              toggleEditing={() =>
                handleEditToggleClick(HeaderDetailType.NUMBER)
              }
              onSave={(value) =>
                handleSaveInvoiceDetail(HeaderDetailType.NUMBER, value)
              }
              readonly={readonly}
              error={
                isInvoice
                  ? isDuplicatedInvoice(invoice as InvoiceFieldsFragment)
                  : !number
              }
              info={
                isInvoice ? (
                  <InvoiceNumberTooltipContainer>
                    <DuplicatedInvoiceInfo
                      invoice={invoice as InvoiceFieldsFragment}
                    />
                    <ArchivedDuplicateInvoices
                      invoice={invoice as InvoiceFieldsFragment}
                    />
                  </InvoiceNumberTooltipContainer>
                ) : (
                  <ReceiptMagicWand
                    readonly={readonly}
                    receiptNumber={number}
                    receipt={invoice as ReceiptFieldsFragment}
                    onSave={(value) =>
                      handleSaveInvoiceDetail(HeaderDetailType.NUMBER, value)
                    }
                  />
                )
              }
              testIds={{
                edit: "invoice-number-edit",
                check: "invoice-number-check",
                input: "invoice-number-input",
              }}
            />
          </Detail>
          <Detail>
            <InvoiceHeaderDateEdit
              title={intl.$t({ id: "ISSUED" })}
              date={issueDate ? new Date(issueDate) : null}
              isEditing={editingInputs.includes(HeaderDetailType.ISSUE_DATE)}
              toggleEditing={() =>
                handleEditToggleClick(HeaderDetailType.ISSUE_DATE)
              }
              onChange={(date) => {
                setIssueDate(date?.getTime());
                handleSaveInvoiceDetail(
                  HeaderDetailType.ISSUE_DATE,
                  date?.getTime(),
                );
              }}
              readonly={readonly}
            />
          </Detail>
          <If isTrue={isInvoice}>
            <Detail>
              <PoNumberingSettingsCheck alternativeCondition={!!poNumber}>
                <InvoiceHeaderTextEdit
                  title={intl.$t({ id: "PO_HASH" })}
                  text={poNumber}
                  isEditing={editingInputs.includes(HeaderDetailType.PO_NUMBER)}
                  toggleEditing={() =>
                    handleEditToggleClick(HeaderDetailType.PO_NUMBER)
                  }
                  onSave={(value) =>
                    handleSaveInvoiceDetail(HeaderDetailType.PO_NUMBER, value)
                  }
                  error={isNotMatchingPoNumber(
                    invoice as InvoiceFieldsFragment,
                  )}
                  readonly={invoicePoNumberReadonly}
                  info={
                    <NotMatchingPoNumberInfo
                      invoice={invoice as InvoiceFieldsFragment}
                    />
                  }
                />
              </PoNumberingSettingsCheck>
            </Detail>
            <Detail>
              <InvoiceHeaderDateEdit
                title={intl.$t({ id: "DUE_DATE" })}
                date={dueDate ? new Date(dueDate) : null}
                isEditing={editingInputs.includes(HeaderDetailType.DUE_DATE)}
                toggleEditing={() =>
                  handleEditToggleClick(HeaderDetailType.DUE_DATE)
                }
                onChange={(date) => {
                  setDueDate(date?.getTime());
                  handleSaveInvoiceDetail(
                    HeaderDetailType.DUE_DATE,
                    date?.getTime(),
                  );
                }}
                readonly={readonly}
              />
            </Detail>
            <Detail />
            <Detail>
              <InvoiceHeaderTextEdit
                title={intl.$t({ id: "DESCRIPTION" })}
                text={description}
                isEditing={editingInputs.includes(HeaderDetailType.DESCRIPTION)}
                toggleEditing={() =>
                  handleEditToggleClick(HeaderDetailType.DESCRIPTION)
                }
                onSave={(value) =>
                  handleSaveInvoiceDetail(HeaderDetailType.DESCRIPTION, value)
                }
                info={
                  <InfoIconContainer>
                    <InfoTooltip
                      message={intl.$t({ id: "INVOICE_DESCRIPTION" })}
                    />
                  </InfoIconContainer>
                }
              />
            </Detail>
          </If>
          <Detail>
            <If
              isTrue={
                isInvoice || !!equipmentItems.length || !!serviceCodes.length
              }
            >
              <DrawerPanel
                anchor={(togglePanel) => (
                  <>
                    <InvoiceOptionsButton
                      opened={!!optionsAnchorEl && type !== InvoiceType.Invoice}
                      handleOptionsClick={handleOptionsClick}
                      type={type}
                    />

                    {type === InvoiceType.Invoice ? (
                      <InvoiceOptionsDialog
                        invoice={invoice as InvoiceFieldsFragment}
                        readonly={readonly}
                        rescanInvoice={rescanInvoice}
                        togglePanel={togglePanel}
                        closeOptions={handleOptionsClose}
                        readjust={readjust}
                        show={Boolean(optionsAnchorEl)}
                      />
                    ) : (
                      <Popover
                        open={Boolean(optionsAnchorEl)}
                        anchorEl={optionsAnchorEl}
                        onClose={handleOptionsClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        sx={{
                          " .MuiPaper-root": {
                            borderRadius: "1rem",
                            borderTopLeftRadius: 0,
                            boxShadow: "none",
                            marginLeft: "0.5px",
                          },
                        }}
                        className="bg-black/50"
                      >
                        <ReceiptDocumentOptions
                          receipt={invoice as ReceiptFieldsFragment}
                          readonly={readonly}
                          equipmentItems={equipmentItems}
                          loadingEquipmentItems={loadingEquipmentItems}
                          serviceCodes={serviceCodes}
                          loadingServiceCodes={loadingServiceCodes}
                        />
                      </Popover>
                    )}
                  </>
                )}
                content={(togglePanel) => (
                  <InvoiceForm
                    onClose={() => togglePanel(false)}
                    type={InvoiceFormType.CORRECTION}
                  />
                )}
              />
            </If>
          </Detail>
          <Detail>
            <If isTrue={!isInvoice}>
              <ReceiptHeaderCostCodeSelect
                receipt={invoice as ReceiptFieldsFragment}
                onChange={(id: string | null) =>
                  handleSaveInvoiceDetail(
                    HeaderDetailType.COST_CODE,
                    id ?? undefined,
                  )
                }
              />
            </If>
          </Detail>
        </Details>
      </If>
    </Container>
  );
};

import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ImageInitials } from "@/common/components/image-initials/ImageInitials";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import {
  LgFixedContainer,
  MdContainer,
  MdFixedContainer,
  RequestedItemsContainer,
  SmdFixedContainer,
  SmFixedContainer,
  XsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { GroupedInventoryItems } from "../../types/GroupedInventoryItems";
import { createSelectedItemId } from "../../utils/getSelectedItemIdParts";
import { CurrentStockAveragePrice } from "./components/current-stock-average-price/CurrentStockAveragePrice";
import { InventoryItemAllItemsCheckbox } from "./components/current-stock-checkbox/InventoryItemAllItemsCheckbox";
import { InventoryItemCheckbox } from "./components/current-stock-checkbox/InventoryItemCheckbox";
import { CurrentStockInStock } from "./components/current-stock-in-stock/CurrentStockInStock";
import { CurrentStockScheduledInflow } from "./components/current-stock-scheduled-inflow/CurrentStockScheduledInflow";
import { CurrentStockScheduledOutflow } from "./components/current-stock-scheduled-outflow/CurrentStockScheduledOutflow";

const Item = tw.div`flex items-center justify-center text-xs`;
const TwoLineContainer = tw.div`pr-1`;
const HeaderContainer = tw.div`relative flex items-center justify-center`;
const StyledInfoTooltip = tw(InfoTooltip)`absolute -right-6 top-0 bottom-0`;

export const useCurrentStockConfiguration = () => {
  const { formatCostCode } = useProjectCostCodes();
  const { formatUom } = useUomOptions();
  const intl = useIntl();

  const configuration: Array<GridCol<GroupedInventoryItems>> = useMemo(
    () => [
      {
        wrapper: XsFixedContainer,
        position: "center",
        item: ({ item }) => (
          <InventoryItemCheckbox
            item={{
              ...item,
              id: createSelectedItemId(
                item.warehouses[0]?.warehouse.id,
                item.id,
              ),
            }}
          />
        ),
        details: ({ item }) => <InventoryItemCheckbox item={item} />,
        header: <InventoryItemAllItemsCheckbox />,
      },
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <ProjectItemMaterialView
            count={count}
            item={{ ...item, material: item.orgMaterial }}
          />
        ),
        details: ({ item, count }) => (
          <ProjectItemMaterialView
            count={count}
            item={{ ...item, material: item.orgMaterial }}
            className="pl-6"
          />
        ),
        position: "none",
        header: <FormattedMessage id="ITEMS" />,
      },
      {
        wrapper: LgFixedContainer,
        header: <FormattedMessage id="COST_CODE" />,
        details: () => <div />,
        position: "center",
        item: ({ item }) => (
          <Item>{formatCostCode(item.orgMaterial.costCode?.id)}</Item>
        ),
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="UOM" />,
        position: "center",
        item: ({ item }) => <Item>{formatUom(item.uom)}</Item>,
        details: () => <div />,
      },
      {
        wrapper: MdContainer,
        header: <FormattedMessage id="WAREHOUSE" />,
        position: "center",
        item: ({ item }) => (
          <ImageInitials
            name={item.warehouses?.[0]?.warehouse.name}
            width={40}
            includeTooltip
          />
        ),
        details: ({ item, index = 0 }) => (
          <ImageInitials
            name={item.warehouses?.[index]?.warehouse.name}
            width={40}
            includeTooltip
          />
        ),
      },
      {
        wrapper: SmdFixedContainer,
        header: <FormattedMessage id="IN_STOCK" />,
        position: "center",
        item: ({ item }) => <CurrentStockInStock item={item.warehouses?.[0]} />,
        details: ({ item, index = 0 }) => (
          <CurrentStockInStock item={item.warehouses?.[index]} />
        ),
      },
      {
        wrapper: SmdFixedContainer,
        header: (
          <HeaderContainer>
            <FormattedMessage
              id="SCHEDULED_OUTFLOW_BREAK"
              tagName={TwoLineContainer}
              values={{ br: <br key="break" /> }}
            />
            <StyledInfoTooltip
              message={intl.$t({ id: "SCHEDULED_OUTFLOW_TOOLTIP" })}
            />
          </HeaderContainer>
        ),
        position: "center",
        item: ({ item }) => (
          <CurrentStockScheduledOutflow item={item.warehouses?.[0]} />
        ),
        details: ({ item, index = 0 }) => (
          <CurrentStockScheduledOutflow item={item.warehouses?.[index]} />
        ),
      },
      {
        wrapper: SmdFixedContainer,
        header: (
          <HeaderContainer>
            <FormattedMessage
              id="SCHEDULED_INFLOW_BREAK"
              tagName={TwoLineContainer}
              values={{ br: <br key="break" /> }}
            />
            <StyledInfoTooltip
              message={intl.$t({ id: "SCHEDULED_INFLOW_TOOLTIP" })}
            />
          </HeaderContainer>
        ),
        position: "center",
        item: ({ item }) => (
          <CurrentStockScheduledInflow item={item.warehouses?.[0]} />
        ),
        details: ({ item, index = 0 }) => (
          <CurrentStockScheduledInflow item={item.warehouses?.[index]} />
        ),
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="AVERAGE_PRICE" />,
        position: "center",
        item: ({ item }) => (
          <CurrentStockAveragePrice item={item.warehouses?.[0]} />
        ),
        details: ({ item, index = 0 }) => (
          <CurrentStockAveragePrice item={item.warehouses?.[index]} />
        ),
      },
    ],
    [formatCostCode, formatUom, intl],
  );

  return configuration;
};

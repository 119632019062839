import { Select } from "@/common/components/select/components/single/Select";
import { useComplianceGroups } from "@/contractor/pages/admin/orders/pages/compliance-groups/hooks/useComplianceGroups";
import { InputBaseComponentProps } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
type Props = {
  complianceGroupId: string | null | undefined;
  setComplianceGroupId: (value: string | null) => void;
  inputProps?: InputBaseComponentProps | undefined;
};

export const ComplianceGroupSelector: FC<Props> = ({
  complianceGroupId,
  setComplianceGroupId,
  inputProps,
}) => {
  const intl = useIntl();
  const { complianceGroups, formatComplianceGroup } = useComplianceGroups();
  return (
    <Select
      options={complianceGroups}
      value={complianceGroupId}
      onChange={setComplianceGroupId}
      getLabel={(option) => formatComplianceGroup(option)}
      getValue={(option) => option.id}
      placeholder={intl.$t({ id: "VENDOR_COMPLIANCE_GROUP_OPTIONAL" })}
      inputProps={inputProps}
    />
  );
};

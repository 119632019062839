import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div<{ $isZero: boolean }>`
  grid px-1 text-sm
  ${(props) => props.$isZero && "text-red-500"}
`;

type Props = {
  item: InventoryItemFieldsFragment;
};
export const InventoryItemExistingStock: FC<Props> = ({ item }) => {
  const remainingQuantity = useMemo(
    () => getInventoryItemRemainingQuantity(item).toNumber(),
    [item],
  );

  return (
    <Container $isZero={remainingQuantity === 0}>{remainingQuantity}</Container>
  );
};

import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { PhoneAndroid } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { PhoneView } from "../phone-input/PhoneView";
import { Tooltip } from "../tooltip/Tooltip";

type Props = {
  contact: Pick<
    OrgPreferredVendorsFieldsFragment["contacts"][0],
    "name" | "cellPhone" | "email" | "id"
  >;
  classes?: {
    root?: string;
    popover?: string;
  };
};

const PopoverDescription = tw.div`flex flex-col`;
const Item = tw.span``;

export const VendorListItem: FC<Props> = ({ contact, classes }) => (
  <Tooltip
    id={contact.id}
    key={contact.id}
    position="top"
    element={
      <Item className={classes?.root}>
        {contact.name}
        <If isTrue={contact.cellPhone}>
          <PhoneAndroid className="text-base" />
        </If>
      </Item>
    }
    className={classes?.popover}
  >
    <PopoverDescription>
      <FormattedMessage
        tagName="div"
        id="PHONE_WITH_VALUE"
        values={{ value: <PhoneView value={contact.cellPhone} /> }}
      />
      <FormattedMessage
        tagName="div"
        id="EMAIL_WITH_VALUE"
        values={{ value: contact?.email }}
      />
    </PopoverDescription>
  </Tooltip>
);

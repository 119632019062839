import { useHeaderOnlyInvoiceOption } from "@/common/hooks/useHeaderOnlyInvoiceOption";
import { useEnabledPoInSourceSystem } from "@/contractor/common/hooks/useEnabledPoInSourceSystem";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useMemo } from "react";

export const useNoReleaseForInvoiceInfo = () => {
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();
  const { hasHeaderOnlyInvoiceOption } = useHeaderOnlyInvoiceOption();
  const { connectedSourceSystem } = useOrgSettings();

  const sectionDescriptionKey = useMemo(() => {
    if (connectedSourceSystem) {
      if (hasHeaderOnlyInvoiceOption) {
        return "INVOICE_NOT_MATCHED_DESCRIPTION_CONNECTION_ACTIVE_WITH_HEADER_ONLY_OPTION";
      }
      return "INVOICE_NOT_MATCHED_DESCRIPTION_CONNECTION_ACTIVE";
    }

    return "INVOICE_NOT_MATCHED_DESCRIPTION";
  }, [connectedSourceSystem, hasHeaderOnlyInvoiceOption]);

  return {
    sectionDescriptionKey,
    isPoEnabledInSourceSystem,
    hasHeaderOnlyInvoiceOption,
  };
};

import { usePointerDrag } from "@/common/hooks/usePointerDrag";
import { DragIndicator } from "@mui/icons-material";
import { PropsWithChildren, useRef } from "react";
import { TbLayoutSidebarRightExpandFilled } from "react-icons/tb";
import tw from "tailwind-styled-components";
import { IconButtonBorderless } from "../button/IconButton";
import { useResizeColumns } from "./useResizeColumns";

const Container = tw.div<{
  $hasFooter: boolean;
  $tallFooter: boolean;
}>`
  flex relative gap-x-0.5 
  ${({ $hasFooter, $tallFooter }) => ($hasFooter ? ($tallFooter ? "h-[calc(100vh-400px)]" : "h-[calc(100vh-220px)]") : "h-[calc(100vh-200px)]")}  
`;
const Column = tw.div<{ $minimized?: boolean; $isDragging?: boolean }>`
  relative overflow-auto h-full
  ${({ $isDragging }) => (!$isDragging ? "transition-all duration-200 ease-linear" : "")}
  ${({ $minimized }) => ($minimized ? "delay-200" : "")}
`;
const ColumnContent = tw.div<{ $minimized?: boolean }>`
  h-full transition-opacity duration-200 ease-linear
  ${({ $minimized }) => ($minimized ? "opacity-0" : "delay-200")} 
`;
const Divider = tw.div<{
  $isInvalidLeft?: boolean;
  $isInvalidRight?: boolean;
  $isDragging?: boolean;
}>`
  relative w-2 bg-white text-gray-400
  transition-colors duration-200 rounded-sm
  hover:bg-gray-200 hover:text-gray-500 cursor-col-resize
  ${({ $isInvalidLeft, $isInvalidRight, $isDragging }) =>
    $isInvalidLeft
      ? "cursor-e-resize hover:bg-red-100"
      : $isInvalidRight
        ? "cursor-w-resize hover:bg-red-100"
        : $isDragging
          ? "hover:bg-gray-300 hover:text-gray-600"
          : ""}
`;
const DragIndicatorStyled = tw(DragIndicator)`
  absolute w-3 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  
`;
const DragOverlay = tw.div`
  fixed inset-0 z-50 cursor-col-resize
`;

const IconButtonBorderlessStyled = tw(IconButtonBorderless)<{
  $visible: boolean;
}>`
  absolute -left-1 top-0 transition-opacity duration-200 ease-linear
  ${({ $visible }) => ($visible ? "opacity-100" : "opacity-0")}
`;

type ResizableColumnsProps = {
  hasFooter?: boolean;
  tallFooter?: boolean;
  minimizeRightSide?: boolean;
  paddingBottom?: number;
  onMinimizeRightSide?: () => void;
} & PropsWithChildren;

export const ResizableColumns = ({
  hasFooter = false,
  tallFooter = false,
  minimizeRightSide = true,
  paddingBottom,
  children,
  onMinimizeRightSide,
}: ResizableColumnsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    leftWidth,
    isDragging,
    isRightMinimized,
    isInvalidLeft,
    isInvalidRight,
    setIsDragging,
    handleResize,
    toggleMinimize,
    resetInvalidStates,
  } = useResizeColumns({ minimizeRightSide, onMinimizeRightSide });

  const { handlePointerDown } = usePointerDrag({
    onDragStart: () => setIsDragging(true),
    onDragMove: (e) => {
      if (!containerRef.current) {
        return;
      }

      const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
      const containerRect = containerRef.current.getBoundingClientRect();
      const newWidthPx = clientX - containerRect.left;
      handleResize(newWidthPx, containerRect.width);
    },
    onDragEnd: () => {
      setIsDragging(false);
      resetInvalidStates();
    },
  });

  const childrenArray = Array.isArray(children) ? children : [children];

  return (
    <>
      <Container
        ref={containerRef}
        $hasFooter={hasFooter}
        $tallFooter={tallFooter}
        style={{ paddingBottom }}
      >
        <Column style={{ width: `${leftWidth}%` }} $isDragging={isDragging}>
          {childrenArray[0]}
        </Column>
        <Divider
          onPointerDown={handlePointerDown}
          onTouchStart={handlePointerDown}
          onDoubleClick={toggleMinimize}
          $isInvalidLeft={isInvalidLeft}
          $isInvalidRight={isInvalidRight && !isRightMinimized}
          $isDragging={isDragging}
        >
          <DragIndicatorStyled />
        </Divider>
        <Column
          style={{ width: leftWidth < 100 ? `${100 - leftWidth}%` : "32px" }}
          $isDragging={isDragging}
          $minimized={isRightMinimized}
        >
          <ColumnContent $minimized={isRightMinimized}>
            {childrenArray[1]}
          </ColumnContent>
          <IconButtonBorderlessStyled
            onClick={toggleMinimize}
            $visible={isRightMinimized}
          >
            <TbLayoutSidebarRightExpandFilled className="text-2xl" />
          </IconButtonBorderlessStyled>
        </Column>
      </Container>
      {isDragging && <DragOverlay />}
    </>
  );
};

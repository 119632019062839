import { TransactionItemOrigin } from "@/contractor/pages/home/inventory-management/common/components/transaction-origin/TransactionItemOrigin";
import { InventoryItemPendingFlowFieldsFragment } from "@/generated/graphql";
import { WarehouseOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { TransactionOrigin } from "../../../../../transaction-history-stock/components/transaction-history-list/types/TransactionOrigin";

type Props = {
  item: InventoryItemPendingFlowFieldsFragment;
};

export const ScheduledOutflowFrom = ({ item }: Props) => {
  const intl = useIntl();
  const transactionItem: TransactionOrigin = useMemo(
    () => ({
      name: item.release.warehouse?.name ?? item.release.sourceWarehouse?.name,
      typeName: intl.$t({ id: "WAREHOUSE" }),
      icon: WarehouseOutlined,
    }),
    [intl, item.release.sourceWarehouse?.name, item.release.warehouse?.name],
  );

  return <TransactionItemOrigin item={transactionItem} />;
};

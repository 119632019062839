import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { getUserName } from "@/common/utils/users/getUserName";
import { useInvoiceDownload } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoiceDownload";
import {
  DistributorInvoiceFieldsFragment,
  InvoiceFieldsFragment,
  InvoiceSummaryFieldsFragment,
  ReceiptFieldsFragment,
} from "@/generated/graphql";
import { Download, DownloadOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  invoice:
    | DistributorInvoiceFieldsFragment
    | InvoiceFieldsFragment
    | InvoiceSummaryFieldsFragment
    | ReceiptFieldsFragment
    | null;
  version?: number;
  simpleButton?: boolean;
};

const ButtonContent = tw.div`flex flex-row items-center py-0`;
const CircleButton = tw.div`w-7 h-7 rounded-full flex items-center justify-center text-blue-500 hover:text-blue-300 mt-1 ml-1`;
const CircleButtonIcon = tw(Download)`text-base`;

export const InvoiceDownloadButton: FC<Props> = ({
  invoice,
  version,
  simpleButton = false,
}) => {
  const intl = useIntl();
  const { downloadInvoice } = useInvoiceDownload();

  const downloadPdf = useCallback(async () => {
    const downloadInvoiceUrl = await downloadInvoice(invoice?.id, version);
    if (downloadInvoiceUrl) {
      window.open(downloadInvoiceUrl, "_blank");
    }
  }, [downloadInvoice, invoice?.id, version]);

  const simpleButtonTooltipProps = useMemo(() => {
    if (invoice && "downloadedAt" in invoice && !!invoice.downloadedAt) {
      return {
        id: "DOWNLOADED_AT_BY",
        values: {
          downloadedAt: intl.formatDate(invoice.downloadedAt),
          downloadedBy: getUserName(invoice.downloadedBy),
          br: <br key="break" />,
        },
      };
    }
    return {
      id: "DOWNLOAD_INVOICE",
    };
  }, [invoice, intl]);

  if (simpleButton) {
    return (
      <Tooltip
        id="invoice-download-tooltip"
        element={
          <CircleButton
            onClick={(e) => {
              downloadPdf();
              e.preventDefault();
              e.stopPropagation();
            }}
            className={
              invoice && "downloadedAt" in invoice && !!invoice.downloadedAt
                ? "bg-green-300"
                : ""
            }
          >
            <CircleButtonIcon />
          </CircleButton>
        }
      >
        <FormattedMessage {...simpleButtonTooltipProps} />
      </Tooltip>
    );
  }

  return (
    <OutlinedButton $small className="h-6 pr-4" onClick={downloadPdf}>
      <ButtonContent>
        <DownloadOutlined className="text-sm" />
        <FormattedMessage id="DOWNLOAD" />
      </ButtonContent>
    </OutlinedButton>
  );
};

import { If } from "@/common/components/if/If";
import { FC } from "react";
import {
  FooterCell,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterPropsWithTaxes,
  FooterTotal,
} from "../../../../../../../common/components/grid-table/styles/Footer.styles";
import { useRelease } from "../../../providers/ReleaseProvider";
import { isWarehouseRelease } from "../../../utils/isWarehouseRelease";

export const ReleaseEmptyFooter: FC<FooterPropsWithTaxes> = ({
  Wrapper,
  includeAdditionalCharges,
}) => {
  const { release } = useRelease();
  return (
    <FooterCol>
      <Wrapper className="flex-col">
        <If isTrue={!isWarehouseRelease(release)}>
          <FooterHeader />
          <If isTrue={includeAdditionalCharges}>
            <FooterCell />
          </If>
          <FooterCell />
          <FooterCell />
          <FooterDashedLine />
        </If>
        <FooterTotal />
      </Wrapper>
    </FooterCol>
  );
};

import { CostCodeFieldsFragment } from "@/generated/graphql";

const trimDescription = (description: string) => {
  return description.replace(/\s+/g, " ").trim();
};

const trimCode = (code: string) => {
  return code.replace(/\s+/g, " ").trim();
};

export const formatCostCode = (
  costCode: CostCodeFieldsFragment,
  showCodeWithNumber: boolean,
) => ({
  ...costCode,
  code: trimCode(costCode.code),
  description: trimDescription(costCode.description),
  formatted: showCodeWithNumber
    ? `${trimDescription(costCode?.description)} (${trimCode(costCode?.code)})`.trim()
    : trimDescription(costCode.description),
});

import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useTableMaps } from "../../hooks/useTableMaps";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { applyClasses } from "../utils/applyClasses";
import { applyTooltip } from "../utils/applyTooltip";
import { useVendorRenderer } from "../vendor-renderer/useVendorRenderer";

const logoBaseClasses = `
  absolute top-1/2 -translate-y-1/2 left-2.5 text-2xl h-4
  grid place-items-center select-none object-contain 
`;

export const useSupplierRenderer = () => {
  const intl = useIntl();
  const { vendorByNameMap, warehouseByNameMap } = useTableMaps();
  const baseRenderer = useBaseRenderer();
  const vendorRenderer = useVendorRenderer();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      baseRenderer(instance, td, row, col, prop, value, cellProperties);
      const vendor = vendorByNameMap[value];
      const warehouse = warehouseByNameMap[value];
      if (!vendor && !warehouse) {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
        return;
      }

      if (vendor) {
        vendorRenderer(instance, td, row, col, prop, value, cellProperties);
      } else {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
        const warehouse = warehouseByNameMap[value];
        if (warehouse) {
          applyClasses(td, "pl-10 text-left");
          const warehouseLogo = document.createElement("img");
          warehouseLogo.src = new URL(
            "@/assets/icons/warehouse.svg",
            import.meta.url,
          ).href;
          warehouseLogo.className = `${logoBaseClasses}`;
          td.appendChild(warehouseLogo);
          applyTooltip(warehouseLogo, intl.$t({ id: "WAREHOUSE" }));
        }
      }
    },
    [baseRenderer, vendorRenderer, vendorByNameMap, warehouseByNameMap, intl],
  );

  return renderer;
};

import {
  CategoryState,
  useToggleCategory,
} from "@/common/hooks/useToggleCategory";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  UNSPECIFIED_COST_CODE_ID,
  useUnspecifiedCostCode,
} from "../../../../../common/hooks/useUnspecifiedCostCode";
import {
  UNSPECIFIED_ZONE_ID,
  useUnspecifiedZone,
} from "../../../../../common/hooks/useUnspecifiedZone";
import { useProjectMaps } from "../hooks/useProjectMaps";
import { useProject } from "./ProjectProvider";
import {
  BUDGET_VIEW_TYPE,
  ProjectReportType,
  useSpendingReport,
} from "./ProjectSpendingReportProvider";

export type ZoneCategory = CategoryState<CategoryState<ProjectReportType>>;

type ProviderContextType = {
  zones: ZoneCategory[];
  toggleCostCode: (costCodeId: string, zoneId: string) => void;
  toggleZone: (id: string) => void;
};

type Props = {
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  zones: [],
  toggleCostCode: NoFunction,
  toggleZone: NoFunction,
});

export const ProjectSpendingReportItemsProvider: FC<Props> = ({ children }) => {
  const { unassignedZone } = useUnspecifiedZone();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const [zones, setZones] = useState<ZoneCategory[]>([]);
  const { toggleCategory } = useToggleCategory(zones, setZones);
  const { vendorsReport, materialsReport, zonesReport, viewType } =
    useSpendingReport();
  const { hasPhaseCodes } = useOrgSettings();

  const { project } = useProject();
  const { costCodeMap, zoneMap, materialsMap } = useProjectMaps(project);

  useEffect(() => {
    switch (viewType) {
      case BUDGET_VIEW_TYPE.MATERIALS:
        setZones(
          materialsReport
            ? [
                {
                  id: UNSPECIFIED_ZONE_ID,
                  name: unassignedZone.name,
                  type: "ZONE",
                  isOpened: true,
                  items: materialsReport.costCodes
                    .map((code) => ({
                      id: code.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                      parentId: UNSPECIFIED_ZONE_ID,
                      name:
                        costCodeMap.get(
                          code.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                        )?.description || unassignedCostCode.description,
                      type: "COST_CODE",
                      isOpened: hasPhaseCodes,
                      ...costCodeMap.get(
                        code.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                      ),
                      ...code,
                      items: code.materials.map((material) => {
                        return {
                          id: material.material.id,
                          isOpened: true,
                          items: [],
                          ...materialsMap.get(material.material.id),
                          name: materialsMap.get(material.material.id)?.material
                            .name,
                          ...material,
                          zoneId: UNSPECIFIED_ZONE_ID,
                          costCodeId:
                            code.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                        } as ProjectReportType;
                      }),
                    }))
                    .sort((a, b) =>
                      a.code && b.code
                        ? a.code.localeCompare(b.code)
                        : a.name.localeCompare(b.name),
                    ),
                },
              ].sort((a, b) => a.name.localeCompare(b.name))
            : [],
        );
        break;
      case BUDGET_VIEW_TYPE.ZONES:
        setZones(
          zonesReport
            ? zonesReport.zones
                .map((zone) => ({
                  id: zone.zone?.id ?? UNSPECIFIED_ZONE_ID,
                  name: unassignedZone.name,
                  type: "ZONE",
                  isOpened: true,
                  ...zoneMap.get(zone.zone?.id || UNSPECIFIED_ZONE_ID),
                  ...zone,
                  items: zone.costCodes
                    .map((code) => ({
                      id: code.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                      parentId: zone.zone?.id ?? UNSPECIFIED_ZONE_ID,
                      isOpened: false,
                      type: "COST_CODE",
                      name:
                        costCodeMap.get(
                          code.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                        )?.description || unassignedCostCode.description,
                      ...code,
                      items: code.materials.map((material) => {
                        return {
                          ...materialsMap.get(material.material.id),
                          id: material.material.id,
                          ...material,
                          isOpened: true,
                          items: [],
                          name: materialsMap.get(material.material.id)?.material
                            .name,
                          zoneId: zone.zone?.id || UNSPECIFIED_ZONE_ID,
                          costCodeId:
                            code.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                        } as ProjectReportType;
                      }),
                    }))
                    .sort((a, b) =>
                      a.costCode?.code && b.costCode?.code
                        ? a.costCode?.code.localeCompare(b.costCode?.code)
                        : a.name.localeCompare(b.name),
                    ),
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
            : [],
        );
        break;
      case BUDGET_VIEW_TYPE.VENDORS:
        setZones(
          vendorsReport
            ? [
                {
                  id: UNSPECIFIED_ZONE_ID,
                  name: unassignedZone.name,
                  type: "ZONE",
                  isOpened: true,
                  items: vendorsReport.costCodes
                    .map((code) => ({
                      id: code.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                      name:
                        costCodeMap.get(
                          code.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                        )?.description || unassignedCostCode.description,
                      type: "COST_CODE",
                      isOpened: true,
                      ...costCodeMap.get(
                        code.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                      ),
                      ...code,
                      items: code.vendors.map((vendor) => {
                        return {
                          id: vendor.vendor.id,
                          name: vendor.vendor.name,
                          type: "SUPPLIER",
                          isOpened: true,
                          items: [],
                          zoneId: UNSPECIFIED_ZONE_ID,
                          costCodeId:
                            code.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                          ...vendor,
                        } as ProjectReportType;
                      }),
                    }))
                    .sort((a, b) =>
                      a.code && b.code
                        ? a.code.localeCompare(b.code)
                        : a.name.localeCompare(b.name),
                    ),
                },
              ].sort((a, b) => a.name.localeCompare(b.name))
            : [],
        );
        break;
    }
  }, [
    viewType,
    materialsReport,
    zonesReport,
    vendorsReport,
    unassignedZone,
    unassignedCostCode,
    costCodeMap,
    zoneMap,
    materialsMap,
    hasPhaseCodes,
  ]);

  const toggleZoneCostCode = (
    costCodeId: string,
    zoneId: string,
    zones: ZoneCategory[],
  ) => {
    const zoneIndex = zones.findIndex((z) => z.id === zoneId);
    const zone = zones[zoneIndex];
    const costCodeIndex = zone.items.findIndex((c) => c.id === costCodeId);
    const costCode = zone.items[costCodeIndex];
    return [
      ...zones.slice(0, zoneIndex),
      {
        ...zone,
        items: [
          ...zone.items.slice(0, costCodeIndex),
          { ...costCode, isOpened: !costCode.isOpened },
          ...zone.items.slice(costCodeIndex + 1),
        ],
      },
      ...zones.slice(zoneIndex + 1),
    ];
  };

  const toggleCostCode = useCallback(
    (costCodeId: string, zoneId: string) => {
      setZones(toggleZoneCostCode(costCodeId, zoneId, zones));
    },
    [zones],
  );

  return (
    <ProviderContext.Provider
      value={{
        zones,
        toggleCostCode,
        toggleZone: toggleCategory,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSpendingReportItems = (): ProviderContextType =>
  useContext(ProviderContext);

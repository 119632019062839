import { INVOICE_READONLY_STATUSES } from "@/common/const";
import { UpdateReleaseDialog } from "@/contractor/pages/home/release/pages/specify-details/footer-actions/UpdateReleaseDialog";
import { ReleaseStatus } from "@/generated/graphql";
import { FC } from "react";
import { UseFormHandleSubmit } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { OutlinedButton } from "../../../../../../../../../common/components/button/OutlinedButton";
import { PrimaryButton } from "../../../../../../../../../common/components/button/PrimaryButton";
import { InvoiceFooterState } from "../../../../../../../../../common/components/invoices/invoice-details/types/InvoiceFooterState";
import { MatchedOrderViewState } from "../../../enums/MatchedOrderViewState";
import { InvoiceCreateReleaseFormValues } from "../../matched-order/components/InvoiceVerificationForm";
import { ButtonContainer } from "../InvoiceFooterButtons";
import { useEditOrderButtons } from "./useEditOrderButtons";

export type EditOrderButtonsProps = {
  createReleaseFormHandleSubmit: UseFormHandleSubmit<
    InvoiceCreateReleaseFormValues,
    InvoiceCreateReleaseFormValues
  >;
};

export const EditOrderButtons: FC<EditOrderButtonsProps> = (props) => {
  const {
    saving,
    onSaveClick,
    updateDialogVisible,
    invoice,
    footerState,
    setFooterState,
    setMatchedOrderViewState,
    setUpdateDialogVisible,
    handleUpdateReleaseFromInvoice,
    release,
  } = useEditOrderButtons(props);

  if (footerState !== InvoiceFooterState.EDIT_ORDER) {
    return null;
  }

  return (
    <ButtonContainer>
      <OutlinedButton
        onClick={() => {
          if (invoice && !INVOICE_READONLY_STATUSES.includes(invoice?.status)) {
            setMatchedOrderViewState(
              MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
            );
          } else {
            setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
          }
          setFooterState(InvoiceFooterState.DEFAULT);
        }}
      >
        <FormattedMessage id="CLOSE" />
      </OutlinedButton>
      <PrimaryButton className="py-0" onClick={onSaveClick} loading={saving}>
        <FormattedMessage id="SAVE_ORDER" />
      </PrimaryButton>

      <UpdateReleaseDialog
        visible={updateDialogVisible}
        setVisible={setUpdateDialogVisible}
        callback={handleUpdateReleaseFromInvoice}
        status={release?.status || ReleaseStatus.Draft}
        withCompletionDialog={false}
      />
    </ButtonContainer>
  );
};

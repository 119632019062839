import { useDialog } from "@/common/components/dialog/DialogProvider";
import {
  ExportCompleted,
  ExportType,
} from "@/common/components/export-completed/ExportCompleted";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Progress } from "@/common/components/progress/Progress";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import {
  ExportBatchProvider,
  useExportBatch,
} from "@/common/providers/ExportBatchProvider";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { BatchType, ReleaseStatus } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useExportReleases } from "../../hooks/useExportReleases";
import { useHasMajorityDefaultSync } from "../../hooks/useHasMajorityDefaultSync";
import { useUpdateContractorReleases } from "../../hooks/useUpdateContractorReleases";
import {
  ReleaseConnectionOptionsProvider,
  useReleaseConnectionOptions,
} from "../../providers/ReleaseConnectionOptionsProvider";
import {
  ReleasesByIdsProvider,
  useReleasesByIds,
} from "../../providers/ReleasesByIdsProvider";
import { useBackgroundReleases } from "../buttons/hooks/useBackgroundReleases";
import { ReleaseConnectionOptions } from "../common/ReleaseConnectionOptions";
import { PoAgaveConnections } from "../connection-types/hosted/PoAgaveConnections";

const OverlayPanelStyled = tw(OverlayPanel)`justify-between gap-3`;
const CardList = tw.div`flex flex-col gap-3`;
const PanelTitle = tw.div`flex flex-row gap-1 items-center`;
const NoExportContainer = tw.div`flex gap-1 items-center bg-gray-100 p-4 text-sm text-gray-500 px-4 py-4 rounded-3xl`;

type Props = {
  onClose: () => void;
  releaseIds: string[];
  mode: ConnectionMode;
};

const ConnectPOsPanelWithProvider: FC<Props> = ({ onClose, mode }) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { releasesByIds, loading, validatedReleases } = useReleasesByIds();
  const { updateContractorReleases, loading: updating } =
    useUpdateContractorReleases();
  const {
    linkPos,
    linkingPos,
    updatePoLink,
    autoSync,
    syncPo,
    updatingPoLink,
    linkingPo,
    syncingPoLink,
    useSourceSystemPO,
    backgroundExport,
  } = useReleaseConnectionOptions();
  const { newBatch, externalBatch } = useExportBatch();
  const { hasMajorityDefaultSync, syncCount } =
    useHasMajorityDefaultSync(releasesByIds);
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { exportReleases } = useExportReleases();
  const { connectedSourceSystem } = useOrgSettings();
  const validatedReleasesCount = useMemo(
    () => validatedReleases.filter((i) => i.validated).length,
    [validatedReleases],
  );

  const { generateBackgroundPoLinkInputs } = useBackgroundReleases();
  const onSave = useCallback(async () => {
    if (backgroundExport) {
      if (!connectedSourceSystem) {
        return;
      }
      const inputs = generateBackgroundPoLinkInputs();
      exportReleases(inputs);
      onClose();
      return;
    }
    const shouldSyncPo =
      (mode === ConnectionMode.Edit || mode === ConnectionMode.Sync) &&
      releasesByIds[0].poLink?.id &&
      useSourceSystemPO;
    if (shouldSyncPo && releasesByIds[0].poLink?.id) {
      if (!(await updatePoLink(releasesByIds[0].poLink?.id))) {
        return;
      }

      if (!autoSync && !(await syncPo(releasesByIds[0].poLink?.id))) {
        return;
      }
    } else if (
      mode === ConnectionMode.Connect &&
      useSourceSystemPO &&
      !(await linkPos())
    ) {
      return;
    } else if (
      !useSourceSystemPO &&
      !(await updateContractorReleases({
        releaseIds: releasesByIds.map((r) => r.id),
        useSourceSystemPO: false,
      }))
    ) {
      return;
    }
    openDialog({
      content: (
        <ExportCompleted
          count={validatedReleasesCount}
          type={ExportType.ORDER}
          message={
            shouldSyncPo || !useSourceSystemPO
              ? intl.$t({ id: "CHANGES_SAVED" })
              : undefined
          }
        />
      ),
      closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      hideTitle: true,
    });
    onClose();
  }, [
    backgroundExport,
    mode,
    releasesByIds,
    useSourceSystemPO,
    linkPos,
    updateContractorReleases,
    openDialog,
    validatedReleasesCount,
    intl,
    onClose,
    connectedSourceSystem,
    generateBackgroundPoLinkInputs,
    exportReleases,
    updatePoLink,
    autoSync,
    syncPo,
  ]);

  const saveLabel = useMemo(() => {
    if (!useSourceSystemPO) {
      return intl.$t({ id: "SAVE" });
    }
    if (mode === ConnectionMode.Edit) {
      return intl.$t({ id: "UPDATE" });
    } else if (mode === ConnectionMode.Connect) {
      return intl.$t({ id: "EXPORT" });
    } else if (mode === ConnectionMode.Sync) {
      return intl.$t({ id: "SYNC" });
    }
  }, [intl, mode, useSourceSystemPO]);

  const title = useMemo(() => intl.$t({ id: "EXPORT_SETTINGS" }), [intl]);

  const disableSave = useMemo(() => {
    if (!useSourceSystemPO) {
      return false;
    }
    if (backgroundExport) {
      return false;
    }
    if (
      mode === ConnectionMode.Connect &&
      hasFeatureInConnectedSourceSystem(IntegrationFeature.POBatching) &&
      !newBatch &&
      !externalBatch
    ) {
      return true;
    }
    return (
      validatedReleasesCount === 0 || validatedReleases.some((v) => v.loading)
    );
  }, [
    useSourceSystemPO,
    backgroundExport,
    mode,
    hasFeatureInConnectedSourceSystem,
    newBatch,
    externalBatch,
    validatedReleasesCount,
    validatedReleases,
  ]);

  const saveClickHandle = useCallback(() => {
    if (
      mode === ConnectionMode.Connect &&
      releasesByIds.some((release) => release.status === ReleaseStatus.Draft) &&
      useSourceSystemPO &&
      !backgroundExport
    ) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        includeWarningIcon: true,
        title: intl.$t(
          { id: "CONNECT_ORDER_MODAL_TITLE" },
          { count: releasesByIds.length },
        ),
        text: intl.$t(
          { id: "CONNECT_ORDER_MODAL_BODY" },
          { count: releasesByIds.length },
        ),
        handleConfirm: onSave,
      });
      return;
    }
    onSave();
  }, [
    backgroundExport,
    intl,
    mode,
    onSave,
    openDialog,
    releasesByIds,
    useSourceSystemPO,
  ]);

  return (
    <OverlayPanelStyled
      title={
        <PanelTitle>
          {title}{" "}
          <InfoTooltip
            message={intl.$t({ id: "CONNECT_PO_TITLE_INFO" })}
            id="title"
            iconClassName="text-white"
          />
        </PanelTitle>
      }
      isLoading={loading}
      onCancel={onClose}
      onSave={saveClickHandle}
      saveLabel={saveLabel}
      disableSave={disableSave}
      saving={updatingPoLink || linkingPo || syncingPoLink || updating}
    >
      <CardList>
        <ReleaseConnectionOptions
          mode={mode}
          release={releasesByIds.length === 1 ? releasesByIds[0] : undefined}
          includeAutoSync
          includeNoExport
          majorityDefaultSync={
            hasMajorityDefaultSync && releasesByIds.length !== syncCount
          }
        />
        <If isTrue={useSourceSystemPO}>
          <PoAgaveConnections
            releases={releasesByIds}
            linkingPos={linkingPos}
            displayDisconnect={[
              ConnectionMode.Sync,
              ConnectionMode.Edit,
            ].includes(mode)}
            onClose={onClose}
            updatingPoLink={updatingPoLink}
          />
        </If>
        <If isTrue={!useSourceSystemPO}>
          <NoExportContainer>
            <InfoOutlined className="text-2xl text-gray-500" />
            <FormattedMessage
              id="ORDERS_SELECTED_NOT_TO_EXPORTED"
              values={{ count: releasesByIds.length }}
            />
          </NoExportContainer>
        </If>
      </CardList>
      <If isTrue={linkingPos.linking && releasesByIds.length > 1}>
        <Progress
          progress={linkingPos.percentage}
          label={intl.$t(
            { id: "EXPORTING_WITH_VALUE" },
            {
              value: (
                <FormattedNumber
                  value={linkingPos.percentage * 100}
                  maximumFractionDigits={0}
                />
              ),
            },
          )}
        />
      </If>
    </OverlayPanelStyled>
  );
};

export const ConnectReleasePOsPanel: FC<Props> = (props) => (
  <NestedStepperProvider>
    <ReleasesByIdsProvider releasesIds={props.releaseIds}>
      <ExportBatchProvider type={BatchType.PurchaseOrder}>
        <ReleaseConnectionOptionsProvider type="hosted">
          <ConnectPOsPanelWithProvider {...props} />
        </ReleaseConnectionOptionsProvider>
      </ExportBatchProvider>
    </ReleasesByIdsProvider>
  </NestedStepperProvider>
);

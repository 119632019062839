import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useGetPriceBreakdown } from "@/contractor/pages/home/inventory-management/pages/current-stock/hooks/useGetPriceBreakdown";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useIsRestockRelease } from "../../../hooks/useIsRestockRelease";
import { useIsWarehouseRelease } from "../../../hooks/useIsWarehouseRelease";
import { useRelease } from "../../../providers/ReleaseProvider";

export const useReleaseTableConfigHelpers = () => {
  const intl = useIntl();
  const { release } = useRelease();
  const getUnitPriceBreakdown = useGetPriceBreakdown();
  const { warehouses } = useWarehouses();
  const { getInventoryItem } = useTableHelpers();
  const { isWarehouseRelease } = useIsWarehouseRelease();
  const { isRestockRelease } = useIsRestockRelease();

  const uomIsDisabled = useCallback(
    (row: Record<string, string>) => {
      if (!row || rowIsEmpty(row)) {
        return "";
      }

      const assignedToInvoiceItem = release?.items.find(
        (item) =>
          item.id === row?.id &&
          !!item.poItemLink &&
          !!release?.poLink?.retroactive,
      );
      if (assignedToInvoiceItem) {
        return assignedToInvoiceItem.uom?.pluralDescription
          ? intl.$t({ id: "DISABLED_ITEM_ASSIGNED_TO_PO" })
          : "";
      }

      const supplier = row[COLUMN_TYPE.Supplier];
      const warehouseId = warehouses.find(
        (warehouse) => warehouse.name === supplier,
      )?.id;
      const item = getInventoryItem({
        material: row[COLUMN_TYPE.Material],
        uom: row[COLUMN_TYPE.UOM],
        supplier,
      });

      if (item && (warehouseId || isWarehouseRelease) && !isRestockRelease) {
        const remaining = getInventoryItemRemainingQuantity(item);
        if (remaining.greaterThan(0)) {
          return intl.$t({ id: "READ_ONLY" });
        }
      }

      return "";
    },
    [
      intl,
      release?.items,
      release?.poLink?.retroactive,
      getInventoryItem,
      isWarehouseRelease,
      warehouses,
      isRestockRelease,
    ],
  );

  const isPriceDisabled = useCallback(
    (row: Record<string, string>) => {
      if (row && !rowIsEmpty(row)) {
        const supplier = row[COLUMN_TYPE.Supplier];
        const warehouseId = warehouses.find(
          (warehouse) => warehouse.name === supplier,
        )?.id;
        const item = getInventoryItem({
          material: row[COLUMN_TYPE.Material],
          uom: row[COLUMN_TYPE.UOM],
          supplier,
        });
        const quantity = Number(row[COLUMN_TYPE.Quantity]);
        if (item && (warehouseId || isWarehouseRelease) && !isRestockRelease) {
          return getUnitPriceBreakdown(item, -quantity);
        }
      }
      return "";
    },
    [
      getInventoryItem,
      getUnitPriceBreakdown,
      isWarehouseRelease,
      warehouses,
      isRestockRelease,
    ],
  );

  return {
    uomIsDisabled,
    isPriceDisabled,
  };
};

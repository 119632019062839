import { useDialog } from "@/common/components/dialog/DialogProvider";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { ReactElement, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useArchiveInventoryItems } from "../../hooks/useArchiveInventoryItems";
import { useInventoryItems } from "../../hooks/useInventoryItems";
import { useInventoryItemsFilter } from "../../hooks/useInventoryItemsFilter";
import { getSelectedItemIdParts } from "../../utils/getSelectedItemIdParts";
import { useInventoryItemSequence } from "../current-stock-list/components/hooks/useInventoryItemSequence";

type Props = {
  deleteIcon: ReactElement;
};

export const useCurrentStockSideActions = ({ deleteIcon }: Props) => {
  const { selectedEntities, setSelectedEntities } = useInventoryItemSequence();
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { filter } = useInventoryItemsFilter();
  const { refetch, inventoryItems } = useInventoryItems();
  const { archiveInventoryItems } = useArchiveInventoryItems();

  const selectedItemIds = useMemo(() => {
    return selectedEntities.map((item) => {
      const { inventoryItemId } = getSelectedItemIdParts(item.id);
      return inventoryItemId;
    });
  }, [selectedEntities]);

  const deleteItems = useCallback(() => {
    if (selectedItemIds.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        includeWarningIcon: true,
        title: intl.$t(
          { id: "DELETE_INVENTORY_ITEM_QUESTION" },
          { number: selectedItemIds.length },
        ),
        text: intl.$t(
          { id: "DELETE_INVENTORY_ITEM_MESSAGE" },
          { number: selectedItemIds.length },
        ),
        handleConfirm: async () => {
          if (await archiveInventoryItems(selectedItemIds)) {
            setSelectedEntities([]);
            refetch();
          }
        },
      });
    }
  }, [
    openDialog,
    intl,
    archiveInventoryItems,
    setSelectedEntities,
    refetch,
    selectedItemIds,
  ]);

  const actions = useMemo(() => {
    const sideActions = [];
    const someItemsHasQuantity = selectedEntities.some((item) => {
      const inventoryItem = inventoryItems.find((i) => {
        const { inventoryItemId } = getSelectedItemIdParts(item.id);
        return i.id === inventoryItemId;
      });
      return getInventoryItemRemainingQuantity(inventoryItem).gt(0);
    });

    if (selectedEntities.length && filter?.archived !== true) {
      sideActions.push({
        icon: deleteIcon,
        tooltipKey: someItemsHasQuantity
          ? "SELECT_EMPTY_ITEMS"
          : "DELETE_ITEMS",
        disabled: someItemsHasQuantity,
        callback: deleteItems,
      });
    }

    return sideActions;
  }, [
    selectedEntities,
    filter?.archived,
    deleteIcon,
    deleteItems,
    inventoryItems,
  ]);

  return { actions, selectedEntities };
};

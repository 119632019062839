import {
  UpdateContractorReleaseInput,
  UpdateContractorReleaseOperation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useUpdateRelease } from "./useUpdateRelease";

export const useSubmitRelease = () => {
  const { updateRelease, updating } = useUpdateRelease();

  const submitRelease = useCallback(
    async (input: UpdateContractorReleaseInput) => {
      const updatedInput = {
        ...input,
        operation: UpdateContractorReleaseOperation.Submit,
      };
      return await updateRelease(updatedInput);
    },
    [updateRelease],
  );

  return {
    submitRelease,
    submitting: updating,
  };
};

import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  hiddenColumns: string[];
  setHiddenColumns: (hiddenColumns: string[]) => void;
};

export const useEditColumnsStore = create<State>()(
  devtools((set) => ({
    hiddenColumns: [],
    setHiddenColumns: (hiddenColumns: string[]) => set({ hiddenColumns }),
  })),
);

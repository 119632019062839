import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ProjectExtendedFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { NewDeliveryForm } from "./new-delivery-form/NewDeliveryForm";
import { NewOrderCreationType } from "./new-delivery-form/types/NewOrderCreationType";

type Props = {
  disabled?: boolean;
  wide?: boolean;
  project?: ProjectExtendedFieldsFragment | null;
  small?: boolean;
  label?: string;
  type?: NewOrderCreationType;
};

export const NewDeliveryButton: FC<Props> = ({
  disabled,
  wide,
  project,
  small = false,
  label,
  type,
}) => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <PrimaryButton
          wide={wide}
          $small={small}
          testId="new-release-button"
          onClick={() => togglePanel(true)}
          disabled={disabled}
        >
          {label ?? <FormattedMessage id="NEW_RELEASE" />}
        </PrimaryButton>
      )}
      content={(togglePanel) => (
        <NewDeliveryForm
          project={project}
          onClose={() => togglePanel(false)}
          type={type}
          title={label}
        />
      )}
    />
  );
};

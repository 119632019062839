import { useDialog } from "@/common/components/dialog/DialogProvider";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isNullOrEmptyString } from "@/common/utils/isNullOrEmptyString";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { PoFormat, ReleaseStatus, SourceSystem } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useInvoiceSequence } from "../../scanned-invoices/providers/InvoiceSequenceProvider";
import { MatchedOrderViewState } from "../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useApproveInvoice = () => {
  const { invoice, approveInvoice, setHasError } = useInvoiceVerification();
  const { release } = useRelease();
  const { calcExtPrice } = usePriceCalculation();
  const { connectedSourceSystem, settings } = useOrgSettings();
  const { setWarningAlert, setSystemAlert } = useSnackbar();
  const intl = useIntl();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { selectedInvoices, navigateToNextSequence } = useInvoiceSequence();
  const {
    useInvoiceItemPrice,
    setMatchedOrderViewState,
    matchedOrderViewState,
  } = useInvoiceMatchedOrder();

  const processApproveInvoice = useCallback(async () => {
    const hasMissingCostCodes = (release?.items ?? []).some(
      (releaseItem) =>
        !releaseItem.costCode ||
        releaseItem.costCode.id === UNSPECIFIED_COST_CODE_ID,
    );
    if (
      await approveInvoice(
        {
          invoiceId: invoice?.id ?? "",
        },
        { hasMissingCostCodes },
      )
    ) {
      setHasError(false);
      setSystemAlert(
        intl.$t(
          {
            id:
              connectedSourceSystem &&
              settings?.integrations.sourceSystems.find(
                (sourceSystem) => sourceSystem.connected,
              )?.autoExportApprovedInvoices
                ? "INVOICE_WAS_APPROVED_AND_QUEUED"
                : "INVOICE_APPROVED",
          },
          {
            integration: connectedSourceSystem
              ? intl.$t({ id: `SOURCE_SYSTEM_${connectedSourceSystem}` })
              : "",
            invoiceNumber: invoice?.number,
          },
        ),
      );
      setTimeout(() => {
        if (selectedInvoices.length > 1) {
          const index = selectedInvoices.findIndex(
            (currentInvoice) => currentInvoice.id === invoice?.id,
          );
          const isLastIndex = index === selectedInvoices.length - 1;
          if (isLastIndex) {
            navigate(routes.invoices);
          } else {
            navigateToNextSequence({ navigateToInvoices: true });
            setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
          }
        } else {
          setMatchedOrderViewState(
            invoice?.headerOnly
              ? MatchedOrderViewState.HEADER_ONLY_INVOICE
              : MatchedOrderViewState.DEFAULT,
          );
        }
      }, 1600);
    }
  }, [
    release?.items,
    approveInvoice,
    invoice?.id,
    invoice?.number,
    invoice?.headerOnly,
    setHasError,
    setSystemAlert,
    intl,
    connectedSourceSystem,
    settings?.integrations.sourceSystems,
    selectedInvoices,
    navigate,
    navigateToNextSequence,
    setMatchedOrderViewState,
  ]);

  const approve = useCallback(async () => {
    setHasError(false);
    if (
      matchedOrderViewState !==
        MatchedOrderViewState.HEADER_ONLY_INVOICE_EDITABLE &&
      matchedOrderViewState !== MatchedOrderViewState.HEADER_ONLY_INVOICE
    ) {
      if (!invoice?.number) {
        setWarningAlert(
          intl.$t({
            id: "INVOICE_NUMBER_MUST_BE_SET",
          }),
        );
        setHasError(true);
        return;
      }
      if (!invoice?.issueDate) {
        setWarningAlert(
          intl.$t({
            id: "INVOICE_ISSUE_DATE_MUST_BE_SET",
          }),
        );
        setHasError(true);
        return;
      }
      const totalInvoiced = (
        release?.items
          .filter((i) => !!i.invoiceItems?.[0]?.id)
          .reduce(
            (acc, item) =>
              acc.plus(
                calcExtPrice(
                  item.invoiceItems?.[0]?.quantity,
                  useInvoiceItemPrice
                    ? item.invoiceItems?.[0]?.unitPrice || 0
                    : item.unitPrice || 0,
                ),
              ),
            new DecimalSafe(0),
          ) || new DecimalSafe(0)
      )
        .plus(invoice?.chargesAmount || 0)
        .plus(invoice?.taxAmount || 0);
      if (
        !totalInvoiced.equals(invoice?.total || 0) &&
        release?.type.poFormat !== PoFormat.Basic &&
        connectedSourceSystem !== SourceSystem.Foundation
      ) {
        setWarningAlert(
          intl.$t({ id: "INVOICE_APPROVE_ERROR_DIFFERENT_TOTALS" }),
        );
        setHasError(true);
        return;
      }
      if (!totalInvoiced.equals(invoice?.total || 0)) {
        openDialog({
          includeWarningIcon: true,
          title: intl.$t({ id: "INVOICE_TOTAL_MISMATCH" }),
          text: intl.$t(
            { id: "INVOICE_TOTAL_MISMATCH_TEXT" },
            {
              additionalInfo:
                invoice?.release?.status === ReleaseStatus.Reserved
                  ? `${intl.$t({ id: "RELEASE_TRANSFER_TO_RESERVED" }, { releaseNumber: invoice?.release?.sequenceNumber })}. `
                  : "",
            },
          ),
          confirmButtonText: intl.$t({ id: "APPROVE" }),
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          handleConfirm: processApproveInvoice,
        });
        return;
      }
      if (
        includePoNumbering &&
        invoice?.poNumber !== invoice?.release?.poNumber &&
        !(
          isNullOrEmptyString(invoice?.poNumber) &&
          isNullOrEmptyString(invoice?.release?.poNumber)
        )
      ) {
        setWarningAlert(intl.$t({ id: "INVOICE_APPROVE_ERROR_SAME_PO" }));

        setHasError(true);
        return;
      }
    }
    if (invoice?.orgDuplicate) {
      setWarningAlert(
        intl.$t({
          id: "INVOICE_WITH_THE_SAME_NUMBER_ALREADY_EXISTS_IN_SYSTEM",
        }),
      );
      setHasError(true);
      return;
    }
    if (invoice?.vendorDuplicate) {
      setWarningAlert(
        intl.$t({
          id: "INVOICE_WITH_THE_SAME_NUMBER_ALREADY_EXISTS_FOR_VENDOR",
        }),
      );
      setHasError(true);
      return;
    }
    if (invoice?.release?.status === ReleaseStatus.Reserved) {
      openDialog({
        includeWarningIcon: true,
        title: intl.$t({ id: "CONFIRM_INVOICE" }),
        text: intl.$t(
          { id: "RELEASE_TRANSFER_TO_RESERVED" },
          { releaseNumber: invoice?.release?.sequenceNumber },
        ),
        confirmButtonText: intl.$t({ id: "APPROVE" }),
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        handleConfirm: processApproveInvoice,
      });
      return;
    }
    await processApproveInvoice();
  }, [
    setHasError,
    invoice?.number,
    invoice?.issueDate,
    invoice?.chargesAmount,
    invoice?.taxAmount,
    invoice?.total,
    invoice?.orgDuplicate,
    invoice?.vendorDuplicate,
    invoice?.poNumber,
    invoice?.release?.poNumber,
    invoice?.release?.status,
    invoice?.release?.sequenceNumber,
    release?.items,
    release?.type.poFormat,
    connectedSourceSystem,
    matchedOrderViewState,
    includePoNumbering,
    processApproveInvoice,
    setWarningAlert,
    intl,
    calcExtPrice,
    useInvoiceItemPrice,
    openDialog,
  ]);

  return {
    approve,
  };
};

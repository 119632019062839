import { WatcherTagPicker } from "@/common/components/watchers/WatcherTagPicker";
import { useUsers } from "@/common/hooks/useUsers";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

type Props = {
  triggerMutation?: boolean;
  moreTagCount?: number;
  verticalItems?: boolean;
  readonly?: boolean;
  classes?: {
    popper?: string;
  };
};

export const ReleaseWatchers = ({
  triggerMutation = false,
  moreTagCount,
  verticalItems = false,
  classes,
}: Props) => {
  const { release } = useRelease();
  const { updateRelease } = useReleaseUpdate();
  const [updating, setUpdating] = useState(false);
  const intl = useIntl();

  const { users } = useUsers({
    requireLocation: true,
    locationId: release?.project?.location.id,
  });

  const onRemove = useCallback(
    async (id: string) => {
      if (!release) {
        return;
      }
      setUpdating(true);
      try {
        await updateRelease(
          {
            releaseId: release.id,
            version: release.version,
            watcherIds: release.watchers
              .filter((watcher) => watcher.id !== id)
              .map((g) => g.id),
            skipConfirmation: true,
            skipVendorNotification: true,
          },
          { batch: !triggerMutation },
        );
      } finally {
        setUpdating(false);
      }
    },
    [release, triggerMutation, updateRelease],
  );

  const onAdd = useCallback(
    async (userId: string | null) => {
      if (!release || !userId) {
        return;
      }
      setUpdating(true);
      try {
        await updateRelease(
          {
            releaseId: release.id,
            version: release.version,
            watcherIds: [...(release.watchers || []).map((g) => g.id), userId],
            skipConfirmation: true,
            skipVendorNotification: true,
          },
          { batch: !triggerMutation },
        );
      } finally {
        setUpdating(false);
      }
    },
    [release, triggerMutation, updateRelease],
  );

  const existingWatchers = useMemo(
    () => release?.watchers.map((watcher) => watcher.id) || [],
    [release?.watchers],
  );

  if (!release || !users) {
    return null;
  }

  return (
    <WatcherTagPicker
      classes={classes}
      users={users}
      existingWatchers={existingWatchers}
      moreTagCount={moreTagCount || users.length}
      onRemove={onRemove}
      onAdd={onAdd}
      loading={updating}
      placeholder={intl.$t({ id: "SELECT_WATCHERS" })}
      verticalItems={verticalItems}
      readonly={release && !isAuthorized(release?.permissions.edit)}
    />
  );
};

import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useInvoiceVerification } from "../../../../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../../../InvoiceVerificationForm";

export const useInvoiceHeaderOnly = () => {
  const { setValue } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { invoice } = useInvoiceVerification();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue("invoice.number", invoice?.number || "");
    setValue("invoice.projectId", invoice?.project?.id || null);
    setValue(
      "invoice.issueDate",
      invoice?.issueDate ? new Date(invoice.issueDate) : null,
    );
    setValue(
      "invoice.dueDate",
      invoice?.dueDate ? new Date(invoice.dueDate) : null,
    );
    setValue("invoice.poNumber", invoice?.poNumber || "");
    setValue(
      "invoice.sellerOrgLocationId",
      invoice?.sellerOrgLocation?.id || null,
    );
    setValue("invoice.projectId", invoice?.project?.id || null);
    setValue("invoice.description", invoice?.description || "");
    setValue("invoice.subtotal", invoice?.subtotal || "0");
    setValue("invoice.taxAmount", invoice?.taxAmount || "0");
    setValue("invoice.chargesAmount", invoice?.chargesAmount || "0");
  }, [invoice, setValue]);

  return { open, setOpen };
};

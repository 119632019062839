import { gql } from "@apollo/client";
import { COST_CODE_FIELDS } from "../../contractor/pages/admin/cost-structure/pages/cost-codes/fragments/costCodeFragment";
import { PROJECT_MATERIAL_EXTENDED_FIELDS } from "../../contractor/pages/admin/org-items/pages/materials/fragments/projectMaterialExtendedFragment";
import { SOURCE_SYSTEM_MAPPING_FIELDS } from "../../contractor/pages/admin/org-settings/fragments/sourceSystemMappingFragment";
import { ADDRESS_FIELDS } from "./addressFragment";
import { MANUFACTURER_FIELDS } from "./manufacturerFragment";
import { ORG_LOCATION_FIELDS } from "./orgLocationsFragment";
import { ZONE_FIELDS } from "./zoneFragment";

export const PROJECT_FIELDS = gql`
  ${ADDRESS_FIELDS}
  ${ZONE_FIELDS}
  ${ORG_LOCATION_FIELDS}
  ${COST_CODE_FIELDS}
  ${PROJECT_MATERIAL_EXTENDED_FIELDS}
  ${MANUFACTURER_FIELDS}
  ${SOURCE_SYSTEM_MAPPING_FIELDS}
  fragment ProjectFields on Project {
    id
    name
    status
    budget
    deletedAt
    startDate
    percentageOfBudgetSpent
    jobNumber
    taxExempt
    estimatedItemGroups {
      costCode {
        ...CostCodeFields
      }
      estimatedItemCount
    }
    team {
      id
      firstName
      lastName
      email
      photoUrl
    }
    address {
      ...AddressFields
    }
    location {
      ...OrgLocationFields
      org {
        id
        costCodes {
          ...CostCodeFields
        }
      }
    }
    items {
      id
      material {
        ...ProjectMaterialExtendedFields
      }
      manufacturer {
        ...ManufacturerFields
      }
      estimateUom {
        ...UOMFields
      }
      estimatedItems {
        id
      }
      buyoutItems {
        id
      }
    }
    zones {
      ...ZoneFields
    }
    mappings {
      ...SourceSystemMappingFields
    }
    permissions {
      edit
      createTag
      report
    }
    poJobNumber
    restrictCostCodes
    usesProjectCostCodes
    preferredInventoryInflows
  }
`;

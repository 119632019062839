import {
  TotalProps,
  usePriceCalculation,
} from "@/common/hooks/usePriceCalculation";
import { TaxType } from "@/generated/graphql";
import { useMemo } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { useTaxCalculation } from "../../sales-tax-input/hooks/useTaxCalculation";

export type ReleaseAdditionalChargesInputFormContext = TotalProps & {
  taxType: TaxType | null | undefined;
};

type Props = {
  form?: UseFormReturn<
    ReleaseAdditionalChargesInputFormContext,
    unknown,
    ReleaseAdditionalChargesInputFormContext
  >;
  taxable?: boolean;
};

export const useReleaseAdditionalChargesInput = (
  props: Props = {
    taxable: true,
  },
) => {
  const { watch: defaultWatch } =
    useFormContext<ReleaseAdditionalChargesInputFormContext>();
  const watch = props?.form?.watch || defaultWatch;
  const { calcAdditionalChargesPrice } = usePriceCalculation();
  const { getTaxAmount } = useTaxCalculation();

  const [
    netAmount,
    customTaxAmount,
    taxCodeId,
    taxRate,
    orderTypeId,
    additionalCharges,
    taxVariance,
    taxableNetAmount,
    taxType,
  ] = watch([
    "netAmount",
    "customTaxAmount",
    "taxCodeId",
    "taxRate",
    "orderTypeId",
    "additionalCharges",
    "taxVariance",
    "taxableNetAmount",
    "taxType",
  ]);
  const taxAmount = useMemo(
    () => getTaxAmount(taxRate, customTaxAmount, netAmount),
    [customTaxAmount, getTaxAmount, netAmount, taxRate],
  );

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxAmount,
      taxRate,
      orderTypeId,
      netAmount,
      taxCodeId,
      taxType,
      chargesAmount: calcAdditionalChargesPrice(additionalCharges),
      taxVariance,
      taxableNetAmount: props?.taxable ? taxableNetAmount : netAmount,
    }),
    [
      customTaxAmount,
      taxAmount,
      taxRate,
      orderTypeId,
      netAmount,
      taxCodeId,
      taxType,
      calcAdditionalChargesPrice,
      additionalCharges,
      taxVariance,
      props?.taxable,
      taxableNetAmount,
    ],
  );

  return {
    releaseInput,
  };
};

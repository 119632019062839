import { Select } from "@/common/components/select/components/single/Select";
import { Switch } from "@/common/components/switch/Switch";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ReceiptFieldsFragment } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReceiptPaymentMethod } from "./useReceiptPaymentMethod";

const Container = tw.div`flex gap-2 py-1.5 rounded-4xl items-center font-normal`;
const InfoIcon = tw(InfoOutlined)`text-blue-500`;
const PaymentInfo = tw.div`text-white`;

export type ReceiptPaymentMethodProps = {
  receipt: ReceiptFieldsFragment | null;
  selectedPaymentMethodId: string | undefined;
  setSelectedPaymentMethodId: (id: string | null) => void;
  onPaidToggle: (value: boolean) => void;
};

export const ReceiptPaymentMethod: FC<ReceiptPaymentMethodProps> = ({
  receipt,
  selectedPaymentMethodId,
  setSelectedPaymentMethodId,
  onPaidToggle,
}) => {
  const intl = useIntl();
  const {
    isPaid,
    togglePaid,
    paymentMethodsOptions,
    includeToggle,
    loadingPaymentMethods,
  } = useReceiptPaymentMethod({ receipt, onPaidToggle });

  if (loadingPaymentMethods) {
    return null;
  }
  return (
    <Container className={!includeToggle ? "" : "bg-blue-800 px-4"}>
      {includeToggle ? (
        <>
          <FormattedMessage
            id="WILL_THIS_RECEIPT_BE_PAID_VIA_INVOICE"
            tagName={PaymentInfo}
          />
          <Tooltip
            id="receipt-info"
            element={<InfoIcon className="text-white" />}
          >
            <FormattedMessage id="WILL_THIS_RECEIPT_BE_PAID_VIA_INVOICE_INFO" />
          </Tooltip>
          <Switch
            width={60}
            offLabel={intl.$t({ id: "NO" })}
            onLabel={intl.$t({ id: "YES" })}
            onChange={togglePaid}
            value={isPaid}
          />
        </>
      ) : (
        <Select
          label={intl.$t({ id: "PAYMENT_METHOD" })}
          InputLabelProps={{
            color: "error",
          }}
          placeholder={intl.$t({ id: "PAYMENT_METHOD" })}
          options={paymentMethodsOptions}
          loading={loadingPaymentMethods}
          getValue={(paymentMethod) => paymentMethod.id}
          getLabel={(paymentMethod) => paymentMethod.label}
          value={selectedPaymentMethodId}
          onChange={setSelectedPaymentMethodId}
          sx={{
            ".MuiFormLabel-root": {
              backgroundColor: "white !important",
            },
          }}
        />
      )}
    </Container>
  );
};

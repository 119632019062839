import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { InvoiceCreateReleaseFormValues } from "../../../InvoiceVerificationForm";

export const useInvoiceHeaderOnlyTotals = () => {
  const { watch } = useFormContext<InvoiceCreateReleaseFormValues>();
  const watchFields = watch([
    "invoice.subtotal",
    "invoice.chargesAmount",
    "invoice.taxAmount",
  ]);

  const total = useMemo(
    () =>
      new DecimalSafe(watchFields[0] || 0)
        .add(watchFields[1] || 0)
        .add(watchFields[2] || 0)
        .toString(),
    [watchFields],
  );

  return {
    total,
  };
};

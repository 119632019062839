import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  UpdateContractorReleaseInput,
  UpdateContractorReleaseOperation,
  useScheduleReleaseMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useScheduleRelease = () => {
  const [scheduleReleaseMutation, { loading: scheduling }] =
    useScheduleReleaseMutation();
  const { setError } = useGlobalError();

  const scheduleRelease = useCallback(
    async (input: UpdateContractorReleaseInput) => {
      try {
        const result = await scheduleReleaseMutation({
          variables: {
            input: {
              ...input,
              operation: UpdateContractorReleaseOperation.Schedule,
            },
          },
        });
        setError(result.errors);
        return {
          data: result.data?.updateContractorRelease,
          errors: result.errors,
        };
      } catch (error) {
        setError(error);
        return null;
      }
    },
    [scheduleReleaseMutation, setError],
  );

  return {
    scheduleRelease,
    scheduling,
  };
};

import { sumQuoteGroupFullPrices } from "@/common/components/price/utils/sumQuoteGroupFullPrices";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { QuoteStatus } from "@/generated/graphql";
import Decimal from "decimal.js";
import { createContext, FC, useContext, useMemo } from "react";
import { usePriceCalculation } from "../../../../../common/hooks/usePriceCalculation";
import { useRfqQuoteOptions } from "./RfqQuoteOptionsProvider";
import { AuxQuoteItem, QuoteItem, useRfqQuotes } from "./RfqQuotesProvider";

type QuoteItemPrice = {
  quoteItemId: string;
  price: Decimal;
  priceDiscount: number;
  unitPrice: Decimal;
  unitDiscount: number;
  exactMatchOrMore: boolean;
};

export type QuotePriceWithIndex = {
  quoteItemPrice: QuoteItemPrice | null;
  index: number;
  isLast: boolean;
};

type ItemWithPrices = {
  rfqItemId: string;
  quoteItemsPrice: QuoteItemPrice[];
};

type ProviderContextType = {
  items: ItemWithPrices[];
  findPriceByItemAndQuoteItem: (
    rfqItemId: string,
    quoteItemId: string,
  ) => QuotePriceWithIndex | undefined;
  selectEntireQuoteByBestPrice: (
    quoteId: string,
    rfqItemId?: string,
  ) => {
    quoteItems: QuoteItem[];
    auxQuoteItems: AuxQuoteItem[];
  };
};

const ProviderContext = createContext<ProviderContextType>({
  items: [],
  findPriceByItemAndQuoteItem: () => undefined,
  selectEntireQuoteByBestPrice: () => ({
    quoteItems: [],
    auxQuoteItems: [],
  }),
});

export const BidsPricesProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { rfq, allQuoteItems } = useRfqQuotes();
  const { visibleQuotes } = useRfqQuoteOptions();
  const { calcExtPrice } = usePriceCalculation();
  const items = useMemo<ItemWithPrices[]>(() => {
    const items: ItemWithPrices[] = [];
    if (rfq) {
      rfq.items.forEach((item) => {
        const quotesPrice: QuoteItemPrice[] = [];
        visibleQuotes.forEach((quote) => {
          const quoteItems = quote.itemGroups.find(
            (qItem) => qItem.rfqItem.id === item.id,
          )?.quoteItems;
          quoteItems?.forEach((quoteItem) => {
            if (quoteItem) {
              const price = new DecimalSafe(
                calcExtPrice(
                  quoteItem.quantityDecimal || 0,
                  quoteItem.unitPrice || 0,
                ),
              );
              const totalSum = new DecimalSafe(
                quote?.itemGroups.reduce(sumQuoteGroupFullPrices, 0),
              );
              let unitDiscount = new DecimalSafe(0);
              let priceDiscount = new DecimalSafe(0);
              if (quote.discount && quoteItem.unitPrice) {
                const amountMultiplier = quote.discount.amount
                  ? new DecimalSafe(quote.discount.amount || 0).dividedBy(
                      totalSum,
                    )
                  : new DecimalSafe(quote.discount.percentage || 0).dividedBy(
                      100,
                    );
                priceDiscount = amountMultiplier.mul(price);
                unitDiscount = amountMultiplier.mul(
                  new DecimalSafe(quoteItem.unitPrice),
                );
              }

              if (quoteItem.unitPrice && quoteItem.quantityDecimal) {
                quotesPrice.push({
                  quoteItemId: quoteItem.id,
                  price: price.sub(priceDiscount),
                  priceDiscount: Number(priceDiscount),
                  unitPrice: new DecimalSafe(quoteItem.unitPrice).sub(
                    unitDiscount,
                  ),
                  unitDiscount: Number(unitDiscount),
                  exactMatchOrMore:
                    new DecimalSafe(quoteItem.quantityDecimal).gte(
                      item.quantityDecimal,
                    ) &&
                    new DecimalSafe(quoteItem.quantityDecimal).lte(
                      new DecimalSafe(item.quantityDecimal).plus(
                        new DecimalSafe(item.quantityDecimal).mul(0.1),
                      ),
                    ),
                });
              }
            }
          });
        });
        items.push({
          rfqItemId: item.id,
          quoteItemsPrice: quotesPrice.sort((a, b) =>
            a.exactMatchOrMore && b.exactMatchOrMore
              ? Number(a.unitPrice.sub(b.unitPrice))
              : a.exactMatchOrMore
                ? -1
                : b.exactMatchOrMore
                  ? 1
                  : a.unitPrice.equals(b.unitPrice)
                    ? Number(b.price.sub(a.price))
                    : Number(a.unitPrice.sub(b.unitPrice)),
          ),
        });
      });
    }
    return items;
  }, [rfq, visibleQuotes, calcExtPrice]);

  const findPriceByItemAndQuoteItem = (
    rfqItemId: string,
    quoteItemId: string,
  ) => {
    const emptyItem = {
      quoteItemPrice: null,
      index: -1,
      isLast: true,
    };

    const itemIndex = items.findIndex(
      (priceItem) => priceItem.rfqItemId === rfqItemId,
    );
    if (itemIndex !== -1) {
      const item = items[itemIndex];
      const quotePriceIndex = item.quoteItemsPrice.findIndex(
        (price) => price.quoteItemId === quoteItemId,
      );
      if (quotePriceIndex !== -1) {
        const quoteItemPrice = item.quoteItemsPrice[quotePriceIndex];
        return {
          quoteItemPrice,
          index: quotePriceIndex,
          isLast: quotePriceIndex === item.quoteItemsPrice.length - 1,
        };
      } else {
        return emptyItem;
      }
    } else {
      return emptyItem;
    }
  };

  const selectEntireQuoteByBestPrice = (
    quoteId: string,
    rfqItemId?: string,
  ) => {
    const selectedQuotes =
      rfq?.items.map((rItem) => {
        const ranking =
          allQuoteItems
            .filter(
              (qi) =>
                qi.quoteId === quoteId &&
                qi.rfqItemId === rItem.id &&
                qi.status !== QuoteStatus.Withdrawn,
            )
            .map(
              (qi) =>
                findPriceByItemAndQuoteItem(rItem.id, qi.id)?.index ?? 1e4,
            ) ?? [];
        const quote = visibleQuotes.find((q) => q.id === quoteId);
        const quoteItem = allQuoteItems.find(
          (qi) =>
            qi.quoteId === quote?.id &&
            qi.rfqItemId === rItem.id &&
            rfqItemId !== rItem.id &&
            findPriceByItemAndQuoteItem(rItem.id, qi.id)?.index ===
              Math.min(...ranking),
        );

        return {
          rfqItemId: quoteItem?.rfqItemId ?? "",
          quoteId: quoteItem?.quoteId ?? "",
          quoteItemId: quoteItem?.id ?? "",
        };
      }) ?? [];

    const selectedAuxItems =
      rfq?.quotes
        .find((quote) => quote.id === quoteId)
        ?.auxiliaryItems.map((auxItem) => ({
          itemId: auxItem.id,
          quoteId,
        })) ?? [];

    return {
      quoteItems: selectedQuotes,
      auxQuoteItems: selectedAuxItems,
    };
  };

  return (
    <ProviderContext.Provider
      value={{
        items,
        findPriceByItemAndQuoteItem,
        selectEntireQuoteByBestPrice,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useBidsPrices = (): ProviderContextType =>
  useContext(ProviderContext);

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { getPhysicalRowIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalRowIndex";
import { setTableCells } from "@/common/components/spreadsheet-table/utils/setTableCells";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { useCallback } from "react";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useCopyInvoicePrices = () => {
  const { handsonInstance, spreadsheetData, setMetadata } = useColumnMapper();
  const { invoice } = useInvoiceVerification();
  const { calcExtPrice } = usePriceCalculation();

  const copyPriceFromInvoice = useCallback(() => {
    if (!handsonInstance || !invoice) {
      return;
    }

    const updatedCells = [] as [number, number, string | number][];
    spreadsheetData?.forEach((row, index) => {
      if (row.id) {
        const match = invoice.releaseItemHints.find(
          (hint) => hint?.releaseItem.id === row.id,
        );

        const matchingInvoiceItem = match?.invoiceItem;
        const matchingReleaseItem = match?.releaseItem;

        if (matchingInvoiceItem) {
          const copiedPrice = matchingInvoiceItem.unitPrice;
          const extPrice = calcExtPrice(
            matchingInvoiceItem.quantityDecimal,
            matchingInvoiceItem.unitPrice,
          );
          const previousPrice = matchingReleaseItem?.unitPrice;
          setMetadata(index, { extPrice, copiedPrice, previousPrice });

          updatedCells.push([
            getPhysicalRowIndex(handsonInstance, index),
            getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.UnitPrice),
            matchingInvoiceItem.unitPrice,
          ]);

          updatedCells.push([
            getPhysicalRowIndex(handsonInstance, index),
            getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.ExtPrice),
            extPrice,
          ]);
        }
      }
    });
    setTableCells(updatedCells, handsonInstance);
  }, [calcExtPrice, handsonInstance, invoice, setMetadata, spreadsheetData]);

  return { copyPriceFromInvoice };
};

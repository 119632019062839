import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { SpreadSheetConfig } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { useUnspecifiedZone } from "@/common/hooks/useUnspecifiedZone";
import { autocompleteRenderer } from "handsontable/renderers";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useProjectCostCodes } from "../../../../hooks/useProjectCostCodes";
import { useProjectStore } from "../../../../store/useProjectStore";
import { BudgetHeaderFormValues } from "./BudgetHeaderForm";

export const useBudgetByCostCodesConfiguration = () => {
  const intl = useIntl();
  const { watch } = useFormContext<BudgetHeaderFormValues>();
  const zoneSpecificBudget = watch("zoneSpecificBudget");
  const { zones } = useProjectStore(
    useShallow((state) => ({
      zones: state.zones,
    })),
  );
  const { allCostCodes } = useProjectCostCodes();
  const { requiredValidator, uniqueValidator, combineValidators } =
    useTableValidators();
  const { unassignedZone } = useUnspecifiedZone();
  const allZones = useMemo(
    () => [unassignedZone, ...zones],
    [unassignedZone, zones],
  );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(() => {
    const columns: SpreadSheetConfig[] = [
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode.code",
        columnType: COLUMN_TYPE.CostCode,
        options: allCostCodes.map((costCode) => costCode.formatted),
        validator: combineValidators([requiredValidator, uniqueValidator]),
        renderer: autocompleteRenderer,
      },
      {
        header: intl.$t({ id: "BUDGET" }),
        columnId: "amount",
        columnType: COLUMN_TYPE.Budget,
        hidden: zoneSpecificBudget,
      },
      ...allZones.map((zone) => ({
        header: zone.name,
        columnId: `zones.${zone.id}.amount`,
        columnType: `${COLUMN_TYPE.Budget}_${zone.id}`,
        hidden: !zoneSpecificBudget,
      })),
    ];
    return columns;
  }, [
    intl,
    allCostCodes,
    combineValidators,
    requiredValidator,
    uniqueValidator,
    zoneSpecificBudget,
    allZones,
  ]);

  return { spreadsheetConfig };
};

import {
  AdditionalChargesContainer,
  ItemContainer,
} from "@/common/components/additional-charges/AdditionalCharges.styles";
import { PricePickerControlled } from "@/common/components/price-picker/PricePickerControlled";
import { Price } from "@/common/components/price/Price";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceHeaderOnlyTotals } from "./useInvoiceHeaderOnlyTotals";

type Props = {
  readonly?: boolean;
};

const PricePickerControlledStyled = tw(
  PricePickerControlled,
)`text-right text-sm font-normal`;
const DashedDivider = tw.div`border-dashed border-t border-gray-400 w-full`;

export const InvoiceHeaderOnlyTotals = ({ readonly }: Props) => {
  const { total } = useInvoiceHeaderOnlyTotals();
  return (
    <AdditionalChargesContainer className="mt-5 w-full flex-1 justify-end">
      <ItemContainer>
        <FormattedMessage id="SUBTOTAL" />
        <PricePickerControlledStyled
          testId="subtotal"
          name="invoice.subtotal"
          staticText={readonly}
        />
      </ItemContainer>
      <ItemContainer>
        <FormattedMessage id="ADDITIONAL_CHARGES" />
        <PricePickerControlledStyled
          testId="additional-charges"
          name="invoice.chargesAmount"
          staticText={readonly}
        />
      </ItemContainer>
      <ItemContainer>
        <FormattedMessage id="SALES_TAX" />
        <PricePickerControlledStyled
          testId="sales-tax"
          name="invoice.taxAmount"
          staticText={readonly}
        />
      </ItemContainer>
      <ItemContainer>
        <span />
        <DashedDivider />
      </ItemContainer>
      <ItemContainer>
        <FormattedMessage id="TOTAL" />
        <Price testId="total" price={total} className="text-sm font-normal" />
      </ItemContainer>
    </AdditionalChargesContainer>
  );
};

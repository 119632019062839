import { useIsTextOverflow } from "@/common/utils/useIsTextOverflow";
import { isEmptyString } from "@/common/utils/validationUtils";
import { EventKeysConstants } from "@/config/constants/eventKeysContants";
import { NoFunction } from "@/types/NoFunction";
import { FC, KeyboardEvent, useRef } from "react";
import tw from "tailwind-styled-components";
import { TextField, TextFieldProps } from "../textfield/TextField";
import { Tooltip } from "../tooltip/Tooltip";

type ProductNameProps = {
  name?: string;
  className?: string;
  readonlyClassName?: string;
  setName?: (description: string) => void;
  readonly?: boolean;
  resetName?: () => void;
  error?: boolean;
};

const NameContainer = tw.div`text-sm font-light`;
const TextFieldStyled: FC<TextFieldProps> = tw(TextField)`
  w-full
`;
const Name = tw.div`
  w-full whitespace-normal
`;

export const ProductName: FC<ProductNameProps> = ({
  name = "",
  className,
  readonlyClassName,
  setName = NoFunction,
  resetName = NoFunction,
  readonly = true,
}) => {
  const ref = useRef(null);
  const { isTextOverflow } = useIsTextOverflow(ref);

  return (
    <NameContainer className={className}>
      {readonly ? (
        <Tooltip
          element={
            <Name ref={ref} className={readonlyClassName}>
              {name}
            </Name>
          }
        >
          {isTextOverflow ? name : null}
        </Tooltip>
      ) : (
        <TextFieldStyled
          size="small"
          required
          className="w-full bg-white"
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value)
          }
          InputProps={{
            className: "p-2 text-sm",
          }}
          multiline
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === EventKeysConstants.Escape) {
              resetName();
            }
          }}
          autoFocus
          onFocusCapture={(input: React.FocusEvent<HTMLInputElement>) => {
            if (input) {
              const end = input.target.value.length;

              input.target.setSelectionRange(end, end);
            }
          }}
          error={isEmptyString(name)}
        />
      )}
    </NameContainer>
  );
};

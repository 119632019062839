import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { composeMaterialName } from "@/common/utils/composeMaterialName";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectTags } from "@/contractor/pages/home/project/hooks/useProjectTags";
import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import { useMemo } from "react";
import { isOrgCatalogSku } from "../../material/utils";
import { vendorLabelFormatter } from "../../supplier-picker/utils/vendorLabelFormatter";
import { useVendors } from "../../vendors/hooks/useVendors";

export const useTableDropdownOptions = () => {
  const { uoms } = useUomOptions();
  const { manufacturers } = useManufacturers();
  const { zones } = useProjectZones();
  const { tags, phaseCodes } = useProjectTags();
  const { projectCostCodes } = useProjectCostCodes();
  const { costTypes, formatCostType } = useCostTypes();
  const { materials } = useMaterials();
  const { vendors, getVendorCode } = useVendors();
  const { warehouses } = useWarehouses();
  const { settings } = useOrgSettings();

  const itemOptions = useMemo(
    () =>
      materials.map((m) => {
        if (isOrgCatalogSku(m?.material)) {
          // TODO @Constantin, can you check the third param? I've added it similar to the useTableHelpers
          return composeMaterialName(
            m,
            materials,
            !!settings?.display?.appendExternalCode,
          );
        } else {
          return m.material.name;
        }
      }),
    [materials, settings?.display?.appendExternalCode],
  );

  const manufacturerOptions = useMemo(
    () => manufacturers.map((m) => m.name),
    [manufacturers],
  );
  const uomOptions = useMemo(() => {
    const filteredUoms = uoms.filter((o) => o.mnemonic);
    return (
      filteredUoms
        .map((o) => o.pluralDescription || "")
        .concat(filteredUoms.map((o) => o.singularDescription || ""))
        .concat(filteredUoms.map((o) => o.mnemonic || ""))
        .concat(filteredUoms.map((o) => o.alternativeRefs || [""]).flat())
        .filter((o) => o) ?? []
    );
  }, [uoms]);

  const costCodeOptions = useMemo(
    () => projectCostCodes.map((costCode) => costCode.formatted) ?? [],
    [projectCostCodes],
  );

  const costTypeOptions = useMemo(
    () => costTypes.map((c) => formatCostType(c)),
    [costTypes, formatCostType],
  );

  const zoneOptions = useMemo(
    () => zones?.map((zone) => zone.name.trim()) ?? [],
    [zones],
  );

  const vendorOptions = useMemo(
    () =>
      vendors.map((vendor) =>
        vendorLabelFormatter(vendor.sellerOrgLocation, [], {
          vendorCode: getVendorCode(vendor),
        }),
      ) ?? [],
    [vendors, getVendorCode],
  );

  const warehouseOptions = useMemo(
    () => warehouses.map((warehouse) => warehouse.name),
    [warehouses],
  );

  const supplierOptions = useMemo(
    () => [...warehouseOptions, ...vendorOptions],
    [warehouseOptions, vendorOptions],
  );

  const tagOptions = useMemo(() => {
    return (
      tags?.filter((tag) => !tag.mapping).map((tag) => tag.name.trim()) ?? []
    );
  }, [tags]);

  const phaseCodeOptions = useMemo(
    () => phaseCodes?.map((phaseCode) => phaseCode.name.trim()) || [],
    [phaseCodes],
  );

  return {
    itemOptions,
    manufacturerOptions,
    uomOptions,
    costCodeOptions,
    costTypeOptions,
    zoneOptions,
    vendorOptions,
    supplierOptions,
    tagOptions,
    phaseCodeOptions,
    warehouseOptions,
  };
};

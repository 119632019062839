import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useRfq } from "@/contractor/pages/home/rfq/hooks/useRfq";
import { RfqFieldsFragment, RfqStatus } from "@/generated/graphql";
import { Edit } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Chip } from "@mui/material";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import RfqConfigVendorsDrawer from "./RfqConfigVendorsDrawer";

const Container = tw.div`grid grid-flow-col max-w-screen-lg items-center gap-2 text-sm justify-end`;
const Label = tw.label`font-medium`;
const TooltipChip = tw(Chip)`h-6 hover:cursor-default mb-1 text-white`;
const StyledChip = tw(
  Chip,
)`h-6 hover:cursor-default bg-[#293B59] bg-opacity-20`;
const OtherVendorsChip = tw(StyledChip)`
  bg-blue-500 font-medium text-white w-6 h-6 [&_span]:pl-1 [&_span]:pr-1 [&_span]:text-clip
`;
const AddMoreChip = tw(Edit)`
  w-6 text-blue-500  text-xl font-medium [&_span]:pl-1 [&_span]:text-clip hover:cursor-pointer cursor-pointer
`;
const ClosedIconWrapper = tw.div`mr-2 [&_svg]:fill-white [&_svg]:hover:fill-gray-200 cursor-pointer`;

const DrawerPanelContent = ({
  togglePanel,
  drawerTitle,
  rfq,
  drawerSaveLabel,
  drawerFilterExistingVendors,
}: {
  togglePanel: (arg0: boolean) => void;
  drawerTitle: string;
  rfq: RfqFieldsFragment;
  drawerSaveLabel?: string;
  drawerFilterExistingVendors?: boolean;
}) => (
  <RfqConfigVendorsDrawer
    togglePanel={togglePanel}
    panelTitle={drawerTitle}
    filterVendors={
      drawerFilterExistingVendors
        ? rfq.targets.map((el) => el.sellerOrgLocation.id)
        : undefined
    }
    saveLabel={drawerSaveLabel ?? undefined}
  />
);

const DrawerPanelAnchor = (togglePanel: (arg0: boolean) => void) => (
  <AddMoreChip onClick={() => togglePanel(true)} />
);

interface IRfqVendorsInfo {
  drawerTitle: string;
  drawerSaveLabel?: string;
  drawerFilterExistingVendors?: boolean;
}

const RfqVendorsInfo: FC<IRfqVendorsInfo> = ({
  drawerTitle,
  drawerSaveLabel,
  drawerFilterExistingVendors,
}) => {
  const { vendors } = useVendors();
  const { rfq, updateRfq } = useRfq();
  const intl = useIntl();
  const { openDialog } = useDialog();

  const getVendor = useCallback(
    (vendorId: string) =>
      vendors.find((el) => el.sellerOrgLocation.id === vendorId),
    [vendors],
  );

  const saveChanges = useCallback(
    async (vendorId: string) => {
      if (!rfq) {
        return;
      }
      await updateRfq({
        rfqId: rfq.id,
        targets: rfq.targets
          .filter((target) => target.sellerOrgLocation.id !== vendorId)
          .map((target) => ({
            sellerOrgLocationId: target.sellerOrgLocation.id,
            contactIDs: target.contacts.map((contact) => contact.id),
          })),
      });
    },
    [rfq, updateRfq],
  );

  const onRemoveVendor = useCallback(
    async (rfq: RfqFieldsFragment, vendorId: string) => {
      if (rfq.status === RfqStatus.Active) {
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "PROCEED" }),
          includeWarningIcon: true,
          title: intl.$t({ id: "REMOVE_VENDOR_CONFIRMATION" }),
          handleConfirm: () => saveChanges(vendorId),
        });
      } else {
        await saveChanges(vendorId);
      }
    },
    [intl, openDialog, saveChanges],
  );

  if (!rfq) {
    return <Loader loading />;
  }

  const [visibleVendor, ...otherVendors] = rfq.targets;

  return (
    <Container>
      <Label>{intl.$t({ id: "RFQ_LABEL_VENDOR_S" })}</Label>
      <If isTrue={visibleVendor}>
        <StyledChip
          onDelete={
            rfq.targets.length > 1
              ? () => onRemoveVendor(rfq, visibleVendor.sellerOrgLocation.id)
              : undefined
          }
          deleteIcon={
            <ClosedIconWrapper>
              <CancelIcon fontSize="small" />
            </ClosedIconWrapper>
          }
          key={visibleVendor?.sellerOrgLocation.id}
          label={
            <>
              {getVendor(visibleVendor?.sellerOrgLocation.id)?.sellerOrgLocation
                .org.name ?? undefined}
            </>
          }
        />
      </If>

      <If isTrue={otherVendors.length}>
        <Tooltip
          id="other-vendors"
          element={<OtherVendorsChip label={`+${otherVendors.length}`} />}
        >
          {otherVendors.map((vendor) => (
            <TooltipChip
              onDelete={() => onRemoveVendor(rfq, vendor.sellerOrgLocation.id)}
              deleteIcon={
                <ClosedIconWrapper>
                  <CancelIcon fontSize="small" />
                </ClosedIconWrapper>
              }
              key={vendor.sellerOrgLocation.id}
              label={
                <>
                  {getVendor(vendor.sellerOrgLocation.id)?.sellerOrgLocation.org
                    .name ?? undefined}
                </>
              }
            />
          ))}
        </Tooltip>
      </If>

      <DrawerPanel
        anchor={DrawerPanelAnchor}
        content={(togglePanel) =>
          DrawerPanelContent({
            togglePanel,
            drawerTitle,
            rfq,
            drawerSaveLabel,
            drawerFilterExistingVendors,
          })
        }
      />
    </Container>
  );
};

export default RfqVendorsInfo;

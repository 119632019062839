import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useMemo } from "react";
import { useInventoryWizardTransaction } from "../../../../../hooks/useInventoryWizardTransaction";

export const useTransactionHistoryTransfersTotalPrice = () => {
  const { transaction } = useInventoryWizardTransaction({
    skipStoreUpdates: true,
  });
  const { calcExtPrice } = usePriceCalculation();

  const total = useMemo(
    () =>
      transaction?.items.reduce(
        (acc: DecimalSafe, item) =>
          acc.add(calcExtPrice(item.quantity, item.unitPrice)),
        new DecimalSafe(0),
      ),
    [transaction, calcExtPrice],
  );

  return { total };
};

import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface ApolloClientState {
  client: ApolloClient<NormalizedCacheObject> | null;
  setClient: (client: ApolloClient<NormalizedCacheObject>) => void;
}

export const useApolloClientStore = create<ApolloClientState>()(
  devtools((set) => ({
    client: null,
    setClient: (client) => set({ client }),
  })),
);

import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import { DeleteOutline } from "@mui/icons-material";
import { useCurrentStockSideActions } from "./useCurrentStockSideActions";

export const CurrentStockSideActions = () => {
  const { selectedEntities, actions } = useCurrentStockSideActions({
    deleteIcon: <DeleteOutline />,
  });

  return (
    <SideActionBar
      visible={selectedEntities.length > 0 && actions.length > 0}
      actions={actions}
    />
  );
};

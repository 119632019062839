import { ListSelectAll } from "@/common/components/list-select-all/ListSelectAll";
import { useIntl } from "react-intl";
import { useReceipts } from "../../providers/ReceiptsProvider";
import { useReceiptSequence } from "../../providers/ReceiptsSequenceProvider";

export const ReceiptsSelectAll = () => {
  const intl = useIntl();
  const { totalCount } = useReceipts();
  const { selectedReceipts, setSelectedReceipts } = useReceiptSequence();

  return (
    <ListSelectAll
      allItemsSelected={false}
      totalCount={totalCount}
      selectedCount={selectedReceipts.length}
      itemNamePlural={intl.$t({ id: "RECEIPTS" })}
      itemNameSingular={intl.$t({ id: "RECEIPT" })}
      clearAll={() => setSelectedReceipts([])}
      hideSelectAll
    />
  );
};

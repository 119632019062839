import { useSupplierInsurance } from "@/common/hooks/useSupplierInsurance";
import { useCallback, useState } from "react";
import { BsCheck, BsExclamation } from "react-icons/bs";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { LinkLike } from "../link-like/LinkLike";
import { SupplierInsuranceModal } from "../supplier-insurance-modal/SupplierInsuranceModal";
import { Tooltip } from "../tooltip/Tooltip";

type Props = {
  vendorId?: string;
  className?: string;
};

const CheckmarkCircleGreen = tw(
  BsCheck,
)`bg-green-600 fill-green-600 text-xl rounded-full fill-white`;

const WarningIconRed = tw(
  BsExclamation,
)`bg-red-500 fill-red-500 text-xl rounded-full m-0 fill-white`;

export const SupplierInsuranceInfo = ({ vendorId, className = "" }: Props) => {
  const intl = useIntl();
  const [modalOpened, setModalOpened] = useState(false);
  const { insuranceExpired, supplierInsuranceInfoIsVisible } =
    useSupplierInsurance(vendorId);

  const closeModal = useCallback(() => {
    setModalOpened(false);
  }, []);

  if (!supplierInsuranceInfoIsVisible || !vendorId) {
    return null;
  }

  return (
    <>
      <Tooltip
        id="vendor-insurance-info-id"
        element={
          <LinkLike onClick={() => setModalOpened(true)}>
            {insuranceExpired ? <WarningIconRed /> : <CheckmarkCircleGreen />}
          </LinkLike>
        }
        className={className}
      >
        {intl.$t({
          id: insuranceExpired
            ? "SELLER_INSURANCE_EXPIRED"
            : "VIEW_VENDOR_INSURANCE",
        })}
      </Tooltip>
      <SupplierInsuranceModal
        modalOpened={modalOpened}
        closeModal={closeModal}
        vendorId={vendorId}
      />
    </>
  );
};

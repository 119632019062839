import { useDialog } from "@/common/components/dialog/DialogProvider";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { routes } from "@/config/routes";
import { useInventoryHelpers } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryHelpers";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import {
  CreateInventoryAdjustmentItemInput,
  CreateInventoryTransferItemInput,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { InventoryTransferFormValues } from "../components/inventory-transfer-form/InventoryTransferForm";
import { useInventoryTransferMutations } from "./useInventoryTransferMutations";
import { useInventoryTransferTableValidators } from "./useInventoryTransferTableValidators";

export const useSyncInventoryTransfer = () => {
  const intl = useIntl();
  const { createInventoryTransfer, createInventoryTransferLoading } =
    useInventoryTransferMutations();
  const { spreadsheetData, gotoInvalidRow } = useColumnMapper();
  const { addMissingMaterials, getCellWithAdditionalData } = useTableHelpers();
  const findMaterialByName = useFindMaterialByName();
  const { validateRequiredValues } = useTableValidators();
  const { validateRowValues } = useInventoryTransferTableValidators();
  const { findInventoryItemByNameAndUom } = useInventoryHelpers();
  const { materials } = useMaterials();
  const { openDialog } = useDialog();
  const { warehouses } = useStartupDataStore(
    useShallow((state) => ({
      warehouses: state.warehouses,
    })),
  );
  const navigate = useNavigate();

  const getItems = useCallback(async () => {
    const newItems: CreateInventoryAdjustmentItemInput[] = [];
    const newMaterials = (await addMissingMaterials()) || [];

    spreadsheetData
      .filter((row) => !rowIsEmpty(row))
      .forEach((row) => {
        const rowMaterialText = getCellWithAdditionalData(
          row,
          COLUMN_TYPE.Material,
        );
        const uom = getCellValue(row, COLUMN_TYPE.UOM);
        const inventoryItem = findInventoryItemByNameAndUom(
          rowMaterialText,
          uom,
        );
        let material = findMaterialByName(rowMaterialText, [
          ...materials,
          ...newMaterials,
        ]);
        const notes = getCellValue(row, COLUMN_TYPE.Notes);
        const quantity = getCellValue(row, COLUMN_TYPE.Quantity) || "0";

        const newItem = {
          ...(inventoryItem
            ? {
                inventoryItemId: inventoryItem.id,
              }
            : {
                orgMaterialId: material?.id,
                uom: uom,
              }),
          notes,
          quantity,
        } as CreateInventoryAdjustmentItemInput;
        newItems.push(newItem);
      });

    return newItems;
  }, [
    spreadsheetData,
    materials,
    addMissingMaterials,
    getCellWithAdditionalData,
    findMaterialByName,
    findInventoryItemByNameAndUom,
  ]);

  const validateItems = useCallback(async () => {
    if (
      !(await validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UnitPrice,
      ])) ||
      !(await validateRowValues([COLUMN_TYPE.Material, COLUMN_TYPE.Quantity]))
    ) {
      gotoInvalidRow();
      return false;
    }
    return true;
  }, [gotoInvalidRow, validateRequiredValues, validateRowValues]);

  const onSave = useCallback(
    async (values: InventoryTransferFormValues) => {
      const { originWarehouseId, destinationWarehouseId, date, instructions } =
        values;
      const sourceWarehouse = warehouses.find(
        (warehouse) => warehouse.id === originWarehouseId,
      );
      const destinationWarehouse = warehouses.find(
        (warehouse) => warehouse.id === destinationWarehouseId,
      );

      if (
        !(await validateItems()) ||
        !sourceWarehouse ||
        !destinationWarehouse
      ) {
        return undefined;
      }

      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "CONFIRM" }),
        includeWarningIcon: true,
        title: intl.$t(
          { id: "TRANSFER_NUM_MATERIALS" },
          {
            count: spreadsheetData.filter((row) => !rowIsEmpty(row)).length,
          },
        ),
        content: intl.$t(
          { id: "FROM_WAREHOUSE_TO_WAREHOUSE" },
          {
            sourceWarehouseName: sourceWarehouse?.name,
            destinationWarehouseName: destinationWarehouse?.name,
          },
        ),
        handleConfirm: async () => {
          const items = await getItems();
          const result = await createInventoryTransfer({
            originWarehouseId: sourceWarehouse.id,
            destinationWarehouseId: destinationWarehouse.id,
            date: date ? new Date(date).getTime() : 0,
            items: items as CreateInventoryTransferItemInput[],
            instructions: instructions,
          });
          if (result) {
            navigate(routes.currentStock);
          }
        },
      });
    },
    [
      createInventoryTransfer,
      getItems,
      intl,
      openDialog,
      warehouses,
      navigate,
      validateItems,
      spreadsheetData,
    ],
  );

  return {
    onSave,
    saving: createInventoryTransferLoading,
  };
};

import { DeleteButton } from "@/common/components/button/DeleteButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { ReceiptSummaryFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReceipts } from "../../providers/ReceiptsProvider";
import { ReceiptWarnings } from "./ReceiptWarnings";

const Container = tw.div`flex flex-row gap-2 items-center`;

type Props = {
  receipt: ReceiptSummaryFieldsFragment;
};

export const ReceiptActions: FC<Props> = ({ receipt }) => {
  const { archiveReceipt, exportEnabled } = useReceipts();
  const intl = useIntl();
  const { openDialog } = useDialog();

  return (
    <Container>
      <ReceiptWarnings receipt={receipt} />
      <If
        isTrue={
          !exportEnabled && receipt.permissions.archive && !receipt.archivedAt
        }
      >
        <DeleteButton
          onClick={() =>
            openDialog({
              cancelButtonText: intl.$t({ id: "CANCEL" }),
              confirmButtonText: intl.$t({ id: "PROCEED" }),
              includeErrorIcon: true,
              title: intl.$t({ id: "DELETE_RECEIPT" }),
              text: receipt.release
                ? intl.$t(
                    { id: "DELETE_RECEIPT_DETAILS" },
                    { orderNumber: receipt.release?.sequenceNumber },
                  )
                : "",
              handleConfirm: () => archiveReceipt(receipt.id),
            })
          }
        />
      </If>
    </Container>
  );
};

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFormattedMaterialName } from "@/common/components/spreadsheet-table/hooks/useFormattedMaterialName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import {
  ExtraOption,
  SpreadSheetConfig,
} from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { addExtraItemDetails } from "@/common/components/spreadsheet-table/utils/addExtraItemDetails";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { getInventoryItemUnitPrice } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemUnitPrice";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import {
  InventoryItemFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIsRestockRelease } from "../../../hooks/useIsRestockRelease";
import { useIsWarehouseRelease } from "../../../hooks/useIsWarehouseRelease";
import { useRelease } from "../../../providers/ReleaseProvider";

export const useReleaseItemsDecorator = () => {
  const { buyout } = useContractorBuyout();
  const { estimatedItems } = useProjectStore();
  const { vendors, getVendorCode } = useVendors();
  const { warehouses } = useWarehouses();
  const { formatCostCode } = useProjectCostCodes();
  const { materialsMap } = useMaterials();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { getPrefilledValue, tableHasColumn } = useTableHelpers();
  const getFormattedMaterialName = useFormattedMaterialName();
  const { hasPhaseCodes } = useOrgSettings();
  const { release } = useRelease();
  const { uoms } = useUomOptions();
  const { inventoryItems, currentWarehouseId } = useInventoryItems();
  const { isWarehouseRelease } = useIsWarehouseRelease();
  const { isRestockRelease } = useIsRestockRelease();

  const vendorById = useMemo(
    () => new Map(vendors.map((v) => [v.sellerOrgLocation.id, v])),
    [vendors],
  );
  const uomMap = useMemo(() => new Map(uoms.map((u) => [u.id, u])), [uoms]);

  const hasSupplierColumn = useMemo(
    () => tableHasColumn(COLUMN_TYPE.Supplier),
    [tableHasColumn],
  );

  const getTotalQuantity = useCallback(
    (inventoryItem: InventoryItemFieldsFragment) =>
      inventoryItem.state.reduce(
        (acc, curr) => acc.plus(curr.remainingQuantity),
        new DecimalSafe(0),
      ),
    [],
  );

  const extraOptions = useMemo(() => {
    let lumpSumItems: ExtraOption[] = [];
    let buyoutItems: ExtraOption[] = [];
    let estimates: ExtraOption[] = [];

    if (!isWarehouseRelease) {
      lumpSumItems =
        release?.items
          .filter(
            (item) => item.uom && isLumpSumUomText(item.uom.pluralDescription),
          )
          .map((lumpSumItem) => {
            const material = lumpSumItem.projectItem?.material.id
              ? materialsMap.get(lumpSumItem.projectItem.material.id)
              : undefined;
            const name =
              lumpSumItem.name ||
              (material ? getFormattedMaterialName(material) : "");
            return { name, value: lumpSumItem.id } as ExtraOption;
          }) || [];

      buyoutItems = (
        buyout?.items.filter((buyoutItem) =>
          materialsMap.has(buyoutItem.projectItem.material.id),
        ) || []
      ).map((buyoutItem) => {
        const material = materialsMap.get(buyoutItem.projectItem.material.id);
        const costOrTag = hasPhaseCodes
          ? buyoutItem?.tags[0]?.name
          : buyoutItem?.costCode?.description || unassignedCostCode.description;
        const contentText = `${costOrTag}, ${buyoutItem.quantityDecimal} ${material?.defaultEstimateUom.pluralDescription}`;
        return {
          name: material ? getFormattedMaterialName(material) : "",
          content: addExtraItemDetails(contentText, "text-green-800"),
          value: buyoutItem.id,
          prefilling: [
            [COLUMN_TYPE.BuyoutItemId, buyoutItem.id],
            [COLUMN_TYPE.Quantity, buyoutItem.quantityDecimal],
            ...(hasPhaseCodes
              ? [[COLUMN_TYPE.PhaseCode, buyoutItem?.tags[0]?.name]]
              : [[COLUMN_TYPE.CostCode, formatCostCode(buyoutItem?.costCode)]]),
            [COLUMN_TYPE.UOM, material?.defaultEstimateUom.pluralDescription],
            [COLUMN_TYPE.Manufacturer, buyoutItem?.manufacturer?.name],
            [COLUMN_TYPE.PrefilledPrice, buyoutItem?.unitPrice],
          ],
        } as ExtraOption;
      });

      const releaseIsSubmitted = checkReleaseStatus(release, [
        ReleaseStatus.Scheduled,
        ReleaseStatus.Requested,
        ReleaseStatus.PartiallyReceived,
        ReleaseStatus.Received,
      ]);

      estimates =
        estimatedItems
          .filter(
            (estItem) =>
              !releaseIsSubmitted ||
              estItem.sellerOrgLocation?.id === release?.sellerOrgLocation?.id,
          )
          .map((estItem) => {
            const material = materialsMap.get(estItem.material.id);
            const uomDesc = material
              ? material.defaultEstimateUom.pluralDescription
              : "";
            const contentText = `${estItem.quantityDecimal} ${uomDesc}`;
            const vendor = estItem.sellerOrgLocation?.id
              ? vendorById.get(estItem.sellerOrgLocation.id)
              : undefined;
            return {
              name: material ? getFormattedMaterialName(material) : "",
              content: addExtraItemDetails(contentText, "text-blue-500"),
              value: estItem.id,
              prefilling: [
                [COLUMN_TYPE.EstimatedItemId, estItem.id],
                ...(vendor
                  ? [
                      [
                        COLUMN_TYPE.Supplier,
                        vendorLabelFormatter(vendor.sellerOrgLocation, [], {
                          vendorCode: getVendorCode(vendor),
                        }),
                      ],
                    ]
                  : []),
                [COLUMN_TYPE.Quantity, estItem.quantityDecimal],
                ...(hasPhaseCodes
                  ? [[COLUMN_TYPE.PhaseCode, estItem?.tags[0]?.name]]
                  : [
                      [COLUMN_TYPE.CostCode, formatCostCode(estItem?.costCode)],
                    ]),
                [
                  COLUMN_TYPE.UOM,
                  uomMap.get(estItem.uom.id)?.pluralDescription,
                ],
                [COLUMN_TYPE.Manufacturer, estItem?.manufacturer?.name],
                [COLUMN_TYPE.PrefilledPrice, estItem?.unitPrice],
                [COLUMN_TYPE.Zone, estItem?.zone?.name],
              ],
            } as ExtraOption;
          }) || [];
    }

    const inventoryExtraOptions: ExtraOption[] = inventoryItems
      .filter(
        (item) =>
          hasSupplierColumn ||
          item.state.some((state) => state.warehouse.id === currentWarehouseId),
      )
      .filter((item) => item.state.length > 0)
      .toSorted((a, b) => {
        const quantityA = getTotalQuantity(a).toNumber();
        const quantityB = getTotalQuantity(b).toNumber();
        if (quantityA === 0 && quantityB === 0) {
          return 0;
        }
        if (quantityA === 0 || quantityB === 0) {
          return quantityA === 0 ? 1 : -1;
        }
        return a.orgMaterial.material?.name.localeCompare(
          b.orgMaterial.material?.name,
        );
      })
      .map((inventoryItem) => {
        const material = materialsMap.get(inventoryItem.orgMaterial.id);
        const totalQuantity = getTotalQuantity(inventoryItem);
        const itemDetails = addExtraItemDetails(
          `${totalQuantity.toString()} ${inventoryItem.uom.pluralDescription}`,
          "text-blue-800",
        );
        const price = isRestockRelease
          ? getPrefilledValue({
              material: inventoryItem.orgMaterial.material?.name ?? "",
              uom: inventoryItem.uom.pluralDescription,
            }).value
          : getInventoryItemUnitPrice(inventoryItem);
        const supplier = warehouses.find(
          (warehouse) =>
            warehouse.id ===
            inventoryItem.state.find((state) => state.warehouse)?.warehouse?.id,
        );
        return {
          name: material ? getFormattedMaterialName(material) : "",
          content: itemDetails,
          value: inventoryItem.id,
          prefilling: [
            ...(!hasPhaseCodes
              ? [[COLUMN_TYPE.CostCode, formatCostCode(material?.costCode)]]
              : []),
            ...(supplier?.id !== currentWarehouseId
              ? [[COLUMN_TYPE.Supplier, supplier?.name]]
              : []),
            [COLUMN_TYPE.UOM, inventoryItem.uom.pluralDescription],
            [COLUMN_TYPE.PrefilledPrice, price],
          ],
        } as ExtraOption;
      });
    return [
      ...inventoryExtraOptions,
      ...buyoutItems,
      ...estimates,
      ...lumpSumItems,
    ];
  }, [
    release,
    buyout?.items,
    estimatedItems,
    inventoryItems,
    materialsMap,
    warehouses,
    vendorById,
    uomMap,
    getFormattedMaterialName,
    formatCostCode,
    hasPhaseCodes,
    unassignedCostCode.description,
    getVendorCode,
    getTotalQuantity,
    getPrefilledValue,
    isRestockRelease,
    isWarehouseRelease,
    currentWarehouseId,
    hasSupplierColumn,
  ]);

  const getEstimatedItemId = useCallback(
    (id: string): string => {
      const releaseItem = release?.items.find((item) => item.id === id);
      const estimatedItemIndex =
        releaseItem?.projectItem?.estimatedItems.findIndex(
          (item) => item.zone?.id === releaseItem?.zone?.id,
        );
      return estimatedItemIndex !== -1
        ? `projectItem.estimatedItems.${estimatedItemIndex}.id`
        : "";
    },
    [release?.items],
  );

  const extraColumns = useMemo<SpreadSheetConfig[]>(() => {
    return [
      {
        header: "",
        columnId: "buyoutItem.id",
        columnType: COLUMN_TYPE.BuyoutItemId,
        metadataColumn: true,
      },
      {
        header: "",
        columnId: (id: string) => getEstimatedItemId(id),
        columnType: COLUMN_TYPE.EstimatedItemId,
        metadataColumn: true,
      },
    ] as SpreadSheetConfig[];
  }, [getEstimatedItemId]);

  return { extraOptions, extraColumns };
};

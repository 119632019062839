import { CostCodesSelector } from "@/common/components/cost-codes-selector/CostCodesSelector";
import { If } from "@/common/components/if/If";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { Switch } from "@/common/components/switch/Switch";
import { WarehouseSelector } from "@/common/components/warehouse-selector/WarehouseSelector";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useAddInventoryItems } from "../../../hooks/useAddInventoryItems";

const Container = tw.div`
  grid grid-cols-[auto_1fr_auto_auto_auto_auto] justify-between gap-4 bg-green-300 p-2 rounded-lg mb-1 w-full h-14 sticky top-0 z-70
`;
const InputContainer = tw.div`grid max-w-64`;
const SwitchContainer = tw.div`grid grid-flow-col gap-1 max-w-64 place-items-center`;
const LeftSpace = tw.div`w-full`;
const Title = tw.div`text-base font-medium self-start w-fit grid place-items-center h-full mx-2`;

export const InventoryItemsFilters: FC = () => {
  const intl = useIntl();
  const {
    search,
    setSearch,
    selectedCostCodes,
    setSelectedCostCodes,
    currentWarehouseId,
    setCurrentWarehouseId,
    showZeroQuantityInventoryItems,
    setShowZeroQuantityInventoryItems,
    limitToCurrentWarehouse,
  } = useAddInventoryItems();
  const { inventoryItems } = useInventoryItems();
  const { projectCostCodes } = useProjectCostCodes();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { hasPhaseCodes } = useOrgSettings();
  const costCodeOptions = useMemo(() => {
    return projectCostCodes
      .filter((costCode) =>
        inventoryItems?.find(
          (item) => item.orgMaterial.costCode?.id === costCode.id,
        ),
      )
      .concat(unassignedCostCode);
  }, [inventoryItems, projectCostCodes, unassignedCostCode]);

  return (
    <Container>
      <FormattedMessage id="SELECT_WAREHOUSE_ITEMS" tagName={Title} />
      <LeftSpace />
      <SwitchContainer>
        <FormattedMessage id="SHOW_ZERO_QUANTITY_INVENTORY_ITEMS" />
        <Switch
          offLabel={intl.$t({ id: "NO" })}
          onLabel={intl.$t({ id: "YES" })}
          value={showZeroQuantityInventoryItems}
          onChange={setShowZeroQuantityInventoryItems}
        />
      </SwitchContainer>
      <If isTrue={!limitToCurrentWarehouse}>
        <InputContainer>
          <WarehouseSelector
            showAllWarehousesOption
            value={currentWarehouseId}
            onChange={setCurrentWarehouseId}
            placeholder={intl.$t({ id: "SELECT_WAREHOUSE" })}
          />
        </InputContainer>
      </If>
      <If isTrue={!hasPhaseCodes}>
        <InputContainer>
          <CostCodesSelector
            options={costCodeOptions}
            selectedCostCodes={selectedCostCodes}
            setSelectedCostCodes={setSelectedCostCodes}
            limitTags={1}
          />
        </InputContainer>
      </If>
      <InputContainer>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={setSearch}
          value={search}
        />
      </InputContainer>
    </Container>
  );
};

import {
  WizardModal,
  WizardModalMode,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { AnimatedLoading } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";
import { InfoOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrgSettings } from "../../../org-settings/hooks/useOrgSettings";
import { ImportExternalVendorsForm } from "./ImportExternalVendorsForm";
import { ImportExternalVendorsTable } from "./ImportExternalVendorsTable";
import { SelectedExternalVendors } from "./SelectedExternalVendors";
import { VendorsImportForm } from "./VendorsImportForm";
import { useImportExternalVendors } from "./hooks/useImportExternalVendors";
import { useRefreshExternalVendors } from "./hooks/useRefreshExternalVendors";
import { useImportExternalVendorsStore } from "./stores/useImportExternalVendorsWizardStore";

const ImportStatus = tw.div`flex items-center gap-2`;
const InfoStyled = tw(InfoOutlined)`text-gray-500`;

export const ImportExternalVendorsModal = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const {
    modalOpened,
    selectedExternalVendors,
    setSelectedExternalVendors,
    closeModal,
  } = useImportExternalVendorsStore();
  const { watch, formState } = useFormContext<ImportExternalVendorsForm>();
  const { handleRefreshExternalVendors, refreshingExternalVendors } =
    useRefreshExternalVendors(connectedSourceSystem || null);
  const {
    handleCreateVendors,
    handleCreateRemainingVendors,
    onImportCompleted,
    importingVendors,
  } = useImportExternalVendors();

  const formVendors = watch("vendors");

  const pages: WizardModalPage[] = useMemo(() => {
    if (!connectedSourceSystem) {
      return [];
    }
    return [
      {
        title: null,
        pages: [
          {
            component: (
              <ImportExternalVendorsTable
                selectedItemIds={selectedExternalVendors.map(
                  (v) => v.externalId,
                )}
                setSelectedItems={setSelectedExternalVendors}
                sourceSystem={connectedSourceSystem}
                title={intl.$t({ id: "IMPORT_VENDORS" })}
                selectedVendors={selectedExternalVendors}
              />
            ),
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "outlined",
                onClick: handleRefreshExternalVendors,
                loading: refreshingExternalVendors,
                text: intl.$t(
                  { id: "SYNC_FROM_SOURCE_SYSTEM" },
                  {
                    sourceSystem: intl.$t({
                      id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                    }),
                  },
                ),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: handleCreateVendors,
                loading: importingVendors,
                text: intl.$t(
                  { id: "IMPORT_COUNT" },
                  { count: selectedExternalVendors.length },
                ),
                className: "flex-1",
                disabled: selectedExternalVendors.length === 0,
              },
            ],
            rightSidePanel: (
              <SelectedExternalVendors
                externalVendors={selectedExternalVendors}
                onRemove={(id: string) =>
                  setSelectedExternalVendors(
                    selectedExternalVendors.filter((i) => i.externalId !== id),
                  )
                }
              />
            ),
          },
          {
            component: (
              <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
            ),
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            hideHeader: false,
            customHeader: (
              <ImportStatus>
                <InfoStyled />
                <FormattedMessage
                  id={
                    selectedExternalVendors.length !== formVendors.length
                      ? `IMPORTED_VENDORS_STATUS`
                      : `IMPORTED_VENDORS_STATUS_NO_VENDORS`
                  }
                  values={{
                    total: selectedExternalVendors.length,
                    remaining:
                      selectedExternalVendors.length - formVendors.length,
                  }}
                />
              </ImportStatus>
            ),
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: handleCreateRemainingVendors,
                text: intl.$t(
                  { id: "SAVE_AND_IMPORT_COUNT" },
                  { count: formVendors.length },
                ),
                className: "flex-1",
                disabled: !formState.isValid || !formVendors.length,
              },
            ],
            component: <VendorsImportForm />,
          },
          {
            component: (
              <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
            ),
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: (
              <CompletedStep
                text={intl.$t({ id: "IMPORT_COMPLETED" })}
                onTimeoutCompleted={onImportCompleted}
              />
            ),
            onModalClose: closeModal,
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
        ],
      },
    ];
  }, [
    connectedSourceSystem,
    intl,
    closeModal,
    selectedExternalVendors,
    handleRefreshExternalVendors,
    refreshingExternalVendors,
    handleCreateVendors,
    formState,
    formVendors,
    handleCreateRemainingVendors,
    onImportCompleted,
    importingVendors,
    setSelectedExternalVendors,
  ]);

  return (
    <WizardModal
      mode={WizardModalMode.MULTIPLE_PAGES}
      opened={modalOpened}
      pages={pages}
      onClose={closeModal}
    />
  );
};

import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FormattedMessage, useIntl } from "react-intl";
import { useArchiveReceipt } from "../../../receipts/hooks/useArchiveReceipt";
import { useReceiptSequence } from "../../../receipts/providers/ReceiptsSequenceProvider";

export const DeleteReceiptButton = ({
  show,
  id,
  releaseSequenceNumber,
}: {
  show: boolean;
  id: string | undefined;
  releaseSequenceNumber: number | null | undefined;
}) => {
  const { openDialog } = useDialog();
  const intl = useIntl();
  const { navigateToNextSequence } = useReceiptSequence();
  const { archiveReceipt } = useArchiveReceipt();

  if (!show || !id) {
    return null;
  }
  return (
    <OutlinedButton
      onClick={() => {
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "PROCEED" }),
          includeErrorIcon: true,
          title: intl.$t({ id: "DELETE_RECEIPT" }),
          text: releaseSequenceNumber
            ? intl.$t(
                { id: "DELETE_RECEIPT_DETAILS" },
                { orderNumber: releaseSequenceNumber },
              )
            : "",
          handleConfirm: async () => {
            await archiveReceipt(id);
            navigateToNextSequence({ navigateToReceipts: true });
          },
        });
      }}
    >
      <FormattedMessage id="DELETE" />
    </OutlinedButton>
  );
};

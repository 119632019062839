import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  InvoiceFieldsFragment,
  InvoiceToExportFieldsFragment,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useClearPoNumber } from "../../scanned-invoices/hooks/useClearPoNumber";
import { useInvoiceValidation } from "../../scanned-invoices/providers/InvoiceValidationProvider";

export const useInvoiceClearPo = (
  invoice: InvoiceFieldsFragment | null | InvoiceToExportFieldsFragment,
) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { connectedSourceSystem } = useOrgSettings();
  const { clearPoNumber } = useClearPoNumber();
  const { refetchInvoiceValidation } = useInvoiceValidation();

  const integration = useMemo(
    () =>
      connectedSourceSystem
        ? intl.$t({ id: `INTEGRATION_${connectedSourceSystem}` })
        : "",
    [connectedSourceSystem, intl],
  );
  const clearPo = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      title: intl.$t({ id: "CLEAR_PO_NUMBER" }),
      text: intl.$t(
        { id: "CLEAR_PO_NUMBER_CONFIRMATION" },
        {
          poNumber:
            invoice?.poNumber || invoice?.release?.poNumber?.toString() || "",
          invoiceNumber: invoice?.number?.toString() || "",
          releaseNumber: invoice?.release?.sequenceNumber || "",
          br: <br />,
          integration,
        },
      ),
      includeWarningIcon: true,
      handleConfirm: async () => {
        if (!invoice?.id) {
          return;
        }
        await clearPoNumber(invoice?.id);
        refetchInvoiceValidation();
      },
    });
  }, [
    openDialog,
    intl,
    invoice?.poNumber,
    invoice?.release?.poNumber,
    invoice?.release?.sequenceNumber,
    invoice?.number,
    invoice?.id,
    integration,
    clearPoNumber,
    refetchInvoiceValidation,
  ]);

  return { clearPo };
};

import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { BATCH_MONTH_DATE_FORMAT } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useMarkPoAsNotExportable } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useMarkPoAsNotExportable";
import {
  BatchType,
  PoFormat,
  SourceSystem,
  useSyncPoMutation,
  useUpdatePoLinkMutation,
} from "@/generated/graphql";
import { LinkingProgress } from "@/types/LinkingProgress";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { format } from "date-fns";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useExportBatch } from "../../../../../../../common/providers/ExportBatchProvider";
import { useCreateExternalBatch } from "../hooks/useCreateExternalBatch";
import { useDefaultReleasePo } from "../hooks/useDefaultReleasePo";
import { useHasMajorityDefaultSync } from "../hooks/useHasMajorityDefaultSync";
import { useLinkPo } from "../hooks/useLinkPo";
import { useSyncPO } from "../hooks/useSyncPo";
import { useReleasesByIds } from "./ReleasesByIdsProvider";
export type UpdatedRelease = {
  releaseId: string;
  poNumber?: string;
  externalVendorCode?: string;
};

type ProviderContextType = {
  autoSync: boolean;
  setAutoSync: (value: boolean) => void;
  warehouseNumber?: string;
  setWarehouseNumber: (value: string) => void;
  sourceSystem?: SourceSystem;
  updatedReleases: UpdatedRelease[];
  updateRelease: (updatedRelease: UpdatedRelease) => void;
  linkingPos: LinkingProgress;
  linkPos: () => Promise<boolean>;
  syncPo: (poLinkId: string) => Promise<boolean>;
  updatePoLink: (poLinkId: string) => Promise<boolean>;
  clearErrors: () => void;
  hasBatchError: boolean;
  updatingPoLink: boolean;
  syncingPoLink: boolean;
  linkingPo: boolean;
  useSourceSystemPO: boolean;
  setUseSourceSystemPO: (value: boolean) => void;
  backgroundExport: boolean;
  setBackgroundExport: (value: boolean) => void;
};

type Props = {
  children: React.ReactNode;
  type: "onPremise" | "hosted";
};

const ProviderContext = createContext<ProviderContextType>({
  autoSync: false,
  setAutoSync: NoFunction,
  warehouseNumber: "",
  setWarehouseNumber: NoFunction,
  sourceSystem: undefined,
  updateRelease: NoFunction,
  updatedReleases: [],
  linkingPos: {
    linking: false,
    percentage: 0,
    completed: [],
    errors: [],
  },
  linkPos: NoFunctionBooleanPromise,
  syncPo: NoFunctionBooleanPromise,
  updatePoLink: NoFunctionBooleanPromise,
  clearErrors: NoFunction,
  hasBatchError: false,
  updatingPoLink: false,
  syncingPoLink: false,
  linkingPo: false,
  useSourceSystemPO: false,
  setUseSourceSystemPO: NoFunction,
  backgroundExport: false,
  setBackgroundExport: NoFunction,
});

export const ReleaseConnectionOptionsProvider: FC<Props> = ({
  children,
  type,
}) => {
  const { setError } = useGlobalError();
  const { linkPo, linkingPo } = useLinkPo();
  const { syncPO } = useSyncPO();

  const { releasesByIds, validatedReleases } = useReleasesByIds();
  const { connectedSourceSystemSettings } = useOrgSettings();
  const { createExternalBatch } = useCreateExternalBatch();
  const { batchDate, newBatch, externalBatch } = useExportBatch();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const [backgroundExport, setBackgroundExport] = useState(
    hasFeatureInConnectedSourceSystem(
      IntegrationFeature.DefaultBackgroundExport,
    ),
  );
  const { getDefaultReleasePo } = useDefaultReleasePo();

  const { markAsNotExportable } = useMarkPoAsNotExportable();
  const [updatedReleases, setPoReleases] = useState<UpdatedRelease[]>([]);
  const { hasMajorityDefaultSync } = useHasMajorityDefaultSync(releasesByIds);
  const [autoSync, setAutoSync] = useState(
    (releasesByIds.length >= 1 && hasMajorityDefaultSync) ??
      !!connectedSourceSystemSettings?.defaultAutoSync,
  );
  const [useSourceSystemPO, setUseSourceSystemPO] = useState(
    !releasesByIds.some((release) => !release.useSourceSystemPO),
  );
  const initialized = useRef(false);
  const [warehouseNumber, setWarehouseNumber] = useState<string>(
    (type === "hosted"
      ? connectedSourceSystemSettings?.toWarehouseLedgerAccount?.externalId
      : undefined) || "",
  );
  const [linkingPos, setLinkingPos] = useState<LinkingProgress>({
    linking: false,
    percentage: 0,
    completed: [],
    errors: [],
  });
  const [hasBatchError, setHasBatchError] = useState(false);

  useEffect(() => {
    if (releasesByIds && releasesByIds.length > 0 && !initialized.current) {
      if (releasesByIds.length >= 1 && hasMajorityDefaultSync) {
        setAutoSync(true);
      }
      setUseSourceSystemPO(
        !releasesByIds.some((release) => !release.useSourceSystemPO),
      );
      if (releasesByIds.some((release) => release.poLink?.syncedAt)) {
        setBackgroundExport(false);
      }
      initialized.current = true;
    }
  }, [hasMajorityDefaultSync, initialized, releasesByIds]);

  useEffect(() => {
    if (!initialized.current) {
      setAutoSync(!!connectedSourceSystemSettings?.defaultAutoSync);
      setUseSourceSystemPO(
        !releasesByIds.some((release) => !release.useSourceSystemPO),
      );
    }
  }, [
    connectedSourceSystemSettings?.defaultAutoSync,
    initialized,
    releasesByIds,
  ]);

  const updateRelease = useCallback(
    ({ releaseId, poNumber, externalVendorCode }: UpdatedRelease) => {
      const updatedPoReleases = updatedReleases.filter(
        (poRelease) => poRelease.releaseId !== releaseId,
      );
      updatedPoReleases.push({
        releaseId,
        poNumber,
        externalVendorCode,
      });
      setPoReleases(updatedPoReleases);
    },
    [updatedReleases],
  );

  const resetLinking = useCallback(
    ({ linking }: { linking: boolean } = { linking: false }) => {
      setLinkingPos({
        linking,
        percentage: 0,
        completed: [],
        errors: [],
      });
    },
    [],
  );

  const linkPos = useCallback(async () => {
    if (connectedSourceSystemSettings) {
      let successful = true;
      let batchId = externalBatch?.externalId;
      const releasesToExport = releasesByIds.filter(
        (r) => validatedReleases.find((vr) => vr.id === r.id)?.validated,
      );

      if (
        hasFeatureInConnectedSourceSystem(IntegrationFeature.POBatching) &&
        !connectedSourceSystemSettings.autoPostPOs &&
        newBatch
      ) {
        setHasBatchError(false);
        const newBatch = await createExternalBatch({
          sourceSystem: connectedSourceSystemSettings.system,
          month: format(batchDate, BATCH_MONTH_DATE_FORMAT),
          type: BatchType.PurchaseOrder,
        });
        if (newBatch) {
          batchId = newBatch.externalId;
        } else {
          setHasBatchError(true);
          return false;
        }
      }
      resetLinking({ linking: true });
      const errors = [];
      for await (const [key, release] of releasesToExport.entries()) {
        const updatedRelease = updatedReleases.find(
          (r) => r.releaseId === release?.id,
        );

        const result = release.poLink?.syncedAt
          ? await syncPO(release.poLink.id)
          : await linkPo({
              releaseId: release?.id,
              autoSync,
              poNumber:
                updatedRelease?.poNumber ||
                release.poNumber ||
                getDefaultReleasePo(release) ||
                "",
              format: release.type.poFormat || PoFormat.Basic,
              sourceSystem: connectedSourceSystemSettings.system,
              batchId: !connectedSourceSystemSettings.autoPostPOs
                ? batchId
                : undefined,
            });
        const hasTextError = typeof result !== "boolean";
        let error = "";
        if (hasTextError || !result) {
          successful = false;
          if (hasTextError) {
            error = result;
          }
        }
        errors.push({ id: release.id, message: error });
        setLinkingPos({
          linking: true,
          percentage: (key + 1) / releasesToExport.length,
          completed: [...linkingPos.completed, release.id],
          errors: [],
        });
      }
      setLinkingPos({
        linking: false,
        percentage: 0,
        completed: [],
        errors,
      });
      return successful;
    }
    return false;
  }, [
    autoSync,
    batchDate,
    connectedSourceSystemSettings,
    createExternalBatch,
    externalBatch?.externalId,
    getDefaultReleasePo,
    hasFeatureInConnectedSourceSystem,
    linkPo,
    linkingPos.completed,
    newBatch,
    releasesByIds,
    resetLinking,
    syncPO,
    updatedReleases,
    validatedReleases,
  ]);

  const [syncPoMutation, { loading: syncingPoLink }] = useSyncPoMutation({});

  const syncPo = useCallback(
    async (poLinkId: string) => {
      try {
        resetLinking({ linking: true });
        const { data } = await syncPoMutation({
          variables: {
            poLinkId,
          },
        });
        resetLinking({ linking: false });
        return !!data?.syncPO;
      } catch (error) {
        setError(error);
        resetLinking({ linking: false });
        return false;
      }
    },
    [resetLinking, setError, syncPoMutation],
  );

  const [updatePoLinkMutation, { loading: updatingPoLink }] =
    useUpdatePoLinkMutation();
  const updatePoLink = async (poLinkId: string) => {
    try {
      const { data } = await updatePoLinkMutation({
        variables: {
          input: {
            externalWarehouseId: warehouseNumber || undefined,
            clearWarehouse: !warehouseNumber,
            autoSync,
            poLinkID: poLinkId,
          },
        },
      });
      return !!data?.updatePOLink;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const setUseSourceSystemPOWithConfirmation = useCallback(
    (value: boolean) => {
      if (releasesByIds.length === 1 && !releasesByIds[0].poLink) {
        setUseSourceSystemPO(value);
      } else {
        markAsNotExportable(releasesByIds, {
          unlink: releasesByIds.some((release) => release.poLink),
          callback: () => setUseSourceSystemPO(false),
        });
      }
    },
    [markAsNotExportable, releasesByIds],
  );
  return (
    <ProviderContext.Provider
      value={{
        autoSync,
        setAutoSync,
        warehouseNumber,
        setWarehouseNumber,
        sourceSystem: connectedSourceSystemSettings?.system,
        updatedReleases,
        updateRelease,
        linkPos,
        linkingPos,
        syncPo,
        updatePoLink,
        clearErrors: resetLinking,
        hasBatchError,
        updatingPoLink,
        syncingPoLink,
        linkingPo,
        useSourceSystemPO,
        setUseSourceSystemPO: setUseSourceSystemPOWithConfirmation,
        backgroundExport,
        setBackgroundExport,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseConnectionOptions = (): ProviderContextType =>
  useContext(ProviderContext);

import { Loader } from "@/common/components/loader/Loader";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { FC, useCallback } from "react";
import { BsPlus } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRelease } from "../../../../providers/ReleaseProvider";
import { useSubmitChanges } from "../../hooks/useSubmitReleaseChanges";
import {
  IMPORT_MATERIALS_DRAWER,
  ImportMaterialsDrawerTabs,
} from "../import-from-file/ImportReleaseItemsDrawer";

const AddNoteDocument = tw.div`
  grid grid-flow-col justify-start gap-2 items-center text-blue-500 hover:text-blue-300 cursor-pointer text-sm
`;
const AddNoteDocumentButton = tw.button`
  grid place-items-center border-blue-800 w-17 h-17 border rounded-md  text-3xl
`;

export const AddNoteSidePanel: FC = () => {
  const { toggle } = useGlobalDrawer();
  const { submitChanges, submitting } = useSubmitChanges();
  const { updating } = useRelease();

  const onClick = useCallback(async () => {
    if (updating) {
      return;
    }
    await submitChanges({
      callback: () => {
        toggle(IMPORT_MATERIALS_DRAWER, true, ImportMaterialsDrawerTabs.Note);
      },
      validationKey: "NEED_TO_SAVE_CHANGES_FIELD_REQUEST",
    });
  }, [submitChanges, toggle, updating]);

  return (
    <AddNoteDocument onClick={onClick}>
      <AddNoteDocumentButton>
        <BsPlus />
      </AddNoteDocumentButton>
      <FormattedMessage id="ADD_NOTE_QUOTE" />
      <Loader loading={submitting} small />
    </AddNoteDocument>
  );
};

import { isUUID } from "@/common/utils/uuidUtils";

const isBooleanLike = (value: string | boolean): boolean => {
  return (
    value === true || value === false || value === "true" || value === "false"
  );
};

export const rowIsEmpty = (
  row: Record<string, string> | (string | null | undefined)[],
): boolean => {
  const values = Array.isArray(row) ? row : Object.values(row);
  return values.every((cell) => !cell || isUUID(cell) || isBooleanLike(cell));
};

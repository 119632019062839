import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useBidsPrices } from "@/contractor/pages/home/rfq-quotes/providers/BidsPricesProvider";
import { useRfqQuotes } from "@/contractor/pages/home/rfq-quotes/providers/RfqQuotesProvider";
import {
  QuoteStatus,
  RfqItemFieldsFragment,
  RfqQuotesQuoteFieldsFragment,
  RfqQuotesQuoteItemFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";

export const useQuoteItemCard = ({
  rfqItem,
  quoteItem,
  quote,
}: {
  rfqItem: RfqItemFieldsFragment;
  quoteItem?: RfqQuotesQuoteItemFieldsFragment;
  quote: RfqQuotesQuoteFieldsFragment;
}) => {
  const {
    rfq,
    selectedQuotes,
    setSelectedQuotes,
    allQuoteItems,
    multiQuote,
    setSelectedAuxiliaryQuoteItems,
  } = useRfqQuotes();
  const { selectEntireQuoteByBestPrice } = useBidsPrices();

  const isLess = useMemo(
    () =>
      new DecimalSafe(quoteItem?.quantityDecimal || 0).lessThan(
        rfqItem.quantityDecimal,
      ),
    [quoteItem?.quantityDecimal, rfqItem.quantityDecimal],
  );

  const missingItem = useMemo(
    () => !quoteItem || quoteItem.status === QuoteStatus.Withdrawn,
    [quoteItem],
  );

  const isSelected = useMemo(() => {
    return selectedQuotes.some((qi) => qi.quoteItemId === quoteItem?.id);
  }, [quoteItem?.id, selectedQuotes]);

  const isAwarded = useMemo(() => {
    return quote.status === QuoteStatus.Accepted;
  }, [quote.status]);

  const isRejected = useMemo(() => {
    return quote.status === QuoteStatus.Rejected;
  }, [quote.status]);

  const selectQuoteItem = useCallback(() => {
    if (rfq?.status === RfqStatus.Awarded) {
      if (isSelected) {
        setSelectedQuotes(
          selectedQuotes.filter((quote) => quote.quoteItemId !== quoteItem?.id),
        );
      } else if (selectedQuotes[0]?.quoteId !== quote?.id) {
        setSelectedQuotes(
          allQuoteItems
            .filter((qItem) => qItem.quoteId === quote?.id)
            .map((qItem) => ({
              rfqItemId: qItem.rfqItemId,
              quoteId: qItem.quoteId,
              quoteItemId: qItem.id,
            })),
        );
      } else {
        setSelectedQuotes([
          ...selectedQuotes,
          {
            rfqItemId: rfqItem.id,
            quoteId: quote?.id || "",
            quoteItemId: quoteItem?.id || "",
          },
        ]);
      }
      return;
    }

    if (rfqItem && !missingItem) {
      if (isSelected) {
        setSelectedQuotes(
          selectedQuotes.filter((quote) => quote.quoteItemId !== quoteItem?.id),
        );
      } else if (multiQuote) {
        const filteredQuotes = selectedQuotes.filter(
          (item) => item.rfqItemId !== rfqItem.id,
        );

        setSelectedQuotes([
          ...filteredQuotes,
          {
            rfqItemId: rfqItem.id,
            quoteId: quote?.id || "",
            quoteItemId: quoteItem?.id || "",
          },
        ]);
      } else {
        const sameQuoteSelected =
          selectedQuotes.length &&
          selectedQuotes.every((qi) => qi.quoteId === quote?.id);

        const entireQuote = selectEntireQuoteByBestPrice(quote?.id, rfqItem.id);
        setSelectedQuotes([
          ...(sameQuoteSelected ? selectedQuotes : entireQuote.quoteItems),
          {
            rfqItemId: rfqItem.id,
            quoteId: quote?.id || "",
            quoteItemId: quoteItem?.id || "",
          },
        ]);

        setSelectedAuxiliaryQuoteItems(entireQuote.auxQuoteItems);
      }
    }
  }, [
    rfqItem,
    isSelected,
    multiQuote,
    quote,
    missingItem,
    quoteItem?.id,
    selectedQuotes,
    setSelectedQuotes,
    selectEntireQuoteByBestPrice,
    setSelectedAuxiliaryQuoteItems,
    allQuoteItems,
    rfq?.status,
  ]);

  return {
    selectQuoteItem,
    isLess,
    missingItem,
    isSelected,
    isAwarded,
    isRejected,
  };
};

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";

import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import { useCallback } from "react";
import { getPhysicalColumnIndex } from "../../utils/getPhysicalColumnIndex";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { applyClasses } from "../utils/applyClasses";
import { applyTooltip } from "../utils/applyTooltip";
import { isValueEmpty } from "../utils/isValueEmpty";
import { removeTooltip } from "../utils/removeTooltip";
import { useCostCodeIsNotSupported } from "./useCostCodeIsNotSupported";

export const useCostCodeRenderer = () => {
  const { costCodeIsNotSupported } = useCostCodeIsNotSupported();
  const { zones } = useProjectZones();
  const baseRenderer = useBaseRenderer();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      baseRenderer(instance, td, row, col, prop, value, cellProperties);

      if (isValueEmpty(value)) {
        return;
      }

      const rowData = instance?.getDataAtRow(row);
      const zone = zones?.find(
        (z) =>
          z.name ===
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Zone)],
      );

      if (value && costCodeIsNotSupported(value, zone?.id)) {
        applyTooltip(td, costCodeIsNotSupported(value, zone?.id) || "");
        applyClasses(td, "text-red-500 bg-red-100 border-gray-200");
      } else {
        applyClasses(td, "");
        removeTooltip(td);
      }
    },
    [costCodeIsNotSupported, baseRenderer, zones],
  );

  return renderer;
};

import { DecimalSafe } from "@/common/utils/decimalSafe";
import {
  InventoryItemPendingFlowFieldsFragment,
  InventoryItemSummaryFieldsFragment,
} from "@/generated/graphql";
import { WarehouseInventoryItem } from "../../../types/WarehouseInventoryItem";
import inventoryItemsUtils from "../inventoryItemsUtils";

export const updateScheduledOutflows = (
  item: InventoryItemSummaryFieldsFragment,
  warehouses: WarehouseInventoryItem[],
  warehouseIds?: string[] | null,
) => {
  item.pendingOutflows.forEach(
    (outflow: InventoryItemPendingFlowFieldsFragment) => {
      const warehouse = warehouses.find(
        (w) => w.warehouse.id === outflow.release.sourceWarehouse?.id,
      );
      if (warehouse) {
        warehouse.scheduledOutflowTotal = new DecimalSafe(
          warehouse.scheduledOutflowTotal,
        )
          .plus(
            new DecimalSafe(outflow.quantityDecimal).minus(
              outflow.receivedQuantityDecimal,
            ),
          )
          .toNumber();
        warehouse.pendingOutflows = [
          ...(warehouse.pendingOutflows || []),
          outflow,
        ];
      } else if (
        outflow.release.sourceWarehouse &&
        (warehouseIds?.length === 0 ||
          warehouseIds?.includes(outflow.release.sourceWarehouse.id))
      ) {
        const newWarehouse = inventoryItemsUtils.generateWarehouseStateEntry(
          item,
          {
            warehouse: outflow.release.sourceWarehouse,
            remainingQuantity: new DecimalSafe(outflow.quantityDecimal)
              .minus(outflow.receivedQuantityDecimal)
              .toString(),
          },
        );
        newWarehouse.scheduledOutflowTotal = new DecimalSafe(
          outflow.quantityDecimal,
        )
          .minus(outflow.receivedQuantityDecimal)
          .toNumber();
        newWarehouse.pendingOutflows = [outflow];
        warehouses.push(newWarehouse);
      }
    },
  );
};

import { AssetViewer } from "@/common/components/asset-viewer/AssetViewer";
import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { InfoText } from "@/common/components/info-text/InfoText";
import { Loader } from "@/common/components/loader/Loader";
import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { UserAvatarStyled } from "@/common/components/user-avatar/UserAvatar";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  DeliverySlipStatus,
  UpdateDeliverySlipInput,
} from "@/generated/graphql";
import { Cached, ImageOutlined } from "@mui/icons-material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReplaceDeliverySlip } from "../../../../hooks/useReplaceDeliverySlip";
import { useRescanDeliverySlip } from "../../../../hooks/useRescanDeliverySlip";
import { useDeliverySlipVerification } from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipDateEdit } from "../delivery-slip-date-edit/DeliverySlipDateEdit";
import { DeliverySlipEditDialog } from "../delivery-slip-edit-dialog/DeliverySlipEditDialog";
import { DeliverySlipPoNumber } from "../delivery-slip-po-number/DeliverySlipPoNumber";
import { DeliverySlipPhotosPanel } from "./DeliverySlipPhotosPanel";

const Container = tw.div`grid grid-flow-row gap-4 mb-20 items-start content-start`;
const SlipContainer = tw.div`grid bg-blue-100 rounded-3xl pt-6 pb-5 mb-4 text-black h-full overflow-scroll`;
const UploadContainer = tw.div`grid p-4`;
const Header = tw.div`text-base font-medium px-6 pb-2 flex flex-col gap-1`;
const TimeLabel = tw.div`text-xs text-gray-600`;
const CreatedByInfo = tw.div`grid grid-flow-col gap-2 items-center`;
const InfoTextStyled = tw(InfoText)`px-6 pb-2 flex-1`;
const ReplaceSlip = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const NoSlipImageContainer = tw.div`grid grid-flow-col justify-center gap-4 place-items-center h-48 border-dashed border-spacing-2 border border-gray-500 rounded-lg m-3`;
const ImageOutlinedStyled = tw(ImageOutlined)`text-4xl text-gray-400`;
const OptionalText = tw.span`font-thin`;
const TitleGroup = tw.div`grid grid-flow-col gap-2 items-center`;
const ButtonContent = tw.div`flex flex-row items-center py-0`;
const CachedIcon = tw(Cached)`py-0 mr-1 text-xl text-white`;
const RescanButtonContainer = tw.div`mb-3 mx-6`;
const NotesContainer = tw.div`flex items-center`;
const Row = tw.div`grid grid-flow-col place-items-center justify-between`;

const DeliverySlipDetailsWithProvider: FC = () => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [isFulfillmentDateEditing, setIsFulfillmentDateEditing] =
    useState(false);
  const { deliverySlip, setScanningSlip, refetch, handleSaveFulfillmentDate } =
    useDeliverySlipVerification();
  const [fulfillmentDate, setFulfillmentDate] = useState<number | null>();
  const { replaceDeliverySlip, loading } = useReplaceDeliverySlip();
  const { release } = useRelease();
  const { setWarningAlert, setSuccessAlert } = useSnackbar();
  const { rescanDeliverySlip } = useRescanDeliverySlip();
  const { isSystemAdmin } = useUser();
  const intl = useIntl();

  const onSlipChange = useCallback(
    async (assets: AssetFieldsFragment[]) => {
      setScanningSlip(true);
      if (assets.length && deliverySlip) {
        const updateSlipProps: UpdateDeliverySlipInput = {
          id: deliverySlip.id,
          assetUrls: assets.map((asset) => asset.url),
          async: false,
        };

        if (assets.filter((asset) => asset.url.includes(".pdf?"))?.length > 1) {
          setWarningAlert(
            <FormattedMessage id="ONLY_ONE_PDF_DOCUMENT_ALLOWED" />,
          );
          setScanningSlip(false);
          return;
        }

        const newSlip = await replaceDeliverySlip(updateSlipProps);
        if (newSlip?.deliveredReleaseItems?.length === 0) {
          setWarningAlert(<FormattedMessage id="NO_ITEMS_ON_DELIVERY_SLIP" />);
        } else {
          setSuccessAlert(
            <FormattedMessage
              id="NUMBER_OF_ITEMS_FOUND_ON_DELIVERY_SLIP"
              values={{ items: newSlip?.deliveredReleaseItems?.length }}
            />,
          );
        }
        setScanningSlip(false);
      }
      setScanningSlip(false);
    },
    [
      setScanningSlip,
      deliverySlip,
      replaceDeliverySlip,
      setWarningAlert,
      setSuccessAlert,
    ],
  );

  const handleRescan = useCallback(async () => {
    if (deliverySlip?.id) {
      const result = await rescanDeliverySlip(deliverySlip.id);
      if (result.data?.rescanDeliverySlip) {
        await refetch();
      }
    }
  }, [deliverySlip?.id, rescanDeliverySlip, refetch]);

  useEffect(() => {
    setFulfillmentDate(deliverySlip?.fulfillmentDate);
  }, [deliverySlip?.fulfillmentDate]);

  return (
    <Container>
      <SlipContainer>
        <If isTrue={!loading}>
          <Header>
            <Row>
              <TitleGroup>
                <FormattedMessage id="PACKING_SLIP" />{" "}
                <If isTrue={!release?.type.requireDeliverySlip}>
                  <OptionalText>
                    (<FormattedMessage id="OPTIONAL" />)
                  </OptionalText>
                </If>
              </TitleGroup>
              <If isTrue={deliverySlip}>
                <CreatedByInfo>
                  <TimeLabel>
                    <FormattedDate
                      value={deliverySlip?.createdAt}
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                      hour="2-digit"
                      minute="2-digit"
                    />
                  </TimeLabel>
                  <UserAvatarStyled
                    firstName={deliverySlip?.createdBy.firstName}
                    lastName={deliverySlip?.createdBy.lastName}
                    email={deliverySlip?.createdBy.email}
                    photoUrl={deliverySlip?.createdBy.photoUrl}
                  />
                </CreatedByInfo>
              </If>
            </Row>
            <Row className="justify-end">
              <DeliverySlipDateEdit
                title={intl.$t({ id: "DELIVERY_SLIP_FULFILLMENT_DATE" })}
                date={fulfillmentDate ? new Date(fulfillmentDate) : null}
                isEditing={isFulfillmentDateEditing}
                toggleEditing={() =>
                  setIsFulfillmentDateEditing((prev) => !prev)
                }
                onChange={handleSaveFulfillmentDate}
                readonly={deliverySlip?.status === DeliverySlipStatus.Processed}
              />
            </Row>
            <Row className="justify-end">
              <DeliverySlipPoNumber value={deliverySlip?.poNumber} />
            </Row>
          </Header>
          <NotesContainer>
            {deliverySlip?.notes ? (
              <InfoTextStyled title="NOTES" body={deliverySlip?.notes} />
            ) : null}
            <If isTrue={isSystemAdmin && deliverySlip?.asset}>
              <RescanButtonContainer>
                <LoadingButton
                  button={OutlinedButton}
                  onClick={handleRescan}
                  loading={loading}
                  $small
                  className="h-6 pr-4"
                >
                  <ButtonContent>
                    <CachedIcon className="text-blue-800" />
                    <FormattedMessage id="RESCAN" />
                  </ButtonContent>
                </LoadingButton>
              </RescanButtonContainer>
            </If>
          </NotesContainer>

          {deliverySlip?.asset ? (
            <AssetViewer asset={deliverySlip?.asset} />
          ) : (
            <NoSlipImageContainer>
              <ImageOutlinedStyled />
              <FormattedMessage id="FULFILL_WITHOUT_PACKING_SLIP" />
            </NoSlipImageContainer>
          )}
          <If isTrue={!deliverySlip}>
            <UploadContainer>
              <UploadAsset
                accept={{
                  [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
                  [PDF_MIME_TYPE]: PDF_EXTENSIONS,
                }}
                testId="upload-delivery-slip"
                onChange={onSlipChange}
                mode="horizontal"
                className="flex items-start"
                classes={{
                  dndContainer: "h-[calc(50vh)]",
                  container: "grid",
                }}
                xs
              >
                <FormattedMessage id="DELIVERY_SLIP_SUPPORTED_FORMATS" />
              </UploadAsset>
            </UploadContainer>
          </If>

          <If isTrue={deliverySlip}>
            <UploadContainer>
              <ReplaceSlip>
                <FormattedMessage id="REPLACE_SLIP_TEXT" />
                <PrimaryButton $small onClick={() => setShowEditDialog(true)}>
                  <FormattedMessage id="REPLACE" />
                </PrimaryButton>
              </ReplaceSlip>
            </UploadContainer>
          </If>
        </If>
        <If isTrue={loading}>
          <Loader loading loadingTextKey="SCANNING_PACKING_SLIP" />
        </If>

        <DeliverySlipEditDialog
          visible={showEditDialog}
          setVisible={setShowEditDialog}
          handleConfirm={onSlipChange}
          initialAssets={[]}
        />
      </SlipContainer>
      {release && <DeliverySlipPhotosPanel release={release} expanded />}
    </Container>
  );
};

export const DeliverySlipDetails = () => {
  const { release } = useRelease();

  return (
    <UploadAssetProvider
      initialAssets={[]}
      context={AssetContext.DeliverySlip}
      projectId={release?.project?.id}
    >
      <DeliverySlipDetailsWithProvider />
    </UploadAssetProvider>
  );
};

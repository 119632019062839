import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Price } from "@/common/components/price/Price";
import { routes } from "@/config/routes";
import { InvoiceFieldsFragment } from "@/generated/graphql";
import { ReactNode, useCallback } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";

const SlipCard = tw.div`
  grid grid-flow-col py-4 px-4 gap-4 w-fit bg-white border rounded-lg border-gray-400 hover:bg-blue-100`;
const InvoiceDetailsContainer = tw.div`grid ml-2 relative`;
const InvoiceDetailsItem = tw.div`text-xs text-black font-normal`;
const DeletedIndicator = tw.span`text-2xs italic`;
const Container = tw.div`w-full`;

export const DuplicatedInvoicesPanel = ({
  duplicatedInvoices,
}: {
  duplicatedInvoices: InvoiceFieldsFragment["duplicates"];
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const getImageWrapper = useCallback(
    (id: string, children: ReactNode) => {
      const currentInvoice = duplicatedInvoices.find(
        (invoice) => invoice.id === id,
      );
      return (
        <SlipCard>
          <LinkLike
            onClick={() =>
              navigate(
                generatePath(routes.invoiceVerification, {
                  invoiceId: currentInvoice?.id,
                }),
                {
                  replace: true,
                },
              )
            }
          >
            {children}
          </LinkLike>
        </SlipCard>
      );
    },
    [duplicatedInvoices, navigate],
  );

  const getInvoiceDetails = useCallback(
    (parentId: string) => {
      const currentInvoice = duplicatedInvoices.find(
        (invoice) => invoice.id === parentId,
      );
      return (
        <InvoiceDetailsContainer>
          <If isTrue={currentInvoice?.number}>
            <InvoiceDetailsItem>
              #{currentInvoice?.number} -{" "}
              <If isTrue={currentInvoice?.archivedAt}>
                <DeletedIndicator>
                  <FormattedMessage id="DELETED" />
                </DeletedIndicator>
              </If>
            </InvoiceDetailsItem>
          </If>
          {currentInvoice?.issueDate && (
            <InvoiceDetailsItem>
              <FormattedDate value={new Date(currentInvoice?.issueDate)} />
            </InvoiceDetailsItem>
          )}
          <InvoiceDetailsItem>
            <Price price={currentInvoice?.total} />
          </InvoiceDetailsItem>
        </InvoiceDetailsContainer>
      );
    },
    [duplicatedInvoices],
  );

  return (
    <Container>
      <AssetsCard
        headerClassName="bg-blue-100"
        title={intl.$t({ id: "INVOICES_WITH_THE_SAME_NUMBER" })}
        expanded
        includeTooltip={false}
        includeNavigation={false}
        assets={[
          ...(duplicatedInvoices.map((i) => ({
            ...i.documents[i.documents.length - 1].asset,
            parentId: i.id,
          })) || []),
        ]}
        imageDetails={getInvoiceDetails}
        getImageWrapper={getImageWrapper}
      />
    </Container>
  );
};

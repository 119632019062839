import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { InvoiceDocumentType } from "@/common/components/invoices/invoice-issue/common/InvoiceIssue";
import { InvoiceIssues } from "@/common/components/invoices/invoice-issue/InvoiceIssues";
import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { MatchedOrderViewState } from "../../../../../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";
import { useInvoiceHeaderOnly } from "../common/hooks/useInvoiceHeaderOnly";
import { InvoiceHeaderOnlyTotals } from "../common/invoice-header-only-footer/InvoiceHeaderOnlyTotals";
import { InvoiceHeaderOnlyHeader } from "./invoice-header-only-header/InvoiceHeaderOnlyHeader";
import { InvoiceHeaderOnlyOptions } from "./invoice-header-only-options/InvoiceHeaderOnlyOptions";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const DashedDivider = tw.div`w-full border-t border-dashed border-gray-300 my-2`;

export const InvoiceHeaderOnly: FC = () => {
  const { setMatchedOrderViewState } = useInvoiceMatchedOrder();
  const { invoice } = useInvoiceVerification();
  const onEdit = useCallback(() => {
    setMatchedOrderViewState(
      MatchedOrderViewState.HEADER_ONLY_INVOICE_EDITABLE,
    );
  }, [setMatchedOrderViewState]);
  useInvoiceHeaderOnly();
  return (
    <Container>
      <InvoiceHeaderOnlyHeader />
      <InvoiceIssues
        invoice={invoice}
        documentType={InvoiceDocumentType.INVOICE}
      />
      <DashedDivider />
      <InvoiceHeaderOnlyOptions />
      <DashedDivider />
      <OutlinedButton onClick={onEdit} $small className="ml-4 mt-4">
        <FormattedMessage id="EDIT" />
      </OutlinedButton>
      <InvoiceHeaderOnlyTotals readonly />
    </Container>
  );
};

import { FileSourceSelector } from "@/common/components/file-source-selector/FileSourceSelector";
import { If } from "@/common/components/if/If";
import { InfoText } from "@/common/components/info-text/InfoText";
import { Loader } from "@/common/components/loader/Loader";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Select } from "@/common/components/select/components/single/Select";
import { Switch } from "@/common/components/switch/Switch";
import { TagsSelector } from "@/common/components/tags-selector/TagsSelector";
import { TextField } from "@/common/components/textfield/TextField";
import { FileTableCopyPaste } from "@/common/components/upload-file-mapper/FileTableCopyPaste";
import {
  CSV_EXTENSIONS,
  CSV_MIME_TYPE,
  FileUploadArea,
  XLS_EXTENSIONS,
  XLS_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import {
  FileType,
  ProjectExtendedFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import { LocalOfferOutlined } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useExternalEstimates } from "../../hooks/useExternalEstimates";
import { useProjectTags } from "../../hooks/useProjectTags";
import { useProjectZones } from "../../hooks/useProjectZones";
import { ImportItemsForm } from "./import-items-form/ImportItemsForm";
import {
  ImportItemsFormValues,
  useImportItems,
} from "./import-items-form/useImportItems";
import { ImportItemsTabs, ImportItemsType } from "./ImportItemsTabs";
import { MaterialFileMapper } from "./material-file-mapper/MaterialFileMapper";
import {
  MaterialFileUploadProvider,
  useMaterialFileUpload,
} from "./providers/MaterialFileUploadProvider";
import { useImportProjectItems } from "./useImportProjectItems";

export type ImportProjectItemsProps = {
  onClose: () => void;
  project: ProjectExtendedFieldsFragment;
};

const FormControlStyled = tw(FormControl)`
  flex flex-row w-full mb-2 justify-between
`;

const UploadAreaContainer = tw.div`
  grid h-full content-between gap-2
`;

const OverlayPanelContainer = tw.div`
  grid gap-2 content-start h-full w-full
`;

const FileFormatContainer = tw.div`
  mt-4 pb-8
`;

const StyledCheckbox = tw(Checkbox)`
  ml-1 text-blue-800 mb-2 pb-1
`;
const Label = tw.div`mr-2`;

export const NEW_ZONE_ID = "new-zone";

const MaterialFileUploadSelectionWithProvider: FC<ImportProjectItemsProps> = ({
  onClose,
  project,
}) => {
  const intl = useIntl();
  const { importingProjects } = useImportItems();
  const {
    sourceType,
    onSourceChanged,
    updateFile,
    uploadedFileSelected,
    customFileTypeSelected,
    myCustomTypeSelected,
    integrationTypeSelected,
    copyPasteTable,
    pastedDataSelected,
    removeOption,
    setZone,
    selectedZone,
    newZoneName,
    setNewZoneName,
    tags,
    setTags,
    clearAllEstimatedItems,
    setClearAllEstimatedItems,
    importZones,
    setImportZones,
    importManufacturers,
    setImportManufacturers,
    selectedEstimateExternalId,
    setSelectedEstimateExternalId,
    saveFormat,
    setSaveFormat,
    formatName,
    setFormatName,
    mappingMode,
    setMappingMode,
    disableSave,
    loadingError,
    handleSave,
    file,
    activeTab,
    setActiveTab,
  } = useImportProjectItems({ onClose, project });
  const {
    isUploading,
    fieldPreviews,
    loadingPreview,
    previewError,
    customFileFormats,
  } = useMaterialFileUpload();
  const { tags: projectTags } = useProjectTags();
  const { zones } = useProjectZones();

  const { loadingEstimates, foundationJobMaterialListOptions } =
    useExternalEstimates(project.id, sourceType);

  return (
    <OverlayPanel
      title={intl.$t({
        id:
          sourceType === SourceSystem.Foundation
            ? "IMPORT_MATERIAL_LIST"
            : "UPLOAD_MATERIAL_LIST",
      })}
      onSave={handleSave}
      saving={isUploading || importingProjects}
      disableSave={disableSave}
      onCancel={onClose}
      tabs={ImportItemsTabs}
      activeTab={activeTab}
      onTabChange={(tab) => setActiveTab(tab as ImportItemsType)}
      saveLabel={intl.$t({
        id: "IMPORT",
      })}
    >
      <OverlayPanelContainer>
        <If isTrue={activeTab === ImportItemsType.ImportFromEstimates}>
          <InfoText
            type="primary"
            title="YOUR_MATERIAL_LIST"
            body="YOUR_MATERIAL_LIST_DESCRIPTION"
          />
          <FormControlStyled>
            <FileSourceSelector
              value={sourceType}
              onChange={onSourceChanged}
              selectedFiles={file ? [file.name] : []}
              disabled={mappingMode}
              customFileFormats={customFileFormats}
              onRemoveCallback={removeOption}
            />
          </FormControlStyled>
          <If isTrue={sourceType === SourceSystem.Foundation}>
            <FormControlStyled>
              <Select
                placeholder={intl.$t({
                  id:
                    foundationJobMaterialListOptions.length === 0
                      ? "NO_MATERIAL_LIST_FOR_JOB"
                      : "SELECT_JOB_MATERIAL_LIST",
                })}
                className="w-full"
                options={foundationJobMaterialListOptions}
                getLabel={(option) =>
                  `${option.sourceId}${
                    option.description ? ` - ${option.description}` : ""
                  }`
                }
                getValue={(option) => option.externalId}
                value={selectedEstimateExternalId}
                onChange={setSelectedEstimateExternalId}
                loading={loadingEstimates}
              />
            </FormControlStyled>
          </If>
          <If
            isTrue={
              sourceType !== SourceSystem.Foundation ||
              selectedEstimateExternalId
            }
          >
            <FormControlStyled>
              <TagsSelector
                creatable
                options={projectTags}
                selectedTags={tags}
                setSelectedTags={setTags}
                limitTags={1}
                placeholder={intl.$t({
                  id: "TAGS",
                })}
                popup={<LocalOfferOutlined fontSize="small" />}
                project={project}
              />
            </FormControlStyled>
            <FormControlStyled>
              <Label>
                <FormattedMessage id="UPLOAD_ESTIMATE_CLEAR_EXISTING_PROJECT_ITEMS" />
              </Label>
              <Switch
                value={clearAllEstimatedItems}
                onChange={() =>
                  setClearAllEstimatedItems(!clearAllEstimatedItems)
                }
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
              />
            </FormControlStyled>
          </If>
          <If isTrue={sourceType !== SourceSystem.Foundation}>
            <FormControlStyled>
              <Label>
                <FormattedMessage id="UPLOAD_ESTIMATE_CLEAR_INCLUDE_ZONES" />
              </Label>
              <Switch
                value={importZones}
                onChange={() => setImportZones(!importZones)}
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
              />
            </FormControlStyled>
            <FormControlStyled>
              <Label>
                <FormattedMessage id="UPLOAD_ESTIMATE_IMPORT_MANUFACTURERS" />
              </Label>
              <Switch
                value={importManufacturers}
                onChange={() => setImportManufacturers(!importManufacturers)}
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
              />
            </FormControlStyled>
          </If>
          <If
            isTrue={
              !importZones &&
              (sourceType !== SourceSystem.Foundation ||
                selectedEstimateExternalId)
            }
          >
            <FormControlStyled>
              <Select
                placeholder={intl.$t({
                  id: "ZONE",
                })}
                className="w-full"
                options={zones}
                value={selectedZone}
                onChange={setZone}
                inputValue={newZoneName}
                handleInputChange={setNewZoneName}
                getLabel={(option) => option.name}
                getValue={(option) => option.id}
                creatable
                creatableFn={(value) => {
                  return {
                    id: NEW_ZONE_ID,
                    name: value,
                    inUse: false,
                  };
                }}
              />
            </FormControlStyled>
          </If>
          <If
            isTrue={
              (integrationTypeSelected ||
                customFileTypeSelected ||
                myCustomTypeSelected) &&
              sourceType !== SourceSystem.Foundation
            }
          >
            <UploadAreaContainer>
              <FileUploadArea
                onChange={updateFile}
                error={loadingError}
                accept={
                  myCustomTypeSelected
                    ? myCustomTypeSelected.fileType === FileType.Csv
                      ? {
                          [CSV_MIME_TYPE]: CSV_EXTENSIONS,
                        }
                      : {
                          [XLS_MIME_TYPE]: XLS_EXTENSIONS,
                        }
                    : undefined
                }
              />
            </UploadAreaContainer>
          </If>
          <If isTrue={uploadedFileSelected}>
            <Loader loading={loadingPreview}>
              <If isTrue={!previewError}>
                <InfoText type="primary" title="MAP_UPLOADED_FILES" />
                <MaterialFileMapper
                  mappingMode={mappingMode}
                  setMappingMode={setMappingMode}
                  fieldPreviews={fieldPreviews}
                />
                <If isTrue={!pastedDataSelected}>
                  <FileFormatContainer>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            value={saveFormat}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              setSaveFormat(e.target.checked);
                            }}
                          />
                        }
                        label={intl.$t({ id: "SAVE_FORMAT" })}
                      />
                      <If isTrue={saveFormat}>
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            label={intl.$t({ id: "FILE_FORMAT_NAME" })}
                            value={formatName}
                            disabled={!saveFormat}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => setFormatName(event.target.value)}
                          />
                        </FormControl>
                      </If>
                    </FormGroup>
                  </FileFormatContainer>
                </If>
              </If>
            </Loader>
            <If isTrue={previewError}>
              <InfoText
                type="error"
                title="UPLOAD_MATERIAL_LIST_ERROR"
                body="CUSTOM_FILE_UPLOAD_ERROR"
              />
            </If>
          </If>
          <If isTrue={copyPasteTable}>
            <FileTableCopyPaste
              handleChange={updateFile}
              instructions="COPY_PASTE_TABLE_DESCRIPTION"
            />
          </If>
        </If>
        <If isTrue={activeTab === ImportItemsType.ImportFromProject}>
          <ImportItemsForm />
        </If>
      </OverlayPanelContainer>
    </OverlayPanel>
  );
};

export const MaterialFileUploadSelection: FC<ImportProjectItemsProps> = (
  props,
) => {
  const form = useForm<ImportItemsFormValues>({
    defaultValues: {
      sourceProjectId: undefined,
      replace: false,
    },
  });
  return (
    <MaterialFileUploadProvider>
      <FormProvider {...form}>
        <MaterialFileUploadSelectionWithProvider {...props} />
      </FormProvider>
    </MaterialFileUploadProvider>
  );
};

import { LocalPaginationProvider } from "@/common/components/pagination-local/LocalPaginationProvider";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { Switch } from "@/common/components/switch/Switch";
import {
  ExternalMaterialComplianceGroupsProvider,
  useExternalMaterialComplianceGroups,
} from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/compliance-groups/ExternalComplianceGroupsProvider";
import { SourceSystem } from "@/generated/graphql";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportExternalComplianceGroups } from "../../../providers/ImportExternalComplianceGroupsProvider";
import { useImportExternalComplianceGroupsTableConfiguration } from "./ImportExternalComplianceGroupsTable.configuration";

const SearchBarTitleComponent = tw.div`flex flex-col pr-2`;
const ToggleText = tw.div`flex flex-row font-normal text-sm items-center mt-1`;

const ImportExternalComplianceGroupsTableWithProviders = () => {
  const intl = useIntl();
  const {
    sourceSystem,
    selectedExternalComplianceGroups,
    totalExternalComplianceGroupsCount,
    setSelectedExternalComplianceGroups,
    setTotalExternalComplianceGroupsCount,
    importAllExternalItems,
    setImportAllExternalItems,
  } = useImportExternalComplianceGroups();
  const { externalComplianceGroups, loadingExternalComplianceGroups } =
    useExternalMaterialComplianceGroups();
  const configuration = useImportExternalComplianceGroupsTableConfiguration(
    selectedExternalComplianceGroups,
  );

  useEffect(() => {
    setTotalExternalComplianceGroupsCount(externalComplianceGroups.length);
  }, [externalComplianceGroups, setTotalExternalComplianceGroupsCount]);

  return (
    <LocalSearchableList
      tableConfiguration={configuration}
      items={externalComplianceGroups}
      loadingItems={loadingExternalComplianceGroups}
      searchBarTitle={
        <SearchBarTitleComponent>
          <FormattedMessage id="SELECT_COMPLIANCE_GROUPS_TO_IMPORT" />
          <ToggleText>
            <FormattedMessage
              id="IMPORT_ALL_COMPLIANCE_GROUPS_FROM_SYSTEM"
              values={{
                system: intl.$t({ id: `INTEGRATION_${sourceSystem}` }),
              }}
            />
            <Switch
              className="ml-2"
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              value={importAllExternalItems}
              onChange={(value) => {
                setImportAllExternalItems(value);
                setSelectedExternalComplianceGroups(
                  value ? externalComplianceGroups.map((i) => i.id) : [],
                );
              }}
            />
          </ToggleText>
        </SearchBarTitleComponent>
      }
      searchKeys={["code", "name", "description"]}
      itemSelection={{
        type: "multiple",
        selectedItemIds: selectedExternalComplianceGroups,
        setSelectedItemIds: (ids: string[]) => {
          setImportAllExternalItems(
            ids.length === totalExternalComplianceGroupsCount,
          );
          setSelectedExternalComplianceGroups(ids);
        },
      }}
    />
  );
};

export const ImportExternalComplianceGroupsTable: FC<{
  defaultSourceSystem?: SourceSystem;
}> = ({ defaultSourceSystem }) => {
  return (
    <LocalPaginationProvider>
      <ExternalMaterialComplianceGroupsProvider
        defaultSourceSystem={defaultSourceSystem}
      >
        <ImportExternalComplianceGroupsTableWithProviders />
      </ExternalMaterialComplianceGroupsProvider>
    </LocalPaginationProvider>
  );
};

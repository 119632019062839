import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { FormattedMessage } from "react-intl";
import { SelectWarehouseDialog } from "../select-warehouse-dialog/SelectWarehouseDialog";
import { useAddTransfer } from "./useAddTransfer";

export const AddTransferButton = () => {
  const {
    handleTransfer,
    multipleWarehousesSelected,
    open,
    setOpen,
    handleConfirm,
    isSingleWarehouse,
  } = useAddTransfer();

  if (isSingleWarehouse) {
    return null;
  }

  return (
    <>
      <Tooltip
        element={
          <OutlinedButton
            onClick={handleTransfer}
            disabled={multipleWarehousesSelected}
          >
            <FormattedMessage id="TRANSFER" />
          </OutlinedButton>
        }
      >
        {multipleWarehousesSelected ? (
          <FormattedMessage id="TRANSFER_MULTIPLE_WAREHOUSES_TOOLTIP" />
        ) : (
          ""
        )}
      </Tooltip>
      <SelectWarehouseDialog
        open={open}
        onClose={() => setOpen(false)}
        labelKey="TRANSFER_FROM"
        handleConfirm={handleConfirm}
      />
    </>
  );
};

import { MaterialType } from "@/common/components/material/MaterialType";
import { MaterialView } from "@/common/components/material/MaterialView";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";

type Props = {
  item: InventoryItemFieldsFragment;
};
export const InventoryItemName: FC<Props> = ({ item }) => {
  return <MaterialView material={item.orgMaterial.material as MaterialType} />;
};

import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { getDiscountByAmount } from "@/common/components/price/utils/getDiscountByAmount";
import { getDiscountByPercentage } from "@/common/components/price/utils/getDiscountByPercentage";
import { sumQuoteGroupFullPrices } from "@/common/components/price/utils/sumQuoteGroupFullPrices";
import { TOTAL_PRICE_DECIMAL_POINTS } from "@/common/const";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { routes } from "@/config/routes";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import { RfqStatus } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { CancelBuyoutButton } from "../../../../buyout/components/common/CancelBuyoutButton";
import { CancelRfqButton } from "../../../../rfq/components/buttons/CancelRfqButton";
import { RequestChangesButton } from "../../../../rfq/components/buttons/RequestChangesButton";
import { useRfqQuoteOptions } from "../../../providers/RfqQuoteOptionsProvider";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";
import { CreateBuyoutButton } from "../create-buyout-button/CreateBuyoutButton";
import { CreateOrderButton } from "../create-order-button/CreateOrderButton";
import { useGetOrderFromQuote } from "./useGetOrderFromQuote";

const Container = tw.div`
  flex flex-row self-start justify-self-end right-12 
  justify-end col-span-full gap-2 mt-36 p-4 bg-white
`;

const TotalAmountContainer = tw.div`
  grid grid-cols-auto-auto justify-items-end gap-x-5 gap-y-1 pt-3 pl-3 pr-1
`;

const ButtonContainer = tw.div`
  flex self-start justify-self-end right-12 justify-end space-x-1
`;

const Label = tw.div`
  font-light text-left w-full
`;

const Value = tw.div`
  font-medium text-right
`;

export const TotalAmount = () => {
  const {
    rfq,
    selectedQuotes,
    selectedAuxiliaryQuoteItems,
    cancelQuote,
    createDraftQuote,
    allQuoteItems,
    auxiliaryItems,
  } = useRfqQuotes();
  const { calcAdditionalChargesPrice } = usePriceCalculation();
  const getNewOrderFromRfqQuote = useGetOrderFromQuote();
  const { createStandaloneRelease, creating } = useCreateRelease();
  const navigate = useNavigate();

  const { visibleQuotes } = useRfqQuoteOptions();
  const countBuyouts = useMemo(() => {
    return visibleQuotes.reduce((acc, quote) => {
      return (
        acc +
        (selectedQuotes.some((selected) =>
          quote.itemGroups.some((group) =>
            group.quoteItems.some(
              (quoteItem) => selected.quoteItemId === quoteItem.id,
            ),
          ),
        )
          ? 1
          : 0)
      );
    }, 0);
  }, [visibleQuotes, selectedQuotes]);

  const total: number = useMemo(() => {
    return (
      visibleQuotes.reduce((prevValue, quote) => {
        let discount = new DecimalSafe(0);
        const chargesAmount = Number(
          calcAdditionalChargesPrice(
            selectedQuotes.some(
              (selectedQuote) => selectedQuote.quoteId === quote.id,
            ) && selectedQuotes.length === 1
              ? quote.additionalCharges
              : [],
          ),
        );
        const sum =
          chargesAmount +
          allQuoteItems
            .filter((qi) =>
              selectedQuotes.some(
                (selected) =>
                  selected.quoteItemId === qi.id && quote.id === qi.quoteId,
              ),
            )
            .reduce((prevValue, item) => {
              const price = new DecimalSafe(item.unitPrice || 0).mul(
                item.quantityDecimal || 0,
              );
              return Number(price.add(new DecimalSafe(prevValue)));
            }, 0) +
          selectedAuxiliaryQuoteItems.reduce((prevValue, item) => {
            const auxItem = rfq?.quotes
              .map((q) => q.auxiliaryItems)
              .flat()
              .find(
                (aux) => aux.id === item.itemId && quote.id === item.quoteId,
              );
            const price = new DecimalSafe(auxItem?.unitPrice || 0).mul(
              auxItem?.quantityDecimal || 0,
            );
            return Number(price.add(new DecimalSafe(prevValue)));
          }, 0);

        if (quote.discount) {
          if (quote.discount.percentage) {
            discount = getDiscountByPercentage(sum, quote.discount.percentage);
          }
          if (quote.discount.amount) {
            discount = getDiscountByAmount(
              quote.discount.amount ?? "0",
              quote.itemGroups.reduce(sumQuoteGroupFullPrices, 0),
            ).mul(sum);
          }
        }
        return Number(new DecimalSafe(sum).sub(discount).add(prevValue));
      }, 0) ?? 0
    );
  }, [
    visibleQuotes,
    allQuoteItems,
    selectedAuxiliaryQuoteItems,
    calcAdditionalChargesPrice,
    selectedQuotes,
    rfq?.quotes,
  ]);

  const createDraftOrder = useCallback(async () => {
    const input = await getNewOrderFromRfqQuote({
      rfq,
      selectedQuotes,
      allQuoteItems,
      selectedAuxiliaryQuoteItems,
      auxiliaryItems,
    });
    const release = await createStandaloneRelease(input);
    if (release) {
      navigate(
        generatePath(routes.specifyDeliveryDetails, { deliveryId: release.id }),
      );
    }
  }, [
    createStandaloneRelease,
    navigate,
    getNewOrderFromRfqQuote,
    rfq,
    selectedQuotes,
    allQuoteItems,
    selectedAuxiliaryQuoteItems,
    auxiliaryItems,
  ]);

  const isDisabled = useMemo(() => {
    return !selectedQuotes.length;
  }, [selectedQuotes]);

  return (
    <>
      <Container>
        <TotalAmountContainer>
          <Label>
            <FormattedMessage id="TOTAL_BUYOUTS" />:
          </Label>
          <Value>{countBuyouts}</Value>
          <Label>
            <FormattedMessage id="TOTAL_COST" />:
          </Label>
          <Value>
            <Price
              price={total}
              maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
            />
          </Value>
        </TotalAmountContainer>
      </Container>
      <FloatingFooter>
        <ButtonContainer>
          <If
            isTrue={
              (rfq?.status === RfqStatus.Active ||
                rfq?.status === RfqStatus.Awarded) &&
              !rfq.quotes.some((quote) => quote.buyout) &&
              isAuthorized(rfq.permissions.archive)
            }
          >
            <CancelRfqButton cancelRfq={cancelQuote} rfq={rfq} />
          </If>
          <CancelBuyoutButton
            isVisible={rfq?.status === RfqStatus.Awarded}
            rfqId={rfq?.id}
            rfqNumber={rfq?.clientIdentifier}
          />
          <If isTrue={rfq?.status === RfqStatus.Awarded}>
            <PrimaryButton onClick={createDraftOrder} loading={creating}>
              <FormattedMessage id="CREATE_ORDER" />
            </PrimaryButton>
          </If>
          <If isTrue={rfq?.status !== RfqStatus.Awarded}>
            <If isTrue={isAuthorized(rfq?.permissions.edit)}>
              <RequestChangesButton
                rfq={rfq}
                createRfqDraft={createDraftQuote}
              />
            </If>
            <CreateBuyoutButton rfq={rfq} isDisabled={isDisabled} />
            <CreateOrderButton rfq={rfq} isDisabled={isDisabled} />
          </If>
        </ButtonContainer>
      </FloatingFooter>
    </>
  );
};

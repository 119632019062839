import { TextField } from "@/common/components/textfield/TextField";
import { DEFAULT_DEBOUNCE_TIME } from "@/common/const";
import { useSourceSystemLogo } from "@/common/hooks/integrations/source-system-config/useSourceSystemLogo";
import { IntegrationLogo } from "@/contractor/pages/admin/integrations/components/form/components/IntegrationLogo";
import { SourceSystem } from "@/generated/graphql";
import { InputBaseComponentProps } from "@mui/material";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDebouncedCallback } from "use-debounce";
import { If } from "../if/If";
import { Loader } from "../loader/Loader";
import { MagicWand } from "./MagicWand";

const TextFieldStyled = tw(TextField)`
  ${({ $autoSize }: { $autoSize?: boolean }) => ($autoSize ? "" : "w-28")}
  ${({ disabled }: { disabled?: boolean }) => disabled && "bg-gray-100"}
  ${({ staticText }: { staticText?: boolean }) =>
    !staticText && "bg-white rounded-md"}
`;
const IntegrationLogoStyled = tw(IntegrationLogo)`w-4 h-4 mr-2`;

type Props = {
  hideLabel?: boolean;
  value: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onBlur: (value?: string) => void;
  onChange: (type: string) => void;
  debouncedOnChange?: (type: string) => void;
  debouncedOnChangeTimeout?: number;
  sourceSystem?: SourceSystem;
  autoSize?: boolean;
  placeholder?: string;
  loading?: boolean;
  autoFillValue?: string;
  disabled?: boolean;
  staticText?: boolean;
  size?: "small" | "medium";
  inputProps?: InputBaseComponentProps | undefined;
  skipMagicWand?: boolean;
};

export const PoNumberInput: FC<Props> = ({
  hideLabel,
  value,
  onClick,
  onChange,
  onBlur,
  debouncedOnChange,
  debouncedOnChangeTimeout = DEFAULT_DEBOUNCE_TIME,
  sourceSystem,
  autoSize,
  placeholder,
  loading,
  autoFillValue,
  disabled,
  staticText,
  size = "small",
  inputProps,
  skipMagicWand,
}) => {
  const intl = useIntl();
  const debouncedOnChangeFn = useDebouncedCallback(
    debouncedOnChange
      ? (event: React.ChangeEvent<HTMLInputElement>) =>
          debouncedOnChange(event.target.value)
      : () => {},
    debouncedOnChangeTimeout,
  );
  const { sourceSystemLogo } = useSourceSystemLogo(sourceSystem, true);
  const small = useMemo(() => size === "small", [size]);

  return (
    <TextFieldStyled
      testId="poNumber"
      className="w-full"
      label={hideLabel ? null : intl.$t({ id: "PO_NUMBER" })}
      value={value}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        onClick?.(event);
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
        if (debouncedOnChange) {
          debouncedOnChangeFn(event);
        }
      }}
      placeholder={placeholder}
      onBlur={() => {
        if (debouncedOnChange) {
          debouncedOnChangeFn.cancel();
        }
        onBlur();
      }}
      InputLabelProps={{
        className: small ? "text-sm" : undefined,
        ...(placeholder && { shrink: true }),
      }}
      sx={
        small
          ? {
              ".MuiInputLabel-shrink": {
                top: 4,
                left: 3,
              },
            }
          : undefined
      }
      InputProps={{
        classes: {
          root: small ? "text-sm m-0 pl-0 pr-1" : undefined,
        },
        endAdornment: loading ? (
          <Loader loading small className="mr-2 justify-end" />
        ) : !!sourceSystem || autoFillValue ? (
          <>
            <If
              isTrue={
                !value && autoFillValue && !sourceSystem && !skipMagicWand
              }
            >
              <MagicWand
                onClick={() => {
                  onChange(autoFillValue || "");
                  onBlur(autoFillValue);
                }}
              />
            </If>
            <If isTrue={!!sourceSystem}>
              <IntegrationLogoStyled url={sourceSystemLogo} />
            </If>
          </>
        ) : undefined,
      }}
      inputProps={{
        className: !autoSize
          ? `h-4 ml-0 pl-2 ${inputProps?.className}`
          : inputProps?.className,
      }}
      disabled={!!sourceSystem || disabled}
      staticText={staticText}
      $autoSize={autoSize}
    />
  );
};

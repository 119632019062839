import { FC } from "react";
import { PricePickerWithUom } from "../../../../common/components/price-picker/PricePickerWithUom";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";
import { useDistributorQuoteUnitPriceInput } from "./distributor-quote-unit-price-input/useDistributorQuoteUnitPriceInput";

type Props = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
  readonly: boolean;
  index?: number;
  error?: boolean;
  hideErrorIcon?: boolean;
};

export const DistributorQuoteUnitPriceInput: FC<Props> = ({
  item,
  ...props
}) => {
  const { onChange } = useDistributorQuoteUnitPriceInput({ item });

  return (
    <PricePickerWithUom
      onChange={onChange}
      unitPrice={item.quoteItems[0]?.unitPrice}
      uom={item.rfqItem.projectItem.estimateUom}
      {...props}
    />
  );
};

import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ConnectReleasePOsPanel } from "@/contractor/pages/home/release/components/connections/components/panels/ConnectReleasePOsPanel";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSubmitChanges } from "../../../../pages/specify-details/hooks/useSubmitReleaseChanges";
import { ReleaseExportStatus } from "../common/release-export-status/ReleaseExportStatus";
import { useReleaseExportStatus } from "./hooks/useReleaseExportStatus";

type Props = {
  release: Pick<
    ReleaseFieldsFragment,
    | "status"
    | "id"
    | "poLink"
    | "version"
    | "poNumber"
    | "project"
    | "syncVersion"
    | "poExists"
    | "buyout"
    | "useSourceSystemPO"
  >;
};

export const ConnectReleasePOButton: FC<Props> = ({ release }) => {
  const intl = useIntl();
  const { submitChanges, submitting } = useSubmitChanges();
  const { status, tooltip } = useReleaseExportStatus({ release });
  const { connectedSourceSystem } = useOrgSettings();

  const onClick = useCallback(
    async (togglePanel: (opened: boolean) => void) => {
      await submitChanges({
        callback: () => {
          togglePanel(true);
        },
        validationKey: "NEED_TO_SAVE_CHANGES_EXPORT_SETTINGS",
      });
    },
    [submitChanges],
  );

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <>
          <If isTrue={release.poLink || release.poExists}>
            <ReleaseExportStatus
              status={status}
              tooltip={tooltip}
              sourceSystem={connectedSourceSystem}
              date={release.poLink?.failedAt || release.poLink?.syncedAt}
              poNumber={release.poNumber}
              projectId={release.project?.id}
              poExists={release.poExists}
              poLink={release.poLink}
              useSourceSystemPO={release.useSourceSystemPO}
            />
          </If>
          <OutlinedButton $small onClick={() => onClick(togglePanel)}>
            <Loader loading={submitting} small />
            {intl.$t({ id: "EXPORT_SETTINGS" })}
          </OutlinedButton>
        </>
      )}
      content={(togglePanel) => (
        <ConnectReleasePOsPanel
          releaseIds={[release.id]}
          onClose={() => togglePanel(false)}
          mode={ConnectionMode.Connect}
        />
      )}
    />
  );
};

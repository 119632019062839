import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { useToggleCategory } from "@/common/hooks/useToggleCategory";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { ZoneItemsContainer } from "@/contractor/pages/home/project/Project.styles";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { InventoryItemWithWarehouse } from "../../../../../../inventory-management/pages/current-stock/components/add-inventory-items/types/InventoryItemWithWarehouse";
import { useSwitchInventoryItemList } from "../hooks/useSwitchInventoryItemList";
import { useSwitchInventoryItems } from "../hooks/useSwitchInventoryItems";
import { SwitchInventoryItemsFilters } from "./components/inventory-items-filters/SwitchInventoryItemsFilters";

const Content = tw.div`grid gap-2`;
const ListContainer = tw.div`max-h-[calc(100vh-300px)] overflow-y-auto pr-4`;

type Props = {
  columns: Array<GridCol<InventoryItemWithWarehouse>>;
  warehouseId?: string;
};

export const SwitchInventoryItemList: FC<Props> = ({ columns }) => {
  const { costCodes, setCostCodes } = useSwitchInventoryItemList();
  const { toggleCategory } = useToggleCategory(costCodes, setCostCodes);
  const { selectedItem } = useSwitchInventoryItems();
  const { loading } = useInventoryItems();

  const itemFn = useCallback(
    (item: InventoryItemWithWarehouse) => {
      const isIncluded = selectedItem === item.id;
      return {
        className: isIncluded
          ? "border-2 border-blue-500 bg-blue-100 transition-all"
          : "opacity-90 transition-all bg-gray-100",
      };
    },
    [selectedItem],
  );

  return (
    <Content>
      <ListContainer>
        <SwitchInventoryItemsFilters />
        <ListRenderer>
          <GridTable
            loading={loading}
            configuration={{
              container: ZoneItemsContainer,
              columns,
              classNames: {
                header: "top-14",
                category: "top-[94px]",
                itemFn,
              },
              toggle: {
                category: toggleCategory,
              },
            }}
            emptyList={
              <NoResults
                isFiltered={false}
                translationKey="INVENTORY_ITEMS_LIST_NO_ITEMS"
              />
            }
            items={costCodes}
            virtualized
          />
        </ListRenderer>
      </ListContainer>
    </Content>
  );
};

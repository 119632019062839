import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { SourceSystem } from "@/generated/graphql";
import { ArrowBack, LockOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { RELEASE_EXPORT_STATUSES } from "../config/ReleaseExportStatuses";
import { ReleaseExportStatusEnum } from "../types/ReleaseExportStatusEnum";

type DotColor = "green" | "red" | "orange" | "blue";

const COLOR_MAP = {
  green: "bg-green-800",
  red: "bg-red-500",
  orange: "bg-orange-500",
  blue: "bg-blue-450",
  gray: "bg-gray-500",
};

const TEXT_COLOR_MAP = {
  green: "text-green-800",
  red: "text-red-500",
  orange: "text-orange-500",
  blue: "text-blue-450",
};

const IconContainer = tw.div`border border-dashed border-gray-500 rounded-full mr-1 w-6 h-6 flex items-center justify-center`;
const Arrow = tw(
  ArrowBack,
)`transition-all duration-500 animate-slide text-lg text-blue-500`;

const Status = tw.div<{
  status: ReleaseExportStatusEnum;
}>`w-2 h-2 rounded-full inline-block mr-2
${({ status }: { status: ReleaseExportStatusEnum }) =>
  COLOR_MAP[RELEASE_EXPORT_STATUSES[status].color as DotColor]}
${({ status }: { status: ReleaseExportStatusEnum }) =>
  status === ReleaseExportStatusEnum.Syncing && `animate-pulse`};
`;

const PoImmutableIcon = tw(LockOutlined)<{
  status: ReleaseExportStatusEnum;
}>`mr-1 text-2xl ${({ status }: { status: ReleaseExportStatusEnum }) =>
  TEXT_COLOR_MAP[RELEASE_EXPORT_STATUSES[status].color as DotColor]}
`;

type Props = {
  status: ReleaseExportStatusEnum;
  poLinkImmutable?: boolean;
  sourceSystem?: SourceSystem;
};

export const ReleaseExportStatusIcon: FC<Props> = ({
  status,
  poLinkImmutable,
  sourceSystem,
}) => {
  if (!poLinkImmutable) {
    return <Status status={status} />;
  }

  if (status === ReleaseExportStatusEnum.Syncing) {
    return (
      <IconContainer>
        <Arrow />
      </IconContainer>
    );
  }

  return (
    <Tooltip
      id="release-immutable-tooltip"
      element={<PoImmutableIcon status={status} />}
    >
      <FormattedMessage
        id="ORDER_IMMUTABLE_DESCRIPTION"
        values={{ sourceSystem }}
      />
    </Tooltip>
  );
};

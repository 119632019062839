import {
  DEFAULT_CURRENCY,
  PDF_FONT,
  PDF_LEFT_SPACING,
  TOTAL_PRICE_DECIMAL_POINTS,
} from "@/common/const";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { CostCodeType } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useOrgLevelCostCodes";
import { ZoneCategory } from "@/contractor/pages/home/project/providers/ProjectSpendingReportItemsProvider";
import { ProjectReportType } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import {
  ProjectReportCostCodeVendorFieldsFragment,
  ProjectReportVendorFieldsFragment,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

const EMPTY_STRING = "-";

const formatCurrency = (
  value: string | null | undefined,
  intl: IntlShape,
  currency: string,
) => {
  return (
    intl.formatNumber(Number(value), {
      minimumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
      maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
      currency: currency || DEFAULT_CURRENCY,
      style: "currency",
    }) || EMPTY_STRING
  );
};

const getVendorCostCode = (category: CategoryState<ProjectReportType>) => {
  return category as unknown as ProjectReportCostCodeVendorFieldsFragment & {
    id: string;
  };
};

const itemsForPdf = (
  intl: IntlShape,
  zones: ZoneCategory[],
  header: string[],
  formatCostCode: (costCode: CostCodeType) => string,
  currency: string,
): RowInput[] => {
  const result: RowInput[] = [];
  zones.forEach((zone) => {
    if (!(zones.length === 1 && zones[0].id === UNSPECIFIED_ZONE_ID)) {
      result.push([
        {
          content: `${zone.name} (${intl.$t({ id: "ZONE" })})`,
          colSpan: header.length,
          styles: {
            fontStyle: "bold",
            cellPadding: { left: 7, top: 3, bottom: 3 },
          },
        },
      ]);
    }
    zone.items.forEach((costCode) => {
      const code = getVendorCostCode(costCode);
      const budget = formatCurrency(
        code?.allowance || code?.estimated,
        intl,
        currency,
      );
      const received = formatCurrency(code?.received, intl, currency);
      const ordered = formatCurrency(code?.ordered, intl, currency);
      const invoiced = formatCurrency(code?.invoiced, intl, currency);
      const paid = formatCurrency(code?.paid, intl, currency);
      const overUnder = formatCurrency(code?.overUnder, intl, currency);
      result.push([
        {
          content: `${formatCostCode(costCode.id)} - (${intl.$t({ id: "COST_CODE" })})`,
          styles: {
            fontStyle: "bold",
            cellPadding: { left: 7, top: 3, bottom: 3 },
          },
        },
        budget,
        EMPTY_STRING,
        ordered,
        received,
        invoiced,
        paid,
        overUnder,
      ]);
      const items = costCode.items;
      items.forEach((item) => {
        const vendor = item as ProjectReportVendorFieldsFragment;
        const vendorName = vendor.vendor.name;
        const received = formatCurrency(vendor.received, intl, currency);
        const ordered = formatCurrency(vendor.ordered, intl, currency);
        const invoiced = formatCurrency(vendor.invoiced, intl, currency);
        const paid = formatCurrency(vendor.paid, intl, currency);

        result.push([
          vendorName,
          EMPTY_STRING,
          EMPTY_STRING,
          ordered,
          received,
          invoiced,
          paid,
          EMPTY_STRING,
        ]);
      });
    });
  });

  return result;
};

const HEADER = [
  "PROJECT_BUDGET_VENDOR_HEADER",
  "PROJECT_BUDGET_HEADER",
  "PROJECT_BUDGET_QUOTED_HEADER",
  "PROJECT_BUDGET_ORDERED_HEADER",
  "PROJECT_BUDGET_RECEIVED_HEADER",
  "PROJECT_BUDGET_INVOICED_HEADER",
  "PROJECT_BUDGET_PAID_HEADER",
  "PROJECT_BUDGET_ORDERED_VS_BUDGET_HEADER",
];

export const vendorsBudgetReport = (
  doc: jsPDF,
  intl: IntlShape,
  zones: ZoneCategory[],
  topSpacing: number,
  formatCostCode: (costCode: CostCodeType) => string,
  currency: string,
) => {
  const header = HEADER;

  doc
    .setFont(PDF_FONT, "", "bold")
    .setFontSize(10)
    .text(intl.$t({ id: "PROJECT_JOB_COSTS" }), PDF_LEFT_SPACING, topSpacing);
  topSpacing += 40;
  autoTable(doc, {
    theme: "grid",
    styles: {
      font: PDF_FONT,
      fontSize: 8,
    },
    startY: topSpacing,
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
      halign: "center",
    },
    columnStyles: {
      0: { valign: "middle" },
      1: { halign: "center", valign: "middle" },
      2: { halign: "center", valign: "middle" },
      3: { halign: "center", valign: "middle" },
      4: { halign: "center", valign: "middle" },
      5: { halign: "center", valign: "middle" },
      6: { halign: "center", valign: "middle" },
      7: { halign: "center", valign: "middle" },
    },
    head: [header.map((header) => intl.$t({ id: header }))],
    body: [...itemsForPdf(intl, zones, header, formatCostCode, currency)],
  });
};

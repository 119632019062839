import tw from "tailwind-styled-components";
import { NumericalInput } from "../../numerical-input/NumericalInput";

export const StyledNumericalInput = tw(NumericalInput)`w-full`;

export const TaxPercentageContainer = tw.div`grid gap-2 h-full items-center`;

export const SalesReadonlyTaxLabel = tw.div`font-medium text-sm`;

export const SalesTaxLabel = tw.div`flex gap-3 items-center justify-end`;

export const SalesTaxValue = tw.div`font-normal text-sm`;

export const RemainingSalesTaxValue = tw.div<{
  $lessThanRemaining?: boolean;
}>`font-light text-xs text-gray-600 ${({ $lessThanRemaining }) => $lessThanRemaining && "text-red-500"}`;

export const SalesTaxValueContainer = tw.div`grid`;

export { ItemContainer } from "../../additional-charges/AdditionalCharges.styles";

import { FC } from "react";
import { Outlet } from "react-router";
import tw from "tailwind-styled-components";
import { BudgetTabNavigation } from "./BudgetTabNavigation";

const Container = tw.div`mt-5 xl:px-16 xl:-mx-16 bg-gray-100 flex flex-col -mb-8 pb-8 min-h-[calc(100vh-210px)]`;

export const WithBudgetTabNavigation: FC = () => (
  <>
    <BudgetTabNavigation />
    <Container>
      <Outlet />
    </Container>
  </>
);

import { DecimalSafe } from "@/common/utils/decimalSafe";

type InventoryItemWithState = {
  state?: Array<{
    remainingQuantity: string | number;
    warehouse: {
      id: string;
    };
  }>;
};

export const getInventoryItemRemainingQuantity = (
  inventoryItem?: InventoryItemWithState,
  warehouseId?: string,
) => {
  if (!inventoryItem?.state) {
    return new DecimalSafe(0);
  }
  return inventoryItem.state
    .filter((state) => !warehouseId || state.warehouse.id === warehouseId)
    .reduce(
      (acc: DecimalSafe, state: { remainingQuantity: string | number }) =>
        acc.plus(state.remainingQuantity),
      new DecimalSafe(0),
    );
};

import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { readValue, setValue } from "@/common/utils/localStorage";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { disabledColumns as defaultDisabledColumns } from "../config/defaultConfiguration";
import { useEditColumnsStore } from "../store/useEditColumnsStore";
import { useExtendedColumnConfig } from "./useExtendedColumnConfig";

export type ColumnItemType = {
  id: string;
  header: string;
  enabled: boolean;
  isOptional: boolean;
};

export const useColumnManagement = (visible: boolean, pageId?: string) => {
  const isEnabled = !!pageId;

  const storageKey = useMemo(
    () => `${LOCAL_STORAGE_KEYS.CUSTOM_COLUMNS}_${pageId || "default"}`,
    [pageId],
  );

  const extendedConfig = useExtendedColumnConfig();
  const { setHiddenColumns } = useEditColumnsStore(
    useShallow((state) => ({
      setHiddenColumns: state.setHiddenColumns,
    })),
  );

  const getColumnsFromStorage = useCallback((): ColumnItemType[] => {
    if (!isEnabled) {
      return [];
    }

    const columnsFromConfig = extendedConfig
      .filter((column) => !column.metadataColumn && !column.deactivated)
      .map((column) => ({
        id: column.columnType,
        header: column.header,
        enabled: !column.hidden,
        isOptional: column.optional === true,
      }));

    const savedColumns = readValue(storageKey);

    if (savedColumns && Array.isArray(savedColumns)) {
      const savedColumnsMap = new Map(savedColumns.map((col) => [col.id, col]));

      return columnsFromConfig.map((column) => {
        const savedColumn = savedColumnsMap.get(column.id);
        if (savedColumn) {
          return {
            ...column,
            enabled: column.isOptional ? savedColumn.enabled : true,
          };
        }
        return column;
      });
    }

    if (isEnabled && pageId && defaultDisabledColumns[pageId]) {
      return columnsFromConfig.map((column) => ({
        ...column,
        enabled: column.isOptional
          ? !defaultDisabledColumns[pageId]?.includes(column.id)
          : true,
      }));
    }

    return columnsFromConfig;
  }, [extendedConfig, storageKey, isEnabled, pageId]);

  const initialColumns = useMemo(
    () => getColumnsFromStorage(),
    [getColumnsFromStorage],
  );
  const [columns, setColumns] = useState<ColumnItemType[]>(initialColumns);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    if (visible) {
      const savedColumns = getColumnsFromStorage();
      setColumns(savedColumns);
    }
  }, [visible, getColumnsFromStorage, isEnabled]);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    if (!visible) {
      // We no longer automatically save columns when dialog closes
    }
  }, [visible, isEnabled]);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }
    // We no longer automatically update the store when columns change
  }, [isEnabled]);

  const enabledColumns = useMemo(
    () => columns.filter((column) => column.enabled),
    [columns],
  );

  const disabledColumnsList = useMemo(
    () => columns.filter((column) => !column.enabled),
    [columns],
  );

  const [isEnabledDragActive, setIsEnabledDragActive] = useState(false);
  const [isDisabledDragActive, setIsDisabledDragActive] = useState(false);

  const onDragStart = useCallback(
    (e: React.DragEvent<HTMLDivElement>, column: ColumnItemType) => {
      e.dataTransfer.setData("text/plain", column.id);
    },
    [],
  );

  const onDragOverEnabled = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsEnabledDragActive(true);
    },
    [],
  );

  const onDragOverDisabled = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDisabledDragActive(true);
    },
    [],
  );

  const onDragLeaveEnabled = useCallback(() => {
    setIsEnabledDragActive(false);
  }, []);

  const onDragLeaveDisabled = useCallback(() => {
    setIsDisabledDragActive(false);
  }, []);

  const onDropToEnabled = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const id = e.dataTransfer.getData("text/plain");
    setIsEnabledDragActive(false);

    setColumns((prev) => {
      const columnToMove = prev.find((column) => column.id === id);
      if (!columnToMove) {
        return prev;
      }

      if (columnToMove.enabled) {
        return prev;
      }

      return prev.map((column) =>
        column.id === id ? { ...column, enabled: true } : column,
      );
    });
  }, []);

  const onDropToDisabled = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const id = e.dataTransfer.getData("text/plain");
    setIsDisabledDragActive(false);

    setColumns((prev) => {
      const columnToMove = prev.find((column) => column.id === id);
      if (!columnToMove) {
        return prev;
      }

      if (!columnToMove.enabled) {
        return prev;
      }

      if (!columnToMove.isOptional) {
        return prev;
      }

      return prev.map((column) =>
        column.id === id ? { ...column, enabled: false } : column,
      );
    });
  }, []);

  const moveToEnabled = useCallback((id: string) => {
    setColumns((prev) =>
      prev.map((column) =>
        column.id === id ? { ...column, enabled: true } : column,
      ),
    );
  }, []);

  const moveToDisabled = useCallback((id: string) => {
    setColumns((prev) =>
      prev.map((column) =>
        column.id === id ? { ...column, enabled: false } : column,
      ),
    );
  }, []);

  const canBeMoved = useCallback(
    (column: ColumnItemType) => {
      if (!isEnabled) {
        return false;
      }
      return column.isOptional;
    },
    [isEnabled],
  );

  const resetColumns = useCallback(() => {
    if (!isEnabled) {
      return;
    }

    const savedColumns = getColumnsFromStorage();
    setColumns(savedColumns);
  }, [getColumnsFromStorage, isEnabled]);

  const saveColumnLayout = useCallback(() => {
    if (!isEnabled) {
      return;
    }

    setValue(storageKey, columns);

    const disabledColumnIds = columns
      .filter((column) => !column.enabled && column.isOptional)
      .map((column) => column.id);

    setHiddenColumns(disabledColumnIds);
  }, [columns, storageKey, setHiddenColumns, isEnabled]);

  return {
    columns,
    enabledColumns,
    disabledColumns: disabledColumnsList,
    isEnabledDragActive,
    isDisabledDragActive,
    onDragStart,
    onDragOverEnabled,
    onDragOverDisabled,
    onDragLeaveEnabled,
    onDragLeaveDisabled,
    onDropToEnabled,
    onDropToDisabled,
    moveToEnabled,
    moveToDisabled,
    canBeMoved,
    saveColumnLayout,
    resetColumns,
  };
};

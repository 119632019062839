import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { UpdateInvoiceInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  InvoiceDetailsFooter,
  InvoiceFooterWarningIconRed,
} from "../../../../../../../../../common/components/invoices/invoice-details/footer/InvoiceFooterDetails";
import { useInvoiceValidation } from "../../../../scanned-invoices/providers/InvoiceValidationProvider";
import { useInvoiceFooterTotalsWarning } from "../../../hooks/useInvoiceFooterTotalsWarning";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";
import {
  Column,
  ContentColumn,
  PanelTitle,
  QuarterPanel,
} from "./InvoiceExpandable.styled";

export const InvoicePanel: FC = () => {
  const { invoice, updateInvoice } = useInvoiceVerification();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const {
    invoiceSalesTaxGreaterThanOrderSalesTax,
    invoiceChargesGreaterThanOrderCharges,
  } = useInvoiceFooterTotalsWarning();

  const handleUpdateInvoice = useCallback(
    (changes: UpdateInvoiceInput) => {
      refetchInvoiceValidation();
      return updateInvoice(changes);
    },
    [refetchInvoiceValidation, updateInvoice],
  );

  const readonly = useMemo(
    () => !isAuthorized(invoice?.permissions.edit) || !!invoice?.archivedAt,
    [invoice],
  );

  return (
    <QuarterPanel data-testid="invoiceFooter" className="bg-blue-250">
      <Column>
        <FormattedMessage id="INVOICE" tagName={PanelTitle} />
      </Column>
      <ContentColumn>
        <InvoiceDetailsFooter
          invoice={invoice}
          updateInvoice={handleUpdateInvoice}
          readonly={readonly}
          salesTaxWarning={
            <If isTrue={invoiceSalesTaxGreaterThanOrderSalesTax}>
              <Tooltip
                id="INVOICE_FOOTER_SALES_TAX_ERROR"
                element={<InvoiceFooterWarningIconRed />}
              >
                <FormattedMessage id="INVOICE_FOOTER_SALES_TAX_ERROR" />
              </Tooltip>
            </If>
          }
          additionalChargesWarning={
            <If isTrue={invoiceChargesGreaterThanOrderCharges}>
              <Tooltip
                id="INVOICE_FOOTER_CHARGES_ERROR"
                element={<InvoiceFooterWarningIconRed />}
              >
                <FormattedMessage id="INVOICE_FOOTER_CHARGES_ERROR" />
              </Tooltip>
            </If>
          }
        />
      </ContentColumn>
    </QuarterPanel>
  );
};

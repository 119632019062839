import { SpecifyCostCode } from "@/common/components/specify-cost-code/SpecifyCostCode";
import {
  ProjectCostCodeFieldsFragment,
  ZoneFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { ExpandablePanel } from "../expandable-panel/ExpandablePanel";
import { SpecifyZone } from "../specify-zone/SpecifyZone";

type Props = {
  costCode?: ProjectCostCodeFieldsFragment | null | undefined;
  zone?: ZoneFieldsFragment | null | undefined;
};

export const SpecifyCostCodeAndZoneExpandable: FC<Props> = ({
  costCode,
  zone,
}) => {
  const intl = useIntl();

  if (!costCode && !zone) {
    return null;
  }
  return (
    <ExpandablePanel
      header={intl.$t({
        id:
          !!zone && !!costCode
            ? "SPECIFIED_COST_CODE_AND_ZONE"
            : zone
              ? "SPECIFIED_ZONE"
              : "SPECIFIED_COST_CODE",
      })}
      isOpened={true}
    >
      <SpecifyCostCode costCode={costCode} />
      <SpecifyZone zone={zone} />
    </ExpandablePanel>
  );
};

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFormattedMaterialName } from "@/common/components/spreadsheet-table/hooks/useFormattedMaterialName";
import { ExtraOption } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { addExtraItemDetails } from "@/common/components/spreadsheet-table/utils/addExtraItemDetails";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";

const getTotalQuantity = (inventoryItem: InventoryItemFieldsFragment) => {
  return inventoryItem.state.reduce(
    (acc, curr) => acc.plus(curr.remainingQuantity),
    new DecimalSafe(0),
  );
};

export const useInventoryTransferItemsDecorator = () => {
  const { materials } = useMaterials();
  const { inventoryItems } = useInventoryItems();
  const getFormattedMaterialName = useFormattedMaterialName();

  const extraOptions = useMemo(() => {
    const options =
      inventoryItems
        .filter((item) => item.state.length > 0)
        .toSorted((a, b) => {
          const quantityA = getTotalQuantity(a).toNumber();
          const quantityB = getTotalQuantity(b).toNumber();
          if (quantityA === 0 && quantityB === 0) {
            return 0;
          }
          if (quantityA === 0 || quantityB === 0) {
            return quantityA === 0 ? 1 : -1;
          }
          return a.orgMaterial.material?.name.localeCompare(
            b.orgMaterial.material?.name,
          );
        })
        .map((inventoryItem) => {
          const material = materials.find(
            (m) => m.id === inventoryItem.orgMaterial.id,
          );
          const totalQuantity = getTotalQuantity(inventoryItem);

          const estimatedItemDetails = addExtraItemDetails(
            `${totalQuantity?.toString()} ${inventoryItem?.uom.pluralDescription}`,
            totalQuantity.toNumber() > 0 ? "text-blue-800" : "text-blue-250",
          );

          return {
            name: material ? getFormattedMaterialName(material) : "",
            content: estimatedItemDetails,
            value: inventoryItem.id,
            prefilling: [
              [COLUMN_TYPE.UOM, inventoryItem.uom.pluralDescription],
              [
                COLUMN_TYPE.ExistingStock,
                getInventoryItemRemainingQuantity(inventoryItem).toNumber(),
              ],
            ],
          } as ExtraOption;
        }) || [];
    return options;
  }, [materials, inventoryItems, getFormattedMaterialName]);

  return {
    extraOptions,
  };
};

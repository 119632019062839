import { useDialog } from "@/common/components/dialog/DialogProvider";
import { QUERYSTRING } from "@/common/const";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useInventoryItemsStore } from "@/contractor/pages/admin/inventory-items/store/useInventoryItemsStore";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { isRestockRelease } from "@/contractor/pages/home/release/utils/isRestockRelease";
import { isWarehouseRelease } from "@/contractor/pages/home/release/utils/isWarehouseRelease";
import {
  CreateDeliveredReleaseItemInput,
  DeliverySlipStatus,
  ReleaseStatus,
  UpdateDeliveredReleaseItemInput,
} from "@/generated/graphql";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useReleaseSequence } from "../../../../../deliveries/providers/ReleaseSequenceProvider";
import { useArchiveDeliverySlip } from "../../../../hooks/useArchiveDeliverySlip";
import {
  UpdateReleaseFormValues,
  useDeliverySlipRelease,
} from "../../../../providers/DeliverySlipReleaseProvider";
import { useDeliverySlipSequence } from "../../../../providers/DeliverySlipSequenceProvider";
import { useDeliverySlipStats } from "../../../../providers/DeliverySlipStatsProvider";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";

export const useDeliverySlipReceiveFooter = () => {
  const [receiveDialogIsVisible, setReceiveDialogIsVisible] = useState(false);
  const intl = useIntl();
  const { release } = useRelease();
  const { openDialog } = useDialog();
  const { refetch } = useDeliverySlipStats();
  const { archiveDeliverySlip } = useArchiveDeliverySlip();
  const [confirming, setConfirming] = useState(false);
  const { deliverySlip, updateDeliverySlip, setPackingSlipReceiveViewState } =
    useDeliverySlipVerification();
  const { queryParams, setQueryParams } = useQueryParams();
  const { selectedReleaseItemIds, submitUpdate } = useReleaseUpdate();
  const {
    receivedQuantities,
    updateReleaseForm: form,
    syncUpdateReleaseFromDeliverySlip,
  } = useDeliverySlipRelease();
  const { navigateToNextSequenceOrRedirect } = useDeliverySlipSequence();
  const {
    sequenceActive: releaseSequenceActive,
    navigateToNextSequence: navigateToNextReleaseSequence,
  } = useReleaseSequence();
  const [updateDialogVisible, setUpdateDialogVisible] = useState(false);
  const { fetchInventoryItems } = useInventoryItemsStore(
    useShallow((state) => ({
      fetchInventoryItems: state.fetchInventoryItems,
    })),
  );
  const { setWarningAlert, setSuccessAlert } = useSnackbar();

  const saveRelease = useCallback(
    async ({
      skipVendorNotification,
      skipConfirmation,
    }: {
      skipVendorNotification?: boolean;
      skipConfirmation?: boolean;
    } = {}) => {
      await form?.handleSubmit(async (values: UpdateReleaseFormValues) => {
        const sync = await syncUpdateReleaseFromDeliverySlip({
          ...values,
          skipConfirmation,
          skipVendorNotification,
        });
        if (!sync) {
          return null;
        }
        setPackingSlipReceiveViewState(
          deliverySlip?.status === DeliverySlipStatus.Processed
            ? PackingSlipReceiveViewState.DEFAULT
            : PackingSlipReceiveViewState.EDIT_COVERAGE,
        );

        if (queryParams.get(QUERYSTRING.SPREADSHEET_VIEW)) {
          setQueryParams({ [QUERYSTRING.SPREADSHEET_VIEW]: undefined });
        }
      })();
      setUpdateDialogVisible(false);
      return true;
    },
    [
      deliverySlip?.status,
      form,
      queryParams,
      setPackingSlipReceiveViewState,
      setQueryParams,
      syncUpdateReleaseFromDeliverySlip,
    ],
  );

  const onSaveClick = useCallback(async () => {
    if (
      release &&
      [
        ReleaseStatus.Requested,
        ReleaseStatus.Scheduled,
        ReleaseStatus.PartiallyReceived,
      ].includes(release?.status)
    ) {
      setUpdateDialogVisible(true);
      return;
    }
    await saveRelease();
  }, [saveRelease, release]);

  const navigateToOrder = useCallback(() => {
    if (!releaseSequenceActive) {
      navigateToNextSequenceOrRedirect(deliverySlip?.id, {
        navigateToOrder: true,
        orderId: release?.id,
      });
    } else {
      navigateToNextReleaseSequence({
        navigateToReleases: false,
        navigateToReleaseId: release?.id,
      });
    }
  }, [
    deliverySlip?.id,
    navigateToNextReleaseSequence,
    navigateToNextSequenceOrRedirect,
    release?.id,
    releaseSequenceActive,
  ]);

  const saveReceivedQuantities = useCallback(
    async ({ receive }: { receive: boolean }) => {
      const itemsToAdd: CreateDeliveredReleaseItemInput[] =
        selectedReleaseItemIds
          ?.filter(
            (id) =>
              deliverySlip?.deliveredReleaseItems.find(
                (item) => item.releaseItem.id === id,
              ) === undefined,
          )
          .map((id) => ({
            releaseItemId: id,
            quantity:
              receivedQuantities.find(
                (receivedItem) => receivedItem.releaseItemId === id,
              )?.receivedQuantityDecimal ?? "0",
          })) || [];
      const itemsToUpdate: UpdateDeliveredReleaseItemInput[] =
        deliverySlip?.deliveredReleaseItems
          ?.filter((item) =>
            selectedReleaseItemIds.includes(item.releaseItem.id),
          )
          .map((item) => ({
            id: item.id,
            quantity:
              receivedQuantities.find(
                (receivedItem) =>
                  receivedItem.releaseItemId === item.releaseItem.id,
              )?.receivedQuantityDecimal ?? "0",
          })) || [];
      const itemsToRemove = deliverySlip?.deliveredReleaseItems
        .filter((item) => !selectedReleaseItemIds.includes(item.releaseItem.id))
        .map((item) => item.id);

      const result = await updateDeliverySlip({
        id: deliverySlip?.id ?? "",
        releaseId: release?.id ?? "",
        addedDeliveredReleaseItems: itemsToAdd,
        removedDeliveredReleaseItems: itemsToRemove,
        updatedDeliveredReleaseItems: itemsToUpdate,
        receive,
      });

      return result;
    },
    [
      deliverySlip?.deliveredReleaseItems,
      deliverySlip?.id,
      receivedQuantities,
      release?.id,
      selectedReleaseItemIds,
      updateDeliverySlip,
    ],
  );

  const confirm = useCallback(async () => {
    setConfirming(true);
    const itemsToAdd: CreateDeliveredReleaseItemInput[] =
      selectedReleaseItemIds
        ?.filter(
          (id) =>
            deliverySlip?.deliveredReleaseItems.find(
              (item) => item.releaseItem.id === id,
            ) === undefined,
        )
        .map((id) => ({
          releaseItemId: id,
          quantity:
            receivedQuantities.find(
              (receivedItem) => receivedItem.releaseItemId === id,
            )?.receivedQuantityDecimal ?? "0",
        })) || [];
    const itemsToUpdate: UpdateDeliveredReleaseItemInput[] =
      deliverySlip?.deliveredReleaseItems
        ?.filter((item) => selectedReleaseItemIds.includes(item.releaseItem.id))
        .map((item) => ({
          id: item.id,
          quantity:
            receivedQuantities.find(
              (receivedItem) =>
                receivedItem.releaseItemId === item.releaseItem.id,
            )?.receivedQuantityDecimal ?? "0",
        })) || [];
    const itemsToRemove = deliverySlip?.deliveredReleaseItems
      .filter((item) => !selectedReleaseItemIds.includes(item.releaseItem.id))
      .map((item) => item.id);

    const hasUpdates = itemsToAdd.length > 0 || itemsToUpdate.length > 0;
    if (
      !hasUpdates &&
      release &&
      (isWarehouseRelease(release) || isRestockRelease(release))
    ) {
      setWarningAlert(intl.$t({ id: "DELIVERY_SLIP_VALIDATION_NO_ITEMS" }));
      setConfirming(false);
      return;
    }

    const result = await updateDeliverySlip({
      id: deliverySlip?.id ?? "",
      releaseId: release?.id ?? "",
      addedDeliveredReleaseItems: itemsToAdd,
      removedDeliveredReleaseItems: itemsToRemove,
      updatedDeliveredReleaseItems: itemsToUpdate,
      receive: true,
    });

    if (!result) {
      setConfirming(false);
      return;
    }

    fetchInventoryItems(true);
    await refetch();

    const submitResult = submitUpdate();
    if (!submitResult) {
      setConfirming(false);
      return;
    }

    setConfirming(false);
    navigateToOrder();

    if (!submitResult) {
      setConfirming(false);
      navigateToOrder();
    }
  }, [
    selectedReleaseItemIds,
    deliverySlip?.deliveredReleaseItems,
    deliverySlip?.id,
    release,
    updateDeliverySlip,
    fetchInventoryItems,
    refetch,
    submitUpdate,
    navigateToOrder,
    receivedQuantities,
    setWarningAlert,
    intl,
  ]);

  const archiveSlip = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      includeErrorIcon: true,
      title: intl.$t({ id: "DELETE_DELIVERY_SLIP_DETAILS" }),
      text: intl.$t({ id: "DELETE_DELIVERY_SLIP" }),
      handleConfirm: async () => {
        if (await archiveDeliverySlip(deliverySlip?.id ?? "")) {
          await refetch();
          navigateToNextSequenceOrRedirect(deliverySlip?.id);
        }
      },
    });
  }, [
    archiveDeliverySlip,
    deliverySlip?.id,
    intl,
    navigateToNextSequenceOrRedirect,
    openDialog,
    refetch,
  ]);

  const close = useCallback(() => {
    navigateToNextSequenceOrRedirect(deliverySlip?.id);
  }, [deliverySlip?.id, navigateToNextSequenceOrRedirect]);

  const handleConfirm = useCallback(() => {
    if (release?.status === ReleaseStatus.Reserved && !release?.time) {
      setReceiveDialogIsVisible(true);
      return;
    }
    confirm();
  }, [confirm, release?.status, release?.time]);

  const saveProgress = useCallback(async () => {
    const result = await saveReceivedQuantities({ receive: false });
    if (result) {
      setSuccessAlert(intl.$t({ id: "DELIVERY_SLIP_UPDATED_SUCCESS" }));
    }
  }, [intl, saveReceivedQuantities, setSuccessAlert]);

  return {
    saveProgress,
    handleConfirm,
    close,
    archiveSlip,
    onSaveClick,
    updateDialogVisible,
    setUpdateDialogVisible,
    saveRelease,
    confirming,
    setConfirming,
    confirm,
    receiveDialogIsVisible,
    setReceiveDialogIsVisible,
  };
};

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useSpreadSheetWarning } from "@/common/components/spreadsheet-table/hooks/useSpreadSheetWarning";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import {
  SpreadsheetSaveType,
  useColumnMapper,
} from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useSupplierOptions } from "@/common/components/supplier-picker/useSupplierOptions";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { ReleaseStatus } from "@/generated/graphql";
import { useCallback } from "react";
import { useIsRestockRelease } from "../../../hooks/useIsRestockRelease";
import { useIsWarehouseRelease } from "../../../hooks/useIsWarehouseRelease";
import { useRelease } from "../../../providers/ReleaseProvider";

export const useReleaseItemsValidators = () => {
  const { showWarning } = useSpreadSheetWarning();
  const { getInventoryItem, tableHasColumn } = useTableHelpers();
  const { isRestockRelease } = useIsRestockRelease();
  const { isWarehouseRelease } = useIsWarehouseRelease();
  const { spreadsheetData, vendorOptions } = useColumnMapper();
  const { warehouses } = useWarehouses();
  const { release } = useRelease();
  const { supplierOptions } = useSupplierOptions();

  const validateWarehouseQuantity = useCallback(
    (type?: SpreadsheetSaveType) => {
      if (
        type === SpreadsheetSaveType.Toggle ||
        type === SpreadsheetSaveType.SaveButton ||
        !tableHasColumn(COLUMN_TYPE.Quantity) ||
        isRestockRelease ||
        !isWarehouseRelease ||
        release?.status !== ReleaseStatus.Requested
      ) {
        return true;
      }

      const filteredRows = spreadsheetData.filter((row) => !rowIsEmpty(row));

      if (filteredRows.length === 0) {
        return true;
      }

      return filteredRows.every((row, rowIndex) => {
        const value = Number(row[COLUMN_TYPE.Quantity]);
        const supplier = row[COLUMN_TYPE.Supplier];
        const warehouseId = warehouses.find(
          (warehouse) => warehouse.name === supplier,
        )?.id;
        const hasVendor = vendorOptions.some((option) => option === supplier);

        if (hasVendor) {
          return true;
        }

        const inventoryItem = getInventoryItem({
          material: row[COLUMN_TYPE.Material],
          uom: row[COLUMN_TYPE.UOM],
          supplier,
        });

        if (!inventoryItem) {
          showWarning(`VALIDATION_ERROR_SHEETS_ITEM_NOT_FOUND_IN_INVENTORY`, {
            row: rowIndex + 1,
          });
          return false;
        }

        const remainingQuantity = inventoryItem.state
          .filter(
            (state) =>
              state.warehouse.id === warehouseId ||
              state.warehouse.id === release?.sourceWarehouse?.id,
          )
          ?.reduce(
            (acc, state) => acc.plus(state.remainingQuantity),
            new DecimalSafe(0),
          );

        if (remainingQuantity.lessThan(value)) {
          showWarning(`VALIDATION_ERROR_SHEETS_INVALID_WAREHOUSE_QUANTITY`, {
            row: rowIndex + 1,
            quantity: remainingQuantity.toString(),
          });
          return false;
        }
        return true;
      });
    },
    [
      getInventoryItem,
      tableHasColumn,
      isRestockRelease,
      showWarning,
      spreadsheetData,
      warehouses,
      release?.sourceWarehouse?.id,
      release?.status,
      isWarehouseRelease,
      vendorOptions,
    ],
  );

  const validateAtLeastOneItemMatchesReleaseSupplier = useCallback(
    (type?: SpreadsheetSaveType) => {
      if (
        type === SpreadsheetSaveType.Toggle ||
        type === SpreadsheetSaveType.SaveButton ||
        !tableHasColumn(COLUMN_TYPE.Supplier)
      ) {
        return true;
      }

      const vendorName = supplierOptions.find(
        (option) => option.value === release?.sellerOrgLocation?.id,
      )?.name;

      const warehouseName = release?.sourceWarehouse?.name;

      const filteredRows = spreadsheetData.filter((row) => !rowIsEmpty(row));
      if (filteredRows.length === 0) {
        return true;
      }

      const hasMatch = filteredRows.some((row) => {
        const supplier = row[COLUMN_TYPE.Supplier];
        return (
          !supplier ||
          warehouseName === supplier ||
          vendorName?.includes(supplier)
        );
      });
      if (!hasMatch) {
        showWarning(`VALIDATION_ERROR_SHEETS_NO_MATCHING_SUPPLIER`, { row: 1 });
        return false;
      }
      return true;
    },
    [
      release?.sellerOrgLocation,
      release?.sourceWarehouse?.name,
      supplierOptions,
      spreadsheetData,
      showWarning,
      tableHasColumn,
    ],
  );

  return {
    validateAtLeastOneItemMatchesReleaseSupplier,
    validateWarehouseQuantity,
  };
};

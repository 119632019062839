import { useExportToCsv } from "@/common/components/csv-export/hooks/useExportToCsv";
import { useExportToCsvReleaseData } from "@/common/components/csv-export/hooks/useExportToCsvReleaseData";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useSorting } from "@/common/stores/hooks/useSorting";
import { ObjectType } from "@/common/stores/useSortingStore";
import {
  QueryReleasesSortBy,
  ReleasePartialFieldsFragment,
  ReleaseToCsvExportFieldsFragment,
  useReleasesToCsvExportLazyQuery,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useDeliveries } from "../providers/DeliveriesProvider";
import { useReleaseSequence } from "../providers/ReleaseSequenceProvider";

export const RELEASES_TO_CSV_EXPORT_LIMIT = 15000;

export const useReleasesToCsvExport = () => {
  const intl = useIntl();
  const { cancelDialog } = useDialog();
  const { setSystemAlert } = useSnackbar();
  const { setError } = useGlobalError();
  const { filter } = useDeliveries();
  const { sortEntity } = useSorting(ObjectType.Release);
  const { selectedReleases, allReleasesSelected, clearAll } =
    useReleaseSequence();
  const { getReleaseExportToCsvData } = useExportToCsvReleaseData();
  const { exportToCsv } = useExportToCsv();

  const [fetchReleases, { loading }] = useReleasesToCsvExportLazyQuery();
  const fetchReleasesForCsvExport = useCallback(async (): Promise<
    ReleaseToCsvExportFieldsFragment[]
  > => {
    try {
      const { data, error } = await fetchReleases({
        variables: {
          first: RELEASES_TO_CSV_EXPORT_LIMIT,
          filter,
          sort: sortEntity
            ? {
                by: sortEntity.field as QueryReleasesSortBy,
                descending: sortEntity.descending,
              }
            : undefined,
        },
      });
      if (error) {
        setError(error);
      }

      return data?.releases?.edges?.map((edge) => edge.node) ?? [];
    } catch (error) {
      setError(error);
      return [];
    }
  }, [filter, sortEntity, fetchReleases, setError]);

  const exportReleasesToCsv = useCallback(
    async (openLoaderDialog: () => void) => {
      let releasesToExport = selectedReleases as (
        | ReleaseToCsvExportFieldsFragment
        | ReleasePartialFieldsFragment
      )[];

      if (allReleasesSelected) {
        openLoaderDialog();
        releasesToExport = await fetchReleasesForCsvExport();
        cancelDialog?.();
        clearAll();
      }

      const { data, fileName, headers } =
        getReleaseExportToCsvData(releasesToExport);

      exportToCsv({
        data,
        fileName,
        headers,
      });

      setSystemAlert(
        intl.$t(
          { id: "RELEASES_EXPORT_CSV_SUCCESS" },
          { count: releasesToExport.length },
        ),
        { type: SystemAlertType.MANUAL },
      );
    },
    [
      selectedReleases,
      allReleasesSelected,
      fetchReleasesForCsvExport,
      getReleaseExportToCsvData,
      exportToCsv,
      setSystemAlert,
      cancelDialog,
      clearAll,
      intl,
    ],
  );

  return {
    fetchingReleasesForCsvExport: loading,
    exportReleasesToCsv,
  };
};

import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { useTaxCodeSummaries } from "@/common/components/sales-tax-input/hooks/useTaxCodeSummaries";
import { getAssetType } from "@/common/components/upload-asset/getAssetType";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useUsers } from "@/common/hooks/useUsers";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { hasProperty } from "@/common/utils/objectUtils";
import { generateUUID } from "@/common/utils/uuidUtils";
import { useComplianceGroups } from "@/contractor/pages/admin/orders/pages/compliance-groups/hooks/useComplianceGroups";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import {
  AssetContext,
  DeliverySlipStatus,
  InvoiceStatus,
  InvoicedReleaseItemFieldsFragment,
  ReleaseDocument,
  ReleaseIssuesFieldsFragment,
  ReleaseItemFieldsFragment,
  ReleaseQuery,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
} from "@/generated/graphql";
import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import { useProjectTags } from "../../project/hooks/useProjectTags";
import { useProjectZones } from "../../project/hooks/useProjectZones";
import { useVendorPrice } from "../hooks/useVendorPrice";
import { useSiteContacts } from "../pages/specify-details/components/site-contact-picker/hooks/useSiteContacts";
import { getReleaseNetAmount } from "../store/releaseStoreUtils";

export const useReleaseCacheUpdate = () => {
  const client = useApolloClient();
  const { warehouses } = useWarehouses();
  const { getUomByName } = useUomOptions();
  const { getPrice } = useVendorPrice();
  const { manufacturers } = useManufacturers();
  const { getOrderType } = useOrderTypeOptions();
  const { vendors } = useVendors();
  const { siteContacts } = useSiteContacts();
  const { taxCodes } = useTaxCodeSummaries();
  const { complianceGroups } = useComplianceGroups();
  const { allTags } = useProjectTags();
  const { zones } = useProjectZones();
  const { releaseGroups } = useStartupDataStore(
    useShallow((state) => ({ releaseGroups: state.releaseGroups })),
  );
  const { users } = useUsers();

  const getVendorContacts = useCallback(
    (
      newVendorId?: string | null,
      existingVendorId?: string | null,
      vendorContactIds?: string[],
    ) =>
      vendors
        .find((v) =>
          newVendorId
            ? v.sellerOrgLocation.id === newVendorId || v.id === newVendorId
            : v.sellerOrgLocation.id === existingVendorId,
        )
        ?.contacts.filter((c) => vendorContactIds?.includes(c.id)) || [],
    [vendors],
  );

  const applyUpdatesToItem = useCallback(
    (
      item: ReleaseItemFieldsFragment,
      updatedItem: UpdateContractorReleaseItemInput,
      itemIssues: ReleaseIssuesFieldsFragment[],
      data: ReleaseQuery | null,
      changes:
        | React.MutableRefObject<
            Partial<UpdateContractorReleaseInput> | null | undefined | undefined
          >
        | undefined,
    ) => {
      let requestedUom = item.uom;
      if (updatedItem.uom) {
        const uom = getUomByName(updatedItem.uom);
        if (uom) {
          requestedUom = uom;
        } else {
          requestedUom = {
            id: generateUUID(),
            pluralDescription: updatedItem.uom,
            alternativeRefs: [],
          };
        }
      }
      const quantityDecimal =
        updatedItem.quantityDecimal ?? item.quantityDecimal;
      const prefilledPrice = getPrice(
        item.projectItem?.material.id || "",
        updatedItem.uom || requestedUom?.id || "",
        updatedItem.sellerOrgLocationId || data?.release?.sellerOrgLocation?.id,
        updatedItem.manufacturerId || item.manufacturer?.id || "",
      );
      const previousChanges = changes?.current?.updates?.find(
        (u) => u.releaseItemId === item.id,
      );
      const unitPrice = Object.hasOwn(updatedItem, "unitPrice")
        ? updatedItem.unitPrice
        : previousChanges && Object.hasOwn(previousChanges, "unitPrice")
          ? previousChanges?.unitPrice
          : item.unitPrice;
      const hasUnitPrice = !!unitPrice && unitPrice !== "0";
      const pricePrenegotiated =
        previousChanges?.pricePrenegotiated || item.pricePrenegotiated;
      if (!updatedItem.unitPrice) {
        if (prefilledPrice) {
          if (!hasUnitPrice || pricePrenegotiated) {
            updatedItem.unitPrice = prefilledPrice;
            updatedItem.pricePrenegotiated = true;
          }
        } else if (hasUnitPrice && pricePrenegotiated) {
          updatedItem.unitPrice = null;
          updatedItem.clearUnitPrice = true;
          updatedItem.pricePrenegotiated = false;
        }
      } else {
        updatedItem.pricePrenegotiated = false;
      }
      const itemVendor =
        vendors.find(
          (vendor) =>
            vendor.sellerOrgLocation.id ===
            (updatedItem.clearSellerOrgLocation
              ? null
              : (updatedItem.sellerOrgLocationId ?? item.sellerOrgLocation)),
        ) ?? null;

      const deliveryDate = updatedItem.clearDeliveryDate
        ? null
        : (updatedItem.deliveryDate ?? item.deliveryDate);
      return {
        ...item,
        issues: itemIssues,
        buyoutItem: item.buyoutItem ?? null,
        manufacturer: updatedItem.manufacturerId
          ? manufacturers.find((m) => m.id === updatedItem.manufacturerId)
          : item.manufacturer,
        requestedQuantity: quantityDecimal,
        quantityDecimal,
        receivedQuantityDecimal:
          updatedItem.receivedQuantityDecimal ?? item.receivedQuantityDecimal,
        requestedUom,
        instructions: {
          ...item.instructions,
          __typename: "Instruction" as const,
          text: updatedItem.instructions?.text ?? item.instructions?.text ?? "",
          assets:
            updatedItem.instructions?.assetUrls?.map((url) => ({
              id: generateUUID(),
              url,
              __typename: "Asset" as const,
              type: getAssetType(url),
              context: AssetContext.Instruction,
              pages: null,
              thumbnailUrl: url,
              createdAt: 0,
              isPrivate: false,
            })) ??
            item.instructions?.assets ??
            [],
        },
        uom: requestedUom,
        tags: updatedItem.tags
          ? (allTags || []).filter((t) => updatedItem.tags?.includes(t.id))
          : item.tags,
        zone: zones.find((z) => z.id === updatedItem.zoneId) || item.zone,
        pricePrenegotiated: !!updatedItem.pricePrenegotiated,
        priceEstimated:
          item.priceEstimated && item.unitPrice === updatedItem.unitPrice,
        unitPrice:
          updatedItem.unitPrice === undefined
            ? item.unitPrice
            : updatedItem.unitPrice,
        clearUnitPrice: updatedItem.clearUnitPrice,
        sellerOrgLocation: itemVendor ? itemVendor.sellerOrgLocation : null,
        deliveryDate,
        clearDeliveryDate: updatedItem.clearDeliveryDate,
      };
    },
    [vendors, manufacturers, allTags, zones, getUomByName, getPrice],
  );

  return (
    input?: UpdateContractorReleaseInput,
    changes?: React.MutableRefObject<
      Partial<UpdateContractorReleaseInput> | null | undefined | undefined
    >,
    {
      invoiceId,
      invoiceItems,
      invoiceStatuses,
      removedInvoiceItems,
    }: {
      invoiceId?: string;
      invoiceItems?: (InvoicedReleaseItemFieldsFragment & {
        releaseItemId: string;
      })[];
      invoiceStatuses?: InvoiceStatus[];
      removedInvoiceItems?: string[];
    } = {},
  ) => {
    const newVendor =
      vendors.find(
        (v) => v.sellerOrgLocation.id === input?.sellerOrgLocationId,
      ) || null;
    const preferredVendor = newVendor
      ? {
          id: newVendor.id,
          contacts: newVendor.contacts,
          externalCode: newVendor.externalCode,
          taxExempt: newVendor.taxExempt,
        }
      : null;

    const taxCode = taxCodes.find((tc) => tc.id === input?.taxCodeId);
    const siteContact = input?.siteContactId
      ? siteContacts.find((sc) => sc.id === input?.siteContactId) || {
          id: input?.siteContactId,
          name: "",
          phone: "",
          __typename: "SiteContact",
        }
      : null;
    const sourceWarehouse = hasProperty(input, "sourceWarehouseID")
      ? warehouses.find((w) => w.id === input?.sourceWarehouseID) || null
      : null;
    const inputIssues =
      input?.issues?.map((issue) => ({
        description: issue.description || "",
        issueType: issue.issueType || "",
        photos: [],
        quantityDecimal: issue.quantityDecimal || "0",
        id: generateUUID(),
        releaseItem: {
          __typename: "ReleaseItem" as const,
          id: issue.releaseItemId,
        },
        __typename: "ReleaseIssue" as const,
        resolution: null,
      })) || [];
    client.cache.updateQuery(
      {
        query: ReleaseDocument,
        variables: {
          id: input?.releaseId,
          input,
          invoiceId,
          invoiceStatuses,
        },
      },
      // eslint-disable-next-line sonarjs/cognitive-complexity
      (data: ReleaseQuery | null) => {
        if (data?.release) {
          const vendorContacts = input?.vendorContactIds
            ? getVendorContacts(
                input.sellerOrgLocationId,
                data.release?.sellerOrgLocation?.id,
                input.vendorContactIds,
              )
            : [];
          const issues = data.release.issues
            .filter(
              (issue) =>
                !input?.issues?.find(
                  (inputIssue) =>
                    inputIssue.releaseItemId === issue.releaseItem.id,
                ),
            )
            .concat(inputIssues);

          const result: ReleaseQuery = {
            ...data,
            release: {
              ...data.release,
              description: input?.description || data.release.description,
              poNumber: input?.poNumber ?? data.release.poNumber,
              requiresInventoryReceipt: hasProperty(
                input,
                "requiresInventoryReceipt",
              )
                ? input?.requiresInventoryReceipt
                : data?.release?.requiresInventoryReceipt,
              watchers: input?.watcherIds
                ? users?.filter((user) =>
                    input.watcherIds?.includes(user.id),
                  ) || []
                : data.release.watchers,
              groups: input?.groupIds
                ? releaseGroups.filter((group) =>
                    input.groupIds?.includes(group.id),
                  )
                : data.release.groups,
              vendorContacts: input?.vendorContactIds
                ? vendorContacts
                : data.release.vendorContacts,
              timeTBD: input?.timeTBD ?? data.release.timeTBD,
              includeServices: input?.includeServices
                ? input?.includeServices
                    ?.filter((i) => !i.remove)
                    .map((i) => i.type)
                    .concat(
                      data.release.includeServices.filter(
                        (i) =>
                          !input?.includeServices
                            ?.filter((is) => is.remove)
                            .map((i) => i.type)
                            .includes(i),
                      ),
                    )
                : data?.release?.includeServices,
              deliverySlips: [
                ...data?.release?.deliverySlips,
                ...(input?.deliverySlipUrls || [])
                  .filter((url) =>
                    data?.release?.deliverySlips.every(
                      (ds) => ds.asset?.url !== url,
                    ),
                  )
                  .map((url) => ({
                    __typename: "DeliverySlip" as const,
                    id: generateUUID(),
                    status: DeliverySlipStatus.Pending,
                    createdAt: new Date().getTime(),
                    createdBy: {
                      id: "",
                      firstName: "",
                      lastName: "",
                    },
                    reviewedAt: null,
                    asset: {
                      id: generateUUID(),
                      url,
                      __typename: "Asset" as const,
                      type: getAssetType(url),
                      context: AssetContext.DeliverySlip,
                      pages: null,
                      thumbnailUrl: url,
                      createdAt: 0,
                      isPrivate: false,
                    },
                  })),
              ],
              type: input?.typeId
                ? getOrderType(input?.typeId) || data.release.type
                : data.release.type,
              time: input?.timeTBD
                ? null
                : input?.requestedTime || data.release.time,
              warehouse: input?.warehouseId
                ? warehouses.find((w) => w.id === input?.warehouseId)
                : input?.clearWarehouse
                  ? null
                  : data.release.warehouse,
              taxRate: hasProperty(input, "taxRate")
                ? input?.taxRate
                : data?.release?.taxRate,
              taxVariance: input?.clearTaxVariance
                ? undefined
                : hasProperty(input, "taxVariance")
                  ? input?.taxVariance
                  : data?.release?.taxVariance,
              customTaxAmount: input?.clearCustomTaxAmount
                ? null
                : hasProperty(input, "customTaxAmount")
                  ? input?.customTaxAmount
                  : data?.release?.customTaxAmount,
              taxCode: input?.clearTaxCode
                ? null
                : taxCode || data.release.taxCode,
              taxType: input?.taxType || data.release.taxType,
              preferredVendor:
                (input?.sellerOrgLocationId && newVendor) || sourceWarehouse
                  ? preferredVendor
                  : data.release.preferredVendor,
              sellerOrgLocation:
                (input?.sellerOrgLocationId && newVendor?.sellerOrgLocation) ||
                sourceWarehouse
                  ? newVendor?.sellerOrgLocation || null
                  : data.release.sellerOrgLocation,
              sourceWarehouse:
                sourceWarehouse || newVendor
                  ? sourceWarehouse
                  : data.release.sourceWarehouse,
              siteContact: hasProperty(input, "siteContactId")
                ? siteContact
                : data.release.siteContact,
              complianceGroup: input?.complianceGroupId
                ? complianceGroups.find(
                    (cg) => cg.id === input?.complianceGroupId,
                  )
                : data.release.complianceGroup,
              noteDocument: input?.clearNoteDocument
                ? null
                : data.release.noteDocument,
              quoteDocument: input?.clearQuoteDocument
                ? null
                : data.release.quoteDocument,
              items: data?.release?.items
                .map((item) => {
                  const updatedItem = input?.updates?.find(
                    (i) => i.releaseItemId === item.id,
                  );
                  const deletedItem = input?.removedItems?.find(
                    (i) => i === item.id,
                  );

                  const itemIssues = issues.filter(
                    (issue) => issue.releaseItem.id === item.id,
                  );
                  if (deletedItem) {
                    return null;
                  }
                  if (updatedItem) {
                    return applyUpdatesToItem(
                      item,
                      updatedItem,
                      itemIssues,
                      data,
                      changes,
                    );
                  }
                  return {
                    ...item,
                    issues: itemIssues,
                    invoiceItems: (
                      item.invoiceItems?.filter(
                        (i) =>
                          !removedInvoiceItems?.includes(i.id) &&
                          !invoiceItems?.some((it) => it.id === i.id) &&
                          item.invoiceItems?.some((i) => i.id !== item.id),
                      ) || []
                    ).concat(
                      invoiceItems?.filter(
                        (i) => i.releaseItemId === item.id,
                      ) || [],
                    ),
                  };
                })
                .filter((i) => !!i),
            },
          };
          const netAmount = getReleaseNetAmount(result.release?.items);
          const taxableNetAmount = getReleaseNetAmount(result.release?.items, {
            taxable: true,
          });
          const charges = (
            input?.additionalCharges ||
            result.release?.additionalCharges ||
            []
          ).reduce(
            (acc, charge) =>
              Number(
                new DecimalSafe(acc).add(new DecimalSafe(charge.amount || 0)),
              ),
            0,
          );
          const taxRate = result.release?.taxCode
            ? result.release.taxCode.rate
            : result.release?.taxRate;
          const taxRateAmount = new DecimalSafe(taxableNetAmount)
            .add(charges)
            .mul(taxRate ?? 0);

          return {
            ...result,
            release: {
              ...result.release,
              additionalCharges:
                (
                  input?.additionalCharges ?? result.release?.additionalCharges
                )?.map((charge) => ({
                  ...charge,
                  id: charge.id ?? generateUUID(),
                  __typename: "Charge" as const,
                })) || [],
              taxAmount:
                result.release?.customTaxAmount ||
                taxRateAmount
                  ?.plus(result.release?.taxVariance || "0")
                  .toString(),
              netAmount: netAmount.toString(),
              taxableNetAmount: taxableNetAmount.toString(),
              taxUnadjustedAmount: taxRateAmount.toString(),
              total: new DecimalSafe(netAmount)
                .add(charges)
                .add(
                  input?.customTaxAmount ||
                    result.release?.customTaxAmount ||
                    taxRateAmount ||
                    0,
                )
                .add(result.release?.taxVariance || 0)
                .toString(),
            },
          } as ReleaseQuery;
        }
        return data;
      },
    );
  };
};

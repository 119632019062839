import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { InventoryItemPendingFlowFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { TransactionHistoryItemName } from "../../../transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";

type Props = {
  item: InventoryItemPendingFlowFieldsFragment;
};

export const ScheduledFlowExtPrice = ({ item }: Props) => {
  const { calcExtPrice } = usePriceCalculation();
  const extPrice = useMemo(
    () => calcExtPrice(item?.receivedQuantityDecimal, item?.unitPrice),
    [calcExtPrice, item?.receivedQuantityDecimal, item?.unitPrice],
  );

  return (
    <NotNullableRenderer value={item}>
      <TransactionHistoryItemName>
        <Price price={extPrice} />
      </TransactionHistoryItemName>
    </NotNullableRenderer>
  );
};

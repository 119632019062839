import {
  AssetContext,
  NoteDocumentSummaryFieldsFragment,
} from "@/generated/graphql";
import { CircularProgress } from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExpandablePanel } from "../expandable-panel/ExpandablePanel";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";
import { SupportedFormats, UploadAsset } from "../upload-asset/UploadAsset";
import { UploadAssetProvider } from "../upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "../upload/FileUploadArea";

type Props = {
  noteDocument?: NoteDocumentSummaryFieldsFragment | null;
  readonly?: boolean;
  customRedirect?: () => void;
  children?: React.ReactNode;
  onNoteDelete?: () => void;
  noteDeleting?: boolean;
};

const Container = tw.div`flex items-center gap-2`;

const NoteDocumentPanelWithProvider: FC<Props> = ({
  readonly,
  noteDocument,
  customRedirect,
  children,
  onNoteDelete,
  noteDeleting = false,
}) => {
  const intl = useIntl();

  if (readonly && !noteDocument?.asset && !children) {
    return null;
  }

  return (
    <ExpandablePanel
      header={intl.$t({ id: "NOTE_DOCUMENT_PANEL_TITLE" })}
      isOpened={true}
    >
      <Container>
        <If isTrue={children}>{children}</If>
        <If isTrue={!children}>
          <UploadAsset
            customRedirect={customRedirect}
            accept={{
              [PDF_MIME_TYPE]: PDF_EXTENSIONS,
              [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
            }}
            limit={1}
            xs
            mode="horizontal"
            className="flex w-auto items-start"
            readonly={readonly}
          >
            <If isTrue={!noteDocument?.asset}>
              <SupportedFormats>
                <FormattedMessage id="NOTE_DOCUMENT_SUPPORTED_FORMATS" />
              </SupportedFormats>
            </If>
          </UploadAsset>
          <If isTrue={onNoteDelete}>
            {noteDeleting ? (
              <CircularProgress
                size="small"
                className="ml-2 h-5 w-5 text-blue-500"
              />
            ) : (
              <LinkLike
                className="font-normal text-blue-500 hover:text-blue-300"
                onClick={onNoteDelete}
              >
                {intl.$t({ id: "DELETE" })}
              </LinkLike>
            )}
          </If>
        </If>
      </Container>
    </ExpandablePanel>
  );
};

export const NoteDocumentPanel: FC<Props> = (props) => {
  return (
    <UploadAssetProvider
      initialAssets={props.noteDocument ? [props.noteDocument.asset] : []}
      context={AssetContext.NoteDocument}
    >
      <NoteDocumentPanelWithProvider {...props} />
    </UploadAssetProvider>
  );
};

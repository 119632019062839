import { BuyoutAdditionalChargesAndTaxes } from "@/common/components/buyout-additional-chatrges-and-taxes/BuyoutAdditionalChargesAndTaxes";
import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { Loader } from "@/common/components/loader/Loader";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { ColumnMapperProvider } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { EstimatedPricesProvider } from "@/common/providers/EstimatedPricesProvider";
import { isNonQuotedBuyout } from "@/common/utils/isNonQuotedBuyout";
import { routes } from "@/config/routes";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import {
  BuyoutFieldsFragment,
  BuyoutItemFieldsFragment,
  BuyoutStatus,
  InstructionInput,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useSetCurrentProjectId } from "../../../project/hooks/useSetCurrentProjectId";
import { useContractorBuyout } from "../../providers/ContractorBuyoutProvider";
import { getBuyoutTotal } from "../../store/buyoutStoreUtils";
import { useBuyoutStore } from "../../store/useBuyoutStore";
import { VendorBuyoutInlineNotesAndTermsPanel } from "../common/notes-and-attachments/VendorBuyoutInlineNotesAndTermsPanel";
import { useBuyoutItemsListConfiguration } from "../common/table-configurations/BuyoutListItemsConfiguration";
import { BuyoutActions } from "./components/BuyoutActions";
import { BuyoutAddItems } from "./components/BuyoutAddItems";
import { BuyoutDescription } from "./components/BuyoutDescription";
import { BuyoutHeader } from "./header/BuyoutHeader";
import { BuyoutGroupedProvider } from "./providers/BuyoutGroupedProvider";
import {
  SyncBuyoutItemsProvider,
  useSyncBuyoutItems,
} from "./providers/SyncBuyoutItemsProvider";

const Container = tw.div`pb-15`;

const BuyoutEditableDocumentWithProviders: FC<{
  buyout: BuyoutFieldsFragment;
  loading: boolean;
}> = ({ buyout }) => {
  const spreadsheetConfiguration = useBuyoutItemsListConfiguration();
  const { saving } = useSyncBuyoutItems();
  const { setGlobalVendorId } = useVendorPrices();
  const { updateBuyout, setHasChanges } = useContractorBuyout();
  const isNonQuoted = useMemo(() => isNonQuotedBuyout(buyout), [buyout]);

  const { buyout: storeBuyout, updateStoreItems } = useBuyoutStore(
    useShallow((state) => ({
      buyout: state.buyout,
      updateStoreItems: state.updateStoreItems,
    })),
  );

  const onSpreadSheetChange = useCallback(
    (data: Record<string, string>[]) => {
      setHasChanges(true);
      if (!storeBuyout) {
        return;
      }
      updateStoreItems(
        storeBuyout.items
          ?.filter((item) => data.find((row) => row.id === item.id))
          .map((item) => {
            const updatedItem = data.find((i) => i.id === item.id);
            if (!updatedItem) {
              return item;
            }
            return {
              ...item,
              description: updatedItem[COLUMN_TYPE.Material],
              unitPrice: updatedItem[COLUMN_TYPE.PrefilledPrice],
              quantityDecimal: updatedItem[COLUMN_TYPE.Quantity],
              projectItem: {
                ...item.projectItem,
                estimateUom: {
                  ...item.projectItem?.estimateUom,
                  pluralDescription: updatedItem[COLUMN_TYPE.UOM],
                },
              },
            };
          })
          .concat(
            data
              .filter((row) => !row.id && !rowIsEmpty(row))
              .map((item, index) => {
                return {
                  id: index.toString(),
                  description: item[COLUMN_TYPE.Material],
                  unitPrice: item[COLUMN_TYPE.PrefilledPrice],
                  quantityDecimal: item[COLUMN_TYPE.Quantity],
                  projectItem: {
                    estimateUom: {
                      pluralDescription: item[COLUMN_TYPE.UOM],
                    },
                  },
                } as BuyoutItemFieldsFragment;
              }),
          ),
      );
    },
    [storeBuyout, setHasChanges, updateStoreItems],
  );

  const total = useMemo(() => {
    if (storeBuyout) {
      return getBuyoutTotal(storeBuyout).toString();
    }
    return "0";
  }, [storeBuyout]);

  useEffect(() => {
    if (setGlobalVendorId) {
      setGlobalVendorId(buyout?.sellerOrgLocation?.id);
    }
  }, [buyout?.sellerOrgLocation?.id, setGlobalVendorId]);

  const saveInstructions = useCallback(
    async (instructions: InstructionInput) => {
      if (!buyout) {
        return;
      }
      await updateBuyout({
        instructions,
      });
    },
    [buyout, updateBuyout],
  );

  const saveDescription = useCallback(
    async (description: string) => {
      if (!buyout) {
        return;
      }
      await updateBuyout({
        buyoutId: buyout.id,
        description,
        version: buyout.version,
      });
    },
    [buyout, updateBuyout],
  );

  return (
    <Container>
      <BuyoutHeader buyout={buyout} isNonQuoted={isNonQuoted} />
      <SpreadSheetTable
        items={buyout.items}
        columns={spreadsheetConfiguration}
        saving={saving}
        height="calc(100vh - 450px)"
        onChanges={onSpreadSheetChange}
        readOnly={!isNonQuoted}
      />

      <If isTrue={isNonQuoted}>
        <Instructions
          instruction={buyout.instructions}
          projectId={buyout.project?.id}
          saveInstruction={saveInstructions}
        >
          <BuyoutDescription
            buyout={buyout}
            saveDescription={saveDescription}
          />
        </Instructions>
      </If>
      <VendorBuyoutInlineNotesAndTermsPanel readonly={!isNonQuoted} />
      <QuoteDocumentPanel
        quoteDocument={buyout.quoteDocument}
        quote={buyout.quote}
      />
      <BuyoutAdditionalChargesAndTaxes
        buyout={storeBuyout}
        netAmount={storeBuyout?.netAmount}
        total={total}
        updateBuyout={updateBuyout}
        includePaymentTerms
        readonlyPaymentTerms
        includeAdditionalChargesAllowance
      />
      <BuyoutActions isNonQuoted={isNonQuoted} isEditable />
      <If isTrue={isNonQuoted}>
        <BuyoutAddItems />
      </If>
    </Container>
  );
};

export const BuyoutEditableDocument = () => {
  const { buyout, loading } = useContractorBuyout();
  const spreadsheetConfiguration = useBuyoutItemsListConfiguration();
  const navigate = useNavigate();
  useSetCurrentProjectId(buyout?.project?.id);

  useEffect(() => {
    if (!buyout) {
      return;
    }
    if (buyout?.status === BuyoutStatus.Cancelled) {
      navigate(generatePath(routes.buyout, { id: buyout.id }), {
        replace: true,
      });
      return;
    }
    if (buyout?.status === BuyoutStatus.Draft && buyout.quoteDocument?.id) {
      navigate(
        generatePath(routes.editBuyoutFromQuote, {
          quoteDocumentId: buyout.quoteDocument.id,
          buyoutId: buyout.id,
        }),
        { replace: true },
      );
    }
    if (buyout?.status !== BuyoutStatus.Draft && buyout.quote) {
      navigate(generatePath(routes.buyout, { id: buyout.id }), {
        replace: true,
      });
    }
  }, [buyout, navigate]);

  if (!buyout) {
    return <Loader loading />;
  }

  return (
    <EstimatedPricesProvider projectId={buyout?.project?.id}>
      <ColumnMapperProvider config={spreadsheetConfiguration}>
        <BuyoutGroupedProvider buyout={buyout}>
          <SyncBuyoutItemsProvider>
            <BuyoutEditableDocumentWithProviders
              buyout={buyout}
              loading={loading}
            />
          </SyncBuyoutItemsProvider>
        </BuyoutGroupedProvider>
      </ColumnMapperProvider>
    </EstimatedPricesProvider>
  );
};

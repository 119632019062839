import { Card } from "@/common/layout/ResponsiveClasses";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { FC, PropsWithChildren, useState } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

const Container = tw(Card)`
  flex flex-col w-full lg:border rounded overflow-hidden mt-1 mb-1 bg-white border-gray-200 shadow-sm lg:shadow-none
`;

const Header = tw.div`  
  grid z-10 bg-blue-100 px-4 py-2 grid-flow-col text-sm justify-start gap-3 border-b font-medium border-gray-200 cursor-pointer items-center
`;

const Content = tw.div`
  grid z-0 gap-3 overflow-hidden items-center transition mx-3
  ${({ $expanded }: { $expanded: boolean }) =>
    $expanded
      ? "animate-[expand_0.2s_ease-in-out_forwards] h-fit my-3"
      : "animate-[collapse_0.2s_ease-in-out_forwards] my-0"}
`;

export type ExpandablePanelProps = PropsWithChildren & {
  header: string | React.ReactElement;
  isOpened?: boolean;
  classes?: {
    container?: string;
    header?: string;
    content?: string;
  };
  hideHeader?: boolean;
};

export const ExpandablePanel: FC<ExpandablePanelProps> = ({
  header,
  children,
  isOpened = true,
  classes,
  hideHeader,
}) => {
  const [expanded, setExpanded] = useState(isOpened);

  return (
    <Container className={classes?.container}>
      <If isTrue={!hideHeader}>
        <Header
          onClick={() => setExpanded(!expanded)}
          className={classes?.header}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
          {header}
        </Header>
      </If>
      <Content $expanded={expanded}>{children}</Content>
    </Container>
  );
};

import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import {
  MultipleSelectionProps,
  SearchableList,
} from "@/common/components/searchable-list/SearchableList";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { useSourceSystemConfig } from "@/common/hooks/integrations/source-system-config/useSourceSystemConfig";
import {
  ExternalVendorFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import { FC, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useAgaveExternalVendors } from "./hooks/useAgaveExternalVendors";
import { useExternalVendorsTableConfiguration } from "./ImportExternalVendorsTable.configuration";

type Props = {
  sourceSystem: SourceSystem | null;
  title: string;
  selectedVendors?: ExternalVendorFieldsFragment[];
} & Pick<MultipleSelectionProps, "selectedItemIds"> & {
    setSelectedItems: (items: ExternalVendorFieldsFragment[]) => void;
  };

const ExternalVendorsTableWithProviders: FC<Props> = ({
  title,
  selectedVendors,
  sourceSystem,
  selectedItemIds,
  setSelectedItems,
}) => {
  const { getSourceSystemConfig } = useSourceSystemConfig();
  const { externalVendors, loading, totalCount, filter, setFilter } =
    useAgaveExternalVendors(sourceSystem);

  const configuration = useExternalVendorsTableConfiguration(selectedItemIds);

  useEffect(() => {
    const { activeStatuses, inactiveStatuses } =
      getSourceSystemConfig(sourceSystem);

    if (!activeStatuses || !inactiveStatuses) {
      setFilter({ search: "" });
      return;
    }
    setFilter({ search: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceSystem, getSourceSystemConfig]);

  const selectVendors = useCallback(
    (ids: string[]) => {
      const itemsFromOtherPages =
        selectedVendors?.filter(
          (v) => !externalVendors.map((v) => v.id).includes(v.externalId),
        ) || [];

      const extVendors = [
        ...externalVendors.filter((v) => ids.includes(v.id)),
        ...(itemsFromOtherPages.map((p) => ({ ...p, id: p.externalId })) || []),
      ];

      setSelectedItems(extVendors);
    },
    [externalVendors, setSelectedItems, selectedVendors],
  );

  const selectAllOnCurrentPage = useCallback(
    () => [selectVendors(externalVendors.map((v) => v.externalId))],
    [externalVendors, selectVendors],
  );

  return (
    <SearchableList
      tableConfiguration={configuration}
      items={externalVendors}
      loadingItems={loading}
      totalCount={totalCount}
      searchBarTitle={title}
      headerAdditionalFilters={
        <OutlinedButton onClick={selectAllOnCurrentPage}>
          <FormattedMessage id="SELECT_ALL_ON_CURRENT_PAGE" />
        </OutlinedButton>
      }
      itemSelection={{
        type: "multiple",
        selectedItemIds,
        setSelectedItemIds: selectVendors,
      }}
      search={{
        searchText: filter?.search ?? "",
        setSearchText: (search) => setFilter({ ...filter, search }),
      }}
    />
  );
};

export const ImportExternalVendorsTable: FC<Props> = (props) => {
  return (
    <PaginationProvider
      itemsPerPage={DEFAULT_ITEMS_PER_PAGE}
      useQueryString={false}
    >
      <ExternalVendorsTableWithProviders {...props} />
    </PaginationProvider>
  );
};

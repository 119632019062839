import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFormattedMaterialName } from "@/common/components/spreadsheet-table/hooks/useFormattedMaterialName";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getMaterialNameParts } from "@/common/components/spreadsheet-table/utils/getMaterialNameParts";
import { getPhysicalColumnIndex } from "@/common/components/spreadsheet-table/utils/getPhysicalColumnIndex";
import { setTableCells } from "@/common/components/spreadsheet-table/utils/setTableCells";
import { ALL_WAREHOUSES_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { getInventoryItemUnitPrice } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemUnitPrice";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useInventoryFiltersStore } from "@/contractor/pages/home/inventory-management/pages/current-stock/components/add-inventory-items/store/useInventoryFiltersStore";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import { useSwitchInventoryStore } from "../store/useSwitchInventoryStore";

export const useSwitchInventoryItems = () => {
  const { handsonInstance } = useColumnMapper();
  const { warehouses } = useWarehouses();
  const getFormattedMaterialName = useFormattedMaterialName();
  const { materialsMap } = useMaterials();
  const { formatCostCode } = useProjectCostCodes();
  const { currentWarehouseId: inventoryCurrentWarehouseId } =
    useInventoryItems();
  const {
    selectedItem,
    setSelectedItem,
    currentItem,
    setCurrentItem,
    selectedQuantity,
    currentRow,
    resetFilters: resetSwitchInventoryFilters,
    openInventoryModal,
    setOpenInventoryModal,
  } = useSwitchInventoryStore(
    useShallow((state) => ({
      selectedItem: state.selectedItem,
      setSelectedItem: state.setSelectedItem,
      currentItem: state.currentItem,
      setCurrentItem: state.setCurrentItem,
      selectedQuantity: state.selectedQuantity,
      currentRow: state.currentRow,
      resetFilters: state.resetFilters,
      openInventoryModal: state.openInventoryModal,
      setOpenInventoryModal: state.setOpenInventoryModal,
    })),
  );

  const {
    search,
    setSearch,
    selectedCostCodes,
    setSelectedCostCodes,
    showZeroQuantityInventoryItems,
    setShowZeroQuantityInventoryItems,
    filteredInventoryItems,
    currentWarehouseId,
    setCurrentWarehouseId,
    limitToCurrentWarehouse,
    setLimitToCurrentWarehouse,
    resetFilters: resetInventoryFilters,
  } = useInventoryFiltersStore(
    useShallow((state) => ({
      search: state.search,
      setSearch: state.setSearch,
      selectedCostCodes: state.selectedCostCodes,
      setSelectedCostCodes: state.setSelectedCostCodes,
      showZeroQuantityInventoryItems: state.showZeroQuantityInventoryItems,
      setShowZeroQuantityInventoryItems:
        state.setShowZeroQuantityInventoryItems,
      filteredInventoryItems: state.filteredInventoryItems,
      currentWarehouseId: state.currentWarehouseId,
      setCurrentWarehouseId: state.setCurrentWarehouseId,
      limitToCurrentWarehouse: state.limitToCurrentWarehouse,
      setLimitToCurrentWarehouse: state.setLimitToCurrentWarehouse,
      resetFilters: state.resetFilters,
    })),
  );

  const showItemAlternatives = useCallback(
    ({
      itemName,
      row,
      quantity,
      limitToCurrentWarehouse = false,
    }: {
      itemName: string;
      row: number;
      quantity: number;
      limitToCurrentWarehouse?: boolean;
    }) => {
      const { namePart } = getMaterialNameParts(itemName);
      setCurrentItem(itemName, row, quantity);
      const isInInventory = filteredInventoryItems.some((item) =>
        item.orgMaterial.material.name
          .toLowerCase()
          .includes(namePart.toLowerCase()),
      );
      setSearch(isInInventory ? namePart : "");
      setCurrentWarehouseId(ALL_WAREHOUSES_ID);
      setOpenInventoryModal(true);
      setLimitToCurrentWarehouse(limitToCurrentWarehouse);
    },
    [
      setOpenInventoryModal,
      setCurrentItem,
      setLimitToCurrentWarehouse,
      setSearch,
      setCurrentWarehouseId,
      filteredInventoryItems,
    ],
  );

  const resetFilters = useCallback(() => {
    resetSwitchInventoryFilters();
    resetInventoryFilters();
  }, [resetSwitchInventoryFilters, resetInventoryFilters]);

  const switchItem = useCallback(() => {
    const item = filteredInventoryItems.find(
      (item) => item.id === selectedItem,
    );

    if (!item) {
      return;
    }

    const warehouse = warehouses.find(
      (warehouse) => warehouse.id === item.warehouse,
    );
    const formattedCostCode = formatCostCode(item.orgMaterial.costCode?.id);
    const material = materialsMap.get(item.orgMaterial?.id ?? "");
    const price = getInventoryItemUnitPrice(item, -selectedQuantity);

    setTableCells(
      [
        [
          currentRow,
          getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.Material),
          getFormattedMaterialName(material ?? ""),
        ],
        [
          currentRow,
          getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.UOM),
          item?.uom.pluralDescription ?? "",
        ],
        [
          currentRow,
          getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.UnitPrice),
          price,
        ],
        [
          currentRow,
          getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.PrefilledPrice),
          price,
        ],
        [
          currentRow,
          getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.Supplier),
          warehouse?.id !== inventoryCurrentWarehouseId
            ? (warehouse?.name ?? "")
            : "",
        ],
        [
          currentRow,
          getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.CostCode),
          formattedCostCode ?? "",
        ],
      ],
      handsonInstance,
    );
  }, [
    selectedItem,
    handsonInstance,
    currentRow,
    filteredInventoryItems,
    warehouses,
    formatCostCode,
    getFormattedMaterialName,
    materialsMap,
    inventoryCurrentWarehouseId,
    selectedQuantity,
  ]);

  return {
    selectedItem,
    setSelectedItem,
    switchItem,
    resetFilters,
    search,
    setSearch,
    currentItem,
    setCurrentItem,
    currentRow,
    selectedCostCodes,
    setSelectedCostCodes,
    filteredInventoryItems,
    openInventoryModal,
    setOpenInventoryModal,
    currentWarehouseId,
    setCurrentWarehouseId,
    showZeroQuantityInventoryItems,
    setShowZeroQuantityInventoryItems,
    showItemAlternatives,
    limitToCurrentWarehouse,
  };
};

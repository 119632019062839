import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { usePaymentMethods } from "@/contractor/pages/admin/cost-structure/pages/payment-methods/hooks/usePaymentMethods";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InvoiceType } from "@/generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { PAID_VIA_INVOICE_ID } from "../useInvoiceDetailsHeader";
import { ReceiptPaymentMethodProps } from "./ReceiptPaymentMethod";

export const useReceiptPaymentMethod = ({
  receipt,
  onPaidToggle,
}: Pick<ReceiptPaymentMethodProps, "onPaidToggle" | "receipt">) => {
  const intl = useIntl();
  const { setWarningAlert } = useSnackbar();
  const { paymentMethods, loading: loadingPaymentMethods } =
    usePaymentMethods();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { connectedSourceSystem } = useOrgSettings();

  const [isPaid, setIsPaid] = useState(!receipt?.prePaid);

  const togglePaid = useCallback(
    (value: boolean) => {
      if (
        value === false &&
        receipt?.release &&
        receipt.release.invoices.filter(
          (invoice) => invoice.type !== InvoiceType.Receipt,
        ).length
      ) {
        setWarningAlert(intl.$t({ id: "RECEIPT_PAID_TOGGLE_WARNING" }));
        return;
      }
      setIsPaid(value);
      onPaidToggle(value);
    },
    [receipt?.release, intl, setWarningAlert, onPaidToggle],
  );

  const paymentMethodsOptions = useMemo(() => {
    return [
      {
        id: PAID_VIA_INVOICE_ID,
        label: intl.$t({ id: "WILL_BE_PAID_VIA_INVOICE" }),
      },
      ...paymentMethods.map((paymentMethod) => ({
        id: paymentMethod.id,
        label: paymentMethod.description,
      })),
    ];
  }, [paymentMethods, intl]);

  const includeToggle = useMemo(
    () =>
      paymentMethods.length === 0 ||
      !hasFeatureInConnectedSourceSystem(
        IntegrationFeature.IncludeReceiptPaymentMethods,
      ) ||
      !connectedSourceSystem,
    [paymentMethods, hasFeatureInConnectedSourceSystem, connectedSourceSystem],
  );

  return {
    isPaid,
    togglePaid,
    paymentMethodsOptions,
    includeToggle,
    loadingPaymentMethods,
  };
};

import { useGenerateReceiptNumber } from "@/common/hooks/useGenerateReceiptNumber";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useReceiptCreateReleaseHeader } from "@/contractor/pages/home/receipts/pages/receipt-record-order/stores/useReceiptCreateReleaseHeader";
import { ReceiptFieldsFragment } from "@/generated/graphql";
import { AutoFixNormalOutlined } from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import { LinkLike } from "../../link-like/LinkLike";
import { useVendors } from "../../vendors/hooks/useVendors";

interface ReceiptMagicWandProps {
  readonly: boolean;
  receipt: ReceiptFieldsFragment;
  onSave: (receiptNumber: string) => void;
  receiptNumber: string | undefined | null;
}

export const ReceiptMagicWand = ({
  readonly,
  onSave,
  receiptNumber,
}: ReceiptMagicWandProps) => {
  const { projects } = useProjectListOptions();
  const { vendors } = useVendors();
  const projectId = useReceiptCreateReleaseHeader((state) => state.projectId);
  const vendorId = useReceiptCreateReleaseHeader((state) => state.vendorId);
  const { generateReceiptNumber, loading } = useGenerateReceiptNumber();

  const selectedProject = useMemo(
    () => projects.find((project) => project.id === projectId),
    [projectId, projects],
  );

  const selectedVendor = useMemo(
    () => vendors.find((vendor) => vendor.sellerOrgLocation.id === vendorId),
    [vendorId, vendors],
  );

  const handleClick = useCallback(async () => {
    if (!selectedProject || !selectedVendor) {
      return;
    }
    const receiptNumber = await generateReceiptNumber(
      selectedProject.id,
      selectedVendor.sellerOrgLocation.id,
    );
    if (receiptNumber) {
      onSave(receiptNumber);
    }
  }, [generateReceiptNumber, onSave, selectedProject, selectedVendor]);

  if (readonly || !selectedProject || !selectedVendor || receiptNumber) {
    return null;
  }

  return (
    <LinkLike className="ml-auto mr-2" onClick={handleClick} disabled={loading}>
      <AutoFixNormalOutlined />
    </LinkLike>
  );
};

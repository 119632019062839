import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  ImportComplianceGroupsMutation,
  SourceSystem,
  useImportComplianceGroupsMutation,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { AnimatedLoading } from "../../../../../home/invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";
import { ImportExternalComplianceGroupsImportResults } from "../components/import-external-compliance-groups/steps/ImportExternalComplianceGroupsImportResults";
import { ImportExternalComplianceGroupsTable } from "../components/import-external-compliance-groups/steps/ImportExternalComplianceGroupsTable";

type ProviderContextType = {
  pages: WizardModalPage[];
  sourceSystem: SourceSystem | null;
  modalOpened: boolean;
  openModal: ({ sourceSystem }: { sourceSystem: SourceSystem }) => void;
  closeModal: () => void;

  selectedExternalComplianceGroups: string[];
  setSelectedExternalComplianceGroups: (id: string[]) => void;

  importAllExternalItems: boolean;
  setImportAllExternalItems: (value: boolean) => void;

  totalExternalComplianceGroupsCount: number;
  setTotalExternalComplianceGroupsCount: (count: number) => void;

  importedComplianceGroups:
    | ImportComplianceGroupsMutation["importComplianceGroups"]
    | null;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  sourceSystem: null,
  modalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,

  selectedExternalComplianceGroups: [],
  setSelectedExternalComplianceGroups: NoFunction,

  importAllExternalItems: false,
  setImportAllExternalItems: NoFunction,

  totalExternalComplianceGroupsCount: 0,
  setTotalExternalComplianceGroupsCount: NoFunction,

  importedComplianceGroups: null,
});

export const ImportExternalComplianceGroupsProvider: FC<{
  children: React.ReactNode;
  defaultSourceSystem?: SourceSystem;
}> = ({ children, defaultSourceSystem }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { moveToNextNestedStep, moveToPreviousNestedStep, setNestedStep } =
    useNestedStepper();
  const { complianceGroups, updateStartupData } = useStartupDataStore(
    useShallow((state) => ({
      complianceGroups: state.complianceGroups,
      updateStartupData: state.updateStartupData,
    })),
  );

  const [sourceSystem, setSourceSystem] = useState<SourceSystem | null>(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [importAllExternalItems, setImportAllExternalItems] = useState(false);

  const [
    totalExternalComplianceGroupsCount,
    setTotalExternalComplianceGroupsCount,
  ] = useState(0);
  const [
    selectedExternalComplianceGroups,
    setSelectedExternalComplianceGroups,
  ] = useState<string[]>([]);
  const [importedComplianceGroups, setImportedComplianceGroups] = useState<
    ImportComplianceGroupsMutation["importComplianceGroups"] | null
  >(null);

  const openModal = useCallback(
    ({ sourceSystem }: { sourceSystem: SourceSystem }) => {
      setSourceSystem(sourceSystem);
      setModalOpened(true);
    },
    [],
  );

  const resetModal = useCallback(() => {
    setSourceSystem(null);
    setNestedStep(0);
  }, [setNestedStep]);

  const closeModal = useCallback(() => {
    setModalOpened(false);
    resetModal();
  }, [resetModal]);

  const [importComplianceGroupsMutation] = useImportComplianceGroupsMutation();
  const importSelectedComplianceGroups = useCallback(async () => {
    try {
      if (sourceSystem) {
        moveToNextNestedStep();
        const { data, errors } = await importComplianceGroupsMutation({
          variables: {
            input: {
              externalComplianceGroupIds: selectedExternalComplianceGroups,
              sourceSystem,
            },
          },
        });
        if (errors) {
          moveToPreviousNestedStep();
          setError(errors);
        }
        if (data?.importComplianceGroups) {
          setImportedComplianceGroups(data.importComplianceGroups);
          updateStartupData({
            complianceGroups: [
              ...complianceGroups,
              ...data.importComplianceGroups.created,
            ],
          });
          moveToNextNestedStep();
        }
      }
    } catch (error) {
      moveToPreviousNestedStep();
      setError(error);
    }
  }, [
    sourceSystem,
    selectedExternalComplianceGroups,
    importComplianceGroupsMutation,
    moveToPreviousNestedStep,
    moveToNextNestedStep,
    setError,
    complianceGroups,
    updateStartupData,
  ]);

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: null,
        pages: [
          {
            component: (
              <ImportExternalComplianceGroupsTable
                defaultSourceSystem={defaultSourceSystem}
              />
            ),
            footerButtonsConfig: [
              {
                type: "text",
                text: intl.$t(
                  { id: "OUT_OF_SELECTED" },
                  {
                    count: selectedExternalComplianceGroups.length,
                    total: totalExternalComplianceGroupsCount,
                  },
                ),
                className: "flex mr-4 items-center",
                hidden:
                  selectedExternalComplianceGroups.length === 0 &&
                  totalExternalComplianceGroupsCount === 0,
              },
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: importSelectedComplianceGroups,
                text: intl.$t({ id: "IMPORT" }),
                className: "flex-1",
                disabled: selectedExternalComplianceGroups.length === 0,
              },
            ],
          },
          {
            component: (
              <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
            ),
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: (
              <CompletedStep text={intl.$t({ id: "IMPORT_COMPLETED" })} />
            ),
            onModalClose: moveToNextNestedStep,
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: <ImportExternalComplianceGroupsImportResults />,
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "w-60",
              },
            ],
          },
        ],
      },
    ],
    [
      defaultSourceSystem,
      intl,
      selectedExternalComplianceGroups.length,
      totalExternalComplianceGroupsCount,
      closeModal,
      importSelectedComplianceGroups,
      moveToNextNestedStep,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        modalOpened,
        sourceSystem,
        pages,
        openModal,
        closeModal,

        selectedExternalComplianceGroups,
        setSelectedExternalComplianceGroups,

        importAllExternalItems,
        setImportAllExternalItems,

        totalExternalComplianceGroupsCount,
        setTotalExternalComplianceGroupsCount,

        importedComplianceGroups,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useImportExternalComplianceGroups = () =>
  useContext(ProviderContext);

import { ClearFiltersButton } from "@/common/components/clean-filters-button/ClearFiltersButton";
import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { HeaderFilters } from "@/common/components/header-container/HeaderContainer";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { PopoverWithTabs } from "@/common/components/popover-with-tabs/PopoverWithTabs";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { StatusesFilter } from "@/common/components/status-filter/StatusesFilter";
import { InvoiceHeader } from "@/contractor/pages/home/invoices/common/components/InvoiceHeader";
import { useIntl } from "react-intl";
import { useInvoices } from "../../../providers/InvoicesProvider";
import { InvoiceFolderFilter } from "../../invoice-folder-picker/InvoiceFolderFilter";
import { InvoiceListActions } from "../../invoice-list-actions/InvoiceListActions";
import { InvoiceAdditionalFilters } from "../invoice-additional-filters/InvoiceAdditionalFilters";
import { useScannedInvoicesHeader } from "./hooks/useScannedInvoicesHeader";

enum ScannedInvoicesDateFilters {
  IssueDate = "issueDate",
  DueDate = "dueDate",
  CreateDate = "createDate",
  ApprovalDate = "approvedDate",
}

export const ScannedInvoicesHeader = () => {
  const { filter, setFilter } = useInvoices();
  const intl = useIntl();
  const {
    options,
    projects,
    setFilterHandler,
    setFolderFilterHandler,
    countNonDefaultFilters,
    resetFilterToDefault,
    appliedFilters,
    appliedDateFilters,
  } = useScannedInvoicesHeader();

  return (
    <InvoiceHeader>
      <HeaderFilters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => setFilter({ ...filter, search: value })}
          value={filter?.search}
          tooltip="INVOICES_SEARCH_INFO"
          applyHeaderAccent
          clear={() => setFilter({ ...filter, search: undefined })}
        />
        <ProjectsFilterSelector
          projects={projects}
          onChange={(projectId) =>
            setFilter({
              ...filter,
              projectIds: projectId ? [projectId] : undefined,
            })
          }
          value={filter?.projectIds?.[0]}
          withCustomView
          clear={() =>
            setFilter({
              ...filter,
              projectIds: undefined,
            })
          }
        />
        <StatusesFilter
          options={options}
          selectedStatuses={filter?.statuses}
          onChange={setFilterHandler}
          applyHeaderAccent
        />
        <InvoiceFolderFilter
          value={filter?.folderIds}
          onChange={setFolderFilterHandler}
        />
        <ListHeaderFilterButton
          isDefault={appliedFilters === 0}
          filter={<InvoiceAdditionalFilters />}
          appliedFilters={appliedFilters}
        />
        <ListDatesButton
          isDefault={appliedDateFilters === 0}
          appliedFilters={appliedDateFilters}
          hideDates
          dates={[
            {
              startDate: filter?.minIssueDate,
              endDate: filter?.maxIssueDate,
            },
          ]}
          clearValues={() =>
            setFilter({
              ...filter,
              minIssueDate: undefined,
              maxIssueDate: undefined,
            })
          }
        >
          {(onClose) => (
            <PopoverWithTabs
              tabs={[
                {
                  label: intl.$t({ id: "ISSUE_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.maxIssueDate || filter?.minIssueDate
                          ? {
                              startDate: filter?.minIssueDate
                                ? new Date(filter.minIssueDate)
                                : new Date(),
                              endDate: filter?.maxIssueDate
                                ? new Date(filter.maxIssueDate)
                                : new Date(),
                            }
                          : undefined
                      }
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minIssueDate: dateRange.startDate?.getTime(),
                          maxIssueDate: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: ScannedInvoicesDateFilters.IssueDate,
                },
                {
                  label: intl.$t({ id: "DUE_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.maxDueDate || filter?.minDueDate
                          ? {
                              startDate: filter?.minDueDate
                                ? new Date(filter.minDueDate)
                                : new Date(),
                              endDate: filter?.maxDueDate
                                ? new Date(filter.maxDueDate)
                                : new Date(),
                            }
                          : undefined
                      }
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minDueDate: dateRange.startDate?.getTime(),
                          maxDueDate: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: ScannedInvoicesDateFilters.DueDate,
                },
                {
                  label: intl.$t({ id: "RECEIVED_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.maxCreatedAt || filter?.minCreatedAt
                          ? {
                              startDate: filter?.minCreatedAt
                                ? new Date(filter.minCreatedAt)
                                : new Date(),
                              endDate: filter?.maxCreatedAt
                                ? new Date(filter.maxCreatedAt)
                                : new Date(),
                            }
                          : undefined
                      }
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minCreatedAt: dateRange.startDate?.getTime(),
                          maxCreatedAt: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: ScannedInvoicesDateFilters.CreateDate,
                },
                {
                  label: intl.$t({ id: "APPROVAL_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.maxApprovedAt || filter?.minApprovedAt
                          ? {
                              startDate: filter?.minApprovedAt
                                ? new Date(filter.minApprovedAt)
                                : new Date(),
                              endDate: filter?.maxApprovedAt
                                ? new Date(filter.maxApprovedAt)
                                : new Date(),
                            }
                          : undefined
                      }
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minApprovedAt: dateRange.startDate?.getTime(),
                          maxApprovedAt: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: ScannedInvoicesDateFilters.ApprovalDate,
                },
              ]}
              activeTab={ScannedInvoicesDateFilters.IssueDate}
            />
          )}
        </ListDatesButton>
        <ClearFiltersButton
          count={countNonDefaultFilters}
          onClick={resetFilterToDefault}
        />
      </HeaderFilters>
      <InvoiceListActions />
    </InvoiceHeader>
  );
};

import {
  ProjectListOptionFieldsFragment,
  ProjectStatus,
  useProjectListOptionsQuery,
} from "@/generated/graphql";
import { useEffect, useMemo } from "react";
import { MAX_ITEMS_PER_PAGE } from "../const";
import { sortByName } from "../utils/sorting/sortbyName";
import { useGlobalError } from "./useGlobalError";

type ProjectListProps = {
  includeClosedProjects?: boolean | null;
  skip?: boolean;
  filterResults?: (project: ProjectListOptionFieldsFragment) => boolean;
  groupBy?: (project: ProjectListOptionFieldsFragment) => string;
};

export const useProjectListOptions = ({
  includeClosedProjects = null,
  skip = false,
  filterResults,
  groupBy,
}: ProjectListProps = {}) => {
  const { setError } = useGlobalError();
  const { data, error, loading, refetch } = useProjectListOptionsQuery({
    variables: {
      first: MAX_ITEMS_PER_PAGE,
      filter: {
        deleted: false,
      },
    },
    skip,
  });

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error, setError]);

  const projects = useMemo(
    () =>
      data?.projects.edges.flatMap((edge) => edge.node).sort(sortByName) ?? [],
    [data?.projects.edges],
  );
  const filteredProjects = useMemo(() => {
    const filterFn = filterResults ?? (() => true);
    if (includeClosedProjects === null) {
      return projects.filter(filterFn).toSorted((a, b) => {
        if (groupBy) {
          return groupBy(a).localeCompare(groupBy(b));
        }
        return a.name.localeCompare(b.name);
      });
    }

    return projects
      .filter((project) =>
        includeClosedProjects
          ? [ProjectStatus.Completed, ProjectStatus.Lost].includes(
              project.status,
            )
          : [ProjectStatus.Active, ProjectStatus.Awarded].includes(
              project.status,
            ),
      )
      .filter(filterFn)
      .toSorted((a, b) => {
        if (groupBy) {
          return groupBy(a).localeCompare(groupBy(b));
        }
        return a.name.localeCompare(b.name);
      });
  }, [filterResults, includeClosedProjects, projects, groupBy]);

  return {
    projects: filteredProjects,
    loading,
    refetch,
  };
};

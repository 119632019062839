import { CellValue } from "handsontable/common";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { getPhysicalRowIndex } from "../../utils/getPhysicalRowIndex";
import { rowIsEmpty } from "../../utils/rowIsEmpty";
import { useRenderHelpers } from "../helpers/useRenderHelpers";
import { applyClasses } from "../utils/applyClasses";
import { isValueEmpty } from "../utils/isValueEmpty";
import { removeTooltip } from "../utils/removeTooltip";
import { RendererFunctionType } from "./types/RendererFunctionType";

export const useBaseRenderer = () => {
  const { checkReadOnly, hideLumpSumItemReadOnlyText } = useRenderHelpers();
  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      textRenderer(instance, td, row, col, prop, value, cellProperties);

      if (isValueEmpty(value)) {
        removeTooltip(td);
        return;
      }

      const rowData = instance?.getDataAtRow(row);
      if (rowIsEmpty(rowData)) {
        return;
      }

      applyClasses(td, "htMiddle relative");
      const sourceRow = instance?.getSourceDataAtRow(
        getPhysicalRowIndex(instance, row),
      ) as CellValue[];
      checkReadOnly(td, sourceRow, cellProperties);
      hideLumpSumItemReadOnlyText(td, rowData, instance, cellProperties);
    },
    [checkReadOnly, hideLumpSumItemReadOnlyText],
  );

  return renderer;
};

import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  Base,
  MdFixedContainer,
  NotesContainer,
  SmdFixedContainer,
  SmFixedContainer,
  ToggleContainer,
} from "@/common/layout/ResponsiveClasses";
import {
  QuoteInputContainerReadonly,
  QuoteInputContainerReadonlyWithoutGroup,
} from "@/distributor/common/Common.styles";
import { useManufacturersSettings } from "@/distributor/common/hooks/useManufacturersSettings";
import { BidPriceTotalForItem } from "@/distributor/common/quote/BidPriceTotalForItem";
import {
  DistributorBuyoutFieldsFragment,
  DistributorBuyoutItemFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useItemsHavePhaseCodes } from "../../common/hooks/useItemsHavePhaseCodes";
import { BuyoutCategoryTotal } from "../BuyoutCategoryTotal";
import { BuyoutExpirationDate } from "../BuyoutExpirationDate";
import { BuyoutNotes } from "../BuyoutNotes";
import { BuyoutNotesDetails } from "../BuyoutNotesDetails";
import { DistributorBuyoutCategoryToggle } from "../DistributorBuyoutCategoryToggle";
import { DistributorBuyoutCostCodeManufacturerSelector } from "../DistributorBuyoutCostCodeManufacturerSelector";
import { DistributorBuyoutGroupByCostCodeToggle } from "../DistributorBuyoutGroupByCostCodeToggle";
import { DistributorBuyoutItemMaterialView } from "../DistributorBuyoutItemMaterialView";
import { DistributorBuyoutItemToggle } from "../DistributorBuyoutItemToggle";
import { DistributorBuyoutManufacturerSelector } from "../DistributorBuyoutManufacturerSelector";
import { DistributorBuyoutPriceInput } from "../DistributorBuyoutPriceInput";
import { DistributorBuyoutTagsPicker } from "../DistributorBuyoutTagsPicker";
import { DistributorProductView } from "../DistributorProductView";
import { WithdrawalWrapper } from "../WithdrawalWrapper";
import { SymbolContainer, TotalContainerStyled } from "./BuyoutItem.styles";

const ItemWrapper = tw(Base)`
  col-span-full
  lg:basis-3/12 lg:w-3/12
`;

const ToggleContainerWrapper = tw(
  ToggleContainer,
)`col-span-3 justify-self-start`;

export const useDistributorBuyoutConfiguration = (
  buyout: DistributorBuyoutFieldsFragment,
) => {
  const { getVendorHasManufacturerSetting } = useManufacturersSettings();
  const hasManufacturersSetting = getVendorHasManufacturerSetting(
    buyout?.project?.location?.org.settings?.display,
  );
  const itemsHavePhaseCodes = useItemsHavePhaseCodes();
  const hasPhaseCodes = useMemo(
    () => itemsHavePhaseCodes(buyout.items),
    [buyout.items, itemsHavePhaseCodes],
  );

  const configuration: Array<GridCol<DistributorBuyoutItemFieldsFragment>> =
    useMemo(
      () => [
        {
          wrapper: ItemWrapper,
          item: ({ item, count }) =>
            item.rfqItem ? (
              <DistributorProductView item={item} count={count} />
            ) : (
              <DistributorBuyoutItemMaterialView item={item} count={count} />
            ),
          group: (category) => (
            <ProductCategory
              type={ProductCategoryType.CostCode}
              category={category}
              items={category.items.length}
              itemsHavePhaseCodes={itemsHavePhaseCodes(category.items)}
            />
          ),
          header: (
            <>
              <FormattedMessage id="ITEM_DESCRIPTION" />
              <DistributorBuyoutGroupByCostCodeToggle />
            </>
          ),
        },
        {
          wrapper: SmFixedContainer,
          header: (
            <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />
          ),
          item: ({ item }) => {
            return <DistributorBuyoutTagsPicker item={item} readonly />;
          },
        },
        {
          wrapper: ToggleContainerWrapper,
          item: ({ item, readonly }) => (
            <DistributorBuyoutItemToggle item={item} readonly={readonly} />
          ),
          header: <FormattedMessage id="INCLUDE_ITEM" />,
          group: (category, readonly) => (
            <DistributorBuyoutCategoryToggle
              items={category.items}
              readonly={readonly}
            />
          ),
        },
        {
          header: <FormattedMessage id="PROVIDED_MANUFACTURER" />,
          wrapper: MdFixedContainer,
          item: ({ item, index }) => (
            <WithdrawalWrapper item={item}>
              <DistributorBuyoutManufacturerSelector
                item={item}
                index={index}
              />
            </WithdrawalWrapper>
          ),
          group: (category, readonly) => (
            <DistributorBuyoutCostCodeManufacturerSelector
              category={category}
              readonly={readonly}
            />
          ),
          position: "center",
          hidden: !hasManufacturersSetting,
        },
        {
          wrapper: NotesContainer,
          item: ({ item }) => (
            <WithdrawalWrapper item={item}>
              <BuyoutNotes item={item} readonly={false} />
            </WithdrawalWrapper>
          ),
          position: "center",
          header: <FormattedMessage id="NOTES" />,
        },
        {
          wrapper: NotesContainer,
          position: "center",
          item: ({ item, readonly }) => (
            <WithdrawalWrapper item={item}>
              <BuyoutExpirationDate item={item} readonly={readonly} />
            </WithdrawalWrapper>
          ),
          header: <FormattedMessage id="PRICE_EXPIRATION" />,
        },
        {
          wrapper: QuoteInputContainerReadonly,
          item: ({ item }) => (
            <WithdrawalWrapper item={item}>
              <ValueUnit
                value={item.quantityDecimal}
                uom={item.projectItem.estimateUom}
              />
            </WithdrawalWrapper>
          ),
          group: (category) => (
            <ProductCategoryCount itemsLength={category.items.length} />
          ),
          header: <FormattedMessage id="UNITS_IN_BUYOUT" />,
        },
        {
          wrapper: SymbolContainer,
          item: ({ item }) => (
            <WithdrawalWrapper item={item}>
              <>x</>
            </WithdrawalWrapper>
          ),
        },
        {
          wrapper: QuoteInputContainerReadonlyWithoutGroup,
          item: ({ item, error }) => (
            <WithdrawalWrapper item={item}>
              <ValueCurrency
                value={item.requestedUnitPrice || 0}
                perUom={item.projectItem.estimateUom}
                error={error && !Number(item.unitPrice)}
                dynamicDigits
              />
            </WithdrawalWrapper>
          ),
          header: <FormattedMessage id="REQUESTED_UNIT_PRICE" />,
        },
        {
          wrapper: SmdFixedContainer,
          item: ({ item, index, error }) => (
            <WithdrawalWrapper item={item}>
              <DistributorBuyoutPriceInput
                item={item}
                index={index}
                error={error && !Number(item.unitPrice)}
              />
            </WithdrawalWrapper>
          ),
          position: "center",
          header: <FormattedMessage id="UNIT_PRICE" />,
        },
        {
          wrapper: SymbolContainer,
          item: ({ item }) => (
            <WithdrawalWrapper item={item}>
              <>=</>
            </WithdrawalWrapper>
          ),
        },
        {
          wrapper: TotalContainerStyled,
          item: ({ item }) => (
            <WithdrawalWrapper item={item}>
              <BidPriceTotalForItem item={item} />
            </WithdrawalWrapper>
          ),
          position: "end",
          header: <FormattedMessage id="PRICE" />,
          group: (category) => <BuyoutCategoryTotal items={category.items} />,
          details: ({ item, readonly }) => {
            return (
              <WithdrawalWrapper item={item}>
                <BuyoutNotesDetails item={item} readonly={readonly} />
              </WithdrawalWrapper>
            );
          },
        },
      ],
      [hasManufacturersSetting, itemsHavePhaseCodes, hasPhaseCodes],
    );
  return configuration;
};

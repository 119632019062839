import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { getMaterialNameParts } from "@/common/components/spreadsheet-table/utils/getMaterialNameParts";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useGetProjectItemFromSku } from "@/common/hooks/useGetProjectItem";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  AddToRfqItemInput,
  ManufacturerFieldsFragment,
  UpdateRfqInput,
  UpdateRfqItemInput,
} from "@/generated/graphql";
import { useState } from "react";
import { useRfq } from "./useRfq";
import { useRfqMutations } from "./useRfqMutations";

export const useSyncRfqItems = () => {
  const { manufacturers } = useManufacturers();
  const { spreadsheetData, getRemovedRowIds, rowHasChanges, gotoInvalidRow } =
    useColumnMapper();
  const {
    getCostCodeId,
    addMissingMaterials,
    addMissingManufacturers,
    getCellWithAdditionalData,
    tableHasColumn,
  } = useTableHelpers();
  const findMaterialByName = useFindMaterialByName();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { rfq } = useRfq();
  const { materials } = useMaterials();
  const [saving, setSaving] = useState(false);
  const { hasPhaseCodes } = useOrgSettings();
  const { updateRfq, isUpdating } = useRfqMutations();
  const getProjectItem = useGetProjectItemFromSku();

  const addUpdatedItemOptionalFields = (
    item: UpdateRfqItemInput,
    row: Record<string, string>,
    newManufacturers: ManufacturerFieldsFragment[],
  ): UpdateRfqItemInput => {
    const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
      (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
    );
    const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";

    if (tableHasColumn(COLUMN_TYPE.Notes)) {
      item.instructions = {
        text: notes,
      };
    }
    if (tableHasColumn(COLUMN_TYPE.Manufacturer)) {
      item.manufacturerId = manufacturer?.id;
      item.clearManufacturer = !manufacturer;
    }
    if (tableHasColumn(COLUMN_TYPE.CostCode)) {
      item.costCodeId = getCostCodeId(row);
      item.clearCostCode = !getCostCodeId(row);
    }
    return item;
  };

  const addNewItemOptionalFields = (
    newItem: AddToRfqItemInput,
    row: Record<string, string>,
    newManufacturers: ManufacturerFieldsFragment[],
  ): AddToRfqItemInput => {
    const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
      (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
    );
    const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";
    if (tableHasColumn(COLUMN_TYPE.Notes)) {
      newItem.instructions = {
        text: notes,
      };
    }

    if (tableHasColumn(COLUMN_TYPE.Manufacturer)) {
      newItem.manufacturerId = manufacturer?.id;
    }
    if (tableHasColumn(COLUMN_TYPE.CostCode)) {
      newItem.costCodeId = getCostCodeId(row);
    }
    return newItem;
  };

  const getSyncedRfq = async (): Promise<UpdateRfqInput> => {
    const newItems: AddToRfqItemInput[] = [];
    const itemsToUpdate: UpdateRfqItemInput[] = [];
    let itemsToRemove = [] as string[];

    setSaving(true);
    itemsToRemove = getRemovedRowIds(rfq?.items ?? []);
    const newMaterials = (await addMissingMaterials()) || [];
    const newManufacturers = (await addMissingManufacturers()) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getCellValue(row, COLUMN_TYPE.UOM);

      if (!material || rowIsEmpty(row)) {
        if (row.id) {
          itemsToRemove.push(row.id);
        }
        return false;
      }

      let quantityDecimal =
        getCellValue(row, COLUMN_TYPE.PositiveQuantity) || "0";

      const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );
      const existingItem = rfq?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem?.material.id === material.id;

      if (matchingMaterials) {
        if (rowHasChanges(row) || existingItem.position !== index) {
          const item = {
            rfqItemId: row.id,
            quantityDecimal,
            uom,
            clearManufacturer: !manufacturer,
            description: rowMaterialText,
            position: index,
          } as UpdateRfqItemInput;
          itemsToUpdate.push(
            addUpdatedItemOptionalFields(item, row, newManufacturers),
          );
        }
      } else {
        const newItem = {
          description: getMaterialNameParts(rowMaterialText).namePart,
          projectItem: getProjectItem(material, uom),
          quantityDecimal,
          position: index,
        } as AddToRfqItemInput;
        newItems.push(addNewItemOptionalFields(newItem, row, newManufacturers));
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    return {
      rfqId: rfq?.id ?? "",
      updates: itemsToUpdate,
      addedItems: newItems,
      removedItems: itemsToRemove.filter((item) =>
        rfq?.items.find((i) => i.id === item),
      ),
      assignDefaultCostCodes: false,
    };
  };

  const validateItems = async () => {
    if (
      !(await validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
      ])) ||
      !(await validateRowValues(
        [
          COLUMN_TYPE.PrefilledPrice,
          COLUMN_TYPE.UOM,
          ...(hasPhaseCodes
            ? tableHasColumn(COLUMN_TYPE.PhaseCode)
              ? [COLUMN_TYPE.PhaseCode]
              : []
            : tableHasColumn(COLUMN_TYPE.CostCode)
              ? [COLUMN_TYPE.CostCode]
              : []),
        ],
        undefined,
        { minPrice: undefined },
      ))
    ) {
      gotoInvalidRow();
      return false;
    }
    return true;
  };

  const syncRfqItems = async () => {
    if (!(await validateItems())) {
      return undefined;
    }

    const syncedRfq = await getSyncedRfq();
    await updateRfq(syncedRfq);

    return rfq;
  };

  return {
    getSyncedRfq,
    validateItems,
    syncRfqItems,
    saving: isUpdating || saving,
  };
};

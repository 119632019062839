import { Select } from "@/common/components/select/components/single/Select";
import { GMVBarChart } from "@/contractor/pages/admin/gmv-report/components/GMVBarChart";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { periods, useGMVChartData } from "../hooks/useGMVChartData";

const Container = tw.div`flex justify-center flex-col mt-5 w-full`;
const Selectors = tw.div`flex gap-2 justify-end`;
const GraphContainer = tw.div`grid w-full overflow-x-auto`;

type Props = {
  excludedOrgs: string[] | null;
};

export const GMVCharts: FC<Props> = ({ excludedOrgs }) => {
  const intl = useIntl();
  const {
    graphs,
    headers,
    customers,
    selectedHeader,
    setSelectedHeader,
    selectedCustomer,
    setSelectedCustomer,
    selectedPeriod,
    setSelectedPeriod,
    randomizedColors,
    loadDataForOneMonth,
    VALUE_AGGREGATED_ONLY,
    VALUE_SHOW_ALL,
    VALUE_ALL_CUSTOMERS_DATA,
  } = useGMVChartData({ excludedOrgs });

  const filteredGraphs = useMemo(() => {
    if (selectedCustomer === VALUE_AGGREGATED_ONLY) {
      return graphs.filter((graph) =>
        graph.title.toLowerCase().startsWith(VALUE_ALL_CUSTOMERS_DATA),
      );
    }
    if (selectedCustomer === VALUE_SHOW_ALL) {
      return graphs;
    }
    return graphs.filter((graph) =>
      graph.title.toLowerCase().startsWith(selectedCustomer),
    );
  }, [
    graphs,
    selectedCustomer,
    VALUE_AGGREGATED_ONLY,
    VALUE_SHOW_ALL,
    VALUE_ALL_CUSTOMERS_DATA,
  ]);

  return (
    <Container>
      <Selectors>
        <Select
          className="mb-1 w-64 self-end"
          testId="customer-selector"
          label={intl.$t({ id: "CUSTOMERS" })}
          options={customers}
          value={selectedCustomer}
          onChange={(value) => setSelectedCustomer(value as string)}
          getLabel={(option) => option.name}
          getValue={(option) => option.value}
        />
        <Select
          className="mb-1 w-52 self-end"
          testId="form-order-type-selector"
          label={intl.$t({ id: "COLUMN" })}
          options={headers}
          value={selectedHeader}
          onChange={(value) => setSelectedHeader(value as string)}
          getLabel={(option) => option.name}
          getValue={(option) => option.value}
        />
        <Select
          className="mb-1 w-40 self-end"
          testId="form-order-type-selector"
          label={intl.$t({ id: "PERIOD" })}
          options={periods}
          value={String(selectedPeriod)}
          onChange={(value) => setSelectedPeriod(Number(value))}
          getLabel={(option) => option.name}
          getValue={(option) => option.value}
        />
      </Selectors>
      <GraphContainer>
        {filteredGraphs.map((graph, index) => {
          const isAggregatedGraph = graph.title
            .toLowerCase()
            .startsWith(VALUE_ALL_CUSTOMERS_DATA);

          const potentialDataSplit = isAggregatedGraph
            ? graphs.filter(
                (g) =>
                  !g.title.toLowerCase().startsWith(VALUE_ALL_CUSTOMERS_DATA),
              )
            : undefined;

          const showSplitContent =
            selectedCustomer === VALUE_SHOW_ALL ||
            (selectedCustomer === VALUE_AGGREGATED_ONLY && isAggregatedGraph);

          return (
            <GMVBarChart
              key={`${graph.title}${index}`}
              title={graph.title}
              data={graph.data}
              dataSplit={potentialDataSplit}
              format={graph.format}
              labels={graph.labels}
              colors={randomizedColors}
              showMax={false}
              showSplitTooltipContent={showSplitContent}
              loadData={(id: string) =>
                loadDataForOneMonth(id, index, graph.title)
              }
            />
          );
        })}
      </GraphContainer>
    </Container>
  );
};

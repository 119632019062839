import { If } from "@/common/components/if/If";
import { DatePicker } from "@/common/components/picker/components/DatePicker";
import { TextField } from "@/common/components/textfield/TextField";
import { useIntl } from "react-intl";
import { InvoiceDiscountDatePicker } from "../header/InvoiceDiscountDatePicker";
import { InvoiceDocumentOptionsProps } from "./types/InvoiceDocumentOptions.types";

type Props = Pick<
  InvoiceDocumentOptionsProps,
  "includeInputLabels" | "readonly"
> & {
  invoiceIssueDate: Date | null;
  discountDate: Date | null;
  setDiscountDate: (date: Date | null) => void;
  setDiscountValue: (value: string) => void;
};

export const InvoiceDiscountDate = ({
  includeInputLabels,
  readonly,
  invoiceIssueDate,
  discountDate,
  setDiscountDate,
  setDiscountValue,
}: Props) => {
  const intl = useIntl();

  return (
    <DatePicker
      minDate={invoiceIssueDate ?? undefined}
      date={discountDate}
      onChange={(date) => {
        setDiscountDate(date);
        if (!date) {
          setDiscountValue("0");
        }
      }}
      utc
      className="rounded bg-white"
      textFieldComponent={(props) => (
        <TextField
          {...props}
          shrink
          InputLabelProps={{
            className: "bg-white px-1 text-gray-500",
          }}
          size="small"
          sx={{
            " .MuiInputBase-input": {
              fontSize: "14px",
              textAlign: "center",
            },
          }}
        />
      )}
      customInputAdornment={() => (
        <If isTrue={!readonly}>
          <InvoiceDiscountDatePicker
            issueDate={invoiceIssueDate}
            discountDate={discountDate}
            setDiscountDate={setDiscountDate}
          />
        </If>
      )}
      label={includeInputLabels ? intl.$t({ id: "INVOICE_DISCOUNT_DATE" }) : ""}
      error={Boolean(
        discountDate &&
          invoiceIssueDate &&
          discountDate.getTime() < invoiceIssueDate.getTime(),
      )}
      readonly={readonly}
    />
  );
};

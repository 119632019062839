import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { PricePercentageSelectedType } from "@/common/components/price-percentage-selector/PricePercentageSelector";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useLoadingAction } from "@/common/hooks/useLoadingAction";
import { useUser } from "@/common/providers/UserProvider";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useEquipmentItems } from "@/contractor/pages/admin/cost-structure/pages/equipment/hooks/useEquipmentItems";
import { usePaymentMethods } from "@/contractor/pages/admin/cost-structure/pages/payment-methods/hooks/usePaymentMethods";
import { useServiceCodes } from "@/contractor/pages/admin/cost-structure/pages/service-codes/hooks/useServiceCodes";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { dateDiffInDays } from "@/contractor/pages/home/invoices/pages/scanned-invoices/utils/dateDiff";
import {
  UpdateInvoiceInput,
  useUpdateInvoiceOptionsMutation,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDebounce } from "use-debounce";
import { InvoiceDocumentOptionsProps } from "../invoice-document-options/types/InvoiceDocumentOptions.types";

type Props = Pick<
  InvoiceDocumentOptionsProps,
  "invoice" | "includeInputLabels" | "readonly" | "bulkUpdate" | "rescanInvoice"
>;

export const useInvoiceDocumentOptions = ({
  invoice,
  includeInputLabels = false,
  readonly,
  bulkUpdate = false,
  rescanInvoice,
}: Props) => {
  const intl = useIntl();
  const { loading, asyncAction } = useLoadingAction();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { updateInvoice: updateInvoiceBulk, updating } =
    useInvoiceVerification();
  const [updateInvoiceOptionsMutation] = useUpdateInvoiceOptionsMutation();
  const { setError } = useGlobalError();

  const { isSystemAdmin } = useUser();
  const { serviceCodes, loading: loadingServiceCodes } = useServiceCodes();
  const { equipmentItems, loading: loadingEquipmentItems } =
    useEquipmentItems();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const hasAccountingDate = hasFeatureInConnectedSourceSystem(
    IntegrationFeature.UseAccountingDate,
  );
  const { paymentMethods } = usePaymentMethods();

  const [updatesCount, setUpdatesCount] = useState(0);
  const [retentionValue, setRetentionValue] = useState(
    invoice?.retentionPercentage ?? invoice?.retentionAmount ?? "0",
  );
  const [debouncedRetentionValue] = useDebounce(retentionValue, 700);
  const [discountValue, setDiscountValue] = useState(
    invoice?.discountPercentage ?? invoice?.discountAmount ?? "0",
  );
  const [debouncedDiscountValue] = useDebounce(discountValue, 700);
  const [discountDate, setDiscountDate] = useState(
    invoice?.discountDate ? new Date(invoice?.discountDate) : null,
  );

  const [accountingDate, setAccountingDate] = useState(
    invoice?.accountingDate ? new Date(invoice?.accountingDate) : null,
  );
  const [debouncedUpdatesCount] = useDebounce(updatesCount, 2000);
  const [debouncedAccountingDate] = useDebounce(accountingDate, 700);
  const [discountType, setDiscountType] = useState(
    invoice?.discountAmount != null
      ? PricePercentageSelectedType.Price
      : PricePercentageSelectedType.Percentage,
  );
  const [retentionType, setRetentionType] = useState(
    invoice?.retentionAmount != null
      ? PricePercentageSelectedType.Price
      : PricePercentageSelectedType.Percentage,
  );

  useEffect(() => {
    if (debouncedUpdatesCount) {
      setUpdatesCount(0);
    }
  }, [debouncedUpdatesCount]);

  useEffect(() => {
    setRetentionValue(
      invoice?.retentionPercentage ?? invoice?.retentionAmount ?? "0",
    );
  }, [invoice?.retentionAmount, invoice?.retentionPercentage]);

  useEffect(() => {
    setDiscountValue(
      invoice?.discountPercentage ?? invoice?.discountAmount ?? "0",
    );
  }, [invoice?.discountAmount, invoice?.discountPercentage]);

  useEffect(() => {
    setDiscountDate(
      invoice?.discountDate ? new Date(invoice?.discountDate) : null,
    );
  }, [invoice?.discountDate]);

  const invoiceIssueDate = useMemo(
    () => (invoice?.issueDate ? new Date(invoice.issueDate) : null),
    [invoice?.issueDate],
  );

  const invoiceDiscountDate = useMemo(
    () => (invoice?.discountDate ? new Date(invoice?.discountDate) : null),
    [invoice?.discountDate],
  );

  const showRescanInvoice = useMemo(
    () => rescanInvoice && isSystemAdmin,
    [isSystemAdmin, rescanInvoice],
  );

  const handleRescan = useCallback(async () => {
    if (!rescanInvoice) {
      return;
    }

    await asyncAction(async () => {
      await rescanInvoice();
    });
  }, [asyncAction, rescanInvoice]);

  const discountValueWithError = useCallback(
    (value: DecimalSafe) =>
      value.lessThan(0) ||
      (value.greaterThan(100) &&
        discountType === PricePercentageSelectedType.Percentage) ||
      (new DecimalSafe(invoice?.total ?? "0")
        .minus(invoice?.taxAmount ?? "0")
        .lessThan(value) &&
        discountType === PricePercentageSelectedType.Price),
    [discountType, invoice?.taxAmount, invoice?.total],
  );

  const updateInvoiceOptions = useCallback(
    async (input: UpdateInvoiceInput) => {
      const { data, errors } = await updateInvoiceOptionsMutation({
        variables: { input },
      });
      if (errors) {
        setError(errors);
      }
      return data;
    },
    [setError, updateInvoiceOptionsMutation],
  );

  const updateInvoice = useCallback(
    async (input: UpdateInvoiceInput) => {
      if (bulkUpdate) {
        return await updateInvoiceBulk(input, { bulkUpdate: true });
      }
      return await updateInvoiceOptions(input);
    },
    [bulkUpdate, updateInvoiceBulk, updateInvoiceOptions],
  );

  useEffect(() => {
    if (!invoice?.id || readonly) {
      return;
    }
    const currentValue = new DecimalSafe(
      invoice?.retentionAmount ?? invoice?.retentionPercentage ?? "0",
    );
    const newValue = new DecimalSafe(debouncedRetentionValue);
    if (!currentValue.equals(newValue)) {
      if (newValue.lessThan(0) || newValue.greaterThan(100)) {
        return;
      }
      updateInvoice({
        id: invoice.id,
        retentionPercentage:
          retentionType === PricePercentageSelectedType.Percentage
            ? debouncedRetentionValue
            : undefined,
        retentionAmount:
          retentionType === PricePercentageSelectedType.Price
            ? debouncedRetentionValue
            : undefined,
      });
      setUpdatesCount((count) => count + 1);
    }
    //Dmitry Kozin: updateInvoice is not memoised so we need to exclude this method from the dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedRetentionValue, invoice?.id, readonly, bulkUpdate]);

  useEffect(() => {
    if (!invoice?.id || readonly || !hasAccountingDate) {
      return;
    }
    const currentValue = invoice?.accountingDate ?? undefined;
    const newValue = debouncedAccountingDate?.getTime();
    if (currentValue !== newValue) {
      updateInvoice({
        id: invoice.id,
        accountingDate: debouncedAccountingDate?.getTime(),
        clearAccountingDate: !debouncedAccountingDate ? true : undefined,
      });
      setUpdatesCount((count) => count + 1);
    }
    //Dmitry Kozin: updateInvoice is not memoised so we need to exclude this method from the dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedAccountingDate,
    invoice?.id,
    readonly,
    invoice?.accountingDate,
    bulkUpdate,
  ]);

  useEffect(() => {
    if (!invoice?.id || readonly) {
      return;
    }
    const currentValue = new DecimalSafe(
      invoice?.discountAmount ?? invoice?.discountPercentage ?? "0",
    );
    const newValue = new DecimalSafe(debouncedDiscountValue);
    if (!newValue.equals(currentValue)) {
      if (discountValueWithError(newValue)) {
        return;
      }
      updateInvoice({
        id: invoice.id,
        discountPercentage:
          discountType === PricePercentageSelectedType.Percentage
            ? debouncedDiscountValue
            : undefined,
        discountAmount:
          discountType === PricePercentageSelectedType.Price
            ? debouncedDiscountValue
            : undefined,
      });
      setUpdatesCount((count) => count + 1);
    }
    //Dmitry Kozin: updateInvoice is not memoised so we need to exclude this method from the dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDiscountValue, invoice?.id, readonly, bulkUpdate]);

  useEffect(() => {
    if (!invoice?.id || readonly) {
      return;
    }
    if (discountDate?.getTime() !== invoiceDiscountDate?.getTime()) {
      updateInvoice({
        id: invoice.id,
        discountDate: discountDate?.getTime(),
        ...(discountDate ? {} : { clearDiscountDate: true }),
      });
      setUpdatesCount((count) => count + 1);
    }
    //Dmitry Kozin: updateInvoice is not memoised so we need to exclude this method from the dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.id, readonly, discountDate, invoiceDiscountDate, bulkUpdate]);

  const discountLabel = useMemo(() => {
    const value =
      discountValue &&
      invoice?.discountedTotal &&
      invoice?.total &&
      discountType === PricePercentageSelectedType.Percentage
        ? formatCurrency(
            new DecimalSafe(invoice.total).minus(invoice.discountedTotal),
          )
        : undefined;
    if (includeInputLabels) {
      if (!value) {
        return intl.$t({ id: "INVOICE_DISCOUNT" });
      }
      return `${intl.$t({ id: "INVOICE_DISCOUNT" })} (${value})`;
    }
    return value;
  }, [
    includeInputLabels,
    discountValue,
    invoice?.discountedTotal,
    invoice?.total,
    discountType,
    formatCurrency,
    intl,
  ]);

  const retentionLabel = useMemo(() => {
    const value =
      retentionValue &&
      invoice?.total &&
      retentionType === PricePercentageSelectedType.Percentage
        ? formatCurrency(
            new DecimalSafe(invoice.total).mul(retentionValue).dividedBy(100),
          )
        : undefined;
    if (includeInputLabels) {
      if (value) {
        return `${intl.$t({ id: "INVOICE_RETAINAGE" })} (${value})`;
      } else {
        return intl.$t({ id: "INVOICE_RETAINAGE" });
      }
    } else {
      return value;
    }
  }, [
    retentionValue,
    invoice?.total,
    retentionType,
    formatCurrency,
    includeInputLabels,
    intl,
  ]);

  const invoicePaymentTerm = useMemo(
    () =>
      invoice?.dueDate && invoice.issueDate
        ? dateDiffInDays(invoice.dueDate, invoice.issueDate)?.toString() || "0"
        : "0",
    [invoice?.dueDate, invoice?.issueDate],
  );

  const updateInvoicePaymentTerm = useCallback(
    (value: string) => {
      if (invoice) {
        const issueDate = invoice.issueDate
          ? new Date(invoice.issueDate)
          : new Date();
        const days = Number(value || 0);
        updateInvoice({
          id: invoice.id,
          dueDate: new Date(
            issueDate.setDate(issueDate.getDate() + days),
          ).getTime(),
        });
      }
    },
    [invoice, updateInvoice],
  );

  const handleDiscountTypeChange = useCallback(
    async (type: PricePercentageSelectedType) => {
      if (!invoice || readonly) {
        return;
      }
      const response = await updateInvoice({
        id: invoice.id,
        discountPercentage:
          type === PricePercentageSelectedType.Percentage
            ? !new DecimalSafe(debouncedDiscountValue).isZero() &&
              !new DecimalSafe(invoice?.total ?? "0")
                .minus(invoice?.taxAmount ?? "0")
                .isZero()
              ? new DecimalSafe(debouncedDiscountValue)
                  .mul(100)
                  .dividedBy(
                    new DecimalSafe(invoice?.total ?? "0").minus(
                      invoice?.taxAmount ?? "0",
                    ),
                  )
                  .toString()
              : "0"
            : undefined,
        discountAmount:
          type === PricePercentageSelectedType.Price
            ? new DecimalSafe(invoice?.total ?? 0)
                .minus(invoice.discountedTotal ?? 0)
                .toString()
            : undefined,
      });
      if (response || bulkUpdate) {
        setDiscountType(type);
        setUpdatesCount((count) => count + 1);
      }
    },
    [bulkUpdate, debouncedDiscountValue, invoice, readonly, updateInvoice],
  );

  const handleRetentionTypeChange = useCallback(
    async (type: PricePercentageSelectedType) => {
      if (!invoice || readonly) {
        return;
      }
      const response = await updateInvoice({
        id: invoice.id,
        retentionPercentage:
          type === PricePercentageSelectedType.Percentage
            ? !new DecimalSafe(debouncedRetentionValue).isZero() &&
              !new DecimalSafe(invoice?.total ?? "0").isZero()
              ? new DecimalSafe(debouncedRetentionValue)
                  .mul(100)
                  .dividedBy(new DecimalSafe(invoice?.total ?? "0"))
                  .toString()
              : "0"
            : undefined,
        retentionAmount:
          type === PricePercentageSelectedType.Price
            ? new DecimalSafe(invoice?.total ?? 0)
                .mul(debouncedRetentionValue)
                .dividedBy(100)
                .toString()
            : undefined,
      });
      if (response || bulkUpdate) {
        setRetentionType(type);
        setUpdatesCount((count) => count + 1);
      }
    },
    [bulkUpdate, debouncedRetentionValue, invoice, readonly, updateInvoice],
  );

  const handleChangePaymentMethod = useCallback(
    async (paymentMethodId: string | null) => {
      if (!invoice || readonly) {
        return;
      }
      const response = await updateInvoice({
        id: invoice.id,
        paymentMethodId: paymentMethodId ?? undefined,
        clearPaymentMethod: !paymentMethodId,
      });
      if (response || bulkUpdate) {
        setUpdatesCount((count) => count + 1);
      }
    },
    [bulkUpdate, invoice, readonly, updateInvoice],
  );

  const retentionDollarAmount = useMemo(
    () =>
      (invoice?.retentionAmount ??
      (retentionValue &&
        invoice?.total &&
        retentionType === PricePercentageSelectedType.Percentage))
        ? formatCurrency(
            new DecimalSafe(invoice.total || 0)
              .mul(retentionValue)
              .dividedBy(100),
          )
        : undefined,
    [
      invoice?.retentionAmount,
      retentionType,
      retentionValue,
      invoice?.total,
      formatCurrency,
    ],
  );

  const discountDollarAmount = useMemo(
    () =>
      (invoice?.discountAmount ??
      (discountValue &&
        invoice?.discountedTotal &&
        invoice?.total &&
        discountType === PricePercentageSelectedType.Percentage))
        ? formatCurrency(
            new DecimalSafe(invoice.total || 0).minus(
              invoice.discountedTotal || 0,
            ),
          )
        : undefined,
    [
      invoice?.discountAmount,
      discountType,
      discountValue,
      invoice?.total,
      invoice?.discountedTotal,
      formatCurrency,
    ],
  );

  return {
    accountingDate,
    discountDate,
    discountType,
    discountValue,
    discountDollarAmount,
    handleDiscountTypeChange,
    handleChangePaymentMethod,
    handleRetentionTypeChange,
    invoiceDiscountDate,
    invoiceIssueDate,
    invoicePaymentTerm,
    retentionLabel,
    retentionValue,
    retentionDollarAmount,
    setAccountingDate,
    setDiscountDate,
    setDiscountValue,
    setRetentionValue,
    showRescanInvoice,
    handleRescan,
    discountLabel,
    loadingEquipmentItems,
    loadingServiceCodes,
    updatesCount,
    updating,
    updateInvoicePaymentTerm,
    loading,
    serviceCodes,
    equipmentItems,
    paymentMethods,
    setUpdatesCount,
    updateInvoice,
    discountValueWithError,
    retentionType,
    hasAccountingDate,
  };
};

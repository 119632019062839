import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { SpreadSheetConfig } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { useOrderItemRenderer } from "@/common/components/spreadsheet-table/renderers/material-renderer/useOrderItemRenderer";
import { useOrderQuantityRenderer } from "@/common/components/spreadsheet-table/renderers/quantity-renderer/useOrderQuantityRenderer";
import { useReleaseItemsDecorator } from "@/contractor/pages/home/release/pages/specify-details/hooks/useReleaseItemsDecorator";
import { useReleaseTableConfigHelpers } from "@/contractor/pages/home/release/pages/specify-details/hooks/useReleaseTableConfigHelpers";
import { useIntl } from "react-intl";

export const useCreateOrderFromNoteConfig = (): SpreadSheetConfig[] => {
  const intl = useIntl();
  const { requiredValidator } = useTableValidators();
  const { extraOptions } = useReleaseItemsDecorator();
  const orderItemRenderer = useOrderItemRenderer();
  const orderQuantityRenderer = useOrderQuantityRenderer();
  const { uomIsDisabled, isPriceDisabled } = useReleaseTableConfigHelpers();

  return [
    {
      header: intl.$t({ id: "ITEM_NAME" }),
      columnId: "name",
      extraOptions,
      columnType: COLUMN_TYPE.Material,
      renderer: orderItemRenderer,
    },
    {
      header: intl.$t({ id: "MANUFACTURER" }),
      columnId: "manufacturer",
      columnType: COLUMN_TYPE.Manufacturer,
    },
    {
      header: intl.$t({ id: "TAG" }),
      columnId: "tags",
      columnType: COLUMN_TYPE.Tag,
    },
    {
      header: intl.$t({ id: "UOM" }),
      columnId: "UOM",
      columnType: COLUMN_TYPE.UOM,
      readOnlyFn: uomIsDisabled,
    },
    {
      header: intl.$t({ id: "QUANTITY" }),
      columnId: "quantityDecimal",
      columnType: COLUMN_TYPE.Quantity,
      renderer: orderQuantityRenderer,
    },
    {
      header: intl.$t({ id: "UNIT_PRICE" }),
      columnId: "unitPrice",
      columnType: COLUMN_TYPE.PrefilledPrice,
      validator: requiredValidator,
      readOnlyFn: isPriceDisabled,
    },
    {
      header: intl.$t({ id: "COST_CODE" }),
      columnId: "costCode",
      columnType: COLUMN_TYPE.CostCode,
    },
    {
      header: intl.$t({ id: "COST_TYPE" }),
      columnId: "costType",
      columnType: COLUMN_TYPE.CostType,
    },
    {
      header: intl.$t({ id: "PHASE_CODE" }),
      columnId: "phaseCode",
      columnType: COLUMN_TYPE.PhaseCode,
    },
    {
      header: intl.$t({ id: "ZONE" }),
      columnId: "zone.name",
      columnType: COLUMN_TYPE.Zone,
    },
    {
      header: intl.$t({ id: "NOTES" }),
      columnId: "notes",
      columnType: COLUMN_TYPE.Notes,
    },
    {
      header: intl.$t({ id: "TAX" }),
      columnId: "taxable",
      columnType: COLUMN_TYPE.Taxable,
    },
  ];
};

import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useRfqMutations } from "@/contractor/pages/home/rfq/hooks/useRfqMutations";
import {
  QuoteStatus,
  RfqQuotesAuxiliaryQuoteItemFieldsFragment,
  RfqQuotesFieldsFragment,
  RfqQuotesQuoteItemFieldsFragment,
  RfqStatus,
  useRfqQuotesQuery,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router";

export type QuoteItem = {
  rfqItemId: string;
  quoteId: string;
  quoteItemId: string;
};

export type AuxQuoteItem = {
  quoteId: string;
  itemId: string;
};

export type ExpandedQuoteItem = RfqQuotesQuoteItemFieldsFragment & {
  quoteId: string;
  rfqItemId: string;
};

export type ExpandedAuxiliaryQuoteItem =
  RfqQuotesAuxiliaryQuoteItemFieldsFragment & {
    quoteId: string;
  };

type RfqQuotesProviderContextType = {
  rfq?: RfqQuotesFieldsFragment | null;
  isOpen: boolean;
  toggleOpen: () => void;
  hoverQuoteId: string | null;
  setHoverQuoteId: (quoteId: string | null) => void;
  selectedQuotes: QuoteItem[];
  setSelectedQuotes: (quotes: QuoteItem[]) => void;
  selectedAuxiliaryQuoteItems: AuxQuoteItem[];
  setSelectedAuxiliaryQuoteItems: (quotes: AuxQuoteItem[]) => void;
  multiQuote: boolean;
  setMultiQuote: (multiQuote: boolean) => void;
  cancelQuote: (rfqId: string) => Promise<boolean>;
  createDraftQuote: (
    rfqId: string,
  ) => Promise<undefined | { id: string; clientIdentifier: string }>;
  hasMultipleQuotes: boolean;
  loading: boolean;
  allQuoteItems: ExpandedQuoteItem[];
  auxiliaryItems: ExpandedAuxiliaryQuoteItem[];
};

const RfqQuotesProviderContext = createContext<RfqQuotesProviderContextType>({
  isOpen: false,
  toggleOpen: NoFunction,
  hoverQuoteId: null,
  setHoverQuoteId: NoFunction,
  selectedQuotes: [],
  setSelectedQuotes: NoFunction,
  selectedAuxiliaryQuoteItems: [],
  setSelectedAuxiliaryQuoteItems: NoFunction,
  multiQuote: false,
  setMultiQuote: NoFunction,
  cancelQuote: NoFunctionBooleanPromise,
  createDraftQuote: NoFunctionUndefinedPromise,
  hasMultipleQuotes: false,
  loading: false,
  allQuoteItems: [],
  auxiliaryItems: [],
});

const VISIBLE_QUOTE_STATUSES = [
  QuoteStatus.Submitted,
  QuoteStatus.Rejected,
  QuoteStatus.Accepted,
];

export const OrderQuotesProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { id } = useParams();
  const { data, loading, error } = useRfqQuotesQuery({
    variables: {
      id: id || "",
      quoteStatus: VISIBLE_QUOTE_STATUSES,
    },
    fetchPolicy: "cache-and-network",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedQuotes, setSelectedQuotesFn] = useState<QuoteItem[]>([]);
  const [selectedAuxiliaryQuoteItems, setSelectedAuxiliaryQuoteItems] =
    useState<AuxQuoteItem[]>([]);
  const [hoverQuoteId, setHoverQuoteId] = useState<string | null>(null);
  const [multiQuote, setMultiQuote] = useState<boolean>(false);
  const { cancelRfqMutation, createRfqDraftMutation } = useRfqMutations();

  const toggleOpen = useCallback(() => {
    setIsOpen((oldValue) => !oldValue);
  }, []);

  const setHoverQuoteIdCallback = useCallback((quoteId: string | null) => {
    setHoverQuoteId(quoteId);
  }, []);

  const setMultiQuoteCallback = useCallback((value: boolean) => {
    setMultiQuote(value);
  }, []);

  const setSelectedQuotesCallback = useCallback(
    (quotes: QuoteItem[]) => {
      if (data?.rfq?.status !== RfqStatus.Awarded) {
        setSelectedQuotesFn(quotes.filter((quote) => quote.quoteItemId));
      } else {
        setSelectedQuotesFn(quotes);
      }
    },
    [data?.rfq?.status],
  );

  const setSelectedAuxiliaryQuoteItemsCallback = useCallback(
    (quotes: AuxQuoteItem[]) => {
      setSelectedAuxiliaryQuoteItems(quotes);
    },
    [],
  );

  const allQuoteItems = useMemo(() => {
    return (
      data?.rfq?.quotes
        .map((quote) =>
          quote.itemGroups.map((group) =>
            group.quoteItems.map((item) => ({
              ...item,
              quoteId: quote.id,
              rfqItemId: group.rfqItem.id,
            })),
          ),
        )
        .flat(3) || []
    );
  }, [data?.rfq?.quotes]);

  const auxiliaryItems = useMemo(() => {
    return (
      data?.rfq?.quotes
        .map((quote) =>
          quote.auxiliaryItems.map((item) => ({
            ...item,
            quoteId: quote.id,
          })),
        )
        .flat(3) || []
    );
  }, [data?.rfq?.quotes]);

  const hasMultipleQuotes = useMemo(() => {
    return !!data?.rfq?.quotes && data?.rfq?.quotes.length > 1;
  }, [data?.rfq?.quotes]);

  useErrorEffect(error);

  useEffect(() => {
    if (data?.rfq?.status === RfqStatus.Awarded) {
      const acceptedQuotes = data.rfq.quotes.filter(
        (quote) => quote.status === QuoteStatus.Accepted,
      );
      const acceptedItems = acceptedQuotes
        .map((quote) => {
          return quote?.itemGroups.map((group) =>
            group.quoteItems
              .filter((item) => item.status === QuoteStatus.Accepted)
              .map((item) => ({
                rfqItemId: group.rfqItem.id,
                quoteId: quote.id,
                quoteItemId: item.id,
              })),
          );
        })
        .flat(3);
      setSelectedQuotesFn(acceptedItems);
      setMultiQuote(true);
    }
  }, [data]);

  return (
    <RfqQuotesProviderContext.Provider
      value={{
        rfq: data?.rfq ?? null,
        isOpen,
        toggleOpen,
        cancelQuote: cancelRfqMutation,
        createDraftQuote: createRfqDraftMutation,
        hoverQuoteId,
        setHoverQuoteId: setHoverQuoteIdCallback,
        selectedQuotes,
        setSelectedQuotes: setSelectedQuotesCallback,
        selectedAuxiliaryQuoteItems,
        setSelectedAuxiliaryQuoteItems: setSelectedAuxiliaryQuoteItemsCallback,
        multiQuote,
        setMultiQuote: setMultiQuoteCallback,
        hasMultipleQuotes,
        allQuoteItems,
        auxiliaryItems,
        loading,
      }}
    >
      {children}
    </RfqQuotesProviderContext.Provider>
  );
};

export const useRfqQuotes = (): RfqQuotesProviderContextType =>
  useContext(RfqQuotesProviderContext);

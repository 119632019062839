import { useUser } from "@/common/providers/UserProvider";
import {
  EnterpriseRole,
  OrgLocationRole,
  OrgRole,
  SystemRole,
} from "@/generated/graphql";
import { useMemo } from "react";

type Roles = {
  roles?: OrgRole[];
  enterpriseRoles?: EnterpriseRole[];
  locationRoles?: OrgLocationRole[];
  systemRoles?: SystemRole[];
};

export const useRoles = ({
  roles,
  enterpriseRoles,
  locationRoles,
  systemRoles,
}: Roles) => {
  const { viewer } = useUser();
  const hasRoles = useMemo(
    () => viewer?.orgRoles?.some((o) => roles?.some((p) => o === p)),
    [roles, viewer?.orgRoles],
  );

  const hasEnterpriseRoles = useMemo(
    () =>
      viewer?.enterpriseRoles?.some((o) =>
        enterpriseRoles?.some((p) => o === p),
      ),
    [enterpriseRoles, viewer?.enterpriseRoles],
  );

  const hasLocationRoles = useMemo(
    () =>
      viewer?.locationRoles?.some((o) =>
        locationRoles?.some((p) => o.role === p),
      ),
    [locationRoles, viewer?.locationRoles],
  );

  const hasSystemRoles = useMemo(
    () => viewer?.systemRoles?.some((o) => systemRoles?.some((p) => o === p)),
    [systemRoles, viewer?.systemRoles],
  );

  return { hasRoles, hasEnterpriseRoles, hasLocationRoles, hasSystemRoles };
};

import { If } from "@/common/components/if/If";
import {
  InvoiceDocumentType,
  InvoiceIssue,
  InvoiceIssueType,
} from "@/common/components/invoices/invoice-issue/common/InvoiceIssue";
import { useUser } from "@/common/providers/UserProvider";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { getUserName } from "@/common/utils/users/getUserName";
import { useInvoicePermissions } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoicePermissions";
import { InvoiceKickbackFieldsFragment } from "@/generated/graphql";
import { FC, useEffect } from "react";

type Props = {
  kickbacks: InvoiceKickbackFieldsFragment[];
  invoiceId?: string;
  refetchInvoice?: () => Promise<void>;
  documentType?: InvoiceDocumentType;
  projectId?: string;
  archivedAt?: number | null | undefined;
};

export const InvoiceKickbacks: FC<Props> = ({
  kickbacks,
  invoiceId,
  projectId,
  documentType,
  archivedAt,
}) => {
  const { viewer } = useUser();
  const { fetchInvoicePermissions, permissions } = useInvoicePermissions();

  useEffect(() => {
    if (invoiceId) {
      fetchInvoicePermissions(invoiceId, projectId);
    }
  }, [fetchInvoicePermissions, invoiceId, projectId]);

  return (
    <If isTrue={kickbacks.length > 0}>
      {kickbacks.map((kickback, key) => (
        <InvoiceIssue
          key={key}
          index={key + 1}
          id={kickback.id}
          invoiceId={invoiceId}
          documentType={documentType}
          type={InvoiceIssueType.KICKBACK}
          issueDescription={kickback.description}
          issueDate={kickback.createdAt}
          resolutionDescription={kickback.resolutionDescription}
          issueResponseDate={kickback.resolvedAt}
          rejectionReason={undefined}
          responseBy={getUserName(kickback.resolvedBy)}
          issueBy={getUserName(kickback.reportedBy)}
          assignees={kickback.assignees}
          userCanRespond={isAuthorized(permissions?.respond)}
          canEdit={
            !kickback.resolvedAt && kickback.reportedBy.id === viewer?.id
          }
          assets={kickback.assets}
          resolutionAssets={kickback.resolutionAssets}
          comments={kickback.comments}
          invoiceDeleted={!!archivedAt}
        />
      ))}
    </If>
  );
};

import { LoaderStyled } from "@/common/components/grid-table/GridTable.styles";
import { HeaderContainerWithSecondaryNavigation } from "@/common/components/header-container/HeaderContainer";
import {
  LocalPaginationProvider,
  useLocalPagination,
} from "@/common/components/pagination-local/LocalPaginationProvider";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { useLocalSearch } from "@/common/hooks/useLocalSearch";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useOrgSettings } from "../../../org-settings/hooks/useOrgSettings";
import { useOrgSettingsExtended } from "../../../org-settings/hooks/useOrgSettingsExtended";
import { ComplianceGroupList } from "./components/ComplianceGroupList";
import { ImportExternalComplianceGroupsButton } from "./components/ImportExternalComplianceGroupsButton";
import { ImportExternalComplianceGroupsModal } from "./components/import-external-compliance-groups/ImportExternalComplianceGroupsModal";
import { useComplianceGroups } from "./hooks/useComplianceGroups";
import { ImportExternalComplianceGroupsProvider } from "./providers/ImportExternalComplianceGroupsProvider";

const ITEMS_PER_PAGE = 24;

const HeaderRightPanel = tw.div`flex flex-row`;
const Filters = tw.div`
  grid grid-flow-col gap-5 items-start
`;
const Spacer = tw.div`h-12`;

const ComplianceGroupsWithProvider = () => {
  const intl = useIntl();
  const { complianceGroups } = useComplianceGroups();

  const { loading } = useOrgSettings();
  const { setSearchText, filteredItems, searchText } =
    useLocalSearch(complianceGroups);
  const { getPaginatedItems, setPage } = useLocalPagination();

  const currentComplianceGroups = useMemo(() => {
    if (searchText) {
      return getPaginatedItems([...filteredItems]);
    }
    return getPaginatedItems(filteredItems);
  }, [filteredItems, getPaginatedItems, searchText]);

  const setSearchTextAndResetPage = useCallback(
    (text: string) => {
      setSearchText(text);
      setPage(0);
    },
    [setPage, setSearchText],
  );

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "COMPLIANCE_GROUPS" })}</title>
      </Helmet>
      <LoaderStyled loading={loading}>
        <HeaderContainerWithSecondaryNavigation>
          <Filters>
            <SearchInput
              placeHolder={intl.$t({ id: "SEARCH_COMPLIANCE_GROUP" })}
              className="w-64"
              onChange={setSearchTextAndResetPage}
            />
          </Filters>
          <HeaderRightPanel>
            <ImportExternalComplianceGroupsButton />
          </HeaderRightPanel>
        </HeaderContainerWithSecondaryNavigation>
        <ComplianceGroupList
          items={currentComplianceGroups}
          totalCount={filteredItems.length}
          loading={loading}
        />
        <Spacer />
        <ImportExternalComplianceGroupsModal />
      </LoaderStyled>
    </>
  );
};

export const ComplianceGroups = () => {
  const { connectedSourceSystem } = useOrgSettingsExtended();
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.normal);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  return (
    <NestedStepperProvider>
      <ImportExternalComplianceGroupsProvider
        defaultSourceSystem={connectedSourceSystem?.system}
      >
        <LocalPaginationProvider itemsPerPage={ITEMS_PER_PAGE}>
          <ComplianceGroupsWithProvider />
        </LocalPaginationProvider>
      </ImportExternalComplianceGroupsProvider>
    </NestedStepperProvider>
  );
};

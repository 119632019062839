import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { isNumber } from "lodash";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../../value-currency/hooks/useFormatNumberToCurrency";
import { useTableHelpers } from "../../hooks/useTableHelpers";
import { getPhysicalColumnIndex } from "../../utils/getPhysicalColumnIndex";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { useRenderHelpers } from "../helpers/useRenderHelpers";
import { applyTooltip } from "../utils/applyTooltip";
import { isValueEmpty } from "../utils/isValueEmpty";

export const useUnitPriceRenderer = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { getBuyoutItem } = useTableHelpers();
  const { formatPrice, addIconWithTooltip } = useRenderHelpers();
  const baseRenderer = useBaseRenderer();

  const renderExtendedPrice = useCallback(
    (td: HTMLElement, extendedPrice: number): void => {
      if (extendedPrice && isNumber(extendedPrice)) {
        const extPriceEl = document.createElement("div");
        extPriceEl.className = "text-gray-500 text-3xs select-none mb-1";
        const currencyFormattedValue = formatCurrency(extendedPrice);
        extPriceEl.innerText = intl.$t(
          { id: "EXT_WITH_PRICE" },
          { price: currencyFormattedValue },
        );
        td.appendChild(extPriceEl);
        applyTooltip(
          extPriceEl,
          intl.$t(
            { id: "EXT_PRICE_TOOLTIP" },
            { price: currencyFormattedValue },
          ),
        );
      }
    },
    [intl, formatCurrency],
  );

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      if (isValueEmpty(value)) {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
        return;
      }
      const rowData = instance.getDataAtRow(row);
      const formattedValue = formatPrice(rowData, instance, value);
      baseRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      const buyoutItemIdIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.BuyoutItemId,
      );
      const materialIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Material,
      );
      const uomIndex = getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM);
      const buyoutItem = getBuyoutItem({
        id: rowData[buyoutItemIdIndex],
        material: rowData[materialIndex],
        uom: rowData[uomIndex],
      });

      if (buyoutItem) {
        addIconWithTooltip({
          element: td,
          tooltipText: intl.$t({ id: "BUYOUT_ITEM_PRICE_NOTICE" }),
          iconClasses: "text-green-800 hover:text-green-600",
        });
      }

      const extPriceColumnIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.ExtPrice,
      );
      if (extPriceColumnIndex !== -1) {
        return;
      }
      const quantityIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Quantity,
      );
      const priceIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.UnitPrice,
      );
      const extendedPrice =
        quantityIndex !== -1 && priceIndex !== -1
          ? rowData[quantityIndex] * rowData[priceIndex]
          : 0;
      if (extendedPrice) {
        renderExtendedPrice(td, extendedPrice);
      }
    },
    [
      baseRenderer,
      formatPrice,
      getBuyoutItem,
      addIconWithTooltip,
      intl,
      renderExtendedPrice,
    ],
  );

  return renderer;
};

import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import {
  ReleaseAdditionalChargesInputFormContext,
  useReleaseAdditionalChargesInput,
} from "@/common/components/release-additional-charges-and-taxes/hooks/useReleaseAdditionalChargesInput";
import { SpecifyCostCodeAndZoneExpandable } from "@/common/components/specify-cost-code/SpecifyCostCodeExpandable";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { useInstruction } from "@/common/hooks/useInsruction";
import { TotalProps } from "@/common/hooks/usePriceCalculation";
import { routes } from "@/config/routes";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { ReleaseDescription } from "@/contractor/pages/home/release/components/ReleaseDescription";
import { useFormPriceCalculation } from "@/contractor/pages/home/release/hooks/useFormPriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AdditionalChargesFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useDeliverySlipRelease } from "../../../../providers/DeliverySlipReleaseProvider";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipReleaseItems } from "../../components/delivery-slip-release-items/DeliverySlipReleaseItems";
import { useDeliverySlipEditReleaseSpreadsheetConfig } from "./DeliverySlipEditRelease.config";
import { DeliverySlipEditReleaseHeader } from "./DeliverySlipEditReleaseHeader";
import { DeliverySlipReceiveFooter } from "./DeliverySlipReceiveFooter";
import { DeliverySlipReceiveHeader } from "./DeliverySlipReceiveHeader";

const Container = tw.div`bg-gray-100 rounded-3xl pt-6 h-full overflow-y-scroll`;
const InnerContainer = tw.div`flex flex-col px-2.5 pb-4  mb-20`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;
const Footer = tw.div`mr-7 mt-10 w-full pb-5`;

export const DeliverySlipReceiveOrderDetails: FC = () => {
  const { release, loading } = useRelease();
  const { spreadsheetData } = useColumnMapper();
  const { calcTableTotal } = useTableHelpers();
  const { setGlobalVendorId } = useVendorPrices();
  const { updating, updateReleaseForm: form } = useDeliverySlipRelease();
  const { packingSlipReceiveViewState } = useDeliverySlipVerification();
  const spreadsheetViewColumns = useDeliverySlipEditReleaseSpreadsheetConfig();
  const { watch, setValue, getValues } = form;
  const { saveDescription, saveInstruction } = useInstruction();
  const setGlobalProjectId = useProjectStore(
    (state) => state.setCurrentProjectId,
  );

  const customTaxAmount = watch("customTaxAmount");
  const netAmount = watch("netAmount");
  const vendorId = watch("vendorId");

  const projectId = watch("projectId");

  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
    if (projectId) {
      setGlobalProjectId(projectId);
    }
  }, [setGlobalVendorId, vendorId, setGlobalProjectId, projectId]);

  const releaseItems = useMemo(
    () =>
      release?.items
        .toSorted((a, b) => (a.position || 0) - (b.position || 0))
        .map((item) => ({
          ...item,
          phaseCode: item.tags[0]?.name,
        })) ?? [],
    [release?.items],
  );

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (values.additionalCharges) {
        setValue("additionalCharges", values.additionalCharges);
      }
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue(
          "customTaxAmount",
          values.customTaxAmount || release?.taxAmount || "0",
        );
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }
      if (values.taxVariance || values.clearTaxVariance) {
        setValue(
          "taxVariance",
          values.clearTaxVariance ? undefined : values.taxVariance,
        );
      }
      return true;
    },
    [customTaxAmount, release?.taxAmount, setValue],
  );

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== netAmount) {
        setValue("netAmount", newTotal);
      }
      const taxableNetAmount = `${calcTableTotal(data, { taxable: true })}`;
      if (taxableNetAmount !== getValues("taxableNetAmount")) {
        setValue("taxableNetAmount", taxableNetAmount);
      }
    },
    [calcTableTotal, getValues, netAmount, setValue],
  );

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  const { releaseInput } = useReleaseAdditionalChargesInput({
    form: form as unknown as UseFormReturn<
      ReleaseAdditionalChargesInputFormContext,
      unknown,
      ReleaseAdditionalChargesInputFormContext
    >,
  });
  const { total } = useFormPriceCalculation({
    form: form as unknown as UseFormReturn<TotalProps, unknown, TotalProps>,
  });

  return (
    <Container>
      <InnerContainer>
        <If
          isTrue={
            packingSlipReceiveViewState ===
            PackingSlipReceiveViewState.EDIT_ORDER
          }
        >
          <DeliverySlipEditReleaseHeader />
          <SpreadSheetView>
            <SpreadSheetTable
              items={releaseItems}
              columns={spreadsheetViewColumns}
              saving={loading || updating}
              height="450px"
              onChanges={updateSubtotal}
              pageId={routes.deliverySlipVerification}
            />
          </SpreadSheetView>
          <SpecifyCostCodeAndZoneExpandable
            costCode={release?.costCode}
            zone={release?.zone}
          />
          <Footer>
            <ReleaseAdditionalChargesAndTaxes
              total={`${total}`}
              releaseInput={releaseInput}
              customPaymentTerm={form?.watch("paymentTerm")}
              editableByContractor
              updateRelease={(values) =>
                handleUpdateRelease({ ...values, version: -1 })
              }
              includePaymentTerms
              taxExempt={{
                isProjectTaxExempt: release?.project?.taxExempt,
                isVendorTaxExempt: release?.preferredVendor?.taxExempt,
                vendorName: vendorLabelFormatter(release?.sellerOrgLocation),
              }}
              additionalCharges={
                form?.watch(
                  "additionalCharges",
                ) as AdditionalChargesFieldsFragment[]
              }
            />
            <Instructions
              projectId={projectId}
              instruction={release?.instructions}
              saveInstruction={saveInstruction}
            >
              <ReleaseDescription
                release={release}
                directlyEditable
                saveDescriptionCustomMethod={saveDescription}
              />
            </Instructions>
          </Footer>
        </If>
        <If
          isTrue={
            packingSlipReceiveViewState !==
            PackingSlipReceiveViewState.EDIT_ORDER
          }
        >
          <DeliverySlipReceiveHeader />
          <DeliverySlipReleaseItems />
        </If>
      </InnerContainer>
      <DeliverySlipReceiveFooter />
    </Container>
  );
};

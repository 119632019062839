import { Select } from "@/common/components/select/components/single/Select";
import { InputBaseComponentProps } from "@mui/material";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { GroupRenderer } from "../select/components/renderer/GroupRenderer";
import { WarehouseSelectorCustomRenderer } from "./renderer/WarehouseSelectorCustomRenderer";
import { WarehouseSelectorCustomRendererWithIcon } from "./renderer/WarehouseSelectorCustomRendererWithIcon";
import {
  PROJECT_ADDRESS_ID,
  useWarehouseOptions,
  WarehouseOptionsProps,
} from "./useWarehouseOptions";

type Props = {
  value: string | null | undefined;
  onChange: (value: string | null) => void;
  readonly?: boolean;
  inputProps?: InputBaseComponentProps | undefined;
  disableClearable?: boolean;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
  includeDetails?: boolean;
  disabled?: boolean;
  hideGroup?: boolean;
} & WarehouseOptionsProps;

export const WarehouseSelector: FC<Props> = ({
  value,
  onChange,
  projectAddress,
  includeWarehouseInName = false,
  showAllWarehousesOption = false,
  readonly = false,
  inputProps,
  disableClearable,
  placeholder,
  required = true,
  loading = false,
  includeDetails = false,
  hideGroup,
  allWarehousesLabel,
}) => {
  const intl = useIntl();
  const formattedValue = useMemo(
    () => (!value ? PROJECT_ADDRESS_ID : value),
    [value],
  );

  const { warehouseOptions } = useWarehouseOptions({
    projectAddress,
    existingWarehouseId: value,
    includeWarehouseInName,
    showAllWarehousesOption,
    allWarehousesLabel,
  });
  const warehouse = useMemo(
    () => warehouseOptions.find((option) => option.id === value),
    [value, warehouseOptions],
  );

  return (
    <Select
      options={warehouseOptions}
      placeholder={placeholder ?? intl.$t({ id: "DELIVER_TO" })}
      value={formattedValue}
      inputProps={inputProps}
      onChange={onChange}
      getLabel={(option) => option.name}
      getValue={(option) => option.id}
      InputProps={{ className: warehouse?.archivedAt ? "text-gray-400" : "" }}
      required={required}
      disabled={readonly}
      disableClearable={disableClearable}
      loading={loading}
      customRender={(item) =>
        WarehouseSelectorCustomRenderer(item, {
          includeDetails,
        })
      }
      groupBy={!hideGroup ? (option) => option.type as string : undefined}
      renderGroup={GroupRenderer}
      shrink={!!warehouse}
      selectedOptionCustomView={
        warehouse && (
          <WarehouseSelectorCustomRendererWithIcon item={warehouse} />
        )
      }
    />
  );
};

import { isWarehouseRelease } from "@/contractor/pages/home/release/utils/isWarehouseRelease";
import {
  EnterpriseRole,
  OrgRole,
  SystemRole,
  Warehouse,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useRoles } from "../../org-roles-wrapper/hasRoles";

export const useIsWarehouseManager = () => {
  const {
    hasRoles: hasWarehouseManagerRole,
    hasEnterpriseRoles: isEnterpriseAdmin,
    hasSystemRoles: isFmAdmin,
  } = useRoles({
    roles: [OrgRole.OrgWarehouseManager, OrgRole.OrgAdmin],
    enterpriseRoles: [EnterpriseRole.EnterpriseAdmin],
    systemRoles: [SystemRole.FmAdmin],
  });

  const canConfirmWarehouseRelease = useCallback(
    (
      release?: {
        sourceWarehouse?: Pick<Warehouse, "id"> | null;
      } | null,
    ) =>
      !!(hasWarehouseManagerRole || isEnterpriseAdmin) &&
      isWarehouseRelease(release),
    [hasWarehouseManagerRole, isEnterpriseAdmin],
  );

  const isWarehouseManager = useMemo(
    () => isEnterpriseAdmin || hasWarehouseManagerRole || isFmAdmin,
    [isEnterpriseAdmin, hasWarehouseManagerRole, isFmAdmin],
  );

  return { isWarehouseManager, canConfirmWarehouseRelease };
};

import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { RfqStatus } from "@/generated/graphql";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ButtonContainer } from "../../buyout/Buyout.styles";
import RfqVendorsInfo from "../../rfq-master-catalog-import/components/RfqVendorsInfo";
import { ButtonsContainerGroup } from "../Rfq.styles";
import { useRfq } from "../hooks/useRfq";
import { CancelRfqButton } from "./buttons/CancelRfqButton";
import { RequestChangesButton } from "./buttons/RequestChangesButton";

const StyledButtonContainer = tw(ButtonContainer)`gap-5`;

const CustomInnerFloatingFooter = tw.div` flex content-end justify-end gap-7`;

const RfqFooter = () => {
  const { rfq, cancelRfq, createRfqDraft, loading } = useRfq();
  const intl = useIntl();

  if (loading || !rfq || rfq.deletedAt) {
    return null;
  }

  return (
    <FloatingFooter>
      <CustomInnerFloatingFooter>
        <StyledButtonContainer>
          <If
            isTrue={
              rfq.status === RfqStatus.Active &&
              isAuthorized(rfq.permissions.submit)
            }
          >
            <RfqVendorsInfo
              drawerTitle={intl.$t({
                id: "SEND_RFQ_TO_ANOTHER_VENDOR_S",
              })}
              drawerFilterExistingVendors
              drawerSaveLabel={intl.$t({
                id: "SEND_TO_VENDOR_S",
              })}
            />
          </If>

          <ButtonsContainerGroup>
            <If
              isTrue={
                (rfq.status === RfqStatus.Active ||
                  rfq.status === RfqStatus.Awarded) &&
                !rfq.deletedAt &&
                isAuthorized(rfq.permissions.archive)
              }
            >
              <CancelRfqButton cancelRfq={cancelRfq} rfq={rfq} />
            </If>
            <If
              isTrue={
                rfq.status === RfqStatus.Active &&
                !rfq.deletedAt &&
                isAuthorized(rfq.permissions.edit)
              }
            >
              <RequestChangesButton rfq={rfq} createRfqDraft={createRfqDraft} />
            </If>
          </ButtonsContainerGroup>
        </StyledButtonContainer>
      </CustomInnerFloatingFooter>
    </FloatingFooter>
  );
};

export default RfqFooter;

import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  namedOperations,
  ReleaseDocument,
  useUnarchiveReleasesMutation,
} from "@/generated/graphql";

export const useUnarchiveRelease = () => {
  const { setError } = useGlobalError();
  const [unarchiveReleasesMutation, { loading: unarchiving }] =
    useUnarchiveReleasesMutation({
      update: (cache) => cleanQuery(cache, namedOperations.Query.Releases),
    });
  const unarchiveRelease = async (id: string) => {
    try {
      const { errors } = await unarchiveReleasesMutation({
        variables: {
          ids: [id],
        },
        refetchQueries: [
          {
            query: ReleaseDocument,
            variables: { id },
          },
        ],
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    unarchiveRelease,
    unarchiving,
  };
};

import { If } from "@/common/components/if/If";
import { DateView } from "@/common/utils/dates/DateView";
import {
  TransactionHistoryItemContainer,
  TransactionHistoryItemDetails,
} from "../../transaction-history-steps/transaction-history-stock/components/transaction-history-list/TransactionHistory.styles";

type Props = {
  date: number | null | undefined;
  includeTime?: boolean;
  className?: string;
};

export const TransactionHistoryListViewDateRenderer = ({
  date,
  includeTime = true,
  className,
}: Props) => {
  return (
    <TransactionHistoryItemContainer className={className}>
      <DateView date={date} />
      <If isTrue={includeTime}>
        <TransactionHistoryItemDetails>
          <DateView date={date} onlyTime />
        </TransactionHistoryItemDetails>
      </If>
    </TransactionHistoryItemContainer>
  );
};

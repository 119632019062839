import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { Tabs } from "@/common/components/tabs/Tabs";
import { TextField } from "@/common/components/textfield/TextField";
import { useTemplateStringsStore } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/template-strings/store/useTemplateStringsStore";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { SimpleStepContainer } from "../../../Wizard.styles";
import { CustomTemplates } from "./components/custom-templates/CustomTemplates";
import { DefaultTemplates } from "./components/default-templates/DefaultTemplates";
import { TemplateHeader } from "./components/template-header/TemplateHeader";
import { TemplateCustomVariables } from "./components/template-variables/TemplateCustomVariables";
import { TemplateVariables } from "./components/template-variables/TemplateVariables";
import { TemplateTypeEnum } from "./enums/TemplateType.enum";
import { TabType, useTemplateStrings } from "./hooks/useTemplateStrings";

const Container = tw.div`grid grid-cols-[500px_auto] gap-4 mt-2`;
const Content = tw.div`w-full`;
const CustomTabs = tw(
  Tabs,
)`-mt-3 top-0 mb-4 overflow-visible border-b border-gray-200 pt-3 xl:-mx-60 xl:px-60 shadow-[0_4px_4px_-4px_rgba(0,0,0,0.1)]`;

export const TemplateStrings: FC = () => {
  const { openDialog, cancelDialog } = useDialog();
  const intl = useIntl();
  const { activeTab, tabs } = useTemplateStrings();

  const {
    templates,
    setTemplate,
    customPOTemplates,
    customInvoiceTemplates,
    addCustomPOTemplates,
    addCustomInvoiceTemplates,
    setCustomPOTemplates,
    setCustomInvoiceTemplates,
    updateNewTemplateName,
  } = useTemplateStringsStore(
    useShallow((state) => ({
      templates: state.templates,
      setTemplate: state.setTemplate,
      customPOTemplates: state.customPOTemplates,
      customInvoiceTemplates: state.customInvoiceTemplates,
      addCustomPOTemplates: state.addCustomPOTemplates,
      addCustomInvoiceTemplates: state.addCustomInvoiceTemplates,
      setCustomPOTemplates: state.setCustomPOTemplates,
      setCustomInvoiceTemplates: state.setCustomInvoiceTemplates,
      updateNewTemplateName: state.updateNewTemplateName,
    })),
  );

  const handleAddTemplate = useCallback(
    (templateType: TemplateTypeEnum) => {
      openDialog({
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        title: intl.$t({ id: "ENTER_NEW_TEMPLATE_NAME" }),
        handleConfirm: () => {
          if (templateType === TemplateTypeEnum.PO) {
            addCustomPOTemplates();
          } else {
            addCustomInvoiceTemplates();
          }
          cancelDialog?.();
        },
        content: (
          <TextField
            className="mt-2"
            placeholder={intl.$t({ id: "TEMPLATE_NAME" })}
            onChange={(e) => updateNewTemplateName(e.target.value)}
            autoFocus
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                if (templateType === TemplateTypeEnum.PO) {
                  addCustomPOTemplates();
                } else {
                  addCustomInvoiceTemplates();
                }
                cancelDialog?.();
              }
            }}
          />
        ),
      });
    },
    [
      addCustomInvoiceTemplates,
      addCustomPOTemplates,
      cancelDialog,
      intl,
      openDialog,
      updateNewTemplateName,
    ],
  );

  return (
    <SimpleStepContainer className="justify-start">
      <TemplateHeader />
      <If isTrue={tabs.length > 1}>
        <CustomTabs
          tabs={tabs}
          findIndex={(tab) => activeTab === tab.id}
          type="secondary"
        />
      </If>
      <Container>
        <Content>
          <If isTrue={activeTab === TabType.Templates}>
            <DefaultTemplates templates={templates} setTemplate={setTemplate} />
          </If>
          <If isTrue={activeTab === TabType.CustomPO}>
            <CustomTemplates
              templates={customPOTemplates}
              setTemplates={setCustomPOTemplates}
              onAddTemplate={() => handleAddTemplate(TemplateTypeEnum.PO)}
            />
          </If>
          <If isTrue={activeTab === TabType.CustomInvoice}>
            <CustomTemplates
              templates={customInvoiceTemplates}
              setTemplates={setCustomInvoiceTemplates}
              onAddTemplate={() => handleAddTemplate(TemplateTypeEnum.INVOICE)}
            />
          </If>
        </Content>
        <Content>
          <If isTrue={activeTab === TabType.Templates}>
            <TemplateVariables />
          </If>
          <If
            isTrue={
              activeTab === TabType.CustomPO ||
              activeTab === TabType.CustomInvoice
            }
          >
            <TemplateCustomVariables
              templateType={
                activeTab === TabType.CustomPO
                  ? TemplateTypeEnum.PO
                  : TemplateTypeEnum.INVOICE
              }
            />
          </If>
        </Content>
      </Container>
    </SimpleStepContainer>
  );
};

import {
  DEFAULT_SOURCES,
  DEFAULT_SOURCES_WITH_CUSTOM,
} from "@/common/components/spreadsheet-table/constants/tableConstants";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { getCellValueWithPrefill } from "@/common/components/spreadsheet-table/utils/getCellValueWithPrefill";
import { setPrefillCell } from "@/common/components/spreadsheet-table/utils/setPrefillCell";
import {
  extPriceCalculation,
  usePriceCalculation,
} from "@/common/hooks/usePriceCalculation";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { getInventoryItemUnitPrice } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemUnitPrice";
import Handsontable from "handsontable";
import { CellChange, ChangeSource } from "handsontable/common";
import { useCallback } from "react";
import { useInventoryHelpers } from "../../../../../../admin/inventory-items/hooks/useInventoryHelpers";

export const useInventoryTransferTableHelpers = () => {
  const { calcExtPrice } = usePriceCalculation();
  const findMaterialByName = useFindMaterialByName();
  const { findInventoryItemByNameAndUom } = useInventoryHelpers();

  const prefillStock = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hotInstance || !DEFAULT_SOURCES_WITH_CUSTOM.includes(source)) {
        return;
      }

      changes?.forEach((change) => {
        const [row, column, , value] = change;

        if (column !== COLUMN_TYPE.Material) {
          return;
        }

        if (value) {
          const uom = getCellValueWithPrefill(
            hotInstance,
            row,
            COLUMN_TYPE.UOM,
          );
          const inventoryItem = findInventoryItemByNameAndUom(value, uom);
          if (!inventoryItem) {
            const material = findMaterialByName(value);
            const defaultEstimateUom =
              material?.defaultEstimateUom.pluralDescription ?? "";
            setPrefillCell(
              hotInstance,
              row,
              COLUMN_TYPE.UOM,
              defaultEstimateUom,
            );
            setPrefillCell(hotInstance, row, COLUMN_TYPE.ExistingStock, "0");
            return;
          }

          const remainingQuantity =
            getInventoryItemRemainingQuantity(inventoryItem).toString();
          setPrefillCell(
            hotInstance,
            row,
            COLUMN_TYPE.ExistingStock,
            remainingQuantity,
          );
          setPrefillCell(
            hotInstance,
            row,
            COLUMN_TYPE.UOM,
            inventoryItem.uom.pluralDescription,
          );

          const quantity = Number(
            getCellValueWithPrefill(hotInstance, row, COLUMN_TYPE.Quantity),
          );
          if (quantity) {
            const unitPrice = getInventoryItemUnitPrice(
              inventoryItem,
              -quantity,
            );
            const extPrice = extPriceCalculation(unitPrice, quantity);
            setPrefillCell(hotInstance, row, COLUMN_TYPE.UnitPrice, unitPrice);
            setPrefillCell(hotInstance, row, COLUMN_TYPE.ExtPrice, extPrice);
          }
        } else {
          setPrefillCell(hotInstance, row, COLUMN_TYPE.UOM, null);
          setPrefillCell(hotInstance, row, COLUMN_TYPE.ExistingStock, null);
          setPrefillCell(hotInstance, row, COLUMN_TYPE.UnitPrice, null);
          setPrefillCell(hotInstance, row, COLUMN_TYPE.ExtPrice, null);
        }
      });
    },
    [findInventoryItemByNameAndUom, findMaterialByName],
  );

  const prefillTransfer = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hotInstance || !DEFAULT_SOURCES.includes(source)) {
        return;
      }

      changes?.forEach((change) => {
        const [row, column, , value] = change;

        if (column !== COLUMN_TYPE.Quantity) {
          return;
        }

        const material = getCellValueWithPrefill(
          hotInstance,
          row,
          COLUMN_TYPE.Material,
        );
        const uom = getCellValueWithPrefill(hotInstance, row, COLUMN_TYPE.UOM);
        const inventoryItem = findInventoryItemByNameAndUom(material, uom);

        if (!inventoryItem) {
          setPrefillCell(
            hotInstance,
            row,
            COLUMN_TYPE.NewStock,
            value?.toString(),
          );
          setPrefillCell(hotInstance, row, COLUMN_TYPE.UnitPrice, null);
          setPrefillCell(hotInstance, row, COLUMN_TYPE.ExtPrice, null);
          return;
        }

        const quantity = Number(value);
        const remainingQty = getInventoryItemRemainingQuantity(inventoryItem);
        const newStock = remainingQty.add(new DecimalSafe(quantity)).toString();
        setPrefillCell(hotInstance, row, COLUMN_TYPE.NewStock, newStock);

        if (quantity > 0) {
          const unitPrice = getInventoryItemUnitPrice(inventoryItem, -quantity);
          const extPrice = calcExtPrice(unitPrice, -quantity);
          setPrefillCell(hotInstance, row, COLUMN_TYPE.UnitPrice, unitPrice);
          const absExtPrice = !Number.isNaN(extPrice) ? Math.abs(extPrice) : "";
          setPrefillCell(hotInstance, row, COLUMN_TYPE.ExtPrice, absExtPrice);
        }
      });
    },
    [findInventoryItemByNameAndUom, calcExtPrice],
  );

  return {
    prefillStock,
    prefillTransfer,
  };
};

import { Dialog } from "@/common/components/dialog/Dialog";
import { If } from "@/common/components/if/If";
import { Switch } from "@/common/components/switch/Switch";
import { routes } from "@/config/routes";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useAcceptQuote } from "../../../hooks/useAcceptQuote";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";

const Container = tw.div`
  grid gap-1 mt-5 px-30 items-start justify-center
`;

const InfoText = tw.div`
  text-sm w-fit grid grid-cols-auto-auto gap-x-2 gap-y-2 items-center font-normal place-self-center
  bg-gray-200 py-1.5 px-4 col-span-3 rounded-3xl 
`;

type CreateBuyoutFromRFQDialogProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const CreateBuyoutFromRFQDialog: FC<CreateBuyoutFromRFQDialogProps> = ({
  visible = true,
  setVisible,
}) => {
  const intl = useIntl();
  const [notifyOtherVendors, setNotifyOtherVendors] = useState(false);
  const { rfq, selectedQuotes, selectedAuxiliaryQuoteItems } = useRfqQuotes();
  const navigate = useNavigate();
  const { acceptQuote, accepting } = useAcceptQuote();

  const hasMoreThanOneQuote = useMemo(() => {
    return rfq?.quotes.length && rfq.quotes.length > 1;
  }, [rfq]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const createBuyout = useCallback(async () => {
    const firstBuyoutId = await acceptQuote({
      rfqId: rfq?.id ?? "",
      selectedQuotes,
      selectedAuxiliaryQuoteItems,
      createBuyout: true,
      notifyOtherVendors,
    });
    if (firstBuyoutId) {
      navigate(generatePath(routes.buyout, { id: firstBuyoutId }));
    }
  }, [
    acceptQuote,
    navigate,
    notifyOtherVendors,
    rfq?.id,
    selectedAuxiliaryQuoteItems,
    selectedQuotes,
  ]);

  return (
    <Dialog
      includeWarningIcon
      title={intl.$t({ id: "PROCEED_WITH_SELECTED_ITEMS" })}
      text={intl.$t({ id: "ACCEPT_QUOTE_AND_CREATE_BUYOUT" })}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "CREATE_BUYOUT" })}
      handleCancel={handleCancel}
      handleConfirm={createBuyout}
      saving={accepting}
      show={visible}
      content={
        <Container>
          <If isTrue={hasMoreThanOneQuote}>
            <InfoText>
              <FormattedMessage id="NOTIFY_VENDORS_THEIR_QUOTE_NOT_SELECTED" />
              <Switch
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
                value={notifyOtherVendors}
                onChange={setNotifyOtherVendors}
              />
            </InfoText>
          </If>
        </Container>
      }
      buttonsConfiguration={{
        confirm: {
          wide: true,
        },
        cancel: {
          wide: true,
        },
      }}
    />
  );
};

import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { INTEGRATION_TYPE_TRANSLATIONS } from "@/common/components/estimation-software-selector/EstimationSoftwareSelector";
import { If } from "@/common/components/if/If";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  IntegrationType,
  PoFormat,
  ReleaseFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useIsValidFoundationReleaseExport } from "../../hooks/isValidFoundationReleaseExport";
import { useIsValidSage300ReleaseExport } from "../../hooks/isValidSage300ReleaseExport";
import { ReleaseExportStatus } from "../common/release-export-status/ReleaseExportStatus";
import { ReleaseExportStatusEnum } from "../common/release-export-status/types/ReleaseExportStatusEnum";
import { ExportMultipleReleasePOsPanel } from "../panels/ExportMultipleReleasePOsPanel";
type Props = {
  release: ReleaseFieldsFragment;
};

const ExportedAt = tw.div`text-2xs text-gray-600`;

export const ExportReleaseButton: FC<Props> = ({ release }) => {
  const intl = useIntl();
  const { settings, connectedIntegrationType } = useOrgSettings();
  const { isValidFoundationReleaseExport } =
    useIsValidFoundationReleaseExport();
  const { isValidSage300ReleaseExport } = useIsValidSage300ReleaseExport();

  const status = useMemo(() => {
    if (release.poExists && !release.poLink && !release.exportedAt) {
      return ReleaseExportStatusEnum.PoExists;
    }
    if (
      (connectedIntegrationType === IntegrationType.Foundation &&
        !isValidFoundationReleaseExport({
          release,
          poFormatBasic: release.type.poFormat === PoFormat.Basic,
        })) ||
      (connectedIntegrationType === IntegrationType.Sage300 &&
        !isValidSage300ReleaseExport({
          release,
        }))
    ) {
      return ReleaseExportStatusEnum.ExportIssue;
    }
    if (release.exportedVersion !== release.syncVersion) {
      return ReleaseExportStatusEnum.Outdated;
    }
    return ReleaseExportStatusEnum.OK;
  }, [
    connectedIntegrationType,
    isValidFoundationReleaseExport,
    isValidSage300ReleaseExport,
    release,
  ]);

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <>
          <If isTrue={release.exportedAt || release.poExists}>
            <ExportedAt>
              <ReleaseExportStatus
                status={status}
                poNumber={release.poNumber}
                date={release.exportedAt}
                integrationType={connectedIntegrationType}
                projectId={release.project?.id}
                poExists={release.poExists}
                poLink={release.poLink}
                useSourceSystemPO={release.useSourceSystemPO}
              />
            </ExportedAt>
          </If>
          <If isTrue={!release.poExists}>
            <OutlinedButton $small onClick={() => togglePanel(true)}>
              {intl.$t(
                { id: "EXPORT_PO_TO_INTEGRATION_TYPE" },
                {
                  integration: settings
                    ? intl.$t({
                        id: INTEGRATION_TYPE_TRANSLATIONS[
                          settings?.integrations.accounting.find(
                            (a) => a.enabled,
                          )?.integration as IntegrationType
                        ],
                      })
                    : "",
                },
              )}
            </OutlinedButton>
          </If>
        </>
      )}
      content={(togglePanel) => (
        <ExportMultipleReleasePOsPanel
          releaseIds={[release.id]}
          onClose={() => togglePanel(false)}
        />
      )}
    />
  );
};

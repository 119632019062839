import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import HotTableClass from "@handsontable/react/hotTableClass";
import Handsontable from "handsontable";
import { CellChange, ChangeSource, RangeType } from "handsontable/common";
import { ColumnSettings } from "handsontable/settings";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import {
  ADD_ITEMS_SOURCE,
  PREFILL_SOURCE,
  TRIGGER_UPDATE_SOURCES,
} from "../constants/tableConstants";
import { COLUMN_TYPE } from "../enums/columnType";
import { ColumnType } from "../providers/ColumnMapperProvider";
import { useSpreadSheetStore } from "../store/useSpreadSheetStore";
import { setTableCells } from "../utils/setTableCells";
import { useCellEditHelpers } from "./useCellEditHelpers";
import { usePrefillHelpers } from "./usePrefillHelpers";

type UseTableEventsParams = {
  data: Record<string, string>[] | undefined;
  setSpreadsheetData: (data: Record<string, string>[]) => void;
  onChanges?: (data: Record<string, string>[]) => void;
  hotTableComponent: MutableRefObject<HotTableClass | null>;
  allColumns: ColumnType[];
  customPrefillFunctions: ((
    hotInstance: Handsontable | undefined | null,
    changes: CellChange[] | null,
    source: ChangeSource,
  ) => void)[];
  columnsConfig: ColumnSettings[];
};

export const useTableEvents = ({
  data,
  setSpreadsheetData,
  onChanges,
  hotTableComponent,
  allColumns,
  columnsConfig,
  customPrefillFunctions,
}: UseTableEventsParams) => {
  const [lastColumn, setLastColumn] = useState(false);
  const {
    capDecimalCount,
    preventLumpSumChanges,
    preventReadOnlyChanges,
    removePriceSymbols,
    sanitizedInvalidNumbers,
    updateActivePriceEditorForLumpSum,
  } = useCellEditHelpers();

  const {
    prefillExtraOptions,
    prefillMaterialFields,
    prefillPrices,
    prefillForLumpSum,
    updateExtPriceDependence,
    prefillTaxableValues,
  } = usePrefillHelpers();

  const { getUomByName } = useUomOptions();
  const { formatCostCode } = useProjectCostCodes();
  const { formatCostType } = useCostTypes();

  const callOnChanges = useCallback(
    (changes: CellChange[] | null) => {
      if (
        data &&
        onChanges &&
        changes &&
        changes?.length > 0 &&
        changes.some(
          (change) =>
            change[2] !== change[3] &&
            !(change[3] === "" && change[2] === null),
        )
      ) {
        onChanges(data);
      }
    },
    [onChanges, data],
  );

  const beforeChange = useCallback(
    (changes: (CellChange | null)[], source: ChangeSource) => {
      if (!changes || changes?.length === 0) {
        return;
      }

      if (!TRIGGER_UPDATE_SOURCES.includes(source) && changes.length > 0) {
        return;
      }
      const hotInstance = hotTableComponent.current?.hotInstance;
      hotInstance?.batch(() => {
        removePriceSymbols(hotInstance, changes, source);
        capDecimalCount(hotInstance, changes, source);
        preventReadOnlyChanges(hotInstance, changes, source);
        sanitizedInvalidNumbers(hotInstance, changes, source);
        preventLumpSumChanges(hotInstance, changes, source);
      });
    },
    [
      capDecimalCount,
      preventLumpSumChanges,
      preventReadOnlyChanges,
      removePriceSymbols,
      sanitizedInvalidNumbers,
      hotTableComponent,
    ],
  );

  const afterChange = useCallback(
    (changes: CellChange[] | null, source: ChangeSource) => {
      if (!changes || changes?.length === 0) {
        return;
      }

      if (!TRIGGER_UPDATE_SOURCES.includes(source)) {
        callOnChanges(changes);
        return;
      }
      const hotInstance = hotTableComponent.current?.hotInstance;
      if (data) {
        data.forEach((row) => {
          if (
            Object.values(row).every(
              (cell) => row.id && (!cell || cell === row.id),
            )
          ) {
            delete row.id;
          }
        });

        if (source !== ADD_ITEMS_SOURCE) {
          useSpreadSheetStore.getState().resetPrefillChanges();
          if (customPrefillFunctions.length > 0) {
            customPrefillFunctions.forEach((fn) =>
              fn(hotInstance, changes, source),
            );
          } else {
            prefillExtraOptions(hotInstance, changes, source);
            prefillMaterialFields(hotInstance, changes, source);
            prefillPrices(hotInstance, changes, source);
            prefillForLumpSum(hotInstance, changes, source);
            updateExtPriceDependence(hotInstance, changes, source);
            prefillTaxableValues(hotInstance, changes, source);
          }
          setTableCells(
            useSpreadSheetStore.getState().prefillChanges,
            hotInstance,
            PREFILL_SOURCE,
          );
        }

        callOnChanges(changes);
        setSpreadsheetData(data);
      }
    },
    [
      data,
      prefillTaxableValues,
      prefillExtraOptions,
      prefillForLumpSum,
      prefillMaterialFields,
      prefillPrices,
      setSpreadsheetData,
      updateExtPriceDependence,
      customPrefillFunctions,
      callOnChanges,
      hotTableComponent,
    ],
  );

  const afterRemoveRow = useCallback(() => {
    if (data) {
      setSpreadsheetData(data);
      if (onChanges) {
        onChanges(data);
      }
    }
  }, [data, onChanges, setSpreadsheetData]);

  const afterUpdateSettings = useCallback(() => {
    const hotInstance = hotTableComponent.current?.hotInstance;
    hotInstance?.validateCells();
  }, [hotTableComponent]);

  const afterBeginEditing = useCallback(
    (row: number, column: number) => {
      const hotInstance = hotTableComponent.current?.hotInstance;
      setLastColumn(
        hotInstance?.getSelectedLast()?.[1] === allColumns.length - 1,
      );
      updateActivePriceEditorForLumpSum(hotInstance, column, row);
    },
    [
      allColumns.length,
      updateActivePriceEditorForLumpSum,
      hotTableComponent,
      setLastColumn,
    ],
  );

  const beforePaste = useCallback(
    (changes: string[][], coords: RangeType[]) => {
      changes.forEach((change) => {
        change.forEach((cell, index) => {
          if (!change[index] || typeof change[index] !== "string") {
            return;
          }
          const columnType =
            columnsConfig[index + coords[0].startCol]?.columnType;
          const skipSpaceReplacement = columnsConfig.find(
            (col) => col.columnType === columnType,
          )?.skipSpaceReplacement;
          switch (columnType) {
            case COLUMN_TYPE.CostCode:
              change[index] = formatCostCode(change[index].trim());
              break;
            case COLUMN_TYPE.CostType:
              change[index] = formatCostType(change[index].trim());
              break;
            case COLUMN_TYPE.UOM:
              change[index] =
                getUomByName(cell)?.pluralDescription ?? change[index].trim();
              break;
            default:
              change[index] = skipSpaceReplacement
                ? change[index].trim()
                : change[index].replace(/\s+/g, " ").trim();
          }
          const type = columnsConfig[index + coords[0].startCol]?.type;
          if (type === "numeric" || type === "date") {
            change[index] = change[index].replace(/[,$£]/g, "");
          }
        });
      });
    },
    [columnsConfig, formatCostCode, formatCostType, getUomByName],
  );

  useEffect(() => {
    const dropdowns = document.querySelectorAll(
      "div .listbox",
    ) as NodeListOf<HTMLDivElement>;
    dropdowns.forEach((dropdown: HTMLDivElement) => {
      dropdown.style.right = lastColumn ? "0px" : "auto";
    });
  }, [lastColumn]);

  return {
    beforePaste,
    beforeChange,
    afterChange,
    afterRemoveRow,
    afterUpdateSettings,
    afterBeginEditing,
  };
};

import { SCREEN_BREAKPOINTS, UTC_OFFSET } from "@/common/const";
import { useWindowSize } from "@/common/hooks/useWindowResize";
import {
  DesktopDatePicker,
  DesktopDatePickerProps,
  MobileDatePicker,
  PickersActionBarProps,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { endOfDay, isBefore, startOfDay } from "date-fns";
import { FC, useCallback, useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { LinkLikeText } from "../link-like-text/LinkLikeText";
import CustomPickersActionBar from "./CustomPickersActionBar";
import CustomPickersButton from "./CustomPickersButton";
import CustomPickersDay from "./CustomPickersDay";
import CustomPickersShortcuts from "./CustomPickersShortcuts";
import { DatePickerProps, DateRangePickerValue } from "./types";

const Label = tw(LinkLikeText)`text-sm font-normal`;
const Container = tw.div`flex gap-2 items-center`;

const DateRangePicker = ({
  defaultValue,
  selectedValue,
  onChange,
  titleId,
  isUTC = false,
  label = false,
  open = false,
  includeLabel,
  staticView = false,
  onClose = () => null,
  customActionBar,
  onClear,
  disabled,
}: DatePickerProps) => {
  const viewPort = useWindowSize();
  const isMobile = viewPort.width <= SCREEN_BREAKPOINTS.xs;
  const DatePickerComponent: FC<DesktopDatePickerProps<Date>> = staticView
    ? StaticDatePicker
    : isMobile
      ? MobileDatePicker
      : DesktopDatePicker;

  const [startDate, setStartDate] = useState<Date | undefined>(
    defaultValue?.startDate ? startOfDay(defaultValue?.startDate) : undefined,
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    defaultValue?.endDate ? endOfDay(defaultValue.endDate) : undefined,
  );
  const [isOpened, setIsOpened] = useState<boolean>(open);
  const [value, setValue] = useState<DateRangePickerValue>({});

  const close = useCallback(() => {
    setIsOpened(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    setStartDate(selectedValue?.startDate);
    setEndDate(selectedValue?.endDate);
    setValue(
      selectedValue
        ? {
            startDate: selectedValue.startDate,
            endDate: selectedValue.endDate,
          }
        : {},
    );
  }, [selectedValue]);

  const setValueProxy = (value: DateRangePickerValue) => {
    if (!value.endDate && value.startDate) {
      value.endDate = endOfDay(value.startDate);
    }

    const utcValue = {
      startDate: value.startDate
        ? new Date(value.startDate.getTime() - (isUTC ? UTC_OFFSET : 0))
        : undefined,
      endDate: value.endDate
        ? new Date(value.endDate.getTime() - (isUTC ? UTC_OFFSET : 0))
        : undefined,
    };

    if (onChange) {
      onChange(utcValue);
    }
    setValue(utcValue);
  };

  const onInternalChange = (date: Date | null) => {
    if (!date) {
      return;
    }

    if (
      !startDate ||
      (startDate && endDate) ||
      (startDate && isBefore(date, startDate))
    ) {
      setStartDate(startOfDay(date));
      setEndDate(undefined);
    } else {
      setEndDate(endOfDay(date));
    }
  };

  const clearValues = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setValueProxy({});
    onClear?.();
  };

  return (
    <Container>
      <DatePickerComponent
        onChange={onInternalChange}
        closeOnSelect={false}
        open={isOpened}
        onClose={close}
        onOpen={() => setIsOpened(true)}
        timezone="UTC"
        slots={{
          day: CustomPickersDay,
          actionBar: (props: PickersActionBarProps) => (
            <CustomPickersActionBar
              {...props}
              customActionBar={customActionBar}
            />
          ),
          shortcuts: (props) => (
            <CustomPickersShortcuts {...props} titleId={titleId} />
          ),
          toolbar: () => null,
          field: CustomPickersButton,
        }}
        disabled={disabled}
        slotProps={{
          layout: {
            sx: {
              ".MuiPickersLayout-contentWrapper": {
                gridColumn: "auto",
                width: "30rem",
              },
              ".MuiDateCalendar-root": {
                width: "100%",
                paddingX: "12px",
              },
              ".MuiDayCalendar-header": {
                display: "flex",
                justifyContent: "space-evenly",
                paddingX: "10px",
              },
            },
          },
          // MUI doesn't allow to pass custom props to slots (https://github.com/mui/mui-x/issues/9775)
          field: {
            dateRange: value,
            setIsOpened,
            clearValues,
            includeLabel,
            isUTC,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any,
          shortcuts: {
            isLandscape: false,
            onChange: ({ startDate, endDate }: DateRangePickerValue) => {
              setStartDate(startDate ? startOfDay(startDate) : undefined);
              setEndDate(endDate ? endOfDay(endDate) : undefined);
            },
            isValid: () => true,
            onClose: close,
            // MUI doesn't allow to pass custom props to slots (https://github.com/mui/mui-x/issues/9775)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any,
          actionBar: {
            onAccept: () => {
              setValueProxy({ startDate, endDate });
              close();
            },
            onClear: clearValues,
            onCancel: () => {
              setStartDate(undefined);
              setEndDate(undefined);
              close();
            },
            onSetToday: () => {},
            // MUI doesn't allow to pass custom props to slots (https://github.com/mui/mui-x/issues/9775)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any,
          day: {
            startDate,
            endDate,
            // MUI doesn't allow to pass custom props to slots (https://github.com/mui/mui-x/issues/9775)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any,
        }}
      />
      <If isTrue={label}>
        <Label onClick={() => setIsOpened(!open)}>{label}</Label>
      </If>
    </Container>
  );
};

export default DateRangePicker;

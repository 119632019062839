import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";

const IconContainer = tw.div`
  flex items-center justify-center w-28 h-28 text-7xl text-blue-500 mt-2
`;

type Props = {
  className?: string;
};

export const InfoIcon: FC<Props> = ({ className }) => (
  <IconContainer className={className}>
    <InfoOutlined className="text-9xl" />
  </IconContainer>
);

import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { useCallback } from "react";
import { BsPlus } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Template } from "../../types/Template";
import { TemplateEditor } from "../template-editor/TemplateEditor";
import { TemplatePreview } from "../template-preview/TemplatePreview";
import { CustomTemplateTitle } from "./CustomTemplateTitle";

const Container = tw.div`grid grid-flow-row gap-10 mt-2`;
const Row = tw.div`grid`;

type CustomTemplatesProps = {
  templates: Template[];
  setTemplates: (templates: Template[]) => void;
  onAddTemplate: () => void;
};

export const CustomTemplates = ({
  templates,
  setTemplates,
  onAddTemplate,
}: CustomTemplatesProps) => {
  const setTemplate = useCallback(
    (template: Template) => {
      setTemplates(
        templates.map((t) => (t.name === template.name ? template : t)),
      );
    },
    [setTemplates, templates],
  );

  return (
    <Container>
      {templates.map((template, index) => (
        <Row key={`custom-template-${index}`}>
          <CustomTemplateTitle template={template} />
          <TemplateEditor template={template} setTemplate={setTemplate} />
          <TemplatePreview template={template} />
        </Row>
      ))}
      <OutlinedButton className="w-fit" onClick={onAddTemplate}>
        <BsPlus className="mr-1 h-5 w-5" />
        <FormattedMessage id="ADD_NEW_TEMPLATE" />
      </OutlinedButton>
    </Container>
  );
};

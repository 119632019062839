import { DECIMAL_MAX_FRACTION_DIGITS } from "@/common/const";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { CellValue } from "handsontable/common";
import Core from "handsontable/core";
import { CellProperties } from "handsontable/settings";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../../value-currency/hooks/useFormatNumberToCurrency";
import { COLUMN_TYPE } from "../../enums/columnType";
import { getPhysicalColumnIndex } from "../../utils/getPhysicalColumnIndex";
import { applyClasses } from "../utils/applyClasses";
import { applyTooltip } from "../utils/applyTooltip";
import { isValueEmpty } from "../utils/isValueEmpty";
import { removeTooltip } from "../utils/removeTooltip";

const readOnlyCellClasses = "bg-gray-100";
const readOnlyCellHideTextClasses = "bg-gray-100 text-gray-100";

export const useRenderHelpers = () => {
  const { formatCurrency } = useFormatNumberToCurrency();
  const intl = useIntl();

  const addIconWithTooltip = useCallback(
    ({
      element,
      tooltipText,
      icon = "fa-circle-info",
      iconClasses = "",
      onClick,
    }: {
      element: HTMLElement;
      tooltipText: string;
      icon?: string;
      iconClasses?: string;
      onClick?: () => void;
    }) => {
      const iconCont = document.createElement("span");
      iconCont.className = `
        absolute inline-grid top-0 right-0.5 place-items-center h-full 2xl:right-2
        ${onClick ? "cursor-pointer" : ""}
      `;
      const iconElement = document.createElement("i");
      iconElement.className = `fa-solid text-[15px] ${icon} text-blue-500 ${iconClasses} hover:text-blue-300`;
      iconCont.appendChild(iconElement);
      element.appendChild(iconCont);

      if (onClick) {
        iconElement.onclick = onClick;
      }

      applyTooltip(iconElement, tooltipText);
    },
    [],
  );

  const hideLumpSumItemReadOnlyText = useCallback(
    (
      td: HTMLTableCellElement,
      rowData: CellValue[],
      instance: Core,
      cellProperties: CellProperties,
    ) => {
      if (cellProperties.disabledForLumpSum) {
        const uomContent =
          rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)];
        if (isLumpSumUomText(uomContent)) {
          applyClasses(td, readOnlyCellHideTextClasses);
          applyTooltip(td, intl.$t({ id: "LUMP_SUM_ITEM" }));
          return true;
        }
        applyClasses(td, "");
        removeTooltip(td);
      }
      return false;
    },
    [intl],
  );

  const checkReadOnly = useCallback(
    (
      td: HTMLTableCellElement,
      sourceRow: CellValue[],
      cellProperties: CellProperties,
    ) => {
      if (!cellProperties.readOnlyFn) {
        return;
      }
      const reason = cellProperties.readOnlyFn?.(sourceRow);

      if (!td.className.includes(readOnlyCellClasses)) {
        td.className = `${td.className} ${reason ? readOnlyCellClasses : ""}`;
      }

      if (reason) {
        applyTooltip(td, reason);
      } else {
        removeTooltip(td);
      }
    },
    [],
  );

  const formatPrice = useCallback(
    (rowData: CellValue[], instance: Core, value: string) => {
      const uom = rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)];
      const quantity =
        rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)];

      if (isLumpSumUomText(uom)) {
        return formatCurrency(
          new DecimalSafe(isValueEmpty(quantity) ? 1 : quantity).toNumber(),
          {
            maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
          },
        );
      } else {
        return isValueEmpty(value)
          ? ""
          : formatCurrency(value, {
              maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
            });
      }
    },
    [formatCurrency],
  );

  return {
    checkReadOnly,
    hideLumpSumItemReadOnlyText,
    addIconWithTooltip,
    formatPrice,
  };
};

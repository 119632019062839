import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import * as GraphQL from "@/generated/graphql";
import { useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { useIntl } from "react-intl";
import { useOrderTypesActions } from "../../hooks/useOrderTypesActions";
import { FormType } from "./FormType";
import { OrderTypeFormProps } from "./OrderTypeForm";

export const useOrderTypeFormWithProviders = ({
  orderType,
  onClose,
  methods,
}: Pick<OrderTypeFormProps, "orderType" | "onClose"> & {
  methods: UseFormReturn<FormType>;
}) => {
  const { createOrderType, updateOrderType } = useOrderTypesActions();
  const { setSuccessAlert } = useSnackbar();
  const chargesMode = methods.watch("chargesMode");
  const defaultAutoSync = methods.watch("defaultAutoSync");
  const taxMode = methods.watch("taxMode");
  const { connectedSourceSystem } = useOrgSettingsExtended();
  const intl = useIntl();

  const { hasFeatureInConnectedSourceSystem: hasFeature } =
    useIntegrationFeatureRequirement();
  const onSave = useCallback(
    async (values: FormType) => {
      if (
        (taxMode === GraphQL.TaxMode.Separate &&
          ((!values.salesTaxCostCodeId &&
            hasFeature(IntegrationFeature.IncludeCostCodes)) ||
            (!values.salesTaxCostTypeId &&
              hasFeature(IntegrationFeature.IncludeCostTypes)))) ||
        (chargesMode === GraphQL.ChargesMode.Separate &&
          ((!values.additionalChargesCostCodeId &&
            hasFeature(IntegrationFeature.IncludeCostCodes)) ||
            (!values.additionalChargesCostTypeId &&
              hasFeature(IntegrationFeature.IncludeCostTypes))))
      ) {
        return;
      }
      const defaultValues = {
        name: values.name,
        requireDeliverySlip: values.requireDeliverySlip,
        billable: values.billable,
        transactionKind: values.kind,
        costTypeId: values.costType || undefined,
        default: values.default,
        enableUpcomingNotifications:
          values.kind === GraphQL.TransactionKind.Purchase &&
          values.sendReminders,
        ledgerAccount:
          values.ledgerAccountId && connectedSourceSystem
            ? {
                externalId: values.ledgerAccountId,
                sourceSystem: connectedSourceSystem.system,
              }
            : undefined,
        ledgerSubaccount:
          values.ledgerSubaccountId && connectedSourceSystem
            ? {
                externalId: values.ledgerSubaccountId,
                sourceSystem: connectedSourceSystem.system,
              }
            : undefined,
        poType:
          values.poTypeId && connectedSourceSystem
            ? {
                externalId: values.poTypeId,
                sourceSystem: connectedSourceSystem.system,
              }
            : undefined,
        poFormat: values.poFormatBasic
          ? GraphQL.PoFormat.Basic
          : GraphQL.PoFormat.Detail,
        defaultOrgMaterialId: values.defaultOrgMaterialId,
        taxMode,
        chargesMode,
        defaultAutoSync: !!defaultAutoSync,
        nonJobPO: values.nonJobPO,
        includeTaxInInvoiceSubtotal: values.includeTaxInInvoiceSubtotal,
        trackInventory: values.trackInventory,
        ...(chargesMode === GraphQL.ChargesMode.Separate
          ? {
              additionalChargesCostCodeId: values.additionalChargesCostCodeId,
              additionalChargesCostTypeId: values.additionalChargesCostTypeId,
            }
          : {}),
        ...(taxMode === GraphQL.TaxMode.Separate
          ? {
              salesTaxCostCodeId: values.salesTaxCostCodeId,
              salesTaxCostTypeId: values.salesTaxCostTypeId,
              salesTaxOrgMaterialId: values.salesTaxOrgMaterialId,
            }
          : taxMode === GraphQL.TaxMode.Majority
            ? {
                salesTaxOrgMaterialId: values.salesTaxOrgMaterialId,
              }
            : {}),
      };
      if (orderType) {
        await updateOrderType({
          ...defaultValues,
          id: orderType.id,
          clearCostType: values.costType === "",
          clearLedgerAccount: !values.ledgerAccountId,
          clearPoType: !values.poTypeId,
          clearDefaultOrgMaterial: !values.defaultOrgMaterialId,
        });
      } else {
        await createOrderType({
          ...defaultValues,
        });
      }
      setSuccessAlert(
        intl.$t({
          id: orderType
            ? "ORDER_TYPE_UPDATE_SUCCESS"
            : "ORDER_TYPE_CREATE_SUCCESS",
        }),
      );
      onClose();
    },
    [
      taxMode,
      hasFeature,
      chargesMode,
      connectedSourceSystem,
      defaultAutoSync,
      orderType,
      setSuccessAlert,
      intl,
      onClose,
      updateOrderType,
      createOrderType,
    ],
  );

  return { onSave };
};

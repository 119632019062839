import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useCallback } from "react";
import { COLUMN_TYPE } from "../../enums/columnType";
import { getMaterialNameParts } from "../../utils/getMaterialNameParts";
import { getPhysicalColumnIndex } from "../../utils/getPhysicalColumnIndex";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { useRenderHelpers } from "../helpers/useRenderHelpers";
import { isValueEmpty } from "../utils/isValueEmpty";
import { usePartRenderers } from "./usePartRenderers";

export const useMaterialRenderer = () => {
  const { addIconWithTooltip } = useRenderHelpers();
  const { getMetadata } = useColumnMapper();
  const { setPartNumberComponent, setNewLabel } = usePartRenderers();
  const baseRenderer = useBaseRenderer();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      baseRenderer(instance, td, row, col, prop, value, cellProperties);
      if (isValueEmpty(value)) {
        return;
      }

      const autocompleteOptions: string[] = cellProperties.source as string[];
      const rowData = instance?.getDataAtRow(row);

      const { namePart, partNumber } = getMaterialNameParts(value);
      if (partNumber) {
        setPartNumberComponent(td, namePart, partNumber);
      }

      const isLumpSumItem = isLumpSumUomText(
        rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
      );
      setNewLabel(td, autocompleteOptions, value, isLumpSumItem);

      const meta = getMetadata(row);
      if (meta?.warningIconTooltipText) {
        addIconWithTooltip({
          element: td,
          tooltipText: meta?.warningIconTooltipText.toString(),
          iconClasses: "text-red-500 hover:text-red-300",
        });
      }
    },
    [
      setPartNumberComponent,
      setNewLabel,
      getMetadata,
      addIconWithTooltip,
      baseRenderer,
    ],
  );

  return renderer;
};

import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";
import { useSyncReleaseItems } from "./useSyncReleaseItems";

type SubmitOptions = {
  callback?: () => void;
  validationKey: string;
};

export const useSubmitChanges = () => {
  const [loading, setLoading] = useState(false);
  const { hasChanges } = useReleaseStore();
  const { submitUpdate } = useReleaseUpdate();
  const intl = useIntl();
  const { setWarningAlert } = useSnackbar();
  const { getSyncedRelease } = useSyncReleaseItems();

  const submitChanges = useCallback(
    async ({ callback, validationKey }: SubmitOptions) => {
      setLoading(true);
      const isValid = true;
      if (hasChanges) {
        if (!isValid) {
          setWarningAlert(
            intl.$t({
              id: validationKey,
            }),
          );
          setLoading(false);
        }

        const input = await getSyncedRelease();
        if (!input) {
          setLoading(false);
          return;
        }
        const result = await submitUpdate({
          ...input,
          skipConfirmation: true,
          skipVendorNotification: true,
        });
        setLoading(false);
        if (result) {
          callback?.();
        } else {
          setWarningAlert(
            intl.$t({
              id: validationKey,
            }),
          );
        }
        return;
      }
      setLoading(false);
      callback?.();
    },
    [getSyncedRelease, hasChanges, intl, setWarningAlert, submitUpdate],
  );

  return { submitChanges, submitting: loading };
};

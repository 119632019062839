import {
  OrgPreferredVendorsFieldsFragment,
  WarehouseFieldsFragment,
} from "@/generated/graphql";

export type SupplierCommonFields = {
  value: string | undefined;
  name: string;
  type: SupplierType;
  disabled?: boolean;
};

export type Supplier = (
  | OrgPreferredVendorsFieldsFragment
  | WarehouseFieldsFragment
) &
  SupplierCommonFields;

export type SupplierVendor = OrgPreferredVendorsFieldsFragment &
  SupplierCommonFields;

export type SupplierWarehouse = WarehouseFieldsFragment & SupplierCommonFields;

export enum SupplierType {
  None = "",
  VENDOR = "VENDOR",
  WAREHOUSE = "WAREHOUSE",
}

import { getAssetType } from "@/common/components/upload-asset/getAssetType";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { hasProperty } from "@/common/utils/objectUtils";
import { generateUUID } from "@/common/utils/uuidUtils";
import {
  AssetContext,
  DistributorReleaseFieldsFragment,
  DistributorReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";
import { DistributorUpdateVendorReleaseInput } from "./DistributorReleaseProvider";

export const useDistributorReleaseUpdates = () => {
  const { getUomByName } = useUomOptions();
  const { manufacturers } = useManufacturers();

  return useCallback(
    (
      release: DistributorReleaseFieldsFragment,
      input?: DistributorUpdateVendorReleaseInput,
    ) => {
      if (!release) {
        return null;
      }

      if (!input) {
        return release;
      }

      return {
        ...release,
        version: release.version,
        notes: input.notes || release.notes,
        paymentTerm: input.paymentTerm || release.paymentTerm,
        time: input.time || release.time,
        customTaxAmount: input.clearCustomTaxAmount
          ? null
          : hasProperty(input, "customTaxAmount")
            ? input.customTaxAmount
            : release?.customTaxAmount,
        taxRate: input.taxRate || release.taxRate,
        additionalCharges: input.additionalCharges
          ? input.additionalCharges?.map((c) => {
              return {
                ...c,
                id: c.id || "",
                __typename: "Charge" as const,
              };
            }) || []
          : release.additionalCharges || [],
        items: release.items
          .filter(
            (item) =>
              !input?.removedItems?.includes(item.id) || !item.isIncluded,
          )
          .map((item: DistributorReleaseItemFieldsFragment) => {
            const updatedItem = input.updates?.find(
              (i) => i.releaseItemId === item.id,
            );
            let uom = item.uom;
            if (updatedItem?.uom) {
              const u = getUomByName(updatedItem.uom);
              if (u) {
                uom = u;
              } else {
                uom = {
                  id: generateUUID(),
                  pluralDescription: updatedItem.uom,
                  alternativeRefs: [],
                };
              }
            }
            const unitPrice = updatedItem?.unitPrice ?? item.unitPrice;
            const quantityDecimal =
              updatedItem?.quantityDecimal ?? item.quantityDecimal;
            const newRequestedQuantity = new DecimalSafe(quantityDecimal)
              .minus(
                input.addedItems?.reduce(
                  (acc, item) => acc.plus(new DecimalSafe(item.quantity ?? 0)),
                  new DecimalSafe(0),
                ) ?? new DecimalSafe(0),
              )
              .toNumber();

            const newItem: DistributorReleaseItemFieldsFragment = {
              ...item,
              ...(updatedItem && {
                isIncluded: updatedItem?.isIncluded ?? item.isIncluded,
                manufacturer: updatedItem?.manufacturerId
                  ? manufacturers.find(
                      (m) => m.id === updatedItem?.manufacturerId,
                    )
                  : item.manufacturer,
                quantityDecimal,
                unitPrice,
                requestedQuantity: newRequestedQuantity,
                deliveryDate: updatedItem?.clearDeliveryDate
                  ? null
                  : (updatedItem?.deliveryDate ?? item.deliveryDate),
                deliveryDateTBD: !!updatedItem.deliveryDateTBD,
                uom,
                notes: updatedItem?.notes ?? item.notes,
                assets:
                  updatedItem?.assetUrls?.map((assetUrl) => ({
                    id: generateUUID(),
                    url: assetUrl,
                    __typename: "Asset" as const,
                    type: getAssetType(assetUrl),
                    context: AssetContext.Instruction,
                    pages: null,
                    thumbnailUrl: assetUrl,
                    createdAt: 0,
                    isPrivate: false,
                  })) ?? item.assets,
                taxable: updatedItem?.taxable ?? item.taxable,
              }),
            };
            return newItem;
          })
          .concat(
            input.addedItems
              ?.map((item) => {
                const duplicatedItem = release.items.find((i) =>
                  item.sourceReleaseItemId.includes(i.id),
                );
                if (!duplicatedItem) {
                  return null;
                }
                const newItem: DistributorReleaseItemFieldsFragment = {
                  ...duplicatedItem,
                  id: item.sourceReleaseItemId,
                  quantityDecimal: item.quantity ?? "",
                  deliveryDate: item.deliveryDate ?? null,
                  requestedQuantity: Number(item.quantity) ?? 0,
                };
                return newItem;
              })
              .filter((item) => item !== null) ?? [],
          ),
      };
    },
    [getUomByName, manufacturers],
  );
};

import { formattedDate } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { InvoiceSequenceData } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceSequenceProvider";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";

export const useExportToCsvInvoiceData = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();

  const getInvoiceExportToCsvData = useCallback(
    (invoices: InvoiceSequenceData[]) => {
      const headers = [
        intl.$t({ id: "INVOICE_GROUPS" }),
        intl.$t({ id: "INVOICE_NUMBER_SYMBOL" }),
        intl.$t({ id: "ISSUED" }),
        intl.$t({ id: "RECEIVED" }),
        intl.$t({ id: "TOTAL" }),
        intl.$t({ id: "INVOICE_EMAIL_VENDOR" }),
        intl.$t({ id: "PROJECT" }),
        intl.$t({ id: "MATCHED_ORDER" }),
        intl.$t({ id: "TYPE" }),
        intl.$t({ id: "PO_HASH" }),
        intl.$t({ id: "INVOICE_STATUS" }),
        intl.$t({ id: "APPROVAL_DATE" }),
        intl.$t({ id: "APPROVED_BY" }),
        intl.$t({ id: "EXPORT_ERROR" }),
        intl.$t({ id: "FIRST_KICKBACK_CREATION_DATE" }),
        intl.$t({ id: "FIRST_KICKBACK_CREATED_BY" }),
        intl.$t({ id: "FIRST_KICKBACK_ASSIGNEES" }),
        intl.$t({ id: "LAST_KICKBACK_RESOLVED_AT" }),
        intl.$t({ id: "LAST_KICKBACK_RESOLVED_BY" }),
      ];
      const currentDate = formattedDate({ date: new Date() });
      const fileName = `${intl.$t({ id: "INVOICES" })} - ${currentDate}`;
      const data = invoices.map((invoice) => {
        const firstKickback = invoice.kickbacks?.slice(0, 1)?.[0];
        const lastKickback = invoice.kickbacks?.slice(-1)?.[0];
        return [
          (invoice.folders || []).map((folder) => folder.name).join(", "),
          invoice.number,
          invoice.issueDate ? formattedDate({ date: invoice.issueDate }) : "",
          invoice.createdAt ? formattedDate({ date: invoice.createdAt }) : "",
          invoice.total ? formatCurrency(invoice.total) : "",
          invoice.release?.sellerOrgLocation?.org?.name ||
            invoice.predictedSellerOrgLocation?.org?.name,
          invoice.release?.project?.jobNumber ||
            invoice.predictedProject?.jobNumber,
          invoice.release?.sequenceNumber
            ? intl.$t(
                { id: "ORDER_WITH_NUMBER" },
                { orderNumber: invoice.release?.sequenceNumber },
              )
            : "",
          invoice.release?.type.name,
          invoice.release?.poNumber,
          intl.$t({ id: `INVOICE_STATUS_${invoice.status}` }),
          invoice.approvedAt
            ? formattedDate({
                date: invoice.approvedAt,
                includeTime: true,
                hideTimeZone: true,
              })
            : "",
          invoice.approvedBy ? getUserName(invoice.approvedBy) : "",
          invoice.link?.failedMessage,
          firstKickback?.createdAt
            ? formattedDate({
                date: firstKickback.createdAt,
                includeTime: true,
                hideTimeZone: true,
              })
            : "",
          firstKickback?.reportedBy
            ? getUserName(firstKickback.reportedBy)
            : "",
          firstKickback?.assignees
            ? firstKickback.assignees
                .map((assignee) => getUserName(assignee))
                .join(", ")
            : "",
          lastKickback?.resolvedAt
            ? formattedDate({
                date: lastKickback.resolvedAt,
                includeTime: true,
                hideTimeZone: true,
              })
            : "",
          lastKickback?.resolvedBy ? getUserName(lastKickback.resolvedBy) : "",
        ];
      });

      return { data, fileName, headers };
    },
    [intl, formatCurrency],
  );

  return { getInvoiceExportToCsvData };
};

import { useCallback } from "react";
import { useRenderHelpers } from "../helpers/useRenderHelpers";
import { applyClasses } from "../utils/applyClasses";

interface ItemRendererParams<T> {
  item: T | undefined;
  textGetter: (item: T) => string;
  tooltip: string;
  iconClasses: string;
  icon?: string;
  onClick?: () => void;
}

export const useItemRenderer = () => {
  const { addIconWithTooltip } = useRenderHelpers();

  const setItemRenderer = useCallback(
    <T>(td: HTMLTableCellElement, params: ItemRendererParams<T>) => {
      const { item, textGetter, tooltip, iconClasses, icon, onClick } = params;
      if (item) {
        applyClasses(td, "pr-5");
        const textElement = document.createElement("span");
        textElement.className = "text-gray-500";
        const textDetails = textGetter(item);
        if (textDetails) {
          textElement.innerText = ` ⟮${textDetails}⟯`;
        }
        td.appendChild(textElement);
        addIconWithTooltip({
          element: textElement,
          icon: icon || "fa-circle",
          tooltipText: tooltip,
          iconClasses,
          onClick,
        });
      }
    },
    [addIconWithTooltip],
  );

  return { setItemRenderer };
};

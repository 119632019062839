import { FC, PropsWithChildren, ReactNode } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { useExpandableFooterStore } from "./stores/useExpandableFooterStore";

const Container = tw.div`fixed bottom-0 left-0 right-0 z-[190] overflow-hidden flex flex-col px-0 xl:px-0`;
const InnerContainer = tw.div`pr-2 pl-2 md:pl-16 md:pr-16 bg-blue-450 min-h-15 py-3.5 z-30`;
const ExpandableContainer = tw.div`relative z-20`;

const ExpandBar = tw.div`justify-center flex h-7 left-0 right-0 items-center bg-blue-400 cursor-pointer text-sm text-white
  hover:brightness-[90%] transition-all duration-200 hover:h-9 hover:font-semibold  
`;
const ExpandedChildrenContainer = tw.div`transition-all duration-200`;

const ChevronUp = tw(BsChevronUp)`mx-2`;
const ChevronDown = tw(BsChevronDown)`mx-2`;

type Props = PropsWithChildren<{
  classes?: {
    container?: string;
    childrenContainer?: string;
  };
  expandedChildren?: ReactNode;
  expandLabelId?: string;
  disabled?: boolean;
}>;

export const FloatingFooter: FC<Props> = ({
  classes,
  children,
  expandedChildren,
  expandLabelId = "EXPAND",
  disabled,
}) => {
  const { height, expanded, setExpanded } = useExpandableFooterStore();

  return (
    <Container
      className={`${classes?.container} ${expandedChildren ? "shadow-[rgba(0,0,15,0.2)_0px_6px_8px_12px]" : ""}`}
    >
      <If isTrue={expandedChildren && !disabled}>
        <ExpandableContainer data-testid="invoiceFooterToggle">
          <ExpandBar onClick={() => setExpanded(!expanded)}>
            <If isTrue={expanded}>
              <ChevronDown />
              <FormattedMessage id="COLLAPSE" />
              <ChevronDown />
            </If>
            <If isTrue={!expanded}>
              <ChevronUp />
              <FormattedMessage id={expandLabelId} />
              <ChevronUp />
            </If>
          </ExpandBar>
          <ExpandedChildrenContainer style={{ height: expanded ? height : 0 }}>
            {expandedChildren}
          </ExpandedChildrenContainer>
        </ExpandableContainer>
      </If>
      <InnerContainer className={classes?.childrenContainer}>
        {children}
      </InnerContainer>
    </Container>
  );
};

import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { ProjectNameListDetails } from "@/common/components/projects-filter-selector/ProjectNameListDetails";
import { BuyoutStatusChip } from "@/common/components/statuses/BuyoutStatusChip";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import {
  LgContainer,
  MdContainer,
  SmContainer,
  SmWideContainer,
  XsContainer,
  XsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { BuyoutsBuyoutFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { getVendorContacts } from "../../../../../common/utils/getVendorContacts";
import { ArchiveBuyoutButton } from "./ArchiveBuyoutButton";
import { BuyoutAllItemsCheckbox } from "./BuyoutAllItemsCheckbox";
import { BuyoutCheckbox } from "./BuyoutCheckbox";
import { BuyoutNo } from "./BuyoutNo";
import { DeliveredVsTotal } from "./DeliveredVsTotal";
import { DeliveriesButton } from "./DeliveriesButton";
import { RequestDeliveryButton } from "./RequestDeliveryButton";

export const useBuyoutsConfiguration = () => {
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);

  const configuration: Array<GridCol<BuyoutsBuyoutFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: XsFixedContainer,
        position: "center",
        item: ({ item }) => <BuyoutCheckbox buyout={item} />,
        header: <BuyoutAllItemsCheckbox />,
      },
      {
        wrapper: SmContainer,
        header: <FormattedMessage id="BUYOUT_NUMBER_SYMBOL" />,
        item: ({ item }) => <BuyoutNo item={item} />,
      },
      {
        wrapper: MdContainer,
        header: <FormattedMessage id="PROJECT" />,
        item: ({ item }) => <ProjectNameListDetails project={item.project} />,
      },
      {
        wrapper: SmContainer,
        header: <FormattedMessage id="SUPPLIER" />,
        position: "center",
        item: ({ item, index }) => (
          <VendorLogo
            logoImageUrl={item.sellerOrgLocation?.org.photoUrl}
            address={item.sellerOrgLocation?.address}
            contactName={getVendorContacts(
              item.preferredVendor?.contacts.filter(
                (c) => c.receivesBuyoutNotifications,
              ),
            )}
            orgName={item.sellerOrgLocation?.org.name}
            index={index}
          />
        ),
      },
      {
        wrapper: LgContainer,
        header: <FormattedMessage id="BUYOUT_RELEASED_TOTAL" />,
        position: "center",
        item: ({ item }) => <DeliveredVsTotal buyout={item} />,
        hidden: !hasPermissions,
      },
      {
        wrapper: SmWideContainer,
        header: <FormattedMessage id="DELIVERIES" />,
        position: "center",
        item: ({ item }) => <DeliveriesButton buyout={item} />,
      },
      {
        wrapper: MdContainer,
        position: "center",
        item: ({ item }) => (
          <ButtonsContainer>
            <RequestDeliveryButton buyout={item} />
          </ButtonsContainer>
        ),
      },
      {
        wrapper: MdContainer,
        header: <FormattedMessage id="STATUS" />,
        position: "center",
        item: ({ item }) => <BuyoutStatusChip status={item.status} />,
      },
      {
        wrapper: XsContainer,
        item: ({ item }) => (
          <ButtonsContainer>
            <ArchiveBuyoutButton buyout={item} />
          </ButtonsContainer>
        ),
      },
    ],
    [hasPermissions],
  );

  return configuration;
};

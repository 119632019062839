import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import tw from "tailwind-styled-components";
import { GroupedInventoryItems } from "../../../../types/GroupedInventoryItems";
import { useInventoryItemCheckbox } from "./useInventoryItemCheckbox";

const Container = tw.div`flex`;

export type InventoryItemCheckboxProps = {
  item: GroupedInventoryItems;
};

export const InventoryItemCheckbox = ({ item }: InventoryItemCheckboxProps) => {
  const { selectedEntities, handleChange, isIncluded } =
    useInventoryItemCheckbox({
      item,
    });
  return (
    <Container>
      <If isTrue={isIncluded}>
        <SelectionCheckbox
          itemId={item.id}
          items={selectedEntities.map((i) => i.id)}
          setSelection={handleChange}
        />
      </If>
    </Container>
  );
};

import { LoadingButton } from "@/common/components/button/LoadingButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { SpreadsheetSaveType } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useSyncEstimatedItems } from "../hooks/useSyncEstimatedItems";
import { useProject } from "../providers/ProjectProvider";
import { UploadMaterialsDrawer } from "./import-project-items/upload-materials-drawer/UploadMaterialsDrawer";
import { ProjectAddItems } from "./project-add-items/ProjectAddItems";

const FooterContent = tw.div`grid grid-cols-[80px_1fr_auto] gap-4 w-full h-10 items-center`;
const ButtonContainer = tw.div`w-full items-center justify-end flex`;

export const ProjectContentFooter = () => {
  const { syncEstimatedItems } = useSyncEstimatedItems();
  const { setViewState } = useTableViewStore(
    useShallow((state) => ({
      viewState: state.viewState,
      setViewState: state.setViewState,
    })),
  );
  const { project } = useProject();

  useEffect(() => {
    if (project?.estimatedItemGroups.length === 0) {
      setViewState(TableViewState.spreadsheet);
    }
  }, [project, setViewState]);

  if (!project) {
    return null;
  }

  return (
    <FloatingFooter>
      <FooterContent>
        <ProjectAddItems />
        <div />
        <ButtonContainer>
          <LoadingButton
            button={PrimaryButton}
            onClick={async () => {
              await syncEstimatedItems({
                trigger: SpreadsheetSaveType.SaveButton,
              });
            }}
          >
            <FormattedMessage id="SAVE_CHANGES" />
          </LoadingButton>
        </ButtonContainer>
      </FooterContent>
      <UploadMaterialsDrawer project={project} />
    </FloatingFooter>
  );
};

import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  AssetContext,
  AssetFieldsFragment,
  AssetType,
  UpdateAssetInput,
  useCreateAssetMutation,
  useUpdateAssetMutation,
} from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import {
  NoFunction,
  NoFunctionArrayPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getAssetType } from "./getAssetType";

type ProviderContextType = {
  uploadAssets: (
    files: File[],
    options?: { assetType?: AssetType; replaceExistingAssets?: boolean },
  ) => Promise<AssetFieldsFragment[]>;
  removeAsset: (asset: AssetFieldsFragment) => AssetFieldsFragment[];
  uploading?: boolean;
  assets: AssetFieldsFragment[];
  setAssets: (assets: AssetFieldsFragment[]) => void;
  updateAsset: (input: UpdateAssetInput & Identity) => Promise<void>;
  updatingAssetIds: string[];
};

const ProviderContext = createContext<ProviderContextType>({
  uploadAssets: NoFunctionArrayPromise,
  removeAsset: () => [],
  uploading: false,
  assets: [],
  setAssets: NoFunction,
  updateAsset: NoFunctionUndefinedPromise,
  updatingAssetIds: [],
});

type UploadAssetProviderProps = {
  children: React.ReactNode;
  initialAssets?: AssetFieldsFragment[] | undefined | null;
  type?: AssetType;
  context: AssetContext;
  projectId?: string | null;
  instructionsAssets?: AssetFieldsFragment[] | null;
};

export const UploadAssetProvider: FC<UploadAssetProviderProps> = ({
  children,
  initialAssets,
  type,
  context,
  projectId,
  instructionsAssets,
}) => {
  const [uploadAssetMutation, { loading: uploading }] =
    useCreateAssetMutation();
  const { setError } = useGlobalError();
  const [assets, setAssets] = useState<AssetFieldsFragment[]>(
    initialAssets ?? [],
  );

  useEffect(() => {
    if (!assets) {
      setAssets(initialAssets ?? []);
    }
  }, [assets, initialAssets]);

  useEffect(() => {
    if (instructionsAssets) {
      setAssets(instructionsAssets);
    }
  }, [instructionsAssets]);

  const uploadAssets = async (
    files: File[],
    options?: { assetType?: AssetType; replaceExistingAssets?: boolean },
  ) => {
    const assetList = options?.replaceExistingAssets ? [] : [...assets];
    try {
      for (const file of files) {
        const { data, errors } = await uploadAssetMutation({
          variables: {
            input: {
              file,
              type: options?.assetType ?? type ?? getAssetType(file.name),
              context,
              projectId: projectId || undefined,
            },
          },
        });
        if (data?.createAsset) {
          assetList.push(data.createAsset);
        }
        setError(errors);
      }
      setAssets(assetList);
      return assetList;
    } catch (error) {
      setError(error);
    }
    return assetList;
  };

  const removeAsset = (asset: AssetFieldsFragment) => {
    const result = [...assets.filter((a) => a.url !== asset.url)];
    setAssets(result);
    return result;
  };

  const [updateAssetMutation] = useUpdateAssetMutation();
  const [updatingAssetIds, setUpdatingAssetIds] = useState<string[]>([]);
  const updateAsset = useCallback(
    async ({ id, ...input }: UpdateAssetInput & Identity) => {
      try {
        setUpdatingAssetIds((ids) => [...ids, id]);
        const { data, errors } = await updateAssetMutation({
          variables: { input },
        });

        if (errors) {
          setError(errors);
        }

        if (data) {
          setAssets((assets) =>
            assets.map((asset) => (asset.id === id ? data.updateAsset : asset)),
          );
        }
      } catch (error) {
        setError(error);
      } finally {
        setUpdatingAssetIds((ids) => ids.filter((updateId) => updateId !== id));
      }
    },
    [updateAssetMutation, setError],
  );

  return (
    <ProviderContext.Provider
      value={{
        uploadAssets,
        removeAsset,
        uploading,
        assets,
        setAssets,
        updateAsset,
        updatingAssetIds,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useUploadAssets = (): ProviderContextType =>
  useContext(ProviderContext);

import { ALL_WAREHOUSES_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { WarehouseSelector } from "@/common/components/warehouse-selector/WarehouseSelector";
import { useIntl } from "react-intl";
import { useOrgDefaultFulfillmentLocation } from "../../hooks/useOrgDefaultFulfillmentLocation";

export const OrgFulfillmentLocation = () => {
  const intl = useIntl();
  const { selectedFulfillmentLocation, setDefaultFulfillmentLocationId } =
    useOrgDefaultFulfillmentLocation();

  return (
    <WarehouseSelector
      value={
        !selectedFulfillmentLocation
          ? ALL_WAREHOUSES_ID
          : selectedFulfillmentLocation?.id
      }
      onChange={setDefaultFulfillmentLocationId}
      placeholder={intl.$t({
        id: "DEFAULT_FULFILLMENT_LOCATION",
      })}
      required={false}
      showAllWarehousesOption
      includeWarehouseInName
      allWarehousesLabel={intl.$t({ id: "PROJECT_LOCATION_WITH_ADDRESS" })}
    />
  );
};

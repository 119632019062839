import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { useItemizationSelector } from "./useItemizationSelector";

type Props = { name: string };

export const ItemizationSelector = ({ name }: Props) => {
  const { options } = useItemizationSelector();

  return (
    <SelectControlled
      name={name}
      className="w-32"
      options={options}
      getLabel={(option) => option.label}
      getValue={(option) => option.value}
    />
  );
};

import { Add, Cached, Check } from "@mui/icons-material";
import tw from "tailwind-styled-components";
import { InvoiceDocumentOptionsContainerProps } from "./types/InvoiceDocumentOptions.types";

export const Container = tw.div`flex flex-col rounded-3xl bg-blue-450 px-6 pb-3 pt-2 
${({ $containerProps }: { $containerProps: InvoiceDocumentOptionsContainerProps }) => ($containerProps.anchorPosition === "left" ? "rounded-tl-none" : "rounded-tr-none")}
${({ $containerProps }: { $containerProps: InvoiceDocumentOptionsContainerProps }) => ($containerProps.useColumns ? "grid grid-cols-3 gap-4" : "")}
`;
export const Row = tw.div`gap-y-4 grid py-2 text-sm gap-x-2 items-center
${({ $containerProps }: { $containerProps: InvoiceDocumentOptionsContainerProps }) => ($containerProps.useColumns ? "grid-cols-1" : "grid-cols-2")}
`;
export const ButtonContent = tw.div`flex flex-row items-center py-0`;
export const AddIcon = tw(Add)`py-0 mr-1 text-xl text-white`;
export const CachedIcon = tw(Cached)`py-0 mr-1 text-xl text-white`;
export const SaveStatusPanel = tw.div`flex justify-end gap-1 text-xs text-green-800 items-center mt-2`;
export const CheckIcon = tw(Check)`w-5 h-5`;

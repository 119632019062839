import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  UpdateContractorReleaseInput,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useUpdateRelease = () => {
  const [updateContractorReleaseMutation, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const { setError } = useGlobalError();

  const updateRelease = useCallback(
    async (input: UpdateContractorReleaseInput) => {
      try {
        const result = await updateContractorReleaseMutation({
          variables: {
            input,
          },
        });
        setError(result.errors);
        return {
          data: result.data?.updateContractorRelease,
          errors: result.errors,
        };
      } catch (error) {
        setError(error);
        return null;
      }
    },
    [setError, updateContractorReleaseMutation],
  );

  return {
    updateRelease,
    updating,
  };
};

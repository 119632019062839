export const checkIfMultipleGroups = <T>(
  options: T[],
  groupBy?: (option: T) => string,
) => {
  if (!groupBy) {
    return false;
  }

  const groups = options.reduce((acc, option) => {
    const group = groupBy(option);
    if (!acc.includes(group)) {
      acc.push(group);
    }
    return acc;
  }, [] as string[]);

  return groups.length > 1;
};

import { ORDER_INVOICED_INVOICES_STATUS } from "@/common/const";
import {
  BuyoutDocument,
  BuyoutQuery,
  LinkPoInput,
  ReleaseDocument,
  ReleaseQuery,
  useLinkPoMutation,
} from "@/generated/graphql";
import { GraphQLError } from "graphql";

export const useLinkPo = () => {
  const [linkPoMutation, { loading: linkingPo }] = useLinkPoMutation();

  const linkPo = async (linkPoInput: LinkPoInput) => {
    try {
      const { data } = await linkPoMutation({
        variables: {
          input: linkPoInput,
        },
        update: (cache, { data }) => {
          if (linkPoInput.releaseId) {
            const rls = cache.readQuery<ReleaseQuery>({
              query: ReleaseDocument,
              variables: {
                id: linkPoInput.releaseId,
                invoiceStatuses: ORDER_INVOICED_INVOICES_STATUS,
              },
            });
            if (rls?.release && data?.linkPO) {
              cache.writeQuery({
                query: ReleaseDocument,
                variables: {
                  id: linkPoInput.releaseId,
                },
                data: {
                  release: {
                    ...rls.release,
                    poLink: data.linkPO,
                    poNumber: rls.release.poNumber,
                    useSourceSystemPO: true,
                  },
                },
              });
            }
          }
          if (linkPoInput.buyoutId) {
            const rls = cache.readQuery<BuyoutQuery>({
              query: BuyoutDocument,
              variables: {
                id: linkPoInput.buyoutId,
              },
            });
            if (rls?.buyout && data?.linkPO) {
              cache.writeQuery({
                query: BuyoutDocument,
                variables: {
                  id: linkPoInput.buyoutId,
                },
                data: {
                  buyout: {
                    ...rls.buyout,
                    poLink: {
                      ...data.linkPO,
                      syncedAt: Date.now(),
                    },
                    poNumber: rls.buyout.poNumber,
                  },
                },
              });
            }
          }
        },
      });
      return !!data?.linkPO;
    } catch (error) {
      if ((error as GraphQLError).message) {
        return (error as GraphQLError).message;
      }
      return false;
    }
  };

  return { linkPo, linkingPo };
};

import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  value: string | null | undefined;
};

const Container = tw.div`flex flex-row justify-end items-center h-10 text-sm flex-1 ${(props: { $error?: boolean }) => props.$error && "text-red-500"}`;
const NonEditableView = tw.div`ml-3 flex flex-1 flex-row items-center justify-between pr-3 gap-2`;
const Title = tw.div`font-bold flex items-center gap-1 justify-end text-right`;

export const DeliverySlipPoNumber: FC<Props> = ({ value }) => {
  return (
    <Container>
      <Title>
        <FormattedMessage id="PO_NUMBER" />:
      </Title>
      <NonEditableView className="mr-7">
        <NotNullableRenderer value={value}>{value}</NotNullableRenderer>
      </NonEditableView>
    </Container>
  );
};

import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import { useCallback, useState } from "react";
import { AddInventoryItemsDialog } from "../add-inventory-items/AddInventoryItemsDialog";

export const AddInventoryItemsButton = () => {
  const [showAddItemsDialog, setShowAddItemsDialog] = useState(false);

  const gotoItemSelection = useCallback(() => {
    setShowAddItemsDialog(true);
  }, [setShowAddItemsDialog]);

  return (
    <>
      <AddItemsButton onClick={gotoItemSelection} />
      <AddInventoryItemsDialog
        setVisible={setShowAddItemsDialog}
        visible={showAddItemsDialog}
        limitToCurrentWarehouse
      />
    </>
  );
};

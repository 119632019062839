import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { CurrentStockItemContainer } from "../../CurrentStockList.styles";
import { CurrentStockListItemProps } from "../../types/CurrentStockListItemProps";
import { useCurrentStockInStock } from "./useCurrentStockInStock";

export const CurrentStockInStock = ({ item }: CurrentStockListItemProps) => {
  const { classNames, onClick, disabled } = useCurrentStockInStock({ item });

  return (
    <CurrentStockItemContainer className={classNames.container}>
      <LinkLike onClick={onClick} disabled={disabled}>
        <ValueUnit
          value={item?.inStock ?? 0}
          classNames={{ item: classNames.item }}
          minimumFractionDigits={2}
        />
      </LinkLike>
    </CurrentStockItemContainer>
  );
};

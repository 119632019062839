import { ReleaseType } from "@/generated/graphql";
import tw from "tailwind-styled-components";

const Item = tw.div`py-2 inline-grid grid-flow-col relative gap-1.5`;
const ItemName = tw.span`text-sm ml-6`;

export const OrderTypeCustomRenderer = (item: Pick<ReleaseType, "name">) => {
  return (
    <Item>
      <ItemName>{item.name}</ItemName>
    </Item>
  );
};

import { ListSelectAll } from "@/common/components/list-select-all/ListSelectAll";
import { useIntl } from "react-intl";
import { useRfqs } from "../../providers/RfqsProvider";
import { useRfqSequence } from "../hooks/useRfqSequence";

export const RfqsSelectAll = () => {
  const intl = useIntl();
  const { totalCount } = useRfqs();
  const { selectedEntities, setSelectedEntities } = useRfqSequence();

  return (
    <ListSelectAll
      allItemsSelected={false}
      totalCount={totalCount}
      selectedCount={selectedEntities.length}
      itemNamePlural={intl.$t({ id: "QUOTES" })}
      itemNameSingular={intl.$t({ id: "QUOTE" })}
      clearAll={() => setSelectedEntities([])}
      hideSelectAll
    />
  );
};

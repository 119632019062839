import { useCurrencies } from "@/common/components/currency-picker/hooks/useCurrencies";
import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { OrgSettingsFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { encodeItemizationValue } from "./itemization-setting/useItemizationSelector";
import { encodeUseProjectCostCodes } from "./use-projects-cost-codes-selector/useUseProjectsCostCodesSelector";

type Props = PropsWithChildren;

const getFormValues = (
  orgId: string,
  settings: OrgSettingsFieldsFragment | null | undefined,
) => {
  return {
    id: orgId,
    settings: {
      display: {
        enableManufacturers: settings?.display?.enableManufacturers ?? false,
        itemCostTypes: settings?.display?.itemCostTypes ?? false,
        enableAdditionalCharges:
          settings?.display?.enableAdditionalCharges ?? false,
        requireProjectAssignment:
          settings?.display?.requireProjectAssignment ?? false,
        enableTaxVariance: settings?.display?.enableTaxVariance ?? false,
        defaultItemized: encodeItemizationValue(
          settings?.display?.defaultItemized,
        ),
        receiptDefaultItemized: encodeItemizationValue(
          settings?.display?.receiptDefaultItemized,
        ),
        usesProjectCostCodes: encodeUseProjectCostCodes(
          settings?.display?.usesProjectCostCodes ?? false,
        ),
        appendExternalCode: settings?.display?.appendExternalCode,
      },
      releases: {
        poNumbering: settings?.releases?.poNumbering,
        notifications: settings?.releases?.notifications,
        useSourceSystemPOs: settings?.releases?.useSourceSystemPOs,
        defaultWarehouseId: settings?.releases?.defaultWarehouse?.id,
        clearDefaultWarehouse: undefined,
        instructions: {
          text: settings?.releases?.instructions?.text ?? "",
          assetUrls:
            settings?.releases?.instructions?.assets.map(
              (asset) => asset.url,
            ) ?? [],
        },
      },
      invoices: {
        defaultPaymentTerm: settings?.invoices?.defaultPaymentTerm,
        requireCostCodesDuringApproval:
          settings?.invoices?.requireCostCodesDuringApproval,
        clearDefaultPaymentTerm: undefined,
      },
      project: {
        defaultTeam: settings?.projectSettings.defaultTeam?.map(
          (locationTeam) => ({
            orgLocationId: locationTeam.orgLocation.id,
            userIds: locationTeam.users.map((user) => user.id),
          }),
        ),
      },
      inventory: {
        enabled: settings?.inventory.enabled ?? false,
      },
    },
  };
};

export type OrgSettingsFormInput = ReturnType<typeof getFormValues>;

export const OrgSettingsFormHookProvider: FC<Props> = ({ children }) => {
  const { settings, orgId } = useOrgSettings();
  const { currencies } = useCurrencies();
  const methods = useForm<OrgSettingsFormInput>({
    defaultValues: getFormValues(orgId, settings),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { setAssets } = useUploadAssets();

  useEffect(() => {
    setAssets(settings?.releases?.instructions?.assets ?? []);
  }, [setAssets, settings?.releases?.instructions?.assets]);

  useEffect(() => {
    if (orgId) {
      methods.reset(getFormValues(orgId, settings));
    }
  }, [methods, currencies, orgId, settings]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

import { ExternalProjectsTable } from "@/common/components/external-projects/ExternalProjectsTable";
import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { DEFAULT_ADDRESS_COUNTRY } from "@/common/const";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { isValidUSPostalCode } from "@/common/utils/validationUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { AnimatedLoading } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";
import {
  ExternalProjectFieldsFragment,
  ProjectStatus,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { InfoOutlined } from "@mui/icons-material";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useProjectsHooks } from "../../hooks/useProjectsHooks";
import { useRefreshExternalProjects } from "../../hooks/useRefreshExternalProjects";
import { useProjects } from "../../providers/ProjectsProvider";
import { ProjectsImportForm } from "./ProjectsImportForm";
import { ProjectsImportFormHeader } from "./ProjectsImportFormHeader";
import { SelectedExternalProjects } from "./SelectedExternalProjects";
const ImportStatus = tw.div`flex items-center gap-2`;
const InfoStyled = tw(InfoOutlined)`text-gray-500`;

type ProviderContextType = {
  pages: WizardModalPage[];
  modalOpened: boolean;
  openModal: () => void;
  closeModal: () => void;

  selectedExternalProjects: ExternalProjectFieldsFragment[];
};

export type ProjectToImport = {
  externalId: string;
  name: string;
  jobNumber: string | null | undefined;
  address: {
    addressLine1: string;
    addressLine2: string | null | undefined;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  budget: string;
  startDate?: number | null;
};

type ImportProjectFormType = {
  projects: ProjectToImport[];
  status: ProjectStatus;
  team: string[];
  locationId: string;
  templateProjectId?: string;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  modalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,

  selectedExternalProjects: [],
});

export const ImportExternalProjectsWizardProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();

  const [modalOpened, setModalOpened] = useState(false);
  const { connectedSourceSystem } = useOrgSettings();
  const [selectedExternalProjects, setSelectedExternalProjects] = useState<
    ExternalProjectFieldsFragment[]
  >([]);
  const { moveToNextNestedStep, setNestedStep, moveToPreviousNestedStep } =
    useNestedStepper();
  const { createProjects } = useProjectsHooks();
  const form = useForm<ImportProjectFormType>({
    defaultValues: {
      projects: [],
      team: [],
      status: ProjectStatus.Awarded,
      templateProjectId: undefined,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { locations } = useUserLocations({
    permission: "createProject",
  });
  const { refetchProjects } = useProjects();

  const { handleRefreshExternalProjects, refreshingExternalProjects } =
    useRefreshExternalProjects(connectedSourceSystem || null);

  const openModal = useCallback(() => {
    setModalOpened(true);
  }, []);

  const resetModal = useCallback(() => {
    setSelectedExternalProjects([]);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpened(false);
    resetModal();
    setNestedStep(0);
    form.reset();
  }, [form, resetModal, setNestedStep]);

  const onImportCompleted = useCallback(() => {
    refetchProjects();
    closeModal();
  }, [closeModal, refetchProjects]);

  const canImportProject = useCallback((project: ProjectToImport) => {
    return (
      project.name &&
      project.address &&
      project.address.addressLine1 &&
      project.address.city &&
      project.address.state &&
      project.address.postalCode &&
      project.address.country &&
      (project.address.country !== DEFAULT_ADDRESS_COUNTRY ||
        isValidUSPostalCode(project.address.postalCode))
    );
  }, []);

  const handleCreateProjects = useCallback(async () => {
    const location = locations.find(
      (l) => l.id === form.getValues("locationId"),
    );
    if (!connectedSourceSystem) {
      return;
    }
    const projectsToCreate = selectedExternalProjects.map((p) => ({
      externalId: p.externalId,
      name: p.name,
      jobNumber: p.number === "" ? null : p.number,
      address: {
        addressLine1: p.address?.addressLine1 || "",
        addressLine2: p.address?.addressLine2 || "",
        city: p.address?.city || "",
        state: p.address?.state || location?.address?.state || "",
        postalCode: p.address?.postalCode || "",
        country:
          p.address?.country ||
          location?.address?.country ||
          DEFAULT_ADDRESS_COUNTRY,
      },
      budget: p.amount?.toString() || "",
      startDate: p.startDate?.valueOf(),
      team: form.getValues("team"),
      status: form.getValues("status"),
      locationId: form.getValues("locationId"),
      taxExempt: false,
      templateProjectId: form.getValues("templateProjectId") || undefined,
    }));
    const validProjects = projectsToCreate.filter(canImportProject).map((p) => {
      const { externalId, ...project } = p;

      return {
        ...project,
        budget: project.budget ? project.budget : null,
        mappings: [
          {
            externalId,
            sourceSystem: connectedSourceSystem,
          },
        ],
        startDate: p.startDate?.valueOf(),
      };
    });
    const invalidProjects = projectsToCreate.filter(
      (p) =>
        !validProjects
          .map((p) => p.mappings[0].externalId)
          .includes(p.externalId),
    );
    if (validProjects.length) {
      moveToNextNestedStep();
      const result = await createProjects({ projects: validProjects });
      if (result) {
        if (invalidProjects.length) {
          form.setValue("projects", invalidProjects);
          moveToNextNestedStep();
        } else {
          setNestedStep(4);
        }
      } else {
        moveToPreviousNestedStep();
      }
    } else {
      form.setValue("projects", invalidProjects);
      setNestedStep(2);
    }
  }, [
    canImportProject,
    connectedSourceSystem,
    createProjects,
    form,
    locations,
    moveToNextNestedStep,
    moveToPreviousNestedStep,
    selectedExternalProjects,
    setNestedStep,
  ]);

  const handleCreateRemainingProjects = useCallback(async () => {
    if (!connectedSourceSystem) {
      return;
    }
    const remainingProjects = form.getValues("projects").map((p) => {
      const { externalId, ...project } = p;
      return {
        ...project,
        budget: project.budget?.toString() || null,
        team: form.getValues("team"),
        status: form.getValues("status"),
        locationId: form.getValues("locationId"),
        startDate: project.startDate?.valueOf(),
        mappings: [
          {
            externalId,
            sourceSystem: connectedSourceSystem,
          },
        ],
        taxExempt: false,
        templateProjectId: form.getValues("templateProjectId") || undefined,
      };
    });
    moveToNextNestedStep();
    const result = await createProjects({ projects: remainingProjects });
    if (result) {
      moveToNextNestedStep();
    } else {
      moveToPreviousNestedStep();
    }
  }, [
    connectedSourceSystem,
    createProjects,
    form,
    moveToNextNestedStep,
    moveToPreviousNestedStep,
  ]);

  const pages: WizardModalPage[] = useMemo(() => {
    if (!connectedSourceSystem) {
      return [];
    }
    return [
      {
        title: null,
        pages: [
          {
            component: (
              <ExternalProjectsTable
                multipleSelection={{
                  selectedItemIds: selectedExternalProjects.map(
                    (p) => p.externalId,
                  ),
                  setSelectedItems: setSelectedExternalProjects,
                  type: "multiple",
                }}
                sourceSystem={connectedSourceSystem}
                title={intl.$t({ id: "IMPORT_PROJECTS" })}
                additionalFilters={<ProjectsImportFormHeader />}
                selectedProjects={selectedExternalProjects}
                filterProjectsByStatus
              />
            ),
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "outlined",
                onClick: handleRefreshExternalProjects,
                loading: refreshingExternalProjects,
                text: intl.$t(
                  { id: "SYNC_FROM_SOURCE_SYSTEM" },
                  {
                    sourceSystem: intl.$t({
                      id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                    }),
                  },
                ),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: handleCreateProjects,
                text: intl.$t(
                  { id: "IMPORT_COUNT" },
                  { count: selectedExternalProjects.length },
                ),
                className: "flex-1",
                disabled:
                  selectedExternalProjects.length === 0 ||
                  !form.watch("locationId") ||
                  !form.watch("status") ||
                  (form.watch("team") || []).length === 0,
              },
            ],
            rightSidePanel: (
              <SelectedExternalProjects
                externalProjects={selectedExternalProjects}
                onRemove={(id: string) =>
                  setSelectedExternalProjects(
                    selectedExternalProjects.filter((i) => i.externalId !== id),
                  )
                }
              />
            ),
          },
          {
            component: (
              <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
            ),
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            hideHeader: false,
            customHeader: (
              <ImportStatus>
                <InfoStyled />
                <FormattedMessage
                  id={
                    selectedExternalProjects.length !==
                    form.watch("projects").length
                      ? `IMPORTED_PROJECTS_STATUS`
                      : `IMPORTED_PROJECTS_STATUS_NO_PROJECTS`
                  }
                  values={{
                    total: selectedExternalProjects.length,
                    remaining:
                      selectedExternalProjects.length -
                      form.watch("projects").length,
                  }}
                />
              </ImportStatus>
            ),
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: handleCreateRemainingProjects,
                text: intl.$t(
                  { id: "SAVE_AND_IMPORT_COUNT" },
                  { count: form.watch("projects").length },
                ),
                className: "flex-1",
                disabled:
                  !form.formState.isValid || !form.watch("projects").length,
              },
            ],
            component: <ProjectsImportForm />,
          },
          {
            component: (
              <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
            ),
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: (
              <CompletedStep
                text={intl.$t({ id: "IMPORT_COMPLETED" })}
                onTimeoutCompleted={onImportCompleted}
              />
            ),
            onModalClose: closeModal,
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
        ],
      },
    ];
  }, [
    connectedSourceSystem,
    selectedExternalProjects,
    intl,
    closeModal,
    handleRefreshExternalProjects,
    refreshingExternalProjects,
    handleCreateProjects,
    form,
    handleCreateRemainingProjects,
    onImportCompleted,
  ]);

  return (
    <ProviderContext.Provider
      value={{
        modalOpened,
        pages,
        openModal,
        closeModal,

        selectedExternalProjects,
      }}
    >
      <FormProvider {...form}>{children}</FormProvider>
    </ProviderContext.Provider>
  );
};

export const useImportExternalProjectsWizard = () =>
  useContext(ProviderContext);

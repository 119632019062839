import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { useGetWarehouseById } from "@/common/hooks/useGetWarehouseById";
import {
  OrgPreferredVendorsFieldsFragment,
  SiteContactFieldsFragment,
} from "@/generated/graphql";
import { Address } from "@/types/Address";
import React, { FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Address as AddressView } from "../../address/Address";
import { Tooltip } from "../../tooltip/Tooltip";
import { VendorList } from "../../vendor-list/VendorList";
import { ContactWithPhoneNumber } from "./ContactWithPhoneNumber";
import { InfoHeaderItem } from "./InfoHeaderItem";
import { InfoHeaderItemTitle } from "./InfoHeaderItem.styles";

const Details = tw.div`text-xs md:text-sm line-clamp-3 flex-col gap-1`;
const ListContent = tw.div`grid grid-flow-row justify-start items-start gap-1`;
const ListItem = tw.div`grid grid-cols-[auto_1fr] gap-2`;
const Name = tw.div`text-sm font-medium`;
const Content = tw.div`grid grid-cols-[auto_1fr] md:grid-cols-auto gap-2`;
const ContactContainer = tw.div`flex gap-5 text-xs pt-1`;
const Contact = tw.div`flex gap-1`;
const ContactHeader = tw.div`font-light`;
const NameContainer = tw.div`flex items-center`;
const RightDashedDivider = tw.div`border-dashed border-r border-gray-400 h-full mr-8`;

type Props = {
  title?: string | React.JSX.Element;
  name?: string | React.JSX.Element;
  details?: string | null;
  project?: string | null;
  complianceGroup?: string | null;
  logoImageUrl?: string | null;
  smallLogo?: boolean;
  address?: Address | null;
  includeAddress?: boolean;
  navTo?: string;
  warehouseId?: string;
  hideInMobile?: boolean;
  siteContact?: SiteContactFieldsFragment | null;
  useDefaultTitlePosition?: boolean;
  jobNumber?: string | null;
  logoIcon?: React.JSX.Element;
  vendorContacts?:
    | Pick<
        OrgPreferredVendorsFieldsFragment["contacts"][0],
        "name" | "cellPhone" | "email" | "id"
      >[]
    | undefined;
  classes?: {
    details?: string;
  };
  nameAdditionalComponent?: ReactNode;
  additionalKeyValueItems?: Record<
    string,
    string | null | undefined | ReactNode
  >;
  includeRightDivider?: boolean;
  detailsTooltip?: string;
};

export const OrgDetailsHeaderItem: FC<Props> = ({
  title,
  name,
  details,
  complianceGroup,
  logoImageUrl,
  smallLogo,
  address,
  includeAddress = true,
  warehouseId,
  navTo,
  siteContact,
  hideInMobile = false,
  useDefaultTitlePosition = true,
  jobNumber,
  logoIcon,
  vendorContacts,
  classes,
  project,
  nameAdditionalComponent,
  additionalKeyValueItems,
  includeRightDivider,
  detailsTooltip,
}) => {
  const warehouse = useGetWarehouseById(warehouseId);

  return (
    <InfoHeaderItem
      title={useDefaultTitlePosition ? title : undefined}
      hideInMobile={hideInMobile}
      classes={{ content: logoImageUrl ? "justify-start" : undefined }}
    >
      <If isTrue={logoImageUrl}>
        <OrgLogo
          logoImageUrl={logoImageUrl}
          name={details as string}
          width={smallLogo ? 40 : 50}
          className="hidden 2xl:grid"
        />
      </If>
      <If isTrue={logoIcon}>{logoIcon}</If>
      <Content>
        <ListContent>
          <If isTrue={!useDefaultTitlePosition}>
            <InfoHeaderItemTitle>{title}</InfoHeaderItemTitle>
          </If>
          <If isTrue={name}>
            <NameContainer>
              <If isTrue={navTo}>
                <LinkLike to={navTo} forwardEvent>
                  {name}
                </LinkLike>
              </If>
              <If isTrue={!navTo}>
                <Name>{name}</Name>
              </If>
              {nameAdditionalComponent}
            </NameContainer>
          </If>
          <If isTrue={details}>
            <Tooltip
              id="details-tooltip-id"
              element={
                <Details className={classes?.details}>{details}</Details>
              }
              hideTooltip={!detailsTooltip}
            >
              {detailsTooltip}
            </Tooltip>
          </If>
          <If isTrue={includeAddress}>
            <Details>
              <If isTrue={warehouse}>
                <FormattedMessage
                  id="FORMATTED_WAREHOUSE_NAME"
                  values={{ name: warehouse?.name }}
                />{" "}
                -{" "}
              </If>
              <AddressView
                address={warehouse?.address ?? address}
                customEmptyText="NOT_SPECIFIED"
              />
            </Details>
          </If>
          <If isTrue={jobNumber}>
            <Details>
              <FormattedMessage
                id="JOB_NUMBER_WITH_NO_AND_COLON"
                values={{ jobNumber }}
              />
            </Details>
          </If>
          <If isTrue={siteContact}>
            <ContactContainer>
              <Contact>
                <ContactHeader>
                  <FormattedMessage id="PROJECT_SITE_CONTACT" />:
                </ContactHeader>
                <ContactWithPhoneNumber
                  name={siteContact?.name}
                  phone={siteContact?.phone}
                />
              </Contact>
            </ContactContainer>
          </If>
          <If isTrue={vendorContacts?.length}>
            <ContactContainer>
              <Contact>
                <ContactHeader>
                  <FormattedMessage id="CONTACT" />:
                </ContactHeader>
                <VendorList contacts={vendorContacts} />
              </Contact>
            </ContactContainer>
          </If>
          <If isTrue={project}>
            <ListItem>
              <Name>
                <FormattedMessage id="PROJECT" />:
              </Name>
              {project}
            </ListItem>
          </If>
          <If isTrue={complianceGroup}>
            <ListItem>
              <ContactHeader>
                <FormattedMessage id="COMPLIANCE_GROUP" />:
              </ContactHeader>
              {complianceGroup}
            </ListItem>
          </If>
          {additionalKeyValueItems &&
            Object.entries(additionalKeyValueItems).map(([key, value]) => (
              <ListItem key={key}>
                <Name>{key}:</Name>
                {value || "-"}
              </ListItem>
            ))}
        </ListContent>
      </Content>
      <If isTrue={includeRightDivider}>
        <RightDashedDivider />
      </If>
    </InfoHeaderItem>
  );
};

import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { NewRfqFormType } from "./NewRfqFormType";
import { UpdateRfqVendorsFormType } from "./UpdateRfqVendorsFormType";

type Props = {
  sellerOrgLocationId: string;
  contactIds?: string[] | null;
};

export const RfqFormVendorContacts = ({
  sellerOrgLocationId,
  contactIds = [],
}: Props) => {
  const { vendors } = useVendors();
  const { setValue, watch } = useFormContext<
    UpdateRfqVendorsFormType | NewRfqFormType
  >();
  const initialized = useRef(false);

  const vendorIds = watch("vendorIds");

  const vendor = useMemo(
    () =>
      vendors.find(
        (vendor) => vendor.sellerOrgLocation.id === sellerOrgLocationId,
      ),
    [sellerOrgLocationId, vendors],
  );

  const onContactsChanged = useCallback(
    (values: string[] | null) => {
      setValue(
        "vendorIds",
        vendorIds?.map((vendorId) =>
          vendorId.sellerOrgLocationId === sellerOrgLocationId
            ? { sellerOrgLocationId, contactIDs: values ?? [] }
            : vendorId,
        ) ?? [],
      );
    },
    [sellerOrgLocationId, setValue, vendorIds],
  );

  useEffect(() => {
    if (!vendor || initialized.current) {
      return;
    }
    if (!contactIds?.length) {
      onContactsChanged(
        vendor.contacts
          .filter((contact) => contact.receivesQuoteNotifications)
          .map((contact) => contact.id),
      );
    }
    initialized.current = true;
  }, [contactIds?.length, onContactsChanged, vendor]);

  if (!vendor) {
    return null;
  }
  return (
    <Multiselect
      values={contactIds ?? []}
      onMultipleChange={onContactsChanged}
      options={vendor.contacts}
      getValue={(o) => o.id}
      getLabel={(o) => o.name}
      noWrap
      limitTags={2}
      chipSize="small"
      includeCheckbox
      disableCloseOnSelect
      label={vendorLabelFormatter(vendor.sellerOrgLocation)}
      InputProps={{
        startAdornment: (
          <OrgLogo
            logoImageUrl={vendor.sellerOrgLocation?.org?.photoUrl}
            name={
              vendor.sellerOrgLocation?.org.id
                ? vendor.sellerOrgLocation?.org?.name
                : ""
            }
            width={24}
            className="mr-2"
          />
        ),
      }}
      required
      error={!contactIds?.length}
    />
  );
};

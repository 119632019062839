import { FieldMappingTargetProperty } from "@/generated/graphql";
import { FMColumn } from "./MaterialFileMapper";

export const FILE_MAPPER_COLUMNS: FMColumn[] = [
  {
    name: "NAME",
    details: "NAME_DETAILS",
    value: FieldMappingTargetProperty.Name,
    required: true,
  },
  {
    name: "PART_NUMBER",
    details: "PART_NUMBER_DETAILS",
    value: FieldMappingTargetProperty.PartNumber,
    required: false,
  },
  {
    name: "UOM",
    details: "UOM_DETAILS",
    value: FieldMappingTargetProperty.Uom,
    required: false,
  },
  {
    name: "QUANTITY",
    details: "QUANTITY_DETAILS",
    value: FieldMappingTargetProperty.Quantity,
    required: false,
  },
  {
    name: "UNIT_PRICE",
    details: "UNIT_PRICE_DETAILS",
    value: FieldMappingTargetProperty.UnitPrice,
    required: false,
  },
  {
    name: "TAGS",
    details: "TAGS",
    value: FieldMappingTargetProperty.Tag,
    required: false,
  },
  {
    name: "SUPPLIER",
    details: "VENDOR_DETAILS",
    value: FieldMappingTargetProperty.Vendor,
    required: false,
  },
  {
    name: "SECTION_NAME",
    details: "SECTION_NAME_DETAILS",
    value: FieldMappingTargetProperty.SectionName,
    required: false,
  },
  {
    name: "COST_CODE",
    details: "COST_CODE_DETAILS",
    value: FieldMappingTargetProperty.CostCode,
    required: false,
  },
  {
    name: "ZONE",
    details: "ZONE_DETAILS",
    value: FieldMappingTargetProperty.Zone,
    required: false,
  },
  {
    name: "MANUFACTURER",
    details: "MANUFACTURER_DETAILS",
    value: FieldMappingTargetProperty.Manufacturer,
    required: false,
  },
  {
    name: "NOTES",
    details: "NOTES_DETAILS",
    value: FieldMappingTargetProperty.Notes,
    required: false,
  },
];

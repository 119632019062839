import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { InventoryItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useSwitchInventoryItems } from "../../../hooks/useSwitchInventoryItems";

const Container = tw.div`grid`;

type Props = {
  item: InventoryItemFieldsFragment;
};

export const InventoryItemCheckbox: FC<Props> = ({ item }) => {
  const { selectedItem, setSelectedItem } = useSwitchInventoryItems();

  const onToggle = useCallback(
    (checked: boolean) => {
      setSelectedItem(checked ? item.id : "");
    },
    [item, setSelectedItem],
  );

  const checked = useMemo(() => selectedItem === item.id, [selectedItem, item]);

  return (
    <Container>
      <SelectionCheckbox
        testId="add-inventory-item-checkbox"
        checked={checked}
        setSelection={onToggle}
      />
    </Container>
  );
};

import { OrgCatalogSku, OrgMaterialFieldsFragment } from "@/generated/graphql";
import { isOrgCatalogSku } from "../components/material/utils";

export const composeMaterialName = (
  material: OrgMaterialFieldsFragment,
  materials: OrgMaterialFieldsFragment[],
  appendExternalCode: boolean,
) => {
  if (isOrgCatalogSku(material?.material)) {
    const hasDuplicates =
      materials.filter((mat) => mat.material.name === material.material.name)
        .length > 1;
    const name = material.material.name;
    const partNumber = (material.material as OrgCatalogSku).customPartNumber;
    return `${name}${(hasDuplicates || appendExternalCode) && partNumber ? `〈${partNumber}〉` : ""}`;
  }

  return `${material.material.name}`;
};

import { Dialog } from "@/common/components/dialog/Dialog";
import { If } from "@/common/components/if/If";
import { InfoOutlineRed } from "@/common/components/import-external-po/import-external-po-wizard/importable-external-pos/items/ExternalPO.styles";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { WarehouseSelectorControlled } from "@/common/components/warehouse-selector/WarehouseSelectorControlled";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { ProjectListOptionFieldsFragment } from "@/generated/graphql";
import { DialogContent } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  SelectProjectDialogForm,
  SelectProjectDialogFormValues,
} from "./SelectProjectDialogForm";

const InfoIcon = tw(InfoOutlineRed)`text-blue-500 text-9xl -mb-10`;
const Title = tw.div`text-2xl font-bold text-center mb-4`;
const DialogContentStyled = tw(DialogContent)`grid gap-y-4`;

type SelectProjectDialogProps = {
  open: boolean;
  onClose: () => void;
  labelKey: string;
  handleConfirm: (data: SelectProjectDialogFormValues) => void;
  restock?: boolean;
  creating?: boolean;
};

const SelectProjectDialogWithForm = ({
  open,
  onClose,
  labelKey,
  handleConfirm,
  restock,
  creating,
}: SelectProjectDialogProps) => {
  const intl = useIntl();
  const { handleSubmit, setValue } =
    useFormContext<SelectProjectDialogFormValues>();

  const closeDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  const filterProjects = useCallback(
    (project: ProjectListOptionFieldsFragment) => {
      return restock ? project.preferredInventoryInflows : true;
    },
    [restock],
  );
  const { projects } = useProjectListOptions({ filterResults: filterProjects });
  const { warehouses } = useWarehouses();

  useEffect(() => {
    if (open && projects.length === 1 && warehouses.length === 1) {
      handleSubmit(handleConfirm)();
      onClose();
    }
  }, [
    projects.length,
    warehouses.length,
    handleSubmit,
    handleConfirm,
    onClose,
    open,
  ]);

  useEffect(() => {
    if (projects.length === 1 && restock) {
      setValue("projectId", projects[0].id);
    }
  }, [projects, restock, setValue]);

  return (
    <Dialog
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "PROCEED" })}
      icon={<InfoIcon />}
      handleConfirm={handleSubmit(handleConfirm)}
      handleCancel={closeDialog}
      saving={creating}
      sx={{
        "& .MuiDialog-paper": {
          width: "420px",
        },
      }}
      show={open}
      content={
        <DialogContentStyled>
          <FormattedMessage id={labelKey} tagName={Title} />
          <If isTrue={!restock || projects.length !== 1}>
            <SelectControlled
              name="projectId"
              placeholder={intl.$t({ id: "PROJECT" })}
              options={projects}
              getLabel={(option) => getProjectSelectorLabel(option)}
              getValue={(option) => option.id}
              noOptionsText={intl.$t({ id: "NO_PROJECTS_FOUND" })}
              required
              rules={{ required: true }}
              inputProps={{ className: "text-sm" }}
            />
          </If>
          <WarehouseSelectorControlled
            name="warehouseId"
            placeholder={intl.$t({ id: "WAREHOUSE" })}
            required
          />
        </DialogContentStyled>
      }
    />
  );
};

export const SelectProjectDialog = (props: SelectProjectDialogProps) => {
  return (
    <SelectProjectDialogForm restock={props.restock}>
      <SelectProjectDialogWithForm {...props} />
    </SelectProjectDialogForm>
  );
};

import { ListSelectAll } from "@/common/components/list-select-all/ListSelectAll";
import { useIntl } from "react-intl";
import { useInvoiceSequence } from "../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../providers/InvoicesProvider";

export const InvoicesSelectAll = () => {
  const intl = useIntl();
  const { totalCount } = useInvoices();
  const { selectedInvoices, setSelectedInvoices } = useInvoiceSequence();

  return (
    <ListSelectAll
      allItemsSelected={false}
      totalCount={totalCount}
      selectedCount={selectedInvoices.length}
      itemNamePlural={intl.$t({ id: "INVOICES" })}
      itemNameSingular={intl.$t({ id: "INVOICE" })}
      clearAll={() => setSelectedInvoices([])}
      hideSelectAll
    />
  );
};

import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import {
  ProjectExtendedFieldsFragment,
  SourceSystem,
  UpdateProjectInput,
  UpdateProjectMutation,
  useConnectProjectMutation,
  useDisconnectProjectMutation,
  useProjectQuery,
  useUpdateProjectMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useParams } from "react-router";
import { useSetCurrentProjectId } from "../hooks/useSetCurrentProjectId";

type ProviderContextType = {
  project: ProjectExtendedFieldsFragment | null;
  loading: boolean;
  searchQuery: string | undefined;
  setSearchQuery: (query: string) => void;
  updateProject: (
    projectInput: UpdateProjectInput,
  ) => Promise<UpdateProjectMutation["updateProject"] | undefined>;
  connectProject: (
    sourceSystem: SourceSystem,
    externalId: string,
  ) => Promise<boolean>;
  disconnectProject: (sourceSystem: SourceSystem) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  project: null,
  loading: false,
  searchQuery: "",
  setSearchQuery: NoFunction,
  updateProject: NoFunctionUndefinedPromise,
  connectProject: NoFunctionBooleanPromise,
  disconnectProject: NoFunctionBooleanPromise,
});

export const ProjectProvider: FC<{
  children: React.ReactNode;
  id?: string;
}> = ({ children, id }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { id: projId, projectId } = useParams();
  const { setError } = useGlobalError();
  const { data, error, loading } = useProjectQuery({
    variables: { id: id || projectId || projId || "", excludePhantoms: true },
    skip: !id && !projectId && !projId,
  });
  const [updateExistingProject] = useUpdateProjectMutation();
  const [connectProjectMutation] = useConnectProjectMutation();
  const [disconnectProjectMutation] = useDisconnectProjectMutation();
  const { refetch: refetchProjectListOptions } = useProjectListOptions({
    skip: true,
  });
  useSetCurrentProjectId(data?.project?.id);
  useErrorEffect(error);

  const updateProject = async (projectInput: UpdateProjectInput) => {
    try {
      const {
        locationId,
        id,
        name,
        address,
        jobNumber,
        status,
        startDate,
        budget,
        team,
        releaseInstructions,
        taxExempt,
        poJobNumber,
        usesProjectCostCodes,
        preferredInventoryInflows,
      } = projectInput;
      const newAddress =
        address?.addressLine1 && address.city
          ? {
              addressLine1: address.addressLine1,
              addressLine2: address.addressLine2,
              city: address.city,
              country: address.country,
              postalCode: address.postalCode,
              state: address.state,
            }
          : null;

      const { data: updatedProject, errors } = await updateExistingProject({
        variables: {
          input: {
            id,
            name,
            locationId,
            address: newAddress,
            jobNumber,
            startDate,
            status,
            budget,
            team,
            releaseInstructions: {
              text: releaseInstructions?.text,
              assetUrls: releaseInstructions?.assetUrls,
            },
            taxExempt,
            poJobNumber,
            usesProjectCostCodes,
            preferredInventoryInflows,
          },
        },
      });
      setError(errors);
      if (updatedProject?.updateProject) {
        refetchProjectListOptions();
        return updatedProject?.updateProject;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  const connectProject = async (
    sourceSystem: SourceSystem,
    externalId: string,
  ) => {
    try {
      const { data: connectedProject, errors } = await connectProjectMutation({
        variables: {
          input: {
            nodeId: id || projectId || projId || "",
            externalId,
            sourceSystem,
          },
        },
      });
      setError(errors);
      return !!connectedProject?.connectProject;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const disconnectProject = async (sourceSystem: SourceSystem) => {
    try {
      const { data: disconnectedProject, errors } =
        await disconnectProjectMutation({
          variables: {
            input: {
              nodeId: id || projectId || projId || "",
              sourceSystem,
            },
          },
        });
      setError(errors);
      return !!disconnectedProject?.disconnectProject;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        project: data?.project || null,
        loading,
        searchQuery,
        setSearchQuery,
        updateProject,
        connectProject,
        disconnectProject,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useProject = (): ProviderContextType =>
  useContext(ProviderContext);

export enum MatchedOrderViewState {
  DEFAULT = "DEFAULT",
  MATCH_ORDER = "MATCH_ORDER",
  CREATE_ORDER = "CREATE_ORDER",
  EDIT_INVOICE_COVERAGES = "EDIT_INVOICE_COVERAGES",
  EDIT_ORDER = "EDIT_ORDER",
  FULFILLED = "FULFILLED",
  IMPORT_ORDER = "IMPORT_ORDER",
  HEADER_ONLY_INVOICE_EDITABLE = "HEADER_ONLY_INVOICE_EDITABLE",
  HEADER_ONLY_INVOICE = "HEADER_ONLY_INVOICE",
}

export const DEFAULT_MATCHED_ORDER_VIEW_STATES = [
  MatchedOrderViewState.DEFAULT,
  MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
  MatchedOrderViewState.FULFILLED,
];

import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { TransactionHistoryListViewDateRenderer } from "../../../../../common/transaction-history-list-view-date-renderer/TransactionHistoryListViewDateRenderer";

type Props = {
  item: Pick<InventoryTransactionSummaryFieldsFragment, "date">;
};

export const TransactionDate = ({ item }: Props) => (
  <TransactionHistoryListViewDateRenderer
    className="text-xs"
    date={item.date}
    includeTime={false}
  />
);

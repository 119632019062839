import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  ApproveInvoiceInput,
  ReceiptFieldsFragment,
  ReleaseSelectorFieldsFragment,
  UpdateInvoiceInput,
  UpdateReceiptMutation,
  useReceiptQuery,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { useApproveReceipt } from "../../receipts/hooks/useApproveReceipt";
import { useUpdateReceipt } from "../../receipts/hooks/useUpdateReceipt";
import { useIsReceiptItemized } from "../hooks/useIsReceiptItemized";
import { ReceiptFooterState } from "../types/ReceiptFooterState";
import { ReceiptMatchedOrderViewState } from "../types/ReceiptMatchedOrderViewState";

type ProviderContextType = {
  receipt: ReceiptFieldsFragment | null;
  updateReceipt: (
    input: UpdateInvoiceInput,
    args?: { includeDocuments?: boolean },
  ) => Promise<UpdateReceiptMutation | null | undefined>;
  approveReceipt: (
    input: ApproveInvoiceInput,
    args?: { hasMissingCostCodes?: boolean },
  ) => Promise<boolean>;
  footerState: ReceiptFooterState;
  setFooterState: (state: ReceiptFooterState) => void;
  loading: boolean;
  itemized: boolean;
  setItemized: (itemized: boolean) => void;
  hasReleaseRecorded: boolean;
  isItemized: (
    receipt: ReceiptFieldsFragment | null | undefined,
  ) => boolean | null;
  refetch: () => Promise<void>;
  preselectedPoNumber: string;
  setPreselectedPoNumber: (poNumber: string) => void;
  matchedOrderViewState: ReceiptMatchedOrderViewState;
  setMatchedOrderViewState: (status: ReceiptMatchedOrderViewState) => void;
  selectedRelease: ReleaseSelectorFieldsFragment | null;
  setSelectedRelease: (release: ReleaseSelectorFieldsFragment | null) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  receipt: null,
  updateReceipt: NoFunctionUndefinedPromise,
  approveReceipt: NoFunctionBooleanPromise,
  footerState: ReceiptFooterState.DEFAULT,
  setFooterState: NoFunction,
  loading: false,
  itemized: false,
  setItemized: NoFunction,
  hasReleaseRecorded: false,
  isItemized: NoFunction,
  refetch: NoFunctionPromise,
  preselectedPoNumber: "",
  setPreselectedPoNumber: NoFunction,
  matchedOrderViewState: ReceiptMatchedOrderViewState.DEFAULT,
  setMatchedOrderViewState: NoFunction,
  selectedRelease: null,
  setSelectedRelease: NoFunction,
});

export const ReceiptProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const intl = useIntl();
  const { setSystemAlert } = useSnackbar();
  const { settings } = useOrgSettings();
  const { initViewStore, resetViewStore, setViewState } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
      setViewState: state.setViewState,
    })),
  );
  const { receiptId } = useParams();
  const isItemized = useIsReceiptItemized();

  const [footerState, setFooterState] = useState<ReceiptFooterState>(
    ReceiptFooterState.DEFAULT,
  );
  const [matchedOrderViewState, setMatchedOrderViewState] =
    useState<ReceiptMatchedOrderViewState>(
      ReceiptMatchedOrderViewState.DEFAULT,
    );
  const [selectedRelease, setSelectedRelease] =
    useState<ReleaseSelectorFieldsFragment | null>(null);
  const [preselectedPoNumber, setPreselectedPoNumber] = useState("");

  const { data, loading, error, refetch } = useReceiptQuery({
    variables: { id: receiptId || "" },
    skip: !receiptId,
  });

  const [itemized, setItemized] = useState<boolean>(
    data?.receipt?.release
      ? isItemized(data?.receipt)
      : (settings?.display?.receiptDefaultItemized ?? false),
  );

  useEffect(() => {
    initViewStore(TableViewState.spreadsheet);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  useEffect(() => {
    setViewState(itemized ? TableViewState.spreadsheet : TableViewState.normal);
  }, [itemized, setViewState]);

  const { updateReceipt: updateReceiptMutation } = useUpdateReceipt();

  const updateReceipt = useCallback(
    async (
      input: UpdateInvoiceInput,
      { includeDocuments }: { includeDocuments?: boolean } = {},
    ): Promise<UpdateReceiptMutation | null | undefined> => {
      if (!receiptId) {
        return null;
      }
      return await updateReceiptMutation(input, includeDocuments);
    },
    [receiptId, updateReceiptMutation],
  );

  const { approveReceipt: approveInvoiceMutation } = useApproveReceipt();
  const approveReceipt = useCallback(
    async (
      input: ApproveInvoiceInput,
      { hasMissingCostCodes }: { hasMissingCostCodes?: boolean } = {},
    ): Promise<boolean> => {
      if (
        settings?.invoices.requireCostCodesDuringApproval &&
        hasMissingCostCodes
      ) {
        setSystemAlert(
          intl.$t(
            { id: "RECEIPT_APPROVAL_MISSING_COST_CODES_ERROR" },
            { orderNumber: data?.receipt.release?.sequenceNumber },
          ),
          { type: SystemAlertType.ERROR },
        );
        return false;
      }
      if (receiptId) {
        return await approveInvoiceMutation(input);
      }
      return false;
    },
    [
      data?.receipt.release?.sequenceNumber,
      settings?.invoices.requireCostCodesDuringApproval,
      receiptId,
      intl,
      approveInvoiceMutation,
      setSystemAlert,
    ],
  );

  useErrorEffect(error);

  const refetchReceipt = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <ProviderContext.Provider
      value={{
        receipt: data?.receipt || null,
        refetch: refetchReceipt,
        updateReceipt,
        approveReceipt,
        loading,
        itemized,
        setItemized,
        footerState,
        setFooterState,
        hasReleaseRecorded: !!data?.receipt.release,
        isItemized,
        matchedOrderViewState,
        setMatchedOrderViewState,
        selectedRelease,
        setSelectedRelease,
        preselectedPoNumber,
        setPreselectedPoNumber,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceipt = (): ProviderContextType =>
  useContext(ProviderContext);

import { DiscardButton } from "@/common/components/button/DiscardButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useBuyoutSequence } from "../hooks/useBuyoutSequence";

const Container = tw.div`
  grid grid-cols-[75px_repeat(5,_auto)] gap-2 relative w-fit place-items-center 
`;
const NumberContainer = tw.div`
  text-sm font-medium
  ${({ $hasItems }: { $hasItems: boolean }) =>
    $hasItems ? "text-green-800" : ""}
`;
const InfoText = tw.span`grid text-2xs max-w-20 font-normal`;
const OuterContainer = tw.div` 
  grid grid-flow-col items-center justify-center gap-2 
  text-base font-medium rounded-[32px] py-1 pl-6 pr-1
  ${({ $sequenceEnabled }: { $sequenceEnabled: boolean }) =>
    $sequenceEnabled ? "bg-blue-450" : ""}
`;

export const BuyoutSequence = () => {
  const { connectedIntegrationType, connectedSourceSystem } = useOrgSettings();
  const {
    startSequence,
    sequenceIds,
    selectedEntities,
    setSelectedEntities,
    sequenceEnabled,
    setSequenceEnabled,
    sequenceActive,
    stopSequence,
  } = useBuyoutSequence();

  const onStartSequenceClicked = useCallback(() => {
    startSequence();
  }, [startSequence]);

  const onDiscardSequence = useCallback(() => {
    setSelectedEntities([]);
    setSequenceEnabled(false);
    stopSequence();
  }, [setSelectedEntities, setSequenceEnabled, stopSequence]);

  useEffect(() => {
    if (sequenceActive) {
      setSequenceEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequenceActive]);

  if (!sequenceEnabled) {
    return (
      <If isTrue={!!connectedIntegrationType || !!connectedSourceSystem}>
        <OutlinedButton
          testId="export-buyouts"
          onClick={() => setSequenceEnabled(true)}
        >
          <FormattedMessage id="CREATE_SEQUENCE" />
        </OutlinedButton>
      </If>
    );
  }
  return (
    <OuterContainer $sequenceEnabled>
      <Container data-testid="batchControl">
        <FormattedMessage
          id="USE_CHECKBOXES_TO_SELECT_ITEMS"
          tagName={InfoText}
        />
        <NumberContainer $hasItems={selectedEntities.length > 0}>
          <FormattedMessage
            id="NUMBER_OF_SELECTED"
            values={{ count: sequenceIds.length }}
          />
        </NumberContainer>
        <PrimaryButton
          testId="start-sequence"
          onClick={onStartSequenceClicked}
          disabled={sequenceIds.length === 0}
        >
          <FormattedMessage id="START_SEQUENCE" />
        </PrimaryButton>
        <DiscardButton onClick={() => onDiscardSequence()} />
      </Container>
    </OuterContainer>
  );
};

import { FormattedMessage } from "react-intl";
import {
  ConditionalToggle,
  Container,
  Suggestions,
  SuggestionsSubtitle,
  SuggestionsTitle,
  VariableList,
} from "./TemplateVariablesCommon";
import { useCommonTemplateVariables } from "./hooks/useCommonTemplateVariables";
import { useTemplateVariables } from "./hooks/useTemplateVariables";

export const TemplateVariables = () => {
  const {
    currentTemplate,
    includeConditional,
    toggleIncludeConditional,
    handleCopyToClipboard,
  } = useCommonTemplateVariables();

  const { formattedVariables, defaultValues, handleDefaultValueChange } =
    useTemplateVariables({ includeConditional });

  return (
    <Container>
      <Suggestions>
        <SuggestionsTitle>
          <FormattedMessage id="AVAILABLE_VARIABLES" />
          <ConditionalToggle
            checked={includeConditional}
            onChange={toggleIncludeConditional}
          />
        </SuggestionsTitle>
        <SuggestionsSubtitle>
          <FormattedMessage id="AVAILABLE_VARIABLES_SUBTITLE" />
        </SuggestionsSubtitle>
        <VariableList
          variables={formattedVariables}
          currentTemplate={currentTemplate}
          defaultValues={defaultValues}
          onCopy={handleCopyToClipboard}
          onDefaultValueChange={handleDefaultValueChange}
        />
      </Suggestions>
    </Container>
  );
};

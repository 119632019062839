import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { trimCode } from "@/common/utils/cost-codes-and-zones/trimCode";
import { trimDescription } from "@/common/utils/cost-codes-and-zones/trimDescription";
import {
  CostCodeType,
  useOrgLevelCostCodes,
} from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useOrgLevelCostCodes";
import { ProjectCostCodeFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../store/useProjectStore";

type Options = {
  includeUnspecifiedCostCode?: boolean;
  selectedCostCodeId?: string | null;
  zoneId?: string | null;
};

type ProjectCostCode = ProjectCostCodeFieldsFragment & {
  isExcluded?: boolean;
  formatted: string;
};

export const useProjectCostCodes = (options?: Options) => {
  const {
    projectStoreCostCodes,
    restrictCostCodes,
    loading,
    allowance,
    currentProjectId,
  } = useProjectStore(
    useShallow((state) => ({
      currentProjectId: state.currentProjectId,
      projectStoreCostCodes: state.costCodes,
      restrictCostCodes: state.restrictCostCodes,
      loading: state.loading,
      allowance: state.allowance,
    })),
  );

  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { orgLevelCostCodes, formatCostCode: formatOrgLevelCostCode } =
    useOrgLevelCostCodes();

  const projectCostCodes = useMemo(() => {
    const allCodes =
      currentProjectId && projectStoreCostCodes
        ? projectStoreCostCodes
        : orgLevelCostCodes;
    return restrictCostCodes
      ? allCodes.filter((costCode) =>
          allowance.costCodes.some(
            (allowanceCostCode) =>
              allowanceCostCode.costCode.id === costCode.id,
          ),
        )
      : allCodes;
  }, [
    allowance.costCodes,
    orgLevelCostCodes,
    currentProjectId,
    projectStoreCostCodes,
    restrictCostCodes,
  ]);

  const allProjectCostCodes = useMemo(
    () =>
      currentProjectId && projectStoreCostCodes
        ? projectStoreCostCodes
        : orgLevelCostCodes,
    [orgLevelCostCodes, currentProjectId, projectStoreCostCodes],
  );

  const allCostCodes = useMemo((): ProjectCostCode[] => {
    if (!options?.selectedCostCodeId) {
      return projectCostCodes;
    }
    if (
      !projectCostCodes?.find(
        (costCode) => costCode.id === options?.selectedCostCodeId,
      )
    ) {
      const existingCostCode = orgLevelCostCodes.find(
        (costCode) => costCode.id === options.selectedCostCodeId,
      );
      if (existingCostCode) {
        return [
          ...projectCostCodes,
          ...(existingCostCode
            ? [
                {
                  ...existingCostCode,
                  isExcluded: true,
                  formatted: formatOrgLevelCostCode(existingCostCode),
                },
              ]
            : []),
          ...(options?.includeUnspecifiedCostCode
            ? [
                {
                  ...unassignedCostCode,
                  formatted: unassignedCostCode.description,
                },
              ]
            : []),
        ];
      }
    }

    return [
      ...projectCostCodes,
      ...(options?.includeUnspecifiedCostCode
        ? [
            {
              ...unassignedCostCode,
              formatted: unassignedCostCode.description,
            },
          ]
        : []),
    ];
  }, [
    orgLevelCostCodes,
    formatOrgLevelCostCode,
    options?.includeUnspecifiedCostCode,
    options?.selectedCostCodeId,
    projectCostCodes,
    unassignedCostCode,
  ]);

  const formatCostCode = useCallback(
    (costCode: CostCodeType) => {
      if (typeof costCode === "string") {
        const code = allProjectCostCodes.find(
          (projectCostCode) =>
            trimCode(projectCostCode.code).toLowerCase() ===
              trimCode(costCode).toLowerCase() ||
            trimDescription(projectCostCode.description).toLowerCase() ===
              trimCode(costCode).toLowerCase() ||
            costCode === projectCostCode.id,
        );
        if (code) {
          return code.formatted;
        }
      }
      return formatOrgLevelCostCode(costCode);
    },
    [formatOrgLevelCostCode, allProjectCostCodes],
  );

  const getCodePartOfCostCode = useCallback(
    (costCodeId: string | null | undefined) => {
      if (!costCodeId) {
        return "";
      }
      return (
        projectCostCodes.find((costCode) => costCode.id === costCodeId)?.code ??
        ""
      );
    },
    [projectCostCodes],
  );

  return {
    projectCostCodes,
    costCodes: allCostCodes,
    allCostCodes: allProjectCostCodes,
    formatCostCode,
    loading,
    getCodePartOfCostCode,
    allowance,
    restrictCostCodes,
  };
};

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFormattedMaterialName } from "@/common/components/spreadsheet-table/hooks/useFormattedMaterialName";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { appendTableRows } from "@/common/components/spreadsheet-table/utils/appendTableRows";
import { useInventoryItems } from "@/contractor/pages/admin/inventory-items/hooks/useInventoryItems";
import { getInventoryItemRemainingQuantity } from "@/contractor/pages/admin/inventory-items/utils/getInventoryItemRemainingQuantity";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import { getSelectedItemIdParts } from "../../../utils/getSelectedItemIdParts";
import { useInventoryFiltersStore } from "../store/useInventoryFiltersStore";

export const useAddInventoryItems = () => {
  const { handsonInstance } = useColumnMapper();
  const getFormattedMaterialName = useFormattedMaterialName();
  const { materialsMap } = useMaterials();
  const { formatCostCode } = useProjectCostCodes();
  const { warehouses } = useWarehouses();
  const { inventoryItems, currentWarehouseId: inventoryCurrentWarehouseId } =
    useInventoryItems();
  const {
    selectedItems,
    resetFilters,
    setSelectedItems,
    search,
    setSearch,
    selectedCostCodes,
    setSelectedCostCodes,
    filteredInventoryItems,
    currentWarehouseId,
    setCurrentWarehouseId,
    showZeroQuantityInventoryItems,
    setShowZeroQuantityInventoryItems,
    limitToCurrentWarehouse,
    setLimitToCurrentWarehouse,
  } = useInventoryFiltersStore(
    useShallow((state) => ({
      selectedItems: state.selectedItems,
      setSelectedItems: state.setSelectedItems,
      resetFilters: state.resetFilters,
      search: state.search,
      setSearch: state.setSearch,
      selectedCostCodes: state.selectedCostCodes,
      setSelectedCostCodes: state.setSelectedCostCodes,
      filteredInventoryItems: state.filteredInventoryItems,
      currentWarehouseId: state.currentWarehouseId,
      setCurrentWarehouseId: state.setCurrentWarehouseId,
      showZeroQuantityInventoryItems: state.showZeroQuantityInventoryItems,
      setShowZeroQuantityInventoryItems:
        state.setShowZeroQuantityInventoryItems,
      limitToCurrentWarehouse: state.limitToCurrentWarehouse,
      setLimitToCurrentWarehouse: state.setLimitToCurrentWarehouse,
    })),
  );

  const addSelectedItems = useCallback(() => {
    const newItems =
      selectedItems.map((item) => {
        const { warehouseId, inventoryItemId } = getSelectedItemIdParts(item);
        const inventoryItem = inventoryItems.find(
          (i) => i.id === inventoryItemId,
        );
        const material = materialsMap.get(inventoryItem?.orgMaterial?.id ?? "");
        const warehouse = warehouses.find((w) => w.id === warehouseId);
        const formattedCostCode = formatCostCode(material?.costCode?.id);

        return {
          [COLUMN_TYPE.Material]: getFormattedMaterialName(material ?? ""),
          [COLUMN_TYPE.UOM]: inventoryItem?.uom.pluralDescription ?? "",
          [COLUMN_TYPE.ExistingStock]: inventoryItem
            ? getInventoryItemRemainingQuantity(inventoryItem).toString()
            : "",
          [COLUMN_TYPE.NewStock]: inventoryItem
            ? getInventoryItemRemainingQuantity(inventoryItem).toString()
            : "",
          ...(warehouse?.id !== inventoryCurrentWarehouseId
            ? { [COLUMN_TYPE.Supplier]: warehouse?.name ?? "" }
            : {}),
          [COLUMN_TYPE.CostCode]: formattedCostCode,
        };
      }) || [];
    appendTableRows(newItems, handsonInstance);
  }, [
    selectedItems,
    handsonInstance,
    warehouses,
    getFormattedMaterialName,
    materialsMap,
    inventoryCurrentWarehouseId,
    formatCostCode,
    inventoryItems,
  ]);

  return {
    selectedItems,
    setSelectedItems,
    addSelectedItems,
    resetFilters,
    search,
    setSearch,
    selectedCostCodes,
    setSelectedCostCodes,
    filteredInventoryItems,
    currentWarehouseId,
    setCurrentWarehouseId,
    showZeroQuantityInventoryItems,
    setShowZeroQuantityInventoryItems,
    limitToCurrentWarehouse,
    setLimitToCurrentWarehouse,
  };
};

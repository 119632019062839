import { Dialog } from "@/common/components/dialog/Dialog";
import { InfoOutlineRed } from "@/common/components/import-external-po/import-external-po-wizard/importable-external-pos/items/ExternalPO.styles";
import { WarehouseSelectorControlled } from "@/common/components/warehouse-selector/WarehouseSelectorControlled";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { DialogContent } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  InventoryAdjustmentForm,
  InventoryAdjustmentFormValues,
} from "../../inventory-adjustment/components/inventory-adjustment-form/InventoryAdjustmentForm";
import { UNDEFINED_WAREHOUSE_ID } from "../../utils/getSelectedItemIdParts";
import { useInventoryItemSequence } from "../current-stock-list/components/hooks/useInventoryItemSequence";

const InfoIcon = tw(InfoOutlineRed)`text-blue-500 text-9xl -mb-10`;
const Title = tw.div`text-2xl font-bold text-center mb-4`;

type SelectWarehouseDialogProps = {
  open: boolean;
  onClose: () => void;
  labelKey: string;
  handleConfirm: (values: InventoryAdjustmentFormValues) => void;
};

const SelectWarehouseDialogWithForm = ({
  open,
  onClose,
  labelKey,
  handleConfirm,
}: SelectWarehouseDialogProps) => {
  const intl = useIntl();
  const { handleSubmit } = useFormContext<InventoryAdjustmentFormValues>();
  const { setValue } = useFormContext<InventoryAdjustmentFormValues>();
  const { sequenceIds } = useInventoryItemSequence();
  const { warehouses } = useWarehouses();

  const isSingleWarehouse = useMemo(() => {
    return warehouses.length === 1;
  }, [warehouses]);

  useEffect(() => {
    const nonUndefinedWarehouseIds = sequenceIds.filter(
      (id) => id !== UNDEFINED_WAREHOUSE_ID,
    );
    if (nonUndefinedWarehouseIds.length > 0) {
      setValue("warehouseId", nonUndefinedWarehouseIds[0]);
    } else if (isSingleWarehouse) {
      setValue("warehouseId", warehouses[0].id);
    } else {
      setValue("warehouseId", "");
    }
  }, [sequenceIds, setValue, isSingleWarehouse, warehouses]);

  useEffect(() => {
    const onlyOneWarehouse =
      warehouses.length === 1 ||
      sequenceIds.filter((id) => id !== UNDEFINED_WAREHOUSE_ID).length === 1;
    if (onlyOneWarehouse && open) {
      handleSubmit(handleConfirm)();
      onClose();
    }
  }, [
    warehouses.length,
    handleConfirm,
    onClose,
    open,
    handleSubmit,
    sequenceIds,
  ]);

  const closeDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "PROCEED" })}
      icon={<InfoIcon />}
      handleConfirm={handleSubmit(handleConfirm)}
      handleCancel={closeDialog}
      sx={{
        "& .MuiDialog-paper": {
          width: "420px",
        },
      }}
      show={open}
      content={
        <DialogContent>
          <FormattedMessage id={labelKey} tagName={Title} />
          <WarehouseSelectorControlled
            name="warehouseId"
            placeholder={intl.$t({ id: "WAREHOUSE" })}
          />
        </DialogContent>
      }
    />
  );
};

export const SelectWarehouseDialog = (props: SelectWarehouseDialogProps) => {
  return (
    <InventoryAdjustmentForm>
      <SelectWarehouseDialogWithForm {...props} />
    </InventoryAdjustmentForm>
  );
};

import { NoResults } from "@/common/components/no-results/NoResults";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useWizardStore } from "@/common/components/wizard-modal/store/useWizardStore";
import { getWizardArg } from "@/common/components/wizard-modal/utils/getWizardArg";
import { WarehouseInventoryItem } from "@/contractor/pages/home/inventory-management/pages/current-stock/types/WarehouseInventoryItem";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { applyScheduledFlowFilter } from "../../../../../utils/applyScheduledFlowFilter";
import { useInventoryItemScheduledTransactionsStore } from "../../store/useInventoryItemScheduledTransactionsStore";
import { useTransactionHistoryScheduledInflowsConfiguration } from "./TransactionHistoryScheduledInflows.configuration";

export const TransactionHistoryScheduledInflowsList = () => {
  const { args } = useWizardStore(
    useShallow((state) => ({
      args: state.args,
    })),
  );
  const inventoryItem = useMemo(
    () => getWizardArg<WarehouseInventoryItem>(args, "inventoryItem"),
    [args],
  );
  const { filter } = useInventoryItemScheduledTransactionsStore();
  const configuration = useTransactionHistoryScheduledInflowsConfiguration();

  const filteredPendingInflows = useMemo(
    () => applyScheduledFlowFilter(inventoryItem.pendingInflows, filter),
    [inventoryItem.pendingInflows, filter],
  );

  return (
    <LocalSearchableList
      tableConfiguration={configuration}
      items={filteredPendingInflows}
      emptyList={
        <NoResults
          isFiltered={Object.keys(filter).length > 0}
          filteredTranslationKey="TRANSACTION_HISTORY_NO_TRANSACTIONS_FILTERED"
          translationKey="TRANSACTION_HISTORY_NO_TRANSACTIONS"
        />
      }
    />
  );
};

import { HOLD_FOR_RELEASE_TEXT } from "@/common/const";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { Identity } from "@/types/Identity";
import { CellValue } from "handsontable/common";
import moment from "moment";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { getPhysicalRowIndex } from "../../utils/getPhysicalRowIndex";
import { rowIsEmpty } from "../../utils/rowIsEmpty";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { useRenderHelpers } from "../helpers/useRenderHelpers";
import { applyClasses } from "../utils/applyClasses";
import { applyTooltip } from "../utils/applyTooltip";
import { removeTooltip } from "../utils/removeTooltip";

export const useDeliveryDateRenderer = () => {
  const { release } = useRelease();
  const intl = useIntl();
  const { addIconWithTooltip } = useRenderHelpers();
  const baseRenderer = useBaseRenderer();

  const computeDeliveryDate = useCallback(
    (
      sourceRow: Identity,
      rowData: CellValue[],
      currentValue: string | null | undefined,
    ): {
      computedValue: string;
      releaseItem?: { deliveryDateTBD?: boolean };
    } => {
      if (!currentValue && !rowIsEmpty(rowData)) {
        const releaseItem = release?.items.find(
          (item) => item.id === sourceRow.id,
        );
        const computedValue =
          release?.time && !releaseItem?.deliveryDateTBD
            ? moment(release?.time).format("L")
            : release?.timeTBD || releaseItem?.deliveryDateTBD
              ? HOLD_FOR_RELEASE_TEXT
              : "";
        return { computedValue, releaseItem };
      }
      return { computedValue: (currentValue as string) ?? "" };
    },
    [release?.items, release?.time, release?.timeTBD],
  );

  const getToggleHandler = useCallback(
    (
      instance: {
        setDataAtCell: (row: number, col: number, value: string) => void;
      },
      row: number,
      col: number,
      currentValue: string,
    ): (() => void) => {
      return () => {
        if (currentValue === HOLD_FOR_RELEASE_TEXT) {
          instance.setDataAtCell(row, col, "");
        } else {
          instance.setDataAtCell(row, col, HOLD_FOR_RELEASE_TEXT);
        }
      };
    },
    [],
  );

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const sourceRow = instance.getSourceDataAtRow(
        getPhysicalRowIndex(instance, row),
      ) as Identity;
      const rowData = instance?.getDataAtRow(row);

      if (!value && !rowIsEmpty(rowData)) {
        const { computedValue, releaseItem } = computeDeliveryDate(
          sourceRow,
          rowData,
          value,
        );

        baseRenderer(
          instance,
          td,
          row,
          col,
          prop,
          computedValue,
          cellProperties,
        );
        if (!releaseItem?.deliveryDateTBD) {
          applyClasses(td, "text-gray-500");
        }
      } else {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
      }

      if (rowIsEmpty(rowData)) {
        removeTooltip(td);
        return;
      }

      const includeTooltip =
        value !== HOLD_FOR_RELEASE_TEXT && (value || !release?.timeTBD);

      if (includeTooltip) {
        addIconWithTooltip({
          element: td,
          icon: "fa-calendar-xmark",
          tooltipText: intl.$t({
            id: "SET_ITEM_AS_HOLD_FOR_RELEASE",
          }),
          iconClasses: "cursor-pointer",
          onClick: getToggleHandler(instance, row, col, value),
        });
        removeTooltip(td);
      } else if (
        value === HOLD_FOR_RELEASE_TEXT ||
        (release?.timeTBD && !value)
      ) {
        applyTooltip(td, intl.$t({ id: "HOLD_FOR_RELEASE" }));
      }
    },
    [
      release?.timeTBD,
      addIconWithTooltip,
      intl,
      baseRenderer,
      computeDeliveryDate,
      getToggleHandler,
    ],
  );

  return renderer;
};

import { PDF_LEFT_SPACING } from "@/common/const";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { IntlShape } from "react-intl";

export const releaseSignatureBlock = (doc: jsPDF, intl: IntlShape) => {
  autoTable(doc, {
    theme: "plain",
    tableWidth: doc.internal.pageSize.getWidth() - 2 * PDF_LEFT_SPACING,
    showHead: false,
    styles: {
      fontSize: 10,
      halign: "right",
    },
    columns: [{ dataKey: "value" }],
    body: [
      [
        `${intl.$t({ id: "AUTHORIZED_SIGNATURE" })} _______________________________________`,
      ],
    ],
  });
};

import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useReleaseExportStatus } from "@/contractor/pages/home/release/components/connections/components/buttons/hooks/useReleaseExportStatus";
import { ReleaseExportValidationErrors } from "@/contractor/pages/home/release/components/connections/components/common/release-export-status/components/ReleaseExportValidationErrors";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const useReleaseWarnings = ({
  release,
}: {
  release: ReleasePartialFieldsFragment;
}) => {
  const { settings } = useOrgSettings();
  const { validationErrors } = useReleaseExportStatus({ release });
  const intl = useIntl();
  const syncingTooltipMessage = useMemo(() => {
    if (release.poLink?.syncedAt) {
      return null;
    }

    if (release.poLink?.syncing) {
      if (release.poLink?.syncedAt) {
        return "SYNCING_RELEASE";
      }

      return "EXPORT_IN_PROGRESS";
    }

    if (
      release.poLink &&
      !release.poLink?.syncedAt &&
      !release.poLink?.syncing &&
      !release.poLink?.failedAt
    ) {
      return "QUEUED_FOR_EXPORT";
    }

    return null;
  }, [release.poLink]);

  const errorTooltip = useMemo(() => {
    if (release.poLink?.validationErrors?.length) {
      return (
        <ReleaseExportValidationErrors validationErrors={validationErrors} />
      );
    }
    if (release.poLink?.autoSyncError) {
      return intl.formatMessage({ id: "PO_SYNCHRONIZED_ERROR" });
    }
    if (release.poLink?.failedMessage) {
      return release.poLink?.failedMessage;
    }
  }, [
    intl,
    release.poLink?.autoSyncError,
    release.poLink?.failedMessage,
    release.poLink?.validationErrors?.length,
    validationErrors,
  ]);
  const wasReleaseChangedAfterLinking = useMemo(
    () =>
      !!release.poLink?.syncedVersion &&
      release.poLink?.syncedVersion !== release.syncVersion,
    [release.poLink, release.syncVersion],
  );

  const wasReleaseChangedAfterExport = useMemo(
    () =>
      !!release.exportedVersion &&
      release.exportedVersion !== release.syncVersion,
    [release.exportedVersion, release.syncVersion],
  );

  const wasReleaseChanged = useMemo(
    () => wasReleaseChangedAfterLinking || wasReleaseChangedAfterExport,
    [wasReleaseChangedAfterExport, wasReleaseChangedAfterLinking],
  );

  const isRequiredReceipt = useMemo(() => {
    return (
      settings?.integrations.sourceSystems.find((system) => system.connected)
        ?.postInventoryReceipts ||
      settings?.integrations.accounting.find((accounting) => accounting.enabled)
        ?.postInventoryReceipts
    );
  }, [settings?.integrations.accounting, settings?.integrations.sourceSystems]);

  const poExportedTooltip = useMemo(() => {
    if (!release.useSourceSystemPO) {
      return "PO_SET_NOT_TO_BE_EXPORTED";
    }
    if (release?.exportedAt || release?.poLink?.syncedAt) {
      return release?.poLink?.createdBy
        ? "PO_EXPORTED_AT_BY"
        : "PO_EXPORTED_AT";
    }
    if (release?.poExists) {
      return "PO_EXISTS_IN_SOURCE_SYSTEM";
    }
    return "PO_NOT_EXPORTED";
  }, [
    release?.exportedAt,
    release?.poExists,
    release?.poLink?.createdBy,
    release?.poLink?.syncedAt,
    release.useSourceSystemPO,
  ]);

  const nonArchivedInvoices = useMemo(() => {
    return release.invoices.filter((invoice) => !invoice.archivedAt);
  }, [release.invoices]);

  const nonArchivedReceipts = useMemo(() => {
    return release.receipts.filter((receipt) => !receipt.archivedAt);
  }, [release.receipts]);

  return {
    syncingTooltipMessage,
    errorTooltip,
    wasReleaseChanged,
    wasReleaseChangedAfterLinking,
    wasReleaseChangedAfterExport,
    isRequiredReceipt,
    poExportedTooltip,
    nonArchivedInvoices,
    nonArchivedReceipts,
  };
};

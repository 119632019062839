import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import {
  ManufacturerFieldsFragment,
  OrgCatalogSku,
  OrgMaterialFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";
import { getMaterialNameParts } from "../utils/getMaterialNameParts";
import { sanitizeValue } from "../utils/sanitizeValue";

export const useFindMaterialByName = () => {
  const { materials } = useMaterials();

  return useCallback(
    (name: string, customMaterials?: OrgMaterialFieldsFragment[]) => {
      if (!name) {
        return undefined;
      }

      const { namePart, partNumber } = getMaterialNameParts(
        String(sanitizeValue(name)),
      );

      const hasDuplicates =
        (customMaterials ?? materials).filter((mat) => {
          return mat.material.name === namePart;
        }).length > 1;
      return (customMaterials ?? materials).find((m) => {
        const sameName = m.material.name === namePart;
        const samePartNumber =
          (m.material as OrgCatalogSku).customPartNumber ===
          (partNumber ?? null);
        return hasDuplicates ? sameName && samePartNumber : sameName;
      }) as OrgMaterialFieldsFragment & {
        manufacturer: ManufacturerFieldsFragment;
      };
    },
    [materials],
  );
};

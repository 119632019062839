import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import tw from "tailwind-styled-components";
import { ProjectDefaultUsersSelector } from "./ProjectUsersSelector";

const Container = tw.div`flex flex-col gap-4`;

export const ProjectDefaultUsers = () => {
  const locations = useStartupDataStore((state) => state.locations);

  return (
    <Container>
      {locations.map((location) => (
        <ProjectDefaultUsersSelector
          key={location.id}
          locationId={location.id}
          locationName={location.name}
        />
      ))}
    </Container>
  );
};

import { MAX_VENDOR_NUMBER } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useIntegrationFeatureRequirement } from "../integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { GroupRenderer } from "../select/components/renderer/GroupRenderer";
import { SelectProps } from "../select/components/single/Select";
import { StyledSelect } from "../select/components/single/StyledSelect";
import { SupplierInsuranceInfo } from "../supplier-insurance-info/SupplierInsuranceInfo";
import { Tooltip } from "../tooltip/Tooltip";
import { SupplierPickerCustomRender } from "./components/renderers/SupplierPickerCustomRender";
import { ALL_SUPPLIERS_ID } from "./const/allSuppliersId";
import { warningStyles } from "./const/warningStyles";
import { Supplier, SupplierType, SupplierVendor } from "./types/Supplier";
import { useSupplierOptions } from "./useSupplierOptions";

type Props<T> = {
  supplierId: string | null | undefined;
  setSupplierId: (supplierId: string | null) => void;
  includeAllOption?: boolean;
  xs?: boolean;
  includePlaceholder?: boolean;
  inputPropsClassName?: string;
  contactsFilter?: (
    contact: OrgPreferredVendorsFieldsFragment["contacts"][0],
  ) => boolean;
  className?: string;
  includeWarehouses?: boolean;
  disableWarehouses?: boolean;
  withAdditionalAdornment?: boolean;
  warningTooltip?: string;
  disableWarehousesTooltip?: string;
} & Pick<
  SelectProps<T>,
  | "getOptionDisabled"
  | "testId"
  | "staticText"
  | "disabledTooltip"
  | "placeholder"
  | "required"
  | "error"
  | "InputLabelProps"
  | "onBlur"
  | "inputProps"
  | "selectedOptionCustomView"
  | "disabled"
>;

export const SupplierPicker = ({
  supplierId,
  setSupplierId,
  placeholder,
  includeAllOption = true,
  xs = true,
  includePlaceholder = true,
  inputPropsClassName = "",
  contactsFilter,
  className,
  testId,
  includeWarehouses,
  disableWarehouses,
  withAdditionalAdornment = false,
  warningTooltip,
  disableWarehousesTooltip,
  ...props
}: Props<Supplier>) => {
  const intl = useIntl();
  const { supplierOptions, loading } = useSupplierOptions({
    includeAllOption,
    includeWarehouses,
    disableWarehouses,
  });
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const shouldShowVendorCode = useMemo(() => {
    return hasFeatureInConnectedSourceSystem(
      IntegrationFeature.HumanReadableVendorCode,
    );
  }, [hasFeatureInConnectedSourceSystem]);

  const selectedVendor = useMemo(() => {
    if (!supplierId) {
      return;
    }
    const vendor = supplierOptions.find(
      (supplier) =>
        supplier.type === SupplierType.VENDOR &&
        (supplier as SupplierVendor)?.sellerOrgLocation?.id === supplierId,
    );
    if (!vendor) {
      return;
    }
    return vendor as SupplierVendor;
  }, [supplierId, supplierOptions]);

  return (
    <Tooltip
      id="supplier-picker-id"
      element={
        <StyledSelect
          className={`w-full ${className}`}
          testId={testId || "form-supplier-selector"}
          placeholder={
            includePlaceholder
              ? placeholder || intl.$t({ id: "SUPPLIER" })
              : undefined
          }
          groupBy={includeWarehouses ? (option) => option.type : undefined}
          renderGroup={GroupRenderer}
          inputProps={{
            className: inputPropsClassName,
          }}
          getOptionDisabled={(option) => !!option.disabled}
          disabledTooltip={disableWarehousesTooltip}
          options={supplierOptions || []}
          loading={loading}
          value={supplierId || ALL_SUPPLIERS_ID}
          onChange={(value) => {
            if (
              supplierOptions.find(
                (option) => option.value === value && option.disabled,
              )
            ) {
              return;
            }
            setSupplierId(
              value !== ALL_SUPPLIERS_ID ? (value as string) : null,
            );
          }}
          customRender={(item) =>
            SupplierPickerCustomRender(item, {
              filter: contactsFilter,
              shouldShowVendorCode,
            })
          }
          getLabel={(option) => option.name}
          getValue={(option) => option.value as string}
          noOptionsText={intl.$t({ id: "NO_VENDORS_FOUND" })}
          size={xs ? "xs" : undefined}
          limitResults={MAX_VENDOR_NUMBER}
          {...props}
          endAdornment={
            withAdditionalAdornment ? (
              <SupplierInsuranceInfo
                vendorId={selectedVendor?.sellerOrgLocation.id}
                className="z-10 -mr-1 bg-white pl-2 pr-1"
              />
            ) : undefined
          }
          sx={warningTooltip ? warningStyles : {}}
        />
      }
      hideTooltip={!warningTooltip}
    >
      {warningTooltip}
    </Tooltip>
  );
};

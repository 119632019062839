import {
  ColumnType,
  useColumnMapper,
} from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";

import { Identity } from "@/types/Identity";
import { HotTable } from "@handsontable/react";
import HotTableClass from "@handsontable/react/hotTableClass";
import Handsontable from "handsontable";
import { CellChange, ChangeSource } from "handsontable/common";
import "handsontable/dist/handsontable.full.min.css";
import { useEffect, useRef } from "react";
import tw from "tailwind-styled-components";
import { AddNewRowButton } from "./components/add-new-row-button/AddNewRowButton";
import { EditColumnsButton } from "./components/edit-columns/EditColumnsButton";
import { GridLoader } from "./components/grid-loader/GridLoader";
import { useGridLoadingInfo } from "./hooks/useGridLoadingInfo";
import { useTableColumnHeaders } from "./hooks/useTableColumnHeaders";
import { useTableColumns } from "./hooks/useTableColumns";
import { useTableData } from "./hooks/useTableData";
import { useTableEvents } from "./hooks/useTableEvents";
import { useTableHeaders } from "./hooks/useTableHeaders";
import "./spread-sheet-table.scss";

type Props<T> = {
  items: T[];
  height: string | number;
  columns: ColumnType[];
  saving?: boolean;
  fetchingData?: boolean;
  rowNumber?: number;
  preventUpdatedOnChanges?: boolean;
  onChanges?: (data: Record<string, string>[]) => void;
  includePermissionCheck?: boolean;
  readOnly?: boolean;
  hideAddNewRow?: boolean;
  pageId?: string;
  customPrefillFunctions?: ((
    hotInstance: Handsontable | undefined | null,
    changes: CellChange[] | null,
    source: ChangeSource,
  ) => void)[];
};

type ContainerProps = {
  $saving?: boolean;
};
const Container = tw.div<ContainerProps>`relative group/table
  ${({ $saving }: ContainerProps) =>
    $saving ? "opacity-70 pointer-events-none" : ""}
`;

type IdentityWithAdditional = Identity & {
  position?: number | null;
};

export const SpreadSheetTable = <T extends IdentityWithAdditional>({
  items,
  height,
  columns,
  saving,
  fetchingData = false,
  rowNumber,
  onChanges,
  includePermissionCheck = true,
  readOnly = false,
  hideAddNewRow = false,
  pageId,
  customPrefillFunctions = [],
}: Props<T>) => {
  const { setSpreadsheetData, extraColumns, setHandsonInstance } =
    useColumnMapper();

  const { rowHeaders } = useTableHeaders();

  const { allColumns, tableHiddenColumns, columnsConfig } = useTableColumns({
    columns,
    extraColumns,
    includePermissionCheck,
    pageId,
  });

  const { data, loading } = useTableData({
    items,
    rowNumber,
    allColumns,
    columnsConfig,
    setSpreadsheetData,
  });

  const ref = useRef<HTMLDivElement>(null);
  const hotTableComponent = useRef<HotTableClass>(null);

  const {
    beforeChange,
    afterChange,
    afterRemoveRow,
    afterUpdateSettings,
    afterBeginEditing,
    beforePaste,
  } = useTableEvents({
    data,
    setSpreadsheetData,
    onChanges,
    hotTableComponent,
    allColumns,
    columnsConfig,
    customPrefillFunctions,
  });
  const { columnHeaders } = useTableColumnHeaders({
    allColumns,
  });
  const { loadingData } = useGridLoadingInfo();

  useEffect(() => {
    const hotInstance = hotTableComponent.current?.hotInstance;
    if (hotInstance) {
      setHandsonInstance(hotInstance);
    }
  }, [setHandsonInstance, hotTableComponent.current?.hotInstance]);

  useEffect(() => {
    const hotInstance = hotTableComponent.current?.hotInstance;
    if (hotInstance) {
      hotInstance.updateSettings({
        hiddenColumns: tableHiddenColumns,
      });
    }
  }, [tableHiddenColumns]);

  return (
    <Container
      ref={ref}
      $saving={saving || loading || fetchingData || loadingData}
    >
      <HotTable
        ref={hotTableComponent}
        data={data}
        width="100%"
        height={height}
        colHeaders={columnHeaders}
        rowHeaders={rowHeaders}
        columns={columnsConfig}
        filters
        allowInsertRow={!readOnly}
        allowRemoveRow={!readOnly}
        multiColumnSorting
        allowInsertColumn={false}
        allowRemoveColumn={false}
        beforeChange={beforeChange}
        afterChange={afterChange}
        afterRemoveRow={afterRemoveRow}
        afterUpdateSettings={afterUpdateSettings}
        contextMenu
        hiddenColumns={tableHiddenColumns}
        viewportRowRenderingOffset={20}
        afterBeginEditing={afterBeginEditing}
        manualColumnResize
        manualColumnMove
        colWidths={allColumns.map((col) => col.width)}
        beforePaste={beforePaste}
        invalidCellClassName="invalid"
        autoWrapRow
        stretchH="all"
        licenseKey={
          import.meta.env.VITE_APP_HANDSON_TABLE_KEY ??
          "non-commercial-and-evaluation"
        }
        className="items-center"
        readOnly={readOnly}
      />
      {pageId && <EditColumnsButton pageId={pageId} />}
      <GridLoader saving={saving} loading={loading || loadingData} />
      <AddNewRowButton hideAddNewRow={hideAddNewRow} readOnly={readOnly} />
    </Container>
  );
};

import { COST_CODE_FIELDS } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/fragments/costCodeFragment";
import { gql } from "@apollo/client";
import { MANUFACTURER_FIELDS } from "./manufacturerFragment";
import { TAG_FIELDS } from "./tagFragment";
import { UOM_FIELDS } from "./uomFragment";
import { ZONE_FIELDS } from "./zoneFragment";

export const ESTIMATED_ITEM_FIELDS = gql`
  ${ZONE_FIELDS}
  ${TAG_FIELDS}
  ${MANUFACTURER_FIELDS}
  ${COST_CODE_FIELDS}
  ${UOM_FIELDS}
  fragment EstimatedItemFields on EstimatedItem {
    id
    quantityDecimal
    orderedQuantity
    duplicateCount
    unitPrice
    notes
    zone {
      ...ZoneFields
    }
    tags {
      ...TagFields
    }
    manufacturer {
      ...ManufacturerFields
    }
    position
    costCode {
      ...CostCodeFields
    }
    uom {
      ...UOMFields
    }
    sellerOrgLocation {
      id
    }
  }
`;

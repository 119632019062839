import {
  TextField,
  TextFieldProps,
} from "@/common/components/textfield/TextField";
import { FC } from "react";

type Props = Pick<TextFieldProps, "label" | "value">;

export const VendorStaticDetailText: FC<Props> = ({ label, value }) => {
  return (
    <TextField
      staticText
      label={label}
      value={value}
      InputLabelProps={{
        className: "ml-0",
      }}
      InputProps={{
        className: "text-xl font-medium",
      }}
      className="w-full"
    />
  );
};

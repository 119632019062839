import { useCallback, useEffect, useMemo } from "react";
import { generatePath, useLocation, useNavigate } from "react-router";
import { readValue, removeValue, setValue } from "../utils/localStorage";

type LocalStorageConfig = {
  SEQUENCE_ACTIVE: string;
  SEQUENCE_STEP: string;
  SELECTED_ENTITIES: string;
  REDIRECT_ROUTE: string;
};

export type SequenceStore<T> = {
  selectedEntities: T[];
  setSelectedEntities: (values: T[]) => void;
  step: number;
  setStep: (value: number) => void;
  sequenceActive: boolean;
  setSequenceActive: (value: boolean) => void;
  sequenceEnabled: boolean;
  setSequenceEnabled: (value: boolean) => void;
};

export const useSequence = <T extends { id: string }>({
  localStorageConfig,
  singEntityPath,
  getSearchParams,
  listPath,
  useStore,
  extractSequenceIds,
}: {
  localStorageConfig: LocalStorageConfig;
  singEntityPath?: (id: string) => string;
  getSearchParams?: (id: string) => string;
  listPath: string;
  useStore: () => SequenceStore<T>;
  extractSequenceIds?: (ids: string[]) => string[];
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selectedEntities,
    setSelectedEntities,
    step,
    setStep,
    sequenceActive,
    setSequenceActive,
    sequenceEnabled,
    setSequenceEnabled,
  } = useStore();

  useEffect(() => {
    setSelectedEntities(
      readValue(localStorageConfig.SELECTED_ENTITIES, []) as T[],
    );
    setStep(readValue(localStorageConfig.SEQUENCE_STEP, 0) as number);
    setSequenceActive(
      readValue(localStorageConfig.SEQUENCE_ACTIVE, false) as boolean,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedEntitiesAndUpdateLocalStorage = useCallback(
    (entities: T[]) => {
      setSelectedEntities(entities);
      setValue(localStorageConfig.SELECTED_ENTITIES, entities);
    },
    [localStorageConfig.SELECTED_ENTITIES, setSelectedEntities],
  );

  const setSequenceActiveAndUpdateLocalStorage = useCallback(
    (active: boolean) => {
      setSequenceActive(active);
      setValue(localStorageConfig.SEQUENCE_ACTIVE, active);
    },
    [localStorageConfig.SEQUENCE_ACTIVE, setSequenceActive],
  );

  const setStepAndUpdateLocalStorage = useCallback(
    (step: number) => {
      setStep(step);
      setValue(localStorageConfig.SEQUENCE_STEP, step);
    },
    [localStorageConfig.SEQUENCE_STEP, setStep],
  );

  const startSequence = useCallback(() => {
    setStepAndUpdateLocalStorage(0);
    setSelectedEntitiesAndUpdateLocalStorage(selectedEntities);
    setSequenceActiveAndUpdateLocalStorage(true);
    setValue(localStorageConfig.SEQUENCE_ACTIVE, true);
    const sequenceStep = readValue(
      localStorageConfig.SEQUENCE_STEP,
      0,
    ) as number;
    const firstEntity = selectedEntities[sequenceStep];
    if (firstEntity) {
      setValue(localStorageConfig.REDIRECT_ROUTE, location.search);
      if (singEntityPath) {
        navigate(singEntityPath(firstEntity.id));
      }
    }
  }, [
    localStorageConfig.REDIRECT_ROUTE,
    localStorageConfig.SEQUENCE_ACTIVE,
    localStorageConfig.SEQUENCE_STEP,
    location.search,
    navigate,
    selectedEntities,
    setSelectedEntitiesAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
    singEntityPath,
  ]);

  const stopSequence = useCallback(() => {
    setSelectedEntitiesAndUpdateLocalStorage([]);
    setSequenceActiveAndUpdateLocalStorage(false);
    setStepAndUpdateLocalStorage(0);
    removeValue(localStorageConfig.REDIRECT_ROUTE);
    removeValue(localStorageConfig.SEQUENCE_STEP);
  }, [
    localStorageConfig.REDIRECT_ROUTE,
    localStorageConfig.SEQUENCE_STEP,
    setSelectedEntitiesAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
  ]);

  const sequenceIds = useMemo(
    () =>
      extractSequenceIds
        ? extractSequenceIds(selectedEntities.map((i) => i.id))
        : selectedEntities.map((i) => i.id),
    [selectedEntities, extractSequenceIds],
  );

  const redirectToList = useCallback(() => {
    const redirectParams = readValue(localStorageConfig.REDIRECT_ROUTE, "") as
      | string
      | undefined;
    navigate(generatePath(`${listPath}${redirectParams}`));
  }, [localStorageConfig.REDIRECT_ROUTE, navigate, listPath]);

  const navigateToNextSequence = useCallback(
    (
      {
        navigateToEntities,
        navigateToEntityId,
      }: {
        navigateToEntities?: boolean;
        navigateToEntityId?: string;
      } = {
        navigateToEntities: false,
        navigateToEntityId: undefined,
      },
    ) => {
      if (sequenceActive) {
        if (sequenceIds.length === step + 1) {
          stopSequence();
          redirectToList();
        } else {
          setStepAndUpdateLocalStorage(step + 1);
          if (singEntityPath) {
            navigate({
              pathname: generatePath(singEntityPath(sequenceIds[step + 1])),
              search: getSearchParams
                ? getSearchParams(sequenceIds[step + 1])
                : undefined,
            });
          }
        }
      } else if (navigateToEntities) {
        redirectToList();
      } else if (navigateToEntityId) {
        if (singEntityPath) {
          navigate({
            pathname: generatePath(singEntityPath(navigateToEntityId)),
            search: getSearchParams
              ? getSearchParams(navigateToEntityId)
              : undefined,
          });
        }
      }
    },
    [
      sequenceActive,
      sequenceIds,
      step,
      redirectToList,
      setStepAndUpdateLocalStorage,
      navigate,
      singEntityPath,
      getSearchParams,
      stopSequence,
    ],
  );

  return {
    step,
    setStep: setStepAndUpdateLocalStorage,
    sequenceIds,
    selectedEntities,
    setSelectedEntities: setSelectedEntitiesAndUpdateLocalStorage,
    sequenceActive,
    sequenceEnabled,
    setSequenceEnabled,
    startSequence,
    stopSequence,
    navigateToNextSequence,
    redirectToList,
  };
};

import { Supplier, SupplierType, SupplierVendor } from "../types/Supplier";

export const getVendorSupplierBySupplier = (
  vendor: Supplier,
): SupplierVendor | null => {
  if (vendor.type === SupplierType.VENDOR) {
    return vendor as SupplierVendor;
  }
  return null;
};

import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { InventoryItemScheduledTransactionFilter } from "../../../../types/InventoryItemScheduledTransactionFilter";

type State = {
  filter: InventoryItemScheduledTransactionFilter;
  setFilter: (step: InventoryItemScheduledTransactionFilter) => void;
};

export const useInventoryItemScheduledTransactionsStore = create<State>()(
  devtools((set) => ({
    filter: {},
    setFilter: (filter: InventoryItemScheduledTransactionFilter) =>
      set(() => ({
        filter,
      })),
  })),
);

import { Label } from "@/common/components/release-terms-and-notes/ReleaseTermsAndNotes.styles";
import { NoFunction } from "@/types/NoFunction";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { Select } from "../select/components/single/Select";
import { TextField } from "../textfield/TextField";
import { useCustomPaymentTerms } from "./hooks/useCustomPaymentTerms";
import { useCustomPaymentTermsStyles } from "./hooks/useCustomPaymentTerms.styles";
import { CustomPaymentTermsProps } from "./types/CustomPaymentTermsProps";

const Container = tw.div`flex flex-row items-center`;

export const CustomPaymentTerms: FC<CustomPaymentTermsProps> = ({
  paymentTerm,
  updateItem = NoFunction,
  readonly = false,
  hideLabel = false,
  staticText = false,
  labelId,
  classes,
}) => {
  const {
    handlePaymentTermChange,
    paymentTermOptions,
    setInput,
    onBlur,
    value,
    label,
    customPaymentTermsCreatableFn,
  } = useCustomPaymentTerms({ paymentTerm, updateItem, staticText, readonly });
  const { containerClassName, inputPropsClassName } =
    useCustomPaymentTermsStyles({
      hideLabel,
      classes,
      staticText,
      readonly,
    });
  return (
    <Container className={containerClassName}>
      <If isTrue={!hideLabel}>
        <FormattedMessage id={labelId ?? "PAYMENT_TERMS"} tagName={Label} />
      </If>
      {!readonly && !staticText ? (
        <Select
          label={label}
          options={paymentTermOptions}
          getValue={(o) => o.id}
          getLabel={(o) => o.label}
          onChange={handlePaymentTermChange}
          value={paymentTerm ? paymentTerm.toString() : ""}
          handleInputChange={setInput}
          onBlur={onBlur}
          disableClearable={false}
          creatable
          creatableFn={customPaymentTermsCreatableFn}
          inputProps={{
            className: inputPropsClassName,
          }}
          className="w-full"
          InputLabelProps={{ className: "bg-white px-1 text-gray-500" }}
        />
      ) : (
        <TextField
          label={label}
          value={value}
          xs
          inputProps={{
            className: inputPropsClassName,
          }}
          disabled={!staticText}
          staticText={staticText}
        />
      )}
    </Container>
  );
};

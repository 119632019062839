import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { RfqStatusChip } from "@/common/components/statuses/RfqStatusChip";
import { routes } from "@/config/routes";
import { MessageContextKind, RfqStatus } from "@/generated/graphql";
import { FC, ReactElement, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";
import { RfqMessagesButton } from "../../../rfqs/components/vendors/RfqMessagesButton";
import { useRfqQuotes } from "../../providers/RfqQuotesProvider";
import { ExportQuotesButton } from "../export-quotes/ExportQuotesButton";
import { RfqQuoteOptionsButton } from "../quote-options/RfqQuoteOptionsButton";
export const RfqBreadcrumbs: FC = () => {
  const intl = useIntl();
  const { rfq } = useRfqQuotes();
  const { bidId } = useParams();

  const headerActions = useCallback(() => {
    const actions = [];
    if (bidId && rfq) {
      actions.push(
        <RfqMessagesButton
          quote={{
            id: bidId,
            rfqId: rfq?.id,
            clientIdentifier: rfq?.clientIdentifier,
            projectId: rfq?.project.id,
          }}
          org={rfq.project.location.org}
          text={<MessageButton id={bidId} kind={MessageContextKind.Quote} />}
        />,
      );
    }
    if (rfq && rfq.quotes.length > 0) {
      if (rfq.status !== RfqStatus.Awarded) {
        actions.push(<RfqQuoteOptionsButton key="options" />);
      }
      actions.push(<ExportQuotesButton key="export-quotes" />);
    }
    return actions;
  }, [bidId, rfq]);

  const appendedItems = useMemo(() => {
    const items = [] as ReactElement[];
    if (rfq) {
      items.push(
        <RfqStatusChip key="status" status={rfq?.status} type="breadcrumb" />,
      );
    }
    return items;
  }, [rfq]);

  return (
    <Breadcrumbs
      items={[
        {
          text: <FormattedMessage id="QUOTES" />,
          link: routes.quotes,
          id: "quotes",
        },
        {
          text: intl.$t(
            {
              id: "RFQ_NUMBER_WITH_SYMBOL",
            },
            { number: rfq?.clientIdentifier },
          ),
          link: generatePath(routes.rfqById, { id: rfq?.id || "" }),
          id: "rfq",
        },
        {
          text: (
            <FormattedMessage
              id="QUOTES_NO"
              values={{ number: rfq?.quotes.length || 0 }}
            />
          ),
          id: "quotes",
        },
      ]}
      actions={headerActions()}
      appendItems={appendedItems}
    />
  );
};

import { InvoiceDetails } from "@/common/components/invoices/invoice-details/InvoiceDetails";
import { Loader } from "@/common/components/loader/Loader";
import { ResizableColumns } from "@/common/components/resizable-columns/ResizableColumns";
import { ColumnMapperProvider } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import { INVOICE_READONLY_STATUSES, QUERYSTRING } from "@/common/const";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { ReleaseProvider } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { AssetContext, InvoiceType } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import tw from "tailwind-styled-components";
import { useSetCurrentProjectId } from "../../../project/hooks/useSetCurrentProjectId";
import { ReceiptsSequenceProvider } from "../receipts/providers/ReceiptsSequenceProvider";
import {
  ReceiptCreateReleaseFormValues,
  ReceiptVerificationForm,
} from "./components/ReceiptVerificationForm";
import { ReceiptBreadcrumbs } from "./components/breadcrumbs/ReceiptBreadcrumbs";
import { ReceiptFooter } from "./components/footer/ReceiptFooter";
import { ReceiptOrder } from "./components/order/ReceiptOrder";
import { useReceiptCreateReleaseSpreadsheetConfig } from "./components/order/itemized/create/ReceiptCreateRelease.config";
import { useReceiptUpdateReleaseSpreadsheetConfig } from "./components/order/itemized/update/InvoiceUpdateRelease.config";
import { useInitializeReceiptForm } from "./hooks/useInitializeReceiptForm";
import { ReceiptCreateReleaseProvider } from "./providers/ReceiptCreateReleaseProvider";
import { ReceiptProvider, useReceipt } from "./providers/ReceiptProvider";
import { ReceiptUpdateReleaseProvider } from "./providers/ReceiptUpdateReleaseProvider";
import { UploadReceiptProvider } from "./providers/UploadReceiptProvider";
import { ReceiptFooterState } from "./types/ReceiptFooterState";
import { ReceiptMatchedOrderViewState } from "./types/ReceiptMatchedOrderViewState";

const Container = tw.div`relative mt-5 xl:-mx-10`;

const ReceiptRecordOrderWithProvider: FC = () => {
  const { queryParams } = useQueryParams();
  const {
    receipt,
    updateReceipt,
    footerState,
    setFooterState,
    setMatchedOrderViewState,
  } = useReceipt();
  const { setGlobalVendorId } = useVendorPrices();
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (queryParams.get(QUERYSTRING.CREATE_NEW_ORDER) === "true") {
      setMatchedOrderViewState(ReceiptMatchedOrderViewState.CREATE_ORDER);
      setFooterState(ReceiptFooterState.DEFAULT);
    }
  }, [queryParams, setMatchedOrderViewState, setFooterState]);

  const { watch } = useFormContext<ReceiptCreateReleaseFormValues>();

  useInitializeReceiptForm();

  const vendorId = watch("vendorId");
  const projectId = watch("projectId");
  useSetCurrentProjectId(projectId ?? undefined);

  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
  }, [setGlobalVendorId, vendorId]);

  const hasPermissionOrArchived = useMemo(() => {
    return !isAuthorized(receipt?.permissions.edit) || !!receipt.archivedAt;
  }, [receipt]);

  const readonly = useMemo(
    () => !receipt || INVOICE_READONLY_STATUSES.includes(receipt?.status),
    [receipt],
  );

  const receiptPoNumberReadonly = useMemo(
    () => !isAuthorized(receipt?.permissions.edit) || !!receipt.archivedAt,
    [receipt],
  );

  const toggleEdit = useCallback(
    () => setIsEditMode((oldValue) => !oldValue),
    [],
  );

  return (
    <Container>
      <ReceiptBreadcrumbs />
      <ResizableColumns
        hasFooter={!readonly}
        tallFooter={footerState === ReceiptFooterState.KICKBACK}
      >
        <ReceiptOrder
          isEditMode={isEditMode}
          toggleEdit={toggleEdit}
          readonly={readonly}
        />
        <InvoiceDetails
          invoice={receipt}
          updateInvoice={updateReceipt}
          invoiceReadonly={hasPermissionOrArchived}
          invoicePoNumberReadonly={receiptPoNumberReadonly}
          type={InvoiceType.Receipt}
        />
      </ResizableColumns>
      <ReceiptFooter isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
    </Container>
  );
};

const ReceiptRecordOrderWithReleaseProvider: FC = () => {
  const { receipt } = useReceipt();

  if (!receipt) {
    return <Loader loading />;
  }

  return (
    <ReleaseProvider id={receipt?.release?.id}>
      <UploadAssetProvider
        context={AssetContext.Invoice}
        projectId={receipt.predictedProject?.id}
      >
        <UploadReceiptProvider>
          <SpreadsheetWrapper />
        </UploadReceiptProvider>
      </UploadAssetProvider>
    </ReleaseProvider>
  );
};

const SpreadsheetWrapper = () => {
  const { receipt } = useReceipt();

  const receiptCreateReleaseSpreadsheetConfig =
    useReceiptCreateReleaseSpreadsheetConfig();
  const receiptUpdateReleaseSpreadsheetConfig =
    useReceiptUpdateReleaseSpreadsheetConfig();
  if (!receipt?.release?.id) {
    return (
      <ColumnMapperProvider config={receiptCreateReleaseSpreadsheetConfig}>
        <ReceiptCreateReleaseProvider>
          <ReceiptRecordOrderWithProvider />
        </ReceiptCreateReleaseProvider>
      </ColumnMapperProvider>
    );
  }
  return (
    <ColumnMapperProvider config={receiptUpdateReleaseSpreadsheetConfig}>
      <ReceiptUpdateReleaseProvider>
        <ReceiptRecordOrderWithProvider />
      </ReceiptUpdateReleaseProvider>
    </ColumnMapperProvider>
  );
};

export const ReceiptRecordOrder = () => {
  const { receiptId } = useParams();

  return (
    <ReceiptProvider key={receiptId}>
      <ReceiptsSequenceProvider>
        <ReceiptVerificationForm>
          <ReceiptRecordOrderWithReleaseProvider />
        </ReceiptVerificationForm>
      </ReceiptsSequenceProvider>
    </ReceiptProvider>
  );
};

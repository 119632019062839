import { Price } from "@/common/components/price/Price";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { InventoryItem } from "../../InventoryLedgerList.styles";

type Props = {
  item: InventoryTransactionSummaryFieldsFragment;
};

export const TransactionValue = ({ item }: Props) => {
  const { calcExtPrice } = usePriceCalculation();
  const value = useMemo(
    () =>
      item.items.reduce(
        (acc, item) =>
          new DecimalSafe(acc)
            .add(calcExtPrice(item.quantity, item.unitPrice))
            .toNumber(),
        0,
      ),
    [calcExtPrice, item.items],
  );
  return (
    <InventoryItem>
      <Price price={value} />
    </InventoryItem>
  );
};

import { DeleteButton } from "@/common/components/button/DeleteButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { isAuthorized } from "@/common/utils/isAuthorized";
import { useArchiveRelease } from "@/contractor/pages/home/release/pages/specify-details/hooks/release-mutations/useArchiveRelease";
import { useCancelRelease } from "@/contractor/pages/home/release/pages/specify-details/hooks/release-mutations/useCancelRelease";
import { useUnarchiveRelease } from "@/contractor/pages/home/release/pages/specify-details/hooks/release-mutations/useUnarchiveRelease";
import { isWarehouseRelease } from "@/contractor/pages/home/release/utils/isWarehouseRelease";
import {
  CancelReleaseInput,
  ReleasePartialFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { Replay } from "@mui/icons-material";
import { FC, MouseEvent, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExportReleaseIcon } from "../../../../release/components/exports/ExportReleaseIcon";
import { useDeliveries } from "../providers/DeliveriesProvider";
import { ReleaseWarnings } from "./release-warnings/ReleaseWarnings";

const Container = tw.div`flex flex-row gap-1 items-center flex-1 ml-1 justify-end`;
const DeleteButtonContainer = tw.div`w-10 h-10 items-center flex justify-center`;
const DeleteButtonStyled = tw(DeleteButton)`h-auto p-0`;
const RestoreIcon = tw(Replay)`text-blue-500`;

const RELEASE_DELETE_STATUSES = [
  ReleaseStatus.Draft,
  ReleaseStatus.Canceled,
  ReleaseStatus.Rejected,
  ReleaseStatus.AwaitingApproval,
  ReleaseStatus.Received,
  ReleaseStatus.PartiallyReceived,
  ReleaseStatus.Reserved,
];

const RELEASE_CANCEL_STATUSES = [
  ReleaseStatus.Requested,
  ReleaseStatus.Scheduled,
];

export const ReleaseActions: FC<{
  id: string;
  release: ReleasePartialFieldsFragment;
}> = ({ id, release }) => {
  const { openDialog } = useDialog();
  const { unarchiveRelease } = useUnarchiveRelease();
  const { archiveRelease } = useArchiveRelease();
  const { exportEnabled } = useDeliveries();
  const { cancelRelease } = useCancelRelease();
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();

  const isArchiveButtonVisible = useMemo(
    () =>
      (RELEASE_DELETE_STATUSES.includes(release.status) ||
        RELEASE_CANCEL_STATUSES.includes(release.status)) &&
      !release.deletedAt &&
      isAuthorized(release.permissions.archive),
    [release.status, release.deletedAt, release.permissions.archive],
  );

  const handleArchiveRelease = useCallback(async () => {
    if (await archiveRelease(id)) {
      setSuccessAlert(intl.$t({ id: "DELIVERY_DELETED" }));
    }
  }, [archiveRelease, id, setSuccessAlert, intl]);

  const handleUnarchiveRelease = useCallback(
    async (event: MouseEvent<unknown>) => {
      event.preventDefault();
      event.stopPropagation();
      if (await unarchiveRelease(id)) {
        setSuccessAlert(intl.$t({ id: "DELIVERY_RESTORED" }));
      }
    },
    [unarchiveRelease, id, setSuccessAlert, intl],
  );

  const handleCancelRelease = useCallback(async () => {
    if (
      await cancelRelease({
        releaseId: release.id,
        version: release.version,
      } as CancelReleaseInput)
    ) {
      setSuccessAlert(
        intl.$t(
          { id: "ORDER_CANCELED_SUCCESSFULLY" },
          { number: release.sequenceNumber || "N/A" },
        ),
      );
    }
  }, [
    cancelRelease,
    release.id,
    release.version,
    release.sequenceNumber,
    setSuccessAlert,
    intl,
  ]);

  return (
    <Container>
      <If isTrue={exportEnabled}>
        <ExportReleaseIcon release={release} />
      </If>
      <If isTrue={!isWarehouseRelease(release)}>
        <ReleaseWarnings release={release} />
      </If>
      <If isTrue={isArchiveButtonVisible && !exportEnabled}>
        <Tooltip
          id="delete-order-button"
          element={
            <DeleteButtonContainer>
              <DeleteButtonStyled
                onClick={() =>
                  openDialog({
                    cancelButtonText: intl.$t({ id: "CANCEL" }),
                    confirmButtonText: intl.$t({ id: "PROCEED" }),
                    includeWarningIcon: true,
                    title: intl.$t({
                      id: RELEASE_CANCEL_STATUSES.includes(release.status)
                        ? "CANCEL_DELIVERY_QUESTION"
                        : "DELETE_ORDER_DETAILS",
                    }),
                    handleConfirm: () => {
                      if (RELEASE_CANCEL_STATUSES.includes(release.status)) {
                        handleCancelRelease();
                      } else {
                        handleArchiveRelease();
                      }
                    },
                  })
                }
              />
            </DeleteButtonContainer>
          }
        >
          <FormattedMessage
            id={
              RELEASE_CANCEL_STATUSES.includes(release.status)
                ? "CANCEL_ORDER"
                : "DELETE_ORDER"
            }
          />
        </Tooltip>
      </If>
      <If isTrue={release.deletedAt}>
        <Tooltip
          id="restore-order-button"
          element={
            <DeleteButtonContainer>
              <RestoreIcon onClick={handleUnarchiveRelease} />
            </DeleteButtonContainer>
          }
        >
          <FormattedMessage id="RESTORE_DELETED_ORDER" />
        </Tooltip>
      </If>
    </Container>
  );
};

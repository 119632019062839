import { WrapperProps } from "@/common/components/grid-table/types/WrapperProps";
import { FC } from "react";
import tw from "tailwind-styled-components";

export const FooterCol = tw.div`flex flex-col flex-1`;
export const FooterHeader = tw.div`h-8 flex items-center justify-end pr-6`;
export const FooterHeaderText = tw.div`text-2xs text-gray-600 text-right`;
export const FooterCell = tw.div`h-10 flex items-center justify-end pr-6`;
export const FooterDashedLine = tw.div`w-full lg:border-gray-600 border-dashed border-t-[1px] mt-6 h-1`;
export const FooterTotal = tw(FooterCell)<{
  $isWarehouseRelease?: boolean;
}>`h-17 pt-1 ${(props) => (props.$isWarehouseRelease ? "pt-[25px]" : "")}`;
export const FooterItemInputContainer = tw(
  FooterCell,
)`flex h-12 p-2 items-center`;
export const FooterCellCol = tw.div`flex flex-col gap-y-1`;

export type FooterProps = {
  Wrapper: FC<WrapperProps>;
};

export type FooterPropsWithTaxes = FooterProps & {
  includeAdditionalCharges?: boolean;
};

import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { monetarySplit } from "@/common/utils/monetarySplit";
import { useInvoiceMatchedOrder } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceMatchedOrderProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback, useMemo } from "react";

export const useInvoicedItemExtPriceGroup = (items: ExpandedReleaseItem[]) => {
  const { release } = useRelease();
  const { calcExtPrice } = usePriceCalculation();
  const { useInvoiceItemPrice } = useInvoiceMatchedOrder();

  const getNetAmount = useCallback(
    (taxable?: boolean) => {
      return items.reduce(
        (acc, item) =>
          acc.add(
            !taxable || item.taxable
              ? calcExtPrice(
                  item.invoiceItems?.[0]?.quantity || "0",
                  useInvoiceItemPrice && !isLumpSumItem(item)
                    ? item.invoiceItems?.[0]?.unitPrice
                    : item.unitPrice,
                )
              : new DecimalSafe(0),
          ),
        new DecimalSafe(0),
      );
    },
    [calcExtPrice, items, useInvoiceItemPrice],
  );

  const netAmount = useMemo(() => getNetAmount(), [getNetAmount]);
  const taxableNetAmount = useMemo(() => getNetAmount(true), [getNetAmount]);

  const salesTaxSplit = useMemo(() => {
    const additionalChargesAmount = new DecimalSafe(
      (release?.additionalCharges || []).reduce(
        (acc, charge) => acc.add(charge.amount),
        new DecimalSafe(0),
      ),
    );
    const taxAmount = new DecimalSafe(
      (release?.customTaxAmount !== null &&
      release?.customTaxAmount !== undefined
        ? release?.customTaxAmount
        : release?.taxAmount) || 0,
    );
    const netAmountWithAdditionalCharges = taxableNetAmount.add(
      additionalChargesAmount,
    );
    const taxAmountToDistribute = taxableNetAmount
      .div(
        netAmountWithAdditionalCharges.equals(0)
          ? 1
          : netAmountWithAdditionalCharges,
      )
      .mul(taxAmount);
    const result = monetarySplit(taxAmountToDistribute, [
      taxableNetAmount,
      new DecimalSafe(release?.taxableNetAmount || 0).minus(taxableNetAmount),
    ]);
    return result[0];
  }, [
    release?.additionalCharges,
    release?.customTaxAmount,
    release?.taxAmount,
    release?.taxableNetAmount,
    taxableNetAmount,
  ]);

  const valueWithTax = useMemo(
    () => netAmount.add(salesTaxSplit).toDP(2),
    [netAmount, salesTaxSplit],
  );

  const hidden = useMemo(
    () => items.some((i) => (i.invoiceItems || []).length > 0),
    [items],
  );

  return {
    netAmount,
    valueWithTax,
    hidden,
  };
};

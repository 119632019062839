import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useCallback, useMemo } from "react";
import { useInvoiceValidation } from "../../scanned-invoices/providers/InvoiceValidationProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

const SALES_TAX_DESCRIPTION = "Sales Tax";

export const useInvoiceRemainingSalesTax = () => {
  const { invoiceValidation } = useInvoiceValidation();
  const { invoice } = useInvoiceVerification();

  const remainingSalesTaxAmount = useMemo(() => {
    if (invoiceValidation) {
      const externalItem = invoiceValidation?.po?.items.find(
        (externalItem) => externalItem.description === SALES_TAX_DESCRIPTION,
      );
      return externalItem ? Number(externalItem.remainingAmount) : undefined;
    }
    return undefined;
  }, [invoiceValidation]);
  const { formatCurrency } = useFormatNumberToCurrency();

  const formatSalesTax = useCallback(
    (taxAmount: number | undefined | string) => {
      return `${formatCurrency(taxAmount, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
    },
    [formatCurrency],
  );

  const formattedRemainingSalesTax = useMemo(() => {
    return `${formatSalesTax(remainingSalesTaxAmount || 0)}`;
  }, [formatSalesTax, remainingSalesTaxAmount]);

  const remainingLessThanInvoiceSalesTax = useMemo(() => {
    if (remainingSalesTaxAmount === undefined) {
      return false;
    }

    if (invoice?.link && !invoice?.link.failedAt) {
      return false;
    }

    return new DecimalSafe(remainingSalesTaxAmount || 0).lt(
      invoice?.taxAmount || 0,
    );
  }, [remainingSalesTaxAmount, invoice?.taxAmount, invoice?.link]);

  return {
    remainingSalesTaxAmount: formattedRemainingSalesTax,
    remainingLessThanInvoiceSalesTax,
  };
};

import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { VendorContact } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useVendors } from "../vendors/hooks/useVendors";
import { ALL_SUPPLIERS_ID } from "./const/allSuppliersId";
import {
  Supplier,
  SupplierType,
  SupplierVendor,
  SupplierWarehouse,
} from "./types/Supplier";
import { vendorLabelFormatter } from "./utils/vendorLabelFormatter";

type Props = {
  includeAllOption?: boolean;
  includeWarehouses?: boolean;
  disableWarehouses?: boolean;
  contactsFilter?: (contact: VendorContact) => boolean;
};

export const useSupplierOptions = (
  {
    contactsFilter,
    includeAllOption,
    includeWarehouses,
    disableWarehouses,
  }: Props = {
    contactsFilter: () => true,
  },
) => {
  const { vendors, loading, shouldShowVendorCode, getVendorCode } =
    useVendors();
  const intl = useIntl();

  const { warehouses } = useWarehouses();
  const supplierOptions: Supplier[] = useMemo(() => {
    const allOption = {
      name: intl.$t({
        id: includeWarehouses ? "ALL_SUPPLIERS" : "ALL_VENDORS",
      }),
      id: ALL_SUPPLIERS_ID,
    };
    const opts = [
      ...(includeAllOption
        ? [
            {
              ...allOption,
              value: allOption.id,
              contacts: [],
              sellerOrgLocation: {
                id: ALL_SUPPLIERS_ID,
                name: "",
                org: { id: "", name: allOption.name },
              },
              taxExempt: false,
              externalVendors: [],
              type: SupplierType.None,
            } as Supplier,
          ]
        : []),
    ];

    if (includeWarehouses) {
      warehouses.forEach((warehouse) => {
        opts.push({
          ...warehouse,
          value: warehouse.id,
          type: SupplierType.WAREHOUSE,
          name: warehouse.name,
          disabled: disableWarehouses,
        });
      });
    }
    vendors.forEach((vendor) => {
      opts.push({
        ...vendor,
        value: vendor.sellerOrgLocation.id,
        type: SupplierType.VENDOR,
        name: vendorLabelFormatter(
          vendor.sellerOrgLocation,
          vendor.contacts.filter(contactsFilter ?? (() => true)),
          {
            vendorCode: getVendorCode(vendor),
          },
        ),
        externalVendors: vendor.externalVendors,
      });
    });

    return opts;
  }, [
    contactsFilter,
    disableWarehouses,
    getVendorCode,
    includeAllOption,
    includeWarehouses,
    intl,
    vendors,
    warehouses,
  ]);

  const findOrderTypeByLocationId = useCallback(
    (locationId: string) => {
      return vendors.find(
        (vendor) => vendor.sellerOrgLocation.id === locationId,
      )?.defaultReleaseType?.id;
    },
    [vendors],
  );

  const findPaymentTermByLocationId = useCallback(
    (locationId: string | undefined) => {
      if (!locationId) {
        return undefined;
      }
      return (
        vendors.find((vendor) => vendor.sellerOrgLocation.id === locationId)
          ?.defaultPaymentTerm ?? undefined
      );
    },
    [vendors],
  );

  const findCostCodeIdByLocationId = useCallback(
    (locationId: string) => {
      return vendors.find(
        (vendor) => vendor.sellerOrgLocation.id === locationId,
      )?.costCode?.id;
    },
    [vendors],
  );

  const findSupplierTypeBySupplierId = useCallback(
    (supplierId: string | null | undefined) => {
      return supplierOptions.find(
        (supplier) =>
          (supplier as SupplierVendor).sellerOrgLocation?.id === supplierId ||
          supplier.id === supplierId,
      )?.type;
    },
    [supplierOptions],
  );

  const findVendorById = useCallback(
    (supplierId: string | null | undefined) => {
      return supplierOptions.find(
        (supplier) =>
          (supplier as SupplierVendor).sellerOrgLocation?.id === supplierId ||
          supplier.id === supplierId,
      );
    },
    [supplierOptions],
  );

  const findWarehouseById = useCallback(
    (warehouseId: string | null | undefined) => {
      return supplierOptions.find(
        (supplier) =>
          (supplier as SupplierWarehouse).id === warehouseId ||
          supplier.id === warehouseId,
      );
    },
    [supplierOptions],
  );

  return {
    supplierOptions,
    loading,
    shouldShowVendorCode,
    findOrderTypeByLocationId,
    findPaymentTermByLocationId,
    findCostCodeIdByLocationId,
    findSupplierTypeBySupplierId,
    findVendorById,
    findWarehouseById,
  };
};

import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { useInvoiceDocumentOptions } from "@/common/components/invoices/invoice-details/hooks/useInvoiceDocumentOptions";
import { Price } from "@/common/components/price/Price";
import { DateView } from "@/common/utils/dates/DateView";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../../../providers/InvoiceVerificationProvider";
import {
  OrderMatchedHeader,
  OrderNumberHeader,
} from "../../../../../../styles/InvoiceVerification.styles";

const Container = tw.div`w-full`;

export const InvoiceHeaderOnlyOptions: FC = () => {
  const { invoice } = useInvoiceVerification();
  const { discountDollarAmount, retentionDollarAmount } =
    useInvoiceDocumentOptions({ invoice });
  const intl = useIntl();
  const options = useMemo(() => {
    return {
      [intl.$t({ id: "INVOICE_DISCOUNT_DATE" })]: invoice?.discountDate ? (
        <DateView date={invoice.discountDate} />
      ) : undefined,
      [intl.$t({ id: "INVOICE_DISCOUNT" })]:
        invoice?.discountPercentage !== null ? (
          `${invoice?.discountPercentage || 0}% (${discountDollarAmount})`
        ) : invoice?.discountAmount !== null ? (
          <Price price={invoice?.discountAmount} />
        ) : undefined,
      [intl.$t({ id: "INVOICE_RETAINAGE" })]:
        invoice?.retentionPercentage !== null ? (
          `${invoice?.retentionPercentage || 0}% (${retentionDollarAmount})`
        ) : invoice?.retentionAmount !== null ? (
          <Price price={invoice?.retentionAmount} />
        ) : undefined,
    };
  }, [
    discountDollarAmount,
    intl,
    invoice?.discountAmount,
    invoice?.discountDate,
    invoice?.discountPercentage,
    invoice?.retentionAmount,
    invoice?.retentionPercentage,
    retentionDollarAmount,
  ]);

  return (
    <Container>
      <OrderMatchedHeader>
        <FormattedMessage id="INVOICE_OPTIONS" tagName={OrderNumberHeader} />
      </OrderMatchedHeader>
      <OrderMatchedHeader className="pt-0">
        <OrgDetailsHeaderItem
          includeAddress={false}
          additionalKeyValueItems={options}
        />
      </OrderMatchedHeader>
    </Container>
  );
};

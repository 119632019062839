import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { DrawerView } from "@/common/components/panel/DrawerView";
import { ProjectExtendedFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { MaterialFileUploadSelection } from "../ImportProjectItems";

type Props = {
  project: ProjectExtendedFieldsFragment;
};

export const UPLOAD_MATERIALS_DRAWER = "upload-project-estimates";

export const UploadMaterialsDrawer: FC<Props> = ({ project }) => {
  const { isOpened, toggle } = useGlobalDrawer();
  return (
    <DrawerView
      isOpened={isOpened(UPLOAD_MATERIALS_DRAWER)}
      setIsOpened={(value) => toggle(UPLOAD_MATERIALS_DRAWER, value)}
      content={(togglePanel) => (
        <MaterialFileUploadSelection
          project={project}
          onClose={() => togglePanel(false)}
        />
      )}
    />
  );
};

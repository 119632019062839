import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useRelease } from "../providers/ReleaseProvider";

export const useIsRestockRelease = () => {
  const { release } = useRelease();
  const formContext = useFormContext<{ projectId: string }>();
  const projectId = formContext?.watch?.("projectId");
  const { projects } = useProjectListOptions();

  const isRestockRelease = useMemo(() => {
    if (projectId !== undefined) {
      const project = projects.find((p) => p.id === projectId);
      return !!project?.preferredInventoryInflows;
    }
    const project = projects.find((p) => p.id === release?.project?.id);
    return !!project?.preferredInventoryInflows;
  }, [projectId, release?.project?.id, projects]);

  return { isRestockRelease };
};

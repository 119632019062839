import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { DistributorQuoteItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useDistributorQuote } from "../providers/DistributorQuoteProvider";

const PricePickerStyled = tw(PricePicker)`
${({ readonly }: { readonly: boolean }) =>
  readonly ? "bg-transparent" : "bg-white"}
`;

type Props = {
  item: DistributorQuoteItemFieldsFragment;
  readonly: boolean;
  index?: number;
  error?: boolean;
  hideErrorIcon?: boolean;
};

export const DistributorQuoteTotalInput: FC<Props> = ({
  item,
  readonly,
  error,
  index,
}) => {
  const { updateQuote } = useDistributorQuote();
  const { calcExtPrice } = usePriceCalculation();

  const price = useMemo(() => {
    return calcExtPrice(item.quantityDecimal || 0, item.unitPrice || 0);
  }, [item.unitPrice, item.quantityDecimal, calcExtPrice]);

  const onChange = useCallback(
    async (total: string | null) => {
      if (item.quantityDecimal === "0" || total === null) {
        return;
      }
      await updateQuote({
        updates: [
          {
            quoteItemId: item.id,
            unitPrice: new DecimalSafe(total)
              .div(item.quantityDecimal ?? 1)
              .toString(),
          },
        ],
      });
    },
    [item.id, item.quantityDecimal, updateQuote],
  );

  return (
    <PricePickerStyled
      value={price}
      onBlur={onChange}
      error={error}
      readonly={readonly}
      index={index}
    />
  );
};

import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import {
  OrgRolesWrapper,
  Permission,
} from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import {
  ProjectExtendedFieldsFragment,
  ProjectStatus,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { StateSelectorControlled } from "../../../../../../../common/components/state-selector/StateSelectorControlled";
import {
  ProjectGroup,
  ProjectLabelDetails,
  ProjectSection,
  ProjectSectionContainer,
  ProjectSectionLabel,
} from "../ProjectForm.styles";
import { ProjectAddress2TextField } from "../input-components/ProjectAddress2TextField";
import { ProjectAddressCityTextField } from "../input-components/ProjectAddressCityTextField";
import { ProjectAddressPostalCodeTextField } from "../input-components/ProjectAddressPostalCodeTextField";
import { ProjectAddressTextField } from "../input-components/ProjectAddressTextField";
import { ProjectBudgetTextField } from "../input-components/ProjectBudgetTextField";
import { ProjectCountrySelector } from "../input-components/ProjectCountrySelector";
import { ProjectJobDatePicker } from "../input-components/ProjectJobDatePicker";
import { ProjectJobNumberTextField } from "../input-components/ProjectJobNumberTextField";
import { ProjectLocationSelector } from "../input-components/ProjectLocationSelector";
import { ProjectNameTextField } from "../input-components/ProjectNameTextField";
import { ProjectPoNumberFormatTextField } from "../input-components/ProjectPoNumberFormatTextField";
import { ProjectReleaseAssets } from "../input-components/ProjectReleaseAssets";
import { ProjectReleaseInstructionsTextField } from "../input-components/ProjectReleaseInstructionsTextField";
import { ProjectStatusSelector } from "../input-components/ProjectStatusSelector";
import { ProjectTaxExempt } from "../input-components/ProjectTaxExempt";
import { ProjectTrackInventory } from "../input-components/ProjectTrackInventory";
import { ProjectUsersSelector } from "../input-components/ProjectUsersSelector";
import { ProjectFormCloseDialog } from "./ProjectFormCloseDialog";
import { ProjectFormProjectConnections } from "./project-connections/ProjectFormProjectConnections";

const ProjectInformationContainer = tw(ProjectSectionContainer)`bg-gray-100`;
const Subgroup = tw.div`grid gap-4`;
const ProjectNameContainer = tw.div`grid md:grid-cols-[_6fr_2fr] gap-2 md:gap-1`;
const AddressContainer = tw.div`grid md:grid-cols-[_3fr_2fr_2fr_2fr] gap-2 md:gap-1`;
const ButtonContainer = tw.div`flex justify-end gap-4 h-8 duration-300 transition-opacity mt-2 mb-2`;
const Label = tw.div`font-medium`;
const PoNumberFormatContainer = tw.div`grid grid-flow-col gap-3 justify-start items-center`;

type NewProjectFormProps = {
  project?: ProjectExtendedFieldsFragment | null;
  calendarOpened?: boolean;
  setCalendarOpened?: (opened: boolean) => void;
  readonly?: boolean;
};

export const ProjectFormProjectInformationSection: FC<NewProjectFormProps> = ({
  project,
  calendarOpened,
  setCalendarOpened,
  readonly = false,
}) => {
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const { setAssets } = useUploadAssets();
  const { settings } = useOrgSettings();
  const { updateProject } = useProject();

  const [saving, setSaving] = useState(false);
  const [closedProjectStatus, setClosedProjectStatus] =
    useState<null | ProjectStatus>(null);

  const defaultValues = useMemo(
    () => ({
      projectName: project?.name || "",
      address: project?.address.addressLine1 || "",
      address2: project?.address.addressLine2 || "",
      jobNumber: project?.jobNumber || "",
      city: project?.address.city || "",
      state: project?.address.state || "",
      postalCode: project?.address.postalCode || "",
      country: project?.address.country || "",
      budget: project?.budget || "",
      status: project?.status,
      locationId: project?.location.id,
      jobStartDate: project?.startDate
        ? new Date(project.startDate)
        : undefined,
      projectUsers: project?.team?.map((a) => a.id) ?? [],
      releaseInstructions: project?.releaseInstructions || {
        text: "",
        assetUrls: [],
      },
      taxExempt: project?.taxExempt || false,
      preferredInventoryInflows: project?.preferredInventoryInflows || false,
      poJobNumber: project?.poJobNumber || "",
    }),
    [project],
  );

  const methods = useForm({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  const saveProject = useCallback(async () => {
    setSaving(true);
    const input = methods.getValues();
    const address = {
      addressLine1: input.address,
      addressLine2: input.address2,
      city: input.city,
      country: input.country,
      postalCode: input.postalCode || "",
      state: input.state || "",
    };
    const updatedProject = {
      id: project?.id || "",
      name: input.projectName,
      jobNumber: input.jobNumber || null,
      locationId: input.locationId || "",
      address,
      startDate: input.jobStartDate?.valueOf() || new Date().valueOf(),
      status: input.status,
      budget: input.budget?.toString() || "0",
      team: input.projectUsers || [],
      releaseInstructions: input.releaseInstructions,
      taxExempt: input.taxExempt,
      poJobNumber: input.poJobNumber,
      preferredInventoryInflows: input.preferredInventoryInflows,
    };
    const result = project?.id ? await updateProject(updatedProject) : null;
    setSaving(false);
    if (!result) {
      return;
    }
    setClosedProjectStatus(null);
    setSuccessAlert(
      intl.$t(
        {
          id: "PROJECT_UPDATE_SUCCESS",
        },
        { name: strongify(input.projectName) },
      ),
    );
  }, [project?.id, methods, intl, updateProject, setSaving, setSuccessAlert]);

  const onSave = useCallback(() => {
    const input = methods.getValues();
    if (!input.status) {
      return;
    }
    if (
      project?.status &&
      ![ProjectStatus.Completed, ProjectStatus.Lost].includes(project.status) &&
      [ProjectStatus.Completed, ProjectStatus.Lost].includes(input.status)
    ) {
      setClosedProjectStatus(input.status);
    } else {
      saveProject();
    }
  }, [project?.status, methods, saveProject]);

  const reset = useCallback(() => {
    methods.reset();
    setAssets(project?.releaseInstructions?.assets || []);
  }, [methods, project?.releaseInstructions?.assets, setAssets]);

  return (
    <>
      <FormProvider {...methods}>
        <ProjectInformationContainer
          onClick={(ev: React.MouseEvent<HTMLFormElement>) => {
            ev.stopPropagation();
          }}
          onSubmit={methods.handleSubmit(onSave)}
        >
          <If
            isTrue={(settings?.integrations.sourceSystems ?? []).some(
              (system) => system.connected,
            )}
          >
            <ProjectSection>
              <ProjectSectionLabel>
                <FormattedMessage id="CONNECTIONS" />
                <Tooltip
                  id="connections"
                  classes={{ tooltip: "max-w-screen-md" }}
                  element={<InfoOutlined className="text-blue-500" />}
                >
                  {intl.$t({ id: "CONNECT_PROJECT_TO_ACCOUNTING_SOFTWARE" })}
                </Tooltip>
              </ProjectSectionLabel>
              <ProjectFormProjectConnections
                mappings={project?.mappings ?? []}
              />
            </ProjectSection>
          </If>
          <ProjectSection>
            <ProjectSectionLabel>
              <FormattedMessage id="DETAILS" />
            </ProjectSectionLabel>
            <ProjectGroup>
              <Subgroup>
                <ProjectLocationSelector
                  name="locationId"
                  disabled={readonly}
                />
                <ProjectNameContainer>
                  <ProjectNameTextField
                    name="projectName"
                    disabled={readonly}
                  />
                  <ProjectJobNumberTextField
                    name="jobNumber"
                    disabled={readonly}
                  />
                </ProjectNameContainer>
                <ProjectAddressTextField name="address" disabled={readonly} />
                <ProjectAddress2TextField name="address2" disabled={readonly} />
                <AddressContainer>
                  <ProjectAddressCityTextField
                    name="city"
                    disabled={readonly}
                  />
                  <StateSelectorControlled
                    name="state"
                    disabled={readonly}
                    countryInputName="country"
                  />
                  <ProjectAddressPostalCodeTextField
                    name="postalCode"
                    disabled={readonly}
                    countryInputName="country"
                  />
                  <ProjectCountrySelector name="country" />
                </AddressContainer>
                <PoNumberFormatContainer>
                  <Label>
                    <FormattedMessage id="PO_NUMBER_FORMAT" />
                  </Label>
                  <InfoTooltip
                    id="po-number-format"
                    message={intl.$t({ id: "PO_NUMBER_FORMAT_TOOLTIP" })}
                  />
                  <ProjectPoNumberFormatTextField
                    name="poJobNumber"
                    jobNumberName="jobNumber"
                  />
                </PoNumberFormatContainer>
              </Subgroup>
              <Subgroup>
                <ProjectUsersSelector name="projectUsers" disabled={readonly} />
                <ProjectJobDatePicker
                  name="jobStartDate"
                  open={calendarOpened}
                  setOpen={setCalendarOpened}
                  disabled={readonly}
                />
                <OrgRolesWrapper permissions={[Permission.canViewPrices]}>
                  <ProjectBudgetTextField name="budget" disabled={readonly} />
                </OrgRolesWrapper>
                <ProjectStatusSelector
                  name="status"
                  includeLabel={false}
                  disabled={readonly}
                />
                <ProjectTaxExempt name="taxExempt" />
                <ProjectTrackInventory name="preferredInventoryInflows" />
              </Subgroup>
            </ProjectGroup>
          </ProjectSection>
          <ProjectSection>
            <ProjectSectionLabel>
              <FormattedMessage id="ORDER_INSTRUCTIONS" tagName="span" />
              <Tooltip
                id="order-instructions-tooltip"
                element={
                  <LinkLike>
                    <InfoOutlined />
                  </LinkLike>
                }
              >
                <FormattedMessage
                  id="ORDER_INSTRUCTIONS_TOOLTIP"
                  tagName="span"
                />
              </Tooltip>
            </ProjectSectionLabel>
            <ProjectGroup>
              <Subgroup>
                <ProjectLabelDetails>
                  <FormattedMessage id="PROJECT_APPLIED_TO_FUTURE_ORDERS" />
                </ProjectLabelDetails>
                <ProjectReleaseInstructionsTextField
                  name="releaseInstructions.text"
                  disabled={readonly}
                />
              </Subgroup>
              <Subgroup>
                <ProjectLabelDetails>
                  <FormattedMessage id="PROJECT_REFERENCE_MATERIALS" />
                </ProjectLabelDetails>
                <ProjectReleaseAssets
                  name="releaseInstructions.assetUrls"
                  disabled={readonly}
                />
              </Subgroup>
            </ProjectGroup>
          </ProjectSection>
          <FloatingFooter>
            <ButtonContainer>
              <If isTrue={methods.formState.isDirty}>
                <OutlinedButton wide onClick={reset}>
                  <FormattedMessage id="CANCEL" />
                </OutlinedButton>
                <PrimaryButton
                  type="submit"
                  loading={saving}
                  wide
                  testId="save-project"
                >
                  <FormattedMessage id="SAVE" />
                </PrimaryButton>
              </If>
            </ButtonContainer>
          </FloatingFooter>
        </ProjectInformationContainer>
      </FormProvider>
      <ProjectFormCloseDialog
        project={project}
        closeProjectStatus={closedProjectStatus}
        handleConfirm={saveProject}
        handleCancel={() => {
          setSaving(false);
          setClosedProjectStatus(null);
        }}
      />
    </>
  );
};

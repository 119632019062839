import Handsontable from "handsontable";
import { ADD_ITEMS_SOURCE } from "../constants/tableConstants";
import { COLUMN_TYPE } from "../enums/columnType";
import { getPhysicalColumnIndex } from "./getPhysicalColumnIndex";
import { setTableCells } from "./setTableCells";
import { toggleTableReadOnly } from "./toggleTableReadOnly";

export const insertTableRows = (
  dataToInsert: Record<string, string>[],
  hotInstance: Handsontable | undefined | null,
  insertIndex: number,
): void => {
  toggleTableReadOnly(hotInstance, true);
  const cellsToAdd: [number, number, string | number][] = [];
  hotInstance?.alter("insert_row_above", insertIndex, dataToInsert.length);
  dataToInsert.forEach((row, index) => {
    const physicalRow = hotInstance?.toPhysicalRow(insertIndex + index);
    Object.entries(row).forEach(([key, value]) => {
      const columnIndex = getPhysicalColumnIndex(
        hotInstance,
        key as COLUMN_TYPE,
      );
      if (columnIndex !== -1) {
        cellsToAdd.push([physicalRow ?? 0, columnIndex, value]);
      }
    });
  });
  setTableCells(cellsToAdd, hotInstance, ADD_ITEMS_SOURCE);
  hotInstance?.selectRows(insertIndex, insertIndex + dataToInsert.length - 1);
  toggleTableReadOnly(hotInstance, false);
};

import { BuyoutAdditionalChargesAndTaxes } from "@/common/components/buyout-additional-chatrges-and-taxes/BuyoutAdditionalChargesAndTaxes";
import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { goToExternalUrl } from "@/common/utils/browserUtils";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isNonQuotedBuyout } from "@/common/utils/isNonQuotedBuyout";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import { routes } from "@/config/routes";
import {
  BuyoutStatus,
  DistributorBuyoutFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { BuyoutList } from "../BuyoutList";
import { BuyoutHeader } from "../header/BuyoutHeader";
import { DistributorBuyoutItemsProvider } from "../providers/DistributorBuyoutItemsProvider";
import { useDistributorBuyout } from "../providers/DistributorBuyoutProvider";
import { DistributorBuyoutInlineNotesAndTerms } from "./distributor-buyout-notes-and-terms/DistributorBuyoutInlineNotesAndTerms";
import { NonQuotedBuyoutFooter } from "./NonQuotedBuyoutFooter";

const Container = tw.div``;

type Props = {
  buyout: DistributorBuyoutFieldsFragment;
};

export const BuyoutDocument: FC<Props> = ({ buyout }) => {
  const { updateVendorBuyout } = useDistributorBuyout();

  const isNonQuoted = useMemo(() => isNonQuotedBuyout(buyout), [buyout]);

  const total = useMemo(
    () =>
      new DecimalSafe(buyout.subtotal)
        .plus(
          buyout.additionalCharges.reduce(
            (acc, charge) => acc.plus(charge.amount || 0),
            new DecimalSafe(0),
          ),
        )
        .toString(),
    [buyout.subtotal, buyout.additionalCharges],
  );
  return (
    <DistributorBuyoutItemsProvider items={buyout.items}>
      <Container className={isNonQuoted ? "pb-15" : ""}>
        <BuyoutHeader buyout={buyout} />
        <BuyoutList buyout={buyout} />
        <If isTrue={isNonQuoted}>
          <Instructions
            instruction={buyout.instructions}
            projectId={buyout.project.id}
          />
        </If>
        <QuoteDocumentPanel
          quoteDocument={buyout.quoteDocument}
          readonly
          quote={buyout.quote}
          vendorRedirect={() => {
            goToExternalUrl(
              generatePath(routes.distributorBid, {
                quoteId: buyout.quote?.id,
              }) + location.search,
              true,
            );
          }}
        />
        <DistributorBuyoutInlineNotesAndTerms buyout={buyout} />
        <BuyoutAdditionalChargesAndTaxes
          buyout={buyout}
          total={total}
          updateBuyout={isNonQuoted ? updateVendorBuyout : undefined}
          readonlySalesTax
          includePaymentTerms
          includeAdditionalChargesAllowance
          readonlyAdditionalChargesAllowance
          readonly={!checkBuyoutStatus(buyout, [BuyoutStatus.Requested])}
        />
        <If isTrue={isNonQuoted}>
          <NonQuotedBuyoutFooter buyout={buyout} />
        </If>
      </Container>
    </DistributorBuyoutItemsProvider>
  );
};

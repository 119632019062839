import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { Dialog } from "@/common/components/dialog/Dialog";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { CustomPaymentTerms } from "@/common/components/terms-and-discounts/CustomPaymentTerms";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { SplittingInvoicesWizardModal } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/SplittingInvoicesWizardModal";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { EquipmentItemServiceCodeSelectors } from "../components/EquipmentItemServiceCodeSelectors";
import { useInvoiceDocumentOptions } from "../hooks/useInvoiceDocumentOptions";
import { InvoicePaymentMethod } from "../InvoicePaymentMethod";
import { InvoiceAccountingDate } from "./InvoiceAccountingDate";
import { InvoiceDiscount } from "./InvoiceDiscount";
import { InvoiceDiscountDate } from "./InvoiceDiscountDate";
import {
  AddIcon,
  ButtonContent,
  CachedIcon,
  CheckIcon,
  SaveStatusPanel,
} from "./InvoiceDocumentOptions.styles";
import { InvoiceRetention } from "./InvoiceRetention";
import { InvoiceDocumentOptionsProps } from "./types/InvoiceDocumentOptions.types";

type Props = InvoiceDocumentOptionsProps & {
  show: boolean;
};

const VerticalDelimiter = tw.div`border-l border-dashed border-gray-500`;
const HorizontalDelimiter = tw.div`border-b border-dashed border-gray-500`;
const Item = tw.div`grid py-2 gap-x-2 items-center grid-cols-2`;
const OptionsContainer = tw.div`grid grid-cols-[1fr_1px_1fr] gap-x-4`;
const LabelContainer = tw.div`flex items-center gap-2`;
const Block = tw.div`text-sm`;
const Header = tw.div`font-bold text-xl mb-4`;
const Footer = tw.div`flex items-center justify-between flex-row-reverse mt-4`;

export const InvoiceOptionsDialog = ({ show = false, ...props }: Props) => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const {
    invoice,
    includeInputLabels,
    bulkUpdate,
    rescanInvoice,
    readonly,
    togglePanel,
    closeOptions,
    readjust,
  } = props;
  const {
    handleChangePaymentMethod,
    invoicePaymentTerm,
    updating,
    updateInvoicePaymentTerm,
    paymentMethods,
    equipmentItems,
    loadingEquipmentItems,
    serviceCodes,
    loadingServiceCodes,
    hasAccountingDate,
    showRescanInvoice,
    handleRescan,
    loading,
    setUpdatesCount,
    updatesCount,
    discountDate,
    invoiceIssueDate,
    discountLabel,
    discountType,
    discountValue,
    setDiscountDate,
    setDiscountValue,
    handleDiscountTypeChange,
    discountValueWithError,
    handleRetentionTypeChange,
    retentionType,
    retentionValue,
    retentionLabel,
    setRetentionValue,
    accountingDate,
    setAccountingDate,
  } = useInvoiceDocumentOptions({
    invoice,
    includeInputLabels,
    readonly,
    bulkUpdate,
    rescanInvoice,
  });
  const { updateInvoice } = useInvoiceVerification();

  return (
    <Dialog
      show={show}
      maxWidth="md"
      content={
        <>
          <Header>
            <FormattedMessage id="INVOICE_OPTIONS" />
          </Header>
          <OptionsContainer>
            <Block>
              <EquipmentItemServiceCodeSelectors
                invoice={invoice}
                readonly={readonly ?? false}
                setUpdatesCount={setUpdatesCount}
                updateInvoice={updateInvoice}
                equipmentItems={equipmentItems}
                loadingEquipmentItems={loadingEquipmentItems}
                serviceCodes={serviceCodes}
                loadingServiceCodes={loadingServiceCodes}
              />
              <Item>
                <LabelContainer>
                  <FormattedMessage id="INVOICE_RETAINAGE" />
                  <InfoTooltip
                    message={intl.$t({ id: "INVOICE_RETAINAGE_TOOLTIP" })}
                  />
                </LabelContainer>
                <InvoiceRetention
                  readonly={readonly}
                  retentionType={retentionType}
                  retentionValue={retentionValue}
                  setRetentionValue={setRetentionValue}
                  handleRetentionTypeChange={handleRetentionTypeChange}
                  retentionLabel={retentionLabel}
                />
              </Item>
            </Block>
            <VerticalDelimiter />
            <Block>
              <Item>
                <FormattedMessage id="INVOICE_DISCOUNT_DATE" />
                <InvoiceDiscountDate
                  discountDate={discountDate}
                  invoiceIssueDate={invoiceIssueDate}
                  includeInputLabels
                  readonly={readonly}
                  setDiscountDate={setDiscountDate}
                  setDiscountValue={setDiscountValue}
                />
              </Item>
              <Item>
                <FormattedMessage id="INVOICE_DISCOUNT" />
                <InvoiceDiscount
                  discountDate={discountDate}
                  discountLabel={discountLabel}
                  discountType={discountType}
                  discountValue={discountValue}
                  readonly={readonly}
                  setDiscountValue={setDiscountValue}
                  handleDiscountTypeChange={handleDiscountTypeChange}
                  discountValueWithError={discountValueWithError}
                />
              </Item>
              <Item>
                <FormattedMessage id="PAYMENT_TERMS" />
                <CustomPaymentTerms
                  paymentTerm={invoicePaymentTerm}
                  updateItem={updateInvoicePaymentTerm}
                  hideLabel
                  classes={{
                    container: "w-full",
                    select: "mr-0 ml-2",
                  }}
                />
              </Item>
              <If
                isTrue={
                  paymentMethods.length &&
                  hasFeatureInConnectedSourceSystem(
                    IntegrationFeature.IncludeInvoicePaymentMethods,
                  ) &&
                  !!connectedSourceSystem
                }
              >
                <Item>
                  <FormattedMessage id="PAYMENT_METHOD" />
                  <InvoicePaymentMethod
                    paymentMethodId={invoice?.paymentMethod?.id}
                    setPaymentMethodId={handleChangePaymentMethod}
                    readonly={readonly || updating}
                    includeInputLabels={includeInputLabels}
                  />
                </Item>
              </If>
              <If isTrue={hasAccountingDate}>
                <Item>
                  <FormattedMessage id="INVOICE_ACCOUNTING_DATE" />
                  <InvoiceAccountingDate
                    accountingDate={accountingDate}
                    setAccountingDate={setAccountingDate}
                  />
                </Item>
              </If>
            </Block>
            <HorizontalDelimiter className="col-span-3 mt-4" />
          </OptionsContainer>
          <If isTrue={!readonly}>
            <Block className="mt-4 flex items-center">
              <If isTrue={readjust}>
                <LabelContainer>
                  <FormattedMessage id="MISSING_OR_WRONG_PAGES" />
                  <InfoTooltip
                    message={intl.$t({ id: "MISSING_OR_WRONG_PAGES_TOOLTIP" })}
                  />
                  <SplittingInvoicesWizardModal />
                </LabelContainer>
                <OutlinedButton $small onClick={readjust} className="ml-2 h-6">
                  <FormattedMessage id="READJUST" />
                </OutlinedButton>
              </If>
              <Block className="ml-auto">
                <If isTrue={togglePanel}>
                  <OutlinedButton
                    $small
                    onClick={() => {
                      togglePanel?.(true);
                      closeOptions();
                    }}
                    className="h-6 pr-4"
                  >
                    <ButtonContent>
                      <AddIcon className="text-blue-800" />
                      <FormattedMessage id="UPLOAD_CORRECTION" />
                    </ButtonContent>
                  </OutlinedButton>
                </If>

                <If isTrue={showRescanInvoice}>
                  <LoadingButton
                    button={OutlinedButton}
                    onClick={handleRescan}
                    loading={loading}
                    $small
                    className="ml-2 h-6 pr-4"
                  >
                    <ButtonContent>
                      <CachedIcon className="text-blue-800" />
                      <FormattedMessage id="RESCAN_INVOICE" />
                    </ButtonContent>
                  </LoadingButton>
                </If>
              </Block>
            </Block>
            <HorizontalDelimiter className="mt-4" />
          </If>
          <Footer>
            <OutlinedButton onClick={props.closeOptions}>
              <FormattedMessage id="CLOSE" />
            </OutlinedButton>
            <If isTrue={updatesCount}>
              <SaveStatusPanel>
                <CheckIcon />
                <FormattedMessage id="CHANGES_SAVED" />
              </SaveStatusPanel>
            </If>
          </Footer>
        </>
      }
      extraButtonMargin={false}
      sx={{
        ".MuiPaper-root": {
          backgroundColor: "#D3DFF3",
        },
        ".MuiDialogActions-root": {
          backgroundColor: "#D3DFF3 !important",
        },
      }}
    />
  );
};

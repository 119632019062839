import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useInvoicePermissionsLazyQuery } from "@/generated/graphql";
import { useCallback } from "react";
import { MatchedOrderViewState } from "../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../providers/InvoiceMatchedOrderProvider";

export const useInvoicePermissions = () => {
  const [loadInvoicePermissions, { loading, error, data }] =
    useInvoicePermissionsLazyQuery();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();

  const fetchInvoicePermissions = useCallback(
    (invoiceId: string, projectId: string | null | undefined) => {
      const isHeaderOnlyInvoice =
        matchedOrderViewState ===
        MatchedOrderViewState.HEADER_ONLY_INVOICE_EDITABLE;
      if (invoiceId && (projectId || isHeaderOnlyInvoice)) {
        return loadInvoicePermissions({
          variables: {
            id: invoiceId,
            projectId: projectId || undefined,
            includeApprove: !!projectId || isHeaderOnlyInvoice,
          },
        });
      }
      return { data: undefined };
    },

    [loadInvoicePermissions, matchedOrderViewState],
  );

  useErrorEffect(error);

  return {
    permissions: data?.invoice.permissions,
    loading,
    fetchInvoicePermissions,
    error,
  };
};

import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.span`text-center text-sm`;

export const VendorView = ({ id }: { id: string | undefined }) => {
  const { vendors } = useVendors();

  const vendor = useMemo(() => {
    return vendors.find((vendor) => vendor.sellerOrgLocation.id === id);
  }, [id, vendors]);

  return (
    <Container>
      {vendor ? (
        <>{vendor.sellerOrgLocation.org.name}</>
      ) : (
        <FormattedMessage id="NONE" />
      )}
    </Container>
  );
};

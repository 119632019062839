import { UTC_OFFSET } from "@/common/const";
import { isSameDay, isSameMonth, isSameYear } from "date-fns";
import { useIntl } from "react-intl";
import { DateRangePickerValue } from "./types";

const useRangeLabelFormat = (isUTC: boolean = true) => {
  const intl = useIntl();

  const generateLabel = ({ startDate, endDate }: DateRangePickerValue) => {
    if (!startDate && !endDate) {
      return "";
    }

    const normalizedStartDate = isUTC
      ? startDate
        ? new Date(startDate?.getTime() + UTC_OFFSET)
        : undefined
      : startDate;

    const normalizedEndDate = isUTC
      ? endDate
        ? new Date(endDate?.getTime() + UTC_OFFSET)
        : undefined
      : endDate;

    const sameDay =
      normalizedStartDate &&
      normalizedEndDate &&
      isSameDay(normalizedStartDate, normalizedEndDate);
    const sameMonth =
      normalizedStartDate &&
      normalizedEndDate &&
      isSameMonth(normalizedStartDate, normalizedEndDate);
    const sameYear =
      normalizedStartDate &&
      normalizedEndDate &&
      isSameYear(normalizedStartDate, normalizedEndDate);

    if (sameDay) {
      return intl.formatDate(normalizedStartDate, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    }

    if (sameYear) {
      if (sameMonth) {
        return `${intl.formatDate(normalizedStartDate, {
          month: "short",
        })} ${intl.formatDate(normalizedStartDate, {
          day: "numeric",
        })} - ${intl.formatDate(normalizedEndDate, {
          day: "numeric",
        })}, ${intl.formatDate(normalizedStartDate, { year: "numeric" })}`;
      } else {
        return `${intl.formatDate(normalizedStartDate, {
          month: "short",
        })} ${intl.formatDate(normalizedStartDate, {
          day: "numeric",
        })} - ${intl.formatDate(normalizedEndDate, {
          month: "short",
        })} ${intl.formatDate(normalizedEndDate, {
          day: "numeric",
        })}, ${intl.formatDate(normalizedStartDate, { year: "numeric" })}`;
      }
    } else {
      return `${intl.formatDate(normalizedStartDate, {
        day: "numeric",
        month: "short",
        year: "numeric",
      })} - ${intl.formatDate(normalizedEndDate, {
        day: "numeric",
        month: "short",
        year: "numeric",
      })}`;
    }
  };

  return { generateLabel };
};

export default useRangeLabelFormat;

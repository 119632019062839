import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { clearTable } from "@/common/components/spreadsheet-table/utils/clearTable";
import { Switch } from "@/common/components/switch/Switch";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { Circle, RefreshOutlined } from "@mui/icons-material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useProjectBudget } from "../../../hooks/useProjectBudget";
import { useBudgetByCostCodes } from "../useBudgetByCostCodes";

const SwitchContainer = tw.div`grid grid-flow-col text-base gap-2 items-center`;
const SyncText = tw.div`grid items-end`;
const SyncTextGroup = tw.div`flex justify-end gap-1 place-items-center text-2xs text-gray-600`;
const RefreshOutlinedStyled = tw(
  RefreshOutlined,
)`text-lg cursor-pointer text-blue-500 hover:text-blue-300`;
const StatusCircle = tw(Circle)<{ $error: boolean }>`
  text-2xs -mt-0.5
  ${({ $error }) => ($error ? "text-red-500" : "text-green-800")}
`;

type AutoSyncSwitchProps = {
  readOnly: boolean;
};

export const AutoSyncSwitch = ({ readOnly }: AutoSyncSwitchProps) => {
  const intl = useIntl();
  const { syncBudget, syncBudgetLoading, budgetLink } = useProjectBudget();
  const [autoSyncBudget, setAutoSyncBudget] = useState(
    budgetLink?.autoSync ?? false,
  );
  const { saveAutoSyncBudget } = useBudgetByCostCodes();
  const { openDialog } = useDialog();
  const { handsonInstance } = useColumnMapper();
  const { settings, connectedSourceSystem } = useOrgSettings();
  const { project } = useProject();

  useEffect(() => {
    setAutoSyncBudget(budgetLink?.autoSync ?? false);
  }, [budgetLink?.autoSync]);

  const onAutoSyncChange = useCallback(
    async (value: boolean) => {
      if (!project) {
        return;
      }
      const result = await saveAutoSyncBudget(value);
      if (result && value && budgetLink?.id) {
        clearTable(handsonInstance);
        syncBudget(budgetLink.id);
      }
      setAutoSyncBudget(value);
    },
    [project, saveAutoSyncBudget, budgetLink?.id, handsonInstance, syncBudget],
  );

  const handleAutoSyncClick = useCallback(
    (value: boolean) => {
      if (value) {
        if (!settings?.display?.usesProjectCostCodes) {
          openDialog({
            maxWidth: "xs",
            cancelButtonText: intl.$t({ id: "CANCEL" }),
            confirmButtonText: intl.$t({ id: "CONFIRM" }),
            includeWarningIcon: true,
            title: intl.$t(
              { id: "AUTO_SYNC_BUDGET" },
              {
                sourceSystem: intl.$t({
                  id: `INTEGRATION_${connectedSourceSystem}`,
                }),
                br: <br key="budget-auto-sync-br" />,
              },
            ),
            content: intl.$t(
              { id: "ENABLE_PROJECT_AUTO_SYNC_INFO" },
              {
                sourceSystem: intl.$t({
                  id: `INTEGRATION_${connectedSourceSystem}`,
                }),
              },
            ),
            handleConfirm: async () => {
              await onAutoSyncChange(value);
            },
            classes: { container: "text-center" },
          });
          return;
        }
        openDialog({
          maxWidth: "xs",
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "CONFIRM" }),
          includeErrorIcon: true,
          title: intl.$t({
            id: "USE_PROJECT_SPECIFIC_COST_CODES",
          }),
          content: intl.$t({
            id: "USE_PROJECT_SPECIFIC_COST_CODES_INFO",
          }),
          handleConfirm: async () => {
            await onAutoSyncChange(value);
          },
          classes: { container: "text-center" },
        });
        return;
      }
      if (project?.usesProjectCostCodes) {
        openDialog({
          maxWidth: "xs",
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "CONFIRM" }),
          includeErrorIcon: true,
          title: intl.$t({
            id: "USE_ORG_LEVEL_COST_CODES",
          }),
          content: intl.$t({
            id: "USE_ORG_LEVEL_COST_CODES_INFO",
          }),
          handleConfirm: async () => {
            await onAutoSyncChange(value);
          },
          classes: { container: "text-center" },
        });
        return;
      }
      onAutoSyncChange(value);
    },
    [
      project?.usesProjectCostCodes,
      onAutoSyncChange,
      settings?.display?.usesProjectCostCodes,
      openDialog,
      intl,
      connectedSourceSystem,
    ],
  );

  const autoSyncDate = useMemo(() => {
    const time = budgetLink?.syncedAt || budgetLink?.createdAt;
    return time ? moment(time).format("L, LT") : "";
  }, [budgetLink?.syncedAt, budgetLink?.createdAt]);

  return (
    <SwitchContainer>
      <SyncText className="text-sm">
        <FormattedMessage
          id="AUTO_SYNC_BUDGET"
          values={{
            sourceSystem: intl.$t({
              id: `INTEGRATION_${connectedSourceSystem}`,
            }),
            br: <br key="budget-auto-sync-br" />,
          }}
        />
        <If isTrue={budgetLink?.autoSync}>
          <SyncTextGroup>
            <Tooltip
              element={<StatusCircle $error={!!budgetLink?.autoSyncError} />}
            >
              {budgetLink?.autoSyncError}
            </Tooltip>
            <FormattedMessage
              id="AUTO_SYNC_BUDGET_LAST_SYNCED"
              values={{ lastSynced: autoSyncDate }}
            />
            {syncBudgetLoading ? (
              <Loader small loading className="w-auto p-px" />
            ) : (
              <RefreshOutlinedStyled
                onClick={() => syncBudget(budgetLink?.id ?? "")}
              />
            )}
          </SyncTextGroup>
        </If>
      </SyncText>
      <Switch
        onLabel={intl.$t({ id: "YES" })}
        offLabel={intl.$t({ id: "NO" })}
        value={autoSyncBudget}
        onChange={handleAutoSyncClick}
        disabled={readOnly}
      />
    </SwitchContainer>
  );
};

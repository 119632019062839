import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useInvoiceStats } from "@/contractor/pages/home/invoices/pages/scanned-invoices/hooks/useInvoiceStats";
import {
  ApproveInvoiceInput,
  useApproveReceiptMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useApproveReceipt = () => {
  const { setError } = useGlobalError();
  const [approveReceiptMutation, { loading }] = useApproveReceiptMutation();
  const { refetch } = useInvoiceStats();

  const approveReceipt = useCallback(
    async (input: ApproveInvoiceInput) => {
      try {
        const { data, errors } = await approveReceiptMutation({
          variables: {
            input,
          },
        });
        if (!errors) {
          refetch();
        }
        setError(errors);
        return Boolean(!!data?.approveReceipt && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [approveReceiptMutation, refetch, setError],
  );

  return {
    approveReceipt,
    loading,
  };
};

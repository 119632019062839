import { useWizardStore } from "@/common/components/wizard-modal/store/useWizardStore";
import { ReleaseDeliverySlipFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

export const useTransactionHistoryInflows = () => {
  const { setArgs } = useWizardStore(
    useShallow((state) => ({
      setArgs: state.setArgs,
    })),
  );

  const onClick = useCallback(
    (deliverySlip?: ReleaseDeliverySlipFieldsFragment) => {
      if (deliverySlip?.inventoryTransaction?.id) {
        setArgs({
          transactionId: deliverySlip?.inventoryTransaction?.id,
        });
      }
    },
    [setArgs],
  );

  return { onClick };
};

import { DiscardButton } from "@/common/components/button/DiscardButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { SourceSystemWrapper } from "@/common/components/source-system-wrapper/SourceSystemWrapper";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { IntegrationType } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceSequence } from "../../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../../providers/InvoicesProvider";
import { ConnectInvoicesPanel } from "../../export/connect-invoice-panel/ConnectInvoicesPanel";
import { ExportInvoicesPanel } from "../../export/export-invoices-panel/ExportInvoicesPanel";
import { useExportInvoicesButton } from "./hooks/useExportInvoicesButton";
const Container = tw.div`
  grid grid-cols-[75px_repeat(5,_auto)] gap-2 relative w-fit place-items-center
`;
const NumberContainer = tw.div`
  text-sm font-medium
  ${({ $hasItems }: { $hasItems: boolean }) =>
    $hasItems ? "text-green-800" : ""}
`;
const InfoText = tw.span`grid text-2xs max-w-20 font-normal`;

export const ExportInvoicesButton: FC = () => {
  const { connectedSourceSystem, connectedIntegrationType } = useOrgSettings();
  const { sequenceIds, stopSequence, selectedInvoices } = useInvoiceSequence();
  const { exportEnabled, setExportEnabled } = useInvoices();
  const { onClosePanel, startExportFlow, onStartSequenceClicked } =
    useExportInvoicesButton();

  if (!exportEnabled) {
    return (
      <OutlinedButton
        testId="export-invoices"
        onClick={startExportFlow}
        className="truncate"
      >
        <FormattedMessage id="CREATE_SEQUENCE" />
      </OutlinedButton>
    );
  }
  return (
    <Container data-testid="batchControl">
      <FormattedMessage
        id="USE_CHECKBOXES_TO_SELECT_ITEMS"
        tagName={InfoText}
      />
      <NumberContainer $hasItems={selectedInvoices.length > 0}>
        <FormattedMessage
          id="NUMBER_OF_SELECTED"
          values={{ count: sequenceIds.length }}
        />
      </NumberContainer>
      <DrawerPanel
        anchor={(togglePanel) => (
          <>
            <PrimaryButton
              testId="start-sequence"
              onClick={onStartSequenceClicked}
              disabled={sequenceIds.length === 0}
            >
              <FormattedMessage id="START_SEQUENCE" />
            </PrimaryButton>
            <SourceSystemWrapper
              allowedIntegrationTypes={[IntegrationType.Foundation]}
              anySourceSystem
            >
              <Tooltip
                element={
                  <PrimaryButton
                    testId="export-multiple-invoices"
                    onClick={() => togglePanel(true)}
                    disabled={
                      sequenceIds.length === 0 ||
                      selectedInvoices.some((invoice) => !invoice.release)
                    }
                  >
                    <FormattedMessage id="EXPORT" />
                  </PrimaryButton>
                }
                id="export-button"
              >
                {selectedInvoices.some((invoice) => !invoice.release) ? (
                  <FormattedMessage id="DISABLE_EXPORT_UNMATCHED_INVOICES" />
                ) : undefined}
              </Tooltip>
            </SourceSystemWrapper>
          </>
        )}
        content={(togglePanel) =>
          connectedIntegrationType && !connectedSourceSystem ? (
            <ExportInvoicesPanel
              invoiceIds={sequenceIds}
              onClose={() => onClosePanel(togglePanel)}
            />
          ) : (
            <ConnectInvoicesPanel
              invoiceIds={sequenceIds}
              onClose={() => onClosePanel(togglePanel)}
            />
          )
        }
      />
      <Tooltip
        element={
          <DiscardButton
            onClick={() => {
              stopSequence();
              setExportEnabled(false);
            }}
          />
        }
        id="discard-button"
      >
        <FormattedMessage id="CLEAR_SEQUENCE" />
      </Tooltip>
    </Container>
  );
};

import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { If } from "@/common/components/if/If";
import { TextField } from "@/common/components/textfield/TextField";
import { FaCheck, FaTimes, FaTrash } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import tw from "tailwind-styled-components";
import { SimpleStepTitle } from "../../../../../Wizard.styles";
import { Template } from "../../types/Template";
import { useCustomTemplateTitle } from "./useCustomTemplateTitle";

const Title = tw(SimpleStepTitle)<{ $editing: boolean }>`
  grid grid-cols-[1fr_auto_auto] items-center mt-1 mr-4 font-medium rounded-md text-md
  ${({ $editing }) => ($editing ? "text-blue-500" : "")}
`;

type CustomTemplateTitleProps = {
  template: Template;
};

export const CustomTemplateTitle = ({ template }: CustomTemplateTitleProps) => {
  const {
    editing,
    templateName,
    setTemplateName,
    handleEdit,
    handleSave,
    handleCancel,
    handleDelete,
    placeholder,
  } = useCustomTemplateTitle(template);

  return (
    <Title $editing={editing}>
      <If isTrue={!editing}>{templateName}</If>
      <If isTrue={editing}>
        <TextField
          placeholder={placeholder}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSave();
            }
          }}
          onChange={(e) => setTemplateName(e.target.value)}
          value={templateName}
        />
      </If>
      <If isTrue={!editing}>
        <IconButtonBorderless onClick={handleEdit}>
          <FaPencil className="text-gray-500 hover:text-blue-500" />
        </IconButtonBorderless>
        <IconButtonBorderless onClick={handleDelete}>
          <FaTrash className="text-gray-500 hover:text-red-500" />
        </IconButtonBorderless>
      </If>
      <If isTrue={editing}>
        <IconButtonBorderless onClick={handleSave}>
          <FaCheck className="text-gray-500 hover:text-blue-500" />
        </IconButtonBorderless>
        <IconButtonBorderless onClick={handleCancel}>
          <FaTimes className="text-gray-500 hover:text-blue-500" />
        </IconButtonBorderless>
      </If>
    </Title>
  );
};

import { If } from "@/common/components/if/If";
import { CalendarToday, CloseOutlined } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from "@mui/x-date-pickers";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { DateRangePickerValue } from "./types";
import useRangeLabelFormat from "./useRangeLabelFormat";

type ButtonFieldProps = BaseSingleInputFieldProps<
  DateRangePickerValue | null,
  Date,
  FieldSection,
  boolean,
  DateValidationError
> & {
  dateRange: DateRangePickerValue;
  setIsOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  clearValues?: () => void;
  includeLabel?: boolean;
  isUTC?: boolean;
};

const Label = tw.label`text-xs ml-2`;
const StyledCloseOutlined = tw(
  CloseOutlined,
)`bg-gray-500 text-white rounded-full text-xs cursor-pointer ml-2`;
const StyledButton = tw(
  Button,
)<ButtonProps>`rounded-lg bg-white min-w-[40px] text-normal`;

const CustomPickersButton: FC<ButtonFieldProps> = ({
  setIsOpened,
  clearValues,
  dateRange,
  id,
  disabled,
  isUTC,
  InputProps: { ref } = {},
  inputProps: { "aria-label": ariaLabel } = {},
  includeLabel = true,
}) => {
  const { generateLabel } = useRangeLabelFormat(isUTC);
  const label = useMemo(
    () => generateLabel(dateRange || {}),
    [generateLabel, dateRange],
  );

  return (
    <StyledButton
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setIsOpened?.((prev) => !prev)}
    >
      <CalendarToday />
      <If isTrue={!!label && includeLabel}>
        <Label>{label}</Label>
      </If>
      <If isTrue={!!label && includeLabel}>
        <StyledCloseOutlined
          onClick={(e) => {
            e.stopPropagation();
            clearValues?.();
          }}
        />
      </If>
    </StyledButton>
  );
};

export default CustomPickersButton;

import { DateView } from "@/common/utils/dates/DateView";
import { FC } from "react";
import tw from "tailwind-styled-components";

type Props = {
  date: number;
};

const Container = tw.div`col-span-2`;
const DateItem = tw.div`flex items-center justify-center text-sm font-medium text-center`;
const Type = tw.div`text-xs text-gray-600 flex items-center justify-center text-center 2xl:whitespace-nowrap`;

export const ListViewDate: FC<Props> = ({ date }) => {
  return (
    <Container>
      <DateItem>
        <DateView date={date} />
      </DateItem>
      <Type>
        <DateView date={date} onlyTime />
      </Type>
    </Container>
  );
};

import { ListSelectAll } from "@/common/components/list-select-all/ListSelectAll";
import { useIntl } from "react-intl";
import { useDeliverySlipSequence } from "../../providers/DeliverySlipSequenceProvider";
import { useDeliverySlips } from "../../providers/DeliverySlipsProvider";

export const DeliverySlipsSelectAll = () => {
  const intl = useIntl();
  const { totalCount } = useDeliverySlips();
  const { selectedDeliverySlips, setSelectedDeliverySlips } =
    useDeliverySlipSequence();

  return (
    <ListSelectAll
      allItemsSelected={false}
      totalCount={totalCount}
      selectedCount={selectedDeliverySlips.length}
      itemNamePlural={intl.$t({ id: "PACKING_SLIPS" })}
      itemNameSingular={intl.$t({ id: "PACKING_SLIP" })}
      clearAll={() => setSelectedDeliverySlips([])}
      hideSelectAll
    />
  );
};

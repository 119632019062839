import { useEffect, useMemo, useState } from "react";

type Input = {
  selectedCount: number;
  totalCount: number;
};

export const useListSelection = ({ selectedCount, totalCount }: Input) => {
  const [allItemsSelected, setAllItemsSelected] = useState(false);

  useEffect(() => {
    if (!!totalCount && selectedCount === totalCount) {
      setAllItemsSelected(true);
    }
  }, [selectedCount, totalCount]);

  const showSelectAllView = useMemo(
    () => !!selectedCount && !allItemsSelected,
    [selectedCount, allItemsSelected],
  );

  return {
    allItemsSelected,
    setAllItemsSelected,
    showSelectAllView,
  };
};

import { FC } from "react";
import tw from "tailwind-styled-components";
import { VendorImportInputStatus } from "./VendorImportInputStatus";
import { VendorStaticDetailText } from "./VendorStaticDetailText";

const Container = tw.div`flex flex-row items-center gap-1`;

type Props = {
  detail: string | null | undefined;
  label: string;
};

export const VendorFilledDetail: FC<Props> = ({ detail, label }) => {
  if (!detail) {
    return null;
  }
  return (
    <Container>
      <VendorImportInputStatus success />
      <VendorStaticDetailText label={label} value={detail} />
    </Container>
  );
};

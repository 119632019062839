import { SequenceStepper } from "@/common/components/sequence-stepper/SequenceStepper";
import { routes } from "@/config/routes";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { useInventoryItemSequence } from "../../../components/current-stock-list/components/hooks/useInventoryItemSequence";

export const InventoryAdjustmentSequenceStepper = () => {
  const navigate = useNavigate();
  const { step, setStep, sequenceIds, redirectToList, stopSequence } =
    useInventoryItemSequence();

  const prevStepCallback = useCallback(() => {
    navigate({
      pathname: routes.inventoryAdjustment,
      search: `?warehouseId=${sequenceIds[step - 1]}`,
    });
  }, [navigate, sequenceIds, step]);

  const nextStepCallback = useCallback(() => {
    navigate({
      pathname: routes.inventoryAdjustment,
      search: `?warehouseId=${sequenceIds[step + 1]}`,
    });
  }, [navigate, sequenceIds, step]);

  const onExitSequence = useCallback(() => {
    stopSequence();
    redirectToList();
  }, [redirectToList, stopSequence]);

  return (
    <SequenceStepper
      titleId="ADJUSTMENT"
      step={step}
      setStep={setStep}
      sequenceIds={sequenceIds}
      exitSequence={onExitSequence}
      prevStepCallback={prevStepCallback}
      nextStepCallback={nextStepCallback}
    />
  );
};

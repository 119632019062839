import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { Switch } from "@/common/components/switch/Switch";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { PoFormat } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`
  grid grid-flow-col gap-1 justify-end text-xs font-normal items-center my-1
`;

const Label = tw.div`w-[160px]`;

export const ExcludePendingInvoicesToggle: FC = () => {
  const intl = useIntl();
  const { invoice, excludePendingInvoices, setExcludePendingInvoices } =
    useInvoiceVerification();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const shouldShowToggle = useMemo(() => {
    return (
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.POItemRemainingAmount,
      ) &&
      !!invoice?.release?.poLink &&
      invoice.release?.type.poFormat === PoFormat.Detail
    );
  }, [
    hasFeatureInConnectedSourceSystem,
    invoice?.release?.poLink,
    invoice?.release?.type.poFormat,
  ]);

  if (!shouldShowToggle) {
    return null;
  }

  return (
    <Container>
      <Label>
        <FormattedMessage id="EXCLUDE_PENDING_INVOICES" />
      </Label>
      <Switch
        value={excludePendingInvoices}
        onChange={setExcludePendingInvoices}
        width={60}
        offLabel={intl.$t({ id: "NO" })}
        onLabel={intl.$t({ id: "YES" })}
      />
    </Container>
  );
};

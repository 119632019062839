import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { FormattedMessage } from "react-intl";
import { SelectProjectDialog } from "../select-project-dialog/SelectProjectDialog";
import { useNewRestock } from "./useNewRestock";

export const NewRestockButton = () => {
  const {
    handleAdjustment,
    open,
    setOpen,
    restockOrderType,
    handleConfirm,
    creating,
  } = useNewRestock();

  return (
    <>
      <Tooltip
        element={
          <PrimaryButton
            onClick={handleAdjustment}
            disabled={!restockOrderType}
          >
            <FormattedMessage id="RESTOCK_ORDER" />
          </PrimaryButton>
        }
      >
        {!restockOrderType ? (
          <FormattedMessage id="RESTOCK_ORDER_DISABLED_TOOLTIP" />
        ) : (
          ""
        )}
      </Tooltip>
      <SelectProjectDialog
        open={open}
        onClose={() => setOpen(false)}
        labelKey="SELECT_PROJECT"
        handleConfirm={handleConfirm}
        restock
        creating={creating}
      />
    </>
  );
};

import { useIsWarehouseManager } from "@/common/components/warehouse-selector/hooks/useIsWarehouseManager";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { routes } from "@/config/routes";
import {
  ReleaseReassignmentFieldsFragment,
  ReleaseStatus,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseOperation,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { useReleaseActions } from "../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";
import {
  ReleaseAlertType,
  useReleaseAlert,
} from "../hooks/release-alerts/useReleaseAlert";

export type CallbackFunction = ({
  skip,
  receive,
  version,
  callback,
  skipVendorNotification,
  skipConfirmation,
  schedule,
}: {
  skip?: boolean;
  receive?: boolean;
  version?: number;
  callback?: (result: boolean) => void;
  skipVendorNotification?: boolean;
  skipConfirmation?: boolean;
  schedule?: boolean;
}) => Promise<boolean | ReleaseReassignmentFieldsFragment[]>;

export const useUpdateReleaseDialog = ({
  callback,
  setVisible,
  visible,
  withCompletionDialog,
  withRedirect,
}: {
  callback?: CallbackFunction;
  setVisible: (visible: boolean) => void;
  visible: boolean;
  withRedirect?: boolean;
  withCompletionDialog?: boolean;
}) => {
  const [skipVendorNotification, setSkipVendorNotification] = useState(false);
  const [skipConfirmation, setSkipConfirmation] = useState(false);
  const { release } = useRelease();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const { canConfirmWarehouseRelease } = useIsWarehouseManager();

  const isWarehouseManagerAndWarehouseRelease = useMemo(
    () => canConfirmWarehouseRelease(release),
    [canConfirmWarehouseRelease, release],
  );
  const { triggerAlert } = useReleaseAlert();
  const { setRequestedDate } = useReleaseActions();
  const { updateRelease } = useReleaseUpdate();
  const { releaseUpdateOptions } = useReleaseStore(
    useShallow((state) => ({
      releaseUpdateOptions: state.releaseUpdateOptions,
    })),
  );

  useEffect(() => {
    if (visible) {
      setSkipVendorNotification(false);
      setSaving(false);
    }
  }, [visible]);

  useEffect(() => {
    setSkipConfirmation(skipVendorNotification);
  }, [skipVendorNotification]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const displayDialog = useCallback(
    (
      result: boolean | ReleaseReassignmentFieldsFragment[],
      opts?: { scheduleWarehouseOrder: boolean },
    ) => {
      if (result && release?.id) {
        if (withCompletionDialog) {
          triggerAlert(
            opts?.scheduleWarehouseOrder
              ? ReleaseAlertType.Confirm
              : ReleaseAlertType.Update,
            {
              release,
            },
          );
          if (withRedirect) {
            setTimeout(() => {
              navigate(
                generatePath(routes.delivery, {
                  deliveryId: release.id,
                }),
              );
            }, DIALOG_AUTO_CLOSE_TIMER);
          }
        }
        setSaving(false);
        setVisible(false);
      }
    },
    [
      navigate,
      release,
      setVisible,
      triggerAlert,
      withCompletionDialog,
      withRedirect,
    ],
  );

  const handleConfirm = useCallback(async () => {
    const scheduleWarehouseOrder =
      release?.status === ReleaseStatus.Requested &&
      isWarehouseManagerAndWarehouseRelease;
    const date = releaseUpdateOptions.requestedTime
      ? new Date(releaseUpdateOptions.requestedTime)
      : undefined;
    if (!callback) {
      setVisible(false);
      return;
    }

    setSaving(true);
    if (
      release?.id &&
      (releaseUpdateOptions.requestedTime || scheduleWarehouseOrder)
    ) {
      await updateRelease({
        releaseId: release.id,
        version: release.version,
        ...(scheduleWarehouseOrder && {
          operation: UpdateContractorReleaseOperation.Schedule,
        }),
        ...(releaseUpdateOptions.requestedTime && {
          requestedTime: date?.getTime(),
          timeTBD: false,
        }),
      });
    }

    await callback({
      skip: skipConfirmation,
      callback: (result) => {
        displayDialog(result, { scheduleWarehouseOrder });
      },
      skipVendorNotification:
        release?.status !== ReleaseStatus.Received
          ? skipVendorNotification
          : true,
    });
  }, [
    callback,
    displayDialog,
    isWarehouseManagerAndWarehouseRelease,
    release?.id,
    release?.status,
    release?.version,
    releaseUpdateOptions.requestedTime,
    setVisible,
    skipConfirmation,
    skipVendorNotification,
    updateRelease,
  ]);

  const onDateConfirm = useCallback(
    async (date: Date | null | undefined) => {
      const isTBD = date === undefined;
      setRequestedDate(date ?? null);

      if (release) {
        const input: UpdateContractorReleaseInput = {
          releaseId: release?.id,
          version: release?.version,
        };

        input.requestedTime = !isTBD && date ? date.getTime() : undefined;
        input.timeTBD = isTBD;

        await updateRelease(input);
      }
    },
    [release, setRequestedDate, updateRelease],
  );

  const titleKey = useMemo(
    () =>
      isWarehouseManagerAndWarehouseRelease &&
      release?.status === ReleaseStatus.Requested
        ? "CONFIRM_WH_RELEASE_MESSAGE"
        : undefined,
    [isWarehouseManagerAndWarehouseRelease, release?.status],
  );

  return {
    onDateConfirm,
    handleConfirm,
    handleCancel,
    saving,
    setSaving,
    skipVendorNotification,
    setSkipVendorNotification,
    skipConfirmation,
    setSkipConfirmation,
    isWarehouseManagerAndWarehouseRelease,
    titleKey,
  };
};

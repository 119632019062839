import { useReleaseSequence } from "@/contractor/pages/home/releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";

export const useReleaseVendorReassignments = () => {
  const {
    startSequence,
    sequenceActive,
    setSelectedReleases,
    step,
    selectedReleases,
  } = useReleaseSequence();

  const setReleaseVendorReassignments = useCallback(
    (
      release:
        | Pick<ReleaseFieldsFragment, "reassignedReleases" | "id" | "status">
        | null
        | undefined,
    ) => {
      if (!release) {
        return [];
      }
      let newAssignments = [];
      if (release.reassignedReleases?.length) {
        newAssignments = [
          ...(sequenceActive
            ? selectedReleases.slice(0, step + 1)
            : [
                {
                  id: release.id,
                  status: release.status,
                },
              ]),
          ...release.reassignedReleases,
          ...(sequenceActive ? selectedReleases.slice(step + 1) : []),
        ];
      } else {
        newAssignments = selectedReleases.map((rel) => ({
          ...rel,
          status: rel.id === release.id ? release.status : rel.status,
        }));
      }
      if (newAssignments.length > 0) {
        if (!sequenceActive) {
          startSequence(newAssignments, 1);
        } else {
          setSelectedReleases(newAssignments);
        }
      }

      return newAssignments;
    },
    [
      selectedReleases,
      sequenceActive,
      setSelectedReleases,
      startSequence,
      step,
    ],
  );

  return { setReleaseVendorReassignments };
};

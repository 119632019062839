import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { PhoneAndroid } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { PhoneView } from "../phone-input/PhoneView";
import { Tooltip } from "../tooltip/Tooltip";

type Props = {
  contacts?: OrgPreferredVendorsFieldsFragment["contacts"];
};

const ContactsContainer = tw.div`flex items-center flex-wrap gap-4 flex-1`;
const ContactContainer = tw.div`text-sm flex items-center gap-1`;
const UserData = tw.div``;

export const SupplierContacts = ({ contacts }: Props) => {
  return (
    <ContactsContainer>
      {contacts?.map((contact) => (
        <ContactContainer key={contact.id}>
          <UserData>{contact.name}</UserData>
          <Tooltip
            id={`supplier-contact-${contact.id}`}
            element={<PhoneAndroid className="h-5 w-5 fill-blue-500" />}
            className="flex flex-col"
          >
            <UserData>
              <FormattedMessage id="PHONE_WITH_VALUE" values={{ value: "" }} />
              <PhoneView
                value={contact.cellPhone}
                phoneExtension={contact.cellPhoneExtension}
              />
            </UserData>
            <UserData>
              <FormattedMessage
                id="EMAIL_WITH_VALUE"
                values={{ value: contact.email }}
              />
            </UserData>
          </Tooltip>
        </ContactContainer>
      ))}
    </ContactsContainer>
  );
};

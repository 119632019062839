import { FormattedMessage } from "react-intl";
import {
  TransactionFooterCell,
  TransactionFooterCellHeader,
  TransactionFooterCol,
} from "../../../../../TransactionHistoryModal.styles";

export const TransactionHistoryInflowsFooterLabels = () => {
  return (
    <TransactionFooterCol>
      <TransactionFooterCellHeader />
      <TransactionFooterCell>
        <FormattedMessage id="SUBTOTAL" />:
      </TransactionFooterCell>
      <TransactionFooterCell className="text-nowrap">
        <FormattedMessage id="ADDITIONAL_CHARGES" />:
      </TransactionFooterCell>
      <TransactionFooterCell>
        <FormattedMessage id="SALES_TAX" />:
      </TransactionFooterCell>
      <TransactionFooterCell>
        <FormattedMessage id="TOTAL" />:
      </TransactionFooterCell>
    </TransactionFooterCol>
  );
};

import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ReleaseItem } from "@/generated/graphql";
import { Check } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";

const GreenCheckIcon = tw(Check)`text-green-500 w-5 h-5`;

type Props = {
  item: Pick<ReleaseItem, "isIncluded" | "taxable">;
};

export const TaxableReleaseItem: FC<Props> = ({ item }) => (
  <If isTrue={item.isIncluded}>
    <NotNullableRenderer value={item.taxable}>
      <GreenCheckIcon />
    </NotNullableRenderer>
  </If>
);

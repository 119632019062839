import { useMemo } from "react";
import { Permission } from "./OrgRolesWrapper";
import { usePermissions } from "./hasPermissions";

export const usePrefillPricesFlag = () => {
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const prefillPrices = useMemo(() => {
    return !hasPermissions;
  }, [hasPermissions]);

  return prefillPrices;
};

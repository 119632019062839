import { useDialog } from "@/common/components/dialog/DialogProvider";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { VendorPickerCustomRender } from "@/common/components/supplier-picker/components/renderers/VendorPickerCustomRender";
import { vendorLabelFormatter } from "@/common/components/supplier-picker/utils/vendorLabelFormatter";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { RfqStatus } from "@/generated/graphql";
import { FC, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useRfq } from "../../rfq/hooks/useRfq";
import { RfqFormVendorsContacts } from "./RfqFormVendorsContacts";
import { UpdateRfqVendorsFormType } from "./UpdateRfqVendorsFormType";

interface IRfqConfigVendorsDrawer {
  togglePanel: (arg0: boolean) => void;
  panelTitle: string;
  filterVendors?: Array<string>;
  saveLabel?: string;
}

const RfqConfigVendorsDrawer: FC<IRfqConfigVendorsDrawer> = ({
  togglePanel,
  filterVendors = [],
  panelTitle,
  saveLabel,
}) => {
  const { vendors, getVendorCode, shouldShowVendorCode } = useVendors();
  const { rfq, updateRfq } = useRfq();
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { handleSubmit, setValue, watch, ...formProps } =
    useForm<UpdateRfqVendorsFormType>({
      defaultValues: {
        vendorIds: [],
      },
      mode: "onSubmit",
      reValidateMode: "onChange",
    });

  const vendorIds = watch("vendorIds");

  useEffect(() => {
    if (rfq) {
      setValue(
        "vendorIds",
        rfq.targets.map((target) => ({
          sellerOrgLocationId: target.sellerOrgLocation.id,
          contactIDs: target.contacts.map((contact) => contact.id),
        })),
      );
    }
  }, [rfq, setValue]);

  const onSubmit = useCallback(
    async (input: UpdateRfqVendorsFormType) => {
      if (!rfq) {
        return;
      }

      if (vendorIds?.some((vendorId) => !vendorId.contactIDs?.length)) {
        return;
      }

      const saveChanges = async () => {
        const result = await updateRfq({
          rfqId: rfq.id,
          targets: input.vendorIds?.map((vendorId) => ({
            ...vendorId,
            contactIDs: vendorId.contactIDs ?? [],
          })),
        });

        if (result) {
          togglePanel(false);
        }
      };

      const vendorsRemoved: boolean = rfq.targets.some(
        (target) =>
          !input.vendorIds?.some(
            (vendorId) =>
              vendorId.sellerOrgLocationId === target.sellerOrgLocation.id,
          ),
      );

      if (rfq.status === RfqStatus.Active && vendorsRemoved) {
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "PROCEED" }),
          includeWarningIcon: true,
          title: intl.$t({ id: "REMOVE_VENDOR_S_CONFIRMATION" }),
          handleConfirm: saveChanges,
        });
      } else {
        await saveChanges();
      }
    },
    [intl, openDialog, rfq, togglePanel, updateRfq, vendorIds],
  );

  const onVendorsChanged = useCallback(
    (values: string[] | null) => {
      setValue(
        "vendorIds",
        values?.map((value) => ({
          sellerOrgLocationId: value,
          contactIDs:
            vendorIds?.find(
              (vendorId) => vendorId.sellerOrgLocationId === value,
            )?.contactIDs ?? [],
        })) ?? [],
      );
    },
    [setValue, vendorIds],
  );

  if (!rfq) {
    return null;
  }

  return (
    <OverlayPanel
      title={panelTitle}
      onCancel={() => togglePanel(false)}
      onSave={handleSubmit(onSubmit)}
      saveLabel={saveLabel || undefined}
      disableSave={(vendorIds ?? []).length === 0}
      className="flex flex-col gap-4"
    >
      <FormProvider
        {...formProps}
        handleSubmit={handleSubmit}
        setValue={setValue}
        watch={watch}
      >
        <FormattedMessage id="RFQ_SELECT_VENDOR_S" />
        <Multiselect
          placeholder={intl.$t({
            id: "SEARCH_VENDOR",
          })}
          options={
            filterVendors.length > 0 && vendors
              ? vendors.filter(({ id }) => filterVendors.indexOf(id) < 0)
              : vendors || []
          }
          customRender={(item) =>
            VendorPickerCustomRender(item, {
              filter: (c) => c.receivesQuoteNotifications,
              shouldShowVendorCode,
              shouldShowContacts: false,
            })
          }
          values={
            vendorIds?.map((vendorId) => vendorId.sellerOrgLocationId) ?? []
          }
          onMultipleChange={onVendorsChanged}
          getLabel={(o) =>
            vendorLabelFormatter(o.sellerOrgLocation, [], {
              vendorCode: getVendorCode(o),
            })
          }
          testId="rfq-form-vendor-selector"
          getValue={(option) => option.sellerOrgLocation.id}
          disableCloseOnSelect
          includeCheckbox
          required
        />
        <RfqFormVendorsContacts />
      </FormProvider>
    </OverlayPanel>
  );
};

export default RfqConfigVendorsDrawer;

import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { Base, SmdFixedContainer } from "@/common/layout/ResponsiveClasses";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import {
  BUDGET_VIEW_TYPE,
  useSpendingReport,
} from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { CurrencyDisplay } from "../../spending-report-list/components/CurrencyDisplay";
import { ReportItemDisplay } from "../../spending-report-list/components/ReportItemDisplay";
import { ValueWithTax } from "../../spending-report-list/components/cost-codes-totals/CostCodeTotal.Styles";
import { MaterialsVendorsToggle } from "../materials-vendors-toggle/MaterialsVendorsToggle";

const FooterContent = tw.div`
  justify-between flex md:grid grid-flow-col items-center lg:flex px-2 gap-1 md:-mx-14 lg:mx-0
`;
const SwitchContainer = tw(Base)`
  basis-full lg:basis-2/12 lg:w-2/12
  2xl:basis-2/12 2xl:w-2/12 flex-1
`;
const AmountContainer = tw.div`text-center w-full`;
const ValueWithTaxStyled = tw(ValueWithTax)`text-black`;
const Container = tw(SmdFixedContainer)`border-l border-gray-600 border-dashed`;

export const SpendingReportFooter = () => {
  const { zonesReport, materialsReport, vendorsReport, viewType } =
    useSpendingReport();
  const { formatCurrency } = useFormatNumberToCurrency();

  const currentReport = useMemo(() => {
    switch (viewType) {
      case BUDGET_VIEW_TYPE.ZONES:
        return zonesReport;
      case BUDGET_VIEW_TYPE.MATERIALS:
        return materialsReport;
      case BUDGET_VIEW_TYPE.VENDORS:
        return vendorsReport;
    }
  }, [zonesReport, materialsReport, vendorsReport, viewType]);

  const values = useMemo(() => {
    switch (viewType) {
      case BUDGET_VIEW_TYPE.ZONES:
        return {
          items: [
            undefined,
            zonesReport?.ordered,
            zonesReport?.receivedTotal,
            zonesReport?.invoicedTotal,
            zonesReport?.paidTotal,
            zonesReport?.overUnder,
          ],
          additionalItems: [
            undefined,
            zonesReport?.orderedNet
              ? new DecimalSafe(zonesReport?.ordered ?? 0)
                  .minus(zonesReport?.orderedNet ?? 0)
                  .toString()
              : undefined,
            new DecimalSafe(zonesReport?.paidTotal ?? 0)
              .minus(zonesReport?.paid ?? 0)
              .toString(),
            new DecimalSafe(zonesReport?.invoicedTotal ?? 0)
              .minus(zonesReport?.invoiced ?? 0)
              .toString(),
            new DecimalSafe(zonesReport?.paidTotal ?? 0)
              .minus(zonesReport?.paid ?? 0)
              .toString(),
            zonesReport ? "WITH_TAX_AND_FEES" : undefined,
          ],
        };
      case BUDGET_VIEW_TYPE.MATERIALS:
        return {
          items: [
            undefined,
            materialsReport?.ordered,
            materialsReport?.receivedTotal,
            materialsReport?.invoicedTotal,
            materialsReport?.paidTotal,
            materialsReport?.overUnder,
          ],
          additionalItems: [
            undefined,
            materialsReport?.orderedNet
              ? new DecimalSafe(materialsReport?.ordered ?? 0)
                  .minus(materialsReport?.orderedNet ?? 0)
                  .toString()
              : undefined,
            new DecimalSafe(materialsReport?.receivedTotal ?? 0)
              .minus(materialsReport?.received ?? 0)
              .toString(),
            new DecimalSafe(materialsReport?.invoicedTotal ?? 0)
              .minus(materialsReport?.invoiced ?? 0)
              .toString(),
            new DecimalSafe(materialsReport?.paidTotal ?? 0)
              .minus(materialsReport?.paid ?? 0)
              .toString(),
            materialsReport ? "WITH_TAX_AND_FEES" : undefined,
          ],
        };
      case BUDGET_VIEW_TYPE.VENDORS:
        return {
          items: [
            undefined,
            vendorsReport?.ordered,
            vendorsReport?.receivedTotal,
            vendorsReport?.invoicedTotal,
            vendorsReport?.paidTotal,
            vendorsReport?.overUnder,
          ],
          additionalItems: [
            undefined,
            vendorsReport?.orderedNet
              ? new DecimalSafe(vendorsReport?.ordered ?? 0)
                  .minus(vendorsReport?.orderedNet ?? 0)
                  .toString()
              : undefined,
            new DecimalSafe(vendorsReport?.receivedTotal ?? 0)
              .minus(vendorsReport?.received ?? 0)
              .toString(),
            new DecimalSafe(vendorsReport?.invoicedTotal ?? 0)
              .minus(vendorsReport?.invoiced ?? 0)
              .toString(),
            new DecimalSafe(vendorsReport?.paidTotal ?? 0)
              .minus(vendorsReport?.paid ?? 0)
              .toString(),
            vendorsReport ? "WITH_TAX_AND_FEES" : undefined,
          ],
        };
    }
  }, [zonesReport, materialsReport, vendorsReport, viewType]);

  return (
    <FloatingFooter>
      <FooterContent>
        <SwitchContainer>
          <MaterialsVendorsToggle />
        </SwitchContainer>
        <SmdFixedContainer />
        <SmdFixedContainer>
          <AmountContainer>
            <ReportItemDisplay
              item={currentReport}
              overall
              className="text-sm"
            />
          </AmountContainer>
        </SmdFixedContainer>
        {values.items?.map((value, index) => (
          <Container key={index}>
            <AmountContainer>
              <CurrencyDisplay
                value={value}
                className="text-sm"
                showColor={index === values.items.length - 1}
                rightAlign={index === values.items.length - 1}
              />
              <If
                isTrue={
                  values.additionalItems[index] &&
                  values.additionalItems[index] !== "0"
                }
              >
                {values.additionalItems[index] === "WITH_TAX_AND_FEES" ? (
                  <ValueWithTaxStyled className="text-right">
                    <FormattedMessage id={values.additionalItems[index]} />
                  </ValueWithTaxStyled>
                ) : (
                  <FormattedMessage
                    id="VALUE_TAX_AND_FEES"
                    tagName={ValueWithTaxStyled}
                    values={{
                      value: formatCurrency(values.additionalItems[index]),
                    }}
                  />
                )}
              </If>
            </AmountContainer>
          </Container>
        ))}
      </FooterContent>
    </FloatingFooter>
  );
};

import { DECIMAL_MAX_FRACTION_DIGITS } from "@/common/const";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { isValueEmpty } from "../utils/isValueEmpty";

export const useQuantityRenderer = () => {
  const intl = useIntl();
  const baseRenderer = useBaseRenderer();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      if (isValueEmpty(value)) {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
      } else {
        const formattedValue = intl.formatNumber(Number(value), {
          maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
        });
        baseRenderer(
          instance,
          td,
          row,
          col,
          prop,
          formattedValue,
          cellProperties,
        );
      }
    },
    [intl, baseRenderer],
  );

  return renderer;
};

import { If } from "@/common/components/if/If";
import useIsEllipsisVisibleHook from "@/contractor/pages/home/rfq-quotes/components/table-view/summary/useIsEllipsisVisibleHook";
import React, { ReactNode, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  w-full grid grid-flow-col gap-1
`;

type ContentProps = {
  $truncated: boolean;
};

const Content = tw.div<ContentProps>`
  text-2xs w-full
  ${({ $truncated }) => ($truncated ? "truncate" : "whitespace-normal")}
`;

const ContentChildren = tw.div`
  grid my-1
`;

const ContentText = tw.span`text-gray-600`;

const LinkLikeStyled = tw.span`
  text-2xs text-blue-500 cursor-pointer hover:text-blue-300
`;

type TextWithShowMoreProps = {
  text?: string | null;
  children?: ReactNode;
  setIsExpanded?: (value: boolean) => void;
  classes?: {
    content?: string;
    text?: string;
  };
};

const TextWithShowMore: React.FC<TextWithShowMoreProps> = ({
  text,
  children,
  setIsExpanded,
  classes,
}) => {
  const [showFullText, setShowFullText] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { isEllipsisVisible } = useIsEllipsisVisibleHook(contentRef);

  const moreIsAvailable = useMemo(() => {
    return isEllipsisVisible || children;
  }, [isEllipsisVisible, children]);

  const toggleShowFullText = () => {
    if (setIsExpanded) {
      setIsExpanded(!showFullText);
    }
    setShowFullText((prev) => !prev);
  };

  return (
    <Container>
      <Content
        className={classes?.content}
        ref={contentRef}
        $truncated={!showFullText}
      >
        <ContentText className={classes?.text}>
          {text}{" "}
          <If isTrue={moreIsAvailable && showFullText}>
            <LinkLikeStyled onClick={toggleShowFullText}>
              <FormattedMessage id="SHOW_LESS" />
            </LinkLikeStyled>
          </If>
        </ContentText>
        <If isTrue={moreIsAvailable && showFullText}>
          <ContentChildren>{children}</ContentChildren>
        </If>
      </Content>
      {moreIsAvailable && !showFullText && (
        <LinkLikeStyled onClick={toggleShowFullText}>
          <FormattedMessage id="SHOW_MORE" />
        </LinkLikeStyled>
      )}
    </Container>
  );
};

export default TextWithShowMore;

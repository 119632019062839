import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { ReceiptsHeader } from "./components/list/ReceiptsHeader";
import { ReceiptsList } from "./components/list/ReceiptsList";
import { ReceiptSideActions } from "./components/side-actions/ReceiptSideActions";
import { ReceiptsSelectAll } from "./components/side-actions/ReceiptsSelectAll";
import { ReceiptCreationProvider } from "./providers/ReceiptCreationProvider";
import { ReceiptsProvider, useReceipts } from "./providers/ReceiptsProvider";
import { ReceiptsSequenceProvider } from "./providers/ReceiptsSequenceProvider";

const ReceiptsProviderWithProvider = () => {
  const { receipts, loading, totalCount, isFiltered } = useReceipts();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "RECEIPTS" })}</title>
      </Helmet>
      <ReceiptsHeader />
      <ReceiptsList
        receipts={receipts}
        isFiltered={isFiltered}
        loading={loading}
        totalCount={totalCount}
      />
      <ReceiptSideActions />
      <ReceiptsSelectAll />
    </>
  );
};
export const Receipts = () => (
  <ReceiptCreationProvider>
    <ReceiptsSequenceProvider>
      <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
        <ReceiptsProvider>
          <ReceiptsSequenceProvider>
            <ReceiptsProviderWithProvider />
          </ReceiptsSequenceProvider>
        </ReceiptsProvider>
      </PaginationProvider>
    </ReceiptsSequenceProvider>
  </ReceiptCreationProvider>
);

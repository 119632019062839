import { ListSelectAll } from "@/common/components/list-select-all/ListSelectAll";
import { useIntl } from "react-intl";
import { useInventoryItems } from "../../hooks/useInventoryItems";
import { useInventoryItemSequence } from "../current-stock-list/components/hooks/useInventoryItemSequence";
export const CurrentStockSelectAll = () => {
  const intl = useIntl();
  const { totalCount } = useInventoryItems();
  const { selectedEntities, setSelectedEntities } = useInventoryItemSequence();

  return (
    <ListSelectAll
      allItemsSelected={false}
      totalCount={totalCount}
      selectedCount={selectedEntities.length}
      itemNamePlural={intl.$t({ id: "ITEMS" })}
      itemNameSingular={intl.$t({ id: "ITEM" })}
      clearAll={() => setSelectedEntities([])}
      hideSelectAll
    />
  );
};

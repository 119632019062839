import { If } from "@/common/components/if/If";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { ColumnMapperProvider } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useMaterials } from "../materials/hooks/useMaterials";
import { useVendorPrices } from "./hooks/useVendorPrices";
import { MaterialPriceList } from "./material-price-list/MaterialPriceList";
import { useMaterialPricesConfiguration } from "./material-price-list/MaterialPriceList.configuration";
import { MaterialPricesFooter } from "./material-price-list/MaterialPricesFooter";
import { MaterialPricesHeader } from "./material-price-list/MaterialPricesHeader";
import {
  MaterialPricesProvider,
  useMaterialPrices,
} from "./providers/MaterialPricesProvider";
import {
  SyncPricesProvider,
  useSyncPrices,
} from "./providers/SyncPricesProvider";
import { ExpandedMaterialPrice } from "./stores/useVendorPricesStore";

const MaterialPricesWithProvider = () => {
  const intl = useIntl();
  const { materials: allMaterials } = useMaterials();
  const { materials } = useMaterialPrices();
  const { loading, totalCount, isFiltered } = useMaterialPrices();
  const viewState = useTableViewStore((state) => state.viewState);
  const { spreadsheetConfig } = useMaterialPricesConfiguration();
  const { saving } = useSyncPrices();
  const { prices } = useVendorPrices();
  const [allPrices, setAllPrices] = useState([] as ExpandedMaterialPrice[]);

  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.normal);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  useEffect(() => {
    const newPrices = [] as ExpandedMaterialPrice[];
    allMaterials.forEach((material) => {
      const matPrices = prices.filter((p) => p.orgMaterialId === material.id);

      if (matPrices.length > 0) {
        newPrices.push(...matPrices);
      } else {
        newPrices.push({
          orgMaterialId: material.id,
          id: "",
          material,
          uom: {
            id: material.defaultEstimateUom?.id,
          },
          manufacturer: { ...material.manufacturer },
          vendorName: "",
        } as ExpandedMaterialPrice);
      }
    });
    if (!isEqual(allPrices, newPrices)) {
      setAllPrices(newPrices);
    }
  }, [allPrices, allMaterials, prices]);

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "MATERIAL_PRICES" })}</title>
      </Helmet>
      <MaterialPricesHeader />
      <If isTrue={viewState === TableViewState.normal}>
        <MaterialPriceList
          materials={materials}
          loading={loading}
          totalCount={totalCount}
          isFiltered={isFiltered}
        />
      </If>
      <If isTrue={viewState === TableViewState.spreadsheet}>
        <SpreadSheetTable
          items={allPrices}
          columns={spreadsheetConfig}
          saving={saving}
          height="calc(100vh - 320px)"
        />
      </If>
      <MaterialPricesFooter />
    </>
  );
};
export const MaterialPrices = () => {
  const { spreadsheetConfig } = useMaterialPricesConfiguration();

  return (
    <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
      <MaterialPricesProvider>
        <ColumnMapperProvider config={spreadsheetConfig}>
          <SyncPricesProvider>
            <MaterialPricesWithProvider />
          </SyncPricesProvider>
        </ColumnMapperProvider>
      </MaterialPricesProvider>
    </PaginationProvider>
  );
};

import {
  DistributorInvoiceFieldsFragment,
  InvoiceFieldsFragment,
  ReceiptFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";

export const useInvoiceDocuments = (
  invoice:
    | InvoiceFieldsFragment
    | null
    | ReceiptFieldsFragment
    | DistributorInvoiceFieldsFragment,
) => {
  const originalDocument = useMemo(
    () =>
      (invoice?.documents ?? []).length > 0 ? invoice?.documents[0] : null,
    [invoice?.documents],
  );

  const newDocument = useMemo(
    () =>
      (invoice?.documents ?? []).length > 1
        ? invoice?.documents[invoice?.documents.length - 1]
        : null,
    [invoice?.documents],
  );

  const documents = useMemo(() => {
    const docs = [];
    if (newDocument) {
      docs.push(newDocument);
    }
    if (originalDocument) {
      docs.push(originalDocument);
    }
    return docs;
  }, [newDocument, originalDocument]);

  return { documents, originalDocument, newDocument };
};

import { ALL_WAREHOUSES_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { UpdateOrgInput } from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

export const useOrgDefaultFulfillmentLocation = () => {
  const { warehouses, settings } = useStartupDataStore();
  const { setValue } = useFormContext<UpdateOrgInput>();

  const [selectedFulfillmentLocationId, setSelectedFulfillmentLocationId] =
    useState<string | null | undefined>(undefined);

  useEffect(() => {
    if (settings?.releases?.defaultWarehouse?.id) {
      setSelectedFulfillmentLocationId(
        settings?.releases?.defaultWarehouse?.id,
      );
    }
  }, [settings?.releases?.defaultWarehouse?.id]);

  const setDefaultFulfillmentLocationId = useCallback(
    (value: string | null) => {
      setSelectedFulfillmentLocationId(value);
      setValue(
        "settings.releases.defaultWarehouseId",
        value !== ALL_WAREHOUSES_ID ? value : null,
      );
      setValue(
        "settings.releases.clearDefaultWarehouse",
        value === ALL_WAREHOUSES_ID,
      );
    },
    [setValue],
  );

  const selectedFulfillmentLocation = useMemo(
    () =>
      warehouses.find(
        (warehouse) => warehouse.id === selectedFulfillmentLocationId,
      ),
    [selectedFulfillmentLocationId, warehouses],
  );

  return {
    selectedFulfillmentLocation,
    setDefaultFulfillmentLocationId,
  };
};

import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { COLUMN_TYPE } from "../../enums/columnType";
import { getPhysicalColumnIndex } from "../../utils/getPhysicalColumnIndex";
import { rowIsEmpty } from "../../utils/rowIsEmpty";
import { RendererFunctionType } from "../base-renderer/types/RendererFunctionType";
import { useBaseRenderer } from "../base-renderer/useBaseRenderer";
import { useRenderHelpers } from "../helpers/useRenderHelpers";
import { applyTooltip } from "../utils/applyTooltip";
import { isValueEmpty } from "../utils/isValueEmpty";
import { removeTooltip } from "../utils/removeTooltip";

export const useBudgetRenderer = () => {
  const intl = useIntl();
  const { formatPrice } = useRenderHelpers();
  const baseRenderer = useBaseRenderer();
  const { allowance } = useProjectStore(
    useShallow((state) => ({
      allowance: state.allowance,
    })),
  );

  const { costCodes } = useProjectCostCodes();

  const getCostCodeId = useCallback(
    (formattedCodeName: string) => {
      const costCode = costCodes.find(
        (code) => formattedCodeName === code.formatted,
      )?.id;
      return costCode;
    },
    [costCodes],
  );

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      if (isValueEmpty(value)) {
        baseRenderer(instance, td, row, col, prop, value, cellProperties);
        return;
      }

      const rowData = instance?.getDataAtRow(row);
      const formattedValue = formatPrice(rowData, instance, value);
      baseRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      const zoneId = String(prop).split("_")[1];
      const costCodeId = getCostCodeId(
        rowData[getPhysicalColumnIndex(instance, COLUMN_TYPE.CostCode)],
      );

      const allowanceCostCode = allowance?.costCodes.find(
        (a) =>
          a.costCode.id === costCodeId &&
          (a.zone?.id === zoneId ||
            (!a.zone?.id && (zoneId === UNSPECIFIED_ZONE_ID || !zoneId))),
      );

      if (
        !rowIsEmpty(rowData) &&
        allowanceCostCode &&
        allowanceCostCode.costTypes
      ) {
        const iconCont = document.createElement("span");
        iconCont.className = `absolute inline-grid top-0 left-2 place-items-center h-full 2xl:left-2 pointer-events-none`;
        const iconElement = document.createElement("i");
        iconElement.className = `fa-solid text-[15px] fa-circle-dollar-to-slot text-blue-500 hover:text-blue-300`;
        iconCont.appendChild(iconElement);
        td.appendChild(iconCont);
        applyTooltip(
          td,
          intl.$t(
            { id: "ALLOWED_COST_TYPES" },
            {
              costTypes: allowanceCostCode.costTypes
                .map((a) => a.description)
                .join(", "),
            },
          ),
        );
      } else {
        removeTooltip(td);
      }
    },
    [formatPrice, allowance, intl, getCostCodeId, baseRenderer],
  );

  return renderer;
};

import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/hooks/useWarehouses";
import { ProjectListOptionFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { UNDEFINED_WAREHOUSE_ID } from "../../utils/getSelectedItemIdParts";
import { useInventoryItemSequence } from "../current-stock-list/components/hooks/useInventoryItemSequence";

export type SelectProjectDialogFormValues = {
  projectId: string;
  warehouseId: string;
};

type SelectProjectDialogForm = UseFormReturn<
  SelectProjectDialogFormValues,
  unknown
>;

const selectProjectDialogFormDefaultValues: SelectProjectDialogFormValues = {
  projectId: "",
  warehouseId: "",
};

type SelectProjectDialogFormProps = {
  restock?: boolean;
  children: React.ReactNode;
};

export const SelectProjectDialogForm: FC<SelectProjectDialogFormProps> = ({
  restock = false,
  children,
}) => {
  const { warehouses } = useWarehouses();
  const { sequenceIds } = useInventoryItemSequence();

  const filterProjects = useCallback(
    (project: ProjectListOptionFieldsFragment) => {
      return restock === project.preferredInventoryInflows;
    },
    [restock],
  );
  const { projects } = useProjectListOptions({ filterResults: filterProjects });

  const selectProjectDialogForm: SelectProjectDialogForm =
    useForm<SelectProjectDialogFormValues>({
      defaultValues: {
        ...selectProjectDialogFormDefaultValues,
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  const { setValue } = selectProjectDialogForm;

  useEffect(() => {
    const nonUndefinedWarehouseIds = sequenceIds.filter(
      (id) => id !== UNDEFINED_WAREHOUSE_ID,
    );
    if (nonUndefinedWarehouseIds.length > 0) {
      setValue("warehouseId", nonUndefinedWarehouseIds[0]);
    } else if (warehouses.length === 1) {
      setValue("warehouseId", warehouses[0].id);
    } else {
      setValue("warehouseId", "");
    }
  }, [sequenceIds, warehouses, setValue]);

  useEffect(() => {
    if (projects.length === 1) {
      setValue("projectId", projects[0].id);
    }
  }, [projects, setValue]);

  return <FormProvider {...selectProjectDialogForm}>{children}</FormProvider>;
};

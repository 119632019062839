import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { Tabs } from "@/common/components/tabs/Tabs";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { routes } from "@/config/routes";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const AdminOrderTabNavigation = () => {
  const intl = useIntl();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const tabs = useMemo(
    () => [
      {
        label: intl.$t({ id: "ORDER_TYPES" }),
        path: routes.adminOrders,
      },
      {
        label: intl.$t({ id: "ORDER_APPROVAL" }),
        path: routes.orderApproval,
      },
      {
        label: intl.$t({ id: "INVOICE_APPROVAL" }),
        path: routes.invoiceApproval,
      },
      {
        label: intl.$t({ id: "COMPLIANCE_GROUPS" }),
        path: routes.complianceGroups,
        excluded: !hasFeatureInConnectedSourceSystem(
          IntegrationFeature.ComplianceGroups,
        ),
      },
    ],
    [intl, hasFeatureInConnectedSourceSystem],
  );

  return <Tabs tabs={tabs} type="secondary" className="pt-4" />;
};

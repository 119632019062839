import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { getUTCDate } from "@/common/components/picker/components/utils/getUTCDate";
import { useReleaseAdditionalChargesInput } from "@/common/components/release-additional-charges-and-taxes/hooks/useReleaseAdditionalChargesInput";
import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { useSupplierOptions } from "@/common/components/supplier-picker/useSupplierOptions";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { PROJECT_ADDRESS_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { usePredictedTax } from "@/common/hooks/usePredictedTax";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { routes } from "@/config/routes";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useNoteDocumentItems } from "@/contractor/pages/home/common/note-document/hooks/useNoteDocumentItems";
import { useNoteDocument } from "@/contractor/pages/home/common/note-document/providers/NoteDocumentProvider";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useFormPriceCalculation } from "@/contractor/pages/home/release/hooks/useFormPriceCalculation";
import { usePrefillDefaultTaxable } from "@/contractor/pages/home/release/hooks/usePrefillDefaultTaxable";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseStore } from "@/contractor/pages/home/release/store/useReleaseStore";
import { ServiceType, UpdateContractorReleaseInput } from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { generatePath, useNavigate, useSearchParams } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { useOrderFromNote } from "../../../../providers/OrderFromNoteProvider";
import { CreateOrderFromNoteFormValues } from "./CreateOrderFromNoteForm";

export const useCreateOrderFromNoteDetails = () => {
  const { noteDocument, loading } = useNoteDocument();
  const { calcTableTotal, getPreferredCostCode } = useTableHelpers();
  const findMaterialByName = useFindMaterialByName();
  const { predictedTaxRate, setTaxInput } = usePredictedTax();
  const { spreadsheetData } = useColumnMapper();
  const { release, loading: loadingRelease } = useRelease();
  const { setGlobalVendorId } = useVendorPrices();
  const { calcExtPrice } = usePriceCalculation();
  const { watch, setValue, getValues } =
    useFormContext<CreateOrderFromNoteFormValues>();
  const [searchParams] = useSearchParams();
  const { defaultOrderType } = useOrderTypeOptions();
  const { findOrderTypeByLocationId } = useVendors();
  const { total } = useFormPriceCalculation();
  const intl = useIntl();
  const { setHasChanges } = useReleaseStore(
    useShallow((state) => ({
      setHasChanges: state.setHasChanges,
    })),
  );
  const navigate = useNavigate();
  const { wasDocumentReplaced } = useOrderFromNote();
  const { openDialog } = useDialog();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { noteDocumentItems } = useNoteDocumentItems(noteDocument);
  const { releaseInput } = useReleaseAdditionalChargesInput();
  const { taxable } = usePrefillDefaultTaxable();
  const { findVendorById } = useSupplierOptions();

  const projectId = watch("projectId");
  const vendorId = watch("vendorId");
  const willCall = watch("willCall");
  const warehouseId = watch("fulfillmentLocationId");
  const customTaxAmount = watch("customTaxAmount");
  const netAmount = watch("netAmount");

  useSetCurrentProjectId(projectId);

  useEffect(() => {
    if (vendorId && findVendorById(vendorId)) {
      setGlobalVendorId(vendorId);
    }
  }, [setGlobalVendorId, findVendorById, vendorId]);

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      setHasChanges(true);
      const newTotal = `${calcTableTotal(data)}`;
      if (newTotal !== netAmount) {
        setValue("netAmount", newTotal);
      }
      const taxableNetAmount = `${calcTableTotal(data, { taxable: true })}`;
      if (taxableNetAmount !== getValues("taxableNetAmount")) {
        setValue("taxableNetAmount", taxableNetAmount);
      }
    },
    [setHasChanges, calcTableTotal, netAmount, getValues, setValue],
  );

  useEffect(() => {
    setValue("instructions", {
      text: release?.instructions?.text ?? "",
      assetUrls: release?.instructions?.assets.map((asset) => asset.url) ?? [],
    });
    setValue("description", release?.description ?? "");
    setValue(
      "willCall",
      release?.id
        ? !release?.includeServices.includes(ServiceType.Delivery)
        : false,
    );
    setValue(
      "vendorStocking",
      release?.id
        ? release?.includeServices.includes(ServiceType.Stocking)
        : false,
    );
  }, [
    release?.id,
    release?.includeServices,
    release?.instructions?.assets,
    release?.instructions?.text,
    release?.description,
    setValue,
  ]);

  useEffect(() => {
    setValue("businessLocationId", noteDocument?.orgLocation?.id ?? "");
    setValue(
      "projectId",
      getValues("projectId") ||
        release?.project?.id ||
        searchParams.get("projectId") ||
        "",
    );
    setValue(
      "vendorId",
      vendorId ||
        release?.sellerOrgLocation?.id ||
        release?.sourceWarehouse?.id ||
        "",
    );
    setValue("paymentTerm", release?.paymentTerm || undefined);
    setValue(
      "customTaxAmount",
      getValues("customTaxAmount") || release?.customTaxAmount || undefined,
    );
    setValue("poNumber", (getValues("poNumber") || release?.poNumber) ?? "");
    setValue(
      "vendorContactIds",
      getValues("vendorContactIds") ||
        (release?.vendorContacts?.length === 1
          ? release?.vendorContacts?.map((vc) => vc.id)
          : []) ||
        [],
    );
    if (release?.time) {
      setValue("orderDate", defaultReleaseDate(getUTCDate(release.time)));
    }
    setValue("timeTBD", getValues("timeTBD") ?? release?.timeTBD ?? null);

    if (release?.additionalCharges) {
      setValue("additionalCharges", release.additionalCharges);
    }
    setValue("taxVariance", getValues("taxVariance") || release?.taxVariance);
    setValue("taxRate", getValues("taxRate") || release?.taxRate || undefined);
    updateSubtotal(spreadsheetData);
  }, [
    updateSubtotal,
    noteDocument,
    setValue,
    searchParams,
    release,
    spreadsheetData,
    vendorId,
    loadingRelease,
    loading,
    getValues,
  ]);

  const sellerOrgLocationId = useMemo(() => {
    if (findVendorById(vendorId)) {
      return vendorId;
    }
    return null;
  }, [findVendorById, vendorId]);

  useEffect(() => {
    if (defaultOrderType) {
      setValue("orderTypeId", defaultOrderType.id);
    }
  }, [defaultOrderType, setValue]);

  useEffect(() => {
    if (sellerOrgLocationId) {
      const orderTypeId = findOrderTypeByLocationId(sellerOrgLocationId);
      if (orderTypeId) {
        setValue("orderTypeId", orderTypeId);
      }
    }
  }, [findOrderTypeByLocationId, sellerOrgLocationId, setValue]);

  useEffect(() => {
    if (sellerOrgLocationId) {
      setTaxInput({
        projectId,
        sellerOrgLocationId,
        willCall,
        warehouseId: willCall
          ? undefined
          : projectId !== warehouseId && warehouseId !== PROJECT_ADDRESS_ID
            ? warehouseId || undefined
            : undefined,
      });
    }
  }, [projectId, sellerOrgLocationId, willCall, warehouseId, setTaxInput]);

  useEffect(() => {
    const taxRate = getValues("taxRate");
    if (
      predictedTaxRate &&
      (taxRate === undefined || taxRate === "") &&
      !release
    ) {
      openDialog({
        cancelButtonText: intl.$t({ id: "NO" }),
        confirmButtonText: intl.$t({ id: "YES" }),
        includeWarningIcon: true,
        title: intl.$t({ id: "OVERWRITE_EXISTING_SALES_TAX_QUESTION" }),
        text: intl.$t(
          {
            id: "OVERWRITE_EXISTING_SALES_TAX",
          },
          {
            value: formatCurrency(
              new DecimalSafe(predictedTaxRate).mul(netAmount),
              {
                minimumFractionDigits: 2,
              },
            ),
          },
        ),
        handleConfirm: () => {
          setValue("taxRate", predictedTaxRate);
        },
        handleCancel: () => {
          setValue("taxRate", "0");
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatCurrency, intl, predictedTaxRate, setValue, release]);

  const tableItems = useMemo(() => {
    if (release?.items && release.items.length && !wasDocumentReplaced) {
      return release.items
        .toSorted((a, b) => (a.position || 0) - (b.position || 0))
        .map((item) => {
          const matchingOrgMaterial = findMaterialByName(
            item.projectItem?.material.material.name ?? item.name ?? "",
          );
          const selectedCostCode = getPreferredCostCode(
            matchingOrgMaterial?.costCode,
          );
          return {
            id: item.id,
            name: item.name ?? "",
            material: matchingOrgMaterial,
            costCode: selectedCostCode?.code,
            notes: item.instructions?.text ?? "",
            UOM:
              item?.uom?.pluralDescription ??
              item?.uom?.mnemonic ??
              item.projectItem?.estimateUom.mnemonic,
            unitPrice: item.unitPrice ?? undefined,
            quantityDecimal: item.quantityDecimal ?? "",
            zone: item?.zone ?? "",
            extPrice: calcExtPrice(item.quantityDecimal, item.unitPrice),
            taxable,
          };
        });
    }

    return noteDocumentItems;
  }, [
    release?.items,
    wasDocumentReplaced,
    noteDocumentItems,
    findMaterialByName,
    calcExtPrice,
    taxable,
    getPreferredCostCode,
  ]);

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (values.additionalCharges) {
        setValue("additionalCharges", values.additionalCharges);
      }

      if (values.clearCustomTaxAmount !== undefined) {
        setValue("clearCustomTaxAmount", !!values.clearCustomTaxAmount);
      }
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue("customTaxAmount", values.customTaxAmount || "");
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }
      if (values.taxVariance || values.clearTaxVariance) {
        setValue(
          "taxVariance",
          values.clearTaxVariance ? undefined : values.taxVariance,
        );
      }
      if (values.timeTBD !== undefined && values.timeTBD !== null) {
        setValue("timeTBD", values.timeTBD);
      }

      return true;
    },
    [customTaxAmount, setValue],
  );

  const handleClick = useCallback(() => {
    if (release) {
      navigate(
        generatePath(routes.specifyDeliveryDetails, { deliveryId: release.id }),
      );
    }
  }, [navigate, release]);

  return {
    releaseInput,
    handleClick,
    handleUpdateRelease,
    tableItems,
    updateSubtotal,
    total,
  };
};

import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import { StateCreator } from "zustand";
import { TemplateVariablesEnum } from "../../enums/TemplateVariables.enum";
import { Template } from "../../types/Template";
import { TemplateStringsState } from "../useTemplateStringsStore";
import { getTemplatePreview } from "../utils/getTemplatePreview";

export interface DefaultTemplatesSlice {
  templates: Template[];
  setTemplates: (templates: Template[]) => Promise<void>;
  setTemplate: (template: Template) => Promise<void>;
  defaults: Record<TemplateVariablesEnum, string>;
  updateDefaults: (defaults: Record<TemplateVariablesEnum, string>) => void;
}

export const createDefaultTemplatesSlice: StateCreator<
  TemplateStringsState,
  [],
  [],
  DefaultTemplatesSlice
> = (set, get) => ({
  templates: [],
  defaults: {} as Record<TemplateVariablesEnum, string>,
  updateDefaults: async (defaults) => {
    set({ defaults });
    for (const template of get().templates) {
      await get().setTemplate(template);
    }
  },
  setTemplates: async (templates) => {
    set({
      templates: templates.map((t) => ({
        ...t,
        preview: get().templates.find(
          (prevTemplate) => prevTemplate.name === t.name,
        )?.preview,
      })),
    });
    for (const template of templates) {
      await get().setTemplate(template);
    }
  },

  setTemplate: async (template) => {
    const client = useApolloClientStore.getState().client;
    if (!client) {
      return;
    }

    const preview = await getTemplatePreview(template, get().defaults, client);
    if (!preview) {
      return;
    }

    set({
      templates: get().templates.map((t) =>
        t.name === template.name ? { ...t, ...template, preview } : t,
      ),
    });
  },
});

import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { InvoiceType } from "@/generated/graphql";
import { ExpandMore } from "@mui/icons-material";
import { MouseEvent } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  opened: boolean;
  handleOptionsClick: (event: MouseEvent<HTMLButtonElement>) => void;
  type?: InvoiceType;
  className?: string;
  withChevron?: boolean;
};

const OptionsButton = tw(
  PrimaryButton,
)`h-6 w-full bg-blue-450 pl-4 text-black hover:bg-blue-450`;

export const InvoiceOptionsButton = ({
  handleOptionsClick,
  opened,
  className = "",
  type = InvoiceType.Invoice,
  withChevron = false,
}: Props) => {
  return (
    <OptionsButton
      className={
        opened ? `rounded-b-none rounded-t-2xl ${className}` : className
      }
      onClick={handleOptionsClick}
      disableRipple
    >
      <FormattedMessage
        id={
          type === InvoiceType.Invoice ? "INVOICE_OPTIONS" : "RECEIPT_OPTIONS"
        }
      />
      <If isTrue={withChevron}>
        <ExpandMore
          className={`transition-all ${opened ? "rotate-180" : ""}`}
        />
      </If>
    </OptionsButton>
  );
};

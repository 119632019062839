import { Card } from "@/common/layout/ResponsiveClasses";
import tw from "tailwind-styled-components";

const HeaderContainer = tw(Card)`
  mb-6 justify-between border
  gap-x-4 gap-y-3 items-center w-full bg-blue-100 py-4 pl-4 pr-3 rounded-md border-blue-200`;

export const SpecifyDetailsDraftHeaderContainer = tw(HeaderContainer)`
  grid grid-cols-[1fr_1fr_0.6fr_1fr_1fr_1fr] grid-rows-2
`;

export const SpecifyDetailsNonDraftHeaderContainer = tw(HeaderContainer)`
  grid grid-cols-2
`;

export const WatchersContainer = tw.div`relative flex justify-end self-stretch text-xs flex-col gap-1 font-light w-full`;
export const WatchersLabel = tw.div`absolute -top-2`;
export const EmptyCell = tw.div``;

export const specifyDetailsInputProps = {
  className: "text-sm",
};
export const specifyDetailsInputLabelProps = {
  classes: {
    shrink: "text-base",
    root: "truncate text-sm max-w-full",
  },
};

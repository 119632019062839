import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { getUTCDate } from "@/common/components/picker/components/utils/getUTCDate";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useReceiptCreation } from "../../receipts/providers/ReceiptCreationProvider";
import { ReceiptCreateReleaseFormValues } from "../components/ReceiptVerificationForm";
import { useReceipt } from "../providers/ReceiptProvider";
import { useIsReceiptItemized } from "./useIsReceiptItemized";

export const useInitializeReceiptForm = () => {
  const [initialized, setInitialized] = useState(false);
  const { receipt, setItemized } = useReceipt();
  const { reset, getValues } = useFormContext<ReceiptCreateReleaseFormValues>();
  const { release } = useRelease();
  const isReceiptItemized = useIsReceiptItemized();
  const { locationId } = useReceiptCreation();
  const { calcExtPrice } = usePriceCalculation();
  const { settings } = useOrgSettings();
  const { projects } = useProjectListOptions();
  const {
    vendors,
    findOrderTypeByLocationId,
    findDefaultPaymentTermByLocationId,
  } = useVendors();
  const { defaultOrderType } = useOrderTypeOptions();

  const receiptSubtotalCalculated = useMemo(() => {
    if (!receipt) {
      return new DecimalSafe(0);
    }

    if (receipt.total && receipt.taxAmount) {
      return new DecimalSafe(receipt.total).minus(
        new DecimalSafe(receipt.taxAmount),
      );
    }

    return receipt.items.reduce(
      (subtotalAmount, item) =>
        subtotalAmount.add(calcExtPrice(item.quantityDecimal, item.unitPrice)),
      new DecimalSafe(0),
    );
  }, [receipt, calcExtPrice]);

  const isItemized = useMemo(
    () => (receipt ? isReceiptItemized(receipt) : false),
    [receipt, isReceiptItemized],
  );

  useEffect(() => {
    //it's important because we need settings to be loaded to detect the default view type for a receipt
    if (!settings) {
      return;
    }
    if (!initialized && (receipt || release)) {
      const isItemized = isReceiptItemized(receipt);
      setItemized(isItemized);

      const businessLocationId =
        getValues("businessLocationId") ||
        (receipt?.project && !receipt.project.deletedAt
          ? receipt.project.location.id
          : undefined) ||
        receipt?.predictedProject?.location?.id ||
        locationId ||
        "";

      const projectId =
        getValues("projectId") ||
        release?.project?.id ||
        (receipt?.project && !receipt.project.deletedAt
          ? receipt.project.id
          : undefined) ||
        receipt?.predictedProject?.id ||
        (projects.length === 1 ? projects[0].id : undefined) ||
        "";

      const vendorId =
        getValues("vendorId") ||
        receipt?.release?.sellerOrgLocation?.id ||
        receipt?.predictedSellerOrgLocation?.id ||
        (vendors.length === 1 ? vendors[0].sellerOrgLocation.id : undefined) ||
        "";

      const orderTypeId =
        getValues("orderTypeId") ||
        receipt?.release?.type.id ||
        (vendorId ? findOrderTypeByLocationId(vendorId) : undefined) ||
        defaultOrderType?.id;

      const issueDate =
        getValues("issueDate") ??
        defaultReleaseDate(getUTCDate(receipt?.issueDate));

      const phaseCodeId =
        getValues("phaseCodeId") ??
        (release?.items.length === 1 &&
        release?.items[0].tags.filter((t) => t.hasMapping).length
          ? release?.items[0].tags.filter((t) => t.hasMapping)[0].id
          : undefined);

      const costCodeId =
        getValues("costCodeId") ??
        (receipt?.costCode
          ? receipt.costCode.id
          : release?.items.length === 1
            ? release?.items[0].costCode?.id
            : undefined);

      const customTaxAmount =
        getValues("customTaxAmount") ??
        (isItemized && release
          ? release.customTaxAmount || undefined
          : receipt?.taxAmount || undefined);

      const netAmount =
        getValues("netAmount") ??
        (isItemized
          ? Number(receipt?.release?.netAmount ?? release?.netAmount)
          : Number(receipt?.subtotal) ||
            Number(receiptSubtotalCalculated) ||
            undefined);

      const description =
        getValues("description") ?? release?.description ?? undefined;

      const paymentTerm =
        vendorId && !!receipt?.release
          ? findDefaultPaymentTermByLocationId(vendorId)?.toString()
          : "";

      const taxVariance = getValues("taxVariance") ?? release?.taxVariance;

      const taxRate = getValues("taxRate") ?? release?.taxRate ?? undefined;

      const taxCodeId = getValues("taxCodeId") ?? release?.taxCode?.id;

      const taxType = getValues("taxType") ?? release?.taxType ?? undefined;

      reset({
        ...getValues(),
        businessLocationId,
        projectId,
        vendorId,
        orderTypeId,
        issueDate,
        phaseCodeId,
        costCodeId,
        customTaxAmount,
        netAmount,
        description,
        paymentTerm,
        taxVariance,
        taxRate,
        taxCodeId,
        taxType,
      });

      setInitialized(true);
    }
  }, [
    initialized,
    locationId,
    receipt,
    release,
    reset,
    setItemized,
    isItemized,
    receiptSubtotalCalculated,
    getValues,
    settings,
    vendors,
    findOrderTypeByLocationId,
    defaultOrderType,
    findDefaultPaymentTermByLocationId,
    projects,
    isReceiptItemized,
  ]);
};

import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { readValue } from "@/common/utils/localStorage";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { disabledColumns } from "../config/defaultConfiguration";
import { useEditColumnsStore } from "../store/useEditColumnsStore";
import { useExtendedColumnConfig } from "./useExtendedColumnConfig";

export const useEditColumns = (pageId?: string) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const extendedConfig = useExtendedColumnConfig();
  const { setHiddenColumns } = useEditColumnsStore(
    useShallow((state) => ({
      setHiddenColumns: state.setHiddenColumns,
    })),
  );

  const storageKey = useMemo(
    () => `${LOCAL_STORAGE_KEYS.CUSTOM_COLUMNS}_${pageId || "default"}`,
    [pageId],
  );

  useEffect(() => {
    if (!pageId) {
      return;
    }

    const savedColumns = readValue(storageKey);

    if (savedColumns && Array.isArray(savedColumns)) {
      const disabledColumnIds = savedColumns
        .filter((column) => !column.enabled && column.isOptional)
        .map((column) => column.id);

      setHiddenColumns(disabledColumnIds);
    } else if (disabledColumns[pageId]) {
      setHiddenColumns(disabledColumns[pageId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageKey]);

  const hasOptionalColumns = useMemo(
    () => !!pageId && extendedConfig.some((column) => column.optional === true),
    [extendedConfig, pageId],
  );

  const handleOpenDialog = useCallback(() => {
    if (!pageId) {
      return;
    }
    setDialogVisible(true);
  }, [pageId]);

  const handleCloseDialog = useCallback(() => {
    setDialogVisible(false);
  }, []);

  return {
    dialogVisible,
    setDialogVisible,
    hasOptionalColumns,
    handleOpenDialog,
    handleCloseDialog,
    pageId,
  };
};

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFindMaterialByName } from "@/common/components/spreadsheet-table/hooks/useFindMaterialByName";
import { useFormattedMaterialName } from "@/common/components/spreadsheet-table/hooks/useFormattedMaterialName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { appendTableRows } from "@/common/components/spreadsheet-table/utils/appendTableRows";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { DEFAULT_UNIT_UOM } from "@/common/const";
import { usePriceCalculation } from "@/common/hooks/usePriceCalculation";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { QuoteDocumentItemFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useAddItemsModal } from "./useAddItemsModal";

export const useAddQuoteItems = () => {
  const { handsonInstance } = useColumnMapper();
  const { quoteDocument } = useQuoteDocument();
  const findMaterialByName = useFindMaterialByName();
  const getFormattedMaterialName = useFormattedMaterialName();
  const { showModal } = useAddItemsModal();
  const { formatCostCode } = useProjectCostCodes();
  const { calcExtPrice } = usePriceCalculation();
  const { getPreferredCostCode } = useTableHelpers();

  const missingItems = useMemo(
    () =>
      quoteDocument?.items.filter(
        (item) =>
          !quoteDocument?.releaseItemHints.some(
            (hint) => hint.quoteDocumentItem?.id === item.id,
          ),
      ) || [],
    [quoteDocument?.items, quoteDocument?.releaseItemHints],
  );

  const addAllItems = useCallback(
    (items?: QuoteDocumentItemFieldsFragment[]) => {
      const newItems =
        (items || quoteDocument?.items || []).map((item) => {
          const material = findMaterialByName(item.description ?? "");
          const selectedCostCode = getPreferredCostCode(material?.costCode);
          return {
            [COLUMN_TYPE.Material]: getFormattedMaterialName(
              material ?? item.description ?? "",
            ),
            [COLUMN_TYPE.Manufacturer]: item.manufacturer ?? "",
            [COLUMN_TYPE.UOM]: String(item.uom) || DEFAULT_UNIT_UOM,
            [COLUMN_TYPE.Quantity]: item.quantity || "",
            [COLUMN_TYPE.UnitPrice]: item.unitPrice || "",
            [COLUMN_TYPE.ExtPrice]: String(
              calcExtPrice(item.quantity, item.unitPrice),
            ),
            [COLUMN_TYPE.CostCode]: formatCostCode(selectedCostCode),
            [COLUMN_TYPE.Taxable]: "true",
          };
        }) || [];
      appendTableRows(newItems, handsonInstance);
    },
    [
      quoteDocument?.items,
      handsonInstance,
      findMaterialByName,
      calcExtPrice,
      formatCostCode,
      getFormattedMaterialName,
      getPreferredCostCode,
    ],
  );

  const addMissingItems = useCallback(() => {
    addAllItems(missingItems);
  }, [addAllItems, missingItems]);

  const addQuoteItems = useCallback(() => {
    if (handsonInstance?.isDestroyed) {
      return;
    }
    const allRowsEmpty = handsonInstance?.getData().every(rowIsEmpty);
    const noMatches = quoteDocument?.items.length === missingItems.length;
    if (allRowsEmpty || noMatches) {
      addAllItems();
      return;
    }
    showModal(
      {
        handleCustomButtonAction: addAllItems,
        handleConfirm: addMissingItems,
      },
      quoteDocument?.items.length || 0,
      missingItems.length,
    );
  }, [
    handsonInstance,
    showModal,
    addAllItems,
    addMissingItems,
    quoteDocument?.items.length,
    missingItems,
  ]);

  return {
    addQuoteItems,
  };
};

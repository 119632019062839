import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useShallow } from "zustand/react/shallow";
import { useInvoiceImportExternalPO } from "../../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../../InvoiceVerificationForm";

export const useInvoiceCreateRelease = () => {
  const { invoice } = useInvoiceVerification();
  const { selectedBaseExternalPO, importedPoItemized, setVariables } =
    useInvoiceImportExternalPO();
  const { initViewStore, resetViewStore, setViewState } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
      setViewState: state.setViewState,
    })),
  );
  const { settings } = useOrgSettings();
  const { vendors } = useVendors();
  const { watch } = useFormContext<InvoiceCreateReleaseFormValues>();

  const vendorId = watch("vendorId");

  useEffect(() => {
    if (
      !selectedBaseExternalPO &&
      !invoice?.release &&
      vendorId &&
      vendors.some(
        (vendor) =>
          vendor.sellerOrgLocation.id === vendorId &&
          !!vendor.costCode?.ledgerAccount?.externalId,
      )
    ) {
      setVariables({ importedPoItemized: false });
    }
  }, [vendorId, invoice, vendors, setVariables, selectedBaseExternalPO]);

  useEffect(() => {
    initViewStore(TableViewState.spreadsheet);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  useEffect(() => {
    setViewState(
      importedPoItemized ? TableViewState.spreadsheet : TableViewState.normal,
    );
  }, [importedPoItemized, setViewState]);

  useEffect(() => {
    if (!selectedBaseExternalPO && settings) {
      const defaultItemized = settings?.display?.defaultItemized;
      setVariables({
        importedPoItemized:
          defaultItemized != null
            ? defaultItemized
            : !(
                (invoice?.items ?? []).length === 1 &&
                isLumpSumUomText(invoice?.items[0]?.UOM)
              ),
      });
    }
  }, [selectedBaseExternalPO, invoice?.items, setVariables, settings]);
};

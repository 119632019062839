import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { MdContainer, XlContainer } from "@/common/layout/ResponsiveClasses";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { useMemo } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  AssignableBuyoutWithBestMatch,
  useAssignBuyout,
} from "../../../providers/AssignBuyoutProvider";
import { AssignBuyoutVendorInfo } from "./AssignBuyoutVendorInfo";
import { AssignableBuyoutNumber } from "./AssignableBuyoutNumber";

const StyledNumberHeader = tw.span`text-center w-full`;
const StyledNumber = tw.span`text-sm text-gray-600 text-center w-full`;

export const useAssignBuyoutConfiguration = () => {
  const { selectedItem, expandedItem, setExpandedItem, allVendors } =
    useAssignBuyout();

  const configuration: Array<GridCol<AssignableBuyoutWithBestMatch>> =
    useMemo(() => {
      return [
        {
          wrapper: MdContainer,
          header: <FormattedMessage id="BUYOUT_NUMBER" />,
          item: ({ item }) => (
            <AssignableBuyoutNumber
              item={item}
              isSelected={selectedItem === item.buyout.id}
              isExpanded={expandedItem === item.buyout.id}
              onExpandClick={(item) =>
                setExpandedItem(
                  expandedItem === item.buyout.id ? "" : item.buyout.id,
                )
              }
            />
          ),
        },
        {
          wrapper: XlContainer,
          header: <FormattedMessage id="SUPPLIER" />,
          item: ({ item }) => (
            <AssignBuyoutVendorInfo
              logoImageUrl={item.buyout?.sellerOrgLocation?.org.photoUrl}
              address={item.buyout?.sellerOrgLocation?.address}
              contactName={getVendorContacts(
                item.buyout?.preferredVendor?.contacts.filter(
                  (c) => c.receivesOrderNotifications,
                ),
              )}
              orgName={item.buyout?.sellerOrgLocation?.org.name}
            />
          ),
          hidden: !allVendors,
        },
        {
          wrapper: MdContainer,
          header: (
            <StyledNumberHeader>
              <FormattedMessage id="BUYOUT_ITEMS" />
            </StyledNumberHeader>
          ),
          item: ({ item }) => <StyledNumber>{item.itemCount}</StyledNumber>,
        },
        {
          wrapper: MdContainer,
          header: (
            <StyledNumberHeader>
              <FormattedMessage id="BUYOUT_ITEMS_MATCHED" />
            </StyledNumberHeader>
          ),
          item: ({ item }) => <StyledNumber>{item.matchCount}</StyledNumber>,
        },
        {
          wrapper: MdContainer,
          header: (
            <StyledNumberHeader>
              <FormattedMessage id="BUYOUT_DATE" />
            </StyledNumberHeader>
          ),
          item: ({ item }) => (
            <StyledNumber>
              <FormattedDate value={item.buyout.createdAt} />
            </StyledNumber>
          ),
        },
      ];
    }, [allVendors, expandedItem, selectedItem, setExpandedItem]);

  return configuration;
};

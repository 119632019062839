import * as GraphQL from "@/generated/graphql";
import { OrderTypeFieldsFragment } from "@/generated/graphql";
import { useForm } from "react-hook-form";
import { FormType } from "../components/edit-order-type/FormType";

export const useOrderTypeForm = (orderType?: OrderTypeFieldsFragment) => {
  const methods = useForm<FormType>({
    defaultValues: {
      name: orderType?.name || "",
      glAccountNumber: orderType?.ledgerAccount?.number || "",
      costType: orderType?.costType?.id || "",
      kind: orderType?.transactionKind,
      default: orderType?.default || false,
      requireDeliverySlip: orderType?.requireDeliverySlip || false,
      billable: orderType?.billable || false,
      sendReminders: orderType?.enableUpcomingNotifications || false,
      ledgerAccountId: orderType?.ledgerAccount?.externalId || null,
      ledgerSubaccountId: orderType?.ledgerSubaccount?.externalId || null,
      poTypeId: orderType?.poType?.externalId || null,
      chargesMode: orderType?.chargesMode,
      taxMode: orderType?.taxMode,
      additionalChargesCostCodeId: orderType?.additionalChargesCostCode?.id,
      additionalChargesCostTypeId: orderType?.additionalChargesCostType?.id,
      salesTaxCostCodeId: orderType?.salesTaxCostCode?.id,
      salesTaxCostTypeId: orderType?.salesTaxCostType?.id,
      poFormatBasic: orderType?.poFormat === GraphQL.PoFormat.Basic,
      salesTaxOrgMaterialId: orderType?.salesTaxOrgMaterial?.id,
      defaultOrgMaterialId: orderType?.defaultOrgMaterial?.id,
      defaultAutoSync: orderType?.defaultAutoSync,
      nonJobPO: orderType?.nonJobPO || false,
      trackInventory: orderType?.trackInventory || false,
      includeTaxInInvoiceSubtotal:
        orderType?.includeTaxInInvoiceSubtotal ?? false,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  return { methods };
};

import { If } from "@/common/components/if/If";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  DateView,
  formattedDate,
  SHORT_DATE_OPTION,
} from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { useEnabledPoInSourceSystem } from "@/contractor/common/hooks/useEnabledPoInSourceSystem";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import {
  ArrowForward,
  CheckCircleRounded,
  Info,
  Link,
} from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { SourceSystemWrapper } from "../../../../../../../../common/components/source-system-wrapper/SourceSystemWrapper";
import { useReleaseWarnings } from "./useReleaseWarnings";

const IconContainer = tw.div`h-fit flex flex-row gap-1 flex-1 justify-end`;
const Icon = tw(Info)`text-orange-500`;
const SyncingIcon = tw(ArrowForward)`transition-all duration-500 animate-slide`;
const SyncIconContainer = tw.div`rounded-full border border-gray-300 border-dashed`;
const LinkStyled = tw(Link)`text-green-600`;
const LinkWarning = tw(Link)`text-orange-500`;
const CheckCircleOutlineRoundedStyled = tw(CheckCircleRounded)`text-green-800`;
const Rounded = tw.div<{
  $disabled: boolean;
  $notRequired?: boolean;
  $poExists?: boolean | null | undefined;
  $poSetNotToBeExported?: boolean;
  $hasError?: boolean;
}>`
${({ $disabled, $poExists, $poSetNotToBeExported, $hasError }) =>
  $hasError
    ? "bg-red-500 text-white"
    : $disabled
      ? "bg-white text-gray-500"
      : $poSetNotToBeExported
        ? "bg-gray-200 text-black"
        : $poExists
          ? "bg-blue-450 text-black"
          : "bg-green-300 text-black"}
${({ $notRequired }) => $notRequired && "bg-gray-200 text-black"}
rounded-full p-3 text-xs border border-gray-300 w-5 h-5 flex items-center justify-center`;
const ListItem = tw.li``;
const List = tw.ul`list-disc px-4`;

export const ReleaseWarnings: FC<{ release: ReleasePartialFieldsFragment }> = ({
  release,
}) => {
  const { connectedSourceSystem } = useOrgSettings();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const intl = useIntl();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();
  const {
    syncingTooltipMessage,
    errorTooltip,
    wasReleaseChanged,
    wasReleaseChangedAfterLinking,
    wasReleaseChangedAfterExport,
    isRequiredReceipt,
    poExportedTooltip,
    nonArchivedInvoices,
    nonArchivedReceipts,
  } = useReleaseWarnings({
    release,
  });

  return (
    <IconContainer>
      <If isTrue={!release.poLink?.syncedAt}>
        <SourceSystemWrapper anyIntegrationType>
          <If isTrue={release.exportedAt && !wasReleaseChanged}>
            <Tooltip
              id={`export-release-${release.id}`}
              element={<CheckCircleOutlineRoundedStyled />}
            >
              <FormattedMessage
                id="EXPORTED_AT"
                values={{
                  date: (
                    <DateView
                      date={release.exportedAt}
                      options={SHORT_DATE_OPTION}
                    />
                  ),
                }}
              />
            </Tooltip>
          </If>
        </SourceSystemWrapper>
      </If>
      <SourceSystemWrapper anySourceSystem anyIntegrationType>
        {(release.poLink?.syncing || !release.poLink?.syncedAt) &&
        syncingTooltipMessage ? (
          <Tooltip
            id={`syncing-${release.id}`}
            element={
              <SyncIconContainer>
                <SyncingIcon />
              </SyncIconContainer>
            }
          >
            {syncingTooltipMessage && (
              <FormattedMessage id={syncingTooltipMessage} />
            )}
          </Tooltip>
        ) : (
          <If
            isTrue={release.poLink?.syncedAt && !wasReleaseChangedAfterLinking}
          >
            <Tooltip id={`synced-${release.id}`} element={<LinkStyled />}>
              <FormattedMessage id="PO_SYNCHRONIZED" />
            </Tooltip>
          </If>
        )}
        <If isTrue={wasReleaseChangedAfterLinking}>
          <Tooltip
            id={`export-release-${release.id}`}
            element={<LinkWarning />}
          >
            <FormattedMessage id="EXPORT_RELEASE_CHANGED" />
          </Tooltip>
        </If>
        <If isTrue={wasReleaseChangedAfterExport && !release.poLink}>
          <Tooltip id={`export-release-${release.id}`} element={<Icon />}>
            <FormattedMessage id="EXPORT_RELEASE_CHANGED" />
          </Tooltip>
        </If>
        <If
          isTrue={
            isPoEnabledInSourceSystem &&
            (includePoNumbering ||
              release?.exportedAt ||
              release?.poLink?.syncedAt) &&
            !syncingTooltipMessage
          }
        >
          <Tooltip
            id={`po-export-${release.id}`}
            element={
              <Rounded
                $disabled={
                  !release?.exportedAt &&
                  !release?.poLink?.syncedAt &&
                  !release?.poExists &&
                  release.useSourceSystemPO
                }
                $poExists={
                  !release?.exportedAt &&
                  !release?.poLink?.syncedAt &&
                  release?.poExists
                }
                $poSetNotToBeExported={!release.useSourceSystemPO}
                $hasError={!!errorTooltip}
              >
                P
              </Rounded>
            }
          >
            {errorTooltip ||
              (connectedSourceSystem ? (
                <FormattedMessage
                  id={poExportedTooltip}
                  values={{
                    date: (
                      <DateView
                        date={release?.exportedAt || release?.poLink?.syncedAt}
                        options={SHORT_DATE_OPTION}
                      />
                    ),
                    username: getUserName(release.poLink?.createdBy),
                    sourceSystem: intl.$t({
                      id: `INTEGRATION_${connectedSourceSystem}`,
                    }),
                  }}
                />
              ) : null)}
          </Tooltip>
        </If>
        <If isTrue={isRequiredReceipt && isPoEnabledInSourceSystem}>
          <Tooltip
            id={`receipt-posted-${release.id}`}
            element={
              <Rounded
                $notRequired={!release?.requiresInventoryReceipt}
                $disabled={!release?.receiptPostedAt}
              >
                R
              </Rounded>
            }
          >
            {release?.requiresInventoryReceipt ? (
              <FormattedMessage
                id={
                  release?.receiptPostedAt
                    ? "RECEIPT_POSTED_AT"
                    : "RECEIPT_NOT_POSTED"
                }
                values={{
                  date: (
                    <DateView
                      date={release?.receiptPostedAt}
                      options={SHORT_DATE_OPTION}
                    />
                  ),
                }}
              />
            ) : (
              <FormattedMessage id="RECEIPT_NOT_REQUIRED" />
            )}
          </Tooltip>
        </If>
        <Tooltip
          id={`invoice-${release.id}`}
          element={
            <Rounded
              $disabled={
                !nonArchivedInvoices.length && !nonArchivedReceipts.length
              }
            >
              I
            </Rounded>
          }
        >
          {nonArchivedInvoices.length || nonArchivedReceipts.length ? (
            <List>
              {nonArchivedInvoices.map((invoice) => {
                return (
                  <ListItem key={invoice.id}>
                    {`${intl.$t({ id: "INVOICE" })}# ${invoice.number || "--"}
                    ${
                      invoice.issueDate
                        ? ` - ${formattedDate({ date: invoice.issueDate })}`
                        : ""
                    }
                    `}
                  </ListItem>
                );
              })}
              {nonArchivedReceipts.map((receipt) => {
                return (
                  <ListItem key={receipt.id}>
                    {`${intl.$t({ id: "RECEIPT_NUMBER_SYMBOL" })} ${receipt.number || "--"}
                    ${
                      receipt.issueDate
                        ? ` - ${formattedDate({ date: receipt.issueDate })}`
                        : ""
                    }
                    `}
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <FormattedMessage id="NO_INVOICE" />
          )}
        </Tooltip>
      </SourceSystemWrapper>
    </IconContainer>
  );
};

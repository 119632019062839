import { DeleteButton } from "@/common/components/button/DeleteButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { InvoiceDownloadButton } from "@/common/components/invoices/invoice-details/header/InvoiceDownloadButton";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceWarnings } from "../invoice-warnings/InvoiceWarnings";
import { useInvoiceActions } from "./hooks/useInvoiceActions";
import { InvoiceActionsProps } from "./types/InvoiceActionsProps";

const Container = tw.div`flex flex-row gap-1.5 items-center`;

export const InvoiceActions: FC<InvoiceActionsProps> = ({
  invoice,
  includeTrash,
}) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { handleDelete, includeDelete } = useInvoiceActions({
    invoice,
    includeTrash,
  });

  return (
    <Container>
      <InvoiceWarnings invoice={invoice} />
      <InvoiceDownloadButton invoice={invoice} simpleButton />
      <If isTrue={includeDelete}>
        <DeleteButton
          onClick={() =>
            openDialog({
              cancelButtonText: intl.$t({ id: "CANCEL" }),
              confirmButtonText: intl.$t({ id: "PROCEED" }),
              includeErrorIcon: true,
              title: intl.$t({ id: "DELETE_INVOICE_DETAILS" }),
              text: intl.$t({ id: "DELETE_INVOICE" }),
              handleConfirm: handleDelete,
            })
          }
        />
      </If>
    </Container>
  );
};

import { ExternalPOsQueryInputType } from "@/common/components/import-external-po/types/ExternalPOsQueryInputType";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useFormattedMaterialName } from "@/common/components/spreadsheet-table/hooks/useFormattedMaterialName";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { appendTableRows } from "@/common/components/spreadsheet-table/utils/appendTableRows";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useAddSlipItems } from "@/common/hooks/add-missing-items-to-order/useAddSlipItems";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { SelectableItem } from "@/contractor/pages/home/release/pages/add-items-to-release/AddItemsToReleaseProvider";
import { OrgMaterialFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDeliverySlipImportExternalPO } from "../../../../hooks/useDeliverySlipImportExternalPO";
import { useDeliverySlipRelease } from "../../../../providers/DeliverySlipReleaseProvider";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";

export const useDeliverySlipEditReleaseHeader = () => {
  const { buyout } = useContractorBuyout();
  const { openModal, externalPo } = useDeliverySlipImportExternalPO();
  const { deliverySlip, setPackingSlipReceiveViewState } =
    useDeliverySlipVerification();
  const { loading: loadingPrices } = useVendorPrices();
  const { updateReleaseForm: form } = useDeliverySlipRelease();
  const externalPOsQueryForm = useFormContext<ExternalPOsQueryInputType>();
  const { handsonInstance } = useColumnMapper();
  const getFormattedMaterialName = useFormattedMaterialName();
  const { formatCostCode } = useProjectCostCodes();
  const { getPreferredCostCode } = useTableHelpers();
  const { materials, loading: loadingMaterials } = useMaterials();
  const { addSlipItems } = useAddSlipItems();
  const [initialized, setInitialized] = useState<boolean>(false);
  const { loading: loadingVendors } = useVendors();

  useEffect(() => {
    if (
      deliverySlip?.release?.itemCount === 0 &&
      (deliverySlip.release.status === ReleaseStatus.Draft ||
        deliverySlip.release.status === ReleaseStatus.Reserved) &&
      !initialized &&
      handsonInstance &&
      !loadingMaterials &&
      !loadingVendors &&
      !loadingPrices
    ) {
      addSlipItems();
      setInitialized(true);
    }
  }, [
    deliverySlip?.release?.itemCount,
    deliverySlip?.release?.status,
    deliverySlip,
    initialized,
    handsonInstance,
    addSlipItems,
    loadingMaterials,
    loadingPrices,
    loadingVendors,
  ]);

  useEffect(() => {
    if (deliverySlip?.fulfillmentDate && !form.getValues("orderDate")) {
      form.setValue("orderDate", new Date(deliverySlip.fulfillmentDate));
    }
  }, [deliverySlip?.fulfillmentDate, form]);

  const poNumber = form?.watch("poNumber");

  const onPoNumberSearchIconClick = useCallback(() => {
    if (!externalPo && poNumber) {
      externalPOsQueryForm?.setValue("search", poNumber);
    }
    openModal({ skipPoList: !!externalPo });
  }, [externalPOsQueryForm, externalPo, openModal, poNumber]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setPackingSlipReceiveViewState(PackingSlipReceiveViewState.DEFAULT);
    },
    [setPackingSlipReceiveViewState],
  );

  const addBuyoutItems = useCallback(
    (items: SelectableItem[]) => {
      const newItems =
        buyout?.items
          .filter((item) => items.some((i) => i.itemId === item.id))
          .map((item) => {
            const material = materials.find(
              (m: OrgMaterialFieldsFragment) =>
                m.id === item.projectItem.material.id,
            );
            const selectedCostCode = getPreferredCostCode(material?.costCode);
            return {
              [COLUMN_TYPE.Material]: getFormattedMaterialName(
                material ?? item.description ?? "",
              ),
              [COLUMN_TYPE.UOM]:
                item.projectItem.estimateUom.pluralDescription || "UT",
              [COLUMN_TYPE.Quantity]: item.quantityDecimal || "",
              [COLUMN_TYPE.ReceivedQuantity]: item.quantityDecimal || "",
              [COLUMN_TYPE.PrefilledPrice]: item.unitPrice || "",
              [COLUMN_TYPE.CostCode]: formatCostCode(selectedCostCode),
              [COLUMN_TYPE.Taxable]: "true",
            };
          }) || [];
      appendTableRows(newItems, handsonInstance);
    },
    [
      buyout?.items,
      handsonInstance,
      materials,
      getFormattedMaterialName,
      getPreferredCostCode,
      formatCostCode,
    ],
  );

  return {
    onPoNumberSearchIconClick,
    handleClick,
    addBuyoutItems,
  };
};

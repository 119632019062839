import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useAddItemsToRelease } from "../../AddItemsToReleaseProvider";

const Container = tw.div`
  flex mr-1 w-5
`;

export const AddEstimatedItemCheckbox: FC<{
  item: ProjectItemFieldsFragment;
  estimatedItem: EstimatedItemFieldsFragment | null | undefined;
  estimatedItems?: EstimatedItemFieldsFragment[];
  quantityDecimal: string | undefined;
  zoneId: string | undefined;
}> = ({
  item,
  estimatedItem,
  estimatedItems = [],
  quantityDecimal = "1",
  zoneId = UNSPECIFIED_ZONE_ID,
}) => {
  const { selectedItems, setSelectedItems } = useAddItemsToRelease();

  const isSelected = useCallback(
    (
      item: ProjectItemFieldsFragment,
      estItem?: EstimatedItemFieldsFragment | null,
    ) => {
      return selectedItems.some(
        (i) => i.itemId === item.id && i.estimatedItemId === estItem?.id,
      );
    },
    [selectedItems],
  );

  const checked = useMemo(
    () =>
      estimatedItems.length > 1
        ? estimatedItems.every((estItem) => isSelected(item, estItem))
        : isSelected(item, estimatedItem),
    [estimatedItem, estimatedItems, isSelected, item],
  );
  const handleChange = useCallback(
    (newChecked: boolean) => {
      const newItems =
        estimatedItems.length > 1 ? estimatedItems : [estimatedItem];

      setSelectedItems(
        newChecked
          ? [
              ...selectedItems.filter(
                (selectedItem) =>
                  !newItems.some(
                    (newItem) =>
                      newItem?.id === selectedItem.estimatedItemId &&
                      selectedItem.itemId === item.id,
                  ),
              ),
              ...newItems.map((estItem) => ({
                itemId: item.id,
                quantityDecimal: estItem?.quantityDecimal || quantityDecimal,
                estimatedItemId: estItem?.id,
                zoneId: estItem?.zone?.id || zoneId,
                costCodeId: estItem?.costCode?.id,
              })),
            ]
          : selectedItems.filter(
              (r) =>
                !newItems.some(
                  (estItem) =>
                    r.itemId === item.id && r.estimatedItemId === estItem?.id,
                ),
            ),
      );
    },
    [
      estimatedItem,
      estimatedItems,
      item.id,
      quantityDecimal,
      selectedItems,
      setSelectedItems,
      zoneId,
    ],
  );

  return (
    <Container>
      <SelectionCheckbox checked={checked} setSelection={handleChange} />
    </Container>
  );
};

import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalFooterButton } from "@/common/components/wizard-modal/components/WizardModalFooterButton";
import {
  isWizardVisible,
  useWizardStore,
} from "@/common/components/wizard-modal/store/useWizardStore";
import { WizardStoreArgs } from "@/common/components/wizard-modal/types/WizardStoreArgs";
import { getWizardArg } from "@/common/components/wizard-modal/utils/getWizardArg";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { routes } from "@/config/routes";
import { InventoryTransactionType } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { TransactionHistoryAdjustments } from "../components/transaction-history-steps/transaction-history-adjustments/TransactionHistoryAdjustments";
import { TransactionHistoryInflows } from "../components/transaction-history-steps/transaction-history-inflows/TransactionHistoryInflows";
import { TransactionHistoryOutflows } from "../components/transaction-history-steps/transaction-history-outflows/TransactionHistoryOutflows";
import { TransactionHistoryScheduledInflows } from "../components/transaction-history-steps/transaction-history-scheduled-inflows/TransactionHistoryScheduledInflows";
import { TransactionHistoryScheduledOutflows } from "../components/transaction-history-steps/transaction-history-scheduled-outflows/TransactionHistoryScheduledOutflows";
import { TransactionHistoryStock } from "../components/transaction-history-steps/transaction-history-stock/TransactionHistoryStock";
import { TransactionHistoryTransfers } from "../components/transaction-history-steps/transaction-history-transfers/TransactionHistoryTransfers";
import { TRANSACTION_HISTORY_MODAL_ID } from "../TransactionHistoryModal";
import {
  InventoryTransactionExtendedType,
  InventoryTransactionScheduledTypes,
} from "../types/InventoryTransactionExtendedType";

export const TRANSACTION_TYPE_STEP_MAP = {
  [InventoryTransactionType.Adjustment]: 1,
  [InventoryTransactionType.Transfer]: 2,
  [InventoryTransactionType.Inflow]: 3,
  [InventoryTransactionType.Outflow]: 4,
  [InventoryTransactionScheduledTypes.SCHEDULED_INFLOW]: 5,
  [InventoryTransactionScheduledTypes.SCHEDULED_OUTFLOW]: 6,
};

export const useTransactionHistoryModal = () => {
  const intl = useIntl();
  const { setNestedStep } = useNestedStepper();
  const navigate = useNavigate();
  const { show, hide, visibleIds, args } = useWizardStore(
    useShallow((state) => ({
      show: state.show,
      hide: state.hide,
      visibleIds: state.visibleIds,
      args: state.args,
    })),
  );

  const releaseId = useMemo(
    () => getWizardArg<string>(args, "releaseId"),
    [args],
  );

  const visible = useMemo(
    () => isWizardVisible(TRANSACTION_HISTORY_MODAL_ID, visibleIds),
    [visibleIds],
  );

  const onOpen = useCallback(
    (
      args?: WizardStoreArgs,
      inventoryTransactionType?: InventoryTransactionExtendedType,
    ) => {
      const defaultStep = inventoryTransactionType
        ? TRANSACTION_TYPE_STEP_MAP[inventoryTransactionType]
        : undefined;
      show(TRANSACTION_HISTORY_MODAL_ID, args, defaultStep);
    },
    [show],
  );

  const onClose = useCallback(() => {
    setNestedStep(0);
    hide(TRANSACTION_HISTORY_MODAL_ID);
  }, [hide, setNestedStep]);

  const defaultFooterButtonConfig: WizardModalFooterButton[] = useMemo(
    () => [
      {
        type: "outlined",
        onClick: onClose,
        text: intl.$t({ id: "CLOSE" }),
        className: "w-60",
      },
      ...(releaseId
        ? [
            {
              type: "outlined",
              onClick: () => {
                navigate(
                  generatePath(routes.specifyDeliveryDetails, {
                    deliveryId: releaseId,
                  }),
                );
                onClose();
              },
              text: intl.$t({ id: "EDIT_ORDER" }),
              className: "w-60 ml-2",
            } as WizardModalFooterButton,
          ]
        : []),
    ],
    [intl, navigate, onClose, releaseId],
  );

  const pages: WizardModalPage[] = useMemo(() => {
    return [
      {
        hideHeader: true,
        pages: [
          {
            component: <TransactionHistoryStock />,
            footerButtonsConfig: defaultFooterButtonConfig,
          },
          {
            component: <TransactionHistoryAdjustments />,
            footerButtonsConfig: defaultFooterButtonConfig,
          },
          {
            component: <TransactionHistoryTransfers />,
            footerButtonsConfig: defaultFooterButtonConfig,
          },
          {
            component: <TransactionHistoryInflows />,
            footerButtonsConfig: defaultFooterButtonConfig,
          },
          {
            component: <TransactionHistoryOutflows />,
            footerButtonsConfig: defaultFooterButtonConfig,
          },
          {
            component: <TransactionHistoryScheduledInflows />,
            footerButtonsConfig: defaultFooterButtonConfig,
          },
          {
            component: <TransactionHistoryScheduledOutflows />,
            footerButtonsConfig: defaultFooterButtonConfig,
          },
        ],
      },
    ];
  }, [defaultFooterButtonConfig]);

  return {
    onOpen,
    onClose,
    pages,
    visible,
  };
};

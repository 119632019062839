import { DateView } from "@/common/utils/dates/DateView";
import { InventoryTransactionSummaryFieldsFragment } from "@/generated/graphql";
import {
  InventoryItem,
  InventoryItemContainer,
  InventoryItemDetails,
} from "../../InventoryLedgerList.styles";

type Props = {
  item: InventoryTransactionSummaryFieldsFragment;
};

export const TransactionIdentifierWithDate = ({ item }: Props) => {
  return (
    <InventoryItemContainer>
      <InventoryItem>#{item.number}</InventoryItem>
      <InventoryItemDetails>
        <DateView date={item.createdAt} includeTime />
      </InventoryItemDetails>
    </InventoryItemContainer>
  );
};

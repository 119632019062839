import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { SystemAlertType } from "../../system-alert/SystemAlert";

export const useSpreadSheetWarning = () => {
  const { setSystemAlert } = useSnackbar();
  const showWarning = useCallback(
    (textId: string, values: Record<string, React.ReactNode>) => {
      setSystemAlert(
        <FormattedMessage
          id={textId}
          values={{
            ...values,
            b: (...chunks) => <strong>{chunks}</strong>,
          }}
        />,
        { type: SystemAlertType.ERROR },
      );
    },
    [setSystemAlert],
  );

  return {
    showWarning,
  };
};

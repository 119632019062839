import { useColumnMapper } from "@/common/components/spreadsheet-table/providers/ColumnMapperProvider";
import { clearSpreadSheetUnusedCostCodes } from "@/common/utils/cost-codes-and-zones/clearSpreadSheetUnusedCostCodes";
import { useEffect } from "react";
import { useProjectCostCodes } from "../../project/hooks/useProjectCostCodes";
import { useProjectStore } from "../../project/store/useProjectStore";
import { useRelease } from "../providers/ReleaseProvider";

export const useItemizedReleaseProjectBudgetSync = () => {
  const { release, loading: releaseLoading } = useRelease();
  const { spreadsheetData, handsonInstance } = useColumnMapper();
  const budgetLink = useProjectStore((state) => state.budgetLink);
  const { projectCostCodes } = useProjectCostCodes();

  useEffect(() => {
    if (
      !handsonInstance ||
      !budgetLink?.autoSync ||
      release ||
      releaseLoading
    ) {
      return;
    }
    clearSpreadSheetUnusedCostCodes(
      spreadsheetData,
      handsonInstance,
      projectCostCodes,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetLink, release, releaseLoading]);
};
